import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { DRI } from './food-data';
import { Observable } from 'rxjs/internal/Observable';
import { __values } from 'tslib';

@Injectable({
  providedIn: 'root',
})

export class DRIService {
  constructor(private httpClient: HttpClient){}
  url_dri ="https://ai-basil.com/v1/foods/lscategoryNutrition";
	
	getDRI(data:any): Observable<DRI[]> {   
    console.log(this.url_dri+'/'+data.lsgroup+'/'+data.age);
    return this.httpClient.get<DRI[]>(this.url_dri+'/'+data.lsgroup+'/'+data.age);
  }
}