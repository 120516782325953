import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OllamaService {
  private apiUrl = 'http://localhost:8080/v1/foods/api/ollama/query'; // Replace with actual backend URL

  constructor(private http: HttpClient) {}

 
  queryOllama(prompt: string): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  
    return this.http.post('http://localhost:8080/v1/foods/api/ollama/query', 
      { prompt }, 
      { headers, responseType: 'text' }
    );
  }
  

  
  
}
