<!--app-admin></app-admin-->

<mat-tab-group animationDuration=0 mat-align-tabs="start">

  <mat-tab label="Nutrients">
    <div class="input-container">
      <!--<input list="food" [(ngModel)]="inputValue" placeholder="Protein, Iodine, Iron..." class="input-field"
        name="foodList" #filter />-->

      <mat-form-field>
        <input matInput placeholder="Protein, Iodine, Iron......" [(ngModel)]="inputValue"
          (input)="filterResults(inputValue)" list="food">
        <mat-label> Search by Nutrient </mat-label>
        <datalist id="food">
          <option value="Protein"></option>
          <option value="Carbohydrate"></option>
          <option value="Fiber"></option>
          <option value="SaturatedFats"></option>
          <option value="MonoUnsaturatedFats"></option>
          <option value="PolyUnsaturatedFats"></option>
          <option value="TransFats"></option>
          <option value="Cholestrol"></option>
          <option value="Water"></option>
          <option value="Biotin"></option>
          <option value="Boron"></option>
          <option value="Calcium"></option>
          <option value="CaroteneAlpha"></option>
          <option value="CaroteneBeta"></option>
          <option value="CaroteneGamma"></option>
          <option value="Chlorine"></option>
          <option value="Cholinefree"></option>
          <option value="CholineFromPhosphocholineMg"></option>
          <option value="CholineFromPhosphotidylCholineMg"></option>
          <option value="CholineFromSphingomyelinMg"></option>
          <option value="CholineFromGlycerophosphocholineMg"></option>
          <option value="CholineTotal"></option>
          <option value="Chromium"></option>
          <option value="Copper"></option>
          <option value="Fluoride"></option>
          <option value="FolateDFE"></option>
          <option value="FolateFood"></option>
          <option value="FolateTotal"></option>
          <option value="Iodine"></option>
          <option value="Iron"></option>
          <option value="Magnesium"></option>
          <option value="Manganese"></option>
          <option value="Molybdenum"></option>
          <option value="Niacin"></option>
          <option value="Nickel"></option>
          <option value="PantothenicAcid"></option>
          <option value="Phosphorus"></option>
          <option value="Potassium"></option>
          <option value="Riboflavin"></option>
          <option value="Selenium"></option>
          <option value="Sodium"></option>
          <option value="Sulfur"></option>
          <option value="Thiamin"></option>
          <option value="VitaminA_RAE"></option>
          <option value="VitaminAIU"></option>
          <option value="VitaminB12Added"></option>
          <option value="VitaminB12"></option>
          <option value="VitaminB6"></option>
          <option value="VitaminC"></option>
          <option value="VitaminD_D2PlusD3IU"></option>
          <option value="VitaminD_D2PlusD3"></option>
          <option value="VitaminD2"></option>
          <option value="VitaminD3"></option>
          <option value="VitaminD4"></option>
          <option value="VitaminE_Added"></option>
          <option value="VitaminE_AlphaTocopherol"></option>
          <option value="VitaminELabelEntryIU"></option>
          <option value="VitaminEMcgRE"></option>
          <option value="VitaminK_Dihydrophylloquinone"></option>
          <option value="VitaminK_Menaquinone4"></option>
          <option value="VitaminK_Phylloquinone"></option>
          <option value="Zinc"></option>
        </datalist>
      </mat-form-field>


      <!-- Clear button that appears when there's input text -->
      <!--<button *ngIf="inputValue" class="clear-button" (click)="clearInput()">
        &#10006;
      </button>
      <datalist id="food">
        <option value="Protein">
        <option value="Carbohydrate">
        <option value="Fiber">
        <option value="SaturatedFats">
        <option value="MonoUnsaturatedFats">
        <option value="PolyUnsaturatedFats">
        <option value="TransFats">
        <option value="Cholestrol">
        <option value="Water">
        <option value="Biotin">
        <option value="Boron">
        <option value="Calcium">
        <option value="CaroteneAlpha">
        <option value="CaroteneBeta">
        <option value="CaroteneGamma">
        <option value="Chlorine">
        <option value="Cholinefree">
        <option value="CholineFromPhosphocholineMg">
        <option value="CholineFromPhosphotidylCholineMg">
        <option value="CholineFromSphingomyelinMg">
        <option value="CholineFromGlycerophosphocholineMg">
        <option value="CholineTotal">
        <option value="Chromium">
        <option value="Copper">
        <option value="Fluoride">
        <option value="FolateDFE">
        <option value="FolateFood">
        <option value="FolateTotal">
        <option value="Iodine">
        <option value="Iron">
        <option value="Magnesium">
        <option value="Manganese">
        <option value="Molybdenum">
        <option value="Niacin">
        <option value="Nickel">
        <option value="PantothenicAcid">
        <option value="Phosphorus">
        <option value="Potassium">
        <option value="Riboflavin">
        <option value="Selenium">
        <option value="Sodium">
        <option value="Sulfur">
        <option value="Thiamin">
        <option value="VitaminA_RAE">
        <option value="VitaminAIU">
        <option value="VitaminB12Added">
        <option value="VitaminB12">
        <option value="VitaminB6">
        <option value="VitaminC">
        <option value="VitaminD_D2PlusD3IU ">
        <option value="VitaminD_D2PlusD3">
        <option value="VitaminD2">
        <option value="VitaminD3">
        <option value="VitaminD4">
        <option value="VitaminE_Added">
        <option value="VitaminE_AlphaTocopherol">
        <option value="VitaminELabelEntryIU">
        <option value="VitaminEMcgRE ">
        <option value="VitaminK_Dihydrophylloquinone">
        <option value="VitaminK_Menaquinone4 ">
        <option value="VitaminK_Phylloquinone">
        <option value="Zinc">
      </datalist>-->
    </div>
    <!-- Search button -->

    <!--<button class="primary" type="button" (click)="filterResults(filter.value)"> Search </button>
  -->
    <!--div class="filter-container"-->
    <!--input matInput (keyup)="applyFilter($event)" placeholder="Filter by category"-->
    <!--/div-->

    <div class="filter-container">
      <mat-form-field appearance="fill">
        <mat-label>Diet Type</mat-label>
        <mat-select [(value)]="selectedDiet" (selectionChange)="applyFilter()">
          <mat-option value="">All Diets</mat-option>
          <mat-option value="vegan">Vegan</mat-option>
          <mat-option value="vegetarian">Vegetarian</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Food Category</mat-label>
        <mat-select [(value)]="selectedCategory" (selectionChange)="applyFilter()">
          <mat-option *ngFor="let category of filteredCategories" [value]="category">{{ category }}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <section class="results">
      <table mat-table [dataSource]="filteredDataSource" class="mat-elevation-z8 custom-table">
        <!-- Index Column -->
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef> Index </th>
          <td mat-cell *matCellDef="let food "> {{food.index}} </td>
        </ng-container>

        <!-- Food Description Column -->
        <ng-container matColumnDef="fooddescription">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let food"> {{food.fooddescription}} </td>
        </ng-container>

        <!-- Foodcategory Column -->
        <ng-container matColumnDef="foodcategory">
          <th mat-header-cell *matHeaderCellDef> Category </th>
          <td mat-cell *matCellDef="let food"> {{food.foodcategory}} </td>
        </ng-container>

        <!-- Serving Size Column -->
        <ng-container matColumnDef="perservingsizeing">
          <th mat-header-cell *matHeaderCellDef> Serving Size </th>
          <td mat-cell *matCellDef="let food"> {{food.perservingsizeing}} </td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="per100unitservinging">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let food"> {{food.per100unitservinging}} </td>
        </ng-container>

        <!-- Unit Column -->
        <ng-container matColumnDef="unitname">
          <th mat-header-cell *matHeaderCellDef> Unit </th>
          <td mat-cell *matCellDef="let food"> {{food.unitname}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </section>

  </mat-tab>
  <mat-tab label="DRI"><app-search-form></app-search-form></mat-tab>
  <mat-tab label="Search Food">
    <div class="food-search">
      <!-- Input field for searching food by name -->
      <mat-form-field appearance="fill">
        <mat-label>Enter Food Name</mat-label>
        <input matInput placeholder="Search food..." [(ngModel)]="foodName" list="nutrients" (input)="onSearchFood()" />
        <datalist id="nutrients">
          <option *ngFor="let food of allFoods" [value]="food"></option>
        </datalist>
      </mat-form-field>

      <!-- Search Button -->
      <!--<button mat-raised-button color="primary" (click)="onSearchFood()">Search</button>-->
    </div>

    <!-- Display the nutrients info in the same tab -->
    <h3 *ngIf="foodData">Food Information</h3>
    <div *ngIf="foodData">
      <p><strong>Food Name:</strong> {{ foodData.foodName }}</p>
      <p><strong>Category:</strong> {{ foodData.foodCategory.categoryName }}</p>

      <h3>Nutrients Information</h3>
      <mat-table [dataSource]="foodData.nutrients" class="mat-elevation-z8" style="width: 100%">
        <!-- Nutrient Name Column -->
        <ng-container matColumnDef="nutrientName">
          <th mat-header-cell *matHeaderCellDef> Nutrient Name </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.nutrientName }} </td>
        </ng-container>

        <!-- Nutrient Amount Column -->
        <ng-container matColumnDef="nutrientAmount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.nutrientAmount }} </td>
        </ng-container>

        <!-- Unit Column -->
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef> Unit </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.unit }} </td>
        </ng-container>

        <!-- Portion Size Column -->
        <ng-container matColumnDef="portionSize">
          <th mat-header-cell *matHeaderCellDef> Portion Size </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.portionSize }} </td>
        </ng-container>

        <!-- Portion Size Unit Column -->
        <ng-container matColumnDef="portionSizeUnit">
          <th mat-header-cell *matHeaderCellDef> Portion Size Unit </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.portionSizeUnit }} </td>
        </ng-container>

        <!-- Table headers and rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
      </mat-table>
    </div>
    <!-- Nutrient Distribution Chart -->

  </mat-tab>
  <mat-tab label="Nutrition Label Generator">
    <form>
      <!-- Food Name Input -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Enter Food Name</mat-label>
        <input matInput [(ngModel)]="foodName" name="foodName" placeholder="E.g., Salad" />
      </mat-form-field>

      <!-- Number of Servings -->
      <mat-form-field appearance="fill" class="half-width">
        <mat-label>Number of Servings</mat-label>
        <input matInput type="number" [(ngModel)]="numberOfServings" placeholder="E.g., 2" name="numberOfServings" />
      </mat-form-field>

      <!-- Serving Size -->
      <mat-form-field appearance="fill" class="half-width">
        <mat-label>Serving Size (g)</mat-label>
        <input matInput type="number" [(ngModel)]="servingSize" placeholder="E.g., 150" name="servingSize" />
      </mat-form-field>

      <!-- Ingredient Section -->
      <div class="ingredient-section">
        <!-- Ingredient Input -->
        <mat-form-field appearance="fill" class="half-width">
          <mat-label>Ingredient</mat-label>
          <input matInput [(ngModel)]="newIngredient" placeholder="Enter ingredient" name="newIngredient"
            list="nutrients" />
          <datalist id="nutrients">
            <option *ngFor="let food of allFoods" [value]="food"></option>
          </datalist>
        </mat-form-field>

        <!-- Amount Input -->
        <mat-form-field appearance="fill" class="half-width">
          <mat-label>Amount (g)</mat-label>
          <input matInput type="number" [(ngModel)]="newAmount" placeholder="Amount in grams" name="newAmount" />
        </mat-form-field>

        <!-- Add Ingredient Button -->
        <button mat-button color="primary" (click)="addIngredient()">Add Ingredient</button>
      </div>

      <!-- Display Ingredient List -->
      <mat-list>
        <mat-list-item *ngFor="let ingredient of ingredients">
          {{ ingredient.ingredient }} - {{ ingredient.amount }} g
          <button mat-icon-button color="warn" (click)="removeIngredient(ingredient)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </form>


    <!-- Dynamic Ingredients Table -->
    <table mat-table [dataSource]="ingredients" class="mat-elevation-z8">

      <!-- Ingredient Column -->
      <ng-container matColumnDef="ingredient">
        <th mat-header-cell *matHeaderCellDef> Ingredient </th>
        <td mat-cell *matCellDef="let element"> {{ element.ingredient }} </td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Amount (g) </th>
        <td mat-cell *matCellDef="let element"> {{ element.amount }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns_nutrition"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns_nutrition;"></tr>
    </table>

    <!-- Generate Button for mat-table -->
    <button mat-raised-button color="accent" (click)="generateNutritionLabel()" class="spaced-button">
      Generate Nutrition Label (Full)
    </button>

    <!-- Generate Button for FDA Nutrition Label -->
    <button mat-raised-button color="accent" (click)="generateNutritionLabelFDA()" class="spaced-button">
      Nutrition Label (FDA-Compliant)
    </button>

    <!-- Generate Button for FDA Nutrition Label -->
    <button mat-raised-button color="accent" (click)="NutritionLabelFDA_VoluntaryNuts()" class="spaced-button">
      Nutrition Label with Voluntary Nutrients (FDA-Compliant)
    </button>


    <!-- Placeholder for Result Display -->
    <!-- Mat Table Display -->
    <div *ngIf="showMatTable && nutritionLabel.length > 0">
      <h3>Nutrition Label</h3>
      <table mat-table [dataSource]="nutritionLabel" class="mat-elevation-z8">
        <!-- Nutrient Name Column -->
        <ng-container matColumnDef="nutrientName">
          <th mat-header-cell *matHeaderCellDef> Nutrient </th>
          <td mat-cell *matCellDef="let element"> {{ element.nutrientName }} </td>
        </ng-container>

        <!-- Nutrient Amount Column -->
        <ng-container matColumnDef="nutrientAmount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let element"> {{ element.nutrientAmount }} </td>
        </ng-container>

        <!-- Unit Column -->
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef> Unit </th>
          <td mat-cell *matCellDef="let element"> {{ element.unit }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['nutrientName', 'nutrientAmount', 'unit']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['nutrientName', 'nutrientAmount', 'unit'];"></tr>
      </table>
    </div>


    <!-- FDA Nutrition Label Display -->
    <div *ngIf="showFDA && nutritionLabel.length > 0" class="nutrition-label">
      <h1>Nutrition Facts</h1>
      <!-- Thin Line Below Nutrition Facts -->
      <div class="vitamin-line"></div>
      <!-- Serving Info -->
      <div class="serving-info">
        <span class="servings-per-container">{{ numberOfServings }} servings per container</span><br />
        <div style="display: flex; justify-content: space-between">
          <span class="bold serving-size">Serving size</span>
          <span class="bold serving-weight">{{ servingSize }}g</span>
        </div>
      </div>

      <!-- Calories Section -->
      <div class="calories-header">Amount per serving</div>
      <div class="calories">
        <span class="calories-text">Calories </span>
        <span class="calories-number">{{ totalCalories }}</span>
      </div>
      <hr class="thin-rule" />


      <!-- Nutrients Section -->
      <div *ngFor="let nutrient of nutritionLabel">
        <div class="nutrient-line" *ngIf="!isVitaminOrMineral(nutrient.nutrientName)" [ngClass]="{
      'bold-nutrient': isMajorNutrient(nutrient.nutrientName),
      'sub-nutrient': nutrient.isSubNutrient,
      'sub-sub-nutrient': nutrient.isSubSubNutrient
    }">
          <!-- Special Formatting for "Trans Fat" -->
          <span *ngIf="nutrient.nutrientName === 'Trans Fat'">
            <i>{{ nutrient.nutrientName.split(' ')[0] }}</i>
            {{ nutrient.nutrientName.substring(nutrient.nutrientName.indexOf(' ') + 1) }}
            {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
          </span>

          <!-- Special Formatting for "Added Sugars" -->
          <span *ngIf="nutrient.nutrientName === 'Added Sugars' && nutrient.isSubSubNutrient">
            Includes {{ nutrient.nutrientAmount }} {{ nutrient.unit }} {{ nutrient.nutrientName }}
          </span>

          <!-- Normal Display for Other Nutrients -->
          <span *ngIf="nutrient.nutrientName !== 'Trans Fat' && nutrient.nutrientName !== 'Added Sugars'">
            {{ nutrient.nutrientName }} {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
          </span>

          <!-- Daily Value Percentage -->
          <span class="dv-amount" *ngIf="nutrient.percentage !== '% Daily Value*' && !isNoDV(nutrient.nutrientName)">
            {{ nutrient.percentage }}%
          </span>
          <span class="dv-amount" *ngIf="nutrient.percentage === '% Daily Value*'">
            {{ nutrient.percentage }}
          </span>

          <!-- Notes -->
          <!-- <span *ngIf="nutrient.note">{{ nutrient.note }}</span>-->
        </div>
      </div>

      
      <!-- Vitamins & Minerals Section -->
      <div class="vitamins">
        <div *ngFor="let vitamin of nutritionLabel">
          <span *ngIf="isVitaminOrMineral(vitamin.nutrientName)" class="vitamin-line">
            <span class="vitamin-name">{{ vitamin.nutrientName }} {{ vitamin.nutrientAmount }} {{ vitamin.unit }}</span>
            <span class="dv-amount" *ngIf="vitamin.percentage">{{ vitamin.percentage }}%</span>
          </span>
        </div>
      </div>

      <!-- Footnote Section -->
      <div class="footnote">
        * The % Daily Value (DV) tells you how much a nutrient in a serving contributes to a daily diet.
        2,000 calories a day is used for general nutrition advice.
      </div>
    </div>




     <!-- FDA Nutrition Label Display Voluntary Nutrition -->
     <div *ngIf="showFDA_VolNut && nutritionLabel.length > 0" class="nutrition-label">
      <h1>Nutrition Facts</h1>
      <!-- Thin Line Below Nutrition Facts -->
      <div class="vitamin-line"></div>
      <!-- Serving Info -->
      <div class="serving-info">
        <span class="servings-per-container">{{ numberOfServings }} servings per container</span><br />
        <div style="display: flex; justify-content: space-between">
          <span class="bold serving-size">Serving size</span>
          <span class="bold serving-weight">{{ servingSize }}g</span>
        </div>
      </div>

      <!-- Calories Section -->
      <div class="calories-header">Amount per serving</div>
      <div class="calories">
        <span class="calories-text">Calories </span>
        <span class="calories-number">{{ totalCalories }}</span>
      </div>
      <hr class="thin-rule" />


      <!-- Nutrients Section -->
      <div *ngFor="let nutrient of nutritionLabel">
        <div class="nutrient-line" *ngIf="!isVitaminOrMineral(nutrient.nutrientName)" [ngClass]="{
      'bold-nutrient': isMajorNutrient(nutrient.nutrientName),
      'sub-nutrient': nutrient.isSubNutrient,
      'sub-sub-nutrient': nutrient.isSubSubNutrient
    }">
          <!-- Special Formatting for "Trans Fat" -->
          <span *ngIf="nutrient.nutrientName === 'Trans Fat'">
            <i>{{ nutrient.nutrientName.split(' ')[0] }}</i>
            {{ nutrient.nutrientName.substring(nutrient.nutrientName.indexOf(' ') + 1) }}
            {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
          </span>

          <!-- Special Formatting for "Added Sugars" -->
          <span *ngIf="nutrient.nutrientName === 'Added Sugars' && nutrient.isSubSubNutrient">
            Includes {{ nutrient.nutrientAmount }} {{ nutrient.unit }} {{ nutrient.nutrientName }}
          </span>

          <!-- Normal Display for Other Nutrients -->
          <span *ngIf="nutrient.nutrientName !== 'Trans Fat' && nutrient.nutrientName !== 'Added Sugars'">
            {{ nutrient.nutrientName }} {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
          </span>

          <!-- Daily Value Percentage -->
          <span class="dv-amount" *ngIf="nutrient.percentage !== '% Daily Value*' && !isNoDV(nutrient.nutrientName)">
            {{ nutrient.percentage }}%
          </span>
          <span class="dv-amount" *ngIf="nutrient.percentage === '% Daily Value*'">
            {{ nutrient.percentage }}
          </span>

          <!-- Notes -->
          <!-- <span *ngIf="nutrient.note">{{ nutrient.note }}</span>-->
        </div>
      </div>

      
      <!-- Vitamins & Minerals Section -->
      <div class="vitamins">
        <div *ngFor="let vitamin of nutritionLabel">
          <span *ngIf="isVitaminOrMineral(vitamin.nutrientName)" class="vitamin-line">
            <span class="vitamin-name">{{ vitamin.nutrientName }} {{ vitamin.nutrientAmount }} {{ vitamin.unit }}</span>
            <span class="dv-amount" *ngIf="vitamin.percentage">{{ vitamin.percentage }}%</span>
          </span>
        </div>
      </div>

      <!-- Footnote Section -->
      <div class="footnote">
        * The % Daily Value (DV) tells you how much a nutrient in a serving contributes to a daily diet.
        2,000 calories a day is used for general nutrition advice.
      </div>
    </div>

  </mat-tab>
</mat-tab-group>