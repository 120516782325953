<div class="forgot-password-container">
  <h2>Forgot Password</h2>

  <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="email">Enter your email address</label>
      <input type="email" id="email" formControlName="email" class="form-control" placeholder="Email" />
      <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
        <span *ngIf="email.errors?.required">Email is required.</span>
        <span *ngIf="email.errors?.email">Please enter a valid email address.</span>
      </div>
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="forgotPasswordForm.invalid">Reset Password</button>

    <div *ngIf="message" class="success-message">
      {{ message }}
    </div>
  </form>
</div>
