import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { BrandedFoods, foodList, FoodNutrient, Foods, FoundationFood, FoundationFoods, NutrientFoodDTO, SRFoods, SurveyFoods } from './food-data';
import { Observable } from 'rxjs/internal/Observable';
import { __values } from 'tslib';

@Injectable({
  providedIn: 'root',
})

export class FoodNutrientsService {
  constructor(private httpClient: HttpClient) { }
  url_nutrients = 'https://ai-basil.com/v1/foods/nutrients';
  url_nutrient_foods ='https://ai-basil.com/v1/foods/all/nutrients';

  url_branded_food_nutrients = 'https://ai-basil.com/v1/foods/branded';
  url_sr_food_nutrients = 'https://ai-basil.com/v1/foods/sr';
  url_survey_food_nutrients = 'https://ai-basil.com/v1/foods/survey';
  url_foundation_food_nutrients = 'https://ai-basil.com/v1/foods/foundation';
  //url_sr_food_nutrients = 'https://ai-basil.com/v1/foods/branded';
  //url_foundation_food_nutrients = 'https://ai-basil.com/v1/foods/branded';
  
  
  url_branded_food_descriptions = 'https://ai-basil.com/v1/foods/branded-foods/stream';
  //url_foundation_food_descriptions = 'https://ai-basil.com/v1/foods/foundation-foods/stream';
  url_foundation_food_descriptions = 'https://ai-basil.com/v1/foods/foundation-foods/stream';
  //url_SR_food_descriptions = 'https://ai-basil.com/v1/foods/sr-foods/stream';
url_SR_food_descriptions = 'https://ai-basil.com/v1/foods/sr-foods/stream';
url_Survey_food_descriptions = 'https://ai-basil.com/v1/foods/survey-foods/stream';

  
  
  
  url_branded_food_details = 'https://ai-basil.com/v1/foods/branded-foods/all/stream';
 // url_foundation_food_details = 'https://ai-basil.com/v1/foods/foundation-foods/all/stream';
 // url_SR_food_details = 'https://ai-basil.com/v1/foods/SR-foods/all/stream';

  url_foundation_food_details = 'https://ai-basil.com/v1/foods/foundation-foods/all/stream';
  url_SR_food_details = 'https://ai-basil.com/v1/foods/sr-foods/all/stream';
  url_survey_food_details = 'https://ai-basil.com/v1/foods/survey-foods/all/stream';

  
  //url_nutrients = 'http://localhost:8080/v1/foods/nutrients';
  //url_branded_food_nutrients = 'http://localhost:8080/v1/foods/branded';
  //url_branded_food_descriptions = 'http://localhost:8080/v1/foods/branded-foods/stream';
  //url_branded_food_details = 'http://localhost:8080/v1/foods/branded-foods/all/stream';
  url_nutrient_names: 'https://ai-basil.com/v1/foods/nutrients/name';


  // Get nutrients by food name
  /* getNutrientsByFoodName(foodName: string): Observable<Foods> {
    const params = new HttpParams().set('foodName', foodName);
    return this.httpClient.get<Foods>(this.url_nutrients, { params });
  } */
  getNutrients(foodName: string): Observable<Foods> {   
    const encodedFoodItem = encodeURIComponent(foodName);

    return this.httpClient.get<Foods>(`${this.url_nutrients}/${encodedFoodItem}`);
  }


// Function to get nutrients for a given food description
getBrandedFoodNutrients(foodDescription: string): Observable<FoodNutrient[]> {
  // Encode the food description for use in the URL
  const encodedFoodDescription = encodeURIComponent(foodDescription);

  // Make the GET request and return the observable
  return this.httpClient.get<FoodNutrient[]>(`${this.url_branded_food_nutrients}/${encodedFoodDescription}`);
}
// Function to get nutrients for a given food description
getSurveyFoodNutrients(foodDescription: string): Observable<FoodNutrient[]> {
  // Encode the food description for use in the URL
  const encodedFoodDescription = encodeURIComponent(foodDescription);

  // Make the GET request and return the observable
  return this.httpClient.get<FoodNutrient[]>(`${this.url_survey_food_nutrients}/${encodedFoodDescription}`);
}

// Function to get nutrients for a given food description
getFoundationFoodNutrients(foodDescription: string): Observable<FoodNutrient[]> {
  // Encode the food description for use in the URL
  const encodedFoodDescription = encodeURIComponent(foodDescription);

  // Make the GET request and return the observable
  return this.httpClient.get<FoodNutrient[]>(`${this.url_foundation_food_nutrients}/${encodedFoodDescription}`);
}


// Function to get nutrients for a given food description
getSRFoodNutrients(foodDescription: string): Observable<FoodNutrient[]> {
  // Encode the food description for use in the URL
  const encodedFoodDescription = encodeURIComponent(foodDescription);

  // Make the GET request and return the observable
  return this.httpClient.get<FoodNutrient[]>(`${this.url_sr_food_nutrients}/${encodedFoodDescription}`);
}


 // Function to get all food descriptions in a stream
 getBrandedFoodDescriptionsStream(): Observable<string[]> {
  return this.httpClient.get<string[]>(this.url_branded_food_descriptions);  // Stream food descriptions from the backend
}

// Function to get all food descriptions in a stream
getFoundationFoodDescriptionsStream(): Observable<string[]> {
  return this.httpClient.get<string[]>(this.url_foundation_food_descriptions);  // Stream food descriptions from the backend
}

// Function to get all food descriptions in a stream
getSRFoodDescriptionsStream(): Observable<string[]> {
  return this.httpClient.get<string[]>(this.url_SR_food_descriptions);  // Stream food descriptions from the backend
}

// Function to get all food descriptions in a stream
getSurveyFoodDescriptionsStream(): Observable<string[]> {
  return this.httpClient.get<string[]>(this.url_Survey_food_descriptions);  // Stream food descriptions from the backend
}


// Function to get branded food details in a stream
getBrandedFoodDetails(description: string): Observable<BrandedFoods> {
  return this.httpClient.get<BrandedFoods>(`${this.url_branded_food_details}/${description}`);
}

// Function to get branded food details in a stream
getFoundationFoodDetails(description: string): Observable<FoundationFood> {
  return this.httpClient.get<FoundationFood>(`${this.url_foundation_food_details}/${description}`);
}


// Function to get branded food details in a stream
getSurveyFoodDetails(description: string): Observable<SurveyFoods> {
  return this.httpClient.get<SurveyFoods>(`${this.url_survey_food_details}/${description}`);
}

// Function to get branded food details in a stream
getSRFoodDetails(description: string): Observable<SRFoods> {
  return this.httpClient.get<SRFoods>(`${this.url_SR_food_details}/${description}`);
}
getFoodsByNutrientName(nutrientName: string): Observable<NutrientFoodDTO[]> {
  const params = new HttpParams().set('nutrientName', nutrientName);
  return this.httpClient.get<NutrientFoodDTO[]>(this.url_nutrient_foods, { params });
}


//chnages
getNutrientNames(): Observable<string[]> {
  return this.httpClient.get<string[]>('https://ai-basil.com/v1/foods/nutrients/name');
}

}