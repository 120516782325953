<div class="about-us-container">
  <div class="nutrition-info">
    <p class="intro">
      <strong>Complete nutrition</strong> is vital for enhancing the quality and longevity of our lives. However, gaining this knowledge organically is challenging, as much of what we understand about nutrition stems from childhood experiences and societal influences. At <strong>AI-Basil</strong>, we aim to change that narrative by providing a platform that bridges the gap between science and everyday nutrition choices.
    </p>
    
    <p class="aspiration">
      <strong>AI-Basil</strong> is the ultimate platform for food and nutrition, grounded in research and powered by AI. We serve both businesses and consumers in the United States, offering solutions that enable everyone to make better, informed nutrition choices.
    </p>
  </div>

  <section class="about-mission">
    <h2>Our Mission</h2>
    <p>
      "At AI-Basil, our mission is to foster a healthier planet and enhance well-being by equipping businesses and consumers with innovative technology solutions to make informed nutrition decisions."
    </p>
  </section>

  <section class="about-offer">
    <h2>What We Offer</h2>
    <div class="card-list">
        <div class="card">
            <h3>Nutrition Label Creator (Beta)</h3>
            <p>Easily generate FDA-compliant nutrition labels for your recipes or ingredients, ensuring accuracy and adherence to regulations.</p>
        </div>
        <div class="card">
            <h3>Personalized Nutrition (DRIs)</h3>
            <p>Receive tailored insights into your Dietary Reference Intakes based on your age, gender, and lifestyle, helping you make more informed dietary decisions.</p>
        </div>
        <div class="card">
            <h3>Essential Nutrient Sources</h3>
            <p>Discover foods that are rich in essential nutrients and learn about their health benefits.</p>
        </div>
        <div class="card">
            <h3>Food Source Finder</h3>
            <p>Access the nutritional values of a wide variety of foods, powered by the USDA National Nutrient Database, to better understand what you're eating.</p>
        </div>
    </div>
    <p>
        At AI-Basil, our offerings continue to grow and evolve. Today's solutions are just the beginning. We are committed to expanding our platform to bring even more innovative and personalized tools that cater to your evolving nutrition needs. Together, we can build a future where everyone has the knowledge and resources to make healthier choices.
      Have any questions? Reach us at <a href="mailto:query&#64;ai-basil.com">query&#64;ai-basil.com</a>.
    </p>
</section>

</div>
