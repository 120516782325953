<!-- landing-page.component.html -->
<div class="landing-page">

  <!-- Login Section -->
  <div class="login-section">
    <div class="login-form">
      <blockquote>"Know your Food."</blockquote>

      <h2>Login</h2>
      <form class="form-group" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <input name="email" type="email" formControlName="email" placeholder="Email">
        </div>
        <div class="password-container">
          <input [type]="showPassword ? 'text' : 'password'" id="password" formControlName="password" placeholder="Password">
          <span (click)="togglePasswordVisibility()" class="eye-icon">
            <i class="fas" [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
          </span>
        </div>

        <div class="form-group forgot-password">
          <a (click)="onForgotPassword()" class="forgot-password-link">Forgot Password?</a>
        </div>

        <button type="submit" class="btn">Submit</button>
      </form>

      <div class="signup-text">
        <span class="signup-message">Don't have an account? </span>
        <a routerLink="/signup" class="signup-link">Sign up</a>
      </div>
    </div>
  </div>
</div>
