import { Component } from '@angular/core';
import { OllamaService } from '../ollama.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'app-ollama-chat',
  standalone: true,
  imports: [FormsModule, CommonModule,MatIconModule ],
  templateUrl: './ollama-chat.component.html',
  styleUrls: ['./ollama-chat.component.css']
})
export class OllamaChatComponent {
  userPrompt: string = '';
  response: string = '';

  constructor(private ollamaService: OllamaService) {}

  /* sendPrompt() {
    // Clear previous response to start fresh for new prompt
    this.response = '';
  
    // Call the Ollama service to get the response based on the user's prompt
    this.ollamaService.queryOllama(this.userPrompt).subscribe({
      next: (data: string) => {
        console.log("Received Raw Data:", data); // Log raw response for debugging
  
        // Split the data by newline (assuming each JSON chunk is separated by a newline)
        const chunks = data.split("\n");
  
        // Loop through each chunk of the response data
        chunks.forEach(chunk => {
          if (chunk.trim()) { // Ignore empty chunks
            try {
              // Parse each chunk individually
              let responseObj = JSON.parse(chunk);
  
              // Check if the response object has a valid 'response' field
              if (responseObj && responseObj.response) {
                // Concatenate the current chunk's response to the full response string
                this.response = this.response ? this.response + responseObj.response : responseObj.response;
                console.log("Full Response So Far:", this.response);
              }
            } catch (error) {
              console.error("Error parsing JSON chunk:", error);
              console.error("Chunk that failed parsing:", chunk);
            }
          }
        });
  
        // Handle 'done' field if necessary, to determine when the response is complete
        if (data.includes('"done":true')) {
          console.log("Completed Response:", this.response);
        }
      },
      error: (error) => {
        // Handle error in communication with Ollama
        console.error("Error communicating with Ollama:", error);
        this.response = 'Error communicating with Ollama';
      }
    });
  } */
  
  
    sendPrompt() {
      // Clear previous response
      this.response = '';
    
      this.ollamaService.queryOllama(this.userPrompt).subscribe({
        next: (data: string) => {
          console.log("Received Raw Data:", data);
    
          const chunks = data.split("\n");
    
          chunks.forEach(chunk => {
            if (chunk.trim()) {
              try {
                let responseObj = JSON.parse(chunk);
                if (responseObj && responseObj.response) {
                  // Convert newlines to <br> for better readability
                  let formattedText = responseObj.response.replace(/\n/g, "<br>");
    
                  // Accumulate formatted response
                  this.response += formattedText;
                  console.log("Formatted Response So Far:", this.response);
                }
              } catch (error) {
                console.error("Error parsing JSON chunk:", error);
                console.error("Chunk that failed parsing:", chunk);
              }
            }
          });
    
          if (data.includes('"done":true')) {
            console.log("Completed Response:", this.response);
          }
        },
        error: (error) => {
          console.error("Error communicating with Ollama:", error);
          this.response = '<span style="color:red;">Error communicating with Ollama</span>';
        }
      });
    }
     
  
  
  
  
}
