<!-- <div>
    <h2>Ask your AI-Basil Nutrition Agent</h2>
    <input [(ngModel)]="userPrompt" placeholder="Enter your question..." />
    <button (click)="sendPrompt()">Ask</button>
    <div *ngIf="response">
      <h3>Response:</h3>
      <p>{{ response }}</p>
    </div>
  </div> -->
  <div class="chat-container">
    <h2 class="chat-header">
      <mat-icon class="chat-icon">chat</mat-icon> Ask your AI-Basil Nutrition Agent
    </h2>
  
    <div class="chat-box">
      <input matInput [(ngModel)]="userPrompt" placeholder="Enter your question..." class="chat-input" />
      <button mat-raised-button color="primary" (click)="sendPrompt()" class="chat-button">
        <mat-icon>send</mat-icon>
      </button>
    </div>


    <div *ngIf="response" class="chat-response">
      <h3>Response:</h3>
      <p [innerHTML]="response"></p> <!-- Use innerHTML to preserve formatting -->
    </div>
    
  </div>
  