
export interface Foods {
  foodId: number;
  foodName: string;
  foodCategory: {
    categoryName: string;
  };
  nutrients: Nutrients[];
}

export interface BrandedFoods {
  description: string;
  brandedFoodCategory: string;
  marketCountry: string;
  brandOwner: string;
  ingredients: string;
  packageWeight: string;
  servingSize: string;
  servingSizeUnit: string;
  availableDate: string;
}
export interface FoundationFood {
  fdcid: string,
  foodDescription: string,
  categoryDescription: string
}

export interface SRFoods {
  fdcid: number,
  foodDescription: string,
  categoryDescription: string
}

export interface SurveyFoods {
  fdcid: number,
  foodDescription: string,
  categoryDescription: string
}
export interface FoodNutrient {
  id: number;          // The unique ID for the food nutrient
  fdcId: string;       // Food Data Center (FDC) ID for the food item
  nutrient: BrandedFoodNutrient;  // The associated Nutrient object
  amount: number;      // The amount of the nutrient present in the food
  dataPoints: string;  // The number of data points available for the nutrient
  derivationId: string; // The ID of the method used to derive the data
  min: string;         // Minimum value for the nutrient in the food item
  max: string;         // Maximum value for the nutrient in the food item
  median: string;      // Median value for the nutrient in the food item
  footnote: string;    // Additional footnote information for the nutrient
  minYearAcquired: string; // The minimum year the data was acquired
}

export interface BrandedFoodNutrient {
  id: number;        // Corresponds to the primary key (ID) of the nutrient
  name: string;      // Name of the nutrient (e.g., "Vitamin C")
  unitName: string;  // Unit of measurement for the nutrient (e.g., "mg")
  nutrientNbr: string; // A unique identifier or number for the nutrient
  rank: string;      // Ranking or order of the nutrient
}



export interface Recipe {
    id: number;
  name: string;
  numberOfServings: number;
  servingSize: number;
  ingredients: { name: string; amount: number }[]
}
export interface Nutrients {
  nutrientName: string;
  nutrientAmount: number;
  unit: string;
  portionSize: string;
  portionSizeUnit: string;
}
export interface Ingredient {
  ingredient: string;
  amount: number; // Amount in grams
}

export interface MacroNutrients {
  age: number,
  lsgroup: string,
  carbohydrate: number,
  fiber: number,
  fat: number,
  linoleicacid: number,
  alphalinolenicacid: number,
  protein: number,
}
export interface Water {
  age: number,
  lsgroup: string,
  water: number
}

export interface Vitamins {
  age: number,
  lsgroup: string,
  vitamina: number,
  vitaminc: number,
  vitamind: number,
  vitamine: number,
  vitamink: number,
  thiamin: number,
  riboflavin: number,
  niacin: number,
  vitaminb6: number,
  folate: number,
  vitaminb12: number,
  pantothenicacid: number,
  biotin: number,
  choline: number
}

export interface Elements {
  age: number,
  lsgroup: string,
  calcium: number,
  copper: number,
  chromium: number,
  fluoride: number,
  iodine: number,
  iron: number,
  magnesium: number,
  manganese: number,
  molybdenum: number,
  phosphorus: number,
  selenium: number,
  zinc: number,
  potassium: number,
  sodium: number,
  chloride: number
}
export interface Food {
  fdcId: number,
  description: string,
  dataType: string,
  publicationDate: string,
  ndbNumber: string,
  foodNutrients: Nutrient[]
}

export interface Nutrient {
  number: string,
  name: string,
  amount: number,
  unitName: string,
  derivationCode: string,
  derivationDescription: string
}

export interface Nutrient_Name {
  name: string,
  nutrient_sources: Nutrient_Source[]
}

export interface Nutrient_Source {
  description: string,
  amount: number,
  unitName: string
}
export interface FoundationFoods {
  id: number,
  fdcid: number,
  identifier: string,
  identifiertype: string,
  foodtype: string,
  fooddescription: string,
  servingweight: number,
  servingsizeweightunit: number,
  servingsize: string,
  perservingsizeing: string,
  per100unitservinging: number,
  foodcategory: string
}



export interface FoundationFoodsWithUnit {
  index: number,
  fooddescription: string,
  perservingsizeing: string,
  per100unitservinging: number,
  foodcategory: string,
  unitname: string
}


export interface NutrientFoodDTO {
  nutrientName: string;
  foodName: string;
  unitName: string;
  amount: string;
  foodCategoryDescription: string;
}


export interface Unit {
  unitname: string;
}

export interface DRI {
  age: number,
  lsgroup: string,
  water: number,
  carbohydrate: number,
  fiber: number,
  fat: number,
  linoleicacid: number,
  alphalinolenicacid: number,
  protein: number,
  calcium: number,
  copper: number,
  chromium: number,
  fluoride: number,
  iodine: number,
  iron: number,
  magnesium: number,
  manganese: number,
  molybdenum: number,
  phosphorus: number,
  selenium: number,
  zinc: number,
  potassium: number,
  sodium: number,
  chloride: number,
  vitamina: number,
  vitaminc: number,
  vitamind: number,
  vitamine: number,
  vitamink: number,
  thiamin: number,
  riboflavin: number,
  niacin: number,
  vitaminb6: number,
  folate: number,
  vitaminb12: number,
  pantothenicacid: number,
  biotin: number,
  choline: number
}

export interface foodList {
  water: string,
  carbohydrate: string,
  fiber: string,
  fat: string,
  linoleicacid: string,
  alphalinolenicacid: string,
  protein: string,
  calcium: string,
  copper: string,
  chromium: string,
  fluoride: string,
  iodine: string,
  iron: string,
  magnesium: string,
  manganese: string,
  molybdenum: string,
  phosphorus: string,
  selenium: string,
  zinc: string,
  potassium: string,
  sodium: string,
  chloride: string,
  vitamina: string,
  vitaminc: string,
  vitamind: string,
  vitamine: string,
  vitamink: string,
  thiamin: string,
  riboflavin: string,
  niacin: string,
  vitaminb6: string,
  folate: string,
  vitaminb12: string,
  pantothenicacid: string,
  biotin: string,
  choline: string
}

export class CartItem {
  // index!: number;
  id!: number;
  name!: string;
  quantity!: number;
  price!: number;
  category!: string
}

export class User {
  id!: number;
  email!: string;
  password!: string;
  turnstileToken?: string;
  name!: string;
  enabled!: boolean;
  verificationCode!: string;
  state!: string;
  country!: string;
  companyName!: string;
  role!: string;
  signupGoal!: string;
  premium!: boolean;
  squareCardToken?: string;
  recipes!: Recipe[];
  cart!: CartItem[];
}



