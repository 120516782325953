<div class="edit-profile-container">
  <h2>Edit Profile</h2>
  <form (submit)="onSaveChanges()">
    <label for="name">Name:</label>
    <input type="text" id="name" [(ngModel)]="user.name" name="name" required>

    <label for="email">Email:</label>
    <input type="email" id="email" [(ngModel)]="user.email" name="email" required>

    <label for="company">Company Name:</label>
    <input type="text" id="company" [(ngModel)]="user.companyName" name="company">

    <label for="state">State:</label>
    <input type="text" id="state" [(ngModel)]="user.state" name="state">

    <label for="country">Country:</label>
    <input type="text" id="country" [(ngModel)]="user.country" name="country">

    <button type="submit" class="btn-save">Save Changes</button>
  </form>
</div>
