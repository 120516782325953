<!--app-admin></app-admin-->

<mat-tab-group animationDuration=0 mat-align-tabs="start">

  <mat-tab label="Nutrients" *ngIf="!isPremiumUser || isPremiumUser">
    <!--<div class="input-container">-->
      <!--<input list="food" [(ngModel)]="inputValue" placeholder="Protein, Iodine, Iron..." class="input-field"
        name="foodList" #filter />-->

     <!--  <mat-form-field>
        <input matInput placeholder="Protein, Iodine, Iron......" [(ngModel)]="inputValue"
          (input)="filterResults(inputValue)" list="food">
        <mat-label> Search by Nutrient </mat-label>
        <datalist id="food">
          <option value="Protein"></option>
          <option value="Carbohydrate"></option>
          <option value="Fiber"></option>
          <option value="SaturatedFats"></option>
          <option value="MonoUnsaturatedFats"></option>
          <option value="PolyUnsaturatedFats"></option>
          <option value="TransFats"></option>
          <option value="Cholestrol"></option>
          <option value="Water"></option>
          <option value="Biotin"></option>
          <option value="Boron"></option>
          <option value="Calcium"></option>
          <option value="CaroteneAlpha"></option>
          <option value="CaroteneBeta"></option>
          <option value="CaroteneGamma"></option>
          <option value="Chlorine"></option>
          <option value="Cholinefree"></option>
          <option value="CholineFromPhosphocholineMg"></option>
          <option value="CholineFromPhosphotidylCholineMg"></option>
          <option value="CholineFromSphingomyelinMg"></option>
          <option value="CholineFromGlycerophosphocholineMg"></option>
          <option value="CholineTotal"></option>
          <option value="Chromium"></option>
          <option value="Copper"></option>
          <option value="Fluoride"></option>
          <option value="FolateDFE"></option>
          <option value="FolateFood"></option>
          <option value="FolateTotal"></option>
          <option value="Iodine"></option>
          <option value="Iron"></option> 
          <option value="Magnesium"></option>
          <option value="Manganese"></option>
          <option value="Molybdenum"></option>
         <option value="Niacin"></option>
          <option value="Nickel"></option>
           <option value="PantothenicAcid"></option>
           <option value="Phosphorus"></option>
           <option value="Potassium"></option>
          <option value="Riboflavin"></option>
          <option value="Selenium"></option>
          <option value="Sodium"></option>
          <option value="Sulfur"></option>
          <option value="Thiamin"></option>
          <option value="VitaminA_RAE"></option>
          <option value="VitaminAIU"></option>
          <option value="VitaminB12Added"></option>
          <option value="VitaminB12"></option>
          <option value="VitaminB6"></option>
          <option value="VitaminC"></option>
          <option value="VitaminD_D2PlusD3IU"></option>
          <option value="VitaminD_D2PlusD3"></option>
          <option value="VitaminD2"></option>
          <option value="VitaminD3"></option>
          <option value="VitaminD4"></option>
          <option value="VitaminE_Added"></option>
          <option value="VitaminE_AlphaTocopherol"></option>
          <option value="VitaminELabelEntryIU"></option>
          <option value="VitaminEMcgRE"></option>
          <option value="VitaminK_Dihydrophylloquinone"></option>
          <option value="VitaminK_Menaquinone4"></option>
          <option value="VitaminK_Phylloquinone"></option>
          <option value="Zinc"></option>
        </datalist>
      </mat-form-field> -->
      <!-- <div class="container">
        <h2>Search Foods by Nutrient</h2>
        <div class="input-group">
          <input
            type="text"
            [(ngModel)]="nutrientName"
            placeholder="Enter nutrient name"
            class="form-control"
          />
          <button (click)="fetchFoods()" class="btn btn-primary">Search</button>
        </div>
      
        <div *ngIf="loading" class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      
        <div *ngIf="error" class="alert alert-danger">
          {{ error }}
        </div>
      
        <div *ngIf="foodList.length > 0">
          <table class="table">
            <thead>
              <tr>
                <th>Nutrient</th>
                <th>Food Name</th>
                <th>Unit</th>
                <th>Amount</th>
                <th>Food Category</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let food of foodList">
                <td>{{ food.nutrientName }}</td>
                <td>{{ food.foodName }}</td>
                <td>{{ food.unitName }}</td>
                <td>{{ food.amount }}</td>
                <td>{{ food.foodCategoryDescription }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="foodList.length === 0 && !loading">
          <p>No foods found for the given nutrient.</p>
        </div>
      </div> -->

      <div class="container">
        <h2>Search Foods by Nutrient</h2>
      
        <!-- Input Field with Auto-Complete List -->
        <div class="input-container">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Search by Nutrient</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="nutrientName"
              placeholder="Enter nutrient name"
              class="form-control"
              list="nutrientsList"
            />
            <datalist id="nutrientsList">
              <option *ngFor="let name of nutrientNamesForDropdown" [value]="name"></option>
            </datalist>
          </mat-form-field>
          
          <div class="input-group">
            <button (click)="fetchFoods()" class="btn btn-primary">Search</button>
          </div>
        </div>
      
        <!-- Spinner for Loading State -->
        <div *ngIf="loading" class="spinner-container">
          <mat-spinner></mat-spinner>
        </div>
      
        <!-- Error Message -->
        <div *ngIf="error" class="alert alert-danger">
          {{ error }}
        </div>
      
        <!-- Filter Dropdown -->
        <!-- <div class="filter-container">
          <mat-form-field appearance="fill">
            <mat-label>Food Category</mat-label>
            <mat-select [(value)]="selectedCategory" (selectionChange)="applyFilter()">
              <mat-option *ngFor="let category of filteredCategories" [value]="category">{{ category }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <button class="chat-button" mat-fab matTooltip="Chat with us!" (click)="openChat()">
          <mat-icon>chat</mat-icon>
        </button>
        
        <div class="filter-container">
          <!-- Food Category Filter -->
          <mat-form-field appearance="fill">
            <mat-label>Food Category</mat-label>
            <mat-select [(value)]="selectedCategory" (selectionChange)="applyFilter()">
              <mat-option value="">All Foods</mat-option> <!-- Option for all foods -->
              <mat-option *ngFor="let category of filteredCategories" [value]="category">
                {{ category }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
        <!-- Displaying Results in Mat-Table -->
        <section class="results" *ngIf="foodList">
          <table mat-table [dataSource]="dataSourceNutrient" matSort class="mat-elevation-z8 custom-table">
          
            <!-- Food Name Column -->
            <ng-container matColumnDef="foodName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Food Name </th>
              <td mat-cell *matCellDef="let food"> {{ food.foodName }} </td>
            </ng-container>
        
            <!-- Unit Column -->
            <ng-container matColumnDef="unitName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit </th>
              <td mat-cell *matCellDef="let food"> {{ food.unitName }} </td>
            </ng-container>
        
            <!-- Amount Column with Sorting -->
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc"> Amount </th>
              <td mat-cell *matCellDef="let food"> {{ food.amount }} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumnsNutrient"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsNutrient;"></tr>
          </table>
        
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </section>
        
        <!-- No Results Message -->
        <div *ngIf="foodList.length === 0 && !loading">
          <p>No foods found for the given nutrient.</p>
        </div>
        
      </div>
      
      

      <!-- Clear button that appears when there's input text -->
      <!--<button *ngIf="inputValue" class="clear-button" (click)="clearInput()">
        &#10006;
      </button>
      <datalist id="food">
        <option value="Protein">
        <option value="Carbohydrate">
        <option value="Fiber">
        <option value="SaturatedFats">
        <option value="MonoUnsaturatedFats">
        <option value="PolyUnsaturatedFats">
        <option value="TransFats">
        <option value="Cholestrol">
        <option value="Water">
        <option value="Biotin">
        <option value="Boron">
        <option value="Calcium">
        <option value="CaroteneAlpha">
        <option value="CaroteneBeta">
        <option value="CaroteneGamma">
        <option value="Chlorine">
        <option value="Cholinefree">
        <option value="CholineFromPhosphocholineMg">
        <option value="CholineFromPhosphotidylCholineMg">
        <option value="CholineFromSphingomyelinMg">
        <option value="CholineFromGlycerophosphocholineMg">
        <option value="CholineTotal">
        <option value="Chromium">
        <option value="Copper">
        <option value="Fluoride">
        <option value="FolateDFE">
        <option value="FolateFood">
        <option value="FolateTotal">
        <option value="Iodine">
        <option value="Iron">
        <option value="Magnesium">
        <option value="Manganese">
        <option value="Molybdenum">
        <option value="Niacin">
        <option value="Nickel">
        <option value="PantothenicAcid">
        <option value="Phosphorus">
        <option value="Potassium">
        <option value="Riboflavin">
        <option value="Selenium">
        <option value="Sodium">
        <option value="Sulfur">
        <option value="Thiamin">
        <option value="VitaminA_RAE">
        <option value="VitaminAIU">
        <option value="VitaminB12Added">
        <option value="VitaminB12">
        <option value="VitaminB6">
        <option value="VitaminC">
        <option value="VitaminD_D2PlusD3IU ">
        <option value="VitaminD_D2PlusD3">
        <option value="VitaminD2">
        <option value="VitaminD3">
        <option value="VitaminD4">
        <option value="VitaminE_Added">
        <option value="VitaminE_AlphaTocopherol">
        <option value="VitaminELabelEntryIU">
        <option value="VitaminEMcgRE ">
        <option value="VitaminK_Dihydrophylloquinone">
        <option value="VitaminK_Menaquinone4 ">
        <option value="VitaminK_Phylloquinone">
        <option value="Zinc">
      </datalist>-->
   <!-- </div>-->
    <!-- Search button -->

    <!--<button class="primary" type="button" (click)="filterResults(filter.value)"> Search </button>
  -->
    <!--div class="filter-container"-->
    <!--input matInput (keyup)="applyFilter($event)" placeholder="Filter by category"-->
    <!--/div-->

    <!--  <div class="filter-container">
      <mat-form-field appearance="fill">
        <mat-label>Diet Type</mat-label>
        <mat-select [(value)]="selectedDiet" (selectionChange)="applyFilter()">
          <mat-option value="">All Diets</mat-option>
          <mat-option value="vegan">Vegan</mat-option>
          <mat-option value="vegetarian">Vegetarian</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Food Category</mat-label>
        <mat-select [(value)]="selectedCategory" (selectionChange)="applyFilter()">
          <mat-option *ngFor="let category of filteredCategories" [value]="category">{{ category }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
 -->
 

   <!--  <section class="results">
      <table mat-table [dataSource]="filteredDataSource" class="mat-elevation-z8 custom-table">
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef> Index </th>
          <td mat-cell *matCellDef="let food "> {{food.index}} </td>
        </ng-container>

        <ng-container matColumnDef="fooddescription">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let food"> {{food.fooddescription}} </td>
        </ng-container>

        <ng-container matColumnDef="foodcategory">
          <th mat-header-cell *matHeaderCellDef> Category </th>
          <td mat-cell *matCellDef="let food"> {{food.foodcategory}} </td>
        </ng-container>

        <ng-container matColumnDef="perservingsizeing">
          <th mat-header-cell *matHeaderCellDef> Serving Size </th>
          <td mat-cell *matCellDef="let food"> {{food.perservingsizeing}} </td>
        </ng-container>

        <ng-container matColumnDef="per100unitservinging">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let food"> {{food.per100unitservinging}} </td>
        </ng-container>

        <ng-container matColumnDef="unitname">
          <th mat-header-cell *matHeaderCellDef> Unit </th>
          <td mat-cell *matCellDef="let food"> {{food.unitname}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </section>  -->

  </mat-tab>
<!--   <mat-tab label="Pay"><app-square-payment></app-square-payment></mat-tab>
 -->
<!-- <mat-tab label="Chat"><app-ollama-chat></app-ollama-chat></mat-tab> -->
 <mat-tab label="DRI" *ngIf="isPremiumUser"><app-search-form></app-search-form></mat-tab>

  <mat-tab label="Search Food" *ngIf="!isPremiumUser || isPremiumUser">
    <!-- Loading spinner -->
    <mat-spinner *ngIf="isLoading" diameter="40"></mat-spinner>
    <div class="food-search">


      <!-- Input field for searching branded food -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Enter Branded Food Name</mat-label>
        <input matInput placeholder="Search branded food..." [(ngModel)]="brandedFoodName" list="brandedList"
          (input)="onSearchBrandedFood()" />
      <datalist id="brandedList">
          <option *ngFor="let description of brandedFoodDescriptions" [value]="description"></option>
        </datalist>
      </mat-form-field>

       <!-- Input field for searching branded food -->
           <mat-form-field appearance = "fill" class="full-width">
        <mat-label>Enter Foundation Food Name</mat-label>
        <input matInput placeholder="Search foundation food..." [(ngModel)]="foundationFoodName" list="foundationList"
          (input)="onSearchFoundationFood()" />
        <datalist id="foundationList">
          <option *ngFor="let description of foundationFoodDescriptions" [value]="description"></option>
        </datalist>
      </mat-form-field>


       <!-- Input field for searching branded food -->
       <mat-form-field appearance="fill" class="full-width">
        <mat-label>Enter Standard Reference Legacy Food Name</mat-label>
        <input matInput placeholder="Search Standard Reference food..." [(ngModel)]="srFoodName" list="SRList"
          (input)="onSearchSRFood()" />
        <datalist id="SRList">
          <option *ngFor="let description of SRFoodDescriptions" [value]="description"></option>
        </datalist>
      </mat-form-field>

      <!-- Input field for searching branded food -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Enter Survey Food Name</mat-label>
        <input matInput placeholder="Search Survey food..." [(ngModel)]="surveyFoodName" list="SurveyList"
          (input)="onSearchSurveyFood()" />
        <datalist id="SurveyList">
          <option *ngFor="let description of SurveyFoodDescriptions" [value]="description"></option>
        </datalist>
      </mat-form-field>


      <!-- Search Button -->
      <!--<button mat-raised-button color="primary" (click)="onSearchFood()">Search</button>-->
    </div>

    <!-- Display the nutrients info in the same tab -->
    <h3 *ngIf="foodData">Food Information</h3>
    <div *ngIf="foodData">
      <p><strong>Food Name:</strong> {{ foodData.foodName }}</p>
      <p><strong>Category:</strong> {{ foodData.foodCategory.categoryName }}</p>

      <h3>Nutrients Information</h3>
      <mat-table [dataSource]="foodData.nutrients" class="mat-elevation-z8" style="width: 100%">
        <!-- Nutrient Name Column -->
        <ng-container matColumnDef="nutrientName">
          <th mat-header-cell *matHeaderCellDef> Nutrient Name </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.nutrientName }} </td>
        </ng-container>

        <!-- Nutrient Amount Column -->
        <ng-container matColumnDef="nutrientAmount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.nutrientAmount }} </td>
        </ng-container>

        <!-- Unit Column -->
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef> Unit </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.unit }} </td>
        </ng-container>

        <!-- Portion Size Column -->
        <ng-container matColumnDef="portionSize">
          <th mat-header-cell *matHeaderCellDef> Portion Size </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.portionSize }} </td>
        </ng-container>

        <!-- Portion Size Unit Column -->
        <ng-container matColumnDef="portionSizeUnit">
          <th mat-header-cell *matHeaderCellDef> Portion Size Unit </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.portionSizeUnit }} </td>
        </ng-container>

        <!-- Table headers and rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
      </mat-table>
    </div>

    <!-- Display Branded Food Nutrients if brandedFoodData is available -->
    
      <div *ngIf="brandedFood">
        <p><strong>Food Name:</strong> {{ brandedFood?.description }}</p>
        <p><strong>Category:</strong> {{ brandedFood?.brandedFoodCategory }}</p>
        <p><strong>Market Country:</strong> {{ brandedFood?.marketCountry }}</p>
      <p><strong>Brand Owner:</strong> {{ brandedFood?.brandOwner }}</p>
        <p><strong>Ingredients:</strong> {{ brandedFood?.ingredients }}</p>
        <p><strong>Package Weight:</strong> {{ brandedFood?.packageWeight }}</p>
        <p><strong>Serving Size:</strong> {{ brandedFood?.servingSize }}</p>
        <p><strong>Serving Size Unit:</strong> {{ brandedFood?.servingSizeUnit }}</p>
        <p><strong>Available Date:</strong> {{ brandedFood?.availableDate }}</p>
      </div>
      <div *ngIf="brandedFoodData">
      <h3>Nutrients per 100{{ brandedFood?.servingSizeUnit }}</h3>
      <mat-table [dataSource]="brandedFoodData" class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="fdcId">
          <th mat-header-cell *matHeaderCellDef> FDC ID </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.fdcId }} </td>
        </ng-container>

        <ng-container matColumnDef="nutrientName">
          <th mat-header-cell *matHeaderCellDef> Nutrient Name </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.nutrient?.name }} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.amount }} </td>
        </ng-container>

        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef> Unit </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.nutrient?.unitName }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsBrandedFood"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsBrandedFood;"></tr>
      </mat-table>
    </div>

    <div *ngIf="foundationFood">
      <p><strong>Food Name:</strong> {{ foundationFood?.foodDescription }}</p>
      <p><strong>Category:</strong> {{ foundationFood?.categoryDescription }}</p>
    </div>
    <div *ngIf="foundationFoodData">
      <h3>Nutrients per 100 g</h3>
      <mat-table [dataSource]="foundationFoodData" class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="fdcId">
          <th mat-header-cell *matHeaderCellDef> FDC ID </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.fdcId }} </td>
        </ng-container>

        <ng-container matColumnDef="nutrientName">
          <th mat-header-cell *matHeaderCellDef> Nutrient Name </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.nutrient?.name }} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.amount }} </td>
        </ng-container>

        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef> Unit </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.nutrient?.unitName }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsBrandedFood"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsBrandedFood;"></tr>
      </mat-table>
    </div>
    <div *ngIf="SRFood">
      <p><strong>Food Name:</strong> {{ SRFood?.foodDescription }}</p>
      <p><strong>Category:</strong> {{ SRFood?.categoryDescription }}</p>
    </div>
    <div *ngIf="SRFoodData">
      <h3>Nutrients per 100 g</h3>
      <mat-table [dataSource]="SRFoodData" class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="fdcId">
          <th mat-header-cell *matHeaderCellDef> FDC ID </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.fdcId }} </td>
        </ng-container>

        <ng-container matColumnDef="nutrientName">
          <th mat-header-cell *matHeaderCellDef> Nutrient Name </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.nutrient?.name }} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.amount }} </td>
        </ng-container>

        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef> Unit </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.nutrient?.unitName }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsBrandedFood"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsBrandedFood;"></tr>
      </mat-table>
    </div>


    <div *ngIf="SurveyFood">
      <p><strong>Food Name:</strong> {{ SurveyFood?.foodDescription }}</p>
      <p><strong>Category:</strong> {{ SurveyFood?.categoryDescription }}</p>
    </div>
    <div *ngIf="surveyFoodData">
      <h3>Nutrients per 100 g</h3>
      <mat-table [dataSource]="surveyFoodData" class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="fdcId">
          <th mat-header-cell *matHeaderCellDef> FDC ID </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.fdcId }} </td>
        </ng-container>

        <ng-container matColumnDef="nutrientName">
          <th mat-header-cell *matHeaderCellDef> Nutrient Name </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.nutrient?.name }} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.amount }} </td>
        </ng-container>

        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef> Unit </th>
          <td mat-cell *matCellDef="let foodNutrient"> {{ foodNutrient.nutrient?.unitName }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsBrandedFood"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsBrandedFood;"></tr>
      </mat-table>
    </div>

    <!-- Nutrient Distribution Chart -->

  </mat-tab>
  <mat-tab label="Nutrition Label Generator (Beta)" *ngIf="isPremiumUser">
    <form>
      <!-- Recipe Name Input -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Enter Recipe Name</mat-label>
        <input matInput placeholder="Search recipe..." [(ngModel)]="foodName" name="foodName" list="recipes"
          (change)="onRecipeSelect(foodName)" />
        <datalist id="recipes">
          <option *ngFor="let recipe of recipeNames" [value]="recipe"></option>
        </datalist>
      </mat-form-field>

      <!-- Number of Servings -->
      <mat-form-field appearance="fill" class="half-width">
        <mat-label>Number of Servings</mat-label>
        <input matInput type="number" [(ngModel)]="numberOfServings" placeholder="E.g., 2" name="numberOfServings" />
      </mat-form-field>

      <!-- Serving Size -->
      <mat-form-field appearance="fill" class="half-width">
        <mat-label>Serving Size (g)</mat-label>
        <input matInput type="number" [(ngModel)]="servingSize" placeholder="E.g., 150" name="servingSize" />
      </mat-form-field>

      <!-- Ingredient Section -->
    <div class="ingredient-section">
        <!-- Ingredient Input -->
        <mat-form-field appearance="fill" class="half-width">
          <mat-label>Ingredient</mat-label>
          <input matInput [(ngModel)]="newIngredient" placeholder="Enter ingredient" name="newIngredient"
            list="ingredientList" />
          <!--<datalist id="nutrients">
            <option *ngFor="let food of allFoods" [value]="food"></option>
          </datalist> -->
         <datalist id="ingredientList">
            <option *ngFor="let description of SRFoodDescriptions" [value]="description"></option>
            <option *ngFor="let description of foundationFoodDescriptions" [value]="description"></option>
            <option *ngFor="let description of brandedFoodDescriptions" [value]="description"></option>
          </datalist>        
         </mat-form-field>

        <!-- Amount Input -->
        <mat-form-field appearance="fill" class="half-width">
          <mat-label>Amount (g)</mat-label>
          <input matInput type="number" [(ngModel)]="newAmount" placeholder="Amount in grams" name="newAmount" />
        </mat-form-field>

        <!-- Add Ingredient Button -->
        <button mat-button color="primary" (click)="addIngredient()">Add Ingredient</button>
      </div>

      <!-- Display Ingredient List -->
      <!--    <mat-list>
        <mat-list-item *ngFor="let ingredient of ingredients">
          {{ ingredient.name }} - {{ ingredient.amount }} g
          <button mat-icon-button color="warn" (click)="removeIngredient(ingredient)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>-->
      <!-- Save Recipe Button -->
      <!--       <button mat-raised-button color="accent" (click)="saveRecipe()">Save Recipe</button> -->

      <h3 class="ingredients-heading">Ingredients Table</h3>
      <!-- Editable Ingredient List -->
      <div *ngFor="let ingredient of ingredients; let i = index" class="ingredient-item">
        <!-- Ingredient Name Input -->
        <mat-form-field appearance="fill" class="half-width">
          <mat-label>Ingredient Name</mat-label>
          <input matInput [(ngModel)]="ingredient.name" placeholder="Edit ingredient name" name="ingredientName{{i}}" />
        </mat-form-field>

        <!-- Ingredient Amount Input -->
        <mat-form-field appearance="fill" class="half-width">
          <mat-label>Amount (g)</mat-label>
          <input matInput type="number" [(ngModel)]="ingredient.amount" placeholder="Edit amount"
            name="ingredientAmount{{i}}" />
        </mat-form-field>

        <!-- Remove Ingredient Button -->
        <button mat-icon-button color="warn" (click)="removeIngredient(ingredient)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <!-- Save Recipe Button -->
      <!-- <button mat-raised-button color="accent" (click)="saveRecipe()">Save Recipe</button>-->
      <button mat-raised-button class="custom-button" (click)="saveRecipe()">Save Recipe</button>
      <button mat-raised-button class="custom-button" (click)="deleteRecipe()">Delete Recipe</button>

    </form>


    <!-- Dynamic Ingredients Table -->
    <!--  <table mat-table [dataSource]="ingredients" class="mat-elevation-z8">-->

    <!-- Ingredient Column -->
    <!--   <ng-container matColumnDef="ingredient">
        <th mat-header-cell *matHeaderCellDef> Ingredient </th>
        <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
      </ng-container>-->

    <!-- Amount Column -->
    <!--   <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Amount (g) </th>
        <td mat-cell *matCellDef="let element"> {{ element.amount }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns_nutrition"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns_nutrition;"></tr>
    </table>-->


    <!-- <div>
      <h3>Ingredients Table</h3>
      <table mat-table [dataSource]="ingredients" class="mat-elevation-z8">-->

    <!-- Ingredient Column -->
    <!--  <ng-container matColumnDef="ingredient">
          <th mat-header-cell *matHeaderCellDef> Ingredient </th>
          <td mat-cell *matCellDef="let element">
            <input matInput [(ngModel)]="element.name" placeholder="Ingredient Name" />
          </td>
        </ng-container>-->

    <!-- Amount Column -->
    <!-- <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Amount (g) </th>
          <td mat-cell *matCellDef="let element">
            <input matInput type="number" [(ngModel)]="element.amount" placeholder="Amount (g)" />
          </td>
        </ng-container>-->

    <!-- Remove Column -->
    <!-- <ng-container matColumnDef="remove">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button color="warn" (click)="removeIngredient(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns_nutrition"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns_nutrition;"></tr>
      </table>

      <button mat-raised-button color="primary" (click)="saveRecipe()">Save Recipe</button>
    </div>
 -->
    <!-- Dropdown for Nutrition Label Options -->
    <div class="button-container">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Generate Nutrition Label</mat-label>
        <mat-select (selectionChange)="handleNutritionOption($event.value)">
          <mat-option value="generateNutritionLabel">Full Nutrition Details</mat-option>
          <mat-option value="generateNutritionLabelFDA">Standard Vertical</mat-option>
          <mat-option value="generateNutritionLabelFDA_MicroNutSidebySide">Micronutrients Listed
            Side-by-Side</mat-option>
          <mat-option value="NutritionLabelFDA_VoluntaryNuts">FDA Nutrition Label (Voluntary Nutrients)</mat-option>
          <mat-option value="generateNutritionLabelFDA_Tabular">FDA Tabular Label</mat-option>
          <mat-option value="generateNutritionLabelFDA_Dual">FDA Dual-Column Label</mat-option>
          <mat-option value="generateNutritionLabelFDA_Linear">FDA Linear Label</mat-option>
          <mat-option value="generateNutritionLabelFDA_LinearInt">FDA Linear (Intermediate)</mat-option>
          <mat-option value="generateNutritionLabelFDA_Simplified">FDA Simplified Label</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <!-- Generate Button for mat-table -->
    <!--<div class="button-container">-->
    <!-- Group: Full Nutrition Labels -->
    <!-- <div class="button-group">
        <button mat-raised-button color="accent" (click)="generateNutritionLabel()" class="spaced-button">
          Full Nutrition Details
        </button>
      </div>-->

    <!-- Group: FDA-Compliant Labels -->
    <!-- Standard Vertical 21 CFR 101.9(d)(12) -->
    <!-- <div class="button-group">
        <button mat-raised-button color="accent" (click)="generateNutritionLabelFDA()" class="spaced-button">
          Standard Vertical
        </button>-->
    <!--Vertical Display with Micronutrients Listed Side-by-Side -->
    <!-- <button mat-raised-button color="accent" (click)="generateNutritionLabelFDA_MicroNutSidebySide()"
          class="spaced-button">
          Micronutrients Listed Side-by-Side
        </button>
        <button mat-raised-button color="accent" (click)="NutritionLabelFDA_VoluntaryNuts()" class="spaced-button">
          FDA Nutrition Label (Voluntary Nutrients)
        </button>
        <button mat-raised-button color="accent" (click)="generateNutritionLabelFDA_Tabular()" class="spaced-button">
          FDA Tabular Label
        </button>
        <button mat-raised-button color="accent" (click)="generateNutritionLabelFDA_Dual()" class="spaced-button">
          FDA Dual-Column Label
        </button>
        <button mat-raised-button color="accent" (click)="generateNutritionLabelFDA_Linear()" class="spaced-button">
          FDA Linear Label
        </button>
        <button mat-raised-button color="accent" (click)="generateNutritionLabelFDA_LinearInt()" class="spaced-button">
          FDA Linear (Intermediate)
        </button>

        <button mat-raised-button color="accent" (click)="generateNutritionLabelFDA_Simplified()" class="spaced-button">
          FDA Simplified Label
        </button>

      </div>
    </div>-->



    <!-- Placeholder for Result Display -->
    <!-- Mat Table Display -->
    <div *ngIf="showMatTable && nutritionLabel.length > 0">
      <h3>Nutrition Label</h3>
      <table mat-table [dataSource]="nutritionLabel" class="mat-elevation-z8">
        <!-- Nutrient Name Column -->
        <ng-container matColumnDef="nutrientName">
          <th mat-header-cell *matHeaderCellDef> Nutrient </th>
          <td mat-cell *matCellDef="let element"> {{ element.nutrientName }} </td>
        </ng-container>

        <!-- Nutrient Amount Column -->
        <ng-container matColumnDef="nutrientAmount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let element"> {{ element.nutrientAmount }} </td>
        </ng-container>

        <!-- Unit Column -->
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef> Unit </th>
          <td mat-cell *matCellDef="let element"> {{ element.unit }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['nutrientName', 'nutrientAmount', 'unit']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['nutrientName', 'nutrientAmount', 'unit'];"></tr>
      </table>
    </div>


    <!-- FDA Nutrition Label Display -->
    <div *ngIf="showFDA && nutritionLabel.length > 0" class="nutrition-label">
      <h1>Nutrition Facts</h1>
      <!-- Thin Line Below Nutrition Facts -->
      <div class="vitamin-line"></div>
      <!-- Serving Info -->
      <div class="serving-info">
        <span>{{ numberOfServings }} servings per container</span><br />
        <div style="display: flex; justify-content: space-between">
          <span class="serving-size">Serving size</span>
          <span class="serving-weight">{{ servingSize }}g</span>
        </div>
      </div>

      <!-- Calories Section -->
      <div class="calories-header">Amount per serving</div>
      <div class="calories">
        <span class="calories-text">Calories </span>
        <span class="calories-number">{{ totalCalories }}</span>
      </div>
      <hr class="thin-rule" />


      <!-- Nutrients Section -->
      <div *ngFor="let nutrient of nutritionLabel">
        <div class="nutrient-line" *ngIf="!isVitaminOrMineral(nutrient.nutrientName)" [ngClass]="{
      'bold-nutrient': isMajorNutrient(nutrient.nutrientName),
      'sub-nutrient': nutrient.isSubNutrient,
      'sub-sub-nutrient': nutrient.isSubSubNutrient
    }">
          <!-- Special Formatting for "Trans Fat" -->
          <span *ngIf="nutrient.nutrientName === 'Trans Fat'">
            <i>{{ nutrient.nutrientName.split(' ')[0] }}</i>
            {{ nutrient.nutrientName.substring(nutrient.nutrientName.indexOf(' ') + 1) }}
            {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
          </span>

          <!-- Special Formatting for "Added Sugars" -->
          <span *ngIf="nutrient.nutrientName === 'Added Sugars' && nutrient.isSubSubNutrient">
            Includes {{ nutrient.nutrientAmount }} {{ nutrient.unit }} {{ nutrient.nutrientName }}
          </span>

          <!-- Normal Display for Other Nutrients -->
          <span *ngIf="nutrient.nutrientName !== 'Trans Fat' && nutrient.nutrientName !== 'Added Sugars'">
            {{ nutrient.nutrientName }} {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
          </span>

          <!-- Daily Value Percentage -->
          <span class="dv-amount" *ngIf="nutrient.percentage !== '% Daily Value*' && !isNoDV(nutrient.nutrientName)">
            {{ nutrient.percentage }}%
          </span>
          <span class="dv-amount" *ngIf="nutrient.percentage === '% Daily Value*'">
            {{ nutrient.percentage }}
          </span>

          <!-- Notes -->
          <!-- <span *ngIf="nutrient.note">{{ nutrient.note }}</span>-->
        </div>
      </div>


      <!-- Vitamins & Minerals Section -->
      <div class="vitamins">
        <div *ngFor="let vitamin of nutritionLabel">
          <span *ngIf="isVitaminOrMineral(vitamin.nutrientName)" class="vitamin-line">
            <span class="vitamin-name">{{ vitamin.nutrientName }} {{ vitamin.nutrientAmount }} {{ vitamin.unit }}</span>
            <span class="dv-amount" *ngIf="vitamin.percentage">{{ vitamin.percentage }}%</span>
          </span>
        </div>
      </div>

      <!-- Footnote Section -->
      <div class="footnote">
        * The % Daily Value (DV) tells you how much a nutrient in a serving contributes to a daily diet.
        2,000 calories a day is used for general nutrition advice.
      </div>
    </div>


    <!-- Vertical Display with Micronutrients Listed Side-by-Side -->
    <div *ngIf="showFDA_MicroNut && nutritionLabel_MicroNut.length > 0" class="nutrition-label">
      <h1>Nutrition Facts</h1>
      <!-- Thin Line Below Nutrition Facts -->
      <div class="vitamin-line"></div>
      <!-- Serving Info -->
      <div class="serving-info">
        <span class="servings-per-container">{{ numberOfServings }} servings per container</span><br />
        <div style="display: flex; justify-content: space-between">
          <span class="bold serving-size">Serving size</span>
          <span class="bold serving-weight">{{ servingSize }}g</span>
        </div>
      </div>

      <!-- Calories Section -->
      <div class="calories-header">Amount per serving</div>
      <div class="calories">
        <span class="calories-text">Calories </span>
        <span class="calories-number">{{ totalCalories }}</span>
      </div>
      <hr class="thin-rule" />


      <!-- Nutrients Section -->
      <div *ngFor="let nutrient of nutritionLabel_MicroNut">
        <div class="nutrient-line" *ngIf="!isVitaminOrMineral(nutrient.nutrientName)" [ngClass]="{
  'bold-nutrient': isMajorNutrient(nutrient.nutrientName),
  'sub-nutrient': nutrient.isSubNutrient,
  'sub-sub-nutrient': nutrient.isSubSubNutrient
}">
          <!-- Special Formatting for "Trans Fat" -->
          <span *ngIf="nutrient.nutrientName === 'Trans Fat'">
            <i>{{ nutrient.nutrientName.split(' ')[0] }}</i>
            {{ nutrient.nutrientName.substring(nutrient.nutrientName.indexOf(' ') + 1) }}
            {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
          </span>

          <!-- Special Formatting for "Added Sugars" -->
          <span *ngIf="nutrient.nutrientName === 'Added Sugars' && nutrient.isSubSubNutrient">
            Includes {{ nutrient.nutrientAmount }} {{ nutrient.unit }} {{ nutrient.nutrientName }}
          </span>

          <!-- Normal Display for Other Nutrients -->
          <span *ngIf="nutrient.nutrientName !== 'Trans Fat' && nutrient.nutrientName !== 'Added Sugars'">
            {{ nutrient.nutrientName }} {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
          </span>

          <!-- Daily Value Percentage -->
          <span class="dv-amount" *ngIf="nutrient.percentage !== '% Daily Value*' && !isNoDV(nutrient.nutrientName)">
            {{ nutrient.percentage }}%
          </span>
          <span class="dv-amount" *ngIf="nutrient.percentage === '% Daily Value*'">
            {{ nutrient.percentage }}
          </span>

          <!-- Notes -->
          <!-- <span *ngIf="nutrient.note">{{ nutrient.note }}</span>-->
        </div>
      </div>


      <!-- Vitamins & Minerals Section -->
      <div class="vitamins-micronutsidebyside">
        <div class="vitamin-column">
          <!-- First Column -->
          <div *ngFor="let vitamin of nutritionLabel_MicroNut; let i = index">
            <span *ngIf="isVitaminOrMineral(vitamin.nutrientName) && i % 2 === 0" class="vitamin-name-micronut">{{
              vitamin.nutrientName }} {{ vitamin.nutrientAmount }} {{ vitamin.unit }} {{ vitamin.percentage }}%</span>
          </div>
        </div>

        <!-- Bullet Column -->
        <div class="bullet-column">
          <div *ngFor="let vitamin of nutritionLabel_MicroNut; let i = index" class="bullet-item">
            <span *ngIf="isVitaminOrMineral(vitamin.nutrientName) && i % 2 === 0" class="bullet">&bull;</span>
          </div>
        </div>

        <div class="vitamin-column">
          <!-- Second Column -->
          <div *ngFor="let vitamin of nutritionLabel_MicroNut; let i = index">
            <span *ngIf="isVitaminOrMineral(vitamin.nutrientName) && i % 2 === 1" class="vitamin-name-micronut">{{
              vitamin.nutrientName }} {{ vitamin.nutrientAmount }} {{ vitamin.unit }} {{ vitamin.percentage }}%
            </span>
          </div>
        </div>
      </div>



      <!-- Footnote Section -->
      <div class="footnote">
        * The % Daily Value (DV) tells you how much a nutrient in a serving contributes to a daily diet.
        2,000 calories a day is used for general nutrition advice.
      </div>
    </div>

    <!-- FDA Nutrition Label Display Voluntary Nutrition -->
    <div *ngIf="showFDA_VolNut && nutritionLabel.length > 0" class="nutrition-label">
      <h1>Nutrition Facts</h1>
      <!-- Thin Line Below Nutrition Facts -->
      <div class="vitamin-line"></div>
      <!-- Serving Info -->
      <div class="serving-info">
        <span class="servings-per-container">{{ numberOfServings }} servings per container</span><br />
        <div style="display: flex; justify-content: space-between">
          <span class="bold serving-size">Serving size</span>
          <span class="bold serving-weight">{{ servingSize }}g</span>
        </div>
      </div>

      <!-- Calories Section -->
      <div class="calories-header">Amount per serving</div>
      <div class="calories">
        <span class="calories-text">Calories </span>
        <span class="calories-number">{{ totalCalories }}</span>
      </div>
      <hr class="thin-rule" />


      <!-- Nutrients Section -->
      <div *ngFor="let nutrient of nutritionLabel">
        <div class="nutrient-line" *ngIf="!isVitaminOrMineral(nutrient.nutrientName)" [ngClass]="{
      'bold-nutrient': isMajorNutrient(nutrient.nutrientName),
      'sub-nutrient': nutrient.isSubNutrient,
      'sub-sub-nutrient': nutrient.isSubSubNutrient
    }">
          <!-- Special Formatting for "Trans Fat" -->
          <span *ngIf="nutrient.nutrientName === 'Trans Fat'">
            <i>{{ nutrient.nutrientName.split(' ')[0] }}</i>
            {{ nutrient.nutrientName.substring(nutrient.nutrientName.indexOf(' ') + 1) }}
            {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
          </span>

          <!-- Special Formatting for "Added Sugars" -->
          <span *ngIf="nutrient.nutrientName === 'Added Sugars' && nutrient.isSubSubNutrient">
            Includes {{ nutrient.nutrientAmount }} {{ nutrient.unit }} {{ nutrient.nutrientName }}
          </span>

          <!-- Normal Display for Other Nutrients -->
          <span *ngIf="nutrient.nutrientName !== 'Trans Fat' && nutrient.nutrientName !== 'Added Sugars'">
            {{ nutrient.nutrientName }} {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
          </span>

          <!-- Daily Value Percentage -->
          <span class="dv-amount" *ngIf="nutrient.percentage !== '% Daily Value*' && !isNoDV(nutrient.nutrientName)">
            {{ nutrient.percentage }}%
          </span>
          <span class="dv-amount" *ngIf="nutrient.percentage === '% Daily Value*'">
            {{ nutrient.percentage }}
          </span>

          <!-- Notes -->
          <!-- <span *ngIf="nutrient.note">{{ nutrient.note }}</span>-->
        </div>
      </div>


      <!-- Vitamins & Minerals Section -->
      <div class="vitamins">
        <div *ngFor="let vitamin of nutritionLabel">
          <span *ngIf="isVitaminOrMineral(vitamin.nutrientName)" class="vitamin-line">
            <span class="vitamin-name">{{ vitamin.nutrientName }} {{ vitamin.nutrientAmount }} {{ vitamin.unit }}</span>
            <span class="dv-amount" *ngIf="vitamin.percentage">{{ vitamin.percentage }}%</span>
          </span>
        </div>
      </div>

      <!-- Footnote Section -->
      <div class="footnote">
        * The % Daily Value (DV) tells you how much a nutrient in a serving contributes to a daily diet.
        2,000 calories a day is used for general nutrition advice.
      </div>
    </div>


    <div *ngIf="showFDA_Tab && nutritionLabel.length > 0" class="nutrition-label-tab">
      <!-- 1st Column -->
      <div class="column-tab column-1-tab">
        <h1 class="nutrition-title-tab">Nutrition Facts</h1>
        <hr class="thin-rule-tab" />
        <div class="serving-info-tab">
          <div class="servings-per-container-tab">{{ numberOfServings }} servings per container</div>
          <div class="serving-size-tab">
            <strong>Serving size <br> {{ servingSize }}g</strong>
          </div>
        </div>
        <hr class="thin-rule-tab" />
        <div class="calories-section-tab">
          <div class="calories-row">
            <div class="calories-left">
              <span class="calories-label-tab">Calories</span>
              <div class="per-serving-size-tab">
                <strong>Per Serving</strong>
              </div>
            </div>
            <span class="calories-value-tab">{{ totalCalories }}</span>
          </div>
        </div>
      </div>

      <!-- 2nd Column -->
      <div class="column-tab column-2-tab">
        <div class="header-row-tab dual-header">
          <div class="header-tab">Amount/Serving</div>
          <div class="header-tab">% Daily Value (DV)</div>
        </div>
        <hr class="thick-rule-tab" />
        <!-- Nutrient Rows -->


        <div *ngFor="let nutrient of getNutritionLabelBetween('Total Fat', 'Sodium')" class="nutrient-row-tab"
          [ngClass]="{'sub-row-tab': nutrient.isSubNutrient, 'sub-sub-row-tab': nutrient.isSubSubNutrient}">
          <div class="item-tab">
            <span *ngIf="!nutrient.isSubNutrient && !nutrient.isSubSubNutrient">
              <strong>{{ nutrient.nutrientName }}</strong>
            </span>
            <span *ngIf="nutrient.isSubNutrient || nutrient.isSubSubNutrient">
              {{ nutrient.nutrientName }}
            </span>
            <span *ngIf="nutrient.nutrientAmount"> {{ nutrient.nutrientAmount }}{{ nutrient.unit }}</span>
          </div>
          <div class="item-dv-tab">
            <span *ngIf="nutrient.percentage">{{ nutrient.percentage }}%</span>
          </div>
          <hr class="thin-rule-tab" />
        </div>



        <hr class="thick-rule-tab" />

        <!-- Vitamins & Minerals Section -->
        <!-- Vitamins Section -->
        <div class="vitamins-tab">
          <!-- First Group -->
          <div *ngFor="let row of groupVitaminsAndMinerals(4)" class="vitamins-row">
            <span *ngFor="let vitamin of row">
              {{ vitamin.nutrientName }} {{ vitamin.nutrientAmount }}{{ vitamin.unit }}
              {{ vitamin.percentage }}%
              <span *ngIf="!isLastItemInRow(row, vitamin)">&bull;</span>
            </span>
          </div>
        </div>

      </div>

      <!-- 3rd Column -->
      <div class="column-tab column-3-tab">
        <div class="header-row-tab dual-header">
          <div class="header-tab">Amount/Serving</div>
          <div class="header-tab">% Daily Value (DV)*</div>
        </div>
        <hr class="thick-rule-tab" />
        <div *ngFor="let nutrient of getNutritionLabelBetween('Total Carbohydrate', 'Protein')" class="nutrient-row-tab"
          [ngClass]="{'sub-row-tab': nutrient.isSubNutrient, 'sub-sub-row-tab': nutrient.isSubSubNutrient}">
          <div class="item-tab">
            <span *ngIf="!nutrient.isSubNutrient && !nutrient.isSubSubNutrient">
              <strong>{{ nutrient.nutrientName }}</strong>
            </span>
            <span *ngIf="nutrient.isSubNutrient || nutrient.isSubSubNutrient">
              {{ nutrient.nutrientName }}
            </span>
            <span *ngIf="nutrient.nutrientAmount"> {{ nutrient.nutrientAmount }}{{ nutrient.unit }}</span>
          </div>
          <div class="item-dv-tab">
            <span *ngIf="nutrient.percentage">{{ nutrient.percentage }}%</span>
          </div>
          <hr class="thin-rule-tab" />
        </div>


        <hr class="thick-rule-tab" />
        <!-- Vitamins Section -->
        <div class="vitamins-tab">
          <!-- Second Group -->
          <div *ngFor="let row of groupRemainingVitaminsAndMinerals(2)" class="vitamins-row">
            <span *ngFor="let vitamin of row">
              {{ vitamin.nutrientName }} {{ vitamin.nutrientAmount }}{{ vitamin.unit }}
              {{ vitamin.percentage }}%
              <span *ngIf="!isLastItemInRow(row, vitamin)">&bull;</span>
            </span>
          </div>
        </div>

      </div>

      <!-- 4th Column -->
      <div class="column-tab column-4-tab">
        <div class="footnote-tab">
          * The % Daily Value (DV) tells you how much a nutrient in a serving contributes to a daily diet. 2,000
          calories a
          day is used for general nutrition advice.
        </div>
      </div>
    </div>


    <!-- Linear Display for Small Packages (with < 12 sq. in. of labelling space)†21 CFR 101.9(j)(13)(i)(B) -->
    <div *ngIf="showFDA_Linear && nutritionLabelLinear.length > 0" class="nutrition-label-linear">
      <span class="nutrition-facts-linear-bold">Nutrition Facts </span>
      <span class="nutrition-facts-linear-nobold">Servings: {{ numberOfServings }}</span>
      <span class="nutrition-facts-linear-bold">Serv. size: {{ servingSize }}g</span>
      <span class="nutrition-facts-linear-nobold">Amount per serving:</span>
      <span class="nutrition-facts-linear-bold">Calories {{ totalCalories }}</span>

      <!-- Nutrients Section -->
      <span *ngFor="let nutrient of nutritionLabelLinear">
        <ng-container *ngIf="!isVitaminOrMineral(nutrient.nutrientName)">
          <span *ngIf="nutrient.nutrientName === 'Trans Fat'">
            <i>{{ nutrient.nutrientName.split(' ')[0] }}</i>
            {{ nutrient.nutrientName.substring(nutrient.nutrientName.indexOf(' ') + 1) }} {{ nutrient.nutrientAmount }}
            {{ nutrient.unit }}
          </span>

          <span [ngClass]="{
      'nutrition-facts-linear-bold': isMajorNutrient(nutrient.nutrientName)
    }" *ngIf="nutrient.nutrientName !== 'Trans Fat'">
            {{ nutrient.nutrientName }} {{ nutrient.nutrientAmount }} {{ nutrient.unit }} ({{ nutrient.percentage }}%
            DV)
          </span>
        </ng-container>
      </span>


      <!-- Vitamins & Minerals Section -->
      <ng-container *ngFor="let vitamin of nutritionLabelLinear">
        <span *ngIf="isVitaminOrMineral(vitamin.nutrientName)" class="nutrition-facts-linear-nobold">
          {{ vitamin.nutrientName }} {{ vitamin.nutrientAmount }} {{ vitamin.unit }} ({{ vitamin.percentage }}% DV)
        </span>
      </ng-container>

    </div>

    <!-- Linear Display for Small or Intermediate-Sized Packages (with nutrients in 8 point font)†21 CFR 101.9(j)(13)(ii)(A)(2) -->
    <div *ngIf="showFDA_LinearInt && nutritionLabel.length > 0" class="nutrition-label-linear">
      <span class="nutrition-facts-linear-bold">Nutrition Facts </span>
      <span class="nutrition-facts-linear-nobold">Servings: {{ numberOfServings }}</span>
      <span class="nutrition-facts-linear-bold">Serv. size: {{ servingSize }}g</span>
      <span class="nutrition-facts-linear-nobold">Amount per serving:</span>
      <span class="nutrition-facts-linear-bold">Calories {{ totalCalories }}</span>

      <!-- Nutrients Section -->
      <span *ngFor="let nutrient of nutritionLabel">
        <ng-container *ngIf="!isVitaminOrMineral(nutrient.nutrientName)">
          <span *ngIf="nutrient.nutrientName === 'Trans Fat'">
            <i>{{ nutrient.nutrientName.split(' ')[0] }}</i>
            {{ nutrient.nutrientName.substring(nutrient.nutrientName.indexOf(' ') + 1) }} {{ nutrient.nutrientAmount }}
            {{ nutrient.unit }}
          </span>

          <span [ngClass]="{
      'nutrition-facts-linear-bold': isMajorNutrient(nutrient.nutrientName)
    }" *ngIf="nutrient.nutrientName !== 'Trans Fat'">
            {{ nutrient.nutrientName }} {{ nutrient.nutrientAmount }} {{ nutrient.unit }} ({{ nutrient.percentage }}%
            DV)
          </span>
        </ng-container>
      </span>


      <!-- Vitamins & Minerals Section -->
      <ng-container *ngFor="let vitamin of nutritionLabel">
        <span *ngIf="isVitaminOrMineral(vitamin.nutrientName)" class="nutrition-facts-linear-nobold">
          {{ vitamin.nutrientName }} {{ vitamin.nutrientAmount }} {{ vitamin.unit }} ({{ vitamin.percentage }}% DV)
        </span>
      </ng-container>

    </div>


    <!-- FDA Dual Nutrition Label Display -->
    <div *ngIf="showFDA_Dual && nutritionLabelDual.length > 0" class="nutrition-label-dual">
      <h1>Nutrition Facts</h1>
      <!-- Thin Line Below Nutrition Facts -->
      <div class="vitamin-line"></div>

      <!-- Serving Info -->
      <div class="serving-info-dual">
        <span class="servings-per-container">{{ numberOfServings }} servings per container</span><br />
        <div style="display: flex; justify-content: space-between">
          <span class="bold serving-size">Serving size</span>
          <span class="bold serving-weight">{{ servingSize }}g</span>
        </div>
      </div>


      <!-- Start of Content with Vertical Lines -->
      <div class="nutrition-content-dual">
        <!-- Calories Section -->
        <div class="calories-row-dual">
          <!-- Column 1: Label -->
          <div class="calories-column-dual">
            <span class="calories-text-dual">Calories</span>
            <hr class="thin-rule-dual" />
          </div>

          <!-- Column 2: Per Serving -->
          <div class="calories-column-dual">
            <div class="per-serving-size-dual">
              <strong>Per Serving</strong>
            </div>
            <span class="calories-per-serving-dual">{{ totalCalories }}</span>
            <hr class="thin-rule-dual" />
          </div>

          <!-- Column 3: Per Container -->
          <div class="calories-column-dual">
            <div class="per-serving-size-dual">
              <strong>Per Container</strong>
            </div>
            <span class="calories-per-container-dual">{{ totalCalories * numberOfServings }}</span>
            <hr class="thin-rule-dual" />
          </div>
        </div>

        <!-- Nutrients Section -->
        <div *ngFor="let nutrient of nutritionLabelDual">
          <div class="nutrient-line-dual" *ngIf="!isVitaminOrMineral(nutrient.nutrientName)" [ngClass]="{
            'sub-nutrient-dual': nutrient.isSubNutrient,
            'sub-sub-nutrient-dual': nutrient.isSubSubNutrient
         }">
            <!-- Nutrient Row -->
            <div class="nutrition-row-dual">
              <!-- Column 1: Nutrient Name -->
              <div class="nutrient-column-dual">
                <div class="nutrient-flex-container">
                  <!-- Special Formatting for "Trans Fat" -->
                  <span *ngIf="nutrient.nutrientName === 'Trans Fat'">
                    <i>{{ nutrient.nutrientName.split(' ')[0] }}</i>
                    {{ nutrient.nutrientName.substring(nutrient.nutrientName.indexOf(' ') + 1) }}
                  </span>
                  <!-- Special Formatting for "Added Sugars" -->
                  <span *ngIf="nutrient.nutrientName === 'Total Carbohydrate'">
                    <strong> Total Carb.</strong>
                  </span>
                  <!-- Special Formatting for "Added Sugars" -->
                  <span *ngIf="nutrient.nutrientName === 'Added Sugars' && nutrient.isSubSubNutrient">
                    Incl. Added Sugars
                  </span>

                  <span [ngClass]="{
              'bold-nutrient-name': isMajorNutrient(nutrient.nutrientName)}"
                    *ngIf="nutrient.nutrientName !== 'Trans Fat' && nutrient.nutrientName !== 'Added Sugars' && nutrient.nutrientName !== 'Total Carbohydrate'">
                    {{ nutrient.nutrientName }}
                  </span>
                </div>
              </div>

              <!-- Column 2: Per Serving -->
              <div class="nutrient-column-dual">
                <span class="nutrient-amount-dual">
                  {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
                </span>
                <span class="dv-amount-dual"
                  *ngIf="nutrient.percentage && !isNoDV(nutrient.nutrientName) && nutrient.percentage!=='% Daily Value*' ">
                  {{ nutrient.percentage }}%
                </span>
              </div>

              <!-- Column 3: Per Container -->
              <div class="nutrient-column-dual">
                <span class="nutrient-amount-dual">
                  {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
                </span>
                <span class="dv-amount-dual" *ngIf="nutrient.percentage && !isNoDV(nutrient.nutrientName)">
                  {{ nutrient.percentage }}%
                </span>
              </div>
            </div>
          </div>


        </div>
        <div class="thick-rule-dual-container">
          <hr class="thick-rule-dual nutrient-rule-dual" />
          <hr class="thick-rule-dual serving-rule-dual" />
          <hr class="thick-rule-dual container-rule-dual" />
        </div>

        <!-- Vitamins & Minerals Section -->
        <div *ngFor="let vitamin of nutritionLabelDual">
          <div class="nutrient-line-dual" *ngIf="isVitaminOrMineral(vitamin.nutrientName)">
            <!-- Vitamin Row -->
            <div class="nutrition-row-dual">
              <!-- Column 1: Vitamin Name -->
              <div class="nutrient-column-dual">
                {{ vitamin.nutrientName }}
              </div>

              <!-- Column 2: Per Serving -->
              <div class="nutrient-column-dual">
                <span class="nutrient-amount-dual">
                  {{ vitamin.nutrientAmount }} {{ vitamin.unit }}
                </span>
                <span class="dv-amount-dual" *ngIf="vitamin.percentage">
                  {{ vitamin.percentage }}%
                </span>
              </div>

              <!-- Column 3: Per Container -->
              <div class="nutrient-column-dual">
                <span class="nutrient-amount-dual">
                  {{ vitamin.nutrientAmount }} {{ vitamin.unit }}
                </span>
                <span class="dv-amount-dual" *ngIf="vitamin.percentage">
                  {{ vitamin.percentage }}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="thin-rule" />


      <!-- Footnote Section -->
      <div class="footnote">
        * The % Daily Value (DV) tells you how much a nutrient in a serving contributes to a daily diet.
        2,000 calories a day is used for general nutrition advice.
      </div>
    </div>

    <!-- FDA Nutrition Simplified Label Display -->
    <div *ngIf="showFDA_Simplified && nutritionLabel.length > 0" class="nutrition-label">
      <h1>Nutrition Facts</h1>
      <!-- Thin Line Below Nutrition Facts -->
      <div class="vitamin-line"></div>
      <!-- Serving Info -->
      <div class="serving-info">
        <span class="servings-per-container">{{ numberOfServings }} servings per container</span><br />
        <div style="display: flex; justify-content: space-between">
          <span class="bold serving-size">Serving size</span>
          <span class="bold serving-weight">{{ servingSize }}g</span>
        </div>
      </div>

      <!-- Calories Section -->
      <div class="calories-header">Amount per serving</div>
      <div class="calories">
        <span class="calories-text">Calories </span>
        <span class="calories-number">{{ totalCalories }}</span>
      </div>
      <hr class="thin-rule" />


      <!-- Nutrients Section -->
      <div *ngFor="let nutrient of nutritionLabel">
        <div class="nutrient-line" *ngIf="!isVitaminOrMineral(nutrient.nutrientName) && shouldDisplayNutrient(nutrient)"
          [ngClass]="{'bold-nutrient': isMajorNutrient(nutrient.nutrientName),
      'sub-nutrient': nutrient.isSubNutrient,
      'sub-sub-nutrient': nutrient.isSubSubNutrient
    }">
          <!-- Special Formatting for "Trans Fat" -->
          <span *ngIf="nutrient.nutrientName === 'Trans Fat'">
            <i>{{ nutrient.nutrientName.split(' ')[0] }}</i>
            {{ nutrient.nutrientName.substring(nutrient.nutrientName.indexOf(' ') + 1) }}
            {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
          </span>

          <!-- Special Formatting for "Added Sugars" -->
          <span *ngIf="nutrient.nutrientName === 'Added Sugars' && nutrient.isSubSubNutrient">
            Includes {{ nutrient.nutrientAmount }} {{ nutrient.unit }} {{ nutrient.nutrientName }}
          </span>

          <!-- Normal Display for Other Nutrients -->
          <span *ngIf="nutrient.nutrientName !== 'Trans Fat' && nutrient.nutrientName !== 'Added Sugars'">
            {{ nutrient.nutrientName }} {{ nutrient.nutrientAmount }} {{ nutrient.unit }}
          </span>

          <!-- Daily Value Percentage -->
          <span class="dv-amount" *ngIf="nutrient.percentage !== '% Daily Value*' && !isNoDV(nutrient.nutrientName)">
            {{ nutrient.percentage }}%
          </span>
          <span class="dv-amount" *ngIf="nutrient.percentage === '% Daily Value*'">
            {{ nutrient.percentage }}
          </span>

          <!-- Notes -->
          <!-- <span *ngIf="nutrient.note">{{ nutrient.note }}</span>-->
        </div>
      </div>

      <!-- Vitamins & Minerals Section -->
      <div class="vitamins_simplified">
        <div *ngFor="let vitamin of nutritionLabel">
          <span *ngIf="isVitaminOrMineral(vitamin.nutrientName) && vitamin.percentage && +vitamin.percentage >= 5"
            class="vitamin-line">
            <span class="vitamin-name">
              {{ vitamin.nutrientName }} {{ vitamin.nutrientAmount }} {{ vitamin.unit }}
            </span>
            <span class="dv-amount" *ngIf="vitamin.percentage">
              {{ vitamin.percentage }}%
            </span>
          </span>
        </div>
      </div>


      <!-- Footnote Section -->
      <div class="footnote_simplified">
        Not a significant source of
        <span *ngFor="let nutrient of insignificantNutrients; let isLast = last">
          {{ nutrient }}
          <span *ngIf="!isLast">, </span>
        </span>
      </div>
      <div class="footnote">
        *%DV=%Daily Value
      </div>
    </div>
  </mat-tab>
</mat-tab-group>                        