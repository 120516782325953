import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoodComponent } from '../food/food.component';
import { PaymentComponent } from '../payment/payment.component';
import { OllamaChatComponent } from '../ollama-chat/ollama-chat.component'; // Import the component
import { MatDialog } from '@angular/material/dialog';
import { BrandedFoods, FoodNutrient, Foods, FoundationFood, FoundationFoods, FoundationFoodsWithUnit, NutrientFoodDTO, SRFoods, SurveyFoods, User } from '../food-data';
import { Unit } from '../food-data'
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs/internal/Observable';
import { FoodService } from '../food.service';
import { catchError, finalize, forkJoin, map, of, switchMap } from 'rxjs';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { AnnouncementsComponent } from '../announcements/announcements.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AuthService } from '../auth/auth.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms'; // <-- Import this
import { ChangeDetectorRef } from '@angular/core';
import { SearchFormComponent } from '../search-form/search-form.component';
import { BlogComponent } from '../blog/blog.component';
import { FoodNutrientsService } from '../foodnutrients.service';
import { ChartOptions } from 'chart.js';
import { ChartData } from 'chart.js';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NFPService } from '../NFP.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [OllamaChatComponent,MatProgressSpinnerModule, MatListModule, MatIconModule, BlogComponent, FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatPaginatorModule, MatTableModule, MatTabsModule, CommonModule, FoodComponent, RouterOutlet, HomeComponent, RouterLink, HeaderComponent, FooterComponent, AnnouncementsComponent, MatSelectModule, SearchFormComponent, MatSort,PaymentComponent],

  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements AfterViewInit {
  

  foodList: NutrientFoodDTO[] = [];

  //dataSourceNutrient = new MatTableDataSource<NutrientFoodDTO>(this.foodList);
  //dataSourceNutrient: MatTableDataSource<NutrientFoodDTO> = new MatTableDataSource<NutrientFoodDTO>([]);
  dataSourceNutrient = new MatTableDataSource<NutrientFoodDTO>(this.foodList);

  filteredFoodListDTO: NutrientFoodDTO[] = [];

  nutrientNameForDropdown: string = '';
  nutrientNamesForDropdown: string[] = [];

  foodName: string = '';
  brandedFoodName: string = '';
  surveyFoodName: string = '';
  foundationFoodName: string = '';
  srFoodName: string = '';
  newIngredient: string = '';
  selectedFoundationIngredient: string;
  selectedSRIngredient: string;
  selectedBrandedIngredient: string;
  newAmount: number | null = null;
  newIngredientType: string = '';
  numberOfServings: number = 0;  // Initialize with a default value or null
  /* ingredients: { 
    name: string; 
    amount: number | null; 
    recipe: { name: string } 
  }[] = []; */
  ingredients: { name: string, amount: number }[] = [];
  isPremiumUser: boolean; // Default value; update it as needed

  displayedColumns_nutrition: string[] = ['ingredient', 'amount'];
  // Define the columns to be displayed for branded food nutrients
  displayedColumnsBrandedFood: string[] = ['fdcId', 'nutrientName', 'amount', 'unit'];
  nutritionLabel: {
    nutrientName: string;
    nutrientAmount: string;
    unit: string;
    isSubNutrient?: boolean;
    isFattyAcid?: boolean;  // Add the isFattyAcid flag here
    percentage?: string;  // Add the percentage property here
    note?: string; // Add this property
    isSubSubNutrient?: boolean
  }[] = [];
  showFDA: boolean = false; // New flag for FDA label visibility
  showMatTable: boolean = false; // For mat-table display
  showFDA_VolNut: boolean = false;
  showFDA_Tab: boolean = false;
  showFDA_LinearInt: boolean = false;
  totalFat: number = 0; // Example value
  fatUnit: string = 'g'; // Example unit
  saturatedFat: number = 0;
  addedSugarsDV: number = 50;
  totalSugarsPercentage: string;
  polyUnsaturatedFat: number = 0;
  monoUnsaturatedFat: number = 0;
  fluoride: number = 0;
  solubleFiber: number | string;
  insolubleFiber: number | string;
  vitaminA: number = 0;
  vitaminC: number = 0;
  thiamin: number = 0;
  riboflavin: number = 0;
  niacin: number = 0;
  vitaminB6: number = 0;
  folate: number = 0;
  vitaminB12: number = 0;
  phosphorus: number = 0;
  magnesium: number = 0;
  zinc: number = 0;
  choline: number = 0;
  transFat: number = 0;
  cholesterol: string = '0 g'; // Example values, replace as needed
  sodium: number = 0;
  totalCarbohydrate: string = '0';
  dietaryFiber: string = '0';
  totalSugars: number = 0;
  addedSugars: number = 0;

  protein: string = '0';
  vitaminD: number = 0;
  calcium: number = 0;
  iron: number = 0;
  potassium: number = 0;
  totalCalories = 0; // Calculate this based on data
  totalFatDV: number = 78;  // Example Daily Value percentage
  saturatedFatDV: number = 25;
  cholesterolDV: number = 300;
  sodiumDV: number = 2300;
  totalCarbohydrateDV: number = 275;
  dietaryFiberDV: number = 28;
  proteinDV: number = 50;
  vitaminDDV: number = 20;
  calciumDV: number = 1300;
  ironDV: number = 18;
  potassiumDV: number = 4700;

  biotinDV = 30;             // mcg
  chlorideDV = 2300;         // mg
  cholineDV = 550;           // mg
  chromiumDV = 35;           // mcg
  copperDV = 0.9;            // mg
  fatDV = 78;                // g
  folateFolicAcidDV = 400;   // mcg DFE
  iodineDV = 150;            // mcg
  magnesiumDV = 420;         // mg
  manganeseDV = 2.3;         // mg
  molybdenumDV = 45;         // mcg
  niacinDV = 16;             // mg NE
  pantothenicAcidDV = 5;     // mg
  phosphorusDV = 1250;       // mg
  riboflavinDV = 1.3;        // mg
  seleniumDV = 55;           // mcg
  thiaminDV = 1.2;           // mg
  vitaminADV = 900;          // mcg RAE
  vitaminB6DV = 1.7;         // mg
  vitaminB12DV = 2.4;        // mcg
  vitaminCDV = 90;           // mg
  vitaminEDV = 15;           // mg alpha-tocopherol
  vitaminKDV = 120;          // mcg
  zincDV = 11;               // mg

  totalFatPercentage: string;
  saturatedFatPercentage: string;
  cholesterolPercentage: string;
  sodiumPercentage: string;
  totalCarbohydratePercentage: string;
  addedSugarsPercentage: string;
  dietaryFiberPercentage: string;
  proteinPercentage: string;
  vitaminDPercentage: string;
  calciumPercentage: string;
  ironPercentage: string;
  potassiumPercentage: string;
  servingSize: number;
  totalCarbohydrateRaw: number;
  dietaryFiberRaw: number;
  proteinRaw: number;
  vitaminDNote: string = "";
  calciumNote: string = "";
  ironNote: string = "";
  potassiumNote: string = "";
  generalLowDVNote: string = "";
  sodiumFormatted: string;
  potassiumFormatted: string;


  inputValue: string = '';
  displayedColumns: string[] = ['index', 'fooddescription', 'foodcategory', 'perservingsizeing', 'per100unitservinging', 'unitname'];
  displayedColumnsNutrient: string[] = ['foodName', 'unitName', 'amount'];

  filteredFoodList: FoundationFoods[] = [];
  foodsWithUnit: FoundationFoodsWithUnit[] = [];

  uniqueCategories: string[] = [];
  filteredCategories: string[] = []; // Will store available categories based on selected diet
  selectedCategory: string = '';

  selectedDiet: string = ''; // New variable to store selected diet filter

  veganExcludedCategories: string[] = ['Dairy and Egg Products', 'Beef Products', 'Poultry Products', 'Sausages and Luncheon Meats', 'Pork Products', 'Finfish and Shellfish Products', 'Restaurant Foods'];
  vegetarianExcludedCategories: string[] = ['Beef Products', 'Poultry Products', 'Sausages and Luncheon Meats', 'Pork Products', 'Finfish and Shellfish Products', 'Restaurant Foods'];
  mediterraneanExcludedCategories: string[] = ['Beef Products'];
  foodList_Protein: FoundationFoods[] = [];
  foodList_Carb: FoundationFoods[] = [];//include carb by summation and others
  foodList_Fiber: FoundationFoods[] = [];
  foodList_SaturatedFats: FoundationFoods[] = [];

  foodList_MonoUnsaturatedFats: FoundationFoods[] = [];
  foodList_PolyUnsaturatedFats: FoundationFoods[] = [];
  foodList_TransFats: FoundationFoods[] = [];

  foodList_Cholestrol: FoundationFoods[] = [];
  foodList_Water: FoundationFoods[] = [];

  foodList_Biotin: FoundationFoods[] = [];
  foodList_Boron: FoundationFoods[] = [];
  foodList_Calcium: FoundationFoods[] = [];
  foodList_Carotene_alpha: FoundationFoods[] = [];
  foodList_Carotene_beta: FoundationFoods[] = [];
  foodList_Carotene_gamma: FoundationFoods[] = [];
  foodList_Chlorine: FoundationFoods[] = [];


  foodList_cholineFree: FoundationFoods[] = [];
  foodList_cholineFromPhosphocholineMg: FoundationFoods[] = [];
  foodList_cholineFromPhosphotidylCholineMg: FoundationFoods[] = [];
  foodList_cholineFromSphingomyelinMg: FoundationFoods[] = [];
  foodList_cholineTotal: FoundationFoods[] = [];
  foodList_cholineFromGlycerophosphocholineMg: FoundationFoods[] = [];

  foodList_chromiumMugram: FoundationFoods[] = [];
  foodList_copper: FoundationFoods[] = [];
  foodList_fluoride: FoundationFoods[] = [];
  foodList_folateDFE: FoundationFoods[] = [];
  foodList_folateFood: FoundationFoods[] = [];
  foodList_folateTotal: FoundationFoods[] = [];
  foodList_iodine: FoundationFoods[] = [];
  foodList_iron: FoundationFoods[] = [];
  foodList_magnesium: FoundationFoods[] = [];
  foodList_manganese: FoundationFoods[] = [];
  foodList_molybdenum: FoundationFoods[] = [];

  foodList_niacin: FoundationFoods[] = [];
  foodList_nickel: FoundationFoods[] = [];

  foodList_pantothenicAcid: FoundationFoods[] = [];
  foodList_phosphorus: FoundationFoods[] = [];
  foodList_potassium: FoundationFoods[] = [];
  foodList_riboflavin: FoundationFoods[] = [];
  foodList_selenium: FoundationFoods[] = [];
  foodList_sodium: FoundationFoods[] = [];
  foodList_sulfur: FoundationFoods[] = [];
  foodList_thiamin: FoundationFoods[] = [];
  foodList_vitaminA_RAE: FoundationFoods[] = [];
  foodList_vitaminAIU: FoundationFoods[] = [];
  foodList_vitaminB12Added: FoundationFoods[] = [];
  foodList_vitaminB12: FoundationFoods[] = [];
  foodList_vitaminB6: FoundationFoods[] = [];
  foodList_vitaminC: FoundationFoods[] = [];
  foodList_VitaminD_D2PlusD3IU: FoundationFoods[] = [];
  foodList_vitaminD_D2PlusD3: FoundationFoods[] = [];
  foodList_vitaminD2: FoundationFoods[] = [];

  foodList_vitaminD3: FoundationFoods[] = [];

  foodList_vitaminD4: FoundationFoods[] = [];
  foodList_vitaminE_Added: FoundationFoods[] = [];
  foodList_vitaminE_AlphaTocopherol: FoundationFoods[] = [];
  foodList_vitaminELabelEntryIU: FoundationFoods[] = [];
  foodList_vitaminEMcgRE: FoundationFoods[] = [];
  foodList_vitaminK_Dihydrophylloquinone: FoundationFoods[] = [];
  foodList_vitaminK_Menaquinone4: FoundationFoods[] = [];

  foodList_vitaminK_Phylloquinone: FoundationFoods[] = [];
  foodList_zinc: FoundationFoods[] = [];
  unit: Unit = { unitname: "" };


  foodData!: Foods | null;
  nutrientData: Foods | null = {
    foodId: 0,
    foodName: '',
    foodCategory: { categoryName: '' },
    nutrients: []
  };
  displayedColumns1: string[] = ['nutrientName', 'nutrientAmount', 'unit', 'portionSize', 'portionSizeUnit'];
  // Updated food list

  //chartData: ChartData<'bar'>;
  chartData: ChartData<'bar'>;
  chartOptions: ChartOptions = {
    responsive: true,
  };


  nutritionColumns = [
    { totalCalories: 220 },
    { totalCalories: 440 }
  ];

  nutrients = [
    { nutrientName: 'Total Fat', amount1: '5g', dv1: 6, amount2: '10g', dv2: 13, unit: '' },
    { nutrientName: 'Saturated Fat', amount1: '2g', dv1: 10, amount2: '4g', dv2: 20, unit: '' },
    { nutrientName: 'Cholesterol', amount1: '15mg', dv1: 5, amount2: '30mg', dv2: 10, unit: 'mg' },
    { nutrientName: 'Sodium', amount1: '240mg', dv1: 10, amount2: '480mg', dv2: 21, unit: 'mg' },
    { nutrientName: 'Total Carbohydrate', amount1: '35g', dv1: 13, amount2: '70g', dv2: 25, unit: 'g' },
    { nutrientName: 'Dietary Fiber', amount1: '6g', dv1: 21, amount2: '12g', dv2: 43, unit: 'g' },
    { nutrientName: 'Total Sugars', amount1: '7g', dv1: '', amount2: '14g', dv2: '', unit: 'g' },
    { nutrientName: 'Protein', amount1: '9g', dv1: '', amount2: '18g', dv2: '', unit: 'g' },
    { nutrientName: 'Vitamin D', amount1: '5mcg', dv1: 25, amount2: '10mcg', dv2: 50, unit: 'mcg' },
    { nutrientName: 'Calcium', amount1: '200mg', dv1: 15, amount2: '400mg', dv2: 30, unit: 'mg' },
    { nutrientName: 'Iron', amount1: '1mg', dv1: 6, amount2: '2mg', dv2: 10, unit: 'mg' },
    { nutrientName: 'Potassium', amount1: '470mg', dv1: 10, amount2: '940mg', dv2: 20, unit: 'mg' }
  ];
  authService = inject(AuthService);
  router = inject(Router);
  brandedFoodDescriptions: string[] = [];
  foundationFoodDescriptions: string[] = [];
  SRFoodDescriptions: string[] = [];
  SurveyFoodDescriptions: string[] = [];
  currentPage = 0;  // The current page number
  pageSize = 100;   // Number of items per page
  totalPages = 0;   // Total number of pages
  monoUnsaturatedFatPercentage: string;
  polyUnsaturatedFatPercentage: string;
  solubleFiberPercentage: string;
  insolubleFiberPercentage: string;
  vitaminAPercentage: string;
  vitaminCPercentage: string;
  thiaminPercentage: string;
  riboflavinPercentage: string;
  niacinPercentage: string;
  vitaminB6Percentage: string;
  folatePercentage: string;
  vitaminB12Percentage: string;
  phosphorusPercentage: string;
  magnesiumPercentage: string;
  zincPercentage: string;
  cholinePercentage: string;
  fluoridePercentage: string;
  isLoading: boolean = false;
  nutritionLabelDual: ({ nutrientName: string; unit: string; percentage: string; nutrientAmount: string; isSubNutrient?: undefined; isSubSubNutrient?: undefined; note?: undefined; } | { nutrientName: string; nutrientAmount: string; unit: string; percentage: string; isSubNutrient: boolean; isSubSubNutrient?: undefined; note?: undefined; } | { nutrientName: string; nutrientAmount: string; unit: string; isSubNutrient: boolean; percentage?: undefined; isSubSubNutrient?: undefined; note?: undefined; } | { nutrientName: string; nutrientAmount: string; unit: string; percentage: string; isSubNutrient: boolean; isSubSubNutrient: boolean; note?: undefined; } | { nutrientName: string; nutrientAmount: string; unit: string; percentage: string | undefined; note: string | undefined; isSubNutrient?: undefined; isSubSubNutrient?: undefined; })[];
  showFDA_Dual: boolean;
  showFDA_Linear: boolean;
  nutritionLabelLinear: ({ nutrientName: string; unit: string; percentage: string; nutrientAmount: string; isSubNutrient?: undefined; isSubSubNutrient?: undefined; note?: undefined; } | { nutrientName: string; nutrientAmount: string; unit: string; percentage: string; isSubNutrient: boolean; isSubSubNutrient?: undefined; note?: undefined; } | { nutrientName: string; nutrientAmount: string; unit: string; isSubNutrient: boolean; percentage?: undefined; isSubSubNutrient?: undefined; note?: undefined; } | { nutrientName: string; nutrientAmount: string; unit: string; percentage: string; isSubNutrient: boolean; isSubSubNutrient: boolean; note?: undefined; } | { nutrientName: string; nutrientAmount: string; unit: string; percentage: string | undefined; note: string | undefined; isSubNutrient?: undefined; isSubSubNutrient?: undefined; })[];
  showFDA_MicroNut: boolean;
  nutritionLabel_MicroNut: ({ nutrientName: string; nutrientAmount: string; unit: string; percentage: string; isSubNutrient?: undefined; isSubSubNutrient?: undefined; note?: undefined; } | { nutrientName: string; nutrientAmount: string; unit: string; percentage: string; isSubNutrient: boolean; isSubSubNutrient?: undefined; note?: undefined; } | { nutrientName: string; nutrientAmount: string; unit: string; isSubNutrient: boolean; percentage?: undefined; isSubSubNutrient?: undefined; note?: undefined; } | { nutrientName: string; nutrientAmount: string; unit: string; percentage: string; isSubNutrient: boolean; isSubSubNutrient: boolean; note?: undefined; } | { nutrientName: string; nutrientAmount: string; unit: string; percentage: string | undefined; note: string | undefined; isSubNutrient?: undefined; isSubSubNutrient?: undefined; })[];
  showFDA_Simplified: boolean;
  insignificantNutrients: string[];
  coreNutrients: string[] = [
    'Total Fat', 'Sodium',
    'Total Carbohydrate', 'Total Sugars', 'Protein'
  ];
  recipeNames: string[] = []; // Array to store recipe names
  loggedInUser: any = null;
  //brandedFoodData: import("/Users/shilpibhattacharyya/Downloads/healthandnutrition/src/app/food-data").FoodNutrient[];
  brandedFoodData!: FoodNutrient[] | null;
  surveyFoodData!: FoodNutrient[] | null;
  foundationFoodData!: FoodNutrient[] | null;
  SRFoodData!: FoodNutrient[] | null;

  brandedFood!: BrandedFoods | null;
  foundationFood!: FoundationFood | null;
  SRFood!: SRFoods | null;
  SurveyFood!: SurveyFoods | null;
  nutrientName: string = '';



  loading: boolean = false;
  error: string | null = null;
  constructor(public dialog: MatDialog, private nfpService: NFPService, private cdr: ChangeDetectorRef, private foodService: FoodService, private foodNutrientsService: FoodNutrientsService) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<NutrientFoodDTO>;
  @ViewChild(MatSort) sort!: MatSort;



  ngOnInit(): void {
    this.loadRecipeNames();

    //this.isPremiumUser = localStorage.getItem('isPremium'); // Get user subscription status
    this.isPremiumUser = localStorage.getItem('isPremium') === 'True';

    // Customize sorting logic
    this.dataSourceNutrient.sortingDataAccessor = (item: NutrientFoodDTO, sortHeaderId: string): string | number => {
      switch (sortHeaderId) {
        case 'amount':
          return parseFloat(item.amount) || 0; // Convert 'amount' to number
        case 'foodName':
          return item.foodName.toLowerCase(); // Sort by food name (case-insensitive)
        case 'unitName':
          return item.unitName.toLowerCase(); // Sort by unit name (case-insensitive)
        default:
          return ''; // Default fallback
      }
    };
    
    
    
    this.foodNutrientsService.getNutrientNames().subscribe((names) => {
      this.nutrientNamesForDropdown = names;
      // Set data source after nutrient names are fetched
    });

    //this.getAllFood();
    /*this.foodService.getAllFood()
    .subscribe((food: Food[]) => this.filteredFoodList = food);*/

    // Call the service method to stream all food descriptions
    // Start receiving food descriptions from the backend stream
    // Start receiving food descriptions from the backend stream
    this.foodNutrientsService.getBrandedFoodDescriptionsStream().subscribe(
      async (data) => {
        if (Array.isArray(data)) {
          const chunkSize = 10;  // Adjust this size based on performance testing
          for (let i = 0; i < data.length; i += chunkSize) {
            const chunk = data.slice(i, i + chunkSize);
            const newDescriptions = chunk.filter(item => !this.brandedFoodDescriptions.includes(item));
            this.brandedFoodDescriptions.push(...newDescriptions);

            // Introduce a small delay to allow UI updates and avoid blocking the main thread
            await new Promise(resolve => setTimeout(resolve, 0));
          }
        } else {
          console.error('Received non-iterable data:', data);
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );

    this.foodNutrientsService.getFoundationFoodDescriptionsStream().subscribe(
      async (data) => {
        if (Array.isArray(data)) {
          const chunkSize = 10;  // Adjust this size based on performance testing
          for (let i = 0; i < data.length; i += chunkSize) {
            const chunk = data.slice(i, i + chunkSize);
            const newDescriptions = chunk.filter(item => !this.foundationFoodDescriptions.includes(item));
            this.foundationFoodDescriptions.push(...newDescriptions);

            // Introduce a small delay to allow UI updates and avoid blocking the main thread
            await new Promise(resolve => setTimeout(resolve, 0));
          }
        } else {
          console.error('Received non-iterable data:', data);
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );

    this.foodNutrientsService.getSRFoodDescriptionsStream().subscribe(
      async (data) => {
        if (Array.isArray(data)) {
          const chunkSize = 10;  // Adjust this size based on performance testing
          for (let i = 0; i < data.length; i += chunkSize) {
            const chunk = data.slice(i, i + chunkSize);
            const newDescriptions = chunk.filter(item => !this.SRFoodDescriptions.includes(item));
            this.SRFoodDescriptions.push(...newDescriptions);

            // Introduce a small delay to allow UI updates and avoid blocking the main thread
            await new Promise(resolve => setTimeout(resolve, 0));
          }
        } else {
          console.error('Received non-iterable data:', data);
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );

    this.foodNutrientsService.getSurveyFoodDescriptionsStream().subscribe(
      async (data) => {
        if (Array.isArray(data)) {
          const chunkSize = 10;  // Adjust this size based on performance testing
          for (let i = 0; i < data.length; i += chunkSize) {
            const chunk = data.slice(i, i + chunkSize);
            const newDescriptions = chunk.filter(item => !this.SurveyFoodDescriptions.includes(item));
            this.SurveyFoodDescriptions.push(...newDescriptions);

            // Introduce a small delay to allow UI updates and avoid blocking the main thread
            await new Promise(resolve => setTimeout(resolve, 0));
          }
        } else {
          console.error('Received non-iterable data:', data);
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
    this.dataSourceNutrient.paginator = this.paginator;
    this.sort.sort({ id: 'amount', start: 'asc', disableClear: false });
    this.dataSourceNutrient.sort = this.sort; // Connect sorting


  }



  // Function to clear the input field
  clearInput() {
    this.inputValue = '';
  }


  applyFilter() {
    if (this.selectedCategory) {
      this.filteredFoodListDTO = this.foodList.filter(
        (food) => food.foodCategoryDescription === this.selectedCategory
      );
    } else {
      this.filteredFoodListDTO = this.foodList;
    }
    // Update dataSource and apply paginator after filtering
    this.dataSourceNutrient = new MatTableDataSource(this.filteredFoodListDTO);
    this.dataSourceNutrient.paginator = this.paginator;
    
  }

  reset() {
    this.filteredFoodListDTO = [];
    this.dataSourceNutrient.data = [];
    this.dataSourceNutrient.paginator=this.paginator;
    this.selectedCategory = '';
  }

  navigateToPage() {
    // Example of navigating to 'page1'. Adjust as needed.
    this.router.navigate(['/login']);
  }

  addIngredient() {
    if (this.newIngredient.trim() && this.newAmount !== null) {
      // Add the ingredient to the local list
      const tempIngredient = {
        name: this.newIngredient.trim(),
        amount: this.newAmount
      };
      this.ingredients = [...this.ingredients, tempIngredient];

      // Reset input fields
      this.newIngredient = '';
      this.newAmount = null;
    } else {
      alert('Please enter a valid ingredient and amount.');
    }
  }


  // Load recipe names from the backend
  loadRecipeNames() {

    this.authService.getCurrentUser().subscribe({
      next: (currentUser) => {
        if (currentUser) {
          console.log('Current User:', currentUser);
          // Use currentUser in further logic
          this.loggedInUser = currentUser;
          this.recipeNames = this.loggedInUser.recipes.map((recipe: { name: any; }) => recipe.name) || [];

        } else {
          console.log('No user found.');
        }
      },
      error: (err) => {
        console.error('Error fetching current user:', err);
      }
    });

  }
  handleNutritionOption(option: string) {
    switch (option) {
      case 'generateNutritionLabel':
        this.generateNutritionLabel();
        break;
      case 'generateNutritionLabelFDA':
        this.generateNutritionLabelFDA();
        break;
      case 'generateNutritionLabelFDA_MicroNutSidebySide':
        this.generateNutritionLabelFDA_MicroNutSidebySide();
        break;
      case 'NutritionLabelFDA_VoluntaryNuts':
        this.NutritionLabelFDA_VoluntaryNuts();
        break;
      case 'generateNutritionLabelFDA_Tabular':
        this.generateNutritionLabelFDA_Tabular();
        break;
      case 'generateNutritionLabelFDA_Dual':
        this.generateNutritionLabelFDA_Dual();
        break;
      case 'generateNutritionLabelFDA_Linear':
        this.generateNutritionLabelFDA_Linear();
        break;
      case 'generateNutritionLabelFDA_LinearInt':
        this.generateNutritionLabelFDA_LinearInt();
        break;
      case 'generateNutritionLabelFDA_Simplified':
        this.generateNutritionLabelFDA_Simplified();
        break;
      default:
        console.warn('Unknown option selected:', option);
    }
  }

  /* // Handle dropdown selection
  onRecipeSelected(selectedRecipe: string): void {
    this.foodName = selectedRecipe;
  } */
  onRecipeSelect(selectedRecipe: string) {
    const recipe = this.loggedInUser.recipes.find((r: { name: any; }) => r.name === selectedRecipe);
    this.numberOfServings = recipe.numberOfServings;
    this.servingSize = recipe.servingSize;
    this.ingredients = recipe ? recipe.ingredients : [];
  }
  onSearchRecipe(): void {
    // Implement any additional logic here if needed
    console.log('Current input:', this.foodName);
  }

  saveRecipe() {
    this.loadRecipeNames();
    if (this.foodName.trim() && this.ingredients.length > 0 && this.numberOfServings > 0 && this.servingSize > 0) {
      const recipePayload = {
        name: this.foodName.trim(),
        numberOfServings: this.numberOfServings,
        servingSize: this.servingSize,
        ingredients: this.ingredients
      };

      // Ensure recipes is an array
      if (!this.loggedInUser.recipes) {
        this.loggedInUser.recipes = [];
      }
      this.loggedInUser.recipes.push(recipePayload); // Add the new recipe to the array

      // Update the user's recipes in the backend
      this.authService.updateUserRecipe(this.loggedInUser!, recipePayload).subscribe({
        next: (response: any) => {
          console.log('Recipe saved:', response);
          alert('Recipe saved successfully!');
          this.resetForm();
        },
        error: (error: any) => {
          console.error('Error saving recipe:', error);
          alert('Failed to save the recipe. Please try again.');
        },
      });
    } else {
      alert('Please enter a valid recipe name and add at least one ingredient.');
    }
  }

  deleteRecipe() {
    // Ensure the recipes list exists
    if (this.foodName.trim() && this.ingredients.length > 0 && this.numberOfServings > 0 && this.servingSize > 0) {


      // Update the user's recipes in the backend
      this.authService.deleteUserRecipe(this.loggedInUser!, this.foodName.trim()).subscribe({
        next: (response: any) => {
          console.log('Recipe delete:', response);
          alert('Recipe deleted successfully!');
          this.resetForm();
        },
        error: (error: any) => {
          console.error('Error deleting recipe:', error);
          alert('Failed to delete the recipe. Please try again.');
        },
      });
    } else {
      alert('Not a valid recipe name.');
    }
  }

  resetForm() {
    this.foodName = '';
    this.ingredients = [];
    this.numberOfServings = 0;
    this.servingSize = 0;
  }
  removeIngredient(ingredient: any) {
    // Remove the ingredient from the local list
    this.ingredients = this.ingredients.filter((i) => i !== ingredient);
  }


  generateNutritionLabel() {
    //const nutrientRequests = this.ingredients.map((ingredient) =>
    /*  this.foodNutrientsService.getNutrients(ingredient.name).pipe(
       catchError((err) => {
         console.error(`Error fetching nutrients for ${ingredient.name}`, err);
         return of({
           foodName: ingredient.name,
           nutrients: [],
         });
       })
     ) */

    const nutrientRequests = this.ingredients.map((ingredient) => this.foodNutrientsService.getBrandedFoodNutrients(ingredient.name).pipe(
      switchMap((brandedResult) => {
        if (brandedResult.length > 0) {
          return of({ foodName: ingredient.name, nutrients: brandedResult });
        } else {
          return this.foodNutrientsService.getFoundationFoodNutrients(ingredient.name).pipe(
            switchMap((foundationResult) => {
              if (foundationResult.length > 0) {
                return of({ foodName: ingredient.name, nutrients: foundationResult });
              } else {
                return this.foodNutrientsService.getSRFoodNutrients(ingredient.name).pipe(
                  switchMap((srResult) => {
                    if (srResult.length > 0) {
                      return of({ foodName: ingredient.name, nutrients: srResult });
                    } else {
                      // No nutrients found in any of the lists
                      return of({ foodName: ingredient.name, nutrients: [] });
                    }
                  })
                );
              }
            })
          );
        }
      }),
      catchError((err) => {
        console.error(`Error fetching nutrients for ${ingredient.name}`, err);
        return of({
          foodName: ingredient.name,
          nutrients: [],
        });
      })
    )
    );

    forkJoin(nutrientRequests).subscribe(
      (results) => {
        const cumulativeNutrients: { [key: string]: number } = {};

        results.forEach((result, index) => {
          const ingredient = this.ingredients[index];

          if (result.nutrients.length > 0) {
            const multiplier = (ingredient.amount || 0) / 100;

            result.nutrients.forEach((nutrient) => {
              const nutrientName = nutrient.nutrient.name;
              const adjustedAmount = nutrient.amount * multiplier;

              if (cumulativeNutrients[nutrientName]) {
                cumulativeNutrients[nutrientName] += adjustedAmount;
              } else {
                cumulativeNutrients[nutrientName] = adjustedAmount;
              }
            });
          }
        });

        // Update nutritionLabel for UI display
        this.nutritionLabel = Object.entries(cumulativeNutrients).map(([name, value]) => ({
          nutrientName: name,
          nutrientAmount: Math.round(value).toString(), // Rounded to 2 decimal places
          unit: results[0]?.nutrients.find(n => n.nutrient.name === name)?.nutrient.unitName || 'N/A',
        }));
      },
      (error) => {
        console.error('Error fetching nutrient data:', error);
      }
    );
    // this.generateNutritionLabelFDA();
    // Logic to generate data for mat-table
    this.showMatTable = true;  // Show mat-table results
    this.showFDA = false;      // Hide FDA label if previously displayed
    this.showFDA_VolNut = false;
    this.showFDA_Tab = false;
    this.showFDA_Dual = false;
    this.showFDA_Linear = false;
    this.showFDA_LinearInt = false;
    this.showFDA_MicroNut = false;
    this.showFDA_Simplified = false;
  }

  generateNutritionLabelFDA() {
    // Your existing logic to generate the label
    this.nutritionLabel = this.generateLabelData(); // Assume this populates the label
    this.showFDA = true;      // Show FDA label
    this.showMatTable = false; // Hide mat-table if previously displayed 
    this.showFDA_VolNut = false;
    this.showFDA_Tab = false;
    this.showFDA_Dual = false;
    this.showFDA_Linear = false;
    this.showFDA_LinearInt = false;
    this.showFDA_MicroNut = false;
    this.showFDA_Simplified = false;
  }

  generateNutritionLabelFDA_Tabular() {
    // Your existing logic to generate the label
    this.nutritionLabel = this.generateLabelData(); // Assume this populates the label
    this.showFDA = false;      // Show FDA label
    this.showMatTable = false; // Hide mat-table if previously displayed 
    this.showFDA_VolNut = false;
    this.showFDA_Tab = true;
    this.showFDA_Dual = false;
    this.showFDA_Linear = false;
    this.showFDA_LinearInt = false;
    this.showFDA_MicroNut = false;
    this.showFDA_Simplified = false;
  }

  generateNutritionLabelFDA_Dual() {
    // Your existing logic to generate the label
    this.nutritionLabel = this.generateLabelData(); // Assume this populates the label
    this.showFDA = false;      // Show FDA label
    this.showMatTable = false; // Hide mat-table if previously displayed 
    this.showFDA_VolNut = false;
    this.showFDA_Tab = false;
    this.showFDA_Dual = true;
    this.showFDA_Linear = false;
    this.showFDA_LinearInt = false;
    this.showFDA_MicroNut = false;
    this.showFDA_Simplified = false;
  }

  generateNutritionLabelFDA_Linear() {
    // Your existing logic to generate the label
    this.nutritionLabel = this.generateLabelData(); // Assume this populates the label
    this.showFDA = false;      // Show FDA label
    this.showMatTable = false; // Hide mat-table if previously displayed 
    this.showFDA_VolNut = false;
    this.showFDA_Tab = false;
    this.showFDA_Dual = false;
    this.showFDA_Linear = true;
    this.showFDA_LinearInt = false;
    this.showFDA_MicroNut = false;
    this.showFDA_Simplified = false;
  }

  generateNutritionLabelFDA_LinearInt() {
    // Your existing logic to generate the label
    this.nutritionLabel = this.generateLabelData(); // Assume this populates the label
    this.showFDA = false;      // Show FDA label
    this.showMatTable = false; // Hide mat-table if previously displayed 
    this.showFDA_VolNut = false;
    this.showFDA_Tab = false;
    this.showFDA_Dual = false;
    this.showFDA_Linear = false;
    this.showFDA_LinearInt = true;
    this.showFDA_MicroNut = false;
    this.showFDA_Simplified = false;
  }

  generateNutritionLabelFDA_Simplified() {
    // Your existing logic to generate the label
    this.nutritionLabel = this.generateLabelData(); // Assume this populates the label
    this.showFDA = false;      // Show FDA label
    this.showMatTable = false; // Hide mat-table if previously displayed 
    this.showFDA_VolNut = false;
    this.showFDA_Tab = false;
    this.showFDA_Dual = false;
    this.showFDA_Linear = false;
    this.showFDA_LinearInt = false;
    this.showFDA_MicroNut = false;
    this.showFDA_Simplified = true;
  }

  generateNutritionLabelFDA_MicroNutSidebySide() {
    // Your existing logic to generate the label
    this.nutritionLabel = this.generateLabelData(); // Assume this populates the label
    this.showFDA = false;      // Show FDA label
    this.showMatTable = false; // Hide mat-table if previously displayed 
    this.showFDA_VolNut = false;
    this.showFDA_Tab = false;
    this.showFDA_Dual = false;
    this.showFDA_Linear = false;
    this.showFDA_LinearInt = false;
    this.showFDA_MicroNut = true;
    this.showFDA_Simplified = false;
  }



  NutritionLabelFDA_VoluntaryNuts() {
    // Your existing logic to generate the label
    this.nutritionLabel = this.LabelData_VolNut(); // Assume this populates the label
    this.showFDA = false;      // Show FDA label
    this.showMatTable = false; // Hide mat-table if previously displayed  
    this.showFDA_VolNut = true;
    this.showFDA_Tab = false;
    this.showFDA_Dual = false;
    this.showFDA_Linear = false;
    this.showFDA_LinearInt = false;
    this.showFDA_MicroNut = false;
    this.showFDA_Simplified = false;
  }

  generateLabelData() {
    const nutrientRequests = this.ingredients.map((ingredient) => this.foodNutrientsService.getBrandedFoodNutrients(ingredient.name).pipe(
      switchMap((brandedResult) => {
        if (brandedResult.length > 0) {
          return of({ foodName: ingredient.name, nutrients: brandedResult });
        } else {
          return this.foodNutrientsService.getFoundationFoodNutrients(ingredient.name).pipe(
            switchMap((foundationResult) => {
              if (foundationResult.length > 0) {
                return of({ foodName: ingredient.name, nutrients: foundationResult });
              } else {
                return this.foodNutrientsService.getSRFoodNutrients(ingredient.name).pipe(
                  switchMap((srResult) => {
                    if (srResult.length > 0) {
                      return of({ foodName: ingredient.name, nutrients: srResult });
                    } else {
                      // No nutrients found in any of the lists
                      return of({ foodName: ingredient.name, nutrients: [] });
                    }
                  })
                );
              }
            })
          );
        }
      }),
      catchError((err) => {
        console.error(`Error fetching nutrients for ${ingredient.name}`, err);
        return of({
          foodName: ingredient.name,
          nutrients: [],
        });
      })
    )
    );



    forkJoin(nutrientRequests).subscribe((results) => {
      //const cumulativeNutrients: { [key: string]: number } = {};
      const cumulativeNutrients: { [key: string]: { value: number; unit: string } } = {};


      results.forEach((result, index) => {
        const ingredient = this.ingredients[index];
        //const ingredientServingSize = ingredient.amount; // Actual serving size for the ingredient
        const factor = (ingredient.amount || 100) / 100;

        if (result.nutrients.length > 0) {
          result.nutrients.forEach((nutrient) => {
            const nutrientName = nutrient.nutrient.name;
            const nutrientUnit = nutrient.nutrient.unitName;

            // Only process Energy nutrients
            if (nutrientName === 'Energy') {
              let energyValueInKcal = nutrient.amount; // Default to the original value

              if (nutrientUnit === 'KJ') {
                // Convert KJ to KCAL (1 KCAL = 4.184 KJ)
                energyValueInKcal = nutrient.amount / 4.184;
              }

              if (nutrientUnit === 'KCAL' || nutrientUnit === 'KJ') {
                // Ensure the unit is stored as KCAL
                if (cumulativeNutrients[nutrientName]) {
                  cumulativeNutrients[nutrientName].value =
                    (cumulativeNutrients[nutrientName].value || 0) + energyValueInKcal * factor;
                } else {
                  cumulativeNutrients[nutrientName] = {
                    value: energyValueInKcal * factor,
                    unit: 'KCAL', // Always store as KCAL
                  };
                }
              }
            } else {
              // Process other nutrients
              if (cumulativeNutrients[nutrientName]) {
                cumulativeNutrients[nutrientName].value =
                  (cumulativeNutrients[nutrientName].value || 0) + nutrient.amount * factor;
              } else {
                cumulativeNutrients[nutrientName] = {
                  value: nutrient.amount * factor,
                  unit: nutrientUnit,
                };
              }
            }
          });
        }
      });

      // After all nutrients are accumulated, adjust for the number of servings
      Object.keys(cumulativeNutrients).forEach((nutrientName) => {
        cumulativeNutrients[nutrientName].value /= this.numberOfServings;  // Now adjust for servings
      });

      // Calculate totalCalories from Energy (Atwater Specific Factors)
      /* this.totalCalories = Math.round(
        cumulativeNutrients['Energy (Atwater Specific Factors)'] ||
        cumulativeNutrients['Energy (Atwater General Factors)'] ||
        cumulativeNutrients['Energy'] ||
        0
      ); */

      /* this.totalCalories = Math.round(
        cumulativeNutrients['Energy (Atwater Specific Factors)']?.unit === 'KCAL'
          ? cumulativeNutrients['Energy (Atwater Specific Factors)']?.value
          : cumulativeNutrients['Energy (Atwater General Factors)']?.unit === 'KCAL'
          ? cumulativeNutrients['Energy (Atwater General Factors)']?.value
          : cumulativeNutrients['Energy']?.unit === 'KCAL'
          ? cumulativeNutrients['Energy']?.value
          : 0
      ); */
      this.totalCalories = Math.round(
        ((cumulativeNutrients['Energy (Atwater Specific Factors)']?.unit === 'KCAL'
          ? cumulativeNutrients['Energy (Atwater Specific Factors)']?.value
          : 0) || 0) +
        ((cumulativeNutrients['Energy']?.unit === 'KCAL'
          ? cumulativeNutrients['Energy']?.value
          : 0) || 0)
      );


      // Nutrient totals based on serving size
      this.totalFat = this.roundFatValue(
        (cumulativeNutrients['Fatty acids, total monounsaturated']?.value || 0) +
        (cumulativeNutrients['Fatty acids, total polyunsaturated']?.value || 0) +
        (cumulativeNutrients['Fatty acids, total trans']?.value || 0) +
        (cumulativeNutrients['Fatty acids, total saturated']?.value || 0) +
        (cumulativeNutrients['Fatty acids, total trans-monoenoic']?.value || 0) +
        (cumulativeNutrients['Fatty acids, total trans-dienoic']?.value || 0)
      );


      this.totalFat = this.roundFatValue(this.totalFat ||
        cumulativeNutrients['Total fat (NLEA)']?.value || cumulativeNutrients['Total lipid (fat)']?.value || 0
      );

      //this.totalFat = this.roundFatValue(cumulativeNutrients['Total fat (NLEA)'] || 0);
      this.saturatedFat = this.roundFatValue(cumulativeNutrients['Fatty acids, total saturated']?.value || 0);
      this.transFat = this.roundFatValue(cumulativeNutrients['Fatty acids, total trans']?.value || 0);


      // Cholesterol handling
      if (cumulativeNutrients['Cholesterol']) {
        let cholesterolValue = cumulativeNutrients['Cholesterol'];
        if (cholesterolValue.value < 2) {
          this.cholesterol = '< 2 ';
        } else if (cholesterolValue.value >= 2 && cholesterolValue.value <= 5) {
          this.cholesterol = 'less than 5';
        } else {
          // Round to the nearest 5 mg increment
          let roundedCholesterol = Math.round(cholesterolValue.value / 5) * 5;
          this.cholesterol = `${roundedCholesterol} `;
        }
      } else {
        this.cholesterol = '0 ';  // Default case when cholesterol is not available
      }

      const cholesterolNumericValue = parseFloat(this.cholesterol) || 0;


      // Total Carbohydrate rounding logic
      const totalCarbohydrateRaw = cumulativeNutrients['Carbohydrate, by difference']?.value || 0;
      this.totalCarbohydrateRaw = totalCarbohydrateRaw; // Store raw value for percentage calculation
      if (totalCarbohydrateRaw < 1) {
        this.totalCarbohydrate = '< 1 ';
      } else {
        this.totalCarbohydrate = `${Math.round(totalCarbohydrateRaw)} `;
      }

      // Dietary Fiber rounding logic
      const dietaryFiberRaw = cumulativeNutrients['Fiber, total dietary']?.value || 0;
      this.dietaryFiberRaw = dietaryFiberRaw; // Store raw value for percentage calculation
      if (dietaryFiberRaw < 0.5) {
        this.dietaryFiber = '0 ';
      } else if (dietaryFiberRaw < 1) {
        this.dietaryFiber = '< 1 ';
      } else {
        this.dietaryFiber = `${Math.round(dietaryFiberRaw)} `;
      }
      this.addedSugars = Math.round(cumulativeNutrients['Sucrose']?.value);
      this.totalSugars = Math.round(cumulativeNutrients['Sugars, Total']?.value);

      //this.totalCarbohydrate = cumulativeNutrients['Carbohydrate, by difference'] || 0;
      //this.dietaryFiber = cumulativeNutrients['Fiber, total dietary'] || 0;
      this.proteinRaw = cumulativeNutrients['Protein']?.value || 0;

      if (this.proteinRaw < 0.5) {
        this.protein = '< 0.5 ';
      } else if (this.proteinRaw >= 0.5 && this.proteinRaw < 1) {
        this.protein = 'less than 1 ';
      } else {
        this.protein = `${Math.round(this.proteinRaw)} `; // Round to nearest 1 g increment
      }

      this.vitaminD = Math.round(cumulativeNutrients['Vitamin D (D2 + D3)']?.value || 0);
      this.calcium = Math.round(cumulativeNutrients['Calcium, Ca']?.value || 0);
      this.iron = Math.round(cumulativeNutrients['Iron, Fe']?.value || 0);

      this.sodium = cumulativeNutrients['Sodium, Na']?.value || 0;
      this.potassium = cumulativeNutrients['Potassium, K']?.value || 0;

      // Apply rounding rules
      const roundedSodium = this.roundSodiumPotassium(this.sodium);
      const roundedPotassium = this.roundSodiumPotassium(this.potassium);

      // For Nutrition Label
      this.sodiumFormatted = roundedSodium < 5 ? '<5' : roundedSodium.toString();
      this.potassiumFormatted = roundedPotassium < 5 ? '<5' : roundedPotassium.toString();




      // Example for Vitamin D
      this.vitaminDPercentage = this.formatPercentageDV((this.vitaminD / this.vitaminDDV) * 100);
      // Example for Calcium
      this.calciumPercentage = this.formatPercentageDV((this.calcium / this.calciumDV) * 100);
      // Example for Iron
      this.ironPercentage = this.formatPercentageDV((this.iron / this.ironDV) * 100);
      // Example for Potassium
      this.potassiumPercentage = this.formatPercentageDV((this.potassium / this.potassiumDV) * 100);
      // Default addedSugars to 0 if no value is found
      this.addedSugars = this.addedSugars ?? 0;
      this.addedSugarsPercentage = Math.round((this.addedSugars / this.addedSugarsDV) * 100).toString();

      // Handling labels for special cases when values are very low
      const lowDVStatement = 'Contains less than 2% of the Daily Value of this nutrient.';
      if (this.vitaminDPercentage === '*') {
        this.vitaminDNote = lowDVStatement;
      }
      if (this.calciumPercentage === '*') {
        this.calciumNote = lowDVStatement;
      }
      if (this.ironPercentage === '*') {
        this.ironNote = lowDVStatement;
      }
      if (this.potassiumPercentage === '*') {
        this.potassiumNote = lowDVStatement;
      }

      // Optional: If all are low and key nutrients Vitamin A, C, Calcium, Iron, include:
      const keyNutrientsNote =
        'Not a significant source of Vitamin A, Vitamin C, Calcium, and Iron.';
      if (
        this.vitaminDPercentage === '*' &&
        this.calciumPercentage === '*' &&
        this.ironPercentage === '*' &&
        this.potassiumPercentage === '*'
      ) {
        this.generalLowDVNote = keyNutrientsNote;
      }


      //this.protein = cumulativeNutrients['Protein'] || 0;


      // Calculate DV percentages based on per-serving values
      this.totalFatPercentage = Math.round((this.totalFat / this.totalFatDV) * 100).toString();
      this.saturatedFatPercentage = Math.round((this.saturatedFat / this.saturatedFatDV) * 100).toString();
      this.cholesterolPercentage = Math.round((cholesterolNumericValue / this.cholesterolDV) * 100).toString();

      this.sodiumPercentage = Math.round((roundedSodium / this.sodiumDV) * 100).toString();
      this.totalCarbohydratePercentage = Math.round((this.totalCarbohydrateRaw / this.totalCarbohydrateDV) * 100).toString();
      this.dietaryFiberPercentage = Math.round((this.dietaryFiberRaw / this.dietaryFiberDV) * 100).toString();

      this.proteinPercentage = Math.round((this.proteinRaw / this.proteinDV) * 100).toString();
      // this.vitaminDPercentage = ((this.vitaminD / this.vitaminDDV) * 100).toFixed(1);
      // this.calciumPercentage = ((this.calcium / this.calciumDV) * 100).toFixed(1);
      // this.ironPercentage = ((this.iron / this.ironDV) * 100).toFixed(1);
      // this.potassiumPercentage = ((roundedPotassium / this.potassiumDV) * 100).toFixed(1);


      // Update the nutrition label with the new per-serving values
      this.nutritionLabel = [
        {
          nutrientName: '', unit: '', percentage: '% Daily Value*',
          nutrientAmount: ''
        },
        { nutrientName: 'Total Fat', nutrientAmount: this.totalFat.toString(), unit: 'g', percentage: this.totalFatPercentage },
        { nutrientName: 'Saturated Fat', nutrientAmount: this.saturatedFat.toString(), unit: 'g', percentage: this.saturatedFatPercentage, isSubNutrient: true },
        { nutrientName: 'Trans Fat', nutrientAmount: this.transFat.toString(), unit: 'g', isSubNutrient: true },
        { nutrientName: 'Cholesterol', nutrientAmount: (Number(this.cholesterol) || 0).toString(), unit: 'mg', percentage: this.cholesterolPercentage },
        // { nutrientName: 'Sodium', nutrientAmount: this.sodium.toFixed(2), unit: 'mg', percentage: this.sodiumPercentage },
        {
          nutrientName: 'Sodium',
          nutrientAmount: this.sodiumFormatted,
          unit: 'mg',
          percentage: this.sodiumPercentage,
        },
        { nutrientName: 'Total Carbohydrate', nutrientAmount: (Number(this.totalCarbohydrate) || 0).toString(), unit: 'g', percentage: this.totalCarbohydratePercentage },
        { nutrientName: 'Dietary Fiber', nutrientAmount: (Number(this.dietaryFiber) || 0).toString(), unit: 'g', percentage: this.dietaryFiberPercentage, isSubNutrient: true },
        { nutrientName: 'Total Sugars', nutrientAmount: (Number(this.totalSugars) || 0).toString(), unit: 'g', percentage: '', isSubNutrient: true },
        { nutrientName: 'Added Sugars', nutrientAmount: (Number(this.addedSugars) || 0).toString(), unit: 'g', percentage: this.addedSugarsPercentage, isSubNutrient: true, isSubSubNutrient: true },
        { nutrientName: 'Protein', nutrientAmount: (Number(this.protein) || 0).toString(), unit: 'g', percentage: this.proteinPercentage },
        // { nutrientName: 'Vitamin D', nutrientAmount: this.vitaminD.toFixed(2), unit: 'mcg', percentage: this.vitaminDPercentage },
        // { nutrientName: 'Calcium', nutrientAmount: this.calcium.toFixed(2), unit: 'mg', percentage: this.calciumPercentage },
        // { nutrientName: 'Iron', nutrientAmount: this.iron.toFixed(2), unit: 'mg', percentage: this.ironPercentage },
        // { nutrientName: 'Potassium', nutrientAmount: this.potassium.toFixed(2), unit: 'mg', percentage: this.potassiumPercentage },
        // Vitamins & Minerals with low DV handling
        // Vitamins & Minerals with low DV handling
        { nutrientName: 'Vitamin D', nutrientAmount: this.vitaminD.toString(), unit: 'mcg', percentage: this.vitaminDPercentage !== '*' ? this.vitaminDPercentage : undefined, note: this.vitaminDNote || undefined },
        { nutrientName: 'Calcium', nutrientAmount: this.calcium.toString(), unit: 'mg', percentage: this.calciumPercentage !== '*' ? this.calciumPercentage : undefined, note: this.calciumNote || undefined },
        { nutrientName: 'Iron', nutrientAmount: this.iron.toString(), unit: 'mg', percentage: this.ironPercentage !== '*' ? this.ironPercentage : undefined, note: this.ironNote || undefined },

        {
          nutrientName: 'Potassium',
          nutrientAmount: this.potassiumFormatted,
          unit: 'mg',
          percentage: this.potassiumPercentage !== '*' ? this.potassiumPercentage : undefined, note: this.potassiumNote || undefined
        },
        // { nutrientName: 'Potassium', nutrientAmount: this.potassium.toFixed(2), unit: 'mg', percentage: this.potassiumPercentage !== '*' ? this.potassiumPercentage : undefined, note: this.potassiumNote || undefined },

      ];
      // Include general note for low key nutrients if applicable
      /*   if (this.generalLowDVNote) {
          this.nutritionLabel.push({ nutrientName: 'General Note', nutrientAmount: '', unit: '', percentage: '', note: this.generalLowDVNote });
        }  */

      this.nutritionLabelDual = [
        {
          nutrientName: '', unit: '', percentage: '% DV*',
          nutrientAmount: ''
        },
        { nutrientName: 'Total Fat', nutrientAmount: this.totalFat.toString(), unit: 'g', percentage: this.totalFatPercentage },
        { nutrientName: 'Saturated Fat', nutrientAmount: this.saturatedFat.toString(), unit: 'g', percentage: this.saturatedFatPercentage, isSubNutrient: true },
        { nutrientName: 'Trans Fat', nutrientAmount: this.transFat.toString(), unit: 'g', isSubNutrient: true },
        { nutrientName: 'Cholesterol', nutrientAmount: (Number(this.cholesterol) || 0).toString(), unit: 'mg', percentage: this.cholesterolPercentage },
        // { nutrientName: 'Sodium', nutrientAmount: this.sodium.toFixed(2), unit: 'mg', percentage: this.sodiumPercentage },
        {
          nutrientName: 'Sodium',
          nutrientAmount: this.sodiumFormatted,
          unit: 'mg',
          percentage: this.sodiumPercentage,
        },
        { nutrientName: 'Total Carbohydrate', nutrientAmount: (Number(this.totalCarbohydrate) || 0).toString(), unit: 'g', percentage: this.totalCarbohydratePercentage },
        { nutrientName: 'Dietary Fiber', nutrientAmount: (Number(this.dietaryFiber) || 0).toString(), unit: 'g', percentage: this.dietaryFiberPercentage, isSubNutrient: true },
        { nutrientName: 'Total Sugars', nutrientAmount: (Number(this.totalSugars) || 0).toString(), unit: 'g', percentage: '', isSubNutrient: true },
        { nutrientName: 'Added Sugars', nutrientAmount: (Number(this.addedSugars) || 0).toString(), unit: 'g', percentage: this.addedSugarsPercentage, isSubNutrient: true, isSubSubNutrient: true },
        { nutrientName: 'Protein', nutrientAmount: (Number(this.protein) || 0).toString(), unit: 'g', percentage: this.proteinPercentage },

        // Vitamins & Minerals with low DV handling
        { nutrientName: 'Vitamin D', nutrientAmount: this.vitaminD.toString(), unit: 'mcg', percentage: this.vitaminDPercentage !== '*' ? this.vitaminDPercentage : undefined, note: this.vitaminDNote || undefined },
        { nutrientName: 'Calcium', nutrientAmount: this.calcium.toString(), unit: 'mg', percentage: this.calciumPercentage !== '*' ? this.calciumPercentage : undefined, note: this.calciumNote || undefined },
        { nutrientName: 'Iron', nutrientAmount: this.iron.toString(), unit: 'mg', percentage: this.ironPercentage !== '*' ? this.ironPercentage : undefined, note: this.ironNote || undefined },

        {
          nutrientName: 'Potassium',
          nutrientAmount: this.potassiumFormatted,
          unit: 'mg',
          percentage: this.potassiumPercentage !== '*' ? this.potassiumPercentage : undefined, note: this.potassiumNote || undefined
        },

      ];
      this.nutritionLabelLinear = [
        { nutrientName: 'Total Fat', nutrientAmount: this.totalFat.toString(), unit: 'g', percentage: this.totalFatPercentage },
        { nutrientName: 'Saturated Fat', nutrientAmount: this.saturatedFat.toString(), unit: 'g', percentage: this.saturatedFatPercentage, isSubNutrient: true },
        { nutrientName: 'Trans Fat', nutrientAmount: this.transFat.toString(), unit: 'g', isSubNutrient: true },
        { nutrientName: 'Cholest.', nutrientAmount: (Number(this.cholesterol) || 0).toString(), unit: 'mg', percentage: this.cholesterolPercentage },
        {
          nutrientName: 'Sodium',
          nutrientAmount: this.sodiumFormatted,
          unit: 'mg',
          percentage: this.sodiumPercentage,
        },
        { nutrientName: 'Total Carb.', nutrientAmount: (Number(this.totalCarbohydrate) || 0).toString(), unit: 'g', percentage: this.totalCarbohydratePercentage },
        { nutrientName: 'Dietary Fiber', nutrientAmount: (Number(this.dietaryFiber) || 0).toString(), unit: 'g', percentage: this.dietaryFiberPercentage, isSubNutrient: true },
        { nutrientName: 'Total Sugars', nutrientAmount: (Number(this.totalSugars) || 0).toString(), unit: 'g', percentage: '', isSubNutrient: true },
        { nutrientName: 'Added Sugars', nutrientAmount: (Number(this.addedSugars) || 0).toString(), unit: 'g', percentage: this.addedSugarsPercentage, isSubNutrient: true, isSubSubNutrient: true },
        { nutrientName: 'Protein', nutrientAmount: (Number(this.protein) || 0).toString(), unit: 'g', percentage: this.proteinPercentage },

        // Vitamins & Minerals with low DV handling
        { nutrientName: 'Vit. D', nutrientAmount: this.vitaminD.toString(), unit: 'mcg', percentage: this.vitaminDPercentage !== '*' ? this.vitaminDPercentage : undefined, note: this.vitaminDNote || undefined },
        { nutrientName: 'Calcium', nutrientAmount: this.calcium.toString(), unit: 'mg', percentage: this.calciumPercentage !== '*' ? this.calciumPercentage : undefined, note: this.calciumNote || undefined },
        { nutrientName: 'Iron', nutrientAmount: this.iron.toString(), unit: 'mg', percentage: this.ironPercentage !== '*' ? this.ironPercentage : undefined, note: this.ironNote || undefined },

        {
          nutrientName: 'Potas.',
          nutrientAmount: this.potassiumFormatted,
          unit: 'mg',
          percentage: this.potassiumPercentage !== '*' ? this.potassiumPercentage : undefined, note: this.potassiumNote || undefined
        },

      ];

      this.nutritionLabel_MicroNut = [
        { nutrientName: 'Total Fat', nutrientAmount: Math.round(this.totalFat).toString(), unit: 'g', percentage: this.totalFatPercentage },
        { nutrientName: 'Saturated Fat', nutrientAmount: Math.round(this.saturatedFat).toString(), unit: 'g', percentage: this.saturatedFatPercentage, isSubNutrient: true },
        { nutrientName: 'Trans Fat', nutrientAmount: Math.round(this.transFat).toString(), unit: 'g', isSubNutrient: true },
        { nutrientName: 'Cholest.', nutrientAmount: Math.round(Number(this.cholesterol) || 0).toString(), unit: 'mg', percentage: this.cholesterolPercentage },
        { nutrientName: 'Sodium', nutrientAmount: Math.round(Number(this.sodium) || 0).toString(), unit: 'mg', percentage: this.sodiumPercentage },
        { nutrientName: 'Total Carbohydrate', nutrientAmount: Math.round(Number(this.totalCarbohydrate) || 0).toString(), unit: 'g', percentage: this.totalCarbohydratePercentage },
        { nutrientName: 'Dietary Fiber', nutrientAmount: Math.round(Number(this.dietaryFiber) || 0).toString(), unit: 'g', percentage: this.dietaryFiberPercentage, isSubNutrient: true },
        { nutrientName: 'Total Sugars', nutrientAmount: Math.round(Number(this.totalSugars) || 0).toString(), unit: 'g', percentage: '', isSubNutrient: true },
        { nutrientName: 'Added Sugars', nutrientAmount: Math.round(Number(this.addedSugars) || 0).toString(), unit: 'g', percentage: this.addedSugarsPercentage, isSubNutrient: true, isSubSubNutrient: true },
        { nutrientName: 'Protein', nutrientAmount: Math.round(Number(this.protein) || 0).toString(), unit: 'g', percentage: this.proteinPercentage },
        { nutrientName: 'Vit. D', nutrientAmount: Math.round(Number(this.vitaminD) || 0).toString(), unit: 'mcg', percentage: this.vitaminDPercentage !== '*' ? this.vitaminDPercentage : undefined, note: this.vitaminDNote || undefined },
        { nutrientName: 'Calcium', nutrientAmount: Math.round(Number(this.calcium) || 0).toString(), unit: 'mg', percentage: this.calciumPercentage !== '*' ? this.calciumPercentage : undefined, note: this.calciumNote || undefined },
        { nutrientName: 'Iron', nutrientAmount: Math.round(Number(this.iron) || 0).toString(), unit: 'mg', percentage: this.ironPercentage !== '*' ? this.ironPercentage : undefined, note: this.ironNote || undefined },
        { nutrientName: 'Potas.', nutrientAmount: Math.round(Number(this.potassium) || 0).toString(), unit: 'mg', percentage: this.potassiumPercentage !== '*' ? this.potassiumPercentage : undefined, note: this.potassiumNote || undefined },
      ];
    });
    // Include general note for low key nutrients if applicable
    /*   if (this.generalLowDVNote) {
        this.nutritionLabel.push({ nutrientName: 'General Note', nutrientAmount: '', unit: '', percentage: '', note: this.generalLowDVNote });
      }  */
    this.insignificantNutrients = this.generateInsignificantNutrients();

    return this.nutritionLabel;
  }


  LabelData_VolNut() {
    const nutrientRequests = this.ingredients.map((ingredient) =>
      this.foodNutrientsService.getNutrients(ingredient.name).pipe(
        catchError((err) => {
          console.error(`Error fetching nutrients for ${ingredient.name}`, err);
          return of({
            foodName: ingredient.name,
            nutrients: [],
          });
        })
      )
    );


    forkJoin(nutrientRequests).subscribe((results) => {
      if (results) {

        const cumulativeNutrients: { [key: string]: number } = {};

        results.forEach((result, index) => {
          const ingredient = this.ingredients[index];
          //const ingredientServingSize = ingredient.amount; // Actual serving size for the ingredient
          const factor = (ingredient.amount || 100) / 100;

          if (result.nutrients.length > 0) {
            result.nutrients.forEach((nutrient) => {
              const nutrientName = nutrient.nutrientName;

              // Normalize nutrient amount for the actual serving size
              //const normalizedAmount = (nutrient.nutrientAmount * this.servingSize) / 100;

              // Accumulate nutrient values
              cumulativeNutrients[nutrientName] =
                (cumulativeNutrients[nutrientName] || 0) + nutrient.nutrientAmount * factor;
            });
          }
        }

        );

        // After all nutrients are accumulated, adjust for the number of servings
        Object.keys(cumulativeNutrients).forEach((nutrientName) => {
          cumulativeNutrients[nutrientName] /= this.numberOfServings;  // Now adjust for servings
        });



        // Calculate totalCalories from Energy (Atwater Specific Factors)
        this.totalCalories = Math.round(
          cumulativeNutrients['Energy (Atwater Specific Factors)'] ||
          cumulativeNutrients['Energy (Atwater General Factors)'] ||
          cumulativeNutrients['Energy'] ||
          0
        );

        // Nutrient totals based on serving size
        this.totalFat = this.roundFatValue(
          (cumulativeNutrients['Fatty acids, total monounsaturated'] || 0) +
          (cumulativeNutrients['Fatty acids, total polyunsaturated'] || 0) +
          (cumulativeNutrients['Fatty acids, total trans'] || 0) +
          (cumulativeNutrients['Fatty acids, total saturated'] || 0) +
          (cumulativeNutrients['Fatty acids, total trans-monoenoic'] || 0) +
          (cumulativeNutrients['Fatty acids, total trans-dienoic'] || 0)
        );

        this.totalFat = this.roundFatValue(this.totalFat ||
          cumulativeNutrients['Total fat (NLEA)'] || cumulativeNutrients['Total lipid (fat)'] || 0
        );

        //this.totalFat = this.roundFatValue(cumulativeNutrients['Total fat (NLEA)'] || 0);
        this.saturatedFat = this.roundFatValue(cumulativeNutrients['Fatty acids, total saturated'] || 0);
        this.transFat = this.roundFatValue(cumulativeNutrients['Fatty acids, total trans'] || 0);
        this.polyUnsaturatedFat = this.roundFatValue(cumulativeNutrients['Fatty acids, total polyunsaturated'] || 0);
        this.monoUnsaturatedFat = this.roundFatValue(cumulativeNutrients['Fatty acids, total monounsaturated'] || 0);
        this.fluoride = this.roundFluorideValue(cumulativeNutrients['Fluoride, F'] || 0);
        this.solubleFiber = this.formatFiberValue(cumulativeNutrients['Fiber, soluble'] || 0);
        this.insolubleFiber = this.formatFiberValue(cumulativeNutrients['Fiber, insoluble'] || 0);
        this.vitaminA = this.roundFatValue(cumulativeNutrients['Vitamin A, RAE'] || 0);
        this.vitaminC = this.roundFatValue(cumulativeNutrients['Vitamin C, total ascorbic acid'] || 0);
        this.thiamin = this.roundFatValue(cumulativeNutrients['Thiamin'] || 0);
        this.riboflavin = this.roundFatValue(cumulativeNutrients['Riboflavin'] || 0);
        this.niacin = this.roundFatValue(cumulativeNutrients['Niacin'] || 0);
        this.vitaminB6 = this.roundFatValue(cumulativeNutrients['Vitamin B-6'] || 0);
        this.folate = this.roundFatValue(cumulativeNutrients['Folate, total'] || 0);
        this.vitaminB12 = this.roundFatValue(cumulativeNutrients['Vitamin B-12'] || 0);
        this.phosphorus = this.roundFatValue(cumulativeNutrients['Phosphorus, P'] || 0);
        this.magnesium = this.roundFatValue(cumulativeNutrients['Magnesium, Mg'] || 0);
        this.zinc = this.roundFatValue(cumulativeNutrients['Zinc, Zn'] || 0);
        this.choline = this.roundFatValue(cumulativeNutrients['Choline, total'] || 0);

        // Cholesterol handling
        if (cumulativeNutrients['Cholesterol']) {
          let cholesterolValue = cumulativeNutrients['Cholesterol'];
          if (cholesterolValue < 2) {
            this.cholesterol = '< 2 ';
          } else if (cholesterolValue >= 2 && cholesterolValue <= 5) {
            this.cholesterol = 'less than 5';
          } else {
            // Round to the nearest 5 mg increment
            let roundedCholesterol = Math.round(cholesterolValue / 5) * 5;
            this.cholesterol = `${roundedCholesterol} `;
          }
        } else {
          this.cholesterol = '0 ';  // Default case when cholesterol is not available
        }

        const cholesterolNumericValue = parseFloat(this.cholesterol) || 0;


        // Total Carbohydrate rounding logic
        const totalCarbohydrateRaw = cumulativeNutrients['Carbohydrate, by difference'] || 0;
        this.totalCarbohydrateRaw = totalCarbohydrateRaw; // Store raw value for percentage calculation
        if (totalCarbohydrateRaw < 1) {
          this.totalCarbohydrate = '< 1 ';
        } else {
          this.totalCarbohydrate = `${Math.round(totalCarbohydrateRaw)} `;
        }

        // Dietary Fiber rounding logic
        const dietaryFiberRaw = cumulativeNutrients['Fiber, total dietary'] || 0;
        this.dietaryFiberRaw = dietaryFiberRaw; // Store raw value for percentage calculation
        if (dietaryFiberRaw < 0.5) {
          this.dietaryFiber = '0 ';
        } else if (dietaryFiberRaw < 1) {
          this.dietaryFiber = '< 1 ';
        } else {
          this.dietaryFiber = `${Math.round(dietaryFiberRaw)} `;
        }
        //this.addedSugars = cumulativeNutrients['Sucrose'];
        // this.addedSugars = cumulativeNutrients['Sucrose'] ?? 0;
        this.addedSugars = cumulativeNutrients['Sucrose'] !== undefined ? cumulativeNutrients['Sucrose'] : 0;


        this.totalSugars = cumulativeNutrients['Sugars, Total'];

        //this.totalCarbohydrate = cumulativeNutrients['Carbohydrate, by difference'] || 0;
        //this.dietaryFiber = cumulativeNutrients['Fiber, total dietary'] || 0;
        this.proteinRaw = cumulativeNutrients['Protein'] || 0;

        if (this.proteinRaw < 0.5) {
          this.protein = '< 0.5 ';
        } else if (this.proteinRaw >= 0.5 && this.proteinRaw < 1) {
          this.protein = 'less than 1 ';
        } else {
          this.protein = `${Math.round(this.proteinRaw)} `; // Round to nearest 1 g increment
        }

        this.vitaminD = Math.round(cumulativeNutrients['Vitamin D (D2 + D3)'] || 0);
        this.calcium = Math.round(cumulativeNutrients['Calcium, Ca'] || 0);
        this.iron = Math.round(cumulativeNutrients['Iron, Fe'] || 0);

        this.sodium = cumulativeNutrients['Sodium, Na'] || 0;
        this.potassium = cumulativeNutrients['Potassium, K'] || 0;

        // Apply rounding rules
        const roundedSodium = this.roundSodiumPotassium(this.sodium);
        const roundedPotassium = this.roundSodiumPotassium(this.potassium);

        // For Nutrition Label
        this.sodiumFormatted = roundedSodium < 5 ? '<5' : roundedSodium.toString();
        this.potassiumFormatted = roundedPotassium < 5 ? '<5' : roundedPotassium.toString();




        // Example for Vitamin D
        this.vitaminDPercentage = this.formatPercentageDV((this.vitaminD / this.vitaminDDV) * 100);
        // Example for Calcium
        this.calciumPercentage = this.formatPercentageDV((this.calcium / this.calciumDV) * 100);
        // Example for Iron
        this.ironPercentage = this.formatPercentageDV((this.iron / this.ironDV) * 100);
        // Example for Potassium
        this.potassiumPercentage = this.formatPercentageDV((this.potassium / this.potassiumDV) * 100);
        this.addedSugarsPercentage = ((this.addedSugars / this.addedSugarsDV) * 100).toFixed(2);
        this.polyUnsaturatedFatPercentage = this.formatPercentageDV((this.polyUnsaturatedFat / this.fatDV) * 100);
        this.monoUnsaturatedFatPercentage = this.formatPercentageDV((this.monoUnsaturatedFat / this.fatDV) * 100);
        // this.fluoridePercentage = this.formatPercentageDV((this.fluoride / this.fluorideDV) * 100);
        // Ensure solubleFiber is a number before calculating percentage
        const solubleFiberValue = typeof this.solubleFiber === 'number' ? this.solubleFiber : 0;
        this.solubleFiberPercentage = this.formatPercentageDV((solubleFiberValue / this.dietaryFiberDV) * 100);
        const insolubleFiberValue = typeof this.solubleFiber === 'number' ? this.solubleFiber : 0;
        this.insolubleFiberPercentage = this.formatPercentageDV((insolubleFiberValue / this.dietaryFiberDV) * 100); // Uses dietary fiber DV
        this.vitaminAPercentage = this.formatPercentageDV((this.vitaminA / this.vitaminADV) * 100);
        this.vitaminCPercentage = this.formatPercentageDV((this.vitaminC / this.vitaminCDV) * 100);
        this.thiaminPercentage = this.formatPercentageDV((this.thiamin / this.thiaminDV) * 100);
        this.riboflavinPercentage = this.formatPercentageDV((this.riboflavin / this.riboflavinDV) * 100);
        this.niacinPercentage = this.formatPercentageDV((this.niacin / this.niacinDV) * 100);
        this.vitaminB6Percentage = this.formatPercentageDV((this.vitaminB6 / this.vitaminB6DV) * 100);
        this.folatePercentage = this.formatPercentageDV((this.folate / this.folateFolicAcidDV) * 100); // Uses folate/folic acid DV
        this.vitaminB12Percentage = this.formatPercentageDV((this.vitaminB12 / this.vitaminB12DV) * 100);
        this.phosphorusPercentage = this.formatPercentageDV((this.phosphorus / this.phosphorusDV) * 100);
        this.magnesiumPercentage = this.formatPercentageDV((this.magnesium / this.magnesiumDV) * 100);
        this.zincPercentage = this.formatPercentageDV((this.zinc / this.zincDV) * 100);
        this.cholinePercentage = this.formatPercentageDV((this.choline / this.cholineDV) * 100);

        // Handling labels for special cases when values are very low
        const lowDVStatement = 'Contains less than 2% of the Daily Value of this nutrient.';
        if (this.vitaminDPercentage === '*') {
          this.vitaminDNote = lowDVStatement;
        }
        if (this.calciumPercentage === '*') {
          this.calciumNote = lowDVStatement;
        }
        if (this.ironPercentage === '*') {
          this.ironNote = lowDVStatement;
        }
        if (this.potassiumPercentage === '*') {
          this.potassiumNote = lowDVStatement;
        }

        // Optional: If all are low and key nutrients Vitamin A, C, Calcium, Iron, include:
        const keyNutrientsNote =
          'Not a significant source of Vitamin A, Vitamin C, Calcium, and Iron.';
        if (
          this.vitaminDPercentage === '*' &&
          this.calciumPercentage === '*' &&
          this.ironPercentage === '*' &&
          this.potassiumPercentage === '*'
        ) {
          this.generalLowDVNote = keyNutrientsNote;
        }


        //this.protein = cumulativeNutrients['Protein'] || 0;


        // Calculate DV percentages based on per-serving values
        this.totalFatPercentage = ((this.totalFat / this.totalFatDV) * 100).toFixed(1);
        this.saturatedFatPercentage = ((this.saturatedFat / this.saturatedFatDV) * 100).toFixed(1);
        this.cholesterolPercentage = ((cholesterolNumericValue / this.cholesterolDV) * 100).toFixed(1);

        this.sodiumPercentage = ((roundedSodium / this.sodiumDV) * 100).toFixed(1);
        this.totalCarbohydratePercentage = ((this.totalCarbohydrateRaw / this.totalCarbohydrateDV) * 100).toFixed(1);
        this.dietaryFiberPercentage = ((this.dietaryFiberRaw / this.dietaryFiberDV) * 100).toFixed(1);

        this.proteinPercentage = ((this.proteinRaw / this.proteinDV) * 100).toFixed(1);
        // this.vitaminDPercentage = ((this.vitaminD / this.vitaminDDV) * 100).toFixed(1);
        // this.calciumPercentage = ((this.calcium / this.calciumDV) * 100).toFixed(1);
        // this.ironPercentage = ((this.iron / this.ironDV) * 100).toFixed(1);
        // this.potassiumPercentage = ((roundedPotassium / this.potassiumDV) * 100).toFixed(1);


        // Update the nutrition label with the new per-serving values

        // Include general note for low key nutrients if applicable
        /*   if (this.generalLowDVNote) {
            this.nutritionLabel.push({ nutrientName: 'General Note', nutrientAmount: '', unit: '', percentage: '', note: this.generalLowDVNote });
          }  */
      }
    });

    // Update the nutrition label with the new per-serving values
    const solubleFiberAmount =
      typeof this.solubleFiber === 'number' ? this.solubleFiber : parseFloat(this.solubleFiber) || 0;

    // Update the nutrition label with the new per-serving values
    const insolubleFiberAmount =
      typeof this.solubleFiber === 'number' ? this.solubleFiber : parseFloat(this.solubleFiber) || 0;

    this.nutritionLabel = [
      {
        nutrientName: '', unit: '', percentage: '% Daily Value*',
        nutrientAmount: ''
      },
      { nutrientName: 'Total Fat', nutrientAmount: this.totalFat.toString(), unit: 'g', percentage: this.totalFatPercentage },
      { nutrientName: 'Saturated Fat', nutrientAmount: this.saturatedFat.toString(), unit: 'g', percentage: this.saturatedFatPercentage, isSubNutrient: true },
      { nutrientName: 'Trans Fat', nutrientAmount: this.transFat.toString(), unit: 'g', isSubNutrient: true },
      { nutrientName: 'Polyunsaturated Fat', nutrientAmount: this.polyUnsaturatedFat.toString(), unit: 'g', isSubNutrient: true },
      { nutrientName: 'Monounsaturated Fat', nutrientAmount: this.monoUnsaturatedFat.toString(), unit: 'g', isSubNutrient: true },
      { nutrientName: 'Cholesterol', nutrientAmount: (Number(this.cholesterol) || 0).toString(), unit: 'mg', percentage: this.cholesterolPercentage },
      {
        nutrientName: 'Sodium',
        nutrientAmount: this.sodiumFormatted,
        unit: 'mg',
        percentage: this.sodiumPercentage,
      },
      { nutrientName: 'Fluoride', nutrientAmount: this.fluoride.toString(), unit: 'mg' },
      { nutrientName: 'Total Carbohydrate', nutrientAmount: (Number(this.totalCarbohydrate) || 0).toString(), unit: 'g', percentage: this.totalCarbohydratePercentage },
      { nutrientName: 'Dietary Fiber', nutrientAmount: (Number(this.dietaryFiber) || 0).toString(), unit: 'g', percentage: this.dietaryFiberPercentage, isSubNutrient: true },
      { nutrientName: 'Soluble Fiber', nutrientAmount: solubleFiberAmount.toString(), unit: 'g', isSubNutrient: true, isSubSubNutrient: true },
      { nutrientName: 'Insoluble Fiber', nutrientAmount: insolubleFiberAmount.toString(), unit: 'g', isSubNutrient: true, isSubSubNutrient: true },
      { nutrientName: 'Total Sugars', nutrientAmount: (Number(this.totalSugars) || 0).toString(), unit: 'g', percentage: '', isSubNutrient: true },
      { nutrientName: 'Added Sugars', nutrientAmount: (Number(this.addedSugars) || 0).toString(), unit: 'g', percentage: this.addedSugarsPercentage, isSubNutrient: true, isSubSubNutrient: true },
      { nutrientName: 'Protein', nutrientAmount: (Number(this.protein) || 0).toString(), unit: 'g', percentage: this.proteinPercentage },
      // Vitamins & Minerals
      { nutrientName: 'Vitamin D', nutrientAmount: this.vitaminD.toString(), unit: 'mcg', percentage: this.vitaminDPercentage !== '*' ? this.vitaminDPercentage : undefined, note: this.vitaminDNote || undefined },
      { nutrientName: 'Calcium', nutrientAmount: this.calcium.toString(), unit: 'mg', percentage: this.calciumPercentage !== '*' ? this.calciumPercentage : undefined, note: this.calciumNote || undefined },
      { nutrientName: 'Iron', nutrientAmount: this.iron.toString(), unit: 'mg', percentage: this.ironPercentage !== '*' ? this.ironPercentage : undefined, note: this.ironNote || undefined },
      { nutrientName: 'Potassium', nutrientAmount: this.potassiumFormatted, unit: 'mg', percentage: this.potassiumPercentage !== '*' ? this.potassiumPercentage : undefined, note: this.potassiumNote || undefined },
      { nutrientName: 'Vitamin A', nutrientAmount: this.vitaminA.toString(), unit: 'mcg', percentage: this.vitaminAPercentage },
      { nutrientName: 'Vitamin C', nutrientAmount: this.vitaminC.toString(), unit: 'mg', percentage: this.vitaminCPercentage },
      { nutrientName: 'Thiamin', nutrientAmount: this.thiamin.toString(), unit: 'mg', percentage: this.thiaminPercentage },
      { nutrientName: 'Riboflavin', nutrientAmount: this.riboflavin.toString(), unit: 'mg', percentage: this.riboflavinPercentage },
      { nutrientName: 'Niacin', nutrientAmount: this.niacin.toString(), unit: 'mg', percentage: this.niacinPercentage },
      { nutrientName: 'Vitamin B6', nutrientAmount: this.vitaminB6.toString(), unit: 'mg', percentage: this.vitaminB6Percentage },
      { nutrientName: 'Folate', nutrientAmount: this.folate.toString(), unit: 'mcg DFE', percentage: this.folatePercentage },
      { nutrientName: 'Vitamin B12', nutrientAmount: this.vitaminB12.toString(), unit: 'mcg', percentage: this.vitaminB12Percentage },
      { nutrientName: 'Phosphorus', nutrientAmount: this.phosphorus.toString(), unit: 'mg', percentage: this.phosphorusPercentage },
      { nutrientName: 'Magnesium', nutrientAmount: this.magnesium.toString(), unit: 'mg', percentage: this.magnesiumPercentage },
      { nutrientName: 'Zinc', nutrientAmount: this.zinc.toString(), unit: 'mg', percentage: this.zincPercentage },
      { nutrientName: 'Choline', nutrientAmount: this.choline.toString(), unit: 'mg', percentage: this.cholinePercentage },
    ];


    return this.nutritionLabel;
  }

  getIndex(nutrientName: string): number {
    const index = this.nutritionLabel.findIndex(nutrient => nutrient.nutrientName === nutrientName);
    return index !== -1 ? index + 1 : this.nutritionLabel.length;
  }

  getFilteredNutritionLabel(): any[] {
    return this.nutritionLabel.filter(nutrient => nutrient.nutrientName.trim() !== '');
  }
  getNutritionLabelBetween(start: string, end: string) {
    const startIndex = this.nutritionLabel.findIndex(nutrient => nutrient.nutrientName === start);
    const endIndex = this.nutritionLabel.findIndex(nutrient => nutrient.nutrientName === end);

    // Ensure valid indices
    if (startIndex === -1 || endIndex === -1 || startIndex >= endIndex) {
      return [];
    }

    return this.nutritionLabel.slice(startIndex, endIndex + 1);
  }


  isLastItemInRow(row: any[], item: any): boolean {
    return row.indexOf(item) === row.length - 1;
  }

  // Helper Functions in Component
  isCoreNutrient(nutrientName: string): boolean {
    const coreNutrients = ['Calories', 'Total Fat', 'Saturated Fat', 'Cholesterol', 'Sodium', 'Total Carbohydrate', 'Dietary Fiber', 'Sugars', 'Protein', 'Vitamin D', 'Calcium', 'Iron', 'Potassium'];
    return coreNutrients.includes(nutrientName);
  }
  isInsignificantNutrient(nutrient: any): boolean {
    const insignificantThreshold = 5; // DV percentage threshold
    return nutrient.percentage && parseFloat(nutrient.percentage) < insignificantThreshold;
  }
  shouldDisplayNutrient(nutrient: any): boolean {
    const alwaysDisplayNutrients = ['Total Fat', 'Sodium', 'Total Carbohydrate', 'Total Sugars', 'Protein'];

    // Display if it's a core nutrient or one of the always-display nutrients and is not insignificant
    return alwaysDisplayNutrients.includes(nutrient.nutrientName) ||
      (this.isCoreNutrient(nutrient.nutrientName) && !this.isInsignificantNutrient(nutrient));
  }

  groupVitaminsAndMinerals(batchSize: number): any[][] {
    const allVitaminsAndMinerals = this.nutritionLabel.filter(item => this.isVitaminOrMineral(item.nutrientName));
    const totalItems = Math.ceil(allVitaminsAndMinerals.length / 2);
    const firstGroup = allVitaminsAndMinerals.slice(0, totalItems);
    return this.createBatches(firstGroup, batchSize);
  }

  groupRemainingVitaminsAndMinerals(batchSize: number): any[][] {
    const allVitaminsAndMinerals = this.nutritionLabel.filter(item => this.isVitaminOrMineral(item.nutrientName));
    const totalItems = Math.ceil(allVitaminsAndMinerals.length / 2);
    const secondGroup = allVitaminsAndMinerals.slice(totalItems);
    return this.createBatches(secondGroup, batchSize);
  }

  createBatches(items: any[], batchSize: number): any[][] {
    const batches: any[][] = [];
    for (let i = 0; i < items.length; i += batchSize) {
      batches.push(items.slice(i, i + batchSize));
    }
    return batches;
  }
  generateInsignificantNutrients(): string[] {
    const insignificantNutrients = this.nutritionLabel
      .filter(nutrient =>
        !this.coreNutrients.includes(nutrient.nutrientName) &&
        nutrient.percentage &&
        parseFloat(nutrient.percentage) < 5
      )
      .map(nutrient => nutrient.nutrientName);

    return insignificantNutrients;
  }

  isMajorNutrient(nutrientName: string): boolean {
    const majorNutrients = ['Total Fat', 'Cholesterol', 'Sodium', 'Fluoride', 'Total Carbohydrate', 'Protein', 'Total Carb.'];
    return majorNutrients.includes(nutrientName);
  }


  isThickLine(nutrient: { nutrientName: string }): boolean {
    // Apply thick line styling for section separators
    return nutrient.nutrientName === 'Total Fat';
  }


  orderedNutrients = this.nutritionLabel.sort((a, b) => {
    const nutrientOrder = [
      'Total Fat',
      'Saturated Fat',
      'Trans Fat',
      'Cholesterol',
      'Sodium',
      'Fluoride',
      'Total Carbohydrate',
      'Dietary Fiber',
      'Total Sugars',
      'Added Sugars',
      'Protein',
    ];
    return nutrientOrder.indexOf(a.nutrientName) - nutrientOrder.indexOf(b.nutrientName);
  });

  // Additional Vitamins Section
  vitamins = this.nutritionLabel.filter(nutrient => ['Vitamin D', 'Calcium', 'Iron', 'Potassium'].includes(nutrient.nutrientName));

  isVitaminOrMineral(nutrientName: string): boolean {
    const vitaminsAndMinerals = ['Vitamin D', 'Vit. D', 'Calcium', 'Iron', 'Potassium', 'Vitamin A', 'Vitamin C',
      'Thiamin', 'Riboflavin', 'Niacin', 'Vitamin B6', 'Folate', 'Vitamin B12', 'Phosphorus',
      'Magnesium', 'Zinc', 'Choline', 'Potas.'
    ];
    return vitaminsAndMinerals.includes(nutrientName);
  }
  isNoDV(nutrientName: string): boolean {
    const sugarandfats = ["Trans Fat", "Total Sugars", "Polyunsaturated Fat",
      'Monounsaturated Fat', 'Fluoride', 'Soluble Fiber', 'Insoluble Fiber'
    ];
    return sugarandfats.includes(nutrientName);

  }

  ngAfterViewInit() {
    console.log(this.sort); // Ensure it is not undefined

        // Custom sorting logic for amount column
        // Custom sorting logic for amount column
        this.dataSourceNutrient.sortingDataAccessor = (item: NutrientFoodDTO, sortHeaderId: string): string | number => {
          switch (sortHeaderId) {
            case 'amount':
              return parseFloat(item.amount) || 0; // Convert 'amount' to number
            case 'foodName':
              return item.foodName.toLowerCase(); // Sort by food name (case-insensitive)
            case 'unitName':
              return item.unitName.toLowerCase(); // Sort by unit name (case-insensitive)
            default:
              return ''; // Default fallback
          }
        };
        this.dataSourceNutrient.paginator = this.paginator;
        this.sort.sort({ id: 'amount', start: 'asc', disableClear: false });
        this.dataSourceNutrient.sort = this.sort; // Connect sorting
        
  }

  // Helper function for rounding Sodium and Potassium values
  roundSodiumPotassium(value: number): number {
    if (value < 5) {
      return 0; // Express as "<5 mg"
    } else if (value <= 140) {
      return Math.round(value / 5) * 5; // Nearest 5 mg increment
    } else {
      return Math.round(value / 10) * 10; // Nearest 10 mg increment
    }
  }

  // Helper function to format %DV as per the specifications
  formatPercentageDV(value: number): string {
    if (value < 2) {
      //return '*'; // Refer to the statement for less than 2% of DV
      return `${Math.round(value)}`;
    } else if (value <= 10) {
      return `${Math.round(value / 2) * 2}`; // Nearest 2% DV increment
    } else if (value <= 50) {
      return `${Math.round(value / 5) * 5}`; // Nearest 5% DV increment
    } else {
      return `${Math.round(value / 10) * 10}`; // Nearest 10% DV increment
    }
  }

  // Helper function to round fat values according to the FDA guidelines
  roundFatValue(value: number): number {
    if (value < 0.5) {
      return 0;
    } else if (value < 5) {
      return Math.round(value * 2) / 2; // Round to nearest 0.5 g
    } else {
      return Math.round(value); // Round to nearest 1 g
    }
  }

  roundFluorideValue(value: number): number {
    if (value < 0.1) {
      return 0; // Express as zero
    } else if (value <= 0.8) {
      return Math.round(value * 10) / 10; // Round to nearest 0.1 mg
    } else {
      return Math.round(value * 5) / 5; // Round to nearest 0.2 mg
    }
  }


  // Reusable function to format fiber values
  formatFiberValue(value: number, isSoluble: boolean = true): string {
    if (value < 0.5) {
      return "0"; // Express as zero if less than 0.5 grams
    } else if (value < 1) {
      return "less than 1 gram"; // Alternative statement for less than 1 gram
    } else {
      return `${Math.round(value)} grams`; // Round to nearest gram if greater than or equal to 1
    }
  }


  // Fetch nutrients for the entered food name
  onSearchFood() {
    this.brandedFoodData = null;
    this.foodData = null;
    this.foundationFoodData = null;
    this.SRFoodData = null;
    this.SRFood = null;
    this.brandedFood = null;
    this.foundationFood = null;

    if (!this.foodName.trim()) {
      // Clear foodData when foodName is empty
      this.foodData = null;
      return;
    }
    this.foodNutrientsService.getNutrients(this.foodName).subscribe(data => {
      this.foodData = data;
      //this.initializeChartData(); // Call initializeChartData after foodData is set
    });
  }

  // Search branded food details and nutrients
  onSearchBrandedFood() {
    // Clear previous data
    this.brandedFoodData = null;
    this.foodData = null;
    this.foundationFoodData = null;
    this.SRFoodData = null;
    this.SRFood = null;
    this.brandedFood = null;
    this.foundationFood = null;


    // If brandedFoodName is empty, reset and return
    if (!this.brandedFoodName.trim()) {
      this.isLoading = false;
      this.brandedFoodData = null;
      return;
    }

    this.isLoading = true; // Show loading spinner

    // Fetch branded food details
    this.foodNutrientsService.getBrandedFoodDetails(this.brandedFoodName).subscribe(
      (brandedFood) => {
        this.brandedFood = brandedFood; // Store the fetched branded food details

        // After fetching branded food details, fetch the food nutrients
        this.foodNutrientsService.getBrandedFoodNutrients(this.brandedFoodName).subscribe(
          (nutrientsData) => {
            this.brandedFoodData = nutrientsData; // Store the food nutrients data
            this.isLoading = false; // Hide loading spinner

          },
          (error) => {
            console.error('Error fetching food nutrients:', error);
          }
        );
      },
      (error) => {
        console.error('Error fetching branded food details:', error);
        this.isLoading = false; // Hide loading spinner if error occurs
      }
    );
  }


  onSearchSurveyFood() {
    // Clear previous data
    this.surveyFoodData = null;
    this.foodData = null;
    this.surveyFoodData = null;
    this.foundationFoodData = null;
    this.SRFoodData = null;
    this.SurveyFood = null;
    this.SRFood = null;
    this.brandedFood = null;
    this.foundationFood = null;


    // If brandedFoodName is empty, reset and return
    if (!this.surveyFoodName.trim()) {
      this.isLoading = false;
      this.surveyFoodData = null;
      return;
    }

    this.isLoading = true; // Show loading spinner

    // Fetch branded food details
    this.foodNutrientsService.getSurveyFoodDetails(this.surveyFoodName).subscribe(
      (surveyFood) => {
        this.SurveyFood = surveyFood; // Store the fetched branded food details

        // After fetching branded food details, fetch the food nutrients
        this.foodNutrientsService.getSurveyFoodNutrients(this.surveyFoodName).subscribe(
          (nutrientsData) => {
            this.surveyFoodData = nutrientsData; // Store the food nutrients data
            this.isLoading = false; // Hide loading spinner

          },
          (error) => {
            console.error('Error fetching food nutrients:', error);
          }
        );
      },
      (error) => {
        console.error('Error fetching branded food details:', error);
        this.isLoading = false; // Hide loading spinner if error occurs
      }
    );
  }



  // Search Foundation food details and nutrients
  onSearchFoundationFood() {
    // Clear previous data
    this.brandedFoodData = null;
    this.foodData = null;
    this.foundationFoodData = null;
    this.SRFoodData = null;
    this.SRFood = null;
    this.brandedFood = null;
    this.foundationFood = null;



    // If foundationFoodName is empty, reset and return
    if (!this.foundationFoodName.trim()) {
      this.isLoading = false;
      this.foundationFoodData = null;
      return;
    }

    this.isLoading = true; // Show loading spinner

    // Fetch branded food details
    this.foodNutrientsService.getFoundationFoodDetails(this.foundationFoodName).subscribe(
      (foundationFood) => {
        this.foundationFood = foundationFood; // Store the fetched branded food details

        // After fetching branded food details, fetch the food nutrients
        this.foodNutrientsService.getFoundationFoodNutrients(this.foundationFoodName).subscribe(
          (nutrientsData) => {
            this.foundationFoodData = nutrientsData; // Store the food nutrients data
            this.isLoading = false; // Hide loading spinner

          },
          (error) => {
            console.error('Error fetching food nutrients:', error);
          }
        );
      },
      (error) => {
        console.error('Error fetching branded food details:', error);
        this.isLoading = false; // Hide loading spinner if error occurs
      }
    );
  }


  // Search SR food details and nutrients
  onSearchSRFood() {
    // Clear previous data
    this.brandedFoodData = null;
    this.foodData = null;
    this.foundationFoodData = null;
    this.SRFoodData = null;
    this.SRFood = null;
    this.brandedFood = null;
    this.foundationFood = null;

    // If brandedFoodName is empty, reset and return
    if (!this.srFoodName.trim()) {
      this.isLoading = false;
      this.SRFoodData = null;
      return;
    }

    this.isLoading = true; // Show loading spinner

    // Fetch branded food details
    this.foodNutrientsService.getSRFoodDetails(this.srFoodName).subscribe(
      (SRFood) => {
        this.SRFood = SRFood; // Store the fetched branded food details

        // After fetching branded food details, fetch the food nutrients
        this.foodNutrientsService.getSRFoodNutrients(this.srFoodName).subscribe(
          (nutrientsData) => {
            this.SRFoodData = nutrientsData; // Store the food nutrients data
            this.isLoading = false; // Hide loading spinner

          },
          (error) => {
            console.error('Error fetching food nutrients:', error);
          }
        );
      },
      (error) => {
        console.error('Error fetching branded food details:', error);
        this.isLoading = false; // Hide loading spinner if error occurs
      }
    );
  }

  public logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  /*  async fetchFoods() {
     if (this.nutrientName) {
       this.foodList = [];
       this.loading = true;
       this.error = null;
 
       try {
         const data = await this.foodNutrientsService.getFoodsByNutrientName(this.nutrientName).toPromise();
         this.foodList = data || [];
         this.dataSourceNutrient.data = this.foodList;
         // Make sure paginator is initialized after data assignment
         if (this.paginator) {
           this.dataSourceNutrient.paginator = this.paginator;
         }
         this.populateCategories();
       } catch (error) {
         this.error = 'Failed to load food data. Please try again.';
         console.error(error);
       } finally {
         this.loading = false;
       }
     }
   } */

  async fetchFoods() {
    this.applyFilter();
    this.reset();
    if (this.nutrientName) {
      this.foodList = [];
      this.loading = true;
      this.error = null;

      try {
        const data = await this.foodNutrientsService.getFoodsByNutrientName(this.nutrientName).toPromise();
        this.foodList = data || [];
        this.dataSourceNutrient.data = this.foodList;
        this.dataSourceNutrient.sort = this.sort;
        console.log("Paginator is:", this.paginator); // Debugging line
        console.log(this.sort); // Ensure it is not undefined


        // Make sure paginator is applied after data is fetched

       // Ensure MatSort and MatPaginator are properly reassigned
      if (!this.dataSourceNutrient.sort || !this.dataSourceNutrient.paginator) {
        setTimeout(() => {
          this.dataSourceNutrient.sort = this.sort;
          this.dataSourceNutrient.paginator = this.paginator;
        });
        console.log('Sortables:', this.sort.sortables);

      }

        // Populate categories or apply any additional logic
        this.populateCategories();
      } catch (error) {
        this.error = 'Failed to load food data. Please try again.';
        console.error(error);
      } finally {
        this.loading = false;
      }
    }
  }

  populateCategories() {
    const categories = this.foodList.map(food => food.foodCategoryDescription);
    this.filteredCategories = Array.from(new Set(categories));
  }
  openChat() {
    this.dialog.open(OllamaChatComponent, {
      width: '400px',
    });
  }
}



