import { Component } from '@angular/core';
import {CommonModule} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'about-page',
  standalone: true,
  imports: [ CommonModule],
templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
})
export class AboutComponent {

  constructor(private router: Router) {

}
  

  
  }
