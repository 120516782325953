import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms'; // Import FormsModule here
import { DRI, Elements, FoundationFoods, FoundationFoodsWithUnit, MacroNutrients, Vitamins, Water } from '../food-data';
import { DRIService } from '../dri.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion'; // Import MatExpansionModule
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';



@Component({
  selector: 'app-search-form',
  standalone: true,
  imports: [FormsModule, CommonModule, MatTableModule, MatExpansionModule],
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css']
})
export class SearchFormComponent {

  authService = inject(AuthService);
  router = inject(Router);
  isFirstSectionOpen = true; // Keep track of the first section's state
  searchCriteria = {
    lsgroup: '',
    age: 0,
    yearOrMonth: 'Year'
  };
  searchForm: FormGroup;
  results: DRI[] = [];

  dataSource = new MatTableDataSource<DRI>(this.results);
  // Custom column names
  columnNames: { [index: string]: any } = {
    water: 'Water(L/d)',
    carbohydrate: 'Carbohydrate(g/d)',
    fiber: 'Fiber(g/d)',
    fat: 'Fat(g/d)',
    linoleicacid: 'Linoleicacid(g/d)',
    alphalinolenicacid: 'Alphalinolenicacid(g/d)',
    protein: 'Protein(g/d)',
    calcium: 'Calcium(mg/d)',
    chromium: 'Chromium(McG/d)',
    copper: 'Copper(McG/d)',
    fluoride: 'Fluoride(McG/d)',
    iodine: 'Iodine(McG/d)',
    iron: 'Iron(mg/d)',
    magnesium: 'Magnesium(mg/d)',
    manganese: 'Manganese(mg/d)',
    molybdenum: 'Molybdenum(McG/d)',
    phosphorus: 'Phosphorus(mg/d)',
    selenium: 'Selenium(McG/d)',
    zinc: 'Zinc(mg/d)',
    potassium: 'Potassium(mg/d)',
    sodium: 'Sodium(mg/d)',
    chloride: 'Chloride(g/d)',
    vitamina: 'Vitamin A(mcg/d)',
    vitaminc: 'Vitamin C(mg/d)',
    vitamind: 'Vitamin D(mcg/d)',
    vitamine: 'Vitamin E(mg/d)',
    vitamink: 'Vitamin K(mcg/d)',
    thiamin: 'Thiamin(mg/d)',
    riboflavin: 'Riboflavin(mg/d)',
    niacin: 'Niacin(mg/d)',
    vitaminb6: 'Vitamin B6(mg/d)',
    folate: 'Folate(mcg/d)',
    vitaminb12: 'Vitamin B12(McG/d)',
    pantothenicacid: 'Pantothenic Acid(mg/d)',
    biotin: 'Biotin(mcg/d)',
    choline: 'Choline(mg/d)'
  };

  selectedOption: string = 'Year'; // Two-way data binding for the dropdown
  dropdownOptions: string[] = ['Infants', 'Children', 'Female', 'Male', 'Pregnancy', 'Lactation']; // Replace with your options
  // Options for the second dropdown
  yesNoOptions = ['Year', 'Month'];
  // Min and max values for age input
 
  minAge = 0; // Set your min age
  maxAge = 10; // Set your max age for children

  ageRange = { min: 1, max: 150 }; // Default range for other groups

  selectedYesNo: string = 'Year';

  transformedData: { columnName: string; columnValue: any; linkText: string; linkUrl: string; }[] = [];
  SECTIONS_DATA: { name: string; items: { columnName: string; columnValue: any; linkText: string; linkUrl: string }[]; }[] = [
    {
      name: 'Water',
      items: this.transformedData,

    },
    {
      name: 'MacroNutrients',
      items: this.transformedData
    },
    {
      name: 'Elements',
      items: this.transformedData
    },
    {
      name: 'Vitamins',
      items: this.transformedData
    }
  ];
  // Sample data to filter (in a real application, you might fetch this data from an API)
  constructor(private driService: DRIService, private location: Location) {
  }

  // Method to handle change in the first dropdown
  /*  onFirstDropdownChange() {
     // When the first dropdown changes, you might want to reset the second dropdown
     if (this.searchCriteria.lsgroup === 'Infants'){
       this.yesNoOptions = [];
       this.yesNoOptions.push('Month');
       this.selectedYesNo = 'Month';
       this.selectedOption='Month';
     }
     else{
       this.yesNoOptions = [];
       this.yesNoOptions.push('Year');
       this.selectedYesNo = 'Year';
       this.selectedOption = 'Year';
     }
   } */
  // Handle the group change event
  onFirstDropdownChange() {
    if (this.searchCriteria.lsgroup === 'Infants') {
      // Set the age input to 0-12 months for infants
      this.searchCriteria.yearOrMonth = 'Month';
      this.minAge = 0;
      this.maxAge = 12;
      this.searchCriteria.age = 0; // Reset age field when switching to Infant
    }
    else if (this.searchCriteria.lsgroup === 'Children') {
      // Set the age input to 0-12 months for infants
      this.searchCriteria.yearOrMonth = 'Year';
      this.minAge = 1;
      this.maxAge = 8;
      this.searchCriteria.age = 1; // Reset age field when switching to Infant
    }
    else if (this.searchCriteria.lsgroup === 'Pregnancy') {
      // Set the age input to 0-12 months for infants
      this.searchCriteria.yearOrMonth = 'Year';
      this.minAge = 14;
      this.maxAge = 50;
      this.searchCriteria.age = 14; // Reset age field when switching to Infant
    }
    else if (this.searchCriteria.lsgroup === 'Lactation') {
      // Set the age input to 0-12 months for infants
      this.searchCriteria.yearOrMonth = 'Year';
      this.minAge = 14;
      this.maxAge = 50;
      this.searchCriteria.age = 14; // Reset age field when switching to Infant
    }
    else {
      // Set the age input to years for all other groups
      this.searchCriteria.yearOrMonth = 'Year';
      this.minAge = 9;
      this.maxAge = 150; // Set max age to 100 for other groups
      this.searchCriteria.age = 9; // Reset age field when switching groups
    }
  }

  isChildOrInfant(): boolean {
    // Adjust this logic based on your actual group naming conventions
    return this.searchCriteria.lsgroup === 'Infants' || this.searchCriteria.lsgroup === 'Children';
  }

  // This will be called when the age is updated
  onAgeChange() {
    if (this.searchCriteria.age < 1) {
      this.searchCriteria.yearOrMonth = 'Month'; // Pre-populate with Month for infants
    } else {
      this.searchCriteria.yearOrMonth = 'Year'; // Use Year for older ages
    }
  }
  
  transformData(data: any[]) {
    const result1: { columnName: string; columnValue: any; linkText: string, linkUrl: string }[] = [];
    data.forEach(item => {
      Object.keys(item).forEach(key => {
        if (key !== 'age' && key !== 'lsgroup')
          result1.push({ columnName: key, columnValue: item[key], linkText: key + ' sources', linkUrl: key });
      });
    });
    console.log(result1);
    return result1;
  }

  onPanelOpened(index: number): void {
    if (index === 0) {
      this.isFirstSectionOpen = true; // Ensure the first section stays open
    }
  }

  onPanelClosed(index: number): void {
    if (index === 0) {
      this.isFirstSectionOpen = true; // Reopen the first section if it is closed
    }
  }

  reset() {
    this.results = [];
    this.dataSource.data = [];
    this.transformedData = [];
    this.SECTIONS_DATA[0].items = [];
    this.SECTIONS_DATA[1].items = [];
    this.SECTIONS_DATA[2].items = [];
    this.SECTIONS_DATA[3].items = [];

  }
  // Function to handle form submission
  onSearch() {
    this.reset();
    if (this.searchCriteria.lsgroup === 'Infant' && (this.searchCriteria.age < 0 || this.searchCriteria.age > 12)) {
      alert('For infants, age must be between 0 and 12 months');
      return;
    }
    const { age, lsgroup } = this.searchCriteria;
    this.driService.getDRI(this.searchCriteria).subscribe(
      (data: DRI[]) => {
        data.forEach((a, i) => {
          var nutrient: DRI = {
            age: a.age,
            lsgroup: a.lsgroup,
            water: a.water,
            carbohydrate: a.carbohydrate,
            fiber: a.fiber,
            fat: a.fat,
            linoleicacid: a.linoleicacid,
            alphalinolenicacid: a.alphalinolenicacid,
            protein: a.protein,
            calcium: a.calcium,
            copper: a.copper,
            chromium: a.chromium,
            fluoride: a.fluoride,
            iodine: a.iodine,
            iron: a.iron,
            magnesium: a.magnesium,
            manganese: a.manganese,
            molybdenum: a.molybdenum,
            phosphorus: a.phosphorus,
            selenium: a.selenium,
            zinc: a.zinc,
            potassium: a.potassium,
            sodium: a.sodium,
            chloride: a.chloride,
            vitamina: a.vitamina,
            vitaminc: a.vitaminc,
            vitamind: a.vitamind,
            vitamine: a.vitamine,
            vitamink: a.vitamink,
            thiamin: a.thiamin,
            riboflavin: a.riboflavin,
            niacin: a.niacin,
            vitaminb6: a.vitaminb6,
            folate: a.folate,
            vitaminb12: a.vitaminb12,
            pantothenicacid: a.pantothenicacid,
            biotin: a.biotin,
            choline: a.choline
          };

          var macronutrient: MacroNutrients[] = [{
            age: a.age,
            lsgroup: a.lsgroup,
            carbohydrate: a.carbohydrate,
            fiber: a.fiber,
            fat: a.fat,
            linoleicacid: a.linoleicacid,
            alphalinolenicacid: a.alphalinolenicacid,
            protein: a.protein
          }];
          var water: Water[] = [{
            water: a.water,
            age: a.age,
            lsgroup: a.lsgroup
          }];
          var vitamins: Vitamins[] = [{
            age: a.age,
            lsgroup: a.lsgroup,
            vitamina: a.vitamina,
            vitaminc: a.vitaminc,
            vitamind: a.vitamind,
            vitamine: a.vitamine,
            vitamink: a.vitamink,
            thiamin: a.thiamin,
            riboflavin: a.riboflavin,
            niacin: a.niacin,
            vitaminb6: a.vitaminb6,
            folate: a.folate,
            vitaminb12: a.vitaminb12,
            pantothenicacid: a.pantothenicacid,
            biotin: a.biotin,
            choline: a.choline
          }];
          var elements: Elements[] = [{
            age: a.age,
            lsgroup: a.lsgroup,
            calcium: a.calcium,
            copper: a.copper,
            chromium: a.chromium,
            fluoride: a.fluoride,
            iodine: a.iodine,
            iron: a.iron,
            magnesium: a.magnesium,
            manganese: a.manganese,
            molybdenum: a.molybdenum,
            phosphorus: a.phosphorus,
            selenium: a.selenium,
            zinc: a.zinc,
            potassium: a.potassium,
            sodium: a.sodium,
            chloride: a.chloride
          }];

          this.results.push(nutrient);
          this.SECTIONS_DATA[0].items = this.transformData(water);
          this.SECTIONS_DATA[1].items = this.transformData(macronutrient);
          this.SECTIONS_DATA[2].items = this.transformData(elements);
          this.SECTIONS_DATA[3].items = this.transformData(vitamins);

        });
        this.dataSource.data = this.dataSource.data.concat(this.results);
        //this.transposedData.data = this.transposeData(this.dataSource.data);
        this.transformedData = this.transformData(this.dataSource.data);
        console.log(this.transformedData);
      },
      error => {
        console.error('Error:', error);
      });

  }

  openRouteInNewTab(id: string) {
    // Generate the URL for the route
    if (this.authService.isLoggedIn()) {
      // const tree = this.router.createUrlTree(['/details'+'/'+id]);
      // this.router.navigateByUrl(tree); // Navigate using the created URL tree

      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/details' + '/' + id]) // Replace 'target-route' with your route
      );

      // Open the URL in a new tab
      window.open(url, '_blank');
    }
    else {
      alert('user not logged in');
    }

  }
  goBack() {
    this.location.back(); // Navigate back to the previous route
  }

  goHome() {
    //this.location.back(); // Navigate back to the previous route
    this.router.navigate(['/home']);
    //, private router: Router

  }
}


