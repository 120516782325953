<div class="signup-container">
    <form class="signup-form" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
        <h2>Create an Account</h2>
        <input type="text" id="name" name="name" placeholder="Name" formControlName="name" required>
        <input type="email" id="email" name="email" placeholder="Email" formControlName="email" required>
        <input type="password" id="password" name="password" placeholder="Password" formControlName="password" required>
        <div class="terms-container">
            <input type="checkbox" id="agree" name="agree" formControlName="cb" required>
            <label for="agree">I have read and agree to the below <u>Terms of Service</u>.</label>
        </div>
        <div>
            <textarea readonly class="terms-textarea">
Welcome to AI-Basil.com. By accessing or using our website, or services (collectively, the "Service"), you agree to be bound by these Terms and Conditions ("Terms"). If you disagree with any part of the terms, you may not access the Service.
            
1. Account Registration
1.1. You must be at least 18 years old to create an account on this Service.
1.2. You are responsible for maintaining the confidentiality of your account and password.
1.3. You agree to provide accurate, current, and complete information during the registration process and to update such
information to keep it accurate, current, and complete.

2. Content and Use of Service
2.1. AI-Basil.com provides nutritional information and dietary content for educational purposes only. The information
should not be considered medical advice.
2.2. Users should consult with a qualified healthcare professional before making any significant changes to their diet
or starting any nutrition-related program found on the Service.
2.3. AI-Basil.com does not guarantee the accuracy, completeness, or usefulness of any information on the Service and
will not be liable for any errors or omissions in the content.

3. Prohibited Activities
Users are prohibited from:
3.1 Impersonating any person or entity or falsely stating or misrepresenting your affiliation with a person or entity.
3.2. Using the Service for any illegal purpose or in violation of any local, state, national, or international law.
3.3. Interfering with or disrupting the Website or servers or networks connected to the Website.

4. Intellectual Property
4.1. The Service and its original content (excluding User Content), features, and functionality are and will remain the
exclusive property of AI-Basil.com and its licensors.
4.2. Trademarks, service marks, logos, trade names, and other proprietary designations of AI-Basil.com used herein are
trademarks or registered trademarks of AI-Basil.com.

5. Disclaimer of Warranties
5.1. The Service is provided on an "AS IS" and "AS AVAILABLE" basis without any representation or warranty, whether
express, implied, or statutory.
5.2. AI-Basil.com specifically disclaims any implied warranties of title, merchantability, fitness for a particular
purpose, and non-infringement.

6. Termination
6.1. We may terminate or suspend your account and bar access to the Website immediately, without prior notice or
liability, under our sole discretion, for any reason whatsoever.

7. Limitation of Liability
7.1. To the maximum extent permitted by applicable law, in no event shall AI-Basil.com be liable for any indirect,
punitive, incidental, special, consequential, or exemplary damages, including without limitation damages for loss of
profits, goodwill, use, data, or other intangible losses.

8. Indemnification
8.1. You agree to defend, indemnify, and hold harmless AI-Basil.com and its licensee and licensors, and their employees,
contractors, agents, officers, and directors, from and against any and all claims, damages, obligations, losses,
liabilities, costs or debt, and expenses.

9. Changes to Terms
9.1. We reserve the right to modify or replace these Terms at any time. It is your responsibility to check the Terms
periodically for changes.

10. Contact Us
If you have any questions about these Terms, please contact us at query&#64;ai-basil.com. By using the Service, you
acknowledge that you have read and understood these Terms and agree to be bound by them.

Last updated: [Aug 28, 2024].
            </textarea>
        </div>
        <button>Signup</button>
        <p>Already have an account? <a routerLink="/login">Login</a></p>
    </form>
</div>