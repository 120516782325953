import { ApplicationConfig } from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import routeConfig from './app/routes';
import { provideProtractorTestingSupport } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter} from '@angular/router';

export const appConfig: ApplicationConfig = {
  providers: [
    provideProtractorTestingSupport(),
    provideRouter(routeConfig),
    provideHttpClient(),
    provideAnimations(),
    provideAnimations()
]
};



