import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],

  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent {

  currentYear: number = new Date().getFullYear();
  authService = inject(AuthService);
  router = inject(Router);

  onLogout(): void {
    // Handle logout logic here
    // For example, navigate to a logout route or clear authentication data
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  onAbout(): void {
    // Navigate to the About page

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/about']) // Replace 'target-route' with your route
    );

    // Open the URL in a new tab
    window.open(url, '_blank');
  }

  onContact(): void {
    // Navigate to the Contact page
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/contact']) // Replace 'target-route' with your route
    );

    // Open the URL in a new tab
    window.open(url, '_blank');
  }
  //   currentYear: number = new Date().getFullYear();
  //   authService = inject(AuthService);
  //   router = inject(Router);
  //   public logout(){
  //     this.authService.logout();
  //     this.router.navigate(['/login']);
  // }
  // About() {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['/about']) // Replace 'target-route' with your route
  //   );

  //   // Open the URL in a new tab
  //   window.open(url, '_blank');
  // } 

  // Contact() {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['/contact']) // Replace 'target-route' with your route
  //   );

  //   // Open the URL in a new tab
  //   window.open(url, '_blank');
  // } 
}