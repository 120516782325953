import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon'; // ✅ Import this


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, CommonModule,MatIconModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  dropdownOpen = false;
  public loggedInUser: any = null;
  public cartItemCount: number = 0; // Initialize cart item count
  public authService = inject(AuthService); // Make authService public
  availabilityMessage: string = '';
  isPremiumUser: boolean; // Default value; update it as needed
  userName: String;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.isPremiumUser = localStorage.getItem('isPremium') === 'True';
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      const userData = JSON.parse(authUser); // Parse stored JSON string
      this.userName = userData?.name || 'User'; // Default to "User" if name is missing
    }
    const authuser: string | null = localStorage.getItem('authUser');

    const openingTime = 9;  // 9 AM
    const closingTime = 23; // 11 PM
    const currentTime = new Date().getHours();

/*     this.availabilityMessage = (currentTime >= openingTime && currentTime < closingTime) 
      ? "We are available from 9 AM to 11 PM (EST)!" 
      : "Our website is available between 9 AM to 11 PM (EST).";
 */
    if (authuser !== null) {
      const id = JSON.parse(authuser).id;
      this.getUserDetails(id);
    }

    // Subscribe to user updates
    this.authService.getUserUpdates().subscribe(updatedUser => {
      if (updatedUser) {
        this.loggedInUser = updatedUser;
        this.cartItemCount = this.loggedInUser.cart.length; // Update cart count
      }
    });
  }
  isLoginPage(): boolean {
    return this.router.url === '/login'; // Adjust the path if needed
  }
  getUserDetails(userid: number): void {
    this.authService.getUser(userid).subscribe(response => {
      if (response) {
        console.log(response);
        this.loggedInUser = response;  
        this.cartItemCount = this.loggedInUser.cart.length;    
      } else {
        console.error('Error fetching user details');
      }
    });
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  onChangePassword() {
    this.router.navigate(['/change-password']);
    this.dropdownOpen = false;
  }

  onGoToCart() {
    this.router.navigate(['/cart']);
    this.dropdownOpen = false;
  }
  onEditProfile() {
    this.router.navigate(['/profile/edit']);
    this.dropdownOpen = false;
  }

  onManagePayment() {
    this.router.navigate(['/profile/payment']);
    this.dropdownOpen = false;
  }

  onDeleteAccount() {
    this.router.navigate(['/profile/delete']);
    this.dropdownOpen = false;
  }


  onLogout() {
    this.loggedInUser = null;
    this.authService.logout();
    this.router.navigate(['/landing']);
    this.dropdownOpen = false;
  }
  onUpgrade() {
    this.router.navigate(['/pricing']); // Redirect to the pricing or subscription page
  }


  
  onLogin() {
    this.router.navigate(['/login']);
  }
}
