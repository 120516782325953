<!--app-admin></app-admin-->

<mat-tab-group animationDuration=0 mat-align-tabs="start">

  <mat-tab label="Nutrients">
    <div class="input-container">
      <!--<input list="food" [(ngModel)]="inputValue" placeholder="Protein, Iodine, Iron..." class="input-field"
        name="foodList" #filter />-->

      <mat-form-field>
        <input matInput placeholder="Protein, Iodine, Iron......" [(ngModel)]="inputValue"
          (input)="filterResults(inputValue)" list="food">
        <mat-label> Search by Nutrient </mat-label>
        <datalist id="food">
          <option value="Protein"></option>
          <option value="Carbohydrate"></option>
          <option value="Fiber"></option>
          <option value="SaturatedFats"></option>
          <option value="MonoUnsaturatedFats"></option>
          <option value="PolyUnsaturatedFats"></option>
          <option value="TransFats"></option>
          <option value="Cholestrol"></option>
          <option value="Water"></option>
          <option value="Biotin"></option>
          <option value="Boron"></option>
          <option value="Calcium"></option>
          <option value="CaroteneAlpha"></option>
          <option value="CaroteneBeta"></option>
          <option value="CaroteneGamma"></option>
          <option value="Chlorine"></option>
          <option value="Cholinefree"></option>
          <option value="CholineFromPhosphocholineMg"></option>
          <option value="CholineFromPhosphotidylCholineMg"></option>
          <option value="CholineFromSphingomyelinMg"></option>
          <option value="CholineFromGlycerophosphocholineMg"></option>
          <option value="CholineTotal"></option>
          <option value="Chromium"></option>
          <option value="Copper"></option>
          <option value="Fluoride"></option>
          <option value="FolateDFE"></option>
          <option value="FolateFood"></option>
          <option value="FolateTotal"></option>
          <option value="Iodine"></option>
          <option value="Iron"></option>
          <option value="Magnesium"></option>
          <option value="Manganese"></option>
          <option value="Molybdenum"></option>
          <option value="Niacin"></option>
          <option value="Nickel"></option>
          <option value="PantothenicAcid"></option>
          <option value="Phosphorus"></option>
          <option value="Potassium"></option>
          <option value="Riboflavin"></option>
          <option value="Selenium"></option>
          <option value="Sodium"></option>
          <option value="Sulfur"></option>
          <option value="Thiamin"></option>
          <option value="VitaminA_RAE"></option>
          <option value="VitaminAIU"></option>
          <option value="VitaminB12Added"></option>
          <option value="VitaminB12"></option>
          <option value="VitaminB6"></option>
          <option value="VitaminC"></option>
          <option value="VitaminD_D2PlusD3IU"></option>
          <option value="VitaminD_D2PlusD3"></option>
          <option value="VitaminD2"></option>
          <option value="VitaminD3"></option>
          <option value="VitaminD4"></option>
          <option value="VitaminE_Added"></option>
          <option value="VitaminE_AlphaTocopherol"></option>
          <option value="VitaminELabelEntryIU"></option>
          <option value="VitaminEMcgRE"></option>
          <option value="VitaminK_Dihydrophylloquinone"></option>
          <option value="VitaminK_Menaquinone4"></option>
          <option value="VitaminK_Phylloquinone"></option>
          <option value="Zinc"></option>
        </datalist>
      </mat-form-field>


      <!-- Clear button that appears when there's input text -->
      <!--<button *ngIf="inputValue" class="clear-button" (click)="clearInput()">
        &#10006;
      </button>
      <datalist id="food">
        <option value="Protein">
        <option value="Carbohydrate">
        <option value="Fiber">
        <option value="SaturatedFats">
        <option value="MonoUnsaturatedFats">
        <option value="PolyUnsaturatedFats">
        <option value="TransFats">
        <option value="Cholestrol">
        <option value="Water">
        <option value="Biotin">
        <option value="Boron">
        <option value="Calcium">
        <option value="CaroteneAlpha">
        <option value="CaroteneBeta">
        <option value="CaroteneGamma">
        <option value="Chlorine">
        <option value="Cholinefree">
        <option value="CholineFromPhosphocholineMg">
        <option value="CholineFromPhosphotidylCholineMg">
        <option value="CholineFromSphingomyelinMg">
        <option value="CholineFromGlycerophosphocholineMg">
        <option value="CholineTotal">
        <option value="Chromium">
        <option value="Copper">
        <option value="Fluoride">
        <option value="FolateDFE">
        <option value="FolateFood">
        <option value="FolateTotal">
        <option value="Iodine">
        <option value="Iron">
        <option value="Magnesium">
        <option value="Manganese">
        <option value="Molybdenum">
        <option value="Niacin">
        <option value="Nickel">
        <option value="PantothenicAcid">
        <option value="Phosphorus">
        <option value="Potassium">
        <option value="Riboflavin">
        <option value="Selenium">
        <option value="Sodium">
        <option value="Sulfur">
        <option value="Thiamin">
        <option value="VitaminA_RAE">
        <option value="VitaminAIU">
        <option value="VitaminB12Added">
        <option value="VitaminB12">
        <option value="VitaminB6">
        <option value="VitaminC">
        <option value="VitaminD_D2PlusD3IU ">
        <option value="VitaminD_D2PlusD3">
        <option value="VitaminD2">
        <option value="VitaminD3">
        <option value="VitaminD4">
        <option value="VitaminE_Added">
        <option value="VitaminE_AlphaTocopherol">
        <option value="VitaminELabelEntryIU">
        <option value="VitaminEMcgRE ">
        <option value="VitaminK_Dihydrophylloquinone">
        <option value="VitaminK_Menaquinone4 ">
        <option value="VitaminK_Phylloquinone">
        <option value="Zinc">
      </datalist>-->
    </div>
    <!-- Search button -->

    <!--<button class="primary" type="button" (click)="filterResults(filter.value)"> Search </button>
  -->
    <!--div class="filter-container"-->
    <!--input matInput (keyup)="applyFilter($event)" placeholder="Filter by category"-->
    <!--/div-->

    <div class="filter-container">
      <mat-form-field appearance="fill">
        <mat-label>Diet Type</mat-label>
        <mat-select [(value)]="selectedDiet" (selectionChange)="applyFilter()">
          <mat-option value="">All Diets</mat-option>
          <mat-option value="vegan">Vegan</mat-option>
          <mat-option value="vegetarian">Vegetarian</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Food Category</mat-label>
        <mat-select [(value)]="selectedCategory" (selectionChange)="applyFilter()">
          <mat-option *ngFor="let category of filteredCategories" [value]="category">{{ category }}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <section class="results">
      <table mat-table [dataSource]="filteredDataSource" class="mat-elevation-z8 custom-table">
        <!-- Index Column -->
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef> Index </th>
          <td mat-cell *matCellDef="let food "> {{food.index}} </td>
        </ng-container>

        <!-- Food Description Column -->
        <ng-container matColumnDef="fooddescription">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let food"> {{food.fooddescription}} </td>
        </ng-container>

        <!-- Foodcategory Column -->
        <ng-container matColumnDef="foodcategory">
          <th mat-header-cell *matHeaderCellDef> Category </th>
          <td mat-cell *matCellDef="let food"> {{food.foodcategory}} </td>
        </ng-container>

        <!-- Serving Size Column -->
        <ng-container matColumnDef="perservingsizeing">
          <th mat-header-cell *matHeaderCellDef> Serving Size </th>
          <td mat-cell *matCellDef="let food"> {{food.perservingsizeing}} </td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="per100unitservinging">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let food"> {{food.per100unitservinging}} </td>
        </ng-container>

        <!-- Unit Column -->
        <ng-container matColumnDef="unitname">
          <th mat-header-cell *matHeaderCellDef> Unit </th>
          <td mat-cell *matCellDef="let food"> {{food.unitname}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </section>

  </mat-tab>
  <mat-tab label="DRI"><app-search-form></app-search-form></mat-tab>
  <mat-tab label="Search Food">
    <div class="food-search">
      <!-- Input field for searching food by name -->
      <mat-form-field appearance="fill">
        <mat-label>Enter Food Name</mat-label>
        <input matInput placeholder="Search food..." [(ngModel)]="foodName" list="nutrients" (input)="onSearchFood()" />
        <datalist id="nutrients">
          <option *ngFor="let food of allFoods" [value]="food"></option>
        </datalist>
      </mat-form-field>

      <!-- Search Button -->
      <!--<button mat-raised-button color="primary" (click)="onSearchFood()">Search</button>-->
    </div>

    <!-- Display the nutrients info in the same tab -->
    <h3 *ngIf="foodData">Food Information</h3>
    <div *ngIf="foodData">
      <p><strong>Food Name:</strong> {{ foodData.foodName }}</p>
      <p><strong>Category:</strong> {{ foodData.foodCategory.categoryName }}</p>

      <h3>Nutrients Information</h3>
      <mat-table [dataSource]="foodData.nutrients" class="mat-elevation-z8" style="width: 100%">
        <!-- Nutrient Name Column -->
        <ng-container matColumnDef="nutrientName">
          <th mat-header-cell *matHeaderCellDef> Nutrient Name </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.nutrientName }} </td>
        </ng-container>

        <!-- Nutrient Amount Column -->
        <ng-container matColumnDef="nutrientAmount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.nutrientAmount }} </td>
        </ng-container>

        <!-- Unit Column -->
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef> Unit </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.unit }} </td>
        </ng-container>

        <!-- Portion Size Column -->
        <ng-container matColumnDef="portionSize">
          <th mat-header-cell *matHeaderCellDef> Portion Size </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.portionSize }} </td>
        </ng-container>

        <!-- Portion Size Unit Column -->
        <ng-container matColumnDef="portionSizeUnit">
          <th mat-header-cell *matHeaderCellDef> Portion Size Unit </th>
          <td mat-cell *matCellDef="let nutrient"> {{ nutrient.portionSizeUnit }} </td>
        </ng-container>

        <!-- Table headers and rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
      </mat-table>
    </div>
    <!-- Nutrient Distribution Chart -->

  </mat-tab>
  <mat-tab label="Nutrition Label Generator">
    <form>
      <!-- Food Name Input -->
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Enter Food Name</mat-label>
        <input matInput [(ngModel)]="foodName" name="foodName" placeholder="E.g., Salad" />
      </mat-form-field>
  
      <!-- Ingredient Input -->
      <mat-form-field appearance="fill" class="half-width">
        <mat-label>Ingredient</mat-label>
        <input matInput [(ngModel)]="newIngredient" placeholder="Enter ingredient" name="newIngredient"  list="nutrients" />
        <datalist id="nutrients">
          <option *ngFor="let food of allFoods" [value]="food"></option>
        </datalist>
      </mat-form-field>

      <!-- Amount Input -->
      <mat-form-field appearance="fill" class="half-width">
        <mat-label>Amount (g)</mat-label>
        <input matInput type="number" [(ngModel)]="newAmount" placeholder="Amount in grams" name="newAmount" />

      </mat-form-field>
  
      <!-- Add Ingredient Button -->
      <button mat-button color="primary" (click)="addIngredient()">Add Ingredient</button>
    </form>
  
    <!-- Dynamic Ingredients Table -->
    <table mat-table [dataSource]="ingredients" class="mat-elevation-z8">
  
      <!-- Ingredient Column -->
      <ng-container matColumnDef="ingredient">
        <th mat-header-cell *matHeaderCellDef> Ingredient </th>
        <td mat-cell *matCellDef="let element"> {{ element.ingredient }} </td>
      </ng-container>
  
      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Amount (g) </th>
        <td mat-cell *matCellDef="let element"> {{ element.amount }} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns_nutrition"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns_nutrition;"></tr>
    </table>
  
<!-- Generate Button for mat-table -->
<button mat-raised-button color="accent" (click)="generateNutritionLabel()">
  Generate Nutrition Label (Mat Table)
</button>

<!-- Generate Button for FDA Nutrition Label -->
<button mat-raised-button color="accent" (click)="generateNutritionLabelFDA()">
  Generate FDA Nutrition Label
</button>

<!-- Placeholder for Result Display -->
<!-- Mat Table Display -->
<div *ngIf="showMatTable && nutritionLabel.length > 0">
  <h3>Nutrition Label</h3>
  <table mat-table [dataSource]="nutritionLabel" class="mat-elevation-z8">
    <!-- Nutrient Name Column -->
    <ng-container matColumnDef="nutrientName">
      <th mat-header-cell *matHeaderCellDef> Nutrient </th>
      <td mat-cell *matCellDef="let element"> {{ element.nutrientName }} </td>
    </ng-container>

    <!-- Nutrient Amount Column -->
    <ng-container matColumnDef="nutrientAmount">
      <th mat-header-cell *matHeaderCellDef> Amount </th>
      <td mat-cell *matCellDef="let element"> {{ element.nutrientAmount }} </td>
    </ng-container>

    <!-- Unit Column -->
    <ng-container matColumnDef="unit">
      <th mat-header-cell *matHeaderCellDef> Unit </th>
      <td mat-cell *matCellDef="let element"> {{ element.unit }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['nutrientName', 'nutrientAmount', 'unit']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['nutrientName', 'nutrientAmount', 'unit'];"></tr>
  </table>
</div>


<!-- FDA Nutrition Label Display -->
<div *ngIf="showFDA && nutritionLabel.length > 0" class="nutrition-label">
  <div class="nutrition-title">Nutrition Facts</div>
  <div class="serving-info">
    {{ servingInfo }}<br />
    <div style="display: flex; justify-content: space-between">
      <span class="bold">Serving size</span>
      <span class="bold">{{ servingSize }}</span>
    </div>
  </div>

  <div class="calories-row">
    <span class="calories-label">Calories</span>
    <span class="calories-value">{{ totalCalories }}</span>
  </div>
  <!-- Total Fat -->
  <div class="nutrient-row thick-line">
    <span class="bold">Total Fat</span>
    <span><span class="bold">{{ totalFat }} {{ fatUnit }}</span></span>
  </div>

  <!-- Saturated Fat -->
  <div class="nutrient-row sub-nutrient">
    <span>Saturated Fat</span>
    <span><span class="bold">{{ saturatedFat }} {{ fatUnit }}</span></span>
  </div>

  <!-- Trans Fat -->
  <div class="nutrient-row sub-nutrient">
    <span>Trans Fat</span>
    <span><span class="bold">{{ transFat }} {{ fatUnit }}</span></span>
  </div>

  <!-- Cholesterol -->
  <div class="nutrient-row">
    <span class="bold">Cholesterol</span>
    <span><span class="bold">{{ cholesterol }} mg</span></span>
  </div>

  <!-- Sodium -->
  <div class="nutrient-row">
    <span class="bold">Sodium</span>
    <span><span class="bold">{{ sodium }} mg</span></span>
  </div>

  <!-- Total Carbohydrate -->
  <div class="nutrient-row">
    <span class="bold">Total Carbohydrate</span>
    <span><span class="bold">{{ totalCarbohydrate }} g</span></span>
  </div>

  <!-- Dietary Fiber -->
  <div class="nutrient-row sub-nutrient">
    <span>Dietary Fiber</span>
    <span><span class="bold">{{ dietaryFiber }} g</span></span>
  </div>

  <!-- Total Sugars -->
  <div class="nutrient-row sub-nutrient">
    <span>Total Sugars</span>
    <span><span class="bold">{{ totalSugars }} g</span></span>
  </div>

  <!-- Added Sugars -->
  <div class="nutrient-row sub-nutrient" style="padding-left: 2rem">
    <span>Includes {{ addedSugars }}g Added Sugars</span>
    <span>{{ addedSugarsPercentage }}%</span>
  </div>

  <!-- Protein -->
  <div class="nutrient-row thick-line">
    <span class="bold">Protein</span>
    <span><span class="bold">{{ protein }} g</span></span>
  </div>

  <!-- Other nutrients continue here... -->


  <!-- Vitamin D -->
  <div class="nutrient-row">
    <span>Vitamin D</span>
    <span><span class="bold">{{ vitaminD }} mcg</span></span>
  </div>

  <!-- Calcium -->
  <div class="nutrient-row">
    <span>Calcium</span>
    <span><span class="bold">{{ calcium }} mg</span></span>
  </div>

  <!-- Iron -->
  <div class="nutrient-row">
    <span>Iron</span>
    <span><span class="bold">{{ iron }} mg</span></span>
  </div>

  <!-- Potassium -->
  <div class="nutrient-row">
    <span>Potassium</span>
    <span><span class="bold">{{ potassium }} mg</span></span>
  </div>

  <div class="dv-note">
    * The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.
  </div>
</div>





  </mat-tab>
</mat-tab-group>

