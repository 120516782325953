<div class="landing-page">
  <!-- Login Section -->
  <!-- Login Form Section -->
  <div class="login-section">
    <div class="login-form">
      <h2>Change Password</h2>
      <form class="form-group" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">

          <div class="password-container" style="position: relative;">

            <!--input type="password" formControlName="password" placeholder="Password"-->
            <input [type]="showPassword ? 'text' : 'password'" id="password" formControlName="password"
              placeholder="Password" />
            <!-- Eye Icon for Show/Hide Password -->
            <span (click)="togglePasswordVisibility()" class="eye-icon"
              style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer;">
              <i [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i> <!-- Using FontAwesome -->
            </span>
          </div>

          <div class="password-container" style="position: relative;">

            <!--input type="password" formControlName="password" placeholder="Password"-->
            <input [type]="showPassword ? 'text' : 'confirmpassword'" id="confirmpassword" formControlName="confirmpassword"
              placeholder="Confirm Password" />
            <!-- Eye Icon for Show/Hide Password -->
            <span (click)="togglePasswordVisibility()" class="eye-icon"
              style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer;">
              <i [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i> <!-- Using FontAwesome -->
            </span>
          </div>
          <button class="btn">Submit</button>
          </div>
      </form>
    </div>
  </div>