import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { foodList, FoundationFoods } from './food-data';
import { Observable } from 'rxjs/internal/Observable';
import { __values } from 'tslib';

@Injectable({
  providedIn: 'root',
})

export class FoodService {
  constructor(private httpClient: HttpClient){}


  url_protein ='https://ai-basil.com/v1/foods/protein/Foundation';
  url_carbohydrate ='https://ai-basil.com/v1/foods/carbohydrate/Foundation';
  url_fiber ='https://ai-basil.com/v1/foods/fiber/Foundation';
  url_saturatedfats ='https://ai-basil.com/v1/foods/saturatedfats/Foundation';

  url_monounsaturatedfats ='https://ai-basil.com/v1/foods/monounsaturatedfats/Foundation';
  url_polyunsaturatedfats ='https://ai-basil.com/v1/foods/polyunsaturatedfats/Foundation';
  url_transfats ='https://ai-basil.com/v1/foods/transfats/Foundation';

  url_water ='https://ai-basil.com/v1/foods/water/Foundation';
  url_cholestrol ='https://ai-basil.com/v1/foods/cholestrol/Foundation';


  url_biotin ='https://ai-basil.com/v1/foods/biotin/Foundation';
  url_boron ='https://ai-basil.com/v1/foods/boron/Foundation';
  url_calcium ='https://ai-basil.com/v1/foods/calcium/Foundation';
  url_carotene_alpha ='https://ai-basil.com/v1/foods/carotenealpha/Foundation';
  url_carotene_beta ='https://ai-basil.com/v1/foods/carotenebeta/Foundation';
  url_carotene_gamma ='https://ai-basil.com/v1/foods/carotenegamma/Foundation';
  url_chlorine ='https://ai-basil.com/v1/foods/chlorine/Foundation';


  url_cholineFree = 'https://ai-basil.com/v1/foods/cholineFree/Foundation';
  url_cholineFromPhosphocholineMg = 'https://ai-basil.com/v1/foods/cholineFromPhosphocholineMg/Foundation';
  url_cholineFromPhosphotidylCholineMg = 'https://ai-basil.com/v1/foods/cholineFromPhosphotidylCholineMg/Foundation';
  url_cholineFromSphingomyelinMg = 'https://ai-basil.com/v1/foods/cholineFromSphingomyelinMg/Foundation';
  url_cholineFromGlycerophosphocholineMg = 'https://ai-basil.com/v1/foods/cholineFromGlycerophosphocholineMg/Foundation';
  url_cholineTotal = 'https://ai-basil.com/v1/foods/cholineTotal/Foundation';


  url_chromiumMugram = 'https://ai-basil.com/v1/foods/chromiumMugram/Foundation';
  url_copper = 'https://ai-basil.com/v1/foods/copper/Foundation';
  url_fluoride = 'https://ai-basil.com/v1/foods/fluoride/Foundation';
  url_folateDFE = 'https://ai-basil.com/v1/foods/folateDFE/Foundation';
  url_folateFood = 'https://ai-basil.com/v1/foods/folateFood/Foundation';
  url_folateTotal = 'https://ai-basil.com/v1/foods/folateTotal/Foundation';
  url_iodine = 'https://ai-basil.com/v1/foods/iodine/Foundation';
  url_iron = 'https://ai-basil.com/v1/foods/iron/Foundation';
  url_magnesium = 'https://ai-basil.com/v1/foods/magnesium/Foundation';
  url_manganese = 'https://ai-basil.com/v1/foods/manganese/Foundation';
  url_molybdenum = 'https://ai-basil.com/v1/foods/molybdenum/Foundation';

  url_niacin = 'https://ai-basil.com/v1/foods/niacin/Foundation';
  url_nickel = 'https://ai-basil.com/v1/foods/nickel/Foundation';

  url_pantothenicAcid = 'https://ai-basil.com/v1/foods/pantothenicAcid/Foundation';
  url_phosphorus = 'https://ai-basil.com/v1/foods/phosphorus/Foundation';
  url_potassium = 'https://ai-basil.com/v1/foods/potassium/Foundation';
  url_riboflavin = 'https://ai-basil.com/v1/foods/riboflavin/Foundation';
  url_selenium = 'https://ai-basil.com/v1/foods/selenium/Foundation';
  url_sodium = 'https://ai-basil.com/v1/foods/sodium/Foundation';
  url_sulfur = 'https://ai-basil.com/v1/foods/sulfur/Foundation';
  url_thiamin = 'https://ai-basil.com/v1/foods/thiamin/Foundation';
  url_vitaminA_RAE = 'https://ai-basil.com/v1/foods/vitaminA_RAE/Foundation';
  url_vitaminAIU = 'https://ai-basil.com/v1/foods/vitaminAIU/Foundation';
  url_vitaminB12Added = 'https://ai-basil.com/v1/foods/vitaminB12Added/Foundation';
  url_vitaminB12 = 'https://ai-basil.com/v1/foods/vitaminB12/Foundation';
  url_vitaminB6 = 'https://ai-basil.com/v1/foods/vitaminB6/Foundation';
  url_vitaminC = 'https://ai-basil.com/v1/foods/vitaminC/Foundation';
  url_VitaminD_D2PlusD3IU = 'https://ai-basil.com/v1/foods/vitaminD_D2PlusD3IU/Foundation';
  url_vitaminD_D2PlusD3 = 'https://ai-basil.com/v1/foods/vitaminD_D2PlusD3/Foundation';
  url_vitaminD2 = 'https://ai-basil.com/v1/foods/vitaminD2/Foundation';
  url_vitaminD = 'https://ai-basil.com/v1/foods/vitaminD/Foundation';
  url_vitaminE = 'https://ai-basil.com/v1/foods/vitaminE/Foundation';
  url_vitaminK = 'https://ai-basil.com/v1/foods/vitaminK/Foundation';
  url_vitaminD3 = 'https://ai-basil.com/v1/foods/vitaminD3/Foundation';

  url_vitaminD4 = 'https://ai-basil.com/v1/foods/vitaminD4/Foundation';
  url_vitaminE_Added = 'https://ai-basil.com/v1/foods/vitaminE_Added/Foundation';
  url_vitaminE_AlphaTocopherol = 'https://ai-basil.com/v1/foods/vitaminE_AlphaTocopherol/Foundation';
  url_vitaminELabelEntryIU = 'https://ai-basil.com/v1/foods/vitaminELabelEntryIU/Foundation';
  url_vitaminEMcgRE = 'https://ai-basil.com/v1/foods/vitaminEMcgRE/Foundation';
  url_vitaminK_Dihydrophylloquinone = 'https://ai-basil.com/v1/foods/vitaminK_Dihydrophylloquinone/Foundation';
  url_vitaminK_Menaquinone4 = 'https://ai-basil.com/v1/foods/vitaminK_Menaquinone4/Foundation';

  url_vitaminK_Phylloquinone = 'https://ai-basil.com/v1/foods/vitaminK_Phylloquinone/Foundation';
  url_zinc = 'https://ai-basil.com/v1/foods/zinc/Foundation';
  
  
  /* getFoodList(food:string): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.foodlist[food]);
  } */

  getFoodList_Protein(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_protein);
  }

  getFoodList_Carb(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_carbohydrate);
  }

  getFoodList_Fiber(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_fiber);
  }

  getFoodList_SaturatedFats(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_saturatedfats);
  }

  getFoodList_TransFats(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_transfats);
  }

  getFoodList_MonoUnsaturatedFats(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_monounsaturatedfats);
  }

  getFoodList_PolyUnsaturatedFats(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_polyunsaturatedfats);
  }

  getFoodList_Cholestrol(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_cholestrol);
  }

  getFoodList_Water(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_water);
  }


  getFoodList_Biotin(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_biotin);
  }

  getFoodList_Boron(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_boron);
  }

  getFoodList_Calcium(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_calcium);
  }

  getFoodList_Carotene_alpha(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_carotene_alpha);
  }

  getFoodList_Carotene_beta(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_carotene_beta);
  }

  getFoodList_Carotene_gamma(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_carotene_gamma);
  }

  getFoodList_Chlorine(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_chlorine);
  }

  getFoodList_CholineFree(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_cholineFree);
  }


  getFoodList_CholineFromPhosphocholineMg(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_cholineFromPhosphocholineMg);
  }

  getFoodList_CholineFromPhosphotidylCholineMg(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_cholineFromPhosphotidylCholineMg);
  }

  getFoodList_CholineFromSphingomyelinMg(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_cholineFromSphingomyelinMg);
  }

  getFoodList_CholineFromGlycerophosphocholine(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_cholineFromGlycerophosphocholineMg);
  }


  getFoodList_CholineTotal(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_cholineTotal);
  }


  getFoodList_ChromiumMugram(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_chromiumMugram);
  }


  getFoodList_Copper(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_copper);
  }

  getFoodList_Fluoride(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_fluoride);
  }


  getFoodList_FolateDFE(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_folateDFE);
  }


  getFoodList_folateFood(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_folateFood);
  }

  getFoodList_FolateTotal(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_folateTotal);
  }


  getFoodList_Iodine(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_iodine);
  }


  getFoodList_Iron(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_iron);
  }


  getFoodList_Magnesium(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_magnesium);
  }


  getFoodList_Manganese(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_manganese);
  }


  getFoodList_Molybdenum(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_molybdenum);
  }


  getFoodList_Niacin(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_niacin);
  }


  getFoodList_Nickel(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_nickel);
  }
  getFoodList_PantothenicAcid(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_pantothenicAcid);
  }

  getFoodList_Phosphorus(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_phosphorus);
  }

  getFoodList_Potassium(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_potassium);
  }

  getFoodList_Riboflavin(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_riboflavin);
  }

  getFoodList_Selenium(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_selenium);
  }


  getFoodList_Sodium(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_sodium);
  }
  getFoodList_Sulfur(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_sulfur);
  }
  getFoodList_Thiamin(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_thiamin);
  }
  getFoodList_VitaminA_RAE(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminA_RAE);
  }
  getFoodList_VitaminAIU(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminAIU);
  }
  getFoodList_VitaminB12Added(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminB12Added);
  }
  getFoodList_VitaminB12(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminB12);
  }
  getFoodList_VitaminB6(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminB6);
  }
  getFoodList_VitaminC(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminC);
  }getFoodList_VitaminD_D2PlusD3IU(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_VitaminD_D2PlusD3IU);
  }getFoodList_VitaminD_D2PlusD3(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminD_D2PlusD3);
  }getFoodList_VitaminD2(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminD2);
  }getFoodList_VitaminD3(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminD3);
  }getFoodList_VitaminD4(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminD4);
  }
  getFoodList_VitaminD(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminD);
  }
  getFoodList_VitaminE(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminE);
  }
  getFoodList_VitaminK(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminK);
  }
  getFoodList_VitaminE_Added(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminE_Added);
  }
  getFoodList_vitaminE_AlphaTocopherol(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminE_AlphaTocopherol);
  }getFoodList_VitaminELabelEntryIU(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminELabelEntryIU);
  }getFoodList_VitaminEMcgRE(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminEMcgRE);
  }getFoodList_VitaminK_Dihydrophylloquinone(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminK_Dihydrophylloquinone);
  }getFoodList_VitaminK_Menaquinone4(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminK_Menaquinone4);
  }getFoodList_vitaminK_Phylloquinone(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_vitaminK_Phylloquinone);
  }getFoodList_zinc(): Observable<FoundationFoods[]> {   
    return this.httpClient.get<FoundationFoods[]>(this.url_zinc);
  }

  submitApplication(firstName: string, lastName: string, email: string) {
   
    // tslint:disable-next-line
    console.log(firstName, lastName, email);
  }
 // async getHousingLocationById(id: number): Promise<HousingLocation | undefined> {
  //  return (await this.getAllHousingLocations_bk()).at(0);
  //}

}