<div class="filter-container">
  <mat-form-field appearance="fill" class="filter-form-field">
    <mat-label>Filter by Category</mat-label>
    <mat-select [(value)]="selectedCategory" (selectionChange)="applyFilter()">
      <mat-option *ngFor="let category of uniqueCategories" [value]="category">
        {{ category }}
      </mat-option>
      <mat-option value="">All</mat-option> <!-- Option to reset the filter -->
    </mat-select>
  </mat-form-field>
</div>

<section class="results">
  <table mat-table [dataSource]="filteredDataSource" class="mat-elevation-z8 custom-table">
    <!-- Index Column -->
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef> Index </th>
      <td mat-cell *matCellDef="let food "> {{food.index}} </td>
    </ng-container>

    <!-- Food Description Column -->
    <ng-container matColumnDef="fooddescription">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let food"> {{food.fooddescription}} </td>
    </ng-container>

    <!-- Foodcategory Column -->
    <ng-container matColumnDef="foodcategory">
      <th mat-header-cell *matHeaderCellDef> Category </th>
      <td mat-cell *matCellDef="let food"> {{food.foodcategory}} </td>
    </ng-container>

    <!-- Serving Size Column -->
    <ng-container matColumnDef="perservingsizeing">
      <th mat-header-cell *matHeaderCellDef> Serving Size </th>
      <td mat-cell *matCellDef="let food"> {{food.perservingsizeing}} </td>
    </ng-container>

    <!-- Amount Column -->
    <ng-container matColumnDef="per100unitservinging">
      <th mat-header-cell *matHeaderCellDef> Amount </th>
      <td mat-cell *matCellDef="let food"> {{food.per100unitservinging}} </td>
    </ng-container>

    <!-- Unit Column -->
    <ng-container matColumnDef="unitname">
      <th mat-header-cell *matHeaderCellDef> Unit </th>
      <td mat-cell *matCellDef="let food"> {{food.unitname}} </td>
    </ng-container>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="toggleAllRows($event)"> Select to Cart </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox [(ngModel)]="element.selected" (change)="onSelectionChange(element)"> </mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

</section>

<button (click)="goHome()" class="responsive-button"> Home </button>
