import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartItem, FoundationFoods, Unit, User } from '../food-data';
import { FoodService } from '../food.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FoodComponent } from '../food/food.component';
import { CommonModule } from '@angular/common';
import { Location } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox'; // <-- Import MatCheckboxModule
import { FormsModule } from '@angular/forms'; // Required for [(ngModel)] two-way binding
import { AuthService } from '../auth/auth.service';


interface FoundationFoodsWithUnit {
  index: number,
  fooddescription: string,
  perservingsizeing: string,
  per100unitservinging: number,
  foodcategory: string,
  unitname: string,
  selected: boolean;
}

@Component({
  selector: 'app-dynamic-page',
  standalone: true,
  imports: [FormsModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatPaginatorModule, MatTableModule, CommonModule, FoodComponent, MatSelectModule],

  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css'],
})
export class DetailsComponent implements OnInit {
  cartCount: number = 0; // Initialize cart count

  value: string;
  pageId: string;
  pageContent: string;
  foodList: FoundationFoods[] = [];
  foodList_Water: FoundationFoods[] = [];
  foodList_Protein: FoundationFoods[] = [];
  foodList_Carb: FoundationFoods[] = [];//include carb by summation and others
  foodList_Fiber: FoundationFoods[] = [];
  foodList_SaturatedFats: FoundationFoods[] = [];

  foodList_MonoUnsaturatedFats: FoundationFoods[] = [];
  foodList_PolyUnsaturatedFats: FoundationFoods[] = [];
  foodList_TransFats: FoundationFoods[] = [];

  foodList_Cholestrol: FoundationFoods[] = [];

  foodList_Biotin: FoundationFoods[] = [];
  foodList_Boron: FoundationFoods[] = [];
  foodList_Calcium: FoundationFoods[] = [];
  foodList_Carotene_alpha: FoundationFoods[] = [];
  foodList_Carotene_beta: FoundationFoods[] = [];
  foodList_Carotene_gamma: FoundationFoods[] = [];
  foodList_Chlorine: FoundationFoods[] = [];


  foodList_cholineFree: FoundationFoods[] = [];
  foodList_cholineFromPhosphocholineMg: FoundationFoods[] = [];
  foodList_cholineFromPhosphotidylCholineMg: FoundationFoods[] = [];
  foodList_cholineFromSphingomyelinMg: FoundationFoods[] = [];
  foodList_cholineTotal: FoundationFoods[] = [];
  foodList_cholineFromGlycerophosphocholineMg: FoundationFoods[] = [];

  foodList_chromiumMugram: FoundationFoods[] = [];
  foodList_copper: FoundationFoods[] = [];
  foodList_fluoride: FoundationFoods[] = [];
  foodList_folateDFE: FoundationFoods[] = [];
  foodList_folateFood: FoundationFoods[] = [];
  foodList_folateTotal: FoundationFoods[] = [];
  foodList_iodine: FoundationFoods[] = [];
  foodList_iron: FoundationFoods[] = [];
  foodList_magnesium: FoundationFoods[] = [];
  foodList_manganese: FoundationFoods[] = [];
  foodList_molybdenum: FoundationFoods[] = [];

  foodList_niacin: FoundationFoods[] = [];
  foodList_nickel: FoundationFoods[] = [];

  foodList_pantothenicAcid: FoundationFoods[] = [];
  foodList_phosphorus: FoundationFoods[] = [];
  foodList_potassium: FoundationFoods[] = [];
  foodList_riboflavin: FoundationFoods[] = [];
  foodList_selenium: FoundationFoods[] = [];
  foodList_sodium: FoundationFoods[] = [];
  foodList_sulfur: FoundationFoods[] = [];
  foodList_thiamin: FoundationFoods[] = [];
  foodList_vitaminA_RAE: FoundationFoods[] = [];
  foodList_vitaminAIU: FoundationFoods[] = [];
  foodList_vitaminB12Added: FoundationFoods[] = [];
  foodList_vitaminB12: FoundationFoods[] = [];
  foodList_vitaminB6: FoundationFoods[] = [];
  foodList_vitaminC: FoundationFoods[] = [];
  foodList_VitaminD_D2PlusD3IU: FoundationFoods[] = [];
  foodList_vitaminD_D2PlusD3: FoundationFoods[] = [];
  foodList_vitaminD2: FoundationFoods[] = [];

  foodList_vitaminD3: FoundationFoods[] = [];

  foodList_vitaminD4: FoundationFoods[] = [];
  foodList_vitaminD: FoundationFoods[] = [];
  foodList_vitaminE: FoundationFoods[] = [];
  foodList_vitaminK: FoundationFoods[] = [];
  foodList_vitaminE_Added: FoundationFoods[] = [];
  foodList_vitaminE_AlphaTocopherol: FoundationFoods[] = [];
  foodList_vitaminELabelEntryIU: FoundationFoods[] = [];
  foodList_vitaminEMcgRE: FoundationFoods[] = [];
  foodList_vitaminK_Dihydrophylloquinone: FoundationFoods[] = [];
  foodList_vitaminK_Menaquinone4: FoundationFoods[] = [];

  foodList_vitaminK_Phylloquinone: FoundationFoods[] = [];
  foodList_zinc: FoundationFoods[] = [];
  filteredFoodList: FoundationFoods[] = [];
  foodsWithUnit: FoundationFoodsWithUnit[] = [];
  unit: Unit = { unitname: "" };
  dataSource = new MatTableDataSource<FoundationFoodsWithUnit>(this.foodsWithUnit);
  filteredDataSource = new MatTableDataSource<FoundationFoodsWithUnit>(this.foodsWithUnit);
  uniqueCategories: string[] = [];
  selectedCategory: string = '';
  displayedColumns: string[] = ['index', 'fooddescription', 'foodcategory', 'perservingsizeing', 'per100unitservinging', 'unitname', 'select'];


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<FoundationFoodsWithUnit>;

  public loggedInUser: any = null;

  constructor(private route: ActivatedRoute, private foodService: FoodService, private location: Location, private router: Router, private authService: AuthService) {
    // Retrieve the passed data from the router's state
    // Access the global variable from the service
  }

  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;


    const authuser: string | null = localStorage.getItem('authUser');  // Example source

    if (authuser !== null) {
      // Use `parsedUserId` here, which is now a `number`
      var id = JSON.parse(authuser).id;
      this.getUserDetails(id);
    }

    this.route.paramMap.subscribe((params) => {
      this.pageId = params.get('id')!;// Access the parameter
      // Load different content based on the pageId
      if (this.pageId && this.pageId.length > 0) {
        this.loadPageContent();
      }
    });
  }

  getUserDetails(userid: number): void {
    this.authService.getUser(userid).subscribe(
      (response) => {
        this.loggedInUser = response;
        console.log('User details:', this.loggedInUser);
      },
      (error) => {
        console.error('Error fetching user details', error);
      }
    );
  }
  goBack() {
    this.location.back(); // Navigate back to the previous route

  }

  goHome() {
    //this.location.back(); // Navigate back to the previous route
    this.router.navigate(['/home']);

  }

  loadPageContent() {
    // You can use a switch case, or make an API call based on the ID
    switch (this.pageId) {
      case 'water':
        this.foodService.getFoodList_Water()
          .subscribe((water: FoundationFoods[]) => {
            if (water && water.length > 0) {
              this.foodList_Water = water;
              this.filterResults(this.pageId);
            }
          });
        break;

      case 'protein':
        this.foodService.getFoodList_Protein()
          .subscribe((protein: FoundationFoods[]) => {
            if (protein && protein.length > 0) {
              this.foodList_Protein = protein;
              this.filterResults(this.pageId);
            }
          }
          );
        break;
      case 'carbohydrate':
        this.foodService.getFoodList_Carb()
          .subscribe((carb: FoundationFoods[]) => {
            if (carb && carb.length > 0) {
              this.foodList_Carb = carb;
              this.filterResults(this.pageId);
            }
          }
          );
        break;
      case 'fiber':
        this.foodService.getFoodList_Fiber()
          .subscribe((fiber: FoundationFoods[]) => {
            if (fiber && fiber.length > 0) {
              this.foodList_Fiber = fiber;
              this.filterResults(this.pageId);
            }
          }
          );
        break;

      case 'fat':
        this.foodService.getFoodList_MonoUnsaturatedFats()
          .subscribe((fats: FoundationFoods[]) => {
            if (fats && fats.length > 0) {
              this.foodList_MonoUnsaturatedFats = fats;
              this.filterResults('monounsaturatedfats');
            }
          }
          );
        break;
      case 'calcium':
        this.foodService.getFoodList_Calcium()
          .subscribe((calcium: FoundationFoods[]) => {
            if (calcium && calcium.length > 0) {
              this.foodList_Calcium = calcium;
              this.filterResults(this.pageId);
            }
          }
          );
        break;

      case 'copper':
        this.foodService.getFoodList_Copper()
          .subscribe((copper: FoundationFoods[]) => {
            if (copper && copper.length > 0) {
              this.foodList_copper = copper;
              this.filterResults(this.pageId);
            }
          }
          );
        break;

      case 'iodine':
        this.foodService.getFoodList_Iodine()
          .subscribe((iodine: FoundationFoods[]) => {
            if (iodine && iodine.length > 0) {
              this.foodList_iodine = iodine;
              this.filterResults(this.pageId);
            }
          }
          );
        break;

      case 'iron':
        this.foodService.getFoodList_Iron()
          .subscribe((iron: FoundationFoods[]) => {
            if (iron && iron.length > 0) {
              this.foodList_iron = iron;
              this.filterResults(this.pageId);
            }
          }
          );
        break;

      case 'magnesium':
        this.foodService.getFoodList_Magnesium()
          .subscribe((magnesium: FoundationFoods[]) => {
            if (magnesium && magnesium.length > 0) {
              this.foodList_magnesium = magnesium;
              this.filterResults(this.pageId);
            }
          }
          );
        break;

      case 'manganese':
        this.foodService.getFoodList_Manganese()
          .subscribe((manganese: FoundationFoods[]) => {
            if (manganese && manganese.length > 0) {
              this.foodList_manganese = manganese;
              this.filterResults(this.pageId);
            }
          }
          );
        break;

      case 'molybdenum':
        this.foodService.getFoodList_Molybdenum()
          .subscribe((molybdenum: FoundationFoods[]) => {
            if (molybdenum && molybdenum.length > 0) {
              this.foodList_molybdenum = molybdenum;
              this.filterResults(this.pageId);
            }
          }
          );
        break;

      case 'phosphorus':
        this.foodService.getFoodList_Phosphorus()
          .subscribe((phosphorus: FoundationFoods[]) => {
            if (phosphorus && phosphorus.length > 0) {
              this.foodList_phosphorus = phosphorus;
              this.filterResults(this.pageId);
            }
          }
          );
        break;

      case 'selenium':
        this.foodService.getFoodList_Selenium()
          .subscribe((selenium: FoundationFoods[]) => {
            if (selenium && selenium.length > 0) {
              this.foodList_selenium = selenium;
              this.filterResults(this.pageId);
            }
          }
          );
        break;

      case 'zinc':
        this.foodService.getFoodList_zinc()
          .subscribe((zinc: FoundationFoods[]) => {
            if (zinc && zinc.length > 0) {
              this.foodList_zinc = zinc;
              this.filterResults(this.pageId);
            }
          });
        break;

      case 'potassium':
        this.foodService.getFoodList_Potassium()
          .subscribe((potassium: FoundationFoods[]) => {
            if (potassium && potassium.length > 0) {
              this.foodList_potassium = potassium;
              this.filterResults(this.pageId);
            }
          });
        break;

      case 'sodium':
        this.foodService.getFoodList_Sodium()
          .subscribe((sodium: FoundationFoods[]) => {
            if (sodium && sodium.length > 0) {
              this.foodList_sodium = sodium;
              this.filterResults(this.pageId);
            }
          });
        break;

      case 'chloride':
        this.foodService.getFoodList_Chlorine()
          .subscribe((chloride: FoundationFoods[]) => {
            if (chloride && chloride.length > 0) {
              this.foodList_Chlorine = chloride;
              this.filterResults('chlorine');
            }
          });
        break;

      case 'vitamina':
        this.foodService.getFoodList_VitaminA_RAE()
          .subscribe((vitamina: FoundationFoods[]) => {
            if (vitamina && vitamina.length > 0) {
              this.foodList_vitaminA_RAE = vitamina;
              this.filterResults('vitamina_rae');
            }
          });
        break;


      case 'vitaminc':
        this.foodService.getFoodList_VitaminC()
          .subscribe((vitaminc: FoundationFoods[]) => {
            if (vitaminc && vitaminc.length > 0) {
              this.foodList_vitaminC = vitaminc;
              this.filterResults('vitaminc');
            }
          });
        break;

        case 'vitamind':
          this.foodService.getFoodList_VitaminD()
            .subscribe((vitamind: FoundationFoods[]) => {
              if (vitamind && vitamind.length > 0) {
                this.foodList_vitaminD = vitamind;
                this.filterResults('vitamind');
              }
            });
          break;  


          case 'vitamine':
            this.foodService.getFoodList_VitaminE()
              .subscribe((vitamine: FoundationFoods[]) => {
                if (vitamine && vitamine.length > 0) {
                  this.foodList_vitaminE = vitamine;
                  this.filterResults('vitamine');
                }
              });
            break;     

            
            case 'vitamink':
              this.foodService.getFoodList_VitaminK()
                .subscribe((vitamink: FoundationFoods[]) => {
                  if (vitamink && vitamink.length > 0) {
                    this.foodList_vitaminK = vitamink;
                    this.filterResults('vitamink');
                  }
                });
              break; 

      case 'thiamin':
        this.foodService.getFoodList_Thiamin()
          .subscribe((thiamin: FoundationFoods[]) => {
            if (thiamin && thiamin.length > 0) {
              this.foodList_thiamin = thiamin;
              this.filterResults(this.pageId);
            }
          });
        break;

      case 'riboflavin':
        this.foodService.getFoodList_Riboflavin()
          .subscribe((riboflavin: FoundationFoods[]) => {
            if (riboflavin && riboflavin.length > 0) {
              this.foodList_riboflavin = riboflavin;
              this.filterResults(this.pageId);
            }
          });
        break;

      case 'niacin':
        this.foodService.getFoodList_Niacin()
          .subscribe((niacin: FoundationFoods[]) => {
            if (niacin && niacin.length > 0) {
              this.foodList_niacin = niacin;
              this.filterResults(this.pageId);
            }
          });
        break;

      case 'vitaminb6':
        this.foodService.getFoodList_VitaminB6()
          .subscribe((vitaminb6: FoundationFoods[]) => {
            if (vitaminb6 && vitaminb6.length > 0) {
              this.foodList_vitaminB6 = vitaminb6;
              this.filterResults(this.pageId);
            }
          });
        break;

      case 'vitaminb12':
        this.foodService.getFoodList_VitaminB12()
          .subscribe((vitaminb12: FoundationFoods[]) => {
            if (vitaminb12 && vitaminb12.length > 0) {
              this.foodList_vitaminB12 = vitaminb12;
              this.filterResults(this.pageId);
            }
          });
        break;

      case 'pantothenicacid':
        this.foodService.getFoodList_PantothenicAcid()
          .subscribe((pantothenicacid: FoundationFoods[]) => {
            if (pantothenicacid && pantothenicacid.length > 0) {
              this.foodList_pantothenicAcid = pantothenicacid;
              this.filterResults(this.pageId);
            }
          });
        break;

      case 'biotin':
        this.foodService.getFoodList_Biotin()
          .subscribe((biotin: FoundationFoods[]) => {
            if (biotin && biotin.length > 0) {
              this.foodList_Biotin = biotin;
              this.filterResults(this.pageId);
            }
          });
        break;


      default:
        this.pageContent = 'Oops! The page you are looking for does not exist.';
        break;
    }
  }

  filterResults(id: string) {
    this.reset();
    if (!id) {
      return;
    }
    if (id.toLowerCase().includes("protein")) {
      this.filteredFoodList = this.foodList_Protein;
      this.unit.unitname = "g";
    }

    if (id.toLowerCase().includes("carbohydrate")) {
      this.filteredFoodList = this.foodList_Carb;
      this.unit.unitname = "g";
    }

    if (id.toLowerCase().includes("fiber")) {
      this.filteredFoodList = this.foodList_Fiber;
      this.unit.unitname = "g";
    }

    if (id.toLowerCase().includes("saturatedfats")) {
      this.filteredFoodList = this.foodList_SaturatedFats;
      this.unit.unitname = "g";
    }


    if (id.toLowerCase().includes("monounsaturatedfats")) {
      this.filteredFoodList = this.foodList_MonoUnsaturatedFats;
      this.unit.unitname = "g";
    }


    if (id.toLowerCase().includes("polyunsaturatedfats")) {
      this.filteredFoodList = this.foodList_PolyUnsaturatedFats;
      this.unit.unitname = "g";
    }


    if (id.toLowerCase().includes("transfats")) {
      this.filteredFoodList = this.foodList_TransFats;
      this.unit.unitname = "g";
    }

    if (id.toLowerCase().includes("cholestrol")) {
      this.filteredFoodList = this.foodList_Cholestrol;
      this.unit.unitname = "mg";
    }
    if (id.toLowerCase().includes("water")) {
      this.filteredFoodList = this.foodList_Water;
      this.unit.unitname = "g";
    }

    if (id.toLowerCase().includes("biotin")) {
      this.filteredFoodList = this.foodList_Biotin;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("boron")) {
      this.filteredFoodList = this.foodList_Boron;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("calcium")) {
      this.filteredFoodList = this.foodList_Calcium;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("carotenealpha")) {
      this.filteredFoodList = this.foodList_Carotene_alpha;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("carotenebeta")) {
      this.filteredFoodList = this.foodList_Carotene_beta;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("carotenegamma")) {
      this.filteredFoodList = this.foodList_Carotene_gamma;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("chlorine")) {
      this.filteredFoodList = this.foodList_Chlorine;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("iron")) {
      this.filteredFoodList = this.foodList_iron;

    }

    if (id.toLowerCase().includes("cholinefree")) {
      this.filteredFoodList = this.foodList_cholineFree;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("cholinefromphosphocholinemg")) {
      this.filteredFoodList = this.foodList_cholineFromPhosphocholineMg;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("cholinefromphosphotidylcholinemg")) {
      this.filteredFoodList = this.foodList_cholineFromPhosphotidylCholineMg;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("cholinefromsphingomyelinmg")) {
      this.filteredFoodList = this.foodList_cholineFromSphingomyelinMg;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("cholinefromglycerophosphocholinemg")) {
      this.filteredFoodList = this.foodList_cholineFromGlycerophosphocholineMg;
      this.unit.unitname = "mg";

    }



    if (id.toLowerCase().includes("cholinetotal")) {
      this.filteredFoodList = this.foodList_cholineTotal;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("chromiumMugram")) {
      this.filteredFoodList = this.foodList_chromiumMugram;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("copper")) {
      this.filteredFoodList = this.foodList_copper;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("fluoride")) {
      this.filteredFoodList = this.foodList_fluoride;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("folatedfe")) {
      this.filteredFoodList = this.foodList_folateDFE;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("folatefood")) {
      this.filteredFoodList = this.foodList_folateFood;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("folatetotal")) {
      this.filteredFoodList = this.foodList_folateTotal;
      this.unit.unitname = "McG";
    }

    if (id.toLowerCase().includes("iodine")) {
      this.filteredFoodList = this.foodList_iodine;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("iron")) {
      this.filteredFoodList = this.foodList_iron;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("magnesium")) {
      this.filteredFoodList = this.foodList_magnesium;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("manganese")) {
      this.filteredFoodList = this.foodList_manganese;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("molybdenum")) {
      this.filteredFoodList = this.foodList_molybdenum;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("niacin")) {
      this.filteredFoodList = this.foodList_niacin;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("nickel")) {
      this.filteredFoodList = this.foodList_nickel;
      this.unit.unitname = "McG";

    }
    if (id.toLowerCase().includes("pantothenicacid")) {
      this.filteredFoodList = this.foodList_pantothenicAcid;
      this.unit.unitname = "mg";
    }
    if (id.toLowerCase().includes("phosphorus")) {
      this.filteredFoodList = this.foodList_phosphorus;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("potassium")) {
      this.filteredFoodList = this.foodList_potassium;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("riboflavin")) {
      this.filteredFoodList = this.foodList_riboflavin;
      this.unit.unitname = "mg";
    }

    if (id.toLowerCase().includes("selenium")) {
      this.filteredFoodList = this.foodList_selenium;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("sodium")) {
      this.filteredFoodList = this.foodList_sodium;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("sulfur")) {
      this.filteredFoodList = this.foodList_sulfur;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("thiamin")) {
      this.filteredFoodList = this.foodList_thiamin;
      this.unit.unitname = "mg";

    }

    if (id.toLowerCase().includes("vitamina_rae")) {
      this.filteredFoodList = this.foodList_vitaminA_RAE;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("vitaminaiu")) {
      this.filteredFoodList = this.foodList_vitaminAIU;
      this.unit.unitname = "IU";

    }
    if (id.toLowerCase().includes("vitaminb12")) {
      this.filteredFoodList = this.foodList_vitaminB12;
      this.unit.unitname = "McG";

    }
    if (id.toLowerCase().includes("vitaminb12added")) {
      this.filteredFoodList = this.foodList_vitaminB12Added;
      this.unit.unitname = "McG";

    }
    if (id.toLowerCase().includes("vitaminb6")) {
      this.filteredFoodList = this.foodList_vitaminB6;
      this.unit.unitname = "mg";

    }
    if (id.toLowerCase().includes("vitaminc")) {
      this.filteredFoodList = this.foodList_vitaminC;
      this.unit.unitname = "mg";

    }
    if (id.toLowerCase().includes("vitamind_d2plusd3")) {
      this.filteredFoodList = this.foodList_vitaminD_D2PlusD3;
      this.unit.unitname = "McG";

    }
    if (id.toLowerCase().includes("vitamind_d2plusd3iu")) {
      this.filteredFoodList = this.foodList_VitaminD_D2PlusD3IU;
      this.unit.unitname = "IU";

    }

    if (id.toLowerCase().includes("vitamind2")) {
      this.filteredFoodList = this.foodList_vitaminD2;
      this.unit.unitname = "McG";

    }
    if (id.toLowerCase().includes("vitamind3")) {
      this.filteredFoodList = this.foodList_vitaminD3;
      this.unit.unitname = "McG";

    }
    if (id.toLowerCase().includes("vitamind4")) {
      this.filteredFoodList = this.foodList_vitaminD4;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("vitamind")) {
      this.filteredFoodList = this.foodList_vitaminD;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("vitamine")) {
      this.filteredFoodList = this.foodList_vitaminE;
      this.unit.unitname = "mg";

    }
    if (id.toLowerCase().includes("vitamink")) {
      this.filteredFoodList = this.foodList_vitaminK;
      this.unit.unitname = "McG";

    }
    /* if (id.toLowerCase().includes("vitamind")) {
      this.filteredFoodList = [
        ...this.foodList_vitaminD_D2PlusD3,
        ...this.foodList_vitaminD2,
        ...this.foodList_vitaminD3,
        ...this.foodList_vitaminD4
      ];
    
      this.unit.unitname = "McG"; // Unified unit for all Vitamin D types
    } */
    
    if (id.toLowerCase().includes("vitamine_added")) {
      this.filteredFoodList = this.foodList_vitaminE_Added;
      this.unit.unitname = "mg";

    }
    if (id.toLowerCase().includes("vitamine_alphatocopherol")) {
      this.filteredFoodList = this.foodList_vitaminE_AlphaTocopherol;
      this.unit.unitname = "mg";

    }
    if (id.toLowerCase().includes("vitaminelabelentryiu")) {
      this.filteredFoodList = this.foodList_vitaminELabelEntryIU;
      this.unit.unitname = "IU";

    }
    if (id.toLowerCase().includes("vitaminemcgre")) {
      this.filteredFoodList = this.foodList_vitaminEMcgRE;
      this.unit.unitname = "McG_RE";

    }

    if (id.toLowerCase().includes("vitaminelabelentryiu")) {
      this.filteredFoodList = this.foodList_vitaminELabelEntryIU;

    }
    if (id.toLowerCase().includes("vitaminemcgre")) {
      this.filteredFoodList = this.foodList_vitaminEMcgRE;

    }
    if (id.toLowerCase().includes("vitamink_dihydrophylloquinone")) {
      this.filteredFoodList = this.foodList_vitaminK_Dihydrophylloquinone;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("vitamink_menaquinone4")) {
      this.filteredFoodList = this.foodList_vitaminK_Menaquinone4;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("vitamink_phylloquinone")) {
      this.filteredFoodList = this.foodList_vitaminK_Phylloquinone;
      this.unit.unitname = "McG";

    }

    if (id.toLowerCase().includes("zinc")) {
      this.filteredFoodList = this.foodList_zinc;
      this.unit.unitname = "mg";

    }

    else {
      console.log("no match");
    }

    this.filteredFoodList.sort((n1, n2) => (n2.per100unitservinging - n1.per100unitservinging));

    this.filteredFoodList.forEach((a, i) => {
      var foodWithUnit: FoundationFoodsWithUnit = {
        index: i + 1,
        fooddescription: a.fooddescription,
        perservingsizeing: a.perservingsizeing,
        per100unitservinging: a.per100unitservinging,
        foodcategory: a.foodcategory,
        unitname: this.unit.unitname,
        selected: false
      };
      this.foodsWithUnit.push(foodWithUnit);
    });

    this.dataSource.data = this.dataSource.data.concat(this.foodsWithUnit);
    this.dataSource.paginator = this.paginator;
    this.getUniqueCategories();
    this.applyFilter(); // Apply initial filter
  }

  getUniqueCategories() {
    const categories = this.dataSource.data
      .map(item => item.foodcategory)
      .filter(item => item !== null);
    this.uniqueCategories = (Array.from(new Set(categories)));
    //.filter(item => item.trim() !== '');
  }

  applyFilter() {
    if (this.selectedCategory) {
      this.filteredDataSource.data = this.dataSource.data.filter(item => item.foodcategory === this.selectedCategory);

    } else {
      this.filteredDataSource.data = this.dataSource.data; // Show all if no category selected
    }
    this.filteredDataSource.paginator = this.paginator;
  }

  reset() {
    this.filteredFoodList = [];
    this.unit = {} as Unit;
    this.foodsWithUnit = [];
    this.dataSource.data = [];
    this.filteredDataSource.data = [];
    this.selectedCategory = '';
  }



  onSelectionChange(element: FoundationFoodsWithUnit) {
    console.log(`${element.fooddescription} selection changed: ${element.fooddescription}`);

    const existingItem = this.loggedInUser.cart.find((item: { name: string; }) => item.name === element.fooddescription);

    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      const cart: CartItem = {
        id: this.loggedInUser.id,
        name: element.fooddescription,
        quantity: 1,
        price: 0,
        category: element.foodcategory
      };
      this.loggedInUser.cart.push(cart);
    }

    // Update the cart in the backend
    this.authService.updateUser(this.loggedInUser).subscribe();
  }

  

  toggleAllRows(event: any) {
    const isChecked = event.checked;
    this.dataSource.data.forEach(row => row.selected = isChecked);
  }
}

