import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { foodList, Foods, FoundationFoods } from './food-data';
import { Observable } from 'rxjs/internal/Observable';
import { __values } from 'tslib';

@Injectable({
  providedIn: 'root',
})

export class FoodNutrientsService {
  constructor(private httpClient: HttpClient) { }
  url_nutrients = 'https://ai-basil.com/v1/foods/nutrients';

  // Get nutrients by food name
  getNutrientsByFoodName(foodName: string): Observable<Foods> {
    const params = new HttpParams().set('foodName', foodName);
    return this.httpClient.get<Foods>(this.url_nutrients, { params });
  }
  getNutrients(foodName: string): Observable<Foods> {   
    const encodedFoodItem = encodeURIComponent(foodName);

    return this.httpClient.get<Foods>(`${this.url_nutrients}/${encodedFoodItem}`);
  }

}