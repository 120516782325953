<!-- landing-page.component.html -->
<!--<div class="landing-page">-->
  <!-- 🔐 Access Features Notice -->
  <!--<div class="access-notice">
    <p>🔒 Sign up or log in to unlock all features!</p>
  </div>

  <div class="hero-section">
    <div class="hero-content">
      <h1 class="hero-title">Create FDA-Compliant Nutrition Labels</h1>
      <p class="hero-description">
        Generate detailed, accurate nutrition labels from your recipes or ingredients with ease, using FDA guidelines.
      </p>
      <button class="cta-button" routerLink="/signup">Create Your Free Account</button>
    </div>
  </div>

  <div class="nutrition-guide">
    <div class="guide-content">
      <div class="guide-header">
        <h2>Know Your Food</h2>
        <p>Explore DRIs, essential food sources, and create FDA-compliant nutrition labels effortlessly.</p>
      </div>

      <div class="feature-grid">
        <div class="feature-item hero-feature">
          <i class="fas fa-clipboard-list icon label-icon"></i>
          <h4>Nutrition Label Creator <span class="beta-tag">(Beta)</span></h4>
          <p> Generate a nutrition label from your recipes or ingredients using FDA guidelines.</p>
        </div>

        <div class="feature-item">
          <i class="fas fa-apple-alt icon apple-icon"></i>
          <h4>Personalized Nutrition (DRIs)</h4>
          <p>Understand your Dietary Reference Intakes tailored to your age, gender, and lifestyle.</p>
        </div>

        <div class="feature-item">
          <i class="fas fa-carrot icon carrot-icon"></i>
          <h4>Essential Nutrient Sources</h4>
          <p>Explore food sources rich in essential nutrients and their health benefits.</p>
        </div>

        <div class="feature-item">
          <i class="fas fa-search icon search-icon"></i>
          <h4>Food Source Finder</h4>
          <p>Discover the nutritional values of different foods using USDA data.</p>
        </div>


      </div>

      <p class="join-message">Begin your journey to better nutrition.</p>
    </div>
  </div>


</div> -->
<div class="landing-page">
  <!-- 🔐 Access Features Notice -->
  <div class="access-notice">
    <p>🔒 Sign up for free or upgrade to premium to unlock advanced features!</p>
  </div>

  <!-- Hero Section -->
  <div class="hero-section">
    <div class="hero-content">
      <h1 class="hero-title">Create FDA-Compliant Nutrition Labels</h1>
      <p class="hero-description">
        Generate detailed, accurate nutrition labels from your recipes or ingredients with ease, using FDA guidelines.
      </p>
    </div>
  </div>
  
  <!-- Free vs. Premium Section -->
  <div class="membership-section">
    <h2>Choose Your Plan</h2>
    <div class="membership-options">
      <!-- Free Plan -->
      <div class="membership-card free-plan">
        <h3>Free</h3>
        <p class="price">$0</p>
        <p>Explore essential nutrition insights.</p>
        <ul>
          <li><span class="checkbox"></span> Essential Nutrient Sources</li>
          <li><span class="checkbox"></span> Food Source Finder</li>
          <li><span class="checkbox"></span> General Nutrition Guides</li>
        </ul>
      </div>
      
      

      <!-- Premium Plan -->
      <div class="membership-card premium-plan">
        <h3>Premium</h3>
        <p class="price">$79/month</p>
        <p>Unlock advanced tools.</p>
        <ul>
          <li><span class="checkbox-free"></span> Everything in Free</li>
          <li><span class="checkbox-plus"></span> Nutrition Label Creator (FDA-Compliant)</li>
          <li><span class="checkbox-plus"></span> Personalized Nutrition (DRIs)</li>
        </ul>
      </div>
      
      
    </div>
    <!-- Common Sign Up Button -->
  <button class="signup-button" routerLink="/signup">Sign Up Free</button>
  </div>



  <!-- Nutrition Guide Section -->
  <div class="nutrition-guide">
    <div class="guide-content">
      <div class="guide-header">
        <h2>Explore Nutrition Insights</h2>
        <p>Discover essential food sources and meet your food business requirements.</p>
      </div>

      <!-- Feature Grid -->
      <div class="feature-grid">
        <!-- Free Features -->
        <div class="feature-item">
          <i class="fas fa-carrot icon carrot-icon"></i>
          <h4>Essential Nutrient Sources</h4>
          <p>Explore food sources rich in essential nutrients.</p>
        </div>

        <div class="feature-item">
          <i class="fas fa-search icon search-icon"></i>
          <h4>Food Source Finder</h4>
          <p>Discover the nutritional values of different foods using USDA data.</p>
        </div>

        <!-- Premium Features (Locked for Free Users) -->
        <div class="feature-item premium-feature">
          <i class="fas fa-clipboard-list icon label-icon"></i>
          <h4>Nutrition Label Creator <span class="beta-tag">(Premium)</span></h4>
          <p> Generate a nutrition label from your recipes using FDA guidelines.</p>
        </div>

        <div class="feature-item premium-feature">
          <i class="fas fa-apple-alt icon apple-icon"></i>
          <h4>Personalized Nutrition (DRIs)</h4>
          <p>Understand your Dietary Reference Intakes tailored to your age and lifestyle.</p>
        </div>
      </div>

     <!-- <p class="join-message">Start with a free plan or upgrade anytime.</p>-->
    </div>

  </div>


  <button class="chat-button" mat-fab matTooltip="Chat with us!" (click)="openChat()">
    <mat-icon>chat</mat-icon>
  </button>
   
</div>
