import {CommonModule} from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule


@Component({
  selector: 'contact-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],  templateUrl: './contact.component.html',  
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent {
  contactForm: FormGroup;
  authService  =  inject(AuthService);
  router  =  inject(Router);
  constructor(private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  onSubmit(): void {
      // Send form data to the server or service
      console.log('Form Submitted', this.contactForm.value);
      
      // Example: call a service to handle form submission
      // this.contactService.sendMessage(this.contactForm.value).subscribe(response => {
      //   console.log('Message sent successfully', response);
      // });
    
    if (this.contactForm.valid) {
      
      this.authService.contact(this.contactForm.value)
        .subscribe({
          next: (data: any) => {
            alert("Feedback sent");
          },
          error: (err) => console.log(err)
        });
    }
    else {
      console.log('Form is invalid');
    }
  }
}

