<header class="header">
  <div class="logo-container">
    <!-- Logo can be an image or text -->
    <a routerLink="/home">
      <img src="assets/AI-Basil-logo.png" alt="Logo" class="logo" />
    </a>
    <div class="tagline">
      <h1>AI-Basil</h1>
      <p>AI Nutrition Assistant</p>
      <!--<p class="sub-tagline">Know Your Food</p>-->
    </div>
  </div>

  <!-- Availability Message
  <div class="availability-message">
    <p>{{ availabilityMessage }}</p>
  </div> -->

  <!-- 
  <div *ngIf="!authService.isLoggedIn()" class="login-container">
    <button class="btn-login" (click)="onLogin()">Login</button>
  </div> -->
  <!-- Show Login button if user is not logged in -->

  <div *ngIf="!authService.isLoggedIn() && !isLoginPage()" class="login-container">
    <button class="btn-login" (click)="onLogin()">
      <i class="fas fa-sign-in-alt"></i> Login
    </button>
  </div>
  

  <!-- Show Upgrade button if user is logged in but not premium -->
  <div *ngIf="authService.isLoggedIn() && !isPremiumUser" class="auth-button">
    <button class="btn-upgrade" (click)="onUpgrade()">
      <i class="fas fa-crown"></i> Upgrade
    </button>
  </div>

  
  <!--  User Icon with Dropdown -->
  <!--<div *ngIf="authService.isLoggedIn() && (!isPremiumUser || isPremiumUser)" class="user-settings" >-->
    <!-- User Icon -->
  <!-- <div class="icon-container">
      <i class="fas fa-user user-icon" (click)="toggleDropdown()"></i>
    </div> -->

  <!-- User Icon with Dropdown & Welcome Message -->
  <div *ngIf="authService.isLoggedIn()" class="user-settings">
    <div class="user-info">
      <span class="welcome-text">Welcome, {{ userName }}!</span>
      <div class="icon-container">
        <i class="fas fa-user user-icon" (click)="toggleDropdown()"></i>
      </div>
    </div>

    <!-- Cart Icon with Badge -->
    <div class="icon-container" *ngIf="isPremiumUser">
      <i class="fas fa-shopping-cart cart-icon" (click)="onGoToCart()"></i>
      <span class="cart-badge">{{ cartItemCount }}</span> <!-- Update this to use the cartItemCount -->
    </div>

    <!-- Dropdown Menu -->
    <div class="dropdown-menu" [class.show]="dropdownOpen">
      <a class="dropdown-item" (click)="onEditProfile()">Edit Profile</a>
      <a class="dropdown-item" (click)="onManagePayment()">Manage Payment</a>
      <a class="dropdown-item" (click)="onDeleteAccount()">Delete Account</a>
      <a class="dropdown-item" (click)="onChangePassword()">Change Password</a>
      <a class="dropdown-item" (click)="onLogout()">Logout</a>
    </div>
  </div>

</header>