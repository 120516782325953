import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../auth/auth.service';



@Component({
  selector: 'app-forgot-password',
  imports: [ReactiveFormsModule],
  standalone: true,
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup;
  message: string = '';
  authService = inject(AuthService);

  constructor(private fb: FormBuilder, private router: Router) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get email() {
    return this.forgotPasswordForm.get('email');
  }

  onSubmit() {
    if (this.forgotPasswordForm.valid) {
      // Simulate an API call to send a password reset email
      const email = this.forgotPasswordForm.value.email;

      // Call your backend service to send password reset link
      this.authService.forgotPassword(email).subscribe(
        {
          next: (data: any) => {
            alert("A reset link has been sent to your email.");
            this.message = 'A reset link has been sent to your email.';
            this.router.navigate(['/login']);
          },
          error: (err) => {
            this.message = 'Failed to send reset link. Please try again.';
          }
        });

      // For now, let's simulate the success response
      // this.authService.signup(this.signupForm.value)
      //   .subscribe({
      //     next: (data: any) => {
      //       alert("Check your email to verify account");
      //       this.router.navigate(['/login']);
      //     },
      //     error: (err) => console.log(err)
      //   });
      //}
    }
  }
}
