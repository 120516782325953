import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../auth/auth.service';


@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})


export class SignupComponent {
  authService  =  inject(AuthService);
  router  =  inject(Router);
  public signupForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    cb: new FormControl(false, [Validators.requiredTrue])
  })
  public onSubmit() {
    if (this.signupForm.valid) {
      
      this.authService.signup(this.signupForm.value)
        .subscribe({
          next: (data: any) => {
            alert("Check your email to verify account");
            this.router.navigate(['/login']);
          },
          error: (err) => console.log(err)
        });
    }
  }  
}
