import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';



export const authGuard: CanActivateFn = (route, state) => {
  const  authService  =  inject(AuthService);
   const  router  =  inject(Router);

   if (authService.isLoggedIn()) {
    return true;
  }
  router.navigate(['/login']);
  return false;

};



/* @Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([this.authService.isLoggedIn(), this.authService.isAuthenticated()]).pipe(map(([auth, id]) => auth === true && id !== null))

  }
} */

