import { Component, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../auth/auth.service';


@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [ CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})

export class LandingComponent {

  authService = inject(AuthService);
  router = inject(Router);

  myForm: FormGroup;
  showPassword = false; // Default is to hide the password

  constructor(private fb: FormBuilder) {
    this.myForm = this.fb.group({
      password: [''] // Initialize password field
    });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword; // Toggle password visibility
  }
  

  protected loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  })

  onForgotPassword() {
    // You can navigate to a "Forgot Password" page
    this.router.navigate(['/forgot-password']);
  }

  onSubmit(){
    if(this.loginForm.valid){
      this.authService.login(this.loginForm.value)
      .subscribe((data: any) => {
        if(data.enabled){        
        if(this.authService.isLoggedIn()){
          this.router.navigate(['/home']);
        }
      }
        else{
          alert('Account not verified');
        }
      });
    }
    else{
      alert('Invalid user');
    }
  }

}


