import { Component, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoodComponent } from '../food/food.component';
import { Foods, FoundationFoods, FoundationFoodsWithUnit } from '../food-data';
import { Unit } from '../food-data'
import { Observable } from 'rxjs/internal/Observable';
import { FoodService } from '../food.service';
import { catchError, forkJoin, map, of } from 'rxjs';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { AnnouncementsComponent } from '../announcements/announcements.component';
import { NuggetsComponent } from '../nuggets/nuggets.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AuthService } from '../auth/auth.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms'; // <-- Import this
import { ChangeDetectorRef } from '@angular/core';
import { SearchFormComponent } from '../search-form/search-form.component';
import { BlogComponent } from '../blog/blog.component';
import { FoodNutrientsService } from '../foodnutrients.service';
import { ChartOptions } from 'chart.js';
import { ChartData } from 'chart.js';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MatListModule, MatIconModule, BlogComponent, FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatPaginatorModule, MatTableModule, MatTabsModule, CommonModule, FoodComponent, RouterOutlet, HomeComponent, RouterLink, HeaderComponent, FooterComponent, AnnouncementsComponent, NuggetsComponent, MatSelectModule, SearchFormComponent],

  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {

  foodName: string = '';
  newIngredient: string = '';
  newAmount: number | null = null;
  numberOfServings: number = 0;  // Initialize with a default value or null
  ingredients: { ingredient: string, amount: number | null }[] = [];
  displayedColumns_nutrition: string[] = ['ingredient', 'amount'];
  nutritionLabel: {
    nutrientName: string;
    nutrientAmount: string;
    unit: string;
    isSubNutrient?: boolean;
    isFattyAcid?: boolean;  // Add the isFattyAcid flag here
    percentage?: string;  // Add the percentage property here
    note?: string; // Add this property
    isSubSubNutrient?: boolean
  }[] = [];
  showFDA: boolean = false; // New flag for FDA label visibility
  showMatTable: boolean = false; // For mat-table display
  showFDA_VolNut: boolean = false;
  totalFat: number = 0; // Example value
  fatUnit: string = 'g'; // Example unit
  saturatedFat: number = 0;
  addedSugarsDV: number = 50;
  totalSugarsPercentage: string;
  polyUnsaturatedFat: number = 0;
  monoUnsaturatedFat: number = 0;
  fluoride: number = 0;
  solubleFiber: number | string;
  insolubleFiber: number | string;
  vitaminA: number =0;
  vitaminC: number =0;
  thiamin: number =0; 
  riboflavin: number =0;
  niacin: number =0;
  vitaminB6: number =0;
  folate: number =0;
  vitaminB12: number =0;
  phosphorus: number =0;
  magnesium: number =0;
  zinc: number =0;
  choline: number =0;
  transFat: number = 0;
  cholesterol: string = '0 g'; // Example values, replace as needed
  sodium: number = 0;
  totalCarbohydrate: string = '0';
  dietaryFiber: string = '0';
  totalSugars: number = 0;
  addedSugars: number = 0;
  addedSugarsPercentage: string = '0';
  protein: string = '0';
  vitaminD: number = 0;
  calcium: number = 0;
  iron: number = 0;
  potassium: number = 0;
  totalCalories = 0; // Calculate this based on data
  totalFatDV: number = 78;  // Example Daily Value percentage
  saturatedFatDV: number = 25;
  cholesterolDV: number = 300;
  sodiumDV: number = 2300;
  totalCarbohydrateDV: number = 275;
  dietaryFiberDV: number = 28;
  proteinDV: number = 50;
  vitaminDDV: number = 20;
  calciumDV: number = 1300;
  ironDV: number = 18;
  potassiumDV: number = 4700;
  
  biotinDV = 30;             // mcg
  chlorideDV = 2300;         // mg
  cholineDV = 550;           // mg
  chromiumDV = 35;           // mcg
  copperDV = 0.9;            // mg
  fatDV = 78;                // g
  folateFolicAcidDV = 400;   // mcg DFE
  iodineDV = 150;            // mcg
  magnesiumDV = 420;         // mg
  manganeseDV = 2.3;         // mg
  molybdenumDV = 45;         // mcg
  niacinDV = 16;             // mg NE
  pantothenicAcidDV = 5;     // mg
  phosphorusDV = 1250;       // mg
  riboflavinDV = 1.3;        // mg
  seleniumDV = 55;           // mcg
  thiaminDV = 1.2;           // mg
  vitaminADV = 900;          // mcg RAE
  vitaminB6DV = 1.7;         // mg
  vitaminB12DV = 2.4;        // mcg
  vitaminCDV = 90;           // mg
  vitaminEDV = 15;           // mg alpha-tocopherol
  vitaminKDV = 120;          // mcg
  zincDV = 11;               // mg

  totalFatPercentage: string;
  saturatedFatPercentage: string;
  cholesterolPercentage: string;
  sodiumPercentage: string;
  totalCarbohydratePercentage: string;
  dietaryFiberPercentage: string;
  proteinPercentage: string;
  vitaminDPercentage: string;
  calciumPercentage: string;
  ironPercentage: string;
  potassiumPercentage: string;
  servingSize: number;
  totalCarbohydrateRaw: number;
  dietaryFiberRaw: number;
  proteinRaw: number;
  vitaminDNote: string="";
  calciumNote: string="";
  ironNote: string="";
  potassiumNote: string="";
  generalLowDVNote: string = "";
  sodiumFormatted: string;
  potassiumFormatted: string;


  inputValue: string = '';
  displayedColumns: string[] = ['index', 'fooddescription', 'foodcategory', 'perservingsizeing', 'per100unitservinging', 'unitname'];
  filteredFoodList: FoundationFoods[] = [];
  foodsWithUnit: FoundationFoodsWithUnit[] = [];
  dataSource = new MatTableDataSource<FoundationFoodsWithUnit>(this.foodsWithUnit);
  filteredDataSource = new MatTableDataSource<FoundationFoodsWithUnit>(this.foodsWithUnit);
  uniqueCategories: string[] = [];
  filteredCategories: string[] = []; // Will store available categories based on selected diet
  selectedCategory: string = '';

  selectedDiet: string = ''; // New variable to store selected diet filter

  veganExcludedCategories: string[] = ['Dairy and Egg Products', 'Beef Products', 'Poultry Products', 'Sausages and Luncheon Meats', 'Pork Products', 'Finfish and Shellfish Products', 'Restaurant Foods'];
  vegetarianExcludedCategories: string[] = ['Beef Products', 'Poultry Products', 'Sausages and Luncheon Meats', 'Pork Products', 'Finfish and Shellfish Products', 'Restaurant Foods'];
  mediterraneanExcludedCategories: string[] = ['Beef Products'];
  foodList_Protein: FoundationFoods[] = [];
  foodList_Carb: FoundationFoods[] = [];//include carb by summation and others
  foodList_Fiber: FoundationFoods[] = [];
  foodList_SaturatedFats: FoundationFoods[] = [];

  foodList_MonoUnsaturatedFats: FoundationFoods[] = [];
  foodList_PolyUnsaturatedFats: FoundationFoods[] = [];
  foodList_TransFats: FoundationFoods[] = [];

  foodList_Cholestrol: FoundationFoods[] = [];
  foodList_Water: FoundationFoods[] = [];

  foodList_Biotin: FoundationFoods[] = [];
  foodList_Boron: FoundationFoods[] = [];
  foodList_Calcium: FoundationFoods[] = [];
  foodList_Carotene_alpha: FoundationFoods[] = [];
  foodList_Carotene_beta: FoundationFoods[] = [];
  foodList_Carotene_gamma: FoundationFoods[] = [];
  foodList_Chlorine: FoundationFoods[] = [];


  foodList_cholineFree: FoundationFoods[] = [];
  foodList_cholineFromPhosphocholineMg: FoundationFoods[] = [];
  foodList_cholineFromPhosphotidylCholineMg: FoundationFoods[] = [];
  foodList_cholineFromSphingomyelinMg: FoundationFoods[] = [];
  foodList_cholineTotal: FoundationFoods[] = [];
  foodList_cholineFromGlycerophosphocholineMg: FoundationFoods[] = [];

  foodList_chromiumMugram: FoundationFoods[] = [];
  foodList_copper: FoundationFoods[] = [];
  foodList_fluoride: FoundationFoods[] = [];
  foodList_folateDFE: FoundationFoods[] = [];
  foodList_folateFood: FoundationFoods[] = [];
  foodList_folateTotal: FoundationFoods[] = [];
  foodList_iodine: FoundationFoods[] = [];
  foodList_iron: FoundationFoods[] = [];
  foodList_magnesium: FoundationFoods[] = [];
  foodList_manganese: FoundationFoods[] = [];
  foodList_molybdenum: FoundationFoods[] = [];

  foodList_niacin: FoundationFoods[] = [];
  foodList_nickel: FoundationFoods[] = [];

  foodList_pantothenicAcid: FoundationFoods[] = [];
  foodList_phosphorus: FoundationFoods[] = [];
  foodList_potassium: FoundationFoods[] = [];
  foodList_riboflavin: FoundationFoods[] = [];
  foodList_selenium: FoundationFoods[] = [];
  foodList_sodium: FoundationFoods[] = [];
  foodList_sulfur: FoundationFoods[] = [];
  foodList_thiamin: FoundationFoods[] = [];
  foodList_vitaminA_RAE: FoundationFoods[] = [];
  foodList_vitaminAIU: FoundationFoods[] = [];
  foodList_vitaminB12Added: FoundationFoods[] = [];
  foodList_vitaminB12: FoundationFoods[] = [];
  foodList_vitaminB6: FoundationFoods[] = [];
  foodList_vitaminC: FoundationFoods[] = [];
  foodList_VitaminD_D2PlusD3IU: FoundationFoods[] = [];
  foodList_vitaminD_D2PlusD3: FoundationFoods[] = [];
  foodList_vitaminD2: FoundationFoods[] = [];

  foodList_vitaminD3: FoundationFoods[] = [];

  foodList_vitaminD4: FoundationFoods[] = [];
  foodList_vitaminE_Added: FoundationFoods[] = [];
  foodList_vitaminE_AlphaTocopherol: FoundationFoods[] = [];
  foodList_vitaminELabelEntryIU: FoundationFoods[] = [];
  foodList_vitaminEMcgRE: FoundationFoods[] = [];
  foodList_vitaminK_Dihydrophylloquinone: FoundationFoods[] = [];
  foodList_vitaminK_Menaquinone4: FoundationFoods[] = [];

  foodList_vitaminK_Phylloquinone: FoundationFoods[] = [];
  foodList_zinc: FoundationFoods[] = [];
  unit: Unit = { unitname: "" };


  foodData!: Foods | null;
  nutrientData: Foods | null = {
    foodId: 0,
    foodName: '',
    foodCategory: { categoryName: '' },
    nutrients: []
  };
  displayedColumns1: string[] = ['nutrientName', 'nutrientAmount', 'unit', 'portionSize', 'portionSizeUnit'];
  // Updated food list

  //chartData: ChartData<'bar'>;
  chartData: ChartData<'bar'>;
  chartOptions: ChartOptions = {
    responsive: true,
  };
  
  foods: any;
 
  allFoods: string[] = [
    'Beans, snap, green, canned, regular pack, drained solids',
    'Beans, snap, green, raw',
    'Beets, raw',
    'Broccoli, raw',
    'Brussels sprouts, raw',
    'Cabbage, bok choy, raw',
    'Cabbage, green, raw',
    'Cabbage, red, raw',
    'Carrots, baby, raw',
    'Carrots, frozen, unprepared',
    'Carrots, mature, raw',
    'Cauliflower, raw',
    'Celery, raw',
    'Collards, raw',
    'Cucumber, with peel, raw',
    'Eggplant, raw',
    'Flour, cassava',
    'Flour, potato',
    'Garlic, raw',
    'Kale, frozen, cooked, boiled, drained, without salt',
    'Kale, raw',
    'Ketchup, restaurant',
    'Lettuce, cos or romaine, raw',
    'Lettuce, iceberg, raw',
    'Lettuce, leaf, green, raw',
    'Lettuce, leaf, red, raw',
    'Lettuce, romaine, green, raw',
    'Mushroom, beech',
    'Mushroom, crimini',
    'Mushroom, enoki',
    'Mushroom, king oyster',
    'Mushroom, lion\'s mane',
    'Mushroom, maitake',
    'Mushroom, oyster',
    'Mushroom, pioppini',
    'Mushroom, portabella',
    'Mushrooms, shiitake',
    'Mushrooms, white button',
    'Onion rings, breaded, par fried, frozen, prepared, heated in oven',
    'Onions, red, raw',
    'Onions, white, raw',
    'Onions, yellow, raw',
    'Peas, green, sweet, canned, sodium added, sugar added, drained and rinsed',
    'Peppers, bell, green, raw',
    'Peppers, bell, orange, raw',
    'Peppers, bell, red, raw',
    'Peppers, bell, yellow, raw',
    'Pickles, cucumber, dill or kosher dill',
    'Potatoes, gold, without skin, raw',
    'Potatoes, red, without skin, raw',
    'Potatoes, russet, without skin, raw',
    'Spinach, baby',
    'Spinach, mature',
    'Squash, summer, green, zucchini, includes skin, raw',
    'Squash, summer, yellow, includes skin, raw',
    'Squash, winter, acorn, raw',
    'Squash, winter, butternut, raw',
    'Sweet potatoes, orange flesh, without skin, raw',
    'Tomato juice, with added ingredients, from concentrate, shelf stable',
    'Tomato, paste, canned, without salt added',
    'Tomato, puree, canned',
    'Tomato, roma',
    'Tomato, sauce, canned, with salt added',
    'Tomatoes, canned, red, ripe, diced',
    'Tomatoes, crushed, canned',
    'Tomatoes, whole, canned, solids and liquids, with salt added',
    'Tomatoes, grape, raw',
    'Sugars, granulated',
    'Mustard, prepared, yellow',
    'Salt, table, iodized',
    'Sauce, salsa, ready-to-serve',
    'Sauce, pasta, spaghetti%2Fmarinara, ready-to-serve',
    'Sausage, turkey, breakfast links, mild, raw',
    'Sausage, Italian, pork, mild, cooked, pan-fried',
    'Sausage, pork, chorizo, link or ground, cooked, pan-fried',
    'Ham, sliced, pre-packaged, deli meat (96%fat free, water added)',
    'Frankfurter, beef, unheated',
    'Chicken, broiler or fryers, breast, skinless, boneless, meat only, cooked, braised',
    'Chicken, broilers or fryers, drumstick, meat only, cooked, braised',
    'Chicken, ground, with additives, raw',
    'Chicken, thigh, boneless, skinless, raw',
    'Turkey, ground, 93% lean, 7% fat, pan-broiled crumbles',
    'Turkey, ground, 93% lean%2F7% fat, raw',//fix
    'Ham, sliced, restaurant',
    'Pork, cured, bacon, cooked, restaurant',
    'Pork, ground, raw',
    'Pork, loin, boneless, raw',
    'Pork, loin, tenderloin, boneless, raw',
    'Almond butter, creamy',
    'Flaxseed, ground',
    'Flour, almond',
    'Flour, chestnut',
    'Flour, coconut',
    'Nuts, almonds, dry roasted, with salt added',
    'Nuts, almonds, whole, raw',
    'Nuts, brazil nuts, raw',
    'Nuts, cashew nuts, raw',
    'Nuts, hazelnuts or filberts, raw',
    'Nuts, macadamia nuts, raw',
    'Nuts, pecans, halves, raw',
    'Nuts, pine nuts, raw',
    'Nuts, pistachio nuts, raw',
    'Nuts, walnuts, English, halves, raw',
    'Seeds, pumpkin seeds (pepitas), raw',
    'Seeds, sunflower seed kernels, dry roasted, with salt added',
    'Seeds, sunflower seed, kernel, raw',
    'Sesame butter, creamy',
    'Beans, black, canned, sodium added, drained and rinsed',
    'Beans, cannellini, canned, sodium added, drained and rinsed',
    'Beans, cannellini, dry',
    'Beans, Dry, Black (0% moisture)',
    'Beans, Dry, Brown (0% moisture)',
    'Beans, Dry, Carioca (0% moisture)',
    'Beans, Dry, Cranberry (0% moisture)',
    'Beans, Dry, Dark Red Kidney (0% moisture)',
    'Beans, Dry, Flor de Mayo (0% moisture)',
    'Beans, Dry, Great Northern (0% moisture)',
    'Beans, Dry, Light Red Kidney (0% moisture)',
    'Beans, Dry, Light Tan (0% moisture)',
    'Beans, Dry, Medium Red (0% moisture)',
    'Beans, Dry, Navy (0% moisture)',
    'Beans, Dry, Pink (0% moisture)',
    'Beans, Dry, Pinto (0% moisture)',
    'Beans, Dry, Red (0% moisture)',
    'Beans, Dry, Small Red (0% moisture)',
    'Beans, Dry, Small White (0% moisture)',
    'Beans, Dry, Tan (0% moisture)',
    'Beans, great northern, canned, sodium added, drained and rinsed',
    'Beans, kidney, dark red, canned, sodium added, sugar added, drained and rinsed',
    'Beans, kidney, light red, canned, sodium added, sugar added, drained and rinsed',
    'Beans, navy, canned, sodium added, drained and rinsed',
    'Beans, pinto, canned, sodium added, drained and rinsed',
    'Blackeye pea, canned, sodium added, drained and rinsed',
    'Blackeye pea, dry',
    'Chickpeas (garbanzo beans, bengal gram), canned, sodium added, drained and rinsed',
    'Chickpeas, (garbanzo beans, bengal gram), dry',
    'Flour, soy, defatted',
    'Flour, soy, full-fat',
    'Hummus, commercial',
    'Lentils, dry',
    'Peanut butter, creamy',
    'Peanuts, raw',
    'Soy milk, sweetened, plain, refrigerated',
    'Soy milk, sweetened, plain, refrigerated',
    'Soy milk, unsweetened, plain, shelf stable',
    'Apples, fuji, with skin, raw',
    'Apples, gala, with skin, raw',
    'Apples, granny smith, with skin, raw',
    'Apples, honeycrisp, with skin, raw',
    'Apples, red delicious, with skin, raw',
    'Applesauce, unsweetened, with added vitamin C',
    'Bananas, overripe, raw',
    'Bananas, ripe and slightly ripe, raw',
    'Blueberries, raw',
    'Cherries, sweet, dark red, raw',
    // 'Cranberry juice, not fortified, from concentrate, shelf stable',
    'Figs, dried, uncooked',
    'Grape juice, purple, with added vitamin C, from concentrate, shelf stable',
    'Grape juice, white, with added vitamin C, from concentrate, shelf stable',
    'Grapefruit juice, red, not fortified, not from concentrate, refrigerated',
    'Grapefruit juice, white, canned or bottled, unsweetened',
    'Grapes, green, seedless, raw',
    'Grapes, red, seedless, raw',
    'Kiwifruit, green, raw',
    'Melons, cantaloupe, raw',
    'Nectarines, raw',
    'Olives, green, Manzanilla, stuffed with pimiento',
    'Orange juice, no pulp, not fortified, from concentrate, refrigerated',
    'Orange juice, no pulp, not fortified, not from concentrate, refrigerated',
    'Oranges, raw, navels',
    'Peaches, yellow, raw',
    'Pears, raw, bartlett',
    'Pineapple, raw',
    'Raspberries, raw',
    'Strawberries, raw',
    'Crustaceans, crab, blue swimming, lump, pasteurized, refrigerated',
    'Crustaceans, shrimp, farm raised, raw',
    'Fish, catfish, farm raised, raw',
    'Fish, cod, Atlantic, wild caught, raw',
    'Fish, haddock, raw',
    'Fish, pollock, raw',
    'Fish, salmon, Atlantic, farm raised, raw',
    'Fish, salmon, sockeye, wild caught, raw',
    'Fish, tilapia, farm raised, raw',
    'Fish, tuna, light, canned in water, drained solids',
    'Oil, canola',
    'Oil, coconut',
    'Oil, corn',
    'Oil, olive, extra light',
    'Oil, olive, extra virgin',
    'Oil, peanut',
    'Oil, safflower',
    'Oil, soybean',
    'Oil, sunflower',
    'Butter, stick, salted',
    'Butter, stick, unsalted',
    'Buttermilk, low fat',
    'Cheese, American, restaurant',
    'Cheese, cheddar',
    'Cheese, cotija, solid',
    'Cheese, cottage, lowfat, 2% milkfat',
    'Cheese, dry white, queso seco',
    'Cheese, feta, whole milk, crumbled',
    'Cheese, monterey jack, solid',
    'Cheese, mozzarella, low moisture, part-skim',
    'Cheese, oaxaca, solid',
    'Cheese, parmesan, grated',
    'Cheese, parmesan, grated, refrigerated',
    'Cheese, pasteurized process cheese food or product, American, singles',
    'Cheese, pasteurized process, American, vitamin D fortified',
    'Cheese, provolone, sliced',
    'Cheese, queso fresco, solid',
    'Cheese, ricotta, whole milk',
    'Cheese, swiss',
    'Cottage cheese, full fat, large or small curd',
    'Cream cheese, full fat, block',
    'Cream, heavy',
    'Cream, sour, full fat',
    'Egg, white, dried',
    'Egg, white, raw, frozen, pasteurized',
    'Egg, whole, dried',
    'Egg, whole, raw, frozen, pasteurized',
    'Egg, yolk, dried',
    'Egg, yolk, raw, frozen, pasteurized',
    'Eggs, Grade A, Large, egg white',
    'Eggs, Grade A, Large, egg whole',
    'Eggs, Grade A, Large, egg yolk',
    'Milk, lowfat, fluid, 1% milkfat, with added vitamin A and vitamin D',
    'Milk, nonfat, fluid, with added vitamin A and vitamin D (fat free or skim)',
    'Milk, reduced fat, fluid, 2% milkfat, with added vitamin A and vitamin D',
    'Milk, whole, 3.25% milkfat, with added vitamin D',
    'Yogurt, Greek, plain, nonfat',
    'Yogurt, Greek, plain, whole milk',
    'Yogurt, Greek, strawberry, nonfat',
    'Yogurt, plain, nonfat',
    'Yogurt, plain, whole milk',
    'Buckwheat, whole grain',
    'Flour, 00',
    'Flour, amaranth',
    'Flour, barley',
    'Flour, bread, white, enriched, unbleached',
    'Flour, buckwheat',
    'Flour, corn, yellow, fine meal, enriched',
    'Flour, oat, whole grain',
    'Flour, pastry, unenriched, unbleached',
    'Flour, quinoa',
    'Flour, rice, brown',
    'Flour, rice, glutinous',
    'Flour, rice, white, unenriched',
    'Flour, rye',
    'Flour, semolina, coarse and semi-coarse',
    'Flour, semolina, fine',
    'Flour, sorghum',
    'Flour, spelt, whole grain',
    'Flour, wheat, all-purpose, enriched, bleached',
    'Flour, wheat, all-purpose, enriched, unbleached',
    'Flour, wheat, all-purpose, unenriched, unbleached',
    'Flour, whole wheat, unenriched',
    'Millet, whole grain',
    'Oats, whole grain, rolled, old fashioned',
    'Oats, whole grain, steel cut',
    'Rice, brown, long grain, unenriched, raw',
    'Rice, white, long grain, unenriched, raw',
    'Almond milk, unsweetened, plain, refrigerated',
    'Almond milk, unsweetened, plain, shelf stable',
    'Oat milk, unsweetened, plain, refrigerated',
    'Beef, chuck, roast, boneless, choice, raw',
    'Beef, flank, steak, boneless, choice, raw',
    'Beef, ground, 90% lean meat / 10% fat, raw',
    'Beef, ground, 80% lean meat / 20% fat, raw',
    'Beef, loin, tenderloin roast, separable lean only, boneless, trimmed to 0" fat, select, cooked, roasted',
    'Beef, loin, top loin steak, boneless, lip-on, separable lean only, trimmed to 1/8" fat, choice, raw',
    'Beef, ribeye, steak, boneless, choice, raw',
    'Beef, round, eye of round roast, boneless, separable lean only, trimmed to 0" fat, select, raw',
    'Beef, round, top round roast, boneless, separable lean only, trimmed to 0" fat, select, raw',
    'Beef, round, top round, boneless, choice, raw',
    'Beef, short loin, porterhouse steak, separable lean only, trimmed to 1/8" fat, select, raw',
    'Beef, short loin, t-bone steak, bone-in, separable lean only, trimmed to 1/8" fat, choice, cooked, grilled',
    'Bread, white, commercially prepared',
    'Bread, whole-wheat, commercially prepared',
    'Cookies, oatmeal, soft, with raisins',
    "Abiyuch, raw",
    "Acerola juice, raw",
    "Acerola, (west indian cherry), raw",
    "Acorn stew (Apache)",
    "Agave, cooked (Southwest)",
    "Agave, dried (Southwest)",
    "Agave, raw (Southwest)",
    "Agutuk, fish with shortening (Alaskan ice cream) (Alaska Native)",
    "Agutuk, fish/berry with seal oil (Alaskan ice cream) (Alaska Native)",
    "Agutuk, meat-caribou (Alaskan ice cream) (Alaska Native)",
    "Alcoholic beverage, beer, light",
    "Alcoholic beverage, beer, light, BUD LIGHT",
    "Alcoholic beverage, beer, light, BUDWEISER SELECT",
    "Alcoholic beverage, beer, light, higher alcohol",
    "Alcoholic beverage, beer, light, low carb",
    "Alcoholic beverage, beer, regular, all",
    "Alcoholic beverage, beer, regular, BUDWEISER",
    "Alcoholic beverage, creme de menthe, 72 proof",
    "Alcoholic beverage, daiquiri, canned",
    "Alcoholic beverage, daiquiri, prepared-from-recipe",
    "Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 100 proof",
    "Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 80 proof",
    "Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 86 proof",
    "Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 90 proof",
    "Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 94 proof",
    "Alcoholic beverage, distilled, rum, 80 proof",
    "Alcoholic beverage, distilled, vodka, 80 proof",
    "Alcoholic beverage, distilled, whiskey, 86 proof",
    "Alcoholic beverage, liqueur, coffee with cream, 34 proof",
    "Alcoholic beverage, liqueur, coffee, 53 proof",
    "Alcoholic beverage, liqueur, coffee, 63 proof",
    "Alcoholic beverage, malt beer, hard lemonade",
    "Alcoholic beverage, pina colada, canned",
    "Alcoholic beverage, pina colada, prepared-from-recipe",
    "Alcoholic beverage, rice (sake)",
    "Alcoholic beverage, tequila sunrise, canned",
    "Alcoholic beverage, whiskey sour",
    "Alcoholic beverage, whiskey sour, canned",
    "Alcoholic beverage, whiskey sour, prepared from item 14028",
    "Alcoholic beverage, whiskey sour, prepared with water, whiskey and powder mix",
    "Alcoholic beverage, wine, cooking",
    "Alcoholic beverage, wine, dessert, dry",
    "Alcoholic beverage, wine, dessert, sweet",
    "Alcoholic beverage, wine, light",
    "Alcoholic beverage, wine, table, all",
    "Alcoholic beverage, wine, table, red",
    "Alcoholic Beverage, wine, table, red, Barbera",
    "Alcoholic Beverage, wine, table, red, Burgundy",
    "Alcoholic Beverage, wine, table, red, Cabernet Franc",
    "Alcoholic Beverage, wine, table, red, Cabernet Sauvignon",
    "Alcoholic Beverage, wine, table, red, Carignane",
    "Alcoholic Beverage, wine, table, red, Claret",
    "Alcoholic Beverage, wine, table, red, Gamay",
    "Alcoholic Beverage, wine, table, red, Lemberger",
    "Alcoholic Beverage, wine, table, red, Merlot",
    "Alcoholic Beverage, wine, table, red, Mouvedre",
    "Alcoholic Beverage, wine, table, red, Petite Sirah",
    "Alcoholic Beverage, wine, table, red, Pinot Noir",
    "Alcoholic Beverage, wine, table, red, Sangiovese",
    "Alcoholic Beverage, wine, table, red, Syrah",
    "Alcoholic Beverage, wine, table, red, Zinfandel",
    "Alcoholic beverage, wine, table, white",
    "Alcoholic beverage, wine, table, white, Chardonnay",
    "Alcoholic beverage, wine, table, white, Chenin Blanc",
    "Alcoholic beverage, wine, table, white, Fume Blanc",
    "Alcoholic beverage, wine, table, white, Gewurztraminer",
    "Alcoholic beverage, wine, table, white, late harvest",
    "Alcoholic beverage, wine, table, white, late harvest, Gewurztraminer",
    "Alcoholic beverage, wine, table, white, Muller Thurgau",
    "Alcoholic beverage, wine, table, white, Muscat",
    "Alcoholic beverage, wine, table, white, Pinot Blanc",
    "Alcoholic beverage, wine, table, white, Pinot Gris (Grigio)",
    "Alcoholic beverage, wine, table, white, Riesling",
    "Alcoholic beverage, wine, table, white, Sauvignon Blanc",
    "Alcoholic beverage, wine, table, white, Semillon",
    "Alcoholic beverages, beer, higher alcohol",
    "Alcoholic beverages, wine, rose",
    "Alfalfa seeds, sprouted, raw",
    "Amaranth grain, cooked",
    "Amaranth grain, uncooked",
    "Amaranth leaves, cooked, boiled, drained, with salt",
    "Amaranth leaves, cooked, boiled, drained, without salt",
    "Amaranth leaves, raw",
    "Andrea's, Gluten Free Soft Dinner Roll",
    "Animal fat, bacon grease",
    "Apple juice, canned or bottled, unsweetened, with added ascorbic acid",
    "Apple juice, canned or bottled, unsweetened, with added ascorbic acid, calcium, and potassium",
    "Apple juice, canned or bottled, unsweetened, without added ascorbic acid",
    "Apple juice, frozen concentrate, unsweetened, diluted with 3 volume water without added ascorbic acid",
    "Apple juice, frozen concentrate, unsweetened, diluted with 3 volume water, with added ascorbic acid",
    "Apple juice, frozen concentrate, unsweetened, undiluted, with added ascorbic acid",
    "Apple juice, frozen concentrate, unsweetened, undiluted, without added ascorbic acid",
    "APPLEBEE'S, 9 oz house sirloin steak",
    "APPLEBEE'S, chicken tenders platter",
    "APPLEBEE'S, chicken tenders, from kids' menu",
    "APPLEBEE'S, crunchy onion rings",
    "APPLEBEE'S, Double Crunch Shrimp",
    "APPLEBEE'S, fish, hand battered",
    "APPLEBEE'S, french fries",
    "APPLEBEE'S, KRAFT, Macaroni & Cheese, from kid's menu",
    "APPLEBEE'S, mozzarella sticks",
    "Apples, canned, sweetened, sliced, drained, heated",
    "Apples, dehydrated (low moisture), sulfured, stewed",
    "Apples, dehydrated (low moisture), sulfured, uncooked",
    "Apples, dried, sulfured, stewed, with added sugar",
    "Apples, dried, sulfured, stewed, without added sugar",
    "Apples, dried, sulfured, uncooked",
    "Apples, frozen, unsweetened, heated (Includes foods for USDA's Food Distribution Program)",
    "Apples, raw, fuji, with skin (Includes foods for USDA's Food Distribution Program)",
    "Apples, raw, gala, with skin (Includes foods for USDA's Food Distribution Program)",
    "Apples, raw, golden delicious, with skin",
    "Apples, raw, granny smith, with skin (Includes foods for USDA's Food Distribution Program)",
    "Apples, raw, red delicious, with skin (Includes foods for USDA's Food Distribution Program)",
    "Apples, raw, with skin (Includes foods for USDA's Food Distribution Program)",
    "Apples, raw, without skin",
    "Apples, raw, without skin, cooked, boiled",
    "Apples, raw, without skin, cooked, microwave",
    "Applesauce, canned, sweetened, with salt",
    "Applesauce, canned, sweetened, without salt",
    "Applesauce, canned, unsweetened, with added ascorbic acid",
    "Applesauce, canned, unsweetened, without added ascorbic acid (Includes foods for USDA's Food Distribution Program)",
    "Apricot nectar, canned, with added ascorbic acid",
    "Apricots, canned, extra heavy syrup pack, without skin, solids and liquids",
    "Apricots, canned, extra light syrup pack, with skin, solids and liquids (Includes foods for USDA's Food Distribution Program)",
    "Apricots, canned, heavy syrup pack, with skin, solids and liquids",
    "Apricots, canned, heavy syrup pack, without skin, solids and liquids",
    "Apricots, canned, heavy syrup, drained",
    "Apricots, canned, juice pack, with skin, solids and liquids",
    "Apricots, canned, light syrup pack, with skin, solids and liquids",
    "Apricots, canned, water pack, with skin, solids and liquids",
    "Apricots, canned, water pack, without skin, solids and liquids",
    "Apricots, dehydrated (low-moisture), sulfured, stewed",
    "Apricots, dehydrated (low-moisture), sulfured, uncooked",
    "Apricots, dried, sulfured, stewed, with added sugar",
    "Apricots, dried, sulfured, stewed, without added sugar",
    "Apricots, dried, sulfured, uncooked",
    "Apricots, frozen, sweetened",
    "Apricots, raw",
    "ARBY'S, roast beef sandwich, classic",
    "Archway Home Style Cookies, Chocolate Chip Ice Box",
    "Archway Home Style Cookies, Coconut Macaroon",
    "Archway Home Style Cookies, Date Filled Oatmeal",
    "Archway Home Style Cookies, Dutch Cocoa",
    "Archway Home Style Cookies, Frosty Lemon",
    "Archway Home Style Cookies, Iced Molasses",
    "Archway Home Style Cookies, Iced Oatmeal",
    "Archway Home Style Cookies, Molasses",
    "Archway Home Style Cookies, Oatmeal",
    "Archway Home Style Cookies, Oatmeal",
    "Archway Home Style Cookies, Oatmeal Raisin",
    "Archway Home Style Cookies, Old Fashioned Molasses",
    "Archway Home Style Cookies, Old Fashioned Windmill Cookies",
    "Archway Home Style Cookies, Peanut Butter",
    "Archway Home Style Cookies, Raspberry Filled",
    "Archway Home Style Cookies, Reduced Fat Ginger Snaps",
    "Archway Home Style Cookies, Strawberry Filled",
    "Archway Home Style Cookies, Sugar Free Oatmeal",
    "Arrowhead, cooked, boiled, drained, with salt",
    "Arrowhead, cooked, boiled, drained, without salt",
    "Arrowhead, raw",
    "Arrowroot flour",
    "Arrowroot, raw",
    "Artichokes, (globe or french), cooked, boiled, drained, with salt",
    "Artichokes, (globe or french), cooked, boiled, drained, without salt",
    "Artichokes, (globe or french), frozen, cooked, boiled, drained, with salt",
    "Artichokes, (globe or french), frozen, cooked, boiled, drained, without salt",
    "Artichokes, (globe or french), frozen, unprepared",
    "Artichokes, (globe or french), raw",
    "Artificial Blueberry Muffin Mix, dry",
    "Arugula, raw",
    "Ascidians (tunughnak) (Alaska Native)",
    "Asparagus, canned, drained solids",
    "Asparagus, canned, no salt added, solids and liquids",
    "Asparagus, canned, regular pack, solids and liquids",
    "Asparagus, cooked, boiled, drained",
    "Asparagus, cooked, boiled, drained, with salt",
    "Asparagus, frozen, cooked, boiled, drained, with salt",
    "Asparagus, frozen, cooked, boiled, drained, without salt",
    "Asparagus, frozen, unprepared",
    "Asparagus, raw",
    "Avocados, raw, all commercial varieties",
    "Avocados, raw, California",
    "Avocados, raw, Florida",
    "Babyfood, apple yogurt dessert, strained",
    "Babyfood, apple-banana juice",
    "Babyfood, apples with ham, strained",
    "Babyfood, apples, dices, toddler",
    "Babyfood, Baby MUM MUM Rice Biscuits",
    "Babyfood, baked product, finger snacks cereal fortified",
    "Babyfood, banana apple dessert, strained",
    "Babyfood, banana juice with low fat yogurt",
    "Babyfood, banana no tapioca, strained",
    "Babyfood, banana with mixed berries, strained",
    "Babyfood, beverage, GERBER, GRADUATES, FRUIT SPLASHERS",
    "Babyfood, carrots and beef, strained",
    "Babyfood, carrots, toddler",
    "Babyfood, cereal, barley, dry fortified",
    "Babyfood, cereal, barley, prepared with whole milk",
    "Babyfood, cereal, brown rice, dry, instant",
    "Babyfood, cereal, egg yolks and bacon, junior",
    "Babyfood, cereal, high protein, prepared with whole milk",
    "Babyfood, cereal, high protein, with apple and orange, dry",
    "Babyfood, cereal, high protein, with apple and orange, prepared with whole milk",
    "Babyfood, cereal, mixed, dry fortified",
    "Babyfood, cereal, mixed, prepared with whole milk",
    "Babyfood, cereal, mixed, with applesauce and bananas, junior, fortified",
    "Babyfood, cereal, mixed, with applesauce and bananas, strained",
    "Babyfood, cereal, mixed, with bananas, dry",
    "Babyfood, cereal, mixed, with bananas, prepared with whole milk",
    "Babyfood, cereal, mixed, with honey, prepared with whole milk",
    "Babyfood, cereal, oatmeal, dry fortified",
    "Babyfood, cereal, Oatmeal, dry, GERBER, SINGLE GRAIN, fortified",
    "Babyfood, cereal, oatmeal, prepared with whole milk",
    "Babyfood, cereal, rice, dry fortified",
    "Babyfood, cereal, rice, prepared with whole milk",
    "Babyfood, cereal, rice, with applesauce and bananas, strained",
    "Babyfood, cereal, rice, with bananas, dry",
    "Babyfood, cereal, rice, with bananas, prepared with whole milk",
    "Babyfood, cereal, rice, with honey, prepared with whole milk",
    "Babyfood, cereal, whole wheat, with apples, dry fortified",
    "Babyfood, cereal, with egg yolks, junior",
    "Babyfood, cereal, with egg yolks, strained",
    "Babyfood, cereal, with eggs, strained",
    "Babyfood, cherry cobbler, junior",
    "Babyfood, cookie, baby, fruit",
    "Babyfood, cookies",
    "Babyfood, cookies, arrowroot",
    "Babyfood, corn and sweet potatoes, strained",
    "Babyfood, crackers, vegetable",
    "Babyfood, dessert, banana pudding, strained",
    "Babyfood, dessert, banana yogurt, strained",
    "Babyfood, dessert, blueberry yogurt, strained",
    "Babyfood, dessert, cherry vanilla pudding, junior",
    "Babyfood, dessert, cherry vanilla pudding, strained",
    "Babyfood, dessert, dutch apple, junior",
    "Babyfood, dessert, dutch apple, strained",
    "Babyfood, dessert, fruit dessert, without ascorbic acid, junior",
    "Babyfood, dessert, fruit dessert, without ascorbic acid, strained",
    "Babyfood, dessert, fruit pudding, orange, strained",
    "Babyfood, dessert, fruit pudding, pineapple, strained",
    "Babyfood, dessert, peach cobbler, junior",
    "Babyfood, dessert, peach cobbler, strained",
    "Babyfood, dessert, peach melba, junior",
    "Babyfood, dessert, peach melba, strained",
    "Babyfood, dessert, peach yogurt",
    "Babyfood, dessert, tropical fruit, junior",
    "Babyfood, dinner, apples and chicken, strained",
    "Babyfood, dinner, beef and rice, toddler",
    "Babyfood, dinner, beef lasagna, toddler",
    "Babyfood, dinner, beef noodle, junior",
    "Babyfood, dinner, beef noodle, strained",
    "Babyfood, dinner, beef with vegetables",
    "Babyfood, dinner, broccoli and chicken, junior",
    "Babyfood, dinner, chicken and rice",
    "Babyfood, dinner, chicken noodle, junior",
    "Babyfood, dinner, chicken noodle, strained",
    "Babyfood, dinner, chicken soup, strained",
    "Babyfood, dinner, chicken stew, toddler",
    "Babyfood, dinner, macaroni and cheese, junior",
    "Babyfood, dinner, macaroni and cheese, strained",
    "Babyfood, dinner, macaroni and tomato and beef, junior",
    "Babyfood, dinner, macaroni and tomato and beef, strained",
    "Babyfood, dinner, macaroni, beef and tomato sauce, toddler",
    "Babyfood, dinner, mixed vegetable, junior",
    "Babyfood, dinner, mixed vegetable, strained",
    "Babyfood, dinner, pasta with vegetables",
    "Babyfood, dinner, spaghetti and tomato and meat, junior",
    "Babyfood, dinner, spaghetti and tomato and meat, toddler",
    "Babyfood, dinner, sweet potatoes and chicken, strained",
    "Babyfood, dinner, turkey and rice, junior",
    "Babyfood, dinner, turkey and rice, strained",
    "Babyfood, dinner, turkey, rice, and vegetables, toddler",
    "Babyfood, dinner, vegetables and beef, junior",
    "Babyfood, dinner, vegetables and beef, strained",
    "Babyfood, dinner, vegetables and chicken, junior",
    "Babyfood, dinner, vegetables and dumplings and beef, junior",
    "Babyfood, dinner, vegetables and dumplings and beef, strained",
    "Babyfood, dinner, vegetables and lamb, junior",
    "Babyfood, dinner, vegetables and noodles and turkey, junior",
    "Babyfood, dinner, vegetables and noodles and turkey, strained",
    "Babyfood, dinner, vegetables and turkey, junior",
    "Babyfood, dinner, vegetables and turkey, strained",
    "Babyfood, dinner, vegetables chicken, strained",
    "Babyfood, dinner, vegetables, noodles and chicken, junior",
    "Babyfood, dinner, vegetables, noodles and chicken, strained",
    "Babyfood, finger snacks, GERBER, GRADUATES, PUFFS, apple and cinnamon",
    "Babyfood, fortified cereal bar, fruit filling",
    "Babyfood, fruit and vegetable, apple and sweet potato",
    "Babyfood, fruit dessert, mango with tapioca",
    "Babyfood, fruit supreme dessert",
    "Babyfood, fruit, apple and blueberry, junior",
    "Babyfood, fruit, apple and blueberry, strained",
    "Babyfood, fruit, apple and raspberry, junior",
    "Babyfood, fruit, apple and raspberry, strained",
    "Babyfood, fruit, applesauce and apricots, junior",
    "Babyfood, fruit, applesauce and apricots, strained",
    "Babyfood, fruit, applesauce and cherries, junior",
    "Babyfood, fruit, applesauce and cherries, strained",
    "Babyfood, fruit, applesauce and pineapple, junior",
    "Babyfood, fruit, applesauce and pineapple, strained",
    "Babyfood, fruit, applesauce with banana, junior",
    "Babyfood, fruit, applesauce, junior",
    "Babyfood, fruit, applesauce, strained",
    "Babyfood, fruit, apricot with tapioca, junior",
    "Babyfood, fruit, apricot with tapioca, strained",
    "Babyfood, fruit, banana and strawberry, junior",
    "Babyfood, fruit, bananas and pineapple with tapioca, junior",
    "Babyfood, fruit, bananas and pineapple with tapioca, strained",
    "Babyfood, fruit, bananas with apples and pears, strained",
    "Babyfood, fruit, bananas with tapioca, junior",
    "Babyfood, fruit, bananas with tapioca, strained",
    "Babyfood, fruit, guava and papaya with tapioca, strained",
    "Babyfood, fruit, papaya and applesauce with tapioca, strained",
    "Babyfood, fruit, peaches, junior",
    "Babyfood, fruit, peaches, strained",
    "Babyfood, fruit, pears and pineapple, junior",
    "Babyfood, fruit, pears and pineapple, strained",
    "Babyfood, fruit, pears, junior",
    "Babyfood, fruit, pears, strained",
    "Babyfood, fruit, plums with tapioca, without ascorbic acid, junior",
    "Babyfood, fruit, plums with tapioca, without ascorbic acid, strained",
    "Babyfood, fruit, prunes with tapioca, without ascorbic acid, junior",
    "Babyfood, fruit, prunes with tapioca, without ascorbic acid, strained",
    "Babyfood, fruit, tutti frutti, junior",
    "Babyfood, fruit, tutti frutti, strained",
    "Babyfood, GERBER, 2nd Foods, apple, carrot and squash, organic",
    "Babyfood, GERBER, 3rd Foods, apple, mango and kiwi",
    "Babyfood, GERBER, Banana with orange medley",
    "Babyfood, GERBER, GRADUATES Lil Biscuits Vanilla Wheat",
    "Babyfood, grape juice, no sugar, canned",
    "Babyfood, green beans and turkey, strained",
    "Babyfood, green beans, dices, toddler",
    "Babyfood, juice treats, fruit medley, toddler",
    "Babyfood, juice, apple",
    "Babyfood, juice, apple - cherry",
    "Babyfood, juice, apple and grape",
    "Babyfood, juice, apple and peach",
    "Babyfood, juice, apple and prune",
    "Babyfood, juice, apple, with calcium",
    "Babyfood, juice, apple-sweet potato",
    "Babyfood, juice, fruit punch, with calcium",
    "Babyfood, juice, mixed fruit",
    "Babyfood, juice, orange",
    "Babyfood, juice, orange and apple",
    "Babyfood, juice, orange and apple and banana",
    "Babyfood, juice, orange and apricot",
    "Babyfood, juice, orange and banana",
    "Babyfood, juice, orange and pineapple",
    "Babyfood, juice, orange-carrot",
    "Babyfood, juice, pear",
    "Babyfood, juice, prune and orange",
    "Babyfood, macaroni and cheese, toddler",
    "Babyfood, mashed cheddar potatoes and broccoli, toddlers",
    "Babyfood, meat, beef with vegetables, toddler",
    "Babyfood, meat, beef, junior",
    "Babyfood, meat, beef, strained",
    "Babyfood, meat, chicken sticks, junior",
    "Babyfood, meat, chicken, junior",
    "Babyfood, meat, chicken, strained",
    "Babyfood, meat, ham, junior",
    "Babyfood, meat, ham, strained",
    "Babyfood, meat, lamb, junior",
    "Babyfood, meat, lamb, strained",
    "Babyfood, meat, meat sticks, junior",
    "Babyfood, meat, pork, strained",
    "Babyfood, meat, turkey sticks, junior",
    "Babyfood, meat, turkey, junior",
    "Babyfood, meat, turkey, strained",
    "Babyfood, meat, veal, strained",
    "Babyfood, mixed fruit juice with low fat yogurt",
    "Babyfood, mixed fruit yogurt, strained",
    "Babyfood, Multigrain whole grain cereal, dry fortified",
    "Babyfood, oatmeal cereal with fruit, dry, instant, toddler fortified",
    "Babyfood, peaches, dices, toddler",
    "Babyfood, pears, dices, toddler",
    "Babyfood, peas and brown rice",
    "Babyfood, peas, dices, toddler",
    "Babyfood, plums, bananas and rice, strained",
    "Babyfood, potatoes, toddler",
    "Babyfood, pretzels",
    "Babyfood, prunes, without vitamin c, strained",
    "Babyfood, ravioli, cheese filled, with tomato sauce",
    "Babyfood, rice and apples, dry",
    "Babyfood, rice cereal, dry, EARTHS BEST ORGANIC WHOLE GRAIN, fortified only with iron",
    "Babyfood, snack, GERBER GRADUATE FRUIT STRIPS, Real Fruit Bars",
    "Babyfood, Snack, GERBER, GRADUATES, LIL CRUNCHIES, baked whole grain corn snack",
    "Babyfood, snack, GERBER, GRADUATES, YOGURT MELTS",
    "Babyfood, tropical fruit medley",
    "Babyfood, vegetable and brown rice, strained",
    "Balsam-pear (bitter gourd), pods, raw",
    "Bamboo shoots, canned, drained solids",
    "Bamboo shoots, cooked, boiled, drained, with salt",
    "Bamboo shoots, cooked, boiled, drained, without salt",
    "Bamboo shoots, raw",
    "Bananas, dehydrated, or banana powder",
    "Bananas, raw",
    "BANQUET, Salisbury Steak With Gravy, family size, frozen, unprepared",
    "Baobab powder",
    "Barbecue loaf, pork, beef",
    "Barley flour or meal",
    "Barley malt flour",
    "Barley, hulled",
    "Barley, pearled, cooked",
    "Barley, pearled, raw",
    "Basil, fresh",
    "Beans, adzuki, mature seed, cooked, boiled, with salt",
    "Beans, adzuki, mature seeds, canned, sweetened",
    "Beans, adzuki, mature seeds, cooked, boiled, without salt",
    "Beans, adzuki, mature seeds, raw",
    "Beans, baked, canned, no salt added",
    "Beans, baked, canned, plain or vegetarian",
    "Beans, baked, canned, with beef",
    "Beans, baked, canned, with franks",
    "Beans, baked, canned, with pork",
    "Beans, baked, canned, with pork and sweet sauce",
    "Beans, baked, canned, with pork and tomato sauce",
    "Beans, baked, home prepared",
    "Beans, black turtle, mature seeds, canned",
    "Beans, black turtle, mature seeds, cooked, boiled, with salt",
    "Beans, black turtle, mature seeds, cooked, boiled, without salt",
    "Beans, black turtle, mature seeds, raw",
    "Beans, black, mature seeds, canned, low sodium",
    "Beans, black, mature seeds, cooked, boiled, with salt",
    "Beans, black, mature seeds, cooked, boiled, without salt",
    "Beans, black, mature seeds, raw",
    "Beans, chili, barbecue, ranch style, cooked",
    "Beans, cranberry (roman), mature seeds, canned",
    "Beans, cranberry (roman), mature seeds, cooked, boiled, with salt",
    "Beans, cranberry (roman), mature seeds, cooked, boiled, without salt",
    "Beans, cranberry (roman), mature seeds, raw",
    "Beans, fava, in pod, raw",
    "Beans, french, mature seeds, cooked, boiled, with salt",
    "Beans, french, mature seeds, cooked, boiled, without salt",
    "Beans, french, mature seeds, raw",
    "Beans, great northern, mature seeds, canned",
    "Beans, great northern, mature seeds, canned, low sodium",
    "Beans, great northern, mature seeds, cooked, boiled, with salt",
    "Beans, great northern, mature seeds, cooked, boiled, without salt",
    "Beans, great northern, mature seeds, raw",
    "Beans, pink, mature seeds, raw",
    "Beans, pinto, canned, drained solids",
    "Beans, pinto, immature seeds, frozen, cooked, boiled, drained, with salt",
    "Beans, pinto, immature seeds, frozen, cooked, boiled, drained, without salt",
    "Beans, pinto, immature seeds, frozen, unprepared",
    "Beans, pinto, mature seeds, canned, drained solids, rinsed in tap water",
    "Beans, pinto, mature seeds, canned, solids and liquids",
    "Beans, pinto, mature seeds, canned, solids and liquids, low sodium",
    "Beans, pinto, mature seeds, cooked, boiled, with salt",
    "Beans, pinto, mature seeds, cooked, boiled, without salt",
    "Beans, pinto, mature seeds, raw (Includes foods for USDA's Food Distribution Program)",
    "Beans, pinto, mature seeds, sprouted, cooked, boiled, drained, with salt",
    "Beans, pinto, mature seeds, sprouted, cooked, boiled, drained, without salt",
    "Beans, pinto, mature seeds, sprouted, raw",
    "Beans, shellie, canned, solids and liquids",
    "Beans, small white, mature seeds, cooked, boiled, with salt",
    "Beans, small white, mature seeds, cooked, boiled, without salt",
    "Beans, small white, mature seeds, raw",
    "Beans, snap, canned, all styles, seasoned, solids and liquids",
    "Beans, snap, green, canned, no salt added, drained solids",
    "Beans, snap, green, canned, no salt added, solids and liquids",
    "Beans, snap, green, canned, regular pack, drained solids",
    "Beans, snap, green, canned, regular pack, solids and liquids",
    "Beans, snap, green, cooked, boiled, drained, with salt",
    "Beans, snap, green, cooked, boiled, drained, without salt",
    "Beans, snap, green, frozen, all styles, microwaved",
    "Beans, snap, green, frozen, all styles, unprepared (Includes foods for USDA's Food Distribution Program)",
    "Beans, snap, green, frozen, cooked, boiled, drained without salt",
    "Beans, snap, green, frozen, cooked, boiled, drained, with salt",
    "Beans, snap, green, microwaved",
    "Beans, snap, green, raw",
    "Beans, snap, yellow, canned, no salt added, drained solids",
    "Beans, snap, yellow, canned, no salt added, solids and liquids",
    "Beans, snap, yellow, canned, regular pack, drained solids",
    "Beans, snap, yellow, canned, regular pack, solids and liquids",
    "Beans, snap, yellow, cooked, boiled, drained, with salt",
    "Beans, snap, yellow, cooked, boiled, drained, without salt",
    "Beans, snap, yellow, frozen, all styles, unprepared",
    "Beans, snap, yellow, frozen, cooked, boiled, drained, with salt",
    "Beans, snap, yellow, frozen, cooked, boiled, drained, without salt",
    "Beans, snap, yellow, raw",
    "Beans, white, mature seeds, canned",
    "Beans, white, mature seeds, cooked, boiled, with salt",
    "Beans, white, mature seeds, cooked, boiled, without salt",
    "Beans, white, mature seeds, raw",
    "Beans, yellow, mature seeds, cooked, boiled, with salt",
    "Beans, yellow, mature seeds, cooked, boiled, without salt",
    "Beans, yellow, mature seeds, raw",
    "Bear, black, meat (Alaska Native)",
    "Bear, polar, meat, raw (Alaska Native)",
    "Beef composite, separable lean only, trimmed to 1/8\" fat, choice, cooked",
    "Beef macaroni with tomato sauce, frozen entree, reduced fat",
    "Beef Pot Pie, frozen entree, prepared",
    "Beef stew, canned entree",
    "Beef, Australian, imported, grass-fed, external fat, raw",
    "Beef, Australian, imported, grass-fed, ground, 85% lean / 15% fat, raw",
    "Beef, Australian, imported, grass-fed, loin, tenderloin steak/roast, boneless, separable lean and fat, raw",
    "Beef, Australian, imported, grass-fed, loin, tenderloin steak/roast, boneless, separable lean only, raw",
    "Beef, Australian, imported, grass-fed, loin, top loin steak/roast, boneless, separable lean and fat, raw",
    "Beef, Australian, imported, grass-fed, loin, top loin steak/roast, boneless, separable lean only, raw",
    "Beef, Australian, imported, grass-fed, loin, top sirloin cap-off steak/roast, boneless, separable lean and fat, raw",
    "Beef, Australian, imported, grass-fed, loin, top sirloin cap-off steak/roast, boneless, separable lean only, raw",
    "Beef, Australian, imported, grass-fed, rib, ribeye steak/roast lip-on, boneless, separable lean and fat, raw",
    "Beef, Australian, imported, grass-fed, rib, ribeye steak/roast lip-on, boneless, separable lean only, raw",
    "Beef, Australian, imported, grass-fed, round, bottom round steak/roast, boneless, separable lean and fat, raw",
    "Beef, Australian, imported, grass-fed, round, bottom round steak/roast, boneless, separable lean only, raw",
    "Beef, Australian, imported, grass-fed, round, top round cap-off steak/roast, boneless, separable lean and fat, raw",
    "Beef, Australian, imported, grass-fed, round, top round cap-off steak/roast, boneless, separable lean only, raw",
    "Beef, Australian, imported, grass-fed, seam fat, raw",
    "Beef, Australian, imported, Wagyu, external fat, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, external fat, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, loin, tenderloin steak/roast, boneless, separable lean and fat, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, loin, tenderloin steak/roast, boneless, separable lean and fat, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, loin, tenderloin steak/roast, boneless, separable lean only, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, loin, tenderloin steak/roast, boneless, separable lean only, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, loin, top loin steak/roast, boneless, separable lean and fat, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, loin, top loin steak/roast, boneless, separable lean only, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, loin, top loin steak/roast, boneless, separable lean only, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, loin, top loin steak/roast, separable lean and fat, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, rib, small end rib steak/roast, boneless, separable lean and fat, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, rib, small end rib steak/roast, boneless, separable lean and fat, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, rib, small end rib steak/roast, boneless, separable lean only, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, rib, small end rib steak/roast, boneless, separable lean only, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, seam fat, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, seam fat, Aust. marble score 9, raw",
    "Beef, bologna, reduced sodium",
    "Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0\" fat, all grades, cooked, roasted",
    "Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0\" fat, all grades, raw",
    "Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0\" fat, choice, cooked, roasted",
    "Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0\" fat, choice, raw",
    "Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0\" fat, select, cooked, roasted",
    "Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0\" fat, select, raw",
    "Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0\" fat, all grades, raw",
    "Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0\" fat, choice, cooked, roasted",
    "Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0\" fat, choice, raw",
    "Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0\" fat, select, cooked, roasted",
    "Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0\" fat, select, raw",
    "Beef, brisket, flat half, boneless separable lean only, trimmed to 0\" fat, all grades, raw",
    "Beef, brisket, flat half, boneless, separable lean and fat, trimmed to 0\" fat, all grades, raw",
    "Beef, brisket, flat half, boneless, separable lean and fat, trimmed to 0\" fat, choice, raw",
    "Beef, brisket, flat half, boneless, separable lean and fat, trimmed to 0\" fat, select, raw",
    "Beef, brisket, flat half, boneless, separable lean only, trimmed to 0\" fat, choice, raw",
    "Beef, brisket, flat half, boneless, separable lean only, trimmed to 0\" fat, select, raw",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 0\" fat, select, cooked, braised",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 1/8\" fat, all grades, raw",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, braised",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 1/8\" fat, select, cooked, braised",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 1/8\" fat, select, raw",
    "Beef, brisket, flat half, separable lean only, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, brisket, flat half, separable lean only, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, brisket, flat half, separable lean only, trimmed to 0\" fat, select, cooked, braised",
    "Beef, brisket, flat half, separable lean only, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, brisket, flat half, separable lean only, trimmed to 1/8\" fat, all grades, raw",
    "Beef, brisket, flat half, separable lean only, trimmed to 1/8\" fat, choice, cooked, braised",
    "Beef, brisket, flat half, separable lean only, trimmed to 1/8\" fat, choice, raw",
    "Beef, brisket, flat half, separable lean only, trimmed to 1/8\" fat, select, cooked, braised",
    "Beef, brisket, flat half, separable lean only, trimmed to 1/8\" fat, select, raw",
    "Beef, brisket, point half, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, brisket, point half, separable lean and fat, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, brisket, point half, separable lean and fat, trimmed to 1/8\" fat, all grades, raw",
    "Beef, brisket, point half, separable lean only, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, brisket, whole, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, brisket, whole, separable lean and fat, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, brisket, whole, separable lean and fat, trimmed to 1/8\" fat, all grades, raw",
    "Beef, brisket, whole, separable lean only, all grades, raw",
    "Beef, brisket, whole, separable lean only, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, carcass, separable lean and fat, choice, raw",
    "Beef, carcass, separable lean and fat, select, raw",
    "Beef, chopped, cured, smoked",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0\" fat, choice, raw",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0\" fat, select, cooked, braised",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0\" fat, select, raw",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0\" fat, choice, raw",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0\" fat, select, cooked, braised",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0\" fat, select, raw",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0\" fat, all grades, cooked, roasted",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0\" fat, choice, cooked, roasted",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0\" fat, choice, raw",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0\" fat, select, cooked, roasted",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0\" fat, select, raw",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0\" fat, all grades, cooked, roasted",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0\" fat, choice, cooked, roasted",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0\" fat, choice, raw",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0\" fat, select, cooked, roasted",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0\" fat, select, raw",
    "Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0\" fat, all grades, cooked, grilled",
    "Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0\" fat, choice, cooked, grilled",
    "Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0\" fat, choice, raw",
    "Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0\" fat, select, cooked, grilled",
    "Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0\" fat, select, raw",
    "Beef, chuck eye steak, boneless, separable lean only, trimmed to 0\" fat, all grades, cooked, grilled",
    "Beef, chuck eye steak, boneless, separable lean only, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck eye steak, boneless, separable lean only, trimmed to 0\" fat, choice, cooked, grilled",
    "Beef, chuck eye steak, boneless, separable lean only, trimmed to 0\" fat, choice, raw",
    "Beef, chuck eye steak, boneless, separable lean only, trimmed to 0\" fat, select, cooked, grilled",
    "Beef, chuck eye steak, boneless, separable lean only, trimmed to 0\" fat, select, raw",
    "Beef, chuck for stew, separable lean and fat, all grades, cooked, braised",
    "Beef, chuck for stew, separable lean and fat, all grades, raw",
    "Beef, chuck for stew, separable lean and fat, choice, cooked, braised",
    "Beef, chuck for stew, separable lean and fat, choice, raw",
    "Beef, chuck for stew, separable lean and fat, select, cooked, braised",
    "Beef, chuck for stew, separable lean and fat, select, raw",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 0\" fat, select, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8\" fat, all grades, raw",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8\" fat, select, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8\" fat, select, raw",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 0\" fat, select, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8\" fat, all grades, raw",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8\" fat, choice, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8\" fat, choice, raw",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8\" fat, select, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8\" fat, select, raw",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8\" fat, all grades, raw",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, braised",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8\" fat, select, cooked, braised",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8\" fat, select, raw",
    "Beef, chuck, blade roast, separable lean only, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck, clod roast, separable lean and fat, trimmed to 0\" fat, all grades, cooked, roasted",
    "Beef, chuck, clod roast, separable lean and fat, trimmed to 0\" fat, choice, cooked, roasted",
    "Beef, chuck, clod roast, separable lean and fat, trimmed to 0\" fat, select, cooked, roasted",
    "Beef, chuck, clod roast, separable lean only, trimmed to 0\" fat, all grades, cooked, roasted",
    "Beef, chuck, clod roast, separable lean only, trimmed to 0\" fat, choice, cooked, roasted",
    "Beef, chuck, clod roast, separable lean only, trimmed to 0\" fat, select, cooked, roasted",
    "Beef, chuck, clod roast, separable lean only, trimmed to 1/4\" fat, all grades, cooked, roasted",
    "Beef, chuck, clod roast, separable lean only, trimmed to 1/4\" fat, all grades, raw",
    "Beef, chuck, clod steak, separable lean only, trimmed to 1/4\" fat, all grades, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0\" fat, choice, raw",
    "Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0\" fat, select, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0\" fat, select, raw",
    "Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0\" fat, choice, raw",
    "Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0\" fat, select, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, mock tender steak, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, broiled",
    "Beef, chuck, mock tender steak, separable lean and fat, trimmed to 0%22 fat, USDA choice, cooked, broiled",
    "Beef, chuck, mock tender steak, separable lean and fat, trimmed to 0%22 fat, USDA select, cooked, broiled",
    "Beef, chuck, mock tender steak, separable lean only, trimmed to 0%22 fat, all grades, cooked, broiled",
    "Beef, chuck, mock tender steak, separable lean only, trimmed to 0%22 fat, choice, cooked, broiled",
    "Beef, chuck, mock tender steak, separable lean only, trimmed to 0%22 fat, select, cooked, broiled",
    "Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, braised",
    "Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0%22 fat, select, cooked, braised",
    "Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0%22 fat, all grades, cooked, braised",
    "Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0%22 fat, select, cooked, braised",
    "Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, grilled",
    "Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0%22 fat, choice, cooked, grilled",
    "Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0%22 fat, select, cooked, grilled",
    "Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, grilled",
    "Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0%22 fat, choice, cooked, grilled",
    "Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0%22 fat, select, cooked, grilled",
    "Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, grilled",
    "Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0%22 fat, choice, cooked, grilled",
    "Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0%22 fat, select, cooked, grilled",
    "Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, top blade, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, broiled",
    "Beef, chuck, top blade, separable lean and fat, trimmed to 0%22 fat, choice, cooked, broiled",
    "Beef, chuck, top blade, separable lean and fat, trimmed to 0%22 fat, select, cooked, broiled",
    "Beef, chuck, top blade, separable lean only, trimmed to 0%22 fat, all grades, cooked, broiled",
    "Beef, chuck, top blade, separable lean only, trimmed to 0%22 fat, choice, cooked, broiled",
    "Beef, chuck, top blade, separable lean only, trimmed to 0%22 fat, select, cooked, broiled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, grilled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0%22 fat, choice, cooked, grilled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0%22 fat, select, cooked, grilled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0%22 fat, all grades, cooked, grilled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0%22 fat, choice, cooked, grilled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0%22 fat, select, cooked, grilled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, under blade pot roast or steak, boneless, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, under blade pot roast or steak, boneless, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, under blade pot roast or steak, boneless, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, under blade pot roast or steak, boneless, separable lean only, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, under blade pot roast or steak, boneless, separable lean only, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, under blade pot roast or steak, boneless, separable lean only, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, under blade pot roast, boneless, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, braised",
    "Beef, chuck, under blade pot roast, boneless, separable lean and fat, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, chuck, under blade pot roast, boneless, separable lean and fat, trimmed to 0%22 fat, select, cooked, braised",
    "Beef, chuck, under blade pot roast, boneless, separable lean only, trimmed to 0%22 fat, all grades, cooked, braised",
    "Beef, chuck, under blade pot roast, boneless, separable lean only, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, chuck, under blade pot roast, boneless, separable lean only, trimmed to 0%22 fat, select, cooked, braised",
    "Beef, chuck, under blade steak, boneless, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, braised",
    "Beef, chuck, under blade steak, boneless, separable lean and fat, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, chuck, under blade steak, boneless, separable lean and fat, trimmed to 0%22 fat, select, cooked, braised",
    "Beef, chuck, under blade steak, boneless, separable lean only, trimmed to 0%22 fat, all grades, cooked, braised",
    "Beef, chuck, under blade steak, boneless, separable lean only, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, chuck, under blade steak, boneless, separable lean only, trimmed to 0%22 fat, select, cooked, braised",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0%22 fat, all grades, cooked",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0%22 fat, choice, cooked",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0%22 fat, select, cooked",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1%2F8%22 fat, all grades, cooked",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1%2F8%22 fat, all grades, raw",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1%2F8%22 fat, choice, cooked",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1%2F8%22 fat, choice, raw",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1%2F8%22 fat, select, cooked",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1%2F8%22 fat, select, raw",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0%22 fat, all grades, cooked",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0%22 fat, all grades, raw",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0%22 fat, choice, cooked",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0%22 fat, choice, raw",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0%22 fat, select, cooked",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0%22 fat, select, raw",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 1%2F8%22 fat, all grades, cooked",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 1%2F8%22 fat, all grades, raw",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 1%2F8%22 fat, choice, raw",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 1%2F8%22 fat, select, cooked",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 1%2F8%22 fat, select, raw",
    "Beef, corned beef hash, with potato, canned",
    "Beef, cured, breakfast strips, cooked",
    "Beef, cured, breakfast strips, raw or unheated",
    "Beef, cured, corned beef, brisket, cooked",
    "Beef, cured, corned beef, brisket, raw",
    "Beef, cured, corned beef, canned",
    "Beef, cured, dried",
    "Beef, cured, luncheon meat, jellied",
    "Beef, cured, pastrami",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, broiled",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, choice, cooked, broiled",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, select, cooked, broiled",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, all grades, cooked, broiled",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, all grades, raw",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, choice, cooked, broiled",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, choice, raw",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, select, cooked, broiled",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, select, raw",
    "Beef, grass-fed, ground, raw",
    "Beef, grass-fed, strip steaks, lean only, raw",
    "Beef, ground, 70% lean meat %2F 30% fat, crumbles, cooked, pan-browned",
    "Beef, ground, 70% lean meat %2F 30% fat, loaf, cooked, baked",
    "Beef, ground, 70% lean meat %2F 30% fat, patty cooked, pan-broiled",
    "Beef, ground, 70% lean meat %2F 30% fat, patty, cooked, broiled",
    "Beef, ground, 70% lean meat %2F 30% fat, raw",
    "Beef, ground, 75% lean meat %2F 25% fat, crumbles, cooked, pan-browned",
    "Beef, ground, 75% lean meat %2F 25% fat, loaf, cooked, baked",
    "Beef, ground, 75% lean meat %2F 25% fat, patty, cooked, broiled",
    "Beef, ground, 75% lean meat %2F 25% fat, patty, cooked, pan-broiled",
    "Beef, ground, 75% lean meat %2F 25% fat, raw",
    "Beef, ground, 80% lean meat %2F 20% fat, crumbles, cooked, pan-browned",
    "Beef, ground, 80% lean meat %2F 20% fat, loaf, cooked, baked",
    "Beef, ground, 80% lean meat %2F 20% fat, patty, cooked, broiled",
    "Beef, ground, 80% lean meat %2F 20% fat, patty, cooked, pan-broiled",
    "Beef, ground, 80% lean meat %2F 20% fat, raw",
    "Beef, ground, 85% lean meat %2F 15% fat, crumbles, cooked, pan-browned",
    "Beef, ground, 85% lean meat %2F 15% fat, loaf, cooked, baked",
    "Beef, ground, 85% lean meat %2F 15% fat, patty, cooked, broiled",
    "Beef, ground, 85% lean meat %2F 15% fat, patty, cooked, pan-broiled",
    "Beef, ground, 85% lean meat %2F 15% fat, raw (Includes foods for USDA%22s Food Distribution Program)",
    "Beef, ground, 90% lean meat %2F 10% fat, crumbles, cooked, pan-browned",
    "Beef, ground, 90% lean meat %2F 10% fat, loaf, cooked, baked",
    "Beef, ground, 90% lean meat %2F 10% fat, patty, cooked, broiled",
    "Beef, ground, 90% lean meat %2F 10% fat, patty, cooked, pan-broiled",
    "Beef, ground, 90% lean meat %2F 10% fat, raw",
    "Beef, ground, 93% lean meat %2F 7% fat, crumbles, cooked, pan-browned",
    "Beef, ground, 93% lean meat %2F 7% fat, loaf, cooked, baked",
    "Beef, ground, 93% lean meat %2F 7% fat, patty, cooked, broiled",
    "Beef, ground, 93% lean meat %2F 7% fat, raw",
    "Beet greens, raw",
    "Beets, canned, drained solids",
    "Beets, canned, no salt added, solids and liquids",
    "Beets, canned, regular pack, solids and liquids",
    "Beets, cooked, boiled, drained",
    "Beets, cooked, boiled, drained, with salt",
    "Beets, harvard, canned, solids and liquids",
    "Beets, pickled, canned, solids and liquids",
    "Beets, raw",
    "Beverage, instant breakfast powder, chocolate, not reconstituted",
    "Beverage, instant breakfast powder, chocolate, sugar-free, not reconstituted",
    "Beverages, Energy drink, Citrus",
    "Beverages, ABBOTT, EAS soy protein powder",
    "Beverages, ABBOTT, EAS whey protein powder",
    "Beverages, ABBOTT, ENSURE PLUS, ready-to-drink",
    "Beverages, ABBOTT, ENSURE, Nutritional Shake, Ready-to-Drink",
    "Beverages, Acai berry drink, fortified",
    "Beverages, almond milk, chocolate, ready-to-drink",
    "Beverages, almond milk, sweetened, vanilla flavor, ready-to-drink",
    "Beverages, almond milk, unsweetened, shelf stable",
    "Beverages, aloe vera juice drink, fortified with Vitamin C",
    "Beverages, AMBER, hard cider",
    "Beverages, Apple juice drink, light, fortified with vitamin C",
    "Beverages, ARIZONA, tea, ready-to-drink, lemon",
    "Beverages, carbonated, club soda",
    "Beverages, carbonated, cola, fast-food cola",
    "Beverages, carbonated, cola, regular",
    "Beverages, carbonated, cola, without caffeine",
    "Beverages, carbonated, ginger ale",
    "Beverages, carbonated, grape soda",
    "Beverages, carbonated, lemon-lime soda, no caffeine",
    "Beverages, carbonated, limeade, high caffeine",
    "Beverages, carbonated, low calorie, cola or pepper-type, with aspartame, contains caffeine",
    "Beverages, carbonated, low calorie, cola or pepper-type, with aspartame, without caffeine",
    "Beverages, carbonated, low calorie, cola or pepper-types, with sodium saccharin, contains caffeine",
    "Beverages, carbonated, low calorie, other than cola or pepper, without caffeine",
    "Beverages, carbonated, low calorie, other than cola or pepper, with aspartame, contains caffeine",
    "Beverages, carbonated, orange",
    "Beverages, carbonated, pepper-type, contains caffeine",
    "Beverages, carbonated, reduced sugar, cola, contains caffeine and sweeteners",
    "Beverages, carbonated, root beer",
    "Beverages, carbonated, SPRITE, lemon-lime, without caffeine",
    "Beverages, carbonated, tonic water",
    "Beverages, Carob-flavor beverage mix, powder",
    "Beverages, Carob-flavor beverage mix, powder, prepared with whole milk",
    "Beverages, chocolate almond milk, unsweetened, shelf-stable, fortified with vitamin D2 and E",
    "Beverages, chocolate drink, milk and soy based, ready to drink, fortified",
    "Beverages, chocolate malt powder, prepared with 1% milk, fortified",
    "Beverages, chocolate malt, powder, prepared with fat free milk",
    "Beverages, chocolate powder, no sugar added",
    "Beverages, chocolate syrup",
    "Beverages, chocolate syrup, prepared with whole milk",
    "Beverages, chocolate-flavor beverage mix for milk, powder, with added nutrients",
    "Beverages, chocolate-flavor beverage mix for milk, powder, with added nutrients, prepared with whole milk",
    "Beverages, chocolate-flavor beverage mix, powder, prepared with whole milk",
    "Beverages, Chocolate-flavored drink, whey and milk based",
    "Beverages, citrus fruit juice drink, frozen concentrate",
    "Beverages, citrus fruit juice drink, frozen concentrate, prepared with water",
    "Beverages, Clam and tomato juice, canned",
    "Beverages, COCA-COLA, POWERADE, lemon-lime flavored, ready-to-drink",
    "Beverages, Cocktail mix, non-alcoholic, concentrated, frozen",
    "Beverages, Cocoa mix, low calorie, powder, with added calcium, phosphorus, aspartame, without added sodium or vitamin A",
    "Beverages, Cocoa mix, NESTLE, Hot Cocoa Mix Rich Chocolate With Marshmallows",
    "Beverages, Cocoa mix, no sugar added, powder",
    "Beverages, Cocoa mix, powder",
    "Beverages, Cocoa mix, powder, prepared with water",
    "Beverages, cocoa mix, with aspartame, powder, prepared with water",
    "Beverages, coconut milk, sweetened, fortified with calcium, vitamins A, B12, D2",
    "Beverages, Coconut water, ready-to-drink, unsweetened",
    "Beverages, coffee and cocoa, instant, decaffeinated, with whitener and low calorie sweetener",
    "Beverages, coffee substitute, cereal grain beverage, powder",
    "Beverages, coffee substitute, cereal grain beverage, powder, prepared with whole milk",
    "Beverages, coffee substitute, cereal grain beverage, prepared with water",
    "Beverages, coffee, brewed, breakfast blend",
    "Beverages, coffee, brewed, espresso, restaurant-prepared",
    "Beverages, coffee, brewed, espresso, restaurant-prepared, decaffeinated",
    "Beverages, coffee, brewed, prepared with tap water",
    "Beverages, coffee, brewed, prepared with tap water, decaffeinated",
    "Beverages, coffee, instant, chicory",
    "Beverages, coffee, instant, decaffeinated, powder",
    "Beverages, coffee, instant, decaffeinated, prepared with water",
    "Beverages, coffee, instant, mocha, sweetened",
    "Beverages, coffee, instant, regular, half the caffeine",
    "Beverages, coffee, instant, regular, powder",
    "Beverages, coffee, instant, regular, prepared with water",
    "Beverages, coffee, instant, vanilla, sweetened, decaffeinated, with non dairy creamer",
    "Beverages, coffee, instant, with chicory",
    "Beverages, coffee, instant, with whitener, reduced calorie",
    "Beverages, coffee, ready to drink, iced, mocha, milk based",
    "Beverages, coffee, ready to drink, milk based, sweetened",
    "Beverages, coffee, ready to drink, vanilla, light, milk based, sweetened",
    "Beverages, Cranberry juice cocktail",
    "Beverages, cranberry-apple juice drink, bottled",
    "Beverages, cranberry-apple juice drink, low calorie, with vitamin C added",
    "Beverages, cranberry-apricot juice drink, bottled",
    "Beverages, cranberry-grape juice drink, bottled",
    "Beverages, CYTOSPORT, Muscle Milk, ready-to-drink",
    "Beverages, dairy drink mix, chocolate, reduced calorie, with aspartame, powder, prepared with water and ice",
    "Beverages, Dairy drink mix, chocolate, reduced calorie, with low-calorie sweeteners, powder",
    "Beverages, DANNON, water, bottled, non-carbonated, with Fluoride",
    "Beverages, chocolate malt, powder, prepared with fat free milk",
    "Beverages, chocolate almond milk, unsweetened, shelf-stable, fortified with vitamin D2 and E",
    "Beverages, chocolate drink, milk and soy based, ready to drink, fortified",
    "Beverages, chocolate malt powder, prepared with 1% milk, fortified",
    "Beverages, carbonated, tonic water",
    "Beverages, Carob-flavor beverage mix, powder",
    "Beverages, drink mix, QUAKER OATS, GATORADE, orange flavor, powder",
    "Beverages, Eggnog-flavor mix, powder, prepared with whole milk",
    "Beverages, Energy Drink with carbonated water and high fructose corn syrup",
    "Beverages, Energy drink, AMP",
    "Beverages, Energy drink, AMP, sugar free",
    "Beverages, Energy drink, FULL THROTTLE",
    "Beverages, Energy Drink, Monster, fortified with vitamins C, B2, B3, B6, B12",
    "Beverages, Energy drink, RED BULL",
    "Beverages, Energy drink, RED BULL, sugar free, with added caffeine, niacin, pantothenic acid, vitamins B6 and B12",
    "Beverages, Energy drink, ROCKSTAR",
    "Beverages, Energy drink, ROCKSTAR, sugar free",
    "Beverages, Energy Drink, sugar free",
    "Beverages, Energy drink, VAULT Zero, sugar-free, citrus flavor",
    "Beverages, Energy drink, VAULT, citrus flavor",
    "Beverages, Fruit flavored drink containing less than 3% fruit juice, with high vitamin C",
    "Beverages, Fruit flavored drink, less than 3% juice, not fortified with vitamin C",
    "Beverages, Fruit flavored drink, reduced sugar, greater than 3% fruit juice, high vitamin C, added calcium",
    "Beverages, fruit juice drink, greater than 3% fruit juice, high vitamin C and added thiamin",
    "Beverages, fruit juice drink, greater than 3% juice, high vitamin C",
    "Beverages, fruit juice drink, reduced sugar, with vitamin E added",
    "Beverages, Fruit punch drink, frozen concentrate",
    "Beverages, Fruit punch drink, frozen concentrate, prepared with water",
    "Beverages, Fruit punch drink, with added nutrients, canned",
    "Beverages, fruit punch drink, without added nutrients, canned",
    "Beverages, fruit punch juice drink, frozen concentrate",
    "Beverages, fruit punch juice drink, frozen concentrate, prepared with water",
    "Beverages, fruit punch-flavor drink, powder, without added sodium, prepared with water",
    "Beverages, fruit-flavored drink, dry powdered mix, low calorie, with aspartame",
    "Beverages, fruit-flavored drink, powder, with high vitamin C with other added vitamins, low calorie",
    "Beverages, FUZE, orange mango, fortified with vitamins A, C, E, B6",
    "Beverages, GEROLSTEINER BRUNNEN GmbH & Co. KG (Gerolsteiner), naturally sparkling, mineral bottled water",
    "Beverages, GEROLSTEINER BRUNNEN GmbH & Co. KG,Gerolsteiner naturally sparkling mineral water",
    "Beverages, grape drink, canned",
    "Beverages, grape juice drink, canned",
    "Beverages, Horchata, as served in restaurant",
    "Beverages, Kiwi Strawberry Juice Drink",
    "Beverages, KRAFT, coffee, instant, French Vanilla Cafe",
    "Beverages, Lemonade fruit juice drink light, fortified with vitamin E and C",
    "Beverages, lemonade, frozen concentrate, pink, prepared with water",
    "Beverages, Lemonade, powder",
    "Beverages, lemonade-flavor drink, powder",
    "Beverages, lemonade-flavor drink, powder, prepared with water",
    "Beverages, LIPTON BRISK, tea, black, ready-to-drink, lemon",
    "Beverages, Malt liquor beverage",
    "Beverages, malted drink mix, chocolate, powder",
    "Beverages, Malted drink mix, chocolate, powder, prepared with whole milk",
    "Beverages, Malted drink mix, chocolate, with added nutrients, powder, prepared with whole milk",
    "Beverages, Malted drink mix, natural, powder, dairy based.",
    "Beverages, Malted drink mix, natural, powder, prepared with whole milk",
    "Beverages, Malted drink mix, natural, with added nutrients, powder, prepared with whole milk",
    "Beverages, Meal supplement drink, canned, peanut flavor",
    "Beverages, milk beverage, reduced fat, flavored and sweetened, Ready-to-drink, added calcium, vitamin A and vitamin D",
    "Beverages, MINUTE MAID, Lemonada, Limeade",
    "Beverages, Mixed vegetable and fruit juice drink, with added nutrients",
    "Beverages, MONSTER energy drink, low carb",
    "Beverages, MOTTS, Apple juice light, fortified with vitamin C",
    "Beverages, NESTEA, tea, black, ready-to-drink, lemon",
    "Beverages, NESTLE, Boost plus, nutritional drink, ready-to-drink",
    "Beverages, nutritional shake mix, high protein, powder",
    "Beverages, OCEAN SPRAY, Cran Cherry",
    "Beverages, OCEAN SPRAY, Cran Grape",
    "Beverages, OCEAN SPRAY, Cran Lemonade",
    "Beverages, OCEAN SPRAY, Cran Pomegranate",
    "Beverages, OCEAN SPRAY, Cran Raspberry Juice Drink",
    "Beverages, OCEAN SPRAY, Cran-Energy, Cranberry Energy Juice Drink",
    "Beverages, OCEAN SPRAY, Cranberry-Apple Juice Drink, bottled",
    "Beverages, OCEAN SPRAY, Diet Cran Cherry",
    "Beverages, OCEAN SPRAY, Diet Cranberry Juice",
    "Beverages, OCEAN SPRAY, Light Cranberry",
    "Beverages, OCEAN SPRAY, Light Cranberry and Raspberry Flavored Juice",
    "Beverages, OCEAN SPRAY, Light Cranberry, Concord Grape",
    "Beverages, OCEAN SPRAY, Ruby Red cranberry",
    "Beverages, OCEAN SPRAY, White Cranberry Peach",
    "Beverages, OCEAN SPRAY, White Cranberry Strawberry Flavored Juice Drink",
    "Beverages, orange and apricot juice drink, canned",
    "Beverages, orange breakfast drink, ready-to-drink, with added nutrients",
    "Beverages, Orange drink, breakfast type, with juice and pulp, frozen concentrate",
    "Beverages, Orange drink, breakfast type, with juice and pulp, frozen concentrate, prepared with water",
    "Beverages, orange drink, canned, with added vitamin C",
    "Beverages, Orange juice drink",
    "Beverages, Orange juice, light, No pulp",
    "Beverages, Orange-flavor drink, breakfast type, low calorie, powder",
    "Beverages, orange-flavor drink, breakfast type, powder",
    "Beverages, orange-flavor drink, breakfast type, powder, prepared with water",
    "Beverages, Orange-flavor drink, breakfast type, with pulp, frozen concentrate, prepared with water",
    "Beverages, Orange-flavor drink, breakfast type, with pulp, frozen concentrate",
    "Beverages, OVALTINE, chocolate malt powder",
    "Beverages, OVALTINE, Classic Malt powder",
    "Beverages, PEPSICO QUAKER, Gatorade G2, low calorie",
    "Beverages, PEPSICO QUAKER, Gatorade, G performance O 2, ready-to-drink",
    "Beverages, pineapple and grapefruit juice drink, canned",
    "Beverages, pineapple and orange juice drink, canned",
    "Beverages, Powerade Zero Ion4, calorie-free, assorted flavors",
    "Beverages, POWERADE, Zero, Mixed Berry",
    "Beverages, Propel Zero, fruit-flavored, non-carbonated",
    "Beverages, Protein powder soy based",
    "Beverages, Protein powder whey based",
    "Beverages, rice milk, unsweetened",
    "Beverages, rich chocolate, powder",
    "Beverages, shake, fast food, strawberry",
    "Beverages, SLIMFAST, Meal replacement, High Protein Shake, Ready-To-Drink, 3-2-1 plan",
    "Beverages, SNAPPLE, tea, black and green, ready to drink, lemon, diet",
    "Beverages, SNAPPLE, tea, black and green, ready to drink, peach, diet",
    "Beverages, Strawberry-flavor beverage mix, powder, prepared with whole milk",
    "Beverages, tea, black, brewed, prepared with distilled water",
    "Beverages, tea, black, brewed, prepared with tap water",
    "Beverages, tea, black, brewed, prepared with tap water, decaffeinated",
    "Beverages, tea, black, ready to drink",
    "Beverages, tea, black, ready to drink, decaffeinated",
    "Beverages, tea, black, ready to drink, decaffeinated, diet",
    "Beverages, tea, black, ready-to-drink, lemon, diet",
    "Beverages, tea, black, ready-to-drink, lemon, sweetened",
    "Beverages, tea, black, ready-to-drink, peach, diet",
    "Beverages, tea, green, brewed, decaffeinated",
    "Beverages, tea, green, brewed, regular",
    "Beverages, tea, green, instant, decaffeinated, lemon, unsweetened, fortified with vitamin C",
    "Beverages, tea, green, ready to drink, ginseng and honey, sweetened",
    "Beverages, tea, green, ready to drink, unsweetened",
    "Beverages, tea, green, ready-to-drink, citrus, diet, fortified with vitamin C",
    "Beverages, tea, green, ready-to-drink, diet",
    "Beverages, tea, green, ready-to-drink, sweetened",
    "Beverages, tea, herb, brewed, chamomile",
    "Beverages, tea, herb, other than chamomile, brewed",
    "Beverages, tea, hibiscus, brewed",
    "Beverages, tea, instant, decaffeinated, lemon, diet",
    "Beverages, tea, instant, decaffeinated, lemon, sweetened",
    "Beverages, tea, instant, decaffeinated, unsweetened",
    "Beverages, tea, instant, lemon, diet",
    "Beverages, tea, instant, lemon, sweetened, powder",
    "Beverages, tea, instant, lemon, sweetened, prepared with water",
    "Beverages, tea, instant, lemon, unsweetened",
    "Beverages, tea, instant, lemon, with added ascorbic acid",
    "Beverages, tea, instant, sweetened with sodium saccharin, lemon-flavored, powder",
    "Beverages, tea, instant, unsweetened, powder",
    "Beverages, tea, instant, unsweetened, prepared with water",
    "Beverages, tea, Oolong, brewed",
    "Beverages, tea, ready-to-drink, lemon, diet",
    "Beverages, The COCA-COLA company, DASANI, water, bottled, non-carbonated",
    "Beverages, The COCA-COLA company, Glaceau Vitamin Water, Revive Fruit Punch, fortified",
    "Beverages, The COCA-COLA company, Hi-C Flashin' Fruit Punch",
    "Beverages, The COCA-COLA company, Minute Maid, Lemonade",
    "Beverages, THE COCA-COLA COMPANY, NOS energy drink, Original, grape, loaded cherry, charged citrus, fortified with vitamins B6 and B12",
    "Beverages, THE COCA-COLA COMPANY, NOS Zero, energy drink, sugar-free with guarana, fortified with vitamins B6 and B12",
    "Beverages, Tropical Punch, ready-to-drink",
    "Beverages, UNILEVER, SLIMFAST Shake Mix, high protein, whey powder, 3-2-1 Plan",
    "Beverages, UNILEVER, SLIMFAST Shake Mix, powder, 3-2-1 Plan",
    "Beverages, UNILEVER, SLIMFAST, meal replacement, regular, ready-to-drink, 3-2-1 Plan",
    "Beverages, V8 SPLASH Juice Drinks, Berry Blend",
    "Beverages, V8 SPLASH Juice Drinks, Diet Berry Blend",
    "Beverages, V8 SPLASH Juice Drinks, Diet Fruit Medley",
    "Beverages, V8 SPLASH Juice Drinks, Diet Strawberry Kiwi",
    "Beverages, V8 SPLASH Juice Drinks, Diet Tropical Blend",
    "Beverages, V8 SPLASH Juice Drinks, Fruit Medley",
    "Beverages, V8 SPLASH Juice Drinks, Guava Passion Fruit",
    "Beverages, V8 SPLASH Juice Drinks, Mango Peach",
    "Beverages, V8 SPLASH Juice Drinks, Orange Pineapple",
    "Beverages, V8 SPLASH Juice Drinks, Orchard Blend",
    "Beverages, V8 SPLASH Juice Drinks, Strawberry Banana",
    "Beverages, V8 SPLASH Juice Drinks, Strawberry Kiwi",
    "Beverages, V8 SPLASH Juice Drinks, Tropical Blend",
    "Beverages, V8 SPLASH Smoothies, Peach Mango",
    "Beverages, V8 SPLASH Smoothies, Strawberry Banana",
    "Beverages, V8 SPLASH Smoothies, Tropical Colada",
    "Beverages, V8 V-FUSION Juices, Acai Berry",
    "Beverages, V8 V-FUSION Juices, Peach Mango",
    "Beverages, V8 V-FUSION Juices, Strawberry Banana",
    "Beverages, V8 V-FUSION Juices, Tropical",
    "Beverages, vegetable and fruit juice blend, 100% juice, with added vitamins A, C, E",
    "Beverages, Vegetable and fruit juice drink, reduced calorie, with low-calorie sweetener, added vitamin C",
    "Beverages, Water with added vitamins and minerals, bottles, sweetened, assorted fruit flavors",
    "Beverages, water, bottled, non-carbonated, CALISTOGA",
    "Beverages, water, bottled, non-carbonated, CRYSTAL GEYSER",
    "Beverages, water, bottled, non-carbonated, DANNON",
    "Beverages, water, bottled, non-carbonated, EVIAN",
    "Beverages, water, bottled, non-carbonated, PEPSI, AQUAFINA",
    "Beverages, water, bottled, PERRIER",
    "Beverages, water, bottled, POLAND SPRING",
    "Beverages, water, bottled, yumberry, pomegranate with anti-oxidants, zero calories",
    "Beverages, water, tap, drinking",
    "Beverages, water, tap, municipal",
    "Beverages, water, tap, well",
    "Beverages, WENDY'S, tea, ready-to-drink, unsweetened",
    "Beverages, Whey protein powder isolate",
    "Beverages, Whiskey sour mix, bottled",
    "Beverages, Whiskey sour mix, powder",
    "Beverages, Wine, non-alcoholic",
    "Beverages, yellow green colored citrus soft drink with caffeine",
    "Beverages, ZEVIA, cola",
    "Beverages, ZEVIA, cola, caffeine free",
    "Biscuits, mixed grain, refrigerated dough",
    "Biscuits, plain or buttermilk, dry mix",
    "Biscuits, plain or buttermilk, dry mix, prepared",
    "Biscuits, plain or buttermilk, frozen, baked",
    "Biscuits, plain or buttermilk, prepared from recipe",
    "Biscuits, plain or buttermilk, refrigerated dough, higher fat",
    "Biscuits, plain or buttermilk, refrigerated dough, higher fat, baked",
    "Biscuits, plain or buttermilk, refrigerated dough, lower fat",
    "Biscuits, plain or buttermilk, refrigerated dough, lower fat, baked",
    "Bison, ground, grass-fed, cooked",
    "Bison, ground, grass-fed, raw",
    "Blackberries, canned, heavy syrup, solids and liquids",
    "Blackberries, frozen, unsweetened",
    "Blackberries, raw",
    "Blackberries, wild, raw (Alaska Native)",
    "Blackberry juice, canned",
    "Blood sausage",
    "Blueberries, canned, heavy syrup, solids and liquids",
    "Blueberries, canned, light syrup, drained",
    "Blueberries, dried, sweetened",
    "Blueberries, frozen, sweetened",
    "Blueberries, frozen, unsweetened (Includes foods for USDA's Food Distribution Program)",
    "Blueberries, raw",
    "Blueberries, wild, canned, heavy syrup, drained",
    "Blueberries, wild, frozen (Alaska Native)",
    "Blueberries, wild, frozen (Includes foods for USDA's Food Distribution Program)",
    "Blueberries, wild, raw (Alaska Native)",
    "Bockwurst, pork, veal, raw",
    "Bologna, beef",
    "Bologna, beef and pork",
    "Bologna, beef and pork, low fat",
    "Bologna, beef, low fat",
    "Bologna, chicken, pork",
    "Bologna, chicken, pork, beef",
    "Bologna, chicken, turkey, pork",
    "Bologna, meat and poultry",
    "Bologna, pork",
    "Bologna, pork and turkey, lite",
    "Bologna, pork, turkey and beef",
    "Bologna, turkey",
    "Borage, cooked, boiled, drained, with salt",
    "Borage, cooked, boiled, drained, without salt",
    "Borage, raw",
    "Boysenberries, canned, heavy syrup",
    "Boysenberries, frozen, unsweetened",
    "Bratwurst, beef and pork, smoked",
    "Bratwurst, chicken, cooked",
    "Bratwurst, pork, beef and turkey, lite, smoked",
    "Bratwurst, pork, beef, link",
    "Bratwurst, pork, cooked",
    "Bratwurst, veal, cooked",
    "Braunschweiger (a liver sausage), pork",
    "Bread, banana, prepared from recipe, made with margarine",
    "Bread, blue corn, somiviki (Hopi)",
    "Bread, boston brown, canned",
    "Bread, chapati or roti, plain, commercially prepared",
    "Bread, chapati or roti, whole wheat, commercially prepared, frozen",
    "Bread, cheese",
    "Bread, cinnamon",
    "Bread, cornbread, dry mix, enriched (includes corn muffin mix)",
    "Bread, cornbread, dry mix, prepared with 2% milk, 80% margarine, and eggs",
    "Bread, cornbread, dry mix, unenriched (includes corn muffin mix)",
    "Bread, cornbread, prepared from recipe, made with low fat (2%) milk",
    "Bread, cracked-wheat",
    "Bread, crumbs, dry, grated, plain",
    "Bread, crumbs, dry, grated, seasoned",
    "Bread, egg",
    "Bread, egg, toasted",
    "Bread, french or vienna (includes sourdough)",
    "Bread, french or vienna, toasted (includes sourdough)",
    "Bread, french or vienna, whole wheat",
    "Bread, gluten-free, white, made with potato extract, rice starch, and rice flour",
    "Bread, gluten-free, white, made with rice flour, corn starch, and/or tapioca",
    "Bread, gluten-free, white, made with tapioca starch and brown rice flour",
    "Bread, gluten-free, whole grain, made with tapioca starch and brown rice flour",
    "Bread, irish soda, prepared from recipe",
    "Bread, Italian",
    "Bread, kneel down (Navajo)",
    "Bread, multi-grain (includes whole-grain)",
    "Bread, multi-grain, toasted (includes whole-grain)",
    "Bread, naan, plain, commercially prepared, refrigerated",
    "Bread, naan, whole wheat, commercially prepared, refrigerated",
    "Bread, oat bran",
    "Bread, oat bran, toasted",
    "Bread, oatmeal",
    "Bread, oatmeal, toasted",
    "Bread, pan dulce, sweet yeast bread",
    "Bread, paratha, whole wheat, commercially prepared, frozen",
    "Bread, pita, white, enriched",
    "Bread, pita, white, unenriched",
    "Bread, pita, whole-wheat",
    "Bread, potato",
    "Bread, pound cake type, pan de torta salvadoran",
    "Bread, protein (includes gluten)",
    "Bread, protein, (includes gluten), toasted",
    "Bread, pumpernickel",
    "Bread, raisin, enriched",
    "Bread, raisin, enriched, toasted",
    "Bread, raisin, unenriched",
    "Bread, reduced-calorie, oat bran",
    "Bread, reduced-calorie, oat bran, toasted",
    "Bread, reduced-calorie, oatmeal",
    "Bread, reduced-calorie, rye",
    "Bread, reduced-calorie, wheat",
    "Bread, reduced-calorie, white",
    "Bread, rice bran",
    "Bread, rice bran, toasted",
    "Bread, roll, Mexican, bollilo",
    "Bread, rye",
    "Bread, rye, toasted",
    "Bread, salvadoran sweet cheese (quesadilla salvadorena)",
    "Bread, sticks, plain",
    "Bread, stuffing, cornbread, dry mix",
    "Bread, stuffing, cornbread, dry mix, prepared",
    "Bread, stuffing, dry mix",
    "Bread, stuffing, dry mix, prepared",
    "Bread, wheat",
    "Bread, wheat, sprouted",
    "Bread, wheat, sprouted, toasted",
    "Bread, wheat, toasted",
    "Bread, white wheat",
    "Bread, white, commercially prepared (includes soft bread crumbs)",
    "Bread, white, commercially prepared, low sodium, no salt",
    "Bread, white, commercially prepared, toasted",
    "Bread, white, commercially prepared, toasted, low sodium no salt",
    "Bread, white, prepared from recipe, made with low fat (2%) milk",
    "Bread, white, prepared from recipe, made with nonfat dry milk",
    "Bread, whole-wheat, commercially prepared",
    "Bread, whole-wheat, commercially prepared, toasted",
    "Bread, whole-wheat, prepared from recipe",
    "Bread, whole-wheat, prepared from recipe, toasted",
    "Breadfruit, raw",
    "Breakfast bar, corn flake crust with fruit",
    "Breakfast bars, oats, sugar, raisins, coconut (include granola bar)",
    "Broadbeans (fava beans), mature seeds, canned",
    "Broadbeans (fava beans), mature seeds, cooked, boiled, with salt",
    "Broadbeans (fava beans), mature seeds, cooked, boiled, without salt",
    "Broadbeans (fava beans), mature seeds, raw",
    "Broadbeans, immature seeds, cooked, boiled, drained, with salt",
    "Broadbeans, immature seeds, cooked, boiled, drained, without salt",
    "Broadbeans, immature seeds, raw",
    "Broccoli raab, cooked",
    "Broccoli raab, raw",
    "Broccoli, chinese, cooked",
    "Broccoli, chinese, raw",
    "Broccoli, cooked, boiled, drained, with salt",
    "Broccoli, cooked, boiled, drained, without salt",
    "Broccoli, flower clusters, raw",
    "Broccoli, frozen, chopped, cooked, boiled, drained, with salt",
    "Broccoli, frozen, chopped, cooked, boiled, drained, without salt",
    "Broccoli, frozen, chopped, unprepared",
    "Broccoli, frozen, spears, cooked, boiled, drained, with salt",
    "Broccoli, frozen, spears, cooked, boiled, drained, without salt",
    "Broccoli, frozen, spears, unprepared (Includes foods for USDA's Food Distribution Program)",
    "Broccoli, leaves, raw",
    "Broccoli, raw",
    "Broccoli, stalks, raw",
    "Brussels sprouts, cooked, boiled, drained, with salt",
    "Brussels sprouts, cooked, boiled, drained, without salt",
    "Brussels sprouts, frozen, cooked, boiled, drained, with salt",
    "Brussels sprouts, frozen, cooked, boiled, drained, without salt",
    "Brussels sprouts, frozen, unprepared",
    "Brussels sprouts, raw",
    "Buckwheat",
    "Buckwheat flour, whole-groat",
    "Buckwheat groats, roasted, cooked",
    "Buckwheat groats, roasted, dry",
    "Buffalo, free range, top round steak, cooked (Shoshone Bannock)",
    "Buffalo, free range, top round steak, raw (Shoshone Bannock)",
    "Bulgur, cooked",
    "Bulgur, dry",
    "Burdock root, cooked, boiled, drained, with salt",
    "Burdock root, cooked, boiled, drained, without salt",
    "Burdock root, raw",
    "BURGER KING, Cheeseburger",
    "BURGER KING, Chicken Strips",
    "BURGER KING, CROISSAN'WICH with Egg and Cheese",
    "BURGER KING, CROISSAN'WICH with Sausage and Cheese",
    "BURGER KING, CROISSAN'WICH with Sausage, Egg and Cheese",
    "BURGER KING, Double Cheeseburger",
    "BURGER KING, DOUBLE WHOPPER, no cheese",
    "BURGER KING, DOUBLE WHOPPER, with cheese",
    "BURGER KING, french fries",
    "BURGER KING, french toast sticks",
    "BURGER KING, Hamburger",
    "BURGER KING, Hash Brown Rounds",
    "BURGER KING, Onion Rings",
    "BURGER KING, Original Chicken Sandwich",
    "BURGER KING, Premium Fish Sandwich",
    "BURGER KING, Vanilla Shake",
    "BURGER KING, WHOPPER, no cheese",
    "BURGER KING, WHOPPER, with cheese",
    "Burrito, bean and cheese, frozen",
    "Burrito, beef and bean, frozen",
    "Burrito, beef and bean, microwaved",
    "Butter oil, anhydrous",
    "Butter replacement, without fat, powder",
    "Butter, Clarified butter (ghee)",
    "Butter, light, stick, with salt",
    "Butter, light, stick, without salt",
    "Butter, salted",
    "Butter, whipped, with salt",
    "Butter, without salt",
    "Butterbur, (fuki), raw",
    "Butterbur, canned",
    "Butterbur, cooked, boiled, drained, with salt",
    "Butterbur, cooked, boiled, drained, without salt",
    "Cabbage, chinese (pak-choi), cooked, boiled, drained, with salt",
    "Cabbage, chinese (pak-choi), cooked, boiled, drained, without salt",
    "Cabbage, chinese (pak-choi), raw",
    "Cabbage, chinese (pe-tsai), cooked, boiled, drained, with salt",
    "Cabbage, chinese (pe-tsai), cooked, boiled, drained, without salt",
  "Cabbage, chinese (pe-tsai), raw",
  "Cabbage, common (danish, domestic, and pointed types), freshly harvest, raw",
  "Cabbage, common (danish, domestic, and pointed types), stored, raw",
  "Cabbage, common, cooked, boiled, drained, with salt",
  "Cabbage, cooked, boiled, drained, without salt",
  "Cabbage, japanese style, fresh, pickled",
  "Cabbage, kimchi",
  "Cabbage, mustard, salted",
  "Cabbage, napa, cooked",
  "Cabbage, raw",
  "Cabbage, red, cooked, boiled, drained, with salt",
  "Cabbage, red, cooked, boiled, drained, without salt",
  "Cabbage, red, raw",
  "Cabbage, savoy, cooked, boiled, drained, with salt",
  "Cabbage, savoy, cooked, boiled, drained, without salt",
  "Cabbage, savoy, raw",
  "Cake, angelfood, commercially prepared",
  "Cake, angelfood, dry mix, prepared",
  "Cake, boston cream pie, commercially prepared",
  "Cake, cheesecake, commercially prepared",
  "Cake, cheesecake, prepared from mix, no-bake type",
  "Cake, cherry fudge with chocolate frosting",
  "Cake, chocolate, commercially prepared with chocolate frosting, in-store bakery",
  "Cake, chocolate, prepared from recipe without frosting",
  "Cake, coffeecake, cheese",
  "Cake, coffeecake, cinnamon with crumb topping, commercially prepared, enriched",
  "Cake, coffeecake, cinnamon with crumb topping, commercially prepared, unenriched",
  "Cake, coffeecake, cinnamon with crumb topping, dry mix, prepared",
  "Cake, coffeecake, creme-filled with chocolate frosting",
  "Cake, coffeecake, fruit",
  "Cake, fruitcake, commercially prepared",
  "Cake, gingerbread, dry mix",
  "Cake, gingerbread, prepared from recipe",
  "Cake, pineapple upside-down, prepared from recipe",
  "Cake, pound, Bimbo Bakeries USA, Panque Casero, home baked style",
  "Cake, pound, commercially prepared, butter (includes fresh and frozen)",
  "Cake, pound, commercially prepared, fat-free",
  "Cake, pound, commercially prepared, other than all butter, enriched",
  "Cake, pound, commercially prepared, other than all butter, unenriched",
  "Cake, pudding-type, carrot, dry mix",
  "Cake, pudding-type, chocolate, dry mix",
  "Cake, pudding-type, white, enriched, dry mix",
  "Cake, pudding-type, white, unenriched, dry mix",
  "Cake, pudding-type, yellow, dry mix",
  "Cake, shortcake, biscuit-type, prepared from recipe",
  "Cake, snack cakes, creme-filled, chocolate with frosting",
  "Cake, snack cakes, creme-filled, chocolate with frosting, low-fat, with added fiber",
  "Cake, snack cakes, creme-filled, sponge",
  "Cake, snack cakes, not chocolate, with icing or filling, low-fat, with added fiber",
  "Cake, sponge, commercially prepared",
  "Cake, sponge, prepared from recipe",
  "Cake, white, dry mix, special dietary (includes lemon-flavored)",
  "Cake, white, prepared from recipe with coconut frosting",
  "Cake, white, prepared from recipe without frosting",
  "Cake, yellow, commercially prepared, with chocolate frosting, in-store bakery",
  "Cake, yellow, commercially prepared, with vanilla frosting",
  "Cake, yellow, enriched, dry mix",
  "Cake, yellow, light, dry mix",
  "Cake, yellow, prepared from recipe without frosting",
  "Cake, yellow, unenriched, dry mix",
  "CAMPBELL'S CHUNKY, Classic Chicken Noodle Soup",
  "CAMPBELL'S CHUNKY, Hearty Beef Barley Soup",
  "CAMPBELL'S CHUNKY, New England Clam Chowder",
  "CAMPBELL'S CHUNKY, Old Fashioned Vegetable Beef Soup",
  "CAMPBELL'S, Chicken Noodle Soup, condensed",
  "CAMPBELL'S, Cream of Mushroom Soup, condensed",
  "CAMPBELL'S, Tomato Soup, condensed",
  "Canada Goose, breast meat only, skinless, raw",
  "Canadian bacon, cooked, pan-fried",
  "Canadian bacon, unprepared",
  "Candied fruit",
  "Candies, 5TH AVENUE Candy Bar",
  "Candies, ALMOND JOY Candy Bar",
  "Candies, butterscotch",
  "Candies, CARAMELLO Candy Bar",
  "Candies, caramels",
  "Candies, caramels, chocolate-flavor roll",
  "Candies, carob, unsweetened",
  "Candies, chocolate covered, caramel with nuts",
  "Candies, chocolate covered, low sugar or low calorie",
  "Candies, chocolate, dark, NFS (45-59% cacao solids 90%; 60-69% cacao solids 5%; 70-85% cacao solids 5%)",
  "Candies, coconut bar, not chocolate covered",
  "Candies, confectioner's coating, butterscotch",
  "Candies, confectioner's coating, peanut butter",
  "Candies, confectioner's coating, yogurt",
  "Candies, crispy bar with peanut butter filling",
  "Candies, dark chocolate coated coffee beans",
  "Candies, divinity, prepared-from-recipe",
  "Candies, fondant, prepared-from-recipe",
  "Candies, fruit snacks, with high vitamin C",
  "Candies, fudge, chocolate marshmallow, prepared-from-recipe",
  "Candies, fudge, chocolate marshmallow, with nuts, prepared-by-recipe",
  "Candies, fudge, chocolate, prepared-from-recipe",
  "Candies, fudge, chocolate, with nuts, prepared-from-recipe",
  "Candies, fudge, peanut butter, prepared-from-recipe",
  "Candies, fudge, vanilla with nuts",
  "Candies, fudge, vanilla, prepared-from-recipe",
  "Candies, gum drops, no sugar or low calorie (sorbitol)",
  "Candies, gumdrops, starch jelly pieces",
  "Candies, halavah, plain",
  "Candies, hard",
  "Candies, hard, dietetic or low calorie (sorbitol)",
  "Candies, HEATH BITES",
  "Candies, HERSHEY'S GOLDEN ALMOND SOLITAIRES",
  "Candies, HERSHEY'S MILK CHOCOLATE WITH ALMOND BITES",
  "Candies, HERSHEY'S POT OF GOLD Almond Bar",
  "Candies, HERSHEY'S SKOR Toffee Bar",
  "Candies, HERSHEY'S, ALMOND JOY BITES",
  "Candies, HERSHEY, KIT KAT BIG KAT Bar",
  "Candies, HERSHEY, REESESTICKS crispy wafers, peanut butter, milk chocolate",
  "Candies, HERSHEYS, PAYDAY Bar",
  "Candies, honey-combed, with peanut butter",
  "Candies, jellybeans",
  "Candies, KIT KAT Wafer Bar",
  "Candies, KRACKEL Chocolate Bar",
  "Candies, M&M MARS 3 MUSKETEERS Truffle Crisp",
  "Candies, M&M MARS Pretzel Chocolate Candies",
  "Candies, MARS SNACKFOOD US, 3 MUSKETEERS Bar",
  "Candies, MARS SNACKFOOD US, COCOAVIA Blueberry and Almond Chocolate Bar",
  "Candies, MARS SNACKFOOD US, COCOAVIA Chocolate Bar",
  "Candies, MARS SNACKFOOD US, COCOAVIA Chocolate Covered Almonds",
  "Candies, MARS SNACKFOOD US, COCOAVIA Crispy Chocolate Bar",
  "Candies, MARS SNACKFOOD US, DOVE Dark Chocolate",
  "Candies, MARS SNACKFOOD US, DOVE Milk Chocolate",
  "Candies, MARS SNACKFOOD US, M&M's Almond Chocolate Candies",
  "Candies, MARS SNACKFOOD US, M&M's Crispy Chocolate Candies",
  "Candies, MARS SNACKFOOD US, M&M's Milk Chocolate Candies",
  "Candies, MARS SNACKFOOD US, M&M's MINIs Milk Chocolate Candies",
  "Candies, MARS SNACKFOOD US, M&M's Peanut Butter Chocolate Candies",
  "Candies, MARS SNACKFOOD US, M&M's Peanut Chocolate Candies",
  "Candies, MARS SNACKFOOD US, MARS Almond Bar",
  "Candies, MARS SNACKFOOD US, MILKY WAY Bar",
  "Candies, MARS SNACKFOOD US, MILKY WAY Caramels, milk chocolate covered",
  "Candies, MARS SNACKFOOD US, MILKY WAY Caramels. dark chocolate covered",
  "Candies, MARS SNACKFOOD US, MILKY WAY Midnight Bar",
  "Candies, MARS SNACKFOOD US, POP'ABLES 3 MUSKETEERS Brand Bite Size Candies",
  "Candies, MARS SNACKFOOD US, POP'ABLES MILKY WAY Brand Bite Size Candies",
  "Candies, MARS SNACKFOOD US, POP'ABLES SNICKERS Brand Bite Size Candies",
  "Candies, MARS SNACKFOOD US, SKITTLES Original Bite Size Candies",
  "Candies, MARS SNACKFOOD US, SKITTLES Sours Original",
  "Candies, MARS SNACKFOOD US, SKITTLES Tropical Bite Size Candies",
  "Candies, MARS SNACKFOOD US, SKITTLES Wild Berry Bite Size Candies",
  "Candies, MARS SNACKFOOD US, SNICKERS Almond bar",
  "Candies, MARS SNACKFOOD US, SNICKERS Bar",
  "Candies, MARS SNACKFOOD US, SNICKERS CRUNCHER",
  "Candies, MARS SNACKFOOD US, SNICKERS MUNCH bar",
  "Candies, MARS SNACKFOOD US, STARBURST Fruit Chews, Fruit and Creme",
  "Candies, MARS SNACKFOOD US, STARBURST Fruit Chews, Original fruits",
  "Candies, MARS SNACKFOOD US, STARBURST Fruit Chews, Tropical fruits",
  "Candies, MARS SNACKFOOD US, STARBURST Sour Fruit Chews",
  "Candies, MARS SNACKFOOD US, TWIX Caramel Cookie Bars",
  "Candies, MARS SNACKFOOD US, TWIX chocolate fudge cookie bars",
  "Candies, MARS SNACKFOOD US, TWIX Peanut Butter Cookie Bars",
  "Candies, marshmallows",
  "Candies, milk chocolate",
  "Candies, milk chocolate coated coffee beans",
  "Candies, milk chocolate coated peanuts",
  "Candies, milk chocolate coated raisins",
  "Candies, milk chocolate, with almonds",
  "Candies, milk chocolate, with rice cereal",
  "Candies, MOUNDS Candy Bar",
  "Candies, MR. GOODBAR Chocolate Bar",
  "Candies, NESTLE, 100 GRAND Bar",
  "Candies, NESTLE, AFTER EIGHT Mints",
  "Candies, NESTLE, BABY RUTH Bar",
  "Candies, NESTLE, BIT-O'-HONEY Candy Chews",
  "Candies, NESTLE, BUTTERFINGER Bar",
  "Candies, NESTLE, BUTTERFINGER Crisp",
  "Candies, NESTLE, CHUNKY Bar",
  "Candies, NESTLE, CRUNCH Bar and Dessert Topping",
  "Candies, NESTLE, GOOBERS Chocolate Covered Peanuts",
  "Candies, NESTLE, OH HENRY! Bar",
  "Candies, NESTLE, RAISINETS Chocolate Covered Raisins",
  "Candies, nougat, with almonds",
  "Candies, peanut bar",
  "Candies, peanut brittle, prepared-from-recipe",
  "Candies, praline, prepared-from-recipe",
  "Candies, REESE'S BITES",
  "Candies, REESE's Fast Break, milk chocolate, peanut butter, soft nougats, candy bar",
  "Candies, REESE'S NUTRAGEOUS Candy Bar",
  "Candies, REESE'S Peanut Butter Cups",
  "Candies, REESE'S PIECES Candy",
  "Candies, REESE'S, FAST BREAK, milk chocolate peanut butter and soft nougats",
  "Candies, ROLO Caramels in Milk Chocolate",
  "Candies, semisweet chocolate",
  "Candies, semisweet chocolate, made with butter",
  "Candies, sesame crunch",
  "Candies, soft fruit and nut squares",
  "Candies, SPECIAL DARK Chocolate Bar",
  "Candies, sugar-coated almonds",
  "Candies, sweet chocolate",
  "Candies, sweet chocolate coated fondant",
  "Candies, SYMPHONY Milk Chocolate Bar",
  "Candies, taffy, prepared-from-recipe",
  "Candies, Tamarind",
  "Candies, TOBLERONE, milk chocolate with honey and almond nougat",
  "Candies, toffee, prepared-from-recipe",
  "Candies, TOOTSIE ROLL, chocolate-flavor roll",
  "Candies, truffles, prepared-from-recipe",
  "Candies, TWIZZLERS CHERRY BITES",
  "Candies, TWIZZLERS NIBS CHERRY BITS",
  "Candies, TWIZZLERS Strawberry Twists Candy",
  "Candies, WHATCHAMACALLIT Candy Bar",
  "Candies, white chocolate",
  "Candies, YORK BITES",
  "Candies, YORK Peppermint Pattie",
  "Capers, canned",
  "Carambola, (starfruit), raw",
  "Carbonated beverage, chocolate-flavored soda",
  "Carbonated beverage, cream soda",
  "Carbonated beverage, low calorie, other than cola or pepper, with sodium saccharin, without caffeine",
  "Cardoon, cooked, boiled, drained, with salt",
  "Cardoon, cooked, boiled, drained, without salt",
  "Cardoon, raw",
  "Caribou, bone marrow, raw (Alaska Native)",
  "Caribou, eye, raw (Alaska Native)",
  "Caribou, hind quarter meat, raw (Alaska Native)",
  "Caribou, hind quarter, meat, cooked (Alaska Native)",
  "Caribou, liver, raw (Alaska Native)",
  "Caribou, rump meat, half dried (Alaska Native)",
  "Caribou, shoulder meat, dried (Alaska Native)",
  "Caribou, tongue, raw (Alaska Native)",
  "Carissa, (natal-plum), raw",
  "Carob flour",
  "CARRABBA'S ITALIAN GRILL, cheese ravioli with marinara sauce",
  "CARRABBA'S ITALIAN GRILL, chicken parmesan without cavatappi pasta",
  "CARRABBA'S ITALIAN GRILL, lasagne",
  "CARRABBA'S ITALIAN GRILL, spaghetti with meat sauce",
  "CARRABBA'S ITALIAN GRILL, spaghetti with pomodoro sauce",
  "Carrot juice, canned",
  "Carrot, dehydrated",
  "Carrots, baby, raw",
  "Carrots, canned, no salt added, drained solids",
  "Carrots, canned, no salt added, solids and liquids",
  "Carrots, canned, regular pack, drained solids",
  "Carrots, canned, regular pack, solids and liquids",
  "Carrots, cooked, boiled, drained, with salt",
  "Carrots, cooked, boiled, drained, without salt",
  "Carrots, frozen, cooked, boiled, drained, with salt",
  "Carrots, frozen, cooked, boiled, drained, without salt",
  "Carrots, frozen, unprepared (Includes foods for USDA's Food Distribution Program)",
  "Carrots, raw",
  "Cassava, raw",
  "Catsup",
  "Catsup, low sodium",
  "Cattail, Narrow Leaf Shoots (Northern Plains Indians)",
  "Cauliflower, cooked, boiled, drained, with salt",
  "Cauliflower, cooked, boiled, drained, without salt",
  "Cauliflower, frozen, cooked, boiled, drained, with salt",
  "Cauliflower, frozen, cooked, boiled, drained, without salt",
  "Cauliflower, frozen, unprepared",
  "Cauliflower, green, cooked, no salt added",
  "Cauliflower, green, cooked, with salt",
  "Cauliflower, green, raw",
  "Cauliflower, raw",
  "Celeriac, cooked, boiled, drained, with salt",
  "Celeriac, cooked, boiled, drained, without salt",
  "Celeriac, raw",
  "Celery, cooked, boiled, drained, with salt",
  "Celery, cooked, boiled, drained, without salt",
  "Celery, raw",
  "Celtuce, raw",
  "Cereals ready-to-eat, ALPEN",
  "Cereals ready-to-eat, BARBARA'S PUFFINS, original",
  "Cereals ready-to-eat, chocolate-flavored frosted puffed corn",
  "Cereals ready-to-eat, FAMILIA",
  "Cereals ready-to-eat, frosted oat cereal with marshmallows",
  "Cereals ready-to-eat, GENERAL MILLS, CHEERIOS",
  "Cereals ready-to-eat, granola, homemade",
  "Cereals ready-to-eat, HEALTH VALLEY, FIBER 7 Flakes",
  "Cereals ready-to-eat, MALT-O-MEAL, Apple ZINGS",
  "Cereals ready-to-eat, MALT-O-MEAL, BERRY COLOSSAL CRUNCH",
  "Cereals ready-to-eat, MALT-O-MEAL, Blueberry MUFFIN TOPS Cereal",
  "Cereals ready-to-eat, MALT-O-MEAL, CHOCOLATE MARSHMALLOW MATEYS",
  "Cereals ready-to-eat, MALT-O-MEAL, CINNAMON TOASTERS",
  "Cereals ready-to-eat, MALT-O-MEAL, COCO-ROOS",
  "Cereals ready-to-eat, MALT-O-MEAL, Cocoa DYNO-BITES",
  "Cereals ready-to-eat, MALT-O-MEAL, COLOSSAL CRUNCH",
  "Cereals ready-to-eat, MALT-O-MEAL, CORN BURSTS",
  "Cereals ready-to-eat, MALT-O-MEAL, Crispy Rice",
  "Cereals ready-to-eat, MALT-O-MEAL, Frosted Flakes",
  "Cereals ready-to-eat, MALT-O-MEAL, Frosted Mini SPOONERS",
  "Cereals ready-to-eat, MALT-O-MEAL, Fruity DYNO-BITES",
  "Cereals ready-to-eat, MALT-O-MEAL, GOLDEN PUFFS",
  "Cereals ready-to-eat, MALT-O-MEAL, Honey BUZZERS",
  "Cereals ready-to-eat, MALT-O-MEAL, HONEY GRAHAM SQUARES",
  "Cereals ready-to-eat, MALT-O-MEAL, Honey Nut SCOOTERS",
  "Cereals ready-to-eat, MALT-O-MEAL, MARSHMALLOW MATEYS",
  "Cereals ready-to-eat, MALT-O-MEAL, OAT BLENDERS with honey",
  "Cereals ready-to-eat, MALT-O-MEAL, OAT BLENDERS with honey & almonds",
  "Cereals ready-to-eat, MALT-O-MEAL, Raisin Bran Cereal",
  "Cereals ready-to-eat, MALT-O-MEAL, TOOTIE FRUITIES",
  "Cereals ready-to-eat, MOM'S BEST, Honey Nut TOASTY O'S",
  "Cereals ready-to-eat, MOM'S BEST, Sweetened WHEAT-FULS",
  "Cereals ready-to-eat, NATURE'S PATH, Organic FLAX PLUS flakes",
  "Cereals ready-to-eat, NATURE'S PATH, Organic FLAX PLUS, Pumpkin Granola",
  "Cereals ready-to-eat, OAT BRAN FLAKES, HEALTH VALLEY",
  "Cereals ready-to-eat, POST Bran Flakes",
  "Cereals ready-to-eat, POST GREAT GRAINS Banana Nut Crunch",
  "Cereals ready-to-eat, POST GREAT GRAINS Cranberry Almond Crunch",
  "Cereals ready-to-eat, POST HONEY BUNCHES OF OATS with cinnamon bunches",
  "Cereals ready-to-eat, POST Raisin Bran Cereal",
  "Cereals ready-to-eat, POST SELECTS Blueberry Morning",
  "Cereals ready-to-eat, POST SELECTS Maple Pecan Crunch",
  "Cereals ready-to-eat, POST, ALPHA-BITS",
  "Cereals ready-to-eat, POST, COCOA PEBBLES",
  "Cereals ready-to-eat, POST, FRUITY PEBBLES",
  "Cereals ready-to-eat, POST, GOLDEN CRISP",
  "Cereals ready-to-eat, POST, GRAPE-NUTS Cereal",
  "Cereals ready-to-eat, POST, GRAPE-NUTS Flakes",
  "Cereals ready-to-eat, POST, GREAT GRAINS Crunchy Pecan Cereal",
  "Cereals ready-to-eat, POST, GREAT GRAINS, Raisin, Date & Pecan",
  "Cereals ready-to-eat, POST, HONEY BUNCHES OF OATS with vanilla bunches",
  "Cereals ready-to-eat, POST, HONEY BUNCHES OF OATS, honey roasted",
  "Cereals ready-to-eat, POST, HONEY BUNCHES OF OATS, pecan bunches",
  "Cereals ready-to-eat, POST, HONEY BUNCHES OF OATS, with almonds",
  "Cereals ready-to-eat, POST, HONEY BUNCHES OF OATS, with real strawberries",
  "Cereals ready-to-eat, POST, Honey Nut Shredded Wheat",
  "Cereals ready-to-eat, POST, Honeycomb Cereal",
  "Cereals ready-to-eat, POST, Shredded Wheat n' Bran, spoon-size",
  "Cereals ready-to-eat, POST, Shredded Wheat, lightly frosted, spoon-size",
  "Cereals ready-to-eat, POST, Shredded Wheat, original big biscuit",
  "Cereals ready-to-eat, POST, Shredded Wheat, original spoon-size",
  "Cereals ready-to-eat, Post, Waffle Crisp",
  "Cereals ready-to-eat, QUAKER Oatmeal Squares, Golden Maple",
  "Cereals ready-to-eat, QUAKER WHOLE HEARTS oat cereal",
  "Cereals ready-to-eat, QUAKER, 100% Natural Granola, Oats, Wheat and Honey",
  "Cereals ready-to-eat, QUAKER, CAP'N CRUNCH",
  "Cereals ready-to-eat, QUAKER, CAP'N CRUNCH with CRUNCHBERRIES",
  "Cereals ready-to-eat, QUAKER, CAP'N CRUNCH'S Halloween Crunch",
  "Cereals ready-to-eat, QUAKER, Cap'n Crunch's OOPS! All Berries Cereal",
  "Cereals ready-to-eat, QUAKER, CAP'N CRUNCH'S PEANUT BUTTER CRUNCH",
  "Cereals ready-to-eat, QUAKER, Christmas Crunch",
  "Cereals ready-to-eat, QUAKER, HONEY GRAHAM OH!S",
  "Cereals ready-to-eat, QUAKER, KING VITAMAN",
  "Cereals ready-to-eat, QUAKER, Low Fat 100% Natural Granola with Raisins",
  "Cereals ready-to-eat, QUAKER, Maple Brown Sugar LIFE Cereal",
  "Cereals ready-to-eat, QUAKER, MOTHER'S Cinnamon Oat Crunch",
  "Cereals ready-to-eat, QUAKER, MOTHER'S COCOA BUMPERS",
  "Cereals ready-to-eat, QUAKER, MOTHER'S GRAHAM BUMPERS",
  "Cereals ready-to-eat, QUAKER, MOTHER'S PEANUT BUTTER BUMPERS Cereal",
  "Cereals ready-to-eat, QUAKER, MOTHER'S Toasted Oat Bran cereal",
  "Cereals ready-to-eat, QUAKER, Natural Granola Apple Cranberry Almond",
  "Cereals ready-to-eat, QUAKER, Oatmeal Squares",
  "Cereals ready-to-eat, QUAKER, Oatmeal Squares, cinnamon",
  "Cereals ready-to-eat, QUAKER, QUAKER 100% Natural Granola with Oats, Wheat, Honey, and Raisins",
  "Cereals ready-to-eat, QUAKER, QUAKER CRUNCHY BRAN",
  "Cereals ready-to-eat, QUAKER, QUAKER Honey Graham LIFE Cereal",
  "Cereals ready-to-eat, QUAKER, QUAKER OAT CINNAMON LIFE",
  "Cereals ready-to-eat, QUAKER, QUAKER OAT LIFE, plain",
  "Cereals ready-to-eat, QUAKER, QUAKER Puffed Rice",
  "Cereals ready-to-eat, QUAKER, QUAKER Puffed Wheat",
  "Cereals ready-to-eat, QUAKER, Shredded Wheat, bagged cereal",
  "Cereals ready-to-eat, QUAKER, SWEET CRUNCH/QUISP",
  "Cereals ready-to-eat, QUAKER, Toasted Multigrain Crisps",
  "Cereals ready-to-eat, RALSTON Corn Biscuits",
  "Cereals ready-to-eat, RALSTON Corn Flakes",
  "Cereals ready-to-eat, RALSTON CRISP RICE",
  "Cereals ready-to-eat, RALSTON Crispy Hexagons",
  "Cereals ready-to-eat, RALSTON Enriched Wheat Bran flakes",
  "Cereals ready-to-eat, RALSTON TASTEEOS",
  "Cereals ready-to-eat, rice, puffed, fortified",
  "Cereals ready-to-eat, SUN COUNTRY, KRETSCHMER Honey Crunch Wheat Germ",
  "Cereals ready-to-eat, SUN COUNTRY, KRETSCHMER Toasted Wheat Bran",
  "Cereals ready-to-eat, SUN COUNTRY, KRETSCHMER Wheat Germ, Regular",
  "Cereals ready-to-eat, UNCLE SAM CEREAL",
  "Cereals ready-to-eat, WEETABIX whole grain cereal",
  "Cereals ready-to-eat, wheat and bran, presweetened with nuts and fruits",
  "Cereals ready-to-eat, wheat germ, toasted, plain",
  "Cereals ready-to-eat, wheat, puffed, fortified",
  "Cereals, corn grits, white, regular and quick, enriched, cooked with water, with salt",
  "Cereals, corn grits, white, regular and quick, enriched, cooked with water, without salt",
  "Cereals, corn grits, white, regular and quick, enriched, dry",
  "Cereals, corn grits, yellow, regular and quick, enriched, cooked with water, without salt",
  "Cereals, corn grits, yellow, regular and quick, unenriched, dry",
  "Cereals, corn grits, yellow, regular, quick, enriched, cooked with water, with salt",
  "Cereals, CREAM OF RICE, cooked with water, with salt",
  "Cereals, CREAM OF RICE, dry",
  "Cereals, CREAM OF WHEAT, 1 minute cook time, cooked with water, microwaved, without salt",
  "Cereals, CREAM OF WHEAT, 1 minute cook time, cooked with water, stove-top, without salt",
  "Cereals, CREAM OF WHEAT, 1 minute cook time, dry",
  "Cereals, CREAM OF WHEAT, 2 1/2 minute cook time, cooked with water, microwaved, without salt",
  "Cereals, CREAM OF WHEAT, 2 1/2 minute cook time, cooked with water, stove-top, without salt",
  "Cereals, CREAM OF WHEAT, 2 1/2 minute cook time, dry",
  "Cereals, CREAM OF WHEAT, instant, dry",
  "Cereals, CREAM OF WHEAT, instant, prepared with water, without salt",
  "Cereals, CREAM OF WHEAT, regular (10 minute), cooked with water, with salt",
  "Cereals, CREAM OF WHEAT, regular (10 minute), cooked with water, without salt",
  "Cereals, CREAM OF WHEAT, regular, 10 minute cooking, dry",
  "Cereals, farina, enriched, assorted brands including CREAM OF WHEAT, quick (1-3 minutes), cooked with water, without salt",
  "Cereals, farina, enriched, assorted brands including CREAM OF WHEAT, quick (1-3 minutes), dry",
  "Cereals, farina, enriched, cooked with water, with salt",
  "Cereals, farina, unenriched, dry",
  "Cereals, MALT-O-MEAL, chocolate, dry",
  "Cereals, MALT-O-MEAL, chocolate, prepared with water, without salt",
  "Cereals, MALT-O-MEAL, Farina Hot Wheat Cereal, dry",
  "Cereals, MALT-O-MEAL, Maple & Brown Sugar Hot Wheat Cereal, dry",
  "Cereals, MALT-O-MEAL, original, plain, dry",
  "Cereals, MALT-O-MEAL, original, plain, prepared with water, without salt",
  "Cereals, Oat Bran, QUAKER, QUAKER/MOTHER'S Oat Bran, prepared with water, salt",
  "Cereals, oats, instant, fortified, maple and brown sugar, dry",
  "Cereals, oats, instant, fortified, plain, dry",
  "Cereals, oats, instant, fortified, plain, prepared with water (boiling water added or microwaved)",
  "Cereals, oats, instant, fortified, with cinnamon and spice, dry",
  "Cereals, oats, instant, fortified, with cinnamon and spice, prepared with water",
  "Cereals, oats, instant, fortified, with raisins and spice, prepared with water",
  "Cereals, oats, regular and quick and instant, unenriched, cooked with water (includes boiling and microwaving), with salt",
  "Cereals, oats, regular and quick, not fortified, dry",
  "Cereals, oats, regular and quick, unenriched, cooked with water (includes boiling and microwaving), without salt",
  "Cereals, QUAKER, corn grits, instant, cheddar cheese flavor, dry",
  "Cereals, QUAKER, corn grits, instant, plain, dry",
  "Cereals, QUAKER, corn grits, instant, plain, prepared (microwaved or boiling water added), without salt",
  "Cereals, QUAKER, hominy grits, white, quick, dry",
  "Cereals, QUAKER, hominy grits, white, regular, dry",
  "Cereals, QUAKER, Instant Grits Product with American Cheese Flavor, dry",
  "Cereals, QUAKER, Instant Grits, Butter flavor, dry",
  "Cereals, QUAKER, Instant Grits, Country Bacon flavor, dry",
  "Cereals, QUAKER, Instant Grits, Ham 'n' Cheese flavor, dry",
  "Cereals, QUAKER, Instant Grits, Redeye Gravy & Country Ham flavor, dry",
  "Cereals, QUAKER, Instant Oatmeal Organic, Regular",
  "Cereals, QUAKER, Instant Oatmeal, Apple and Cinnamon, reduced sugar",
  "Cereals, QUAKER, Instant Oatmeal, apples and cinnamon, dry",
  "Cereals, QUAKER, Instant Oatmeal, Banana Bread, dry",
  "Cereals, QUAKER, Instant Oatmeal, Cinnamon Spice, reduced sugar",
  "Cereals, QUAKER, Instant Oatmeal, Cinnamon Swirl, high fiber",
  "Cereals, QUAKER, Instant Oatmeal, Cinnamon-Spice, dry",
  "Cereals, QUAKER, Instant Oatmeal, DINOSAUR EGGS, Brown Sugar, dry",
  "Cereals, QUAKER, Instant Oatmeal, fruit and cream variety, dry",
  "Cereals, QUAKER, Instant Oatmeal, fruit and cream, variety of flavors, reduced sugar",
  "Cereals, QUAKER, Instant Oatmeal, maple and brown sugar, dry",
  "Cereals, QUAKER, Instant Oatmeal, Raisin and Spice, dry",
  "Cereals, QUAKER, Instant Oatmeal, raisins, dates and walnuts, dry",
  "Cereals, QUAKER, Instant Oatmeal, weight control, cinnamon",
  "Cereals, QUAKER, Oat Bran, QUAKER/MOTHER'S Oat Bran, dry",
  "Cereals, QUAKER, Oat Bran, QUAKER/MOTHER'S Oat Bran, prepared with water, no salt",
  "Cereals, QUAKER, oatmeal, REAL MEDLEYS, apple walnut, dry",
  "Cereals, QUAKER, oatmeal, REAL MEDLEYS, blueberry hazelnut, dry",
  "Cereals, QUAKER, oatmeal, REAL MEDLEYS, cherry pistachio, dry",
  "Cereals, QUAKER, oatmeal, REAL MEDLEYS, peach almond, dry",
  "Cereals, QUAKER, oatmeal, REAL MEDLEYS, summer berry, dry",
  "Cereals, QUAKER, QUAKER MultiGrain Oatmeal, dry",
  "Cereals, QUAKER, QUAKER MultiGrain Oatmeal, prepared with water, no salt",
  "Cereals, QUAKER, QUAKER MultiGrain Oatmeal, prepared with water, salt",
  "Cereals, QUAKER, Quick Oats with Iron, Dry",
  "Cereals, QUAKER, Quick Oats, Dry",
  "Cereals, QUAKER, Weight Control Instant Oatmeal, banana bread",
  "Cereals, QUAKER, Weight Control Instant Oatmeal, maple and brown sugar",
  "Cereals, QUAKER, Whole Wheat Natural Cereal, dry",
  "Cereals, ready-to-eat, MALT-O-MEAL, Blueberry Mini SPOONERS",
  "Cereals, WHEATENA, cooked with water",
  "Cereals, WHEATENA, cooked with water, with salt",
  "Cereals, WHEATENA, dry",
  "Cereals, whole wheat hot natural cereal, cooked with water, with salt",
  "Cereals, whole wheat hot natural cereal, cooked with water, without salt",
  "Cereals, whole wheat hot natural cereal, dry",
  "Chard, swiss, cooked, boiled, drained, with salt",
  "Chard, swiss, cooked, boiled, drained, without salt",
  "Chard, swiss, raw",
  "Chayote, fruit, cooked, boiled, drained, with salt",
  "Chayote, fruit, cooked, boiled, drained, without salt",
  "Chayote, fruit, raw",
  "Cheese food, cold pack, American",
  "Cheese food, pasteurized process, American, vitamin D fortified",
  "Cheese food, pasteurized process, American, without added vitamin D",
  "Cheese food, pasteurized process, swiss",
  "Cheese product, pasteurized process, American, reduced fat, fortified with vitamin D",
  "Cheese product, pasteurized process, American, vitamin D fortified",
  "Cheese puffs and twists, corn based, baked, low fat",
  "Cheese sauce, prepared from recipe",
  "Cheese spread, American or Cheddar cheese base, reduced fat",
  "Cheese spread, cream cheese base",
  "Cheese spread, pasteurized process, American",
  "Cheese substitute, mozzarella",
  "Cheese, american cheddar, imitation",
  "Cheese, American, nonfat or fat free",
  "Cheese, blue",
  "Cheese, brick",
  "Cheese, brie",
  "Cheese, camembert",
  "Cheese, caraway",
  "Cheese, cheddar (Includes foods for USDA's Food Distribution Program)",
  "Cheese, cheddar, nonfat or fat free",
  "Cheese, cheddar, reduced fat (Includes foods for USDA's Food Distribution Program)",
  "Cheese, cheddar, sharp, sliced",
  "Cheese, cheshire",
  "Cheese, colby",
  "Cheese, cottage, creamed, large or small curd",
  "Cheese, cottage, creamed, with fruit",
  "Cheese, cottage, lowfat, 1% milkfat",
  "Cheese, cottage, lowfat, 1% milkfat, lactose reduced",
  "Cheese, cottage, lowfat, 1% milkfat, no sodium added",
  "Cheese, cottage, lowfat, 1% milkfat, with vegetables",
  "Cheese, cottage, lowfat, 2% milkfat",
  "Cheese, cottage, nonfat, uncreamed, dry, large or small curd",
  "Cheese, cottage, with vegetables",
  "Cheese, cream",
  "Cheese, cream, fat free",
  "Cheese, cream, low fat",
  "Cheese, dry white, queso seco",
  "Cheese, edam",
  "Cheese, feta",
  "Cheese, fontina",
  "Cheese, fresh, queso fresco",
  "Cheese, gjetost",
  "Cheese, goat, hard type",
  "Cheese, goat, semisoft type",
  "Cheese, goat, soft type",
  "Cheese, gouda",
  "Cheese, gruyere",
  "Cheese, limburger",
  "Cheese, low fat, cheddar or colby",
  "Cheese, low-sodium, cheddar or colby",
  "Cheese, Mexican blend",
  "Cheese, Mexican, blend, reduced fat",
  "Cheese, mexican, queso anejo",
  "Cheese, mexican, queso asadero",
  "Cheese, mexican, queso chihuahua",
  "Cheese, mexican, queso cotija",
  "Cheese, monterey",
  "Cheese, monterey, low fat",
  "Cheese, mozzarella, low moisture, part-skim",
  "Cheese, mozzarella, low moisture, part-skim, shredded",
  "Cheese, mozzarella, low sodium",
  "Cheese, mozzarella, nonfat",
  "Cheese, mozzarella, part skim milk",
  "Cheese, mozzarella, whole milk",
  "Cheese, mozzarella, whole milk, low moisture",
  "Cheese, muenster",
  "Cheese, muenster, low fat",
  "Cheese, neufchatel",
  "Cheese, parmesan, dry grated, reduced fat",
  "Cheese, parmesan, grated",
  "Cheese, parmesan, hard",
  "Cheese, parmesan, low sodium",
  "Cheese, parmesan, shredded",
  "Cheese, pasteurized process, American, fortified with vitamin D",
  "Cheese, pasteurized process, American, low fat",
  "Cheese, pasteurized process, American, without added vitamin D",
  "Cheese, pasteurized process, cheddar or American, low sodium",
  "Cheese, pasteurized process, pimento",
  "Cheese, pasteurized process, swiss",
  "Cheese, port de salut",
  "Cheese, provolone",
  "Cheese, provolone, reduced fat",
  "Cheese, ricotta, part skim milk",
  "Cheese, ricotta, whole milk",
  "Cheese, romano",
  "Cheese, roquefort",
  "Cheese, swiss",
  "Cheese, swiss, low fat",
  "Cheese, swiss, low sodium",
  "Cheese, Swiss, nonfat or fat free",
  "Cheese, tilsit",
  "Cheese, white, queso blanco",
  "Cheesefurter, cheese smokie, pork, beef",
  "Cherimoya, raw",
  "Cherries, sour, canned, water pack, drained",
  "Cherries, sour, red, canned, extra heavy syrup pack, solids and liquids",
  "Cherries, sour, red, canned, heavy syrup pack, solids and liquids",
  "Cherries, sour, red, canned, light syrup pack, solids and liquids",
  "Cherries, sour, red, canned, water pack, solids and liquids",
  "Cherries, sour, red, frozen, unsweetened (Includes foods for USDA's Food Distribution Program)",
  "Cherries, sour, red, raw",
  "Cherries, sweet, canned, extra heavy syrup pack, solids and liquids",
  "Cherries, sweet, canned, juice pack, solids and liquids",
  "Cherries, sweet, canned, light syrup pack, solids and liquids",
  "Cherries, sweet, canned, pitted, heavy syrup pack, solids and liquids",
  "Cherries, sweet, canned, pitted, heavy syrup, drained",
  "Cherries, sweet, canned, water pack, solids and liquids",
  "Cherries, sweet, raw",
  "Cherries, tart, dried, sweetened (Includes foods for USDA's Food Distribution Program)",
  "Cherry juice, tart",
  "Chewing gum",
  "Chewing gum, sugarless",
  "CHICK-FIL-A, Chick-n-Strips",
  "CHICK-FIL-A, chicken sandwich",
  "CHICK-FIL-A, hash browns",
  "Chicken breast tenders, breaded, cooked, microwaved",
  "Chicken breast tenders, breaded, uncooked",
  "Chicken breast, deli, rotisserie seasoned, sliced, prepackaged",
  "Chicken breast, fat-free, mesquite flavor, sliced",
  "Chicken breast, oven-roasted, fat-free, sliced",
  "Chicken breast, roll, oven-roasted",
  "Chicken patty, frozen, cooked",
  "Chicken patty, frozen, uncooked",
  "Chicken pot pie, frozen entree, prepared",
  "Chicken spread",
  "Chicken tenders, breaded, frozen, prepared",
  "Chicken, broiler or fryers, breast, skinless, boneless, meat only, cooked, braised",
  "Chicken, broiler or fryers, breast, skinless, boneless, meat only, cooked, grilled",
  "Chicken, broiler or fryers, breast, skinless, boneless, meat only, raw",
  "Chicken, broiler or fryers, breast, skinless, boneless, meat only, with added solution, cooked, braised",
  "Chicken, broiler or fryers, breast, skinless, boneless, meat only, with added solution, cooked, grilled",
  "Chicken, broiler, rotisserie, BBQ, back meat only",
  "Chicken, broiler, rotisserie, BBQ, back, meat and skin",
  "Chicken, broiler, rotisserie, BBQ, breast, meat and skin",
  "Chicken, broiler, rotisserie, BBQ, breast, meat only",
  "Chicken, broiler, rotisserie, BBQ, drumstick, meat and skin",
  "Chicken, broiler, rotisserie, BBQ, drumstick, meat only",
  "Chicken, broiler, rotisserie, BBQ, skin",
  "Chicken, broiler, rotisserie, BBQ, thigh, meat and skin",
  "Chicken, broiler, rotisserie, BBQ, thigh, meat only",
  "Chicken, broiler, rotisserie, BBQ, wing, meat and skin",
  "Chicken, broiler, rotisserie, BBQ, wing, meat only",
  "Chicken, broilers or fryers, rotisserie, original seasoning, drumstick, meat and skin, cooked",
  "Chicken, broilers or fryers, back, meat and skin, cooked, fried, batter",
  "Chicken, broilers or fryers, back, meat and skin, cooked, fried, flour",
  "Chicken, broilers or fryers, back, meat and skin, cooked, roasted",
  "Chicken, broilers or fryers, back, meat and skin, cooked, stewed",
  "Chicken, broilers or fryers, back, meat and skin, raw",
  "Chicken, broilers or fryers, back, meat only, cooked, fried",
  "Chicken, broilers or fryers, back, meat only, cooked, roasted",
  "Chicken, broilers or fryers, back, meat only, cooked, stewed",
  "Chicken, broilers or fryers, back, meat only, raw",
  "Chicken, broilers or fryers, breast, meat and skin, cooked, fried, batter",
  "Chicken, broilers or fryers, breast, meat and skin, cooked, fried, flour",
  "Chicken, broilers or fryers, breast, meat and skin, cooked, roasted",
  "Chicken, broilers or fryers, breast, meat and skin, cooked, stewed",
  "Chicken, broilers or fryers, breast, meat and skin, raw",
  "Chicken, broilers or fryers, breast, meat only, cooked, fried",
  "Chicken, broilers or fryers, breast, meat only, cooked, roasted",
  "Chicken, broilers or fryers, breast, meat only, cooked, stewed",
  "Chicken, broilers or fryers, breast, skinless, boneless, meat only, with added solution, raw",
  "Chicken, broilers or fryers, dark meat, drumstick, meat and skin, cooked, braised",
  "Chicken, broilers or fryers, dark meat, drumstick, meat only, cooked, braised",
  "Chicken, broilers or fryers, dark meat, drumstick, meat only, cooked, roasted",
  "Chicken, broilers or fryers, dark meat, drumstick, meat only, raw",
  "Chicken, broilers or fryers, dark meat, meat and skin, cooked, fried, batter",
  "Chicken, broilers or fryers, dark meat, meat and skin, cooked, fried, flour",
  "Chicken, broilers or fryers, dark meat, meat and skin, cooked, roasted",
  "Chicken, broilers or fryers, dark meat, meat and skin, cooked, stewed",
  "Chicken, broilers or fryers, dark meat, meat and skin, raw",
  "Chicken, broilers or fryers, dark meat, meat only, cooked, fried",
  "Chicken, broilers or fryers, dark meat, meat only, cooked, roasted",
  "Chicken, broilers or fryers, dark meat, meat only, cooked, stewed",
  "Chicken, broilers or fryers, dark meat, meat only, raw",
  "Chicken, broilers or fryers, dark meat, thigh, meat and skin, cooked, braised",
  "Chicken, broilers or fryers, dark meat, thigh, meat only, cooked, braised",
  "Chicken, broilers or fryers, dark meat, thigh, meat only, raw",
  "Chicken, broilers or fryers, drumstick, meat and skin, cooked, fried, batter",
  "Chicken, broilers or fryers, drumstick, meat and skin, cooked, fried, flour",
  "Chicken, broilers or fryers, drumstick, meat and skin, cooked, roasted",
  "Chicken, broilers or fryers, drumstick, meat and skin, cooked, stewed",
  "Chicken, broilers or fryers, drumstick, meat and skin, raw",
  "Chicken, broilers or fryers, drumstick, meat only, cooked, fried",
  "Chicken, broilers or fryers, drumstick, meat only, cooked, stewed",
  "Chicken, broilers or fryers, drumstick, rotisserie, original seasoning, meat only, cooked",
  "Chicken, broilers or fryers, giblets, cooked, fried",
  "Chicken, broilers or fryers, giblets, cooked, simmered",
  "Chicken, broilers or fryers, giblets, raw",
  "Chicken, broilers or fryers, leg, meat and skin, cooked, fried, batter",
  "Chicken, broilers or fryers, leg, meat and skin, cooked, fried, flour",
  "Chicken, broilers or fryers, leg, meat and skin, cooked, roasted",
  "Chicken, broilers or fryers, leg, meat and skin, cooked, stewed",
  "Chicken, broilers or fryers, leg, meat and skin, raw",
  "Chicken, broilers or fryers, leg, meat only, cooked, fried",
  "Chicken, broilers or fryers, leg, meat only, cooked, roasted",
  "Chicken, broilers or fryers, leg, meat only, cooked, stewed",
  "Chicken, broilers or fryers, leg, meat only, raw",
  "Chicken, broilers or fryers, light meat, meat and skin, cooked, fried, batter",
  "Chicken, broilers or fryers, light meat, meat and skin, cooked, fried, flour",
  "Chicken, broilers or fryers, light meat, meat and skin, cooked, roasted",
  "Chicken, broilers or fryers, light meat, meat and skin, cooked, stewed",
  "Chicken, broilers or fryers, light meat, meat and skin, raw",
  "Chicken, broilers or fryers, light meat, meat only, cooked, fried",
  "Chicken, broilers or fryers, light meat, meat only, cooked, roasted",
  "Chicken, broilers or fryers, light meat, meat only, cooked, stewed",
  "Chicken, broilers or fryers, light meat, meat only, raw",
  "Chicken, broilers or fryers, meat and skin and giblets and neck, cooked, fried, batter",
  "Chicken, broilers or fryers, meat and skin and giblets and neck, cooked, fried, flour",
  "Chicken, broilers or fryers, meat and skin and giblets and neck, raw",
  "Chicken, broilers or fryers, meat and skin and giblets and neck, roasted",
  "Chicken, broilers or fryers, meat and skin and giblets and neck, stewed",
  "Chicken, broilers or fryers, meat and skin, cooked, fried, batter",
  "Chicken, broilers or fryers, meat and skin, cooked, fried, flour",
  "Chicken, broilers or fryers, meat and skin, cooked, roasted",
  "Chicken, broilers or fryers, meat and skin, cooked, stewed",
  "Chicken, broilers or fryers, meat and skin, raw",
  "Chicken, broilers or fryers, meat only, cooked, fried",
  "Chicken, broilers or fryers, meat only, cooked, roasted",
  "Chicken, broilers or fryers, meat only, cooked, stewed",
  "Chicken, broilers or fryers, meat only, raw",
  "Chicken, broilers or fryers, neck, meat and skin, cooked simmered",
  "Chicken, broilers or fryers, neck, meat and skin, cooked, fried, batter",
  "Chicken, broilers or fryers, neck, meat and skin, cooked, fried, flour",
  "Chicken, broilers or fryers, neck, meat and skin, raw",
  "Chicken, broilers or fryers, neck, meat only, cooked, fried",
  "Chicken, broilers or fryers, neck, meat only, cooked, simmered",
  "Chicken, broilers or fryers, neck, meat only, raw",
  "Chicken, broilers or fryers, rotisserie, original seasoning, back, meat and skin, cooked",
  "Chicken, broilers or fryers, rotisserie, original seasoning, back, meat only, cooked",
  "Chicken, broilers or fryers, rotisserie, original seasoning, breast, meat and skin, cooked",
  "Chicken, broilers or fryers, rotisserie, original seasoning, breast, meat only, cooked",
  "Chicken, broilers or fryers, rotisserie, original seasoning, skin only, cooked",
  "Chicken, broilers or fryers, rotisserie, original seasoning, thigh, meat and skin, cooked",
  "Chicken, broilers or fryers, rotisserie, original seasoning, thigh, meat only, cooked",
  "Chicken, broilers or fryers, rotisserie, original seasoning, wing, meat and skin, cooked",
  "Chicken, broilers or fryers, rotisserie, original seasoning, wing, meat only, cooked",
  "Chicken, broilers or fryers, separable fat, raw",
  "Chicken, broilers or fryers, skin only, cooked, fried, batter",
  "Chicken, broilers or fryers, skin only, cooked, fried, flour",
  "Chicken, broilers or fryers, skin only, cooked, roasted",
  "Chicken, broilers or fryers, skin only, cooked, stewed",
  "Chicken, broilers or fryers, skin only, raw",
  "Chicken, broilers or fryers, thigh, meat and skin, cooked, fried, batter",
  "Chicken, broilers or fryers, thigh, meat and skin, cooked, fried, flour",
  "Chicken, broilers or fryers, thigh, meat and skin, cooked, roasted",
  "Chicken, broilers or fryers, thigh, meat and skin, cooked, stewed",
  "Chicken, broilers or fryers, thigh, meat and skin, raw",
  "Chicken, broilers or fryers, thigh, meat only, cooked, fried",
  "Chicken, broilers or fryers, thigh, meat only, cooked, roasted",
  "Chicken, broilers or fryers, thigh, meat only, cooked, stewed",
  "Chicken, broilers or fryers, wing, meat and skin, cooked, fried, batter",
  "Chicken, broilers or fryers, wing, meat and skin, cooked, fried, flour",
  "Chicken, broilers or fryers, wing, meat and skin, cooked, roasted",
  "Chicken, broilers or fryers, wing, meat and skin, cooked, stewed",
  "Chicken, broilers or fryers, wing, meat and skin, raw",
  "Chicken, broilers or fryers, wing, meat only, cooked, fried",
  "Chicken, broilers or fryers, wing, meat only, cooked, roasted",
  "Chicken, broilers or fryers, wing, meat only, cooked, stewed",
  "Chicken, broilers or fryers, wing, meat only, raw",
  "Chicken, canned, meat only, with broth",
  "Chicken, canned, no broth",
  "Chicken, capons, giblets, cooked, simmered",
  "Chicken, capons, giblets, raw",
  "Chicken, capons, meat and skin and giblets and neck, cooked, roasted",
  "Chicken, capons, meat and skin and giblets and neck, raw",
  "Chicken, capons, meat and skin, cooked, roasted",
  "Chicken, capons, meat and skin, raw",
  "Chicken, cornish game hens, meat and skin, cooked, roasted",
  "Chicken, cornish game hens, meat and skin, raw",
  "Chicken, cornish game hens, meat only, cooked, roasted",
  "Chicken, cornish game hens, meat only, raw",
  "Chicken, dark meat, drumstick, meat and skin, with added solution, cooked, braised",
  "Chicken, dark meat, drumstick, meat and skin, with added solution, cooked, roasted",
  "Chicken, dark meat, drumstick, meat and skin, with added solution, raw",
  "Chicken, dark meat, drumstick, meat only, with added solution, cooked, braised",
  "Chicken, dark meat, drumstick, meat only, with added solution, cooked, roasted",
  "Chicken, dark meat, drumstick, meat only, with added solution, raw",
  "Chicken, dark meat, thigh, meat and skin, with added solution, cooked, braised",
  "Chicken, dark meat, thigh, meat and skin, with added solution, cooked, roasted",
  "Chicken, dark meat, thigh, meat and skin, with added solution, raw",
  "Chicken, dark meat, thigh, meat only, with added solution, cooked, braised",
  "Chicken, dark meat, thigh, meat only, with added solution, cooked, roasted",
  "Chicken, dark meat, thigh, meat only, with added solution, raw",
  "Chicken, feet, boiled",
  "Chicken, gizzard, all classes, cooked, simmered",
  "Chicken, gizzard, all classes, raw",
  "Chicken, ground, crumbles, cooked, pan-browned",
  "Chicken, ground, raw",
  "Chicken, heart, all classes, cooked, simmered",
  "Chicken, heart, all classes, raw",
  "Chicken, liver, all classes, cooked, pan-fried",
  "Chicken, liver, all classes, cooked, simmered",
  "Chicken, liver, all classes, raw",
  "Chicken, meatless",
  "Chicken, meatless, breaded, fried",
  "Chicken, nuggets, dark and white meat, precooked, frozen, not reheated",
  "Chicken, nuggets, white meat, precooked, frozen, not reheated",
  "Chicken, roasting, dark meat, meat only, cooked, roasted",
  "Chicken, roasting, dark meat, meat only, raw",
  "Chicken, roasting, giblets, cooked, simmered",
  "Chicken, roasting, giblets, raw",
  "Chicken, roasting, light meat, meat only, cooked, roasted",
  "Chicken, roasting, light meat, meat only, raw",
  "Chicken, roasting, meat and skin and giblets and neck, cooked, roasted",
  "Chicken, roasting, meat and skin and giblets and neck, raw",
  "Chicken, roasting, meat and skin, cooked, roasted",
  "Chicken, roasting, meat only, cooked, roasted",
  "Chicken, roasting, meat only, raw",
  "Chicken, skin (drumsticks and thighs), cooked, braised",
  "Chicken, skin (drumsticks and thighs), cooked, roasted",
  "Chicken, skin (drumsticks and thighs), raw",
  "Chicken, skin (drumsticks and thighs), with added solution, cooked, braised",
  "Chicken, skin (drumsticks and thighs), with added solution, cooked, roasted",
  "Chicken, skin (drumsticks and thighs), with added solution, raw",
  "Chicken, stewing, dark meat, meat only, cooked, stewed",
  "Chicken, stewing, dark meat, meat only, raw",
  "Chicken, stewing, giblets, cooked, simmered",
  "Chicken, stewing, giblets, raw",
  "Chicken, stewing, light meat, meat only, cooked, stewed",
  "Chicken, stewing, light meat, meat only, raw",
  "Chicken, stewing, meat and skin, and giblets and neck, cooked, stewed",
  "Chicken, stewing, meat and skin, and giblets and neck, raw",
  "Chicken, stewing, meat and skin, cooked, stewed",
  "Chicken, stewing, meat and skin, raw",
  "Chicken, stewing, meat only, cooked, stewed",
  "Chicken, stewing, meat only, raw",
  "Chicken, thighs, frozen, breaded, reheated",
  "Chicken, wing, frozen, glazed, barbecue flavored",
  "Chicken, wing, frozen, glazed, barbecue flavored, heated (conventional oven)",
  "Chicken, wing, frozen, glazed, barbecue flavored, heated (microwave)",
  "Chickpea flour (besan)",
  "Chickpeas (garbanzo beans, bengal gram), mature seeds, canned, drained solids",
  "Chickpeas (garbanzo beans, bengal gram), mature seeds, canned, drained, rinsed in tap water",
  "Chickpeas (garbanzo beans, bengal gram), mature seeds, canned, solids and liquids",
  "Chickpeas (garbanzo beans, bengal gram), mature seeds, canned, solids and liquids, low sodium",
  "Chickpeas (garbanzo beans, bengal gram), mature seeds, cooked, boiled, with salt",
  "Chickpeas (garbanzo beans, bengal gram), mature seeds, cooked, boiled, without salt",
  "Chickpeas (garbanzo beans, bengal gram), mature seeds, raw",
  "Chicory greens, raw",
  "Chicory roots, raw",
  "Chicory, witloof, raw",
  "Chilchen (Red Berry Beverage) (Navajo)",
  "Child formula, ABBOTT NUTRITION, PEDIASURE, ready-to-feed",
  "Child formula, ABBOTT NUTRITION, PEDIASURE, ready-to-feed, with iron and fiber",
  "Chili con carne with beans, canned entree",
  "Chili with beans, canned",
  "Chili with beans, microwavable bowls",
  "Chili, no beans, canned entree",
  "Chiton, leathery, gumboots (Alaska Native)",
  "Chives, freeze-dried",
  "Chives, raw",
  "Chocolate, dark, 45-59% cacao solids",
  "Chocolate, dark, 60-69% cacao solids",
  "Chocolate, dark, 70-85% cacao solids",
  "Chocolate-flavored hazelnut spread",
  "Chokecherries, raw, pitted (Northern Plains Indians)",
  "Chokecherries, raw, pitted (Shoshone Bannock)",
  "Chrysanthemum leaves, raw",
  "Chrysanthemum, garland, cooked, boiled, drained, with salt",
  "Chrysanthemum, garland, cooked, boiled, drained, without salt",
  "Chrysanthemum, garland, raw",
  "Cinnamon buns, frosted (includes honey buns)",
  "Clementines, raw",
  "Clif Z bar",
  "Cloudberries, raw (Alaska Native)",
  "Cockles, raw (Alaska Native)",
  "Cocoa mix, NESTLE, Rich Chocolate Hot Cocoa Mix",
  "Cocoa, dry powder, hi-fat or breakfast, processed with alkali",
  "Cocoa, dry powder, unsweetened",
  "Cocoa, dry powder, unsweetened, HERSHEY'S European Style Cocoa",
  "Cocoa, dry powder, unsweetened, processed with alkali",
  "Collards, cooked, boiled, drained, with salt",
  "Collards, cooked, boiled, drained, without salt",
  "Collards, frozen, chopped, cooked, boiled, drained, with salt",
  "Collards, frozen, chopped, cooked, boiled, drained, without salt",
  "Collards, frozen, chopped, unprepared",
  "Collards, raw",
  "Continental Mills, Krusteaz Almond Poppyseed Muffin Mix, Artificially Flavored, dry",
  "Cookie, butter or sugar, with chocolate icing or filling",
  "Cookie, chocolate, with icing or coating",
  "Cookie, vanilla with caramel, coconut, and chocolate coating",
  "Cookie, with peanut butter filling, chocolate-coated",
  "Cookies, animal crackers (includes arrowroot, tea biscuits)",
  "Cookies, animal, with frosting or icing",
  "Cookies, brownies, commercially prepared",
  "Cookies, brownies, commercially prepared, reduced fat, with added fiber",
  "Cookies, brownies, dry mix, regular",
  "Cookies, brownies, dry mix, sugar free",
  "Cookies, brownies, prepared from recipe",
  "Cookies, butter, commercially prepared, enriched",
  "Cookies, butter, commercially prepared, unenriched",
  "Cookies, chocolate chip sandwich, with creme filling",
  "Cookies, chocolate chip, commercially prepared, regular, higher fat, enriched",
  "Cookies, chocolate chip, commercially prepared, regular, higher fat, unenriched",
  "Cookies, chocolate chip, commercially prepared, regular, lower fat",
  "Cookies, chocolate chip, commercially prepared, soft-type",
  "Cookies, chocolate chip, commercially prepared, special dietary",
  "Cookies, chocolate chip, dry mix",
  "Cookies, chocolate chip, prepared from recipe, made with butter",
  "Cookies, chocolate chip, prepared from recipe, made with margarine",
  "Cookies, chocolate chip, refrigerated dough",
  "Cookies, chocolate chip, refrigerated dough, baked",
  "Cookies, chocolate cream covered biscuit sticks",
  "Cookies, chocolate sandwich, with creme filling, reduced fat",
  "Cookies, chocolate sandwich, with creme filling, regular",
  "Cookies, chocolate sandwich, with creme filling, regular, chocolate-coated",
  "Cookies, chocolate sandwich, with creme filling, special dietary",
  "Cookies, chocolate sandwich, with extra creme filling",
  "Cookies, chocolate wafers",
  "Cookies, chocolate, made with rice cereal",
  "Cookies, coconut macaroon",
  "Cookies, fig bars",
  "Cookies, fortune",
  "Cookies, fudge, cake-type (includes trolley cakes)",
  "Cookies, gingersnaps",
  "Cookies, gluten-free, chocolate sandwich, with creme filling",
  "Cookies, gluten-free, chocolate wafer",
  "Cookies, gluten-free, lemon wafer",
  "Cookies, gluten-free, vanilla sandwich, with creme filling",
  "Cookies, graham crackers, chocolate-coated",
  "Cookies, graham crackers, plain or honey (includes cinnamon)",
  "Cookies, graham crackers, plain or honey, lowfat",
  "Cookies, ladyfingers, with lemon juice and rind",
  "Cookies, ladyfingers, without lemon juice and rind",
  "Cookies, Marie biscuit",
  "Cookies, marshmallow, chocolate-coated (includes marshmallow pies)",
  "Cookies, marshmallow, with rice cereal and chocolate chips",
  "Cookies, molasses",
  "Cookies, oatmeal sandwich, with creme filling",
  "Cookies, oatmeal, commercially prepared, regular",
  "Cookies, oatmeal, commercially prepared, soft-type",
  "Cookies, oatmeal, commercially prepared, special dietary",
  "Cookies, oatmeal, dry mix",
  "Cookies, oatmeal, prepared from recipe, without raisins",
  "Cookies, oatmeal, reduced fat",
  "Cookies, oatmeal, refrigerated dough",
  "Cookies, oatmeal, refrigerated dough, baked",
  "Cookies, oatmeal, with raisins",
  "Cookies, peanut butter sandwich, regular",
  "Cookies, peanut butter sandwich, special dietary",
  "Cookies, peanut butter, commercially prepared, regular",
  "Cookies, peanut butter, commercially prepared, soft-type",
  "Cookies, peanut butter, commercially prepared, sugar free",
  "Cookies, peanut butter, prepared from recipe",
  "Cookies, peanut butter, refrigerated dough",
  "Cookies, peanut butter, refrigerated dough, baked",
  "Cookies, raisin, soft-type",
  "Cookies, shortbread, commercially prepared, pecan",
  "Cookies, shortbread, commercially prepared, plain",
  "Cookies, shortbread, reduced fat",
  "Cookies, sugar wafer, chocolate-covered",
  "Cookies, sugar wafer, with creme filling, sugar free",
  "Cookies, sugar wafers with creme filling, regular",
  "Cookies, sugar, commercially prepared, regular (includes vanilla)",
  "Cookies, sugar, refrigerated dough",
  "Cookies, sugar, refrigerated dough, baked",
  "Cookies, vanilla sandwich with creme filling",
  "Cookies, vanilla sandwich with creme filling, reduced fat",
  "Cookies, vanilla wafers, higher fat",
  "Cookies, vanilla wafers, lower fat",
  "Coriander (cilantro) leaves, raw",
  "Corn bran, crude",
  "Corn dogs, frozen, prepared",
  "Corn flour, masa, enriched, white",
  "Corn flour, masa, unenriched, white",
  "Corn flour, whole-grain, blue (harina de maiz morado)",
  "Corn flour, whole-grain, white",
  "Corn flour, whole-grain, yellow",
  "Corn flour, yellow, degermed, unenriched",
  "Corn flour, yellow, masa, enriched",
  "Corn grain, white",
  "Corn grain, yellow",
  "Corn pudding, home prepared",
  "Corn with red and green peppers, canned, solids and liquids",
  "Corn, dried (Navajo)",
  "Corn, dried, yellow (Northern Plains Indians)",
  "Corn, sweet, white, canned, cream style, no salt added",
  "Corn, sweet, white, canned, cream style, regular pack",
  "Corn, sweet, white, canned, vacuum pack, no salt added",
  "Corn, sweet, white, canned, vacuum pack, regular pack",
  "Corn, sweet, white, canned, whole kernel, drained solids",
  "Corn, sweet, white, canned, whole kernel, no salt added, solids and liquids",
  "Corn, sweet, white, canned, whole kernel, regular pack, solids and liquids",
  "Corn, sweet, white, cooked, boiled, drained, with salt",
  "Corn, sweet, white, cooked, boiled, drained, without salt",
  "Corn, sweet, white, frozen, kernels cut off cob, boiled, drained, with salt",
  "Corn, sweet, white, frozen, kernels cut off cob, boiled, drained, without salt",
  "Corn, sweet, white, frozen, kernels cut off cob, unprepared",
  "Corn, sweet, white, frozen, kernels on cob, cooked, boiled, drained, with salt",
  "Corn, sweet, white, frozen, kernels on cob, cooked, boiled, drained, without salt",
  "Corn, sweet, white, frozen, kernels on cob, unprepared",
  "Corn, sweet, white, raw",
  "Corn, sweet, yellow, canned, brine pack, regular pack, solids and liquids",
  "Corn, sweet, yellow, canned, cream style, no salt added",
  "Corn, sweet, yellow, canned, cream style, regular pack",
  "Corn, sweet, yellow, canned, drained solids, rinsed with tap water",
  "Corn, sweet, yellow, canned, no salt added, solids and liquids (Includes foods for USDA's Food Distribution Program)",
  "Corn, sweet, yellow, canned, vacuum pack, no salt added",
  "Corn, sweet, yellow, canned, vacuum pack, regular pack",
  "Corn, sweet, yellow, canned, whole kernel, drained solids",
  "Corn, sweet, yellow, cooked, boiled, drained, with salt",
  "Corn, sweet, yellow, cooked, boiled, drained, without salt",
  "Corn, sweet, yellow, frozen, kernels cut off cob, boiled, drained, without salt",
  "Corn, sweet, yellow, frozen, kernels cut off cob, unprepared (Includes foods for USDA's Food Distribution Program)",
  "Corn, sweet, yellow, frozen, kernels on cob, cooked, boiled, drained, with salt",
  "Corn, sweet, yellow, frozen, kernels on cob, cooked, boiled, drained, without salt",
  "Corn, sweet, yellow, frozen, kernels on cob, unprepared",
  "Corn, sweet, yellow, frozen, kernels, cut off cob, boiled, drained, with salt",
  "Corn, sweet, yellow, raw",
  "Corn, white, steamed (Navajo)",
  "Corn, yellow, whole kernel, frozen, microwaved",
  "Corned beef and potatoes in tortilla (Apache)",
  "Corned beef loaf, jellied",
  "Cornmeal, blue (Navajo)",
  "Cornmeal, degermed, enriched, white",
  "Cornmeal, degermed, enriched, yellow",
  "Cornmeal, degermed, unenriched, white",
  "Cornmeal, degermed, unenriched, yellow",
  "Cornmeal, white (Navajo)",
  "Cornmeal, white, self-rising, bolted, plain, enriched",
  "Cornmeal, white, self-rising, bolted, with wheat flour added, enriched",
  "Cornmeal, white, self-rising, degermed, enriched",
  "Cornmeal, whole-grain, white",
  "Cornmeal, whole-grain, yellow",
  "Cornmeal, yellow (Navajo)",
  "Cornmeal, yellow, self-rising, bolted, plain, enriched",
  "Cornmeal, yellow, self-rising, bolted, with wheat flour added, enriched",
  "Cornmeal, yellow, self-rising, degermed, enriched",
  "Cornsalad, raw",
  "Cornstarch",
  "Couscous, cooked",
  "Couscous, dry",
  "Cowpeas (blackeyes), immature seeds, cooked, boiled, drained, with salt",
  "Cowpeas (blackeyes), immature seeds, cooked, boiled, drained, without salt",
  "Cowpeas (blackeyes), immature seeds, frozen, cooked, boiled, drained, with salt",
  "Cowpeas (blackeyes), immature seeds, frozen, cooked, boiled, drained, without salt",
  "Cowpeas (blackeyes), immature seeds, frozen, unprepared",
  "Cowpeas (blackeyes), immature seeds, raw",
    "Cowpeas, catjang, mature seeds, cooked, boiled, with salt",
    "Cowpeas, catjang, mature seeds, cooked, boiled, without salt",
    "Cowpeas, catjang, mature seeds, raw",
    "Cowpeas, common (blackeyes, crowder, southern), mature seeds, canned with pork",
    "Cowpeas, common (blackeyes, crowder, southern), mature seeds, canned, plain",
    "Cowpeas, common (blackeyes, crowder, southern), mature seeds, cooked, boiled, with salt",
    "Cowpeas, common (blackeyes, crowder, southern), mature seeds, cooked, boiled, without salt",
    "Cowpeas, common (blackeyes, crowder, southern), mature seeds, raw",
    "Cowpeas, leafy tips, cooked, boiled, drained, with salt",
    "Cowpeas, leafy tips, cooked, boiled, drained, without salt",
    "Cowpeas, leafy tips, raw",
    "Cowpeas, young pods with seeds, cooked, boiled, drained, with salt",
    "Cowpeas, young pods with seeds, cooked, boiled, drained, without salt",
    "Cowpeas, young pods with seeds, raw",
    "Crabapples, raw",
    "CRACKER BARREL, chicken tenderloin platter, fried",
    "CRACKER BARREL, chicken tenderloin platter, fried, from kid's menu",
    "CRACKER BARREL, coleslaw",
    "CRACKER BARREL, country fried shrimp platter",
    "CRACKER BARREL, farm raised catfish platter",
    "CRACKER BARREL, grilled sirloin steak",
    "CRACKER BARREL, macaroni n' cheese",
    "CRACKER BARREL, macaroni n' cheese plate, from kid's menu",
    "CRACKER BARREL, onion rings, thick-cut",
    "CRACKER BARREL, steak fries",
    "Cracker, meal",
    "Crackers, cheese, low sodium",
    "Crackers, cheese, reduced fat",
    "Crackers, cheese, regular",
    "Crackers, cheese, sandwich-type with cheese filling",
    "Crackers, cheese, sandwich-type with peanut butter filling",
    "Crackers, cheese, whole grain",
    "Crackers, cream, Gamesa Sabrosas",
    "Crackers, cream, La Moderna Rikis Cream Crackers",
    "Crackers, crispbread, rye",
    "Crackers, flavored, fish-shaped",
    "Crackers, gluten-free, multi-seeded and multigrain",
    "Crackers, gluten-free, multigrain and vegetable, made with corn starch and white rice flour",
    "Crackers, matzo, egg",
    "Crackers, matzo, egg and onion",
    "Crackers, matzo, plain",
    "Crackers, matzo, whole-wheat",
    "Crackers, melba toast, plain",
    "Crackers, melba toast, plain, without salt",
    "Crackers, melba toast, rye (includes pumpernickel)",
    "Crackers, melba toast, wheat",
    "Crackers, milk",
    "Crackers, multigrain",
    "Crackers, rusk toast",
    "Crackers, rye, sandwich-type with cheese filling",
    "Crackers, rye, wafers, plain",
    "Crackers, rye, wafers, seasoned",
    "Crackers, saltines (includes oyster, soda, soup)",
    "Crackers, saltines, fat-free, low-sodium",
    "Crackers, saltines, low salt (includes oyster, soda, soup)",
    "Crackers, saltines, unsalted tops (includes oyster, soda, soup)",
    "Crackers, saltines, whole wheat (includes multi-grain)",
    "Crackers, sandwich-type, peanut butter filled, reduced fat",
    "Crackers, snack, Goya Crackers",
    "Crackers, standard snack-type, regular",
    "Crackers, standard snack-type, regular, low salt",
    "Crackers, standard snack-type, sandwich, with cheese filling",
    "Crackers, standard snack-type, sandwich, with peanut butter filling",
    "Crackers, standard snack-type, with whole wheat",
    "Crackers, toast thins, low sodium",
    "Crackers, water biscuits",
    "Crackers, wheat, low salt",
    "Crackers, wheat, reduced fat",
    "Crackers, wheat, regular",
    "Crackers, wheat, sandwich, with cheese filling",
    "Crackers, wheat, sandwich, with peanut butter filling",
    "Crackers, whole grain, sandwich-type, with peanut butter filling",
    "Crackers, whole-wheat",
    "Crackers, whole-wheat, low salt",
    "Crackers, whole-wheat, reduced fat",
    "Cranberries, dried, sweetened (Includes foods for USDA's Food Distribution Program)",
    "Cranberries, raw",
    "Cranberries, wild, bush, raw (Alaska Native)",
    "Cranberry juice blend, 100% juice, bottled, with added vitamin C and calcium",
    "Cranberry juice cocktail, bottled",
    "Cranberry juice cocktail, bottled, low calorie, with calcium, saccharin and corn sweetener",
    "Cranberry juice cocktail, frozen concentrate",
    "Cranberry juice cocktail, frozen concentrate, prepared with water",
    "Cranberry juice, unsweetened",
    "Cranberry sauce, canned, sweetened",
    "Cranberry sauce, jellied, canned, OCEAN SPRAY",
    "Cranberry sauce, whole, canned, OCEAN SPRAY",
    "Cranberry, low bush or lingenberry, raw (Alaska Native)",
    "Cranberry-orange relish, canned",
    "Cream puff shell, prepared from recipe",
    "Cream puff, eclair, custard or cream filled, iced",
    "Cream substitute, flavored, liquid",
    "Cream substitute, flavored, powdered",
    "Cream substitute, liquid, light",
    "Cream substitute, liquid, with hydrogenated vegetable oil and soy protein",
    "Cream substitute, liquid, with lauric acid oil and sodium caseinate",
    "Cream substitute, powdered",
    "Cream substitute, powdered, light",
    "Cream, fluid, half and half",
    "Cream, fluid, heavy whipping",
    "Cream, fluid, light (coffee cream or table cream)",
    "Cream, fluid, light whipping",
    "Cream, half and half, fat free",
    "Cream, half and half, lowfat",
    "Cream, sour, cultured",
    "Cream, sour, reduced fat, cultured",
    "Cream, whipped, cream topping, pressurized",
    "Creamy dressing, made with sour cream and/or buttermilk and oil, reduced calorie",
    "Creamy dressing, made with sour cream and/or buttermilk and oil, reduced calorie, cholesterol-free",
    "Creamy dressing, made with sour cream and/or buttermilk and oil, reduced calorie, fat-free",
    "Cress, garden, cooked, boiled, drained, with salt",
    "Cress, garden, cooked, boiled, drained, without salt",
    "Cress, garden, raw",
    "Croissants, apple",
    "Croissants, butter",
    "Croissants, cheese",
    "Croutons, plain",
    "Croutons, seasoned",
    "Crunchmaster, Multi-Grain Crisps, Snack Crackers, Gluten-Free",
    "Crustaceans, crab, alaska king, cooked, moist heat",
    "Crustaceans, crab, alaska king, imitation, made from surimi",
    "Crustaceans, crab, alaska king, raw",
    "Crustaceans, crab, blue, canned",
    "Crustaceans, crab, blue, cooked, moist heat",
    "Crustaceans, crab, blue, crab cakes, home recipe",
    "Crustaceans, crab, blue, raw",
    "Crustaceans, crab, dungeness, cooked, moist heat",
    "Crustaceans, crab, dungeness, raw",
    "Crustaceans, crab, queen, cooked, moist heat",
    "Crustaceans, crab, queen, raw",
    "Crustaceans, crayfish, mixed species, farmed, cooked, moist heat",
    "Crustaceans, crayfish, mixed species, farmed, raw",
    "Crustaceans, crayfish, mixed species, wild, cooked, moist heat",
    "Crustaceans, crayfish, mixed species, wild, raw",
    "Crustaceans, lobster, northern, cooked, moist heat",
    "Crustaceans, lobster, northern, raw",
    "Crustaceans, shrimp, cooked",
    "Crustaceans, shrimp, mixed species, canned",
    "Crustaceans, shrimp, mixed species, cooked, breaded and fried",
    "Crustaceans, shrimp, mixed species, cooked, moist heat (may contain additives to retain moisture)",
    "Crustaceans, shrimp, mixed species, imitation, made from surimi",
    "Crustaceans, shrimp, mixed species, raw (may contain additives to retain moisture)",
    "Crustaceans, shrimp, raw",
    "Crustaceans, spiny lobster, mixed species, cooked, moist heat",
    "Crustaceans, spiny lobster, mixed species, raw",
    "Cucumber, peeled, raw",
    "Cucumber, with peel, raw",
    "Currants, european black, raw",
    "Currants, red and white, raw",
    "Currants, zante, dried",
    "Custard-apple, (bullock's-heart), raw",
    "Dandelion greens, cooked, boiled, drained, with salt",
    "Dandelion greens, cooked, boiled, drained, without salt",
    "Dandelion greens, raw",
    "Danish pastry, cheese",
    "Danish pastry, cinnamon, enriched",
    "Danish pastry, cinnamon, unenriched",
    "Danish pastry, fruit, enriched (includes apple, cinnamon, raisin, lemon, raspberry, strawberry)",
    "Danish pastry, fruit, unenriched (includes apple, cinnamon, raisin, strawberry)",
    "Danish pastry, lemon, unenriched",
    "Danish pastry, raspberry, unenriched",
    "Dates, deglet noor",
    "Dates, medjool",
    "Deer (venison), sitka, raw (Alaska Native)",
    "DENNY'S, chicken nuggets, star shaped, from kid's menu",
    "DENNY'S, chicken strips",
    "DENNY'S, coleslaw",
    "DENNY'S, fish fillet, battered or breaded, fried",
    "DENNY'S, french fries",
    "DENNY'S, golden fried shrimp",
    "DENNY'S, hash browns",
    "DENNY'S, macaroni & cheese, from kid's menu",
    "DENNY'S, mozzarella cheese sticks",
    "DENNY'S, onion rings",
    "DENNY'S, spaghetti and meatballs",
    "DENNY'S, top sirloin steak",
    "Dessert topping, powdered",
    "Dessert topping, powdered, 1.5 ounce prepared with 1/2 cup milk",
    "Dessert topping, pressurized",
    "Dessert topping, semi solid, frozen",
    "Desserts, apple crisp, prepared-from-recipe",
    "Desserts, egg custard, baked, prepared-from-recipe",
    "Desserts, flan, caramel custard, prepared-from-recipe",
    "Desserts, mousse, chocolate, prepared-from-recipe",
    "Desserts, rennin, chocolate, dry mix",
    "Desserts, rennin, tablets, unsweetened",
    "Desserts, rennin, vanilla, dry mix",
    "DIGIORNO Pizza, cheese topping, cheese stuffed crust, frozen, baked",
    "DIGIORNO Pizza, cheese topping, rising crust, frozen, baked",
    "DIGIORNO Pizza, cheese topping, thin crispy crust, frozen, baked",
    "DIGIORNO Pizza, pepperoni topping, cheese stuffed crust, frozen, baked",
    "DIGIORNO Pizza, pepperoni topping, rising crust, frozen, baked",
    "DIGIORNO Pizza, pepperoni topping, thin crispy crust, frozen, baked",
    "DIGIORNO Pizza, supreme topping, rising crust, frozen, baked",
    "DIGIORNO Pizza, supreme topping, thin crispy crust, frozen, baked",
    "Dill weed, fresh",
    "Dip, bean, original flavor",
    "Dip, FRITO'S, bean, original flavor",
    "Dip, salsa con queso, cheese and salsa- medium",
    "Dip, TOSTITOS, salsa con queso, medium",
    "Dock, cooked, boiled, drained, with salt",
    "Dock, cooked, boiled, drained, without salt",
    "Dock, raw",
    "DOMINO'S 14\" Cheese Pizza, Classic Hand-Tossed Crust",
    "DOMINO'S 14\" Cheese Pizza, Crunchy Thin Crust",
    "DOMINO'S 14\" Cheese Pizza, Ultimate Deep Dish Crust",
    "DOMINO'S 14\" EXTRAVAGANZZA FEAST Pizza, Classic Hand-Tossed Crust",
    "DOMINO'S 14\" Pepperoni Pizza, Classic Hand-Tossed Crust",
    "DOMINO'S 14\" Pepperoni Pizza, Crunchy Thin Crust",
    "DOMINO'S 14\" Pepperoni Pizza, Ultimate Deep Dish Crust",
    "DOMINO'S 14\" Sausage Pizza, Classic Hand-Tossed Crust",
    "DOMINO'S 14\" Sausage Pizza, Crunchy Thin Crust",
    "DOMINO'S 14\" Sausage Pizza, Ultimate Deep Dish Crust",
    "Doughnuts, cake-type, chocolate, sugared or glazed",
    "Doughnuts, cake-type, plain (includes unsugared, old-fashioned)",
    "Doughnuts, cake-type, plain, chocolate-coated or frosted",
    "Doughnuts, cake-type, plain, sugared or glazed",
    "Doughnuts, french crullers, glazed",
    "Doughnuts, yeast-leavened, glazed, enriched (includes honey buns)",
    "Doughnuts, yeast-leavened, glazed, unenriched (includes honey buns)",
    "Doughnuts, yeast-leavened, with creme filling",
    "Doughnuts, yeast-leavened, with jelly filling",
    "Dove, cooked (includes squab)",
    "Dressing, honey mustard, fat-free",
    "Drumstick leaves, cooked, boiled, drained, with salt",
    "Drumstick leaves, cooked, boiled, drained, without salt",
    "Drumstick leaves, raw",
    "Drumstick pods, cooked, boiled, drained, with salt",
    "Drumstick pods, cooked, boiled, drained, without salt",
    "Drumstick pods, raw",
    "Duck, domesticated, liver, raw",
    "Duck, domesticated, meat and skin, cooked, roasted",
    "Duck, domesticated, meat and skin, raw",
    "Duck, domesticated, meat only, cooked, roasted",
    "Duck, domesticated, meat only, raw",
    "Duck, scoter, white-winged, meat (Alaska Native)",
    "Duck, wild, breast, meat only, raw",
    "Duck, wild, meat and skin, raw",
    "Duck, young duckling, domesticated, White Pekin, breast, meat and skin, boneless, cooked, roasted",
    "Duck, young duckling, domesticated, White Pekin, breast, meat only, boneless, cooked without skin, broiled",
    "Duck, young duckling, domesticated, White Pekin, leg, meat and skin, bone in, cooked, roasted",
    "Duck, young duckling, domesticated, White Pekin, leg, meat only, bone in, cooked without skin, braised",
    "Dulce de Leche",
    "Dumpling, potato- or cheese-filled, frozen",
    "Durian, raw or frozen",
    "Dutch brand loaf, chicken, pork and beef",
    "Edamame, frozen, prepared",
    "Edamame, frozen, unprepared",
    "Egg custards, dry mix",
    "Egg custards, dry mix, prepared with 2% milk",
    "Egg custards, dry mix, prepared with whole milk",
    "Egg rolls, chicken, refrigerated, heated",
    "Egg rolls, pork, refrigerated, heated",
    "Egg rolls, vegetable, frozen, prepared",
    "Egg substitute, liquid or frozen, fat free",
    "Egg substitute, powder",
    "Egg, duck, whole, fresh, raw",
    "Egg, goose, whole, fresh, raw",
    "Egg, quail, whole, fresh, raw",
    "Egg, turkey, whole, fresh, raw",
    "Egg, white, dried",
    "Egg, white, dried, flakes, stabilized, glucose reduced",
    "Egg, white, dried, powder, stabilized, glucose reduced",
    "Egg, white, dried, stabilized, glucose reduced",
    "Egg, white, raw, fresh",
    "Egg, white, raw, frozen, pasteurized",
    "Egg, whole, cooked, fried",
    "Egg, whole, cooked, hard-boiled",
    "Egg, whole, cooked, omelet",
    "Egg, whole, cooked, poached",
    "Egg, whole, cooked, scrambled",
    "Egg, whole, dried",
    "Egg, whole, dried, stabilized, glucose reduced",
    "Egg, whole, raw, fresh",
    "Egg, whole, raw, frozen, pasteurized (Includes foods for USDA's Food Distribution Program)",
    "Egg, whole, raw, frozen, salted, pasteurized",
    "Egg, yolk, dried",
    "Egg, yolk, raw, fresh",
    "Egg, yolk, raw, frozen, pasteurized",
    "Egg, yolk, raw, frozen, salted, pasteurized",
    "Egg, yolk, raw, frozen, sugared, pasteurized",
    "Eggnog",
    "Eggplant, cooked, boiled, drained, with salt",
    "Eggplant, cooked, boiled, drained, without salt",
    "Eggplant, pickled",
    "Eggplant, raw",
    "Eggs, scrambled, frozen mixture",
    "Elderberries, raw",
    "Elk, free range, ground, cooked patties (Shoshone Bannock)",
    "Elk, free range, ground, raw (Shoshone Bannock)",
    "Elk, free range, roast, eye of round, cooked (Shoshone Bannock)",
    "Elk, free range, roast, eye of round, raw (Shoshone Bannock)",
    "Emu, fan fillet, cooked, broiled",
    "Emu, fan fillet, raw",
    "Emu, flat fillet, raw",
    "Emu, full rump, cooked, broiled",
    "Emu, full rump, raw",
    "Emu, ground, cooked, pan-broiled",
    "Emu, ground, raw",
    "Emu, inside drum, raw",
    "Emu, inside drums, cooked, broiled",
    "Emu, outside drum, raw",
    "Emu, oyster, raw",
    "Emu, top loin, cooked, broiled",
    "Endive, raw",
    "English muffins, plain, enriched, without calcium propionate(includes sourdough)",
    "English muffins, plain, unenriched, with calcium propionate (includes sourdough)",
    "English muffins, plain, unenriched, without calcium propionate (includes sourdough)",
    "English muffins, whole grain white",
    "Epazote, raw",
    "Eppaw, raw",
    "Escarole, cooked, boiled, drained, no salt added",
    "Falafel, home-prepared",
    "Fast food, biscuit",
    "Fast Food, Pizza Chain, 14\" pizza, cheese topping, regular crust",
    "Fast Food, Pizza Chain, 14\" pizza, cheese topping, stuffed crust",
    "Fast Food, Pizza Chain, 14\" pizza, cheese topping, thick crust",
    "Fast Food, Pizza Chain, 14\" pizza, cheese topping, thin crust",
    "Fast Food, Pizza Chain, 14\" pizza, meat and vegetable topping, regular crust",
    "Fast Food, Pizza Chain, 14\" pizza, pepperoni topping, regular crust",
    "Fast Food, Pizza Chain, 14\" pizza, pepperoni topping, thick crust",
    "Fast Food, Pizza Chain, 14\" pizza, pepperoni topping, thin crust",
    "Fast Food, Pizza Chain, 14\" pizza, sausage topping, regular crust",
    "Fast Food, Pizza Chain, 14\" pizza, sausage topping, thick crust",
    "Fast Food, Pizza Chain, 14\" pizza, sausage topping, thin crust",
    "Fast foods, bagel, with breakfast steak, egg, cheese, and condiments",
    "Fast foods, bagel, with egg, sausage patty, cheese, and condiments",
    "Fast foods, biscuit, with crispy chicken fillet",
    "Fast foods, biscuit, with egg and bacon",
    "Fast foods, biscuit, with egg and ham",
    "Fast Foods, biscuit, with egg and sausage",
    "Fast foods, biscuit, with egg, cheese, and bacon",
    "Fast foods, biscuit, with ham",
    "Fast foods, biscuit, with sausage",
    "Fast foods, breadstick, soft, prepared with garlic and parmesan cheese",
    "Fast foods, breakfast burrito, with egg, cheese, and sausage",
    "Fast foods, burrito, with beans",
    "Fast foods, burrito, with beans and beef",
    "Fast foods, burrito, with beans and cheese",
    "Fast foods, burrito, with beans, cheese, and beef",
    "Fast foods, cheeseburger, double, regular patty and bun, with condiments",
    "Fast foods, cheeseburger; double, large patty; with condiments",
    "Fast Foods, cheeseburger; double, large patty; with condiments, vegetables and mayonnaise",
    "Fast foods, cheeseburger; double, regular patty; double decker bun with condiments and special sauce",
    "Fast foods, cheeseburger; double, regular patty; with condiments",
    "Fast foods, cheeseburger; single, large patty; plain",
    "Fast foods, cheeseburger; single, large patty; with condiments",
    "Fast foods, cheeseburger; single, large patty; with condiments, vegetables and mayonnaise",
    "Fast foods, cheeseburger; single, regular patty, with condiments",
    "Fast foods, cheeseburger; single, regular patty, with condiments and vegetables",
    "Fast foods, cheeseburger; single, regular patty; plain",
    "Fast foods, chicken fillet sandwich, plain with pickles",
    "Fast foods, chicken tenders",
    "Fast foods, chicken, breaded and fried, boneless pieces, plain",
    "Fast foods, coleslaw",
    "Fast foods, crispy chicken filet sandwich, with lettuce and mayonnaise",
    "Fast foods, crispy chicken in tortilla, with lettuce, cheese, and ranch sauce",
    "Fast foods, crispy chicken, bacon, and tomato club sandwich, with cheese, lettuce, and mayonnaise",
    "Fast foods, croissant, with egg, cheese, and bacon",
    "Fast foods, croissant, with egg, cheese, and ham",
    "Fast foods, croissant, with egg, cheese, and sausage",
    "Fast foods, egg, scrambled",
    "Fast foods, english muffin, with cheese and sausage",
    "Fast foods, english muffin, with egg, cheese, and canadian bacon",
    "Fast foods, english muffin, with egg, cheese, and sausage",
    "Fast foods, fish sandwich, with tartar sauce",
    "Fast foods, fish sandwich, with tartar sauce and cheese",
    "Fast foods, french toast sticks",
    "Fast Foods, Fried Chicken, Breast, meat and skin and breading",
    "Fast Foods, Fried Chicken, Breast, meat only, skin and breading removed",
    "Fast Foods, Fried Chicken, Drumstick, meat and skin with breading",
    "Fast Foods, Fried Chicken, Drumstick, meat only, skin and breading removed",
    "Fast Foods, Fried Chicken, Skin and breading from all pieces",
    "Fast Foods, Fried Chicken, Thigh, meat and skin and breading",
    "Fast Foods, Fried Chicken, Thigh, meat only, skin and breading removed",
    "Fast Foods, Fried Chicken, Wing, meat and skin and breading",
    "Fast Foods, Fried Chicken, Wing, meat only, skin and breading removed",
    "Fast foods, griddle cake sandwich, egg, cheese, and bacon",
    "Fast foods, griddle cake sandwich, egg, cheese, and sausage",
    "Fast foods, griddle cake sandwich, sausage",
    "Fast Foods, grilled chicken filet sandwich, with lettuce, tomato and spread",
    "Fast foods, grilled chicken in tortilla, with lettuce, cheese, and ranch sauce",
    "Fast foods, grilled chicken, bacon and tomato club sandwich, with cheese, lettuce, and mayonnaise",
    "Fast foods, hamburger, large, single patty, with condiments",
    "Fast foods, hamburger; double, large patty; with condiments, vegetables and mayonnaise",
    "Fast foods, hamburger; single, large patty; with condiments, vegetables and mayonnaise",
    "Fast foods, hamburger; single, regular patty; double decker bun with condiments and special sauce",
    "Fast foods, hamburger; single, regular patty; plain",
    "Fast foods, hamburger; single, regular patty; with condiments",
    "Fast foods, hush puppies",
    "Fast foods, miniature cinnamon rolls",
    "Fast foods, nachos, with cheese",
    "Fast foods, nachos, with cheese, beans, ground beef, and tomatoes",
    "Fast foods, onion rings, breaded and fried",
    "Fast foods, potato, french fried in vegetable oil",
    "Fast foods, potato, mashed",
    "Fast foods, potatoes, hash browns, round pieces or patty",
    "Fast foods, quesadilla, with chicken",
    "Fast foods, roast beef sandwich, plain",
    "Fast foods, shrimp, breaded and fried",
    "Fast foods, strawberry banana smoothie made with ice and low-fat yogurt",
    "Fast foods, submarine sandwich, bacon, lettuce, and tomato on white bread",
    "Fast foods, submarine sandwich, cold cut on white bread with lettuce and tomato",
    "Fast foods, submarine sandwich, ham on white bread with lettuce and tomato",
    "Fast foods, submarine sandwich, meatball marinara on white bread",
    "Fast foods, submarine sandwich, oven roasted chicken on white bread with lettuce and tomato",
    "Fast foods, submarine sandwich, roast beef on white bread with lettuce and tomato",
    "Fast foods, submarine sandwich, steak and cheese on white bread with cheese, lettuce and tomato",
    "Fast foods, submarine sandwich, sweet onion chicken teriyaki on white bread with lettuce, tomato and sweet onion sauce",
    "Fast foods, submarine sandwich, tuna on white bread with lettuce and tomato",
    "Fast foods, submarine sandwich, turkey breast on white bread with lettuce and tomato",
    "Fast foods, submarine sandwich, turkey, roast beef and ham on white bread with lettuce and tomato",
    "Fast foods, sundae, caramel",
    "Fast foods, sundae, hot fudge",
    "Fast foods, sundae, strawberry",
    "Fast foods, taco with beef, cheese and lettuce, hard shell",
    "Fast foods, taco with beef, cheese and lettuce, soft",
    "Fast foods, taco with chicken, lettuce and cheese, soft",
    "Fast foods, vanilla, light, soft-serve ice cream, with cone",
    "Fat free ice cream, no sugar added, flavors other than chocolate",
    "Fat, beef tallow",
    "Fat, chicken",
    "Fat, goose",
    "Fat, turkey",
    "Feijoa, raw",
    "Fennel, bulb, raw",
    "Fiddlehead ferns, frozen, unprepared",
    "Fiddlehead ferns, raw",
    "Figs, canned, extra heavy syrup pack, solids and liquids",
    "Figs, canned, heavy syrup pack, solids and liquids",
    "Figs, canned, light syrup pack, solids and liquids",
    "Figs, canned, water pack, solids and liquids",
    "Figs, dried, stewed",
    "Figs, dried, uncooked",
    "Figs, raw",
    "Fireweed, leaves, raw",
    "Fireweed, young leaves, raw (Alaska Native)",
    "Fish broth",
    "Fish oil, cod liver",
    "Fish oil, herring",
    "Fish oil, menhaden",
    "Fish oil, menhaden, fully hydrogenated",
    "Fish oil, salmon",
    "Fish oil, sardine",
    "Fish, anchovy, european, canned in oil, drained solids",
    "Fish, anchovy, european, raw",
    "Fish, bass, fresh water, mixed species, raw",
    "Fish, bass, freshwater, mixed species, cooked, dry heat",
    "Fish, bass, striped, cooked, dry heat",
    "Fish, bass, striped, raw",
    "Fish, blackfish, whole (Alaska Native)",
    "Fish, bluefish, cooked, dry heat",
    "Fish, bluefish, raw",
    "Fish, burbot, cooked, dry heat",
    "Fish, burbot, raw",
    "Fish, butterfish, cooked, dry heat",
    "Fish, butterfish, raw",
    "Fish, carp, cooked, dry heat",
    "Fish, carp, raw",
    "Fish, catfish, channel, cooked, breaded and fried",
    "Fish, catfish, channel, farmed, cooked, dry heat",
    "Fish, catfish, channel, farmed, raw",
    "Fish, catfish, channel, wild, cooked, dry heat",
    "Fish, catfish, channel, wild, raw",
    "Fish, caviar, black and red, granular",
    "Fish, cisco, raw",
    "Fish, cisco, smoked",
    "Fish, cod, Atlantic, canned, solids and liquid",
    "Fish, cod, Atlantic, cooked, dry heat",
    "Fish, cod, Atlantic, dried and salted",
    "Fish, cod, Atlantic, raw",
    "Fish, cod, Pacific, cooked",
    "Fish, cod, Pacific, cooked, dry heat (may contain additives to retain moisture)",
    "Fish, cod, Pacific, raw (may have been previously frozen)",
    "Fish, croaker, Atlantic, cooked, breaded and fried",
    "Fish, croaker, Atlantic, raw",
    "Fish, cusk, cooked, dry heat",
    "Fish, cusk, raw",
    "Fish, devilfish, meat (Alaska Native)",
    "Fish, drum, freshwater, cooked, dry heat",
    "Fish, drum, freshwater, raw",
    "Fish, eel, mixed species, cooked, dry heat",
    "Fish, eel, mixed species, raw",
    "Fish, fish sticks, frozen, prepared",
    "Fish, flatfish (flounder and sole species), cooked, dry heat",
    "Fish, flatfish (flounder and sole species), raw",
    "Fish, gefiltefish, commercial, sweet recipe",
    "Fish, grouper, mixed species, cooked, dry heat",
    "Fish, grouper, mixed species, raw",
    "Fish, haddock, cooked, dry heat",
    "Fish, haddock, raw",
    "Fish, haddock, smoked",
    "Fish, halibut, Atlantic and Pacific, cooked, dry heat",
    "Fish, halibut, Atlantic and Pacific, raw",
    "Fish, halibut, cooked, with skin (Alaska Native)",
    "Fish, halibut, greenland, cooked, dry heat",
    "Fish, halibut, Greenland, raw",
    "Fish, halibut, raw, with skin (Alaska Native)",
    "Fish, herring eggs on giant kelp, Pacific (Alaska Native)",
    "Fish, herring eggs, Pacific, dry (Alaska Native)",
    "Fish, herring eggs, Pacific, plain (Alaska Native)",
    "Fish, herring, Atlantic, cooked, dry heat",
    "Fish, herring, Atlantic, kippered",
    "Fish, herring, Atlantic, pickled",
    "Fish, herring, Atlantic, raw",
    "Fish, herring, Pacific, cooked, dry heat",
    "Fish, herring, Pacific, flesh, air-dried, packed in oil (Alaska Native)",
    "Fish, herring, Pacific, raw",
    "Fish, ling, cooked, dry heat",
    "Fish, ling, raw",
    "Fish, lingcod, cooked, dry heat",
    "Fish, lingcod, liver (Alaska Native)",
    "Fish, lingcod, meat, raw (Alaska Native)",
    "Fish, lingcod, raw",
    "Fish, mackerel, Atlantic, cooked, dry heat",
    "Fish, mackerel, Atlantic, raw",
    "Fish, mackerel, jack, canned, drained solids",
    "Fish, mackerel, king, cooked, dry heat",
    "Fish, mackerel, king, raw",
    "Fish, mackerel, Pacific and jack, mixed species, cooked, dry heat",
    "Fish, mackerel, Pacific and jack, mixed species, raw",
    "Fish, mackerel, salted",
    "Fish, mackerel, spanish, cooked, dry heat",
    "Fish, mackerel, spanish, raw",
    "Fish, mahimahi, cooked, dry heat",
    "Fish, mahimahi, raw",
    "Fish, milkfish, cooked, dry heat",
    "Fish, milkfish, raw",
    "Fish, monkfish, cooked, dry heat",
    "Fish, monkfish, raw",
    "Fish, mullet, striped, cooked, dry heat",
    "Fish, mullet, striped, raw",
    "Fish, ocean perch, Atlantic, cooked, dry heat",
    "Fish, ocean perch, Atlantic, raw",
    "Fish, perch, mixed species, cooked, dry heat",
    "Fish, perch, mixed species, raw",
    "Fish, pike, northern, cooked, dry heat",
    "Fish, pike, northern, liver (Alaska Native)",
    "Fish, pike, northern, raw",
    "Fish, pike, walleye, cooked, dry heat",
    "Fish, pike, walleye, raw",
    "Fish, pollock, Alaska, cooked",
    "Fish, pollock, Alaska, cooked, dry heat (may contain additives to retain moisture)",
    "Fish, pollock, Alaska, raw",
    "Fish, pollock, Alaska, raw (may contain additives to retain moisture)",
    "Fish, pollock, Atlantic, cooked, dry heat",
    "Fish, pollock, Atlantic, raw",
    "Fish, pompano, florida, cooked, dry heat",
    "Fish, pompano, florida, raw",
    "Fish, pout, ocean, cooked, dry heat",
  "Fish, pout, ocean, raw",
  "Fish, rockfish, Pacific, mixed species, cooked, dry heat",
  "Fish, rockfish, Pacific, mixed species, raw",
  "Fish, roe, mixed species, cooked, dry heat",
  "Fish, roe, mixed species, raw",
  "Fish, roughy, orange, cooked, dry heat",
  "Fish, roughy, orange, raw",
  "Fish, sablefish, cooked, dry heat",
  "Fish, sablefish, raw",
  "Fish, sablefish, smoked",
  "Fish, salmon, Atlantic, farmed, cooked, dry heat",
  "Fish, salmon, Atlantic, farmed, raw",
  "Fish, salmon, Atlantic, wild, cooked, dry heat",
  "Fish, salmon, Atlantic, wild, raw",
  "Fish, salmon, chinook, cooked, dry heat",
  "Fish, salmon, chinook, raw",
  "Fish, salmon, chinook, smoked",
  "Fish, salmon, chinook, smoked, (lox), regular",
  "Fish, salmon, chum, canned, drained solids with bone",
  "Fish, salmon, chum, canned, without salt, drained solids with bone",
  "Fish, salmon, chum, cooked, dry heat",
  "Fish, salmon, chum, dried (Alaska Native)",
  "Fish, salmon, chum, raw",
  "Fish, Salmon, Chum, raw (Alaska Native)",
  "Fish, salmon, coho (silver), raw (Alaska Native)",
  "Fish, salmon, coho, farmed, cooked, dry heat",
  "Fish, salmon, coho, farmed, raw",
  "Fish, salmon, coho, wild, cooked, dry heat",
  "Fish, salmon, coho, wild, cooked, moist heat",
  "Fish, salmon, coho, wild, raw",
  "Fish, salmon, king (chinook), raw (Alaska Native)",
  "Fish, salmon, king, chinook, kippered, canned (Alaska Native)",
  "Fish, salmon, king, chinook, liver (Alaska Native)",
  "Fish, salmon, king, chinook, smoked and canned (Alaska Native)",
  "Fish, salmon, king, chinook, smoked, brined (Alaska Native)",
  "Fish, salmon, king, with skin, kippered, (Alaska Native)",
  "Fish, salmon, pink, canned, drained solids",
  "Fish, Salmon, pink, canned, drained solids, without skin and bones",
  "Fish, salmon, pink, canned, total can contents",
  "Fish, salmon, pink, canned, without salt, solids with bone and liquid",
  "Fish, salmon, pink, cooked, dry heat",
  "Fish, salmon, pink, raw",
  "Fish, salmon, red, (sockeye), canned, smoked (Alaska Native)",
  "Fish, salmon, red, (sockeye), kippered (Alaska Native)",
  "Fish, salmon, red, canned, bones removed (Alaska Native)",
  "Fish, salmon, sockeye (red), raw (Alaska Native)",
  "Fish, salmon, sockeye, canned, drained solids",
  "Fish, salmon, sockeye, canned, without salt, drained solids with bone",
  "Fish, salmon, sockeye, cooked, dry heat",
  "Fish, salmon, sockeye, raw",
  "Fish, salmon, tipnuk, fermented (Alaska Native)",
  "Fish, sardine, Atlantic, canned in oil, drained solids with bone",
  "Fish, sardine, Pacific, canned in tomato sauce, drained solids with bone",
  "Fish, scup, cooked, dry heat",
  "Fish, scup, raw",
  "Fish, sea bass, mixed species, cooked, dry heat",
  "Fish, sea bass, mixed species, raw",
  "Fish, seatrout, mixed species, cooked, dry heat",
  "Fish, seatrout, mixed species, raw",
  "Fish, shad, american, cooked, dry heat",
  "Fish, shad, american, raw",
  "Fish, shark, mixed species, cooked, batter-dipped and fried",
  "Fish, shark, mixed species, raw",
  "Fish, sheefish, raw (Alaska Native)",
  "Fish, sheepshead, cooked, dry heat",
  "Fish, sheepshead, raw",
  "Fish, smelt, rainbow, cooked, dry heat",
  "Fish, smelt, rainbow, raw",
  "Fish, snapper, mixed species, cooked, dry heat",
  "Fish, snapper, mixed species, raw",
  "Fish, spot, cooked, dry heat",
  "Fish, spot, raw",
  "Fish, sturgeon, mixed species, cooked, dry heat",
  "Fish, sturgeon, mixed species, raw",
  "Fish, sturgeon, mixed species, smoked",
  "Fish, sucker, white, cooked, dry heat",
  "Fish, sucker, white, raw",
  "Fish, sunfish, pumpkin seed, cooked, dry heat",
  "Fish, sunfish, pumpkin seed, raw",
  "Fish, surimi",
  "Fish, swordfish, cooked, dry heat",
  "Fish, swordfish, raw",
  "Fish, tilapia, cooked, dry heat",
  "Fish, tilapia, raw",
  "Fish, tilefish, cooked, dry heat",
  "Fish, tilefish, raw",
  "Fish, trout, brook, raw, New York State",
  "Fish, trout, mixed species, cooked, dry heat",
  "Fish, trout, mixed species, raw",
  "Fish, trout, rainbow, farmed, cooked, dry heat",
  "Fish, trout, rainbow, farmed, raw",
  "Fish, trout, rainbow, wild, cooked, dry heat",
  "Fish, trout, rainbow, wild, raw",
  "Fish, tuna salad",
  "Fish, tuna, fresh, bluefin, cooked, dry heat",
  "Fish, tuna, fresh, bluefin, raw",
  "Fish, tuna, fresh, skipjack, raw",
  "Fish, tuna, fresh, yellowfin, raw",
  "Fish, tuna, light, canned in oil, drained solids",
  "Fish, tuna, light, canned in oil, without salt, drained solids",
  "Fish, tuna, light, canned in water, drained solids (Includes foods for USDA's Food Distribution Program)",
  "Fish, tuna, light, canned in water, without salt, drained solids",
  "Fish, tuna, skipjack, fresh, cooked, dry heat",
  "Fish, tuna, white, canned in oil, drained solids",
  "Fish, tuna, white, canned in oil, without salt, drained solids",
  "Fish, tuna, white, canned in water, drained solids",
  "Fish, tuna, white, canned in water, without salt, drained solids",
  "Fish, tuna, yellowfin, fresh, cooked, dry heat",
  "Fish, turbot, european, cooked, dry heat",
  "Fish, turbot, european, raw",
  "Fish, whitefish, broad, head, eyes, cheeks and soft bones (Alaska Native)",
  "Fish, whitefish, broad, liver (Alaska Native)",
  "Fish, whitefish, dried (Alaska Native)",
  "Fish, whitefish, eggs (Alaska Native)",
  "Fish, whitefish, mixed species, cooked, dry heat",
  "Fish, whitefish, mixed species, raw",
  "Fish, whitefish, mixed species, raw (Alaska Native)",
  "Fish, whitefish, mixed species, smoked",
  "Fish, whiting, mixed species, cooked, dry heat",
  "Fish, whiting, mixed species, raw",
  "Fish, wolffish, Atlantic, cooked, dry heat",
  "Fish, wolffish, Atlantic, raw",
  "Fish, yellowtail, mixed species, cooked, dry heat",
  "Fish, yellowtail, mixed species, raw",
  "Flan, caramel custard, dry mix",
  "Flan, caramel custard, dry mix, prepared with 2% milk",
  "Flan, caramel custard, dry mix, prepared with whole milk",
  "Fluid replacement, electrolyte solution (include PEDIALYTE)",
  "Focaccia, Italian flatbread, plain",
  "Formulated bar, high fiber, chewy, oats and chocolate",
  "Formulated bar, LUNA BAR, NUTZ OVER CHOCOLATE",
  "Formulated bar, MARS SNACKFOOD US, COCOAVIA, Chocolate Almond Snack Bar",
  "Formulated bar, MARS SNACKFOOD US, COCOAVIA, Chocolate Blueberry Snack Bar",
  "Formulated bar, MARS SNACKFOOD US, SNICKERS MARATHON Chewy Chocolate Peanut Bar",
  "Formulated bar, MARS SNACKFOOD US, SNICKERS MARATHON Double Chocolate Nut Bar",
  "Formulated bar, MARS SNACKFOOD US, SNICKERS MARATHON Energy Bar, all flavors",
  "Formulated bar, MARS SNACKFOOD US, SNICKERS MARATHON Honey Nut Oat Bar",
  "Formulated bar, MARS SNACKFOOD US, SNICKERS MARATHON MULTIGRAIN CRUNCH BAR",
  "Formulated bar, MARS SNACKFOOD US, SNICKERS MARATHON Protein Performance Bar, Caramel Nut Rush",
  "Formulated bar, POWER BAR, chocolate",
  "Formulated bar, SLIM-FAST OPTIMA meal bar, milk chocolate peanut",
  "Formulated Bar, SOUTH BEACH protein bar",
  "Formulated bar, ZONE PERFECT CLASSIC CRUNCH BAR, mixed flavors",
  "Frankfurter, beef, heated",
  "Frankfurter, beef, low fat",
  "Frankfurter, beef, pork, and turkey, fat free",
  "Frankfurter, beef, unheated",
  "Frankfurter, chicken",
  "Frankfurter, low sodium",
  "Frankfurter, meat",
  "Frankfurter, meat and poultry, cooked, boiled",
  "Frankfurter, meat and poultry, cooked, grilled",
  "Frankfurter, meat and poultry, low fat",
  "Frankfurter, meat and poultry, unheated",
  "Frankfurter, meat, heated",
  "Frankfurter, meatless",
  "Frankfurter, pork",
  "Frankfurter, turkey",
  "French toast, frozen, ready-to-heat",
  "French toast, prepared from recipe, made with low fat (2%) milk",
  "Frijoles rojos volteados (Refried beans, red, canned)",
  "Frog legs, raw",
  "Frostings, chocolate, creamy, dry mix",
  "Frostings, chocolate, creamy, dry mix, prepared with butter",
  "Frostings, chocolate, creamy, dry mix, prepared with margarine",
  "Frostings, chocolate, creamy, ready-to-eat",
  "Frostings, coconut-nut, ready-to-eat",
  "Frostings, cream cheese-flavor, ready-to-eat",
  "Frostings, glaze, chocolate, prepared-from-recipe, with butter, NFSMI Recipe No. C-32",
  "Frostings, glaze, prepared-from-recipe",
  "Frostings, vanilla, creamy, dry mix",
  "Frostings, vanilla, creamy, dry mix, prepared with margarine",
  "Frostings, vanilla, creamy, ready-to-eat",
  "Frostings, white, fluffy, dry mix",
  "Frostings, white, fluffy, dry mix, prepared with water",
  "Frozen novelties, fruit and juice bars",
  "Frozen novelties, ice cream type, chocolate or caramel covered, with nuts",
  "Frozen novelties, ice cream type, sundae, prepackaged",
  "Frozen novelties, ice cream type, vanilla ice cream, light, no sugar added, chocolate coated",
  "Frozen novelties, ice type, fruit, no sugar added",
  "Frozen novelties, ice type, italian, restaurant-prepared",
  "Frozen novelties, ice type, lime",
  "Frozen novelties, ice type, pineapple-coconut",
  "Frozen novelties, ice type, pop",
  "Frozen novelties, ice type, pop, with low calorie sweetener",
  "Frozen novelties, ice type, sugar free, orange, cherry, and grape POPSICLE pops",
  "Frozen novelties, juice type, juice with cream",
  "Frozen novelties, juice type, orange",
  "Frozen novelties, juice type, POPSICLE SCRIBBLERS",
  "Frozen novelties, KLONDIKE, SLIM-A-BEAR Chocolate Cone",
  "Frozen novelties, KLONDIKE, SLIM-A-BEAR Fudge Bar, 98% fat free, no sugar added",
  "Frozen novelties, KLONDIKE, SLIM-A-BEAR Vanilla Sandwich",
  "Frozen novelties, KLONDIKE, SLIM-A-BEAR, No Sugar Added, Stickless Bar",
  "Frozen novelties, No Sugar Added CREAMSICLE Pops",
  "Frozen novelties, No Sugar Added, FUDGSICLE pops",
  "Frozen novelties, Sugar Free, CREAMSICLE Pops",
  "Frozen yogurts, chocolate",
  "Frozen yogurts, chocolate, nonfat milk, sweetened without sugar",
  "Frozen yogurts, chocolate, soft-serve",
  "Frozen yogurts, flavors other than chocolate",
  "Frozen yogurts, vanilla, soft-serve",
  "Fruit butters, apple",
  "Fruit cocktail, (peach and pineapple and pear and grape and cherry), canned, extra heavy syrup, solids and liquids",
  "Fruit cocktail, (peach and pineapple and pear and grape and cherry), canned, extra light syrup, solids and liquids",
  "Fruit cocktail, (peach and pineapple and pear and grape and cherry), canned, heavy syrup, solids and liquids",
  "Fruit cocktail, (peach and pineapple and pear and grape and cherry), canned, juice pack, solids and liquids",
  "Fruit cocktail, (peach and pineapple and pear and grape and cherry), canned, light syrup, solids and liquids",
  "Fruit cocktail, (peach and pineapple and pear and grape and cherry), canned, water pack, solids and liquids",
  "Fruit cocktail, canned, heavy syrup, drained",
  "Fruit juice smoothie, BOLTHOUSE FARMS, BERRY BOOST",
  "Fruit juice smoothie, BOLTHOUSE FARMS, GREEN GOODNESS",
  "Fruit juice smoothie, BOLTHOUSE FARMS, strawberry banana",
  "Fruit juice smoothie, NAKED JUICE, BLUE MACHINE",
  "Fruit juice smoothie, NAKED JUICE, GREEN MACHINE",
  "Fruit juice smoothie, NAKED JUICE, MIGHTY MANGO",
  "Fruit juice smoothie, NAKED JUICE, strawberry banana",
  "Fruit juice smoothie, ODWALLA, ORIGINAL SUPERFOOD",
  "Fruit juice smoothie, ODWALLA, strawberry banana",
  "Fruit salad, (peach and pear and apricot and pineapple and cherry), canned, extra heavy syrup, solids and liquids",
  "Fruit salad, (peach and pear and apricot and pineapple and cherry), canned, juice pack, solids and liquids",
  "Fruit salad, (peach and pear and apricot and pineapple and cherry), canned, light syrup, solids and liquids",
  "Fruit salad, (peach and pear and apricot and pineapple and cherry), canned, water pack, solids and liquids",
  "Fruit salad, (pineapple and papaya and banana and guava), tropical, canned, heavy syrup, solids and liquids",
  "Fruit syrup",
  "Frybread, made with lard (Apache)",
  "Frybread, made with lard (Navajo)",
  "Fungi, Cloud ears, dried",
  "Game meat , bison, ground, raw",
  "Game meat , bison, top sirloin, separable lean only, 1\" steak, cooked, broiled",
  "Game meat, antelope, cooked, roasted",
  "Game meat, antelope, raw",
  "Game meat, bear, cooked, simmered",
  "Game meat, bear, raw",
  "Game meat, beaver, cooked, roasted",
  "Game meat, beaver, raw",
  "Game meat, beefalo, composite of cuts, cooked, roasted",
  "Game meat, beefalo, composite of cuts, raw",
  "Game meat, bison, chuck, shoulder clod, separable lean only, cooked, braised",
  "Game meat, bison, chuck, shoulder clod, separable lean only, raw",
  "Game meat, bison, ground, cooked, pan-broiled",
  "Game meat, bison, ribeye, separable lean only, 1\" steak, cooked, broiled",
  "Game meat, bison, ribeye, separable lean only, trimmed to 0\" fat, raw",
  "Game meat, bison, separable lean only, cooked, roasted",
  "Game meat, bison, separable lean only, raw",
  "Game meat, bison, shoulder clod, separable lean only, trimmed to 0\" fat, raw",
  "Game meat, bison, top round, separable lean only, 1\" steak, cooked, broiled",
  "Game meat, bison, top round, separable lean only, 1\" steak, raw",
  "Game meat, bison, top sirloin, separable lean only, trimmed to 0\" fat, raw",
  "Game meat, boar, wild, cooked, roasted",
  "Game meat, boar, wild, raw",
  "Game meat, buffalo, water, cooked, roasted",
  "Game meat, buffalo, water, raw",
  "Game meat, caribou, cooked, roasted",
  "Game meat, caribou, raw",
  "Game meat, deer, cooked, roasted",
  "Game meat, deer, ground, cooked, pan-broiled",
  "Game meat, deer, ground, raw",
  "Game meat, deer, loin, separable lean only, 1\" steak, cooked, broiled",
  "Game meat, deer, raw",
  "Game meat, deer, shoulder clod, separable lean only, cooked, braised",
  "Game meat, deer, tenderloin, separable lean only, cooked, broiled",
  "Game meat, deer, top round, separable lean only, 1\" steak, cooked, broiled",
  "Game meat, elk, cooked, roasted",
  "Game meat, elk, ground, cooked, pan-broiled",
  "Game meat, elk, ground, raw",
  "Game meat, elk, loin, separable lean only, cooked, broiled",
  "Game meat, elk, raw",
  "Game meat, elk, round, separable lean only, cooked, broiled",
  "Game meat, elk, tenderloin, separable lean only, cooked, broiled",
  "Game meat, goat, cooked, roasted",
  "Game meat, goat, raw",
  "Game meat, horse, cooked, roasted",
  "Game meat, horse, raw",
  "Game meat, moose, cooked, roasted",
  "Game meat, moose, raw",
  "Game meat, muskrat, cooked, roasted",
  "Game meat, muskrat, raw",
  "Game meat, opossum, cooked, roasted",
  "Game meat, rabbit, domesticated, composite of cuts, cooked, roasted",
  "Game meat, rabbit, domesticated, composite of cuts, cooked, stewed",
  "Game meat, rabbit, domesticated, composite of cuts, raw",
  "Game meat, rabbit, wild, cooked, stewed",
  "Game meat, rabbit, wild, raw",
  "Game meat, raccoon, cooked, roasted",
  "Game meat, squirrel, cooked, roasted",
  "Game meat, squirrel, raw",
  "Garlic bread, frozen",
  "Garlic, raw",
  "Gelatin desserts, dry mix",
  "Gelatin desserts, dry mix, prepared with water",
  "Gelatin desserts, dry mix, reduced calorie, with aspartame",
  "Gelatin desserts, dry mix, reduced calorie, with aspartame, added phosphorus, potassium, sodium, vitamin C",
  "Gelatin desserts, dry mix, reduced calorie, with aspartame, no added sodium",
  "Gelatin desserts, dry mix, reduced calorie, with aspartame, prepared with water",
  "Gelatin desserts, dry mix, with added ascorbic acid, sodium-citrate and salt",
  "Gelatins, dry powder, unsweetened",
  "George Weston Bakeries, Brownberry Sage and Onion Stuffing Mix, dry",
  "George Weston Bakeries, Thomas English Muffins",
  "Ginger root, pickled, canned, with artificial sweetener",
  "Ginger root, raw",
  "Glutino, Gluten Free Cookies, Chocolate Vanilla Creme",
  "Glutino, Gluten Free Cookies, Vanilla Creme",
  "Glutino, Gluten Free Wafers, Lemon Flavored",
  "Glutino, Gluten Free Wafers, Milk Chocolate",
  "Goji berries, dried",
  "Goose, domesticated, meat and skin, cooked, roasted",
  "Goose, domesticated, meat and skin, raw",
  "Goose, domesticated, meat only, cooked, roasted",
  "Goose, domesticated, meat only, raw",
  "Goose, liver, raw",
  "Gooseberries, canned, light syrup pack, solids and liquids",
  "Gooseberries, raw",
  "Gourd, dishcloth (towelgourd), cooked, boiled, drained, with salt",
  "Gourd, dishcloth (towelgourd), cooked, boiled, drained, without salt",
  "Gourd, dishcloth (towelgourd), raw",
  "Gourd, white-flowered (calabash), cooked, boiled, drained, with salt",
  "Gourd, white-flowered (calabash), cooked, boiled, drained, without salt",
  "Gourd, white-flowered (calabash), raw",
  "Granola bar, soft, milk chocolate coated, peanut butter",
  "Grape juice, canned or bottled, unsweetened, with added ascorbic acid",
  "Grape juice, canned or bottled, unsweetened, with added ascorbic acid and calcium",
  "Grape juice, canned or bottled, unsweetened, without added ascorbic acid",
  "Grape leaves, canned",
  "Grape leaves, raw",
  "Grapefruit juice, pink or red, with added calcium",
  "Grapefruit juice, pink, raw",
  "Grapefruit juice, white, bottled, unsweetened, OCEAN SPRAY",
  "Grapefruit juice, white, canned or bottled, unsweetened",
  "Grapefruit juice, white, canned, sweetened",
  "Grapefruit juice, white, frozen concentrate, unsweetened, diluted with 3 volume water",
  "Grapefruit juice, white, frozen concentrate, unsweetened, undiluted",
  "Grapefruit juice, white, raw",
  "Grapefruit, raw, pink and red and white, all areas",
  "Grapefruit, raw, pink and red, all areas",
  "Grapefruit, raw, pink and red, California and Arizona",
  "Grapefruit, raw, pink and red, Florida",
  "Grapefruit, raw, white, all areas",
  "Grapefruit, raw, white, California",
  "Grapefruit, raw, white, Florida",
  "Grapefruit, sections, canned, juice pack, solids and liquids",
  "Grapefruit, sections, canned, light syrup pack, solids and liquids",
  "Grapefruit, sections, canned, water pack, solids and liquids",
  "Grapes, american type (slip skin), raw",
  "Grapes, canned, thompson seedless, heavy syrup pack, solids and liquids",
  "Grapes, canned, thompson seedless, water pack, solids and liquids",
  "Grapes, muscadine, raw",
  "Grapes, red or green (European type, such as Thompson seedless), raw",
  "Gravy, au jus, canned",
  "Gravy, au jus, dry",
  "Gravy, beef, canned, ready-to-serve",
  "Gravy, brown instant, dry",
  "Gravy, brown, dry",
  "Gravy, CAMPBELL'S, chicken",
  "Gravy, chicken, canned or bottled, ready-to-serve",
  "Gravy, chicken, dry",
  "Gravy, HEINZ Home Style, classic chicken",
  "Gravy, HEINZ Home Style, savory beef",
  "Gravy, instant beef, dry",
  "Gravy, instant turkey, dry",
  "Gravy, meat or poultry, low sodium, prepared",
  "Gravy, mushroom, canned",
  "Gravy, mushroom, dry, powder",
  "Gravy, onion, dry, mix",
  "Gravy, pork, dry, powder",
  "Gravy, turkey, canned, ready-to-serve",
  "Gravy, turkey, dry",
  "Gravy, unspecified type, dry",
  "Groundcherries, (cape-gooseberries or poha), raw",
  "Guanabana nectar, canned",
  "Guava nectar, canned, with added ascorbic acid",
  "Guava nectar, with sucralose, canned",
  "Guava sauce, cooked",
  "Guavas, common, raw",
  "Guavas, strawberry, raw",
  "Guinea hen, meat and skin, raw",
  "Guinea hen, meat only, raw",
  "Gums, seed gums (includes locust bean, guar)",
  "Ham and cheese loaf or roll",
  "Ham and cheese spread",
  "Ham salad spread",
  "Ham, chopped, canned",
  "Ham, chopped, not canned",
  "Ham, honey, smoked, cooked",
  "Ham, minced",
  "Ham, sliced, pre-packaged, deli meat (96% fat free, water added)",
  "Ham, sliced, regular (approximately 11% fat)",
  "Ham, smoked, extra lean, low sodium",
  "Ham, turkey, sliced, extra lean, prepackaged or deli",
  "Hazelnuts, beaked (Northern Plains Indians)",
  "Headcheese, pork",
  "Hearts of palm, canned",
  "Hearts of palm, raw",
  "Heinz, Weight Watcher, Chocolate Eclair, frozen",
  "Hominy, canned, white",
  "Hominy, canned, yellow",
  "Honey",
  "Honey roll sausage, beef",
  "HORMEL ALWAYS TENDER, Boneless Pork Loin, Fresh Pork",
  "HORMEL ALWAYS TENDER, Center Cut Chops, Fresh Pork",
  "HORMEL ALWAYS TENDER, Pork Loin Filets, Lemon Garlic-Flavored",
  "HORMEL ALWAYS TENDER, Pork Tenderloin, Peppercorn-Flavored",
  "HORMEL ALWAYS TENDER, Pork Tenderloin, Teriyaki-Flavored",
  "HORMEL Canadian Style Bacon",
  "Hormel Pillow Pak Sliced Turkey Pepperoni",
  "HORMEL, Cure 81 Ham",
  "Horned melon (Kiwano)",
  "Horseradish, prepared",
  "HOT POCKETS Ham 'N Cheese Stuffed Sandwich, frozen",
  "HOT POCKETS, CROISSANT POCKETS Chicken, Broccoli, and Cheddar Stuffed Sandwich, frozen",
  "HOT POCKETS, meatballs & mozzarella stuffed sandwich, frozen",
  "HOUSE FOODS Premium Firm Tofu",
  "HOUSE FOODS Premium Soft Tofu",
  "Huckleberries, raw (Alaska Native)",
  "Hummus, commercial",
  "Hummus, home prepared",
  "HUNGRY MAN, Salisbury Steak With Gravy, frozen, unprepared",
  "Hush puppies, prepared from recipe",
  "Hyacinth beans, mature seeds, cooked, boiled, with salt",
  "Hyacinth beans, mature seeds, cooked, boiled, without salt",
  "Hyacinth beans, mature seeds, raw",
  "Hyacinth-beans, immature seeds, cooked, boiled, drained, with salt",
  "Hyacinth-beans, immature seeds, cooked, boiled, drained, without salt",
  "Hyacinth-beans, immature seeds, raw",
  "Ice cream bar, covered with chocolate and nuts",
  "Ice cream bar, stick or nugget, with crunch coating",
  "Ice cream cone, chocolate covered, with nuts, flavors other than chocolate",
  "Ice cream cones, cake or wafer-type",
  "Ice cream cones, sugar, rolled-type",
  "Ice cream cookie sandwich",
  "Ice cream sandwich",
  "Ice cream sandwich, made with light ice cream, vanilla",
  "Ice cream sandwich, vanilla, light, no sugar added",
  "Ice cream sundae cone",
  "Ice cream, bar or stick, chocolate covered",
  "Ice cream, light, soft serve, chocolate",
  "Ice cream, lowfat, no sugar added, cone, added peanuts and chocolate sauce",
  "Ice cream, soft serve, chocolate",
  "Ice creams, BREYERS, 98% Fat Free Chocolate",
  "Ice creams, BREYERS, 98% Fat Free Vanilla",
  "Ice creams, BREYERS, All Natural Light French Chocolate",
  "Ice creams, BREYERS, All Natural Light French Vanilla",
  "Ice creams, BREYERS, All Natural Light Mint Chocolate Chip",
  "Ice creams, BREYERS, All Natural Light Vanilla",
  "Ice creams, BREYERS, All Natural Light Vanilla Chocolate Strawberry",
  "Ice creams, BREYERS, No Sugar Added, Butter Pecan",
  "Ice creams, BREYERS, No Sugar Added, Chocolate Caramel",
  "Ice creams, BREYERS, No Sugar Added, French Vanilla",
  "Ice creams, BREYERS, No Sugar Added, Vanilla",
  "Ice creams, BREYERS, No Sugar Added, Vanilla Chocolate Strawberry",
  
  "Ice creams, BREYERS, No Sugar Added, Vanilla Fudge Twirl",
  "Ice creams, chocolate",
  "Ice creams, chocolate, light",
  "Ice creams, chocolate, light, no sugar added",
  "Ice creams, chocolate, rich",
  "Ice creams, french vanilla, soft-serve",
  "Ice creams, regular, low carbohydrate, chocolate",
  "Ice creams, regular, low carbohydrate, vanilla",
  "Ice creams, strawberry",
  "Ice creams, vanilla",
  "Ice creams, vanilla, fat free",
  "Ice creams, vanilla, light",
  "Ice creams, vanilla, light, no sugar added",
  "Ice creams, vanilla, light, soft-serve",
  "Ice creams, vanilla, rich",
  "Imitation cheese, american or cheddar, low cholesterol",
  "Incaparina, dry mix (corn and soy flours), unprepared",
  "Infant formula, ABBOTT NUTRITION, ALIMENTUM ADVANCE, with iron, powder, not reconstituted, with DHA and ARA",
  "Infant formula, ABBOTT NUTRITION, SIMILAC NEOSURE, ready-to-feed, with ARA and DHA",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, ADVANCE, with iron, liquid concentrate, not reconstituted",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, ADVANCE, with iron, powder, not reconstituted",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, ADVANCE, with iron, ready-to-feed",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, ALIMENTUM, ADVANCE, ready-to-feed, with ARA and DHA",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, ALIMENTUM, with iron, ready-to-feed",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, Expert Care, Diarrhea, ready-to-feed with ARA and DHA",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, For Spit Up, powder, with ARA and DHA",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, For Spit Up, ready-to-feed, with ARA and DHA",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, GO AND GROW, powder, with ARA and DHA",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, GO AND GROW, ready-to-feed, with ARA and DHA",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, ISOMIL, ADVANCE with iron, liquid concentrate",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, ISOMIL, ADVANCE with iron, powder, not reconstituted",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, ISOMIL, ADVANCE with iron, ready-to-feed",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, ISOMIL, with iron, liquid concentrate",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, ISOMIL, with iron, powder, not reconstituted",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, ISOMIL, with iron, ready-to-feed",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, NEOSURE, powder, with ARA and DHA",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, PM 60/40, powder not reconstituted",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, SENSITIVE (LACTOSE FREE) ready-to-feed, with ARA and DHA",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, SENSITIVE, (LACTOSE FREE), liquid concentrate, with ARA and DHA",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, SENSITIVE, (LACTOSE FREE), powder, with ARA and DHA",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, with iron, liquid concentrate, not reconstituted",
  "Infant formula, ABBOTT NUTRITION, SIMILAC, with iron, powder, not reconstituted",
  "Infant Formula, GERBER GOOD START 2, GENTLE PLUS, powder",
  "Infant Formula, GERBER GOOD START 2, GENTLE PLUS, ready-to-feed",
  "Infant formula, GERBER, GOOD START 2 SOY, with iron, powder",
  "Infant formula, GERBER, GOOD START 2 Soy, with iron, ready-to-feed",
  "Infant formula, GERBER, GOOD START 2, PROTECT PLUS, powder",
  "Infant formula, GERBER, GOOD START 2, PROTECT PLUS, ready-to-feed",
  "Infant formula, GERBER, GOOD START, PROTECT PLUS, powder",
  "Infant formula, GERBER, GOOD START, PROTECT PLUS, ready-to-feed",
  "Infant formula, MEAD JOHNSON, Enfamil 24, ready to feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, Enfamil Enspire Powder, with ARA and DHA, not reconstituted",
  "Infant formula, MEAD JOHNSON, Enfamil for Supplementing, powder, with ARA and DHA, not reconstituted",
  "Infant formula, MEAD JOHNSON, Enfamil for Supplementing, ready to feed, with ARA and DHA",
  "Infant Formula, MEAD JOHNSON, ENFAMIL GENTLEASE, with iron, prepared from powder",
  "Infant formula, MEAD JOHNSON, ENFAMIL LIPIL, with iron, ready-to-feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, Enfamil Premature 30 Calories, ready to feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, Enfamil Premature High Protein 24 Calories, ready to feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, Enfamil Reguline Powder, with ARA and DHA, not reconstituted",
  "Infant formula, MEAD JOHNSON, Enfamil Reguline, ready to feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, ENFAMIL, AR, powder, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, ENFAMIL, AR, ready-to-feed, with ARA and DHA",
  "Infant Formula, MEAD JOHNSON, ENFAMIL, ENFACARE, ready-to-feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, ENFAMIL, ENFACARE, with iron, powder, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, ENFAMIL, ENFAGROW, GENTLEASE, Toddler transitions, with ARA and DHA, powder",
  "Infant formula, MEAD JOHNSON, ENFAMIL, Enfagrow, Soy, Toddler ready-to-feed",
  "Infant formula, MEAD JOHNSON, ENFAMIL, ENFAGROW, Soy, Toddler transitions, with ARA and DHA, powder",
  "Infant Formula, MEAD JOHNSON, ENFAMIL, GENTLEASE, with ARA and DHA powder not reconstituted",
  "Infant formula, MEAD JOHNSON, ENFAMIL, Infant, ready-to-feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, ENFAMIL, Infant, with iron, liquid concentrate, with ARA and DHA, reconstituted",
  "Infant formula, MEAD JOHNSON, ENFAMIL, Infant, with iron, powder, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, ENFAMIL, LIPIL, low iron, liquid concentrate, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, ENFAMIL, LIPIL, low iron, ready to feed, with ARA and DHA",
  "Infant Formula, MEAD JOHNSON, ENFAMIL, Newborn, with ARA and DHA, powder",
  "Infant Formula, MEAD JOHNSON, ENFAMIL, Newborn, with DHA and ARA, ready-to-feed",
  "Infant formula, MEAD JOHNSON, ENFAMIL, NUTRAMIGEN AA, ready-to-feed",
  "Infant formula, MEAD JOHNSON, ENFAMIL, NUTRAMIGEN WITH LGG, with iron, powder, not reconstituted, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, ENFAMIL, NUTRAMIGEN, PurAmino, powder, not reconstituted",
  "Infant formula, MEAD JOHNSON, ENFAMIL, NUTRAMIGEN, with iron, liquid concentrate not reconstituted, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, ENFAMIL, NUTRAMIGEN, with iron, ready-to-feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, ENFAMIL, Premature, 20 calories ready-to-feed Low iron",
  "Infant formula, MEAD JOHNSON, ENFAMIL, Premature, 24 calories ready-to-feed Low iron",
  "Infant formula, MEAD JOHNSON, ENFAMIL, Premature, with iron, 20 calories, ready-to-feed",
  "Infant formula, MEAD JOHNSON, ENFAMIL, Premature, with iron, 24 calories, ready-to-feed",
  "Infant Formula, MEAD JOHNSON, ENFAMIL, Premium LIPIL, Infant, Liquid concentrate, not reconstituted",
  "Infant Formula, MEAD JOHNSON, ENFAMIL, Premium LIPIL, Infant, powder",
  "Infant Formula, MEAD JOHNSON, ENFAMIL, Premium, Infant, Liquid concentrate, not reconstituted",
  "Infant Formula, MEAD JOHNSON, ENFAMIL, Premium, Infant, powder",
  "Infant Formula, MEAD JOHNSON, ENFAMIL, Premium, Infant, ready-to-feed",
  "Infant formula, MEAD JOHNSON, ENFAMIL, PROSOBEE, liquid concentrate, reconstituted, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, ENFAMIL, PROSOBEE, with iron, powder, not reconstituted, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, ENFAMIL, with iron, powder",
  "Infant formula, MEAD JOHNSON, Gentlease, ready to feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, NEXT STEP PROSOBEE, powder, not reconstituted",
  "Infant formula, MEAD JOHNSON, NEXT STEP, PROSOBEE LIPIL, powder, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, NEXT STEP, PROSOBEE, LIPIL, ready to feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, Pregestimil 20 Calories, ready to feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, Pregestimil 24 Calories, ready to feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, PREGESTIMIL, with iron, powder, with ARA and DHA, not reconstituted",
  "Infant formula, MEAD JOHNSON, PREGESTIMIL, with iron, with ARA and DHA, prepared from powder",
  "Infant formula, MEAD JOHNSON, PROSOBEE, with iron, ready to feed, with ARA and DHA",
  "Infant formula, MEAD JOHNSON, PROSOBEE, with iron, ready-to-feed",
  "Infant formula, MEAD JOHNSON,NEXT STEP PROSOBEE, prepared from powder",
  "Infant formula, NESTLE, GOOD START ESSENTIALS SOY, with iron, powder",
  "Infant formula, NESTLE, GOOD START SOY, with ARA and DHA, powder",
  "Infant formula, NESTLE, GOOD START SOY, with DHA and ARA, liquid concentrate",
  "Infant formula, NESTLE, GOOD START SOY, with DHA and ARA, ready-to-feed",
  "Infant formula, NESTLE, GOOD START SUPREME, with iron, DHA and ARA, prepared from liquid concentrate",
  "Infant formula, NESTLE, GOOD START SUPREME, with iron, DHA and ARA, ready-to-feed",
  "Infant formula, NESTLE, GOOD START SUPREME, with iron, liquid concentrate, not reconstituted",
  "Infant formula, NESTLE, GOOD START SUPREME, with iron, powder",
  "Infant formula, PBM PRODUCTS, store brand, liquid concentrate, not reconstituted",
  "Infant formula, PBM PRODUCTS, store brand, powder",
  "Infant formula, PBM PRODUCTS, store brand, ready-to-feed",
  "Infant formula, PBM PRODUCTS, store brand, soy, liquid concentrate, not reconstituted",
  "Infant formula, PBM PRODUCTS, store brand, soy, powder",
  "Infant formula, PBM PRODUCTS, store brand, soy, ready-to-feed",
  "Interstate Brands Corp, Wonder Hamburger Rolls",
  "Jackfruit, canned, syrup pack",
  "Jackfruit, raw",
  "Jams and preserves",
  "Jams and preserves, apricot",
  "Jams and preserves, no sugar (with sodium saccharin), any flavor",
  "Jams, preserves, marmalade, reduced sugar",
  "Jams, preserves, marmalades, sweetened with fruit juice",
  "Java-plum, (jambolan), raw",
  "Jellies",
  "Jellies, no sugar (with sodium saccharin), any flavors",
  "Jellies, reduced sugar, home preserved",
  "Jellyfish, dried, salted",
  "Jerusalem-artichokes, raw",
  "Jew's ear, (pepeao), raw",
  "JIMMY DEAN, Sausage, Egg, and Cheese Breakfast Biscuit, frozen, unprepared",
  "Juice, apple and grape blend, with added ascorbic acid",
  "Juice, apple, grape and pear blend, with added ascorbic acid and calcium",
  "Jujube, Chinese, fresh, dried",
  "Jujube, raw",
  "Jute, potherb, cooked, boiled, drained, with salt",
  "Jute, potherb, cooked, boiled, drained, without salt",
  "Jute, potherb, raw",
  "Kale, cooked, boiled, drained, with salt",
  "Kale, cooked, boiled, drained, without salt",
  "Kale, frozen, cooked, boiled, drained, with salt",
  "Kale, frozen, cooked, boiled, drained, without salt",
  "Kale, frozen, unprepared",
  "Kale, raw",
  "Kanpyo, (dried gourd strips)",
  "Keebler, Keebler Chocolate Graham SELECTS",
  "Kefir, lowfat, plain, LIFEWAY",
  "Kefir, lowfat, strawberry, LIFEWAY",
  "Keikitos (muffins), Latino bakery item",
  "KFC, biscuit",
  "KFC, Coleslaw",
  "KFC, Crispy Chicken Strips",
  "KFC, Fried Chicken, EXTRA CRISPY, Breast, meat and skin with breading",
  "KFC, Fried Chicken, EXTRA CRISPY, Breast, meat only, skin and breading removed",
  "KFC, Fried Chicken, EXTRA CRISPY, Drumstick, meat and skin with breading",
  "KFC, Fried Chicken, EXTRA CRISPY, Drumstick, meat only, skin and breading removed",
  "KFC, Fried Chicken, EXTRA CRISPY, Skin and Breading",
  "KFC, Fried Chicken, EXTRA CRISPY, Thigh, meat and skin with breading",
  "KFC, Fried Chicken, EXTRA CRISPY, Thigh, meat only, skin and breading removed",
  "KFC, Fried Chicken, EXTRA CRISPY, Wing, meat and skin with breading",
  "KFC, Fried Chicken, EXTRA CRISPY, Wing, meat only, skin and breading removed",
  "KFC, Fried Chicken, ORIGINAL RECIPE, Breast, meat and skin with breading",
  "KFC, Fried Chicken, ORIGINAL RECIPE, Breast, meat only, skin and breading removed",
  "KFC, Fried Chicken, ORIGINAL RECIPE, Drumstick, meat and skin with breading",
  "KFC, Fried Chicken, ORIGINAL RECIPE, Drumstick, meat only, skin and breading removed",
  "KFC, Fried Chicken, ORIGINAL RECIPE, Skin and Breading",
  "KFC, Fried Chicken, ORIGINAL RECIPE, Thigh, meat and skin with breading",
  "KFC, Fried Chicken, ORIGINAL RECIPE, Thigh, meat only, skin and breading removed",
  "KFC, Fried Chicken, ORIGINAL RECIPE, Wing, meat and skin with breading",
  "KFC, Fried Chicken, ORIGINAL RECIPE, Wing, meat only, skin and breading removed",
  "KFC, Popcorn Chicken",
  "Kielbasa, fully cooked, grilled",
  "Kielbasa, fully cooked, pan-fried",
  "Kielbasa, fully cooked, unheated",
  "Kielbasa, Polish, turkey and beef, smoked",
  "Kiwifruit, green, raw",
  "Kiwifruit, ZESPRI SunGold, raw",
  "Knackwurst, knockwurst, pork, beef",
  "Kohlrabi, cooked, boiled, drained, with salt",
  "Kohlrabi, cooked, boiled, drained, without salt",
  "Kohlrabi, raw",
  "KRAFT BREAKSTONE'S FREE Fat Free Sour Cream",
  "KRAFT BREAKSTONE'S Reduced Fat Sour Cream",
  "KRAFT CHEEZ WHIZ LIGHT Pasteurized Process Cheese Product",
  "KRAFT CHEEZ WHIZ Pasteurized Process Cheese Sauce",
  "Kraft Foods, Shake N Bake Original Recipe, Coating for Pork, dry",
  "KRAFT FREE Singles American Nonfat Pasteurized Process Cheese Product",
  "KRAFT VELVEETA LIGHT Reduced Fat Pasteurized Process Cheese Product",
  "KRAFT VELVEETA Pasteurized Process Cheese Spread",
  "Kraft, Stove Top Stuffing Mix Chicken Flavor",
  "Kumquats, raw",
  "Lamb, Australian, ground, 85% lean / 15% fat, raw",
  "Lamb, Australian, imported, fresh, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8\" fat, cooked",
  "Lamb, Australian, imported, fresh, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, composite of trimmed retail cuts, separable lean only, trimmed to 1/8\" fat, cooked",
  "Lamb, Australian, imported, fresh, composite of trimmed retail cuts, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, external fat, cooked",
  "Lamb, Australian, imported, fresh, external fat, raw",
  "Lamb, Australian, imported, fresh, foreshank, separable lean and fat, trimmed to 1/8\" fat, cooked, braised",
  "Lamb, Australian, imported, fresh, foreshank, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, foreshank, separable lean only, trimmed to 1/8\" fat, cooked, braised",
  "Lamb, Australian, imported, fresh, foreshank, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, bottom, boneless, separable lean and fat, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, leg, bottom, boneless, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, bottom, boneless, separable lean only, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, leg, bottom, boneless, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, center slice, bone-in, separable lean and fat, trimmed to 1/8\" fat, cooked, broiled",
  "Lamb, Australian, imported, fresh, leg, center slice, bone-in, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, center slice, bone-in, separable lean only, trimmed to 1/8\" fat, cooked, broiled",
  "Lamb, Australian, imported, fresh, leg, center slice, bone-in, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, hindshank, heel on, bone-in, separable lean and fat, trimmed to 1/8\" fat, cooked, braised",
  "Lamb, Australian, imported, fresh, leg, hindshank, heel on, bone-in, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, hindshank, heel on, bone-in, separable lean only, trimmed to 1/8\" fat, cooked, braised",
  "Lamb, Australian, imported, fresh, leg, hindshank, heel on, bone-in, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, shank half, separable lean and fat, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, leg, shank half, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, shank half, separable lean only, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, leg, shank half, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, sirloin chops, boneless, separable lean and fat, trimmed to 1/8\" fat, cooked, broiled",
  "Lamb, Australian, imported, fresh, leg, sirloin chops, boneless, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, sirloin chops, boneless, separable lean only, trimmed to 1/8\" fat, cooked, broiled",
  "Lamb, Australian, imported, fresh, leg, sirloin chops, boneless, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, sirloin half, boneless, separable lean and fat, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, leg, sirloin half, boneless, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, sirloin half, boneless, separable lean only, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, leg, sirloin half, boneless, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, trotter off, bone-in, separable lean and fat, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, leg, trotter off, bone-in, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, trotter off, bone-in, separable lean only, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, leg, trotter off, bone-in, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, leg, whole (shank and sirloin), separable lean only, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, leg, whole (shank and sirloin), separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, loin, separable lean and fat, trimmed to 1/8\" fat, cooked, broiled",
  "Lamb, Australian, imported, fresh, loin, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, loin, separable lean only, trimmed to 1/8\" fat, cooked, broiled",
  "Lamb, Australian, imported, fresh, loin, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, rack, roast, frenched, bone-in, separable lean and fat, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, rack, roast, frenched, bone-in, separable lean only, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, rack, roast, frenched, denuded, bone-in, separable lean and fat, trimmed to 0\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, rack, roast, frenched, denuded, bone-in, separable lean only, trimmed to 0\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, rib chop, frenched, bone-in, separable lean and fat, trimmed to 1/8\" fat, cooked, grilled",
  "Lamb, Australian, imported, fresh, rib chop, frenched, bone-in, separable lean only, trimmed to 1/8\" fat, cooked, grilled",
  "Lamb, Australian, imported, fresh, rib chop, frenched, denuded, bone-in, separable lean and fat, trimmed to 0\" fat, cooked, grilled",
  "Lamb, Australian, imported, fresh, rib chop, frenched, denuded, bone-in, separable lean only, trimmed to 0\" fat, cooked, grilled",
  "Lamb, Australian, imported, fresh, rib chop/rack roast, frenched, bone-in, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, rib chop/rack roast, frenched, bone-in, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, seam fat, cooked",
  "Lamb, Australian, imported, fresh, seam fat, raw",
  "Lamb, Australian, imported, fresh, separable fat, cooked",
  "Lamb, Australian, imported, fresh, separable fat, raw",
  "Lamb, Australian, imported, fresh, shoulder, blade, separable lean only, trimmed to 1/8\" fat, cooked, broiled",
  "Lamb, Australian, imported, fresh, shoulder, arm, separable lean and fat, trimmed to 1/8\" fat, cooked, braised",
  "Lamb, Australian, imported, fresh, shoulder, arm, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, shoulder, arm, separable lean only, trimmed to 1/8\" fat, cooked, braised",
  "Lamb, Australian, imported, fresh, shoulder, arm, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, shoulder, blade, separable lean and fat, trimmed to 1/8\" fat, cooked, broiled",
  "Lamb, Australian, imported, fresh, shoulder, blade, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, shoulder, blade, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8\" fat, cooked",
  "Lamb, Australian, imported, fresh, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, shoulder, whole (arm and blade), separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, shoulder, whole (arm and blade), separable lean only, trimmed to 1/8\" fat, cooked",
  "Lamb, Australian, imported, fresh, tenderloin, boneless, separable lean and fat, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, tenderloin, boneless, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, Australian, imported, fresh, tenderloin, boneless, separable lean only, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, Australian, imported, fresh, tenderloin, boneless, separable lean only, trimmed to 1/8\" fat, raw",
  "Lamb, composite of trimmed retail cuts, separable fat, trimmed to 1/4\" fat, choice, cooked",
  "Lamb, composite of trimmed retail cuts, separable fat, trimmed to 1/4\" fat, choice, raw",
  "Lamb, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/4\" fat, choice, cooked",
  "Lamb, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/4\" fat, choice, raw",
  "Lamb, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8\" fat, choice, cooked",
  "Lamb, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
  "Lamb, composite of trimmed retail cuts, separable lean only, trimmed to 1/4\" fat, choice, cooked",
  "Lamb, composite of trimmed retail cuts, separable lean only, trimmed to 1/4\" fat, choice, raw",
  "Lamb, cubed for stew or kabob (leg and shoulder), separable lean only, trimmed to 1/4\" fat, cooked, braised",
  "Lamb, cubed for stew or kabob (leg and shoulder), separable lean only, trimmed to 1/4\" fat, cooked, broiled",
  "Lamb, cubed for stew or kabob (leg and shoulder), separable lean only, trimmed to 1/4\" fat, raw",
  "Lamb, foreshank, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, braised",
  "Lamb, foreshank, separable lean and fat, trimmed to 1/4\" fat, choice, raw",
  "Lamb, foreshank, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
  "Lamb, foreshank, separable lean and fat, trimmed to 1/8\" fat, cooked, braised",
  "Lamb, foreshank, separable lean only, trimmed to 1/4\" fat, choice, cooked, braised",
  "Lamb, foreshank, separable lean only, trimmed to 1/4\" fat, choice, raw",
  "Lamb, ground, cooked, broiled",
  "Lamb, ground, raw",
  "Lamb, leg, shank half, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, leg, shank half, separable lean and fat, trimmed to 1/4\" fat, choice, raw",
  "Lamb, leg, shank half, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, roasted",
  "Lamb, leg, shank half, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
  "Lamb, leg, shank half, separable lean only, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, leg, shank half, separable lean only, trimmed to 1/4\" fat, choice, raw",
  "Lamb, leg, sirloin half, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, leg, sirloin half, separable lean and fat, trimmed to 1/4\" fat, choice, raw",
  "Lamb, leg, sirloin half, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, roasted",
  "Lamb, leg, sirloin half, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
  "Lamb, leg, sirloin half, separable lean only, trimmed to 1/4\" fat, choice, cooked, roasted",

  "Lamb, leg, sirloin half, separable lean only, trimmed to 1/4\" fat, choice, raw",
  "Lamb, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/4\" fat, choice, raw",
  "Lamb, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/8\" fat, choice, cooked, roasted",
  "Lamb, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/8\" fat, choice, raw",
  "Lamb, leg, whole (shank and sirloin), separable lean only, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, leg, whole (shank and sirloin), separable lean only, trimmed to 1/4\" fat, choice, raw",
  "Lamb, loin, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, broiled",
  "Lamb, loin, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, loin, separable lean and fat, trimmed to 1/4\" fat, choice, raw",
  "Lamb, loin, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, broiled",
  "Lamb, loin, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, roasted",
  "Lamb, loin, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
  "Lamb, loin, separable lean only, trimmed to 1/4\" fat, choice, cooked, broiled",
  "Lamb, loin, separable lean only, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, loin, separable lean only, trimmed to 1/4\" fat, choice, raw",
  "Lamb, New Zealand, imported, brains, cooked, soaked and fried",
  "Lamb, New Zealand, imported, brains, raw",
  "Lamb, New Zealand, imported, breast, separable lean only, cooked, braised",
  "Lamb, New Zealand, imported, breast, separable lean only, raw",
  "Lamb, New Zealand, imported, chump, boneless, separable lean only, cooked, fast roasted",
  "Lamb, New Zealand, imported, chump, boneless, separable lean only, raw",
  "Lamb, New Zealand, imported, flap, boneless, separable lean and fat, cooked, braised",
  "Lamb, New Zealand, imported, flap, boneless, separable lean and fat, raw",
  "Lamb, New Zealand, imported, flap, boneless, separable lean only, cooked, braised",
  "Lamb, New Zealand, imported, flap, boneless, separable lean only, raw",
  "Lamb, New Zealand, imported, fore-shank, separable lean and fat, cooked, braised",
  "Lamb, New Zealand, imported, fore-shank, separable lean and fat, raw",
  "Lamb, New Zealand, imported, fore-shank, separable lean only, cooked, braised",
  "Lamb, New Zealand, imported, fore-shank, separable lean only, raw",
  "Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable fat, cooked",
  "Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable fat, raw",
  "Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable lean and fat, cooked",
  "Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable lean and fat, raw",
  "Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8\" fat, cooked",
  "Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable lean only, cooked",
  "Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable lean only, raw",
  "Lamb, New Zealand, imported, frozen, foreshank, separable lean and fat, trimmed to 1/8\" fat, cooked, braised",
  "Lamb, New Zealand, imported, frozen, foreshank, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, New Zealand, imported, frozen, leg, whole (shank and sirloin), separable lean and fat, cooked, roasted",
  "Lamb, New Zealand, imported, frozen, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, New Zealand, imported, frozen, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, New Zealand, imported, frozen, leg, whole (shank and sirloin), separable lean only, cooked, roasted",
  "Lamb, New Zealand, imported, frozen, loin, separable lean and fat, cooked, broiled",
  "Lamb, New Zealand, imported, frozen, loin, separable lean and fat, trimmed to 1/8\" fat, cooked, broiled",
  "Lamb, New Zealand, imported, frozen, loin, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, New Zealand, imported, frozen, loin, separable lean only, cooked, broiled",
  "Lamb, New Zealand, imported, frozen, rib, separable lean and fat, trimmed to 1/8\" fat, cooked, roasted",
  "Lamb, New Zealand, imported, frozen, rib, separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, New Zealand, imported, frozen, shoulder, whole (arm and blade), separable lean and fat, cooked, braised",
  "Lamb, New Zealand, imported, frozen, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8\" fat, cooked, braised",
  "Lamb, New Zealand, imported, frozen, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8\" fat, raw",
  "Lamb, New Zealand, imported, frozen, shoulder, whole (arm and blade), separable lean only, cooked, braised",
  "Lamb, New Zealand, imported, ground lamb, cooked, braised",
  "Lamb, New Zealand, imported, ground lamb, raw",
  "Lamb, New Zealand, imported, heart, cooked, soaked and simmered",
  "Lamb, New Zealand, imported, heart, raw",
  "Lamb, New Zealand, imported, hind-shank, separable lean and fat, cooked, braised",
  "Lamb, New Zealand, imported, hind-shank, separable lean and fat, raw",
  "Lamb, New Zealand, imported, hind-shank, separable lean only, cooked, braised",
  "Lamb, New Zealand, imported, hind-shank, separable lean only, raw",
  "Lamb, New Zealand, imported, Intermuscular fat, cooked",
  "Lamb, New Zealand, imported, Intermuscular fat, raw",
  "Lamb, New Zealand, imported, kidney, cooked, soaked and fried",
  "Lamb, New Zealand, imported, kidney, raw",
  "Lamb, New Zealand, imported, leg chop/steak, bone-in, separable lean and fat, cooked, fast fried",
  "Lamb, New Zealand, imported, leg chop/steak, bone-in, separable lean and fat, raw",
  "Lamb, New Zealand, imported, leg chop/steak, bone-in, separable lean only, cooked, fast fried",
  "Lamb, New Zealand, imported, leg chop/steak, bone-in, separable lean only, raw",
  "Lamb, New Zealand, imported, liver, cooked, soaked and fried",
  "Lamb, New Zealand, imported, liver, raw",
  "Lamb, New Zealand, imported, loin chop, separable lean and fat, cooked, fast fried",
  "Lamb, New Zealand, imported, loin chop, separable lean and fat, raw",
  "Lamb, New Zealand, imported, loin chop, separable lean only, cooked, fast fried",
  "Lamb, New Zealand, imported, loin chop, separable lean only, raw",
  "Lamb, New Zealand, imported, loin saddle, separable lean and fat, cooked, fast roasted",
  "Lamb, New Zealand, imported, loin saddle, separable lean and fat, raw",
  "Lamb, New Zealand, imported, loin saddle, separable lean only, cooked, fast roasted",
  "Lamb, New Zealand, imported, loin saddle, separable lean only, raw",
  "Lamb, New Zealand, imported, loin, boneless, separable lean and fat, cooked, fast roasted",
  "Lamb, New Zealand, imported, loin, boneless, separable lean and fat, raw",
  "Lamb, New Zealand, imported, loin, boneless, separable lean only, cooked, fast roasted",
  "Lamb, New Zealand, imported, loin, boneless, separable lean only, raw",
  "Lamb, New Zealand, imported, neck chops, separable lean and fat, cooked, braised",
  "Lamb, New Zealand, imported, neck chops, separable lean and fat, raw",
  "Lamb, New Zealand, imported, neck chops, separable lean only, cooked, braised",
  "Lamb, New Zealand, imported, neck chops, separable lean only, raw",
  "Lamb, New Zealand, imported, netted shoulder, rolled, boneless, separable lean and fat, cooked, slow roasted",
  "Lamb, New Zealand, imported, netted shoulder, rolled, boneless, separable lean and fat, raw",
  "Lamb, New Zealand, imported, netted shoulder, rolled, boneless, separable lean only, cooked, slow roasted",
  "Lamb, New Zealand, imported, netted shoulder, rolled, boneless, separable lean only, raw",
  "Lamb, New Zealand, imported, rack - fully frenched, separable lean and fat, cooked, fast roasted",
  "Lamb, New Zealand, imported, rack - fully frenched, separable lean and fat, raw",
  "Lamb, New Zealand, imported, rack - fully frenched, separable lean only, cooked, fast roasted",
  "Lamb, New Zealand, imported, rack - fully frenched, separable lean only, raw",
  "Lamb, New Zealand, imported, rack - partly frenched, separable lean and fat, cooked, fast roasted",
  "Lamb, New Zealand, imported, rack - partly frenched, separable lean and fat, raw",
  "Lamb, New Zealand, imported, rack - partly frenched, separable lean only, cooked, fast roasted",
  "Lamb, New Zealand, imported, rack - partly frenched, separable lean only, raw",
  "Lamb, New Zealand, imported, square-cut shoulder chops, separable lean and fat, cooked, braised",
  "Lamb, New Zealand, imported, square-cut shoulder chops, separable lean and fat, raw",
  "Lamb, New Zealand, imported, square-cut shoulder chops, separable lean only, cooked, braised",
  "Lamb, New Zealand, imported, square-cut shoulder chops, separable lean only, raw",
  "Lamb, New Zealand, imported, square-cut shoulder, separable lean and fat, cooked, slow roasted",
  "Lamb, New Zealand, imported, square-cut shoulder, separable lean and fat, raw",
  "Lamb, New Zealand, imported, square-cut shoulder, separable lean only, cooked, slow roasted",
  "Lamb, New Zealand, imported, square-cut shoulder, separable lean only, raw",
  "Lamb, New Zealand, imported, subcutaneous fat, cooked",
  "Lamb, New Zealand, imported, subcutaneous fat, raw",
  "Lamb, New Zealand, imported, sweetbread, cooked, soaked and simmered",
  "Lamb, New Zealand, imported, sweetbread, raw",
  "Lamb, New Zealand, imported, tenderloin, separable lean and fat, cooked, fast fried",
  "Lamb, New Zealand, imported, tenderloin, separable lean and fat, raw",
  "Lamb, New Zealand, imported, tenderloin, separable lean only, cooked, fast fried",
  "Lamb, New Zealand, imported, tenderloin, separable lean only, raw",
  "Lamb, New Zealand, imported, testes, cooked, soaked and fried",
  "Lamb, New Zealand, imported, testes, raw",
  "Lamb, New Zealand, imported, tongue - swiss cut, cooked, soaked and simmered",
  "Lamb, New Zealand, imported, tongue - swiss cut, raw",
  "Lamb, New Zealand, imported, tunnel-boned leg, chump off, shank off, separable lean and fat, cooked, slow roasted",
  "Lamb, New Zealand, imported, tunnel-boned leg, chump off, shank off, separable lean and fat, raw",
  "Lamb, New Zealand, imported, tunnel-boned leg, chump off, shank off, separable lean only, cooked, slow roasted",
  "Lamb, New Zealand, imported, tunnel-boned leg, chump off, shank off, separable lean only, raw",
  "Lamb, rib, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, broiled",
  "Lamb, rib, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, rib, separable lean and fat, trimmed to 1/4\" fat, choice, raw",
  "Lamb, rib, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, broiled",
  "Lamb, rib, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, roasted",
  "Lamb, rib, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
  "Lamb, rib, separable lean only, trimmed to 1/4\" fat, choice, cooked, broiled",
  "Lamb, rib, separable lean only, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, rib, separable lean only, trimmed to 1/4\" fat, choice, raw",
  "Lamb, shoulder, arm, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, braised",
  "Lamb, shoulder, arm, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, broiled",
  "Lamb, shoulder, arm, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, shoulder, arm, separable lean and fat, trimmed to 1/4\" fat, choice, raw",
  "Lamb, shoulder, arm, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, braised",
  "Lamb, shoulder, arm, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
  "Lamb, shoulder, arm, separable lean and fat, trimmed to 1/8\" fat, choice, roasted",
  "Lamb, shoulder, arm, separable lean and fat, trimmed to 1/8\" fat, cooked, broiled",
  "Lamb, shoulder, arm, separable lean only, trimmed to 1/4\" fat, choice, cooked, braised",
  "Lamb, shoulder, arm, separable lean only, trimmed to 1/4\" fat, choice, cooked, broiled",
  "Lamb, shoulder, arm, separable lean only, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, shoulder, arm, separable lean only, trimmed to 1/4\" fat, choice, raw",
  "Lamb, shoulder, blade, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, braised",
  "Lamb, shoulder, blade, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, broiled",
  "Lamb, shoulder, blade, separable lean and fat, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, shoulder, blade, separable lean and fat, trimmed to 1/4\" fat, choice, raw",
  "Lamb, shoulder, blade, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, braised",
  "Lamb, shoulder, blade, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, broiled",
  "Lamb, shoulder, blade, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, roasted",
  "Lamb, shoulder, blade, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
  "Lamb, shoulder, blade, separable lean only, trimmed to 1/4\" fat, choice, cooked, braised",
  "Lamb, shoulder, blade, separable lean only, trimmed to 1/4\" fat, choice, cooked, broiled",
  "Lamb, shoulder, blade, separable lean only, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, shoulder, blade, separable lean only, trimmed to 1/4\" fat, choice, raw",
  "Lamb, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/4\" fat, choice, cooked, braised",
  "Lamb, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/4\" fat, choice, cooked, broiled",
  "Lamb, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/4\" fat, choice, raw",
  "Lamb, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8\" fat, choice, cooked, braised",
  "Lamb, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8\" fat, choice, cooked, broiled",
  "Lamb, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8\" fat, choice, cooked, roasted",
  "Lamb, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8\" fat, choice, raw",
  "Lamb, shoulder, whole (arm and blade), separable lean only, trimmed to 1/4\" fat, choice, cooked, braised",
  "Lamb, shoulder, whole (arm and blade), separable lean only, trimmed to 1/4\" fat, choice, cooked, broiled",
  "Lamb, shoulder, whole (arm and blade), separable lean only, trimmed to 1/4\" fat, choice, cooked, roasted",
  "Lamb, shoulder, whole (arm and blade), separable lean only, trimmed to 1/4\" fat, choice, raw",
  "Lamb, variety meats and by-products, brain, cooked, braised",
  "Lamb, variety meats and by-products, brain, cooked, pan-fried",
  "Lamb, variety meats and by-products, brain, raw",
  "Lamb, variety meats and by-products, heart, cooked, braised",
  "Lamb, variety meats and by-products, heart, raw",
  "Lamb, variety meats and by-products, kidneys, cooked, braised",
  "Lamb, variety meats and by-products, kidneys, raw",
  "Lamb, variety meats and by-products, liver, cooked, braised",
  "Lamb, variety meats and by-products, liver, cooked, pan-fried",
  "Lamb, variety meats and by-products, liver, raw",
  "Lamb, variety meats and by-products, lungs, cooked, braised",
  "Lamb, variety meats and by-products, lungs, raw",
  "Lamb, variety meats and by-products, mechanically separated, raw",
  "Lamb, variety meats and by-products, pancreas, cooked, braised",
  "Lamb, variety meats and by-products, pancreas, raw",
  "Lamb, variety meats and by-products, spleen, cooked, braised",
  "Lamb, variety meats and by-products, spleen, raw",
  "Lamb, variety meats and by-products, tongue, cooked, braised",
  "Lamb, variety meats and by-products, tongue, raw",
  "Lambsquarters, cooked, boiled, drained, with salt",
  "Lambsquarters, cooked, boiled, drained, without salt",
  "Lambsquarters, raw",
  "Lambsquarters, raw (Northern Plains Indians)",
  "Lambsquarters, steamed (Northern Plains Indians)",
  "Lard",
  "Lasagna with meat & sauce, frozen entree",
  "Lasagna with meat & sauce, low-fat, frozen entree",
  "Lasagna with meat sauce, frozen, prepared",
  "Lasagna, cheese, frozen, prepared",
  "Lasagna, cheese, frozen, unprepared",
  "Lasagna, Vegetable, frozen, baked",
  "LEAN POCKETS, Ham N Cheddar",
  "Lean Pockets, Meatballs & Mozzarella",
  "Leavening agents, baking powder, double-acting, sodium aluminum sulfate",
  "Leavening agents, baking powder, double-acting, straight phosphate",
  "Leavening agents, baking powder, low-sodium",
  "Leavening agents, baking soda",
  "Leavening agents, cream of tartar",
  "Leavening agents, yeast, baker's, active dry",
  "Leavening agents, yeast, baker's, compressed",
  "Lebanon bologna, beef",
  "Leeks, (bulb and lower leaf-portion), cooked, boiled, drained, with salt",
  "Leeks, (bulb and lower leaf-portion), cooked, boiled, drained, without salt",
  "Leeks, (bulb and lower leaf-portion), raw",
  "Leeks, (bulb and lower-leaf portion), freeze-dried",
  "Lemon grass (citronella), raw",
  "Lemon juice from concentrate, bottled, CONCORD",
  "Lemon juice from concentrate, bottled, REAL LEMON",
  "Lemon juice from concentrate, canned or bottled",
  "Lemon juice, raw",
  "Lemon peel, raw",
  "Lemonade, frozen concentrate, pink",
  "Lemonade, frozen concentrate, white",
  "Lemonade, frozen concentrate, white, prepared with water",
  "Lemonade, powder, prepared with water",
  "Lemons, raw, without peel",
  "Lentils, mature seeds, cooked, boiled, with salt",
  "Lentils, mature seeds, cooked, boiled, without salt",
  "Lentils, pink or red, raw",
  "Lentils, raw",
  "Lentils, sprouted, cooked, stir-fried, with salt",
  "Lentils, sprouted, cooked, stir-fried, without salt",
  "Lentils, sprouted, raw",
  "Lettuce, butterhead (includes boston and bibb types), raw",
  "Lettuce, cos or romaine, raw",
  "Lettuce, green leaf, raw",
  "Lettuce, iceberg (includes crisphead types), raw",
  "Lettuce, red leaf, raw",
  "Light ice cream, Creamsicle",
  "Light Ice Cream, soft serve, blended with cookie pieces",
  "Light Ice Cream, soft serve, blended with milk chocolate candies",
  "Lima beans, immature seeds, canned, no salt added, solids and liquids",
  "Lima beans, immature seeds, canned, regular pack, solids and liquids",
  "Lima beans, immature seeds, cooked, boiled, drained, with salt",
  "Lima beans, immature seeds, cooked, boiled, drained, without salt",
  "Lima beans, immature seeds, frozen, baby, cooked, boiled, drained, with salt",
  "Lima beans, immature seeds, frozen, baby, cooked, boiled, drained, without salt",
  "Lima beans, immature seeds, frozen, baby, unprepared",
  "Lima beans, immature seeds, frozen, fordhook, cooked, boiled, drained, with salt",
  "Lima beans, immature seeds, frozen, fordhook, cooked, boiled, drained, without salt",
  "Lima beans, immature seeds, frozen, fordhook, unprepared",

  "Lima beans, immature seeds, raw",
  "Lima beans, large, mature seeds, canned",
  "Lima beans, large, mature seeds, cooked, boiled, with salt",
  "Lima beans, large, mature seeds, cooked, boiled, without salt",
  "Lima beans, large, mature seeds, raw",
  "Lima beans, thin seeded (baby), mature seeds, cooked, boiled, with salt",
  "Lima beans, thin seeded (baby), mature seeds, cooked, boiled, without salt",
  "Lima beans, thin seeded (baby), mature seeds, raw",
  "Lime juice, canned or bottled, unsweetened",
  "Lime juice, raw",
  "Limeade, frozen concentrate, prepared with water",
  "Limes, raw",
  "Litchis, dried",
  "Litchis, raw",
  "LITTLE CAESARS 14\" Cheese Pizza, Large Deep Dish Crust",
  "LITTLE CAESARS 14\" Cheese Pizza, Thin Crust",
  "LITTLE CAESARS 14\" Original Round Cheese Pizza, Regular Crust",
  "LITTLE CAESARS 14\" Original Round Meat and Vegetable Pizza, Regular Crust",
  "LITTLE CAESARS 14\" Original Round Pepperoni Pizza, Regular Crust",
  "LITTLE CAESARS 14\" Pepperoni Pizza, Large Deep Dish Crust",
  "Liver cheese, pork",
  "Liver sausage, liverwurst, pork",
  "Liverwurst spread",
  "Loganberries, frozen",
  "Longans, dried",
  "Longans, raw",
  "Loquats, raw",
  "Lotus root, cooked, boiled, drained, with salt",
  "Lotus root, cooked, boiled, drained, without salt",
  "Lotus root, raw",
  "Luncheon meat, pork and chicken, minced, canned, includes Spam Lite",
  "Luncheon meat, pork with ham, minced, canned, includes Spam (Hormel)",
  "Luncheon meat, pork, canned",
  "Luncheon meat, pork, ham, and chicken, minced, canned, reduced sodium, added ascorbic acid, includes SPAM, 25% less sodium",
  "Luncheon sausage, pork and beef",
  "Luncheon slices, meatless",
  "Lupins, mature seeds, cooked, boiled, with salt",
  "Lupins, mature seeds, cooked, boiled, without salt",
  "Lupins, mature seeds, raw",
  "Luxury loaf, pork",
  "Macaroni and cheese dinner with dry sauce mix, boxed, uncooked",
  "Macaroni and cheese loaf, chicken, pork and beef",
  "Macaroni and cheese, box mix with cheese sauce, prepared",
  "Macaroni and cheese, box mix with cheese sauce, unprepared",
  "Macaroni and Cheese, canned entree",
  "Macaroni and Cheese, canned, microwavable",
  "Macaroni and cheese, dry mix, prepared with 2% milk and 80% stick margarine from dry mix",
  "Macaroni and cheese, frozen entree",
  "Macaroni or noodles with cheese, made from reduced fat packaged mix, unprepared",
  "Macaroni or noodles with cheese, microwaveable, unprepared",
  "Macaroni, vegetable, enriched, cooked",
  "Macaroni, vegetable, enriched, dry",
  "Malabar spinach, cooked",
  "Malt beverage, includes non-alcoholic beer",
  "Mammy-apple, (mamey), raw",
  "Mango nectar, canned",
  "Mango, dried, sweetened",
  "Mangos, raw",
  "Mangosteen, canned, syrup pack",
  "Maraschino cherries, canned, drained",
  "Margarine Spread, 40-49% fat, tub",
  "Margarine, 80% fat, stick, includes regular and hydrogenated corn and soybean oils",
  "Margarine, 80% fat, tub, CANOLA HARVEST Soft Spread (canola, palm and palm kernel oils)",
  "Margarine, industrial, non-dairy, cottonseed, soy oil (partially hydrogenated ), for flaky pastries",
  "Margarine, industrial, soy and partially hydrogenated soy oil, use for baking, sauces and candy",
  "Margarine, margarine-like vegetable oil spread, 67-70% fat, tub",
  "Margarine, margarine-type vegetable oil spread, 70% fat, soybean and partially hydrogenated soybean, stick",
  "Margarine, regular, 80% fat, composite, stick, with salt",
  "Margarine, regular, 80% fat, composite, stick, with salt, with added vitamin D",
  "Margarine, regular, 80% fat, composite, stick, without salt",
  "Margarine, regular, 80% fat, composite, stick, without salt, with added vitamin D",
  "Margarine, regular, 80% fat, composite, tub, with salt",
  "Margarine, regular, 80% fat, composite, tub, with salt, with added vitamin D",
  "Margarine, regular, 80% fat, composite, tub, without salt",
  "Margarine, regular, hard, soybean (hydrogenated)",
  "Margarine,spread, 35-39% fat, tub",
  "Margarine-like shortening, industrial, soy (partially hydrogenated), cottonseed, and soy, principal use flaky pastries",
  "Margarine-like spread with yogurt, 70% fat, stick, with salt",
  "Margarine-like spread with yogurt, approximately 40% fat, tub, with salt",
  "Margarine-like spread, BENECOL Light Spread",
  "Margarine-like spread, SMART BALANCE Light Buttery Spread",
  "Margarine-like spread, SMART BALANCE Omega Plus Spread (with plant sterols & fish oil)",
  "Margarine-like spread, SMART BALANCE Regular Buttery Spread with flax oil",
  "Margarine-like spread, SMART BEAT Smart Squeeze",
  "Margarine-like spread, SMART BEAT Super Light without saturated fat",
  "Margarine-like vegetable-oil spread, stick/tub/bottle, 60% fat, with added vitamin D",
  "Margarine-like, butter-margarine blend, 80% fat, stick, without salt",
  "Margarine-like, margarine-butter blend, soybean oil and butter",
  "Margarine-like, vegetable oil spread, 20% fat, with salt",
  "Margarine-like, vegetable oil spread, 20% fat, without salt",
  "Margarine-like, vegetable oil spread, 60% fat, stick, with salt",
  "Margarine-like, vegetable oil spread, 60% fat, stick, with salt, with added vitamin D",
  "Margarine-like, vegetable oil spread, 60% fat, stick/tub/bottle, with salt",
  "Margarine-like, vegetable oil spread, 60% fat, stick/tub/bottle, without salt",
  "Margarine-like, vegetable oil spread, 60% fat, stick/tub/bottle, without salt, with added vitamin D",
  "Margarine-like, vegetable oil spread, 60% fat, tub, with salt",
  "Margarine-like, vegetable oil spread, 60% fat, tub, with salt, with added vitamin D",
  "Margarine-like, vegetable oil spread, approximately 37% fat, unspecified oils, with salt, with added vitamin D",
  "Margarine-like, vegetable oil spread, fat free, liquid, with salt",
  "Margarine-like, vegetable oil spread, fat-free, tub",

  "Margarine-like, vegetable oil spread, stick or tub, sweetened",
  "Margarine-like, vegetable oil-butter spread, reduced calorie, tub, with salt",
  "Margarine-like, vegetable oil-butter spread, tub, with salt",
  "Marmalade, orange",
  "Martha White Foods, Martha White's Buttermilk Biscuit Mix, dry",
  "Martha White Foods, Martha White's Chewy Fudge Brownie Mix, dry",
  "Mary's Gone Crackers, Original Crackers, Organic Gluten Free",
  "Mashu roots, raw (Alaska Native)",
  "Mayonnaise dressing, no cholesterol",
  "Mayonnaise, low sodium, low calorie or diet",
  "Mayonnaise, made with tofu",
  "Mayonnaise, reduced fat, with olive oil",
  "Mayonnaise, reduced-calorie or diet, cholesterol-free",
  "McDONALD'S Bacon Ranch Salad with Crispy Chicken",
  "McDONALD'S, Bacon Egg & Cheese Biscuit",
  "McDONALD'S, Bacon Ranch Salad with Grilled Chicken",
  "McDONALD'S, Bacon Ranch Salad without chicken",
  "McDONALD'S, Bacon, Egg & Cheese McGRIDDLES",
  "McDONALD'S, BIG BREAKFAST",
  "McDONALD'S, BIG MAC",
  "McDONALD'S, BIG MAC (without Big Mac Sauce)",
  "McDONALD'S, Cheeseburger",
  "McDONALD'S, Chicken McNUGGETS",
  "McDONALD'S, Deluxe Breakfast, with syrup and margarine",
  "McDONALD'S, Double Cheeseburger",
  "McDONALD'S, DOUBLE QUARTER POUNDER with Cheese",
  "McDONALD'S, Egg McMUFFIN",
  "McDONALD'S, FILET-O-FISH",
  "McDONALD'S, FILET-O-FISH (without tartar sauce)",
  "McDONALD'S, french fries",
  "McDONALD'S, Fruit 'n Yogurt Parfait",
  "McDONALD'S, Fruit 'n Yogurt Parfait (without granola)",
  "McDONALD'S, Hamburger",
  "McDONALD'S, Hash Brown",
  "McDONALD'S, Hot Caramel Sundae",
  "McDONALD'S, Hot Fudge Sundae",
  "McDONALD'S, Hotcakes (plain)",
  "McDONALD'S, Hotcakes (with 2 pats margarine & syrup)",
  "McDONALD'S, Hotcakes and Sausage",
  "McDONALD'S, McCHICKEN Sandwich",
  "McDONALD'S, McCHICKEN Sandwich (without mayonnaise)",
  "McDONALD'S, McFLURRY with M&M'S CANDIES",
  "McDONALD'S, McFLURRY with OREO cookies",
  "McDONALD'S, QUARTER POUNDER",
  "McDONALD'S, QUARTER POUNDER with Cheese",
  "McDONALD'S, RANCH SNACK WRAP, Crispy",
  "McDONALD'S, RANCH SNACK WRAP, Grilled",
  "McDONALD'S, Sausage Biscuit",
  "McDONALD'S, Sausage Biscuit with Egg",
  "McDONALD'S, Sausage Burrito",
  "McDONALD'S, Sausage McGRIDDLES",
  "McDONALD'S, Sausage McMUFFIN",
  "McDONALD'S, Sausage McMUFFIN with Egg",
  "McDONALD'S, Sausage, Egg & Cheese McGRIDDLES",
  "McDONALD'S, Side Salad",
  "McDONALD'S, Southern Style Chicken Biscuit",
  "McDONALD'S, Strawberry Sundae",
  "McDONALD'S, Vanilla Reduced Fat Ice Cream Cone",
  "Mckee Baking, Little Debbie Nutty Bars, Wafers with Peanut Butter, Chocolate Covered",
  "Meat extender",
  "Meatballs, frozen, Italian style",
  "Meatballs, meatless",
  "Melon balls, frozen",
  "Melon, banana (Navajo)",
  "Melons, cantaloupe, raw",
  "Melons, casaba, raw",
  "Melons, honeydew, raw",
  "Milk and cereal bar",
  "Milk dessert bar, frozen, made from lowfat milk",
  "Milk dessert, frozen, milk-fat free, chocolate",
  "Milk shakes, thick chocolate",
  "Milk shakes, thick vanilla",
  "Milk substitutes, fluid, with lauric acid oil",
  "Milk, buttermilk, dried",
  "Milk, buttermilk, fluid, cultured, lowfat",
  "Milk, buttermilk, fluid, cultured, reduced fat",
  "Milk, buttermilk, fluid, whole",
  "Milk, canned, condensed, sweetened",
  "Milk, canned, evaporated, nonfat, with added vitamin A and vitamin D",
  "Milk, canned, evaporated, with added vitamin A",
  "Milk, canned, evaporated, with added vitamin D and without added vitamin A",
  "Milk, chocolate beverage, hot cocoa, homemade",
  "Milk, chocolate, fat free, with added vitamin A and vitamin D",
  "Milk, chocolate, fluid, commercial, reduced fat, with added calcium",
  "Milk, chocolate, fluid, commercial, reduced fat, with added vitamin A and vitamin D",
  "Milk, chocolate, fluid, commercial, whole, with added vitamin A and vitamin D",
  "Milk, chocolate, lowfat, reduced sugar",
  "Milk, chocolate, lowfat, with added vitamin A and vitamin D",
  "Milk, dry, nonfat, calcium reduced",
  "Milk, dry, nonfat, instant, with added vitamin A and vitamin D",
  "Milk, dry, nonfat, instant, without added vitamin A and vitamin D",
  "Milk, dry, nonfat, regular, with added vitamin A and vitamin D",
  "Milk, dry, nonfat, regular, without added vitamin A and vitamin D",
  "Milk, dry, whole, with added vitamin D",
  "Milk, dry, whole, without added vitamin D",
  "Milk, evaporated, 2% fat, with added vitamin A and vitamin D",
  "Milk, filled, fluid, with blend of hydrogenated vegetable oils",
  "Milk, filled, fluid, with lauric acid oil",
  "Milk, fluid, 1% fat, without added vitamin A and vitamin D",
  "Milk, fluid, nonfat, calcium fortified (fat free or skim)",
  "Milk, goat, fluid, with added vitamin D",
  "Milk, human, mature, fluid (For Reference Only)",
  "Milk, imitation, non-soy",
  "Milk, indian buffalo, fluid",
  "Milk, low sodium, fluid",
  "Milk, lowfat, fluid, 1% milkfat, protein fortified, with added vitamin A and vitamin D",
  "Milk, lowfat, fluid, 1% milkfat, with added nonfat milk solids, vitamin A and vitamin D",
  "Milk, lowfat, fluid, 1% milkfat, with added vitamin A and vitamin D",
  "Milk, nonfat, fluid, protein fortified, with added vitamin A and vitamin D (fat free and skim)",
  "Milk, nonfat, fluid, with added nonfat milk solids, vitamin A and vitamin D (fat free or skim)",
  "Milk, nonfat, fluid, with added vitamin A and vitamin D (fat free or skim)",
  "Milk, nonfat, fluid, without added vitamin A and vitamin D (fat free or skim)",
  "Milk, producer, fluid, 3.7% milkfat",
  "Milk, reduced fat, fluid, 2% milkfat, protein fortified, with added vitamin A and vitamin D",
  "Milk, reduced fat, fluid, 2% milkfat, with added nonfat milk solids and vitamin A and vitamin D",
  "Milk, reduced fat, fluid, 2% milkfat, with added nonfat milk solids, without added vitamin A",
  "Milk, reduced fat, fluid, 2% milkfat, with added vitamin A and vitamin D",
  "Milk, reduced fat, fluid, 2% milkfat, without added vitamin A and vitamin D",
  "Milk, sheep, fluid",
  "Milk, whole, 3.25% milkfat, with added vitamin D",
  "Milk, whole, 3.25% milkfat, without added vitamin A and vitamin D",
  "Millet flour",
  "Millet, cooked",
  "Millet, puffed",
  "Millet, raw",
  "Miso",
  "Mission Foods, Mission Flour Tortillas, Soft Taco, 8 inch",
  "Molasses",
  "Mollusks, abalone, mixed species, cooked, fried",
  "Mollusks, abalone, mixed species, raw",
  "Mollusks, clam, mixed species, canned, drained solids",
  "Mollusks, clam, mixed species, canned, liquid",
  "Mollusks, clam, mixed species, cooked, breaded and fried",
  "Mollusks, clam, mixed species, cooked, moist heat",
  "Mollusks, clam, mixed species, raw",
  "Mollusks, conch, baked or broiled",
  "Mollusks, cuttlefish, mixed species, cooked, moist heat",
  "Mollusks, cuttlefish, mixed species, raw",
  "Mollusks, mussel, blue, cooked, moist heat",
  "Mollusks, mussel, blue, raw",
  "Mollusks, octopus, common, cooked, moist heat",
  "Mollusks, octopus, common, raw",
  "Mollusks, oyster, eastern, canned",
  "Mollusks, oyster, eastern, cooked, breaded and fried",
  "Mollusks, oyster, eastern, farmed, cooked, dry heat",
  "Mollusks, oyster, eastern, farmed, raw",
  "Mollusks, oyster, eastern, wild, cooked, dry heat",
  "Mollusks, oyster, eastern, wild, cooked, moist heat",
  "Mollusks, oyster, eastern, wild, raw",
  "Mollusks, oyster, Pacific, cooked, moist heat",
  "Mollusks, oyster, Pacific, raw",
  "Mollusks, scallop, (bay and sea), cooked, steamed",
  "Mollusks, scallop, mixed species, cooked, breaded and fried",
  "Mollusks, scallop, mixed species, imitation, made from surimi",
  "Mollusks, scallop, mixed species, raw",
  "Mollusks, snail, raw",
  "Mollusks, squid, mixed species, cooked, fried",
  "Mollusks, squid, mixed species, raw",
  "Mollusks, whelk, unspecified, cooked, moist heat",
  "Mollusks, whelk, unspecified, raw",
  "Moose, liver, braised (Alaska Native)",
  "Moose, meat, raw (Alaska Native)",
  "MORI-NU, Tofu, silken, extra firm",
  "MORI-NU, Tofu, silken, firm",
  "MORI-NU, Tofu, silken, lite extra firm",
  "MORI-NU, Tofu, silken, lite firm",
  "MORI-NU, Tofu, silken, soft",
  "Mortadella, beef, pork",
  "Mothbeans, mature seeds, cooked, boiled, with salt",
  "Mothbeans, mature seeds, cooked, boiled, without salt",
  "Mothbeans, mature seeds, raw",
  "Mother's loaf, pork",
  "Mountain yam, hawaii, cooked, steamed, with salt",
  "Mountain yam, hawaii, cooked, steamed, without salt",
  "Mountain yam, hawaii, raw",
  "Mouse nuts, roots (Alaska Native)",
  "Mouse nuts, seedlings (Alaska Native)",
  "Muffin, blueberry, commercially prepared, low-fat",
  "Muffins, blueberry, commercially prepared (Includes mini-muffins)",
  "Muffins, blueberry, dry mix",
  "Muffins, blueberry, prepared from recipe, made with low fat (2%) milk",
  "Muffins, blueberry, toaster-type",
  "Muffins, blueberry, toaster-type, toasted",
  "Muffins, corn, commercially prepared",
  "Muffins, corn, dry mix, prepared",
  "Muffins, corn, prepared from recipe, made with low fat (2%) milk",
  "Muffins, corn, toaster-type",
  "Muffins, English, mixed-grain (includes granola)",
  "Muffins, English, plain, enriched, with ca prop (includes sourdough)",
  "Muffins, English, plain, toasted, enriched, with calcium propionate (includes sourdough)",
  "Muffins, English, raisin-cinnamon (includes apple-cinnamon)",
  "Muffins, English, raisin-cinnamon, toasted (includes apple-cinnamon)",
  "Muffins, English, wheat",
  "Muffins, English, whole-wheat",
  "Muffins, oat bran",
  "Muffins, plain, prepared from recipe, made with low fat (2%) milk",
  "Muffins, wheat bran, dry mix",
  "Muffins, wheat bran, toaster-type with raisins, toasted",
  "Mulberries, raw",
  "Mung beans, mature seeds, cooked, boiled, with salt",

  "Mung beans, mature seeds, cooked, boiled, without salt",
  "Mung beans, mature seeds, raw",
  "Mung beans, mature seeds, sprouted, cooked, boiled, drained, with salt",
  "Mung beans, mature seeds, sprouted, cooked, boiled, drained, without salt",
  "Mung beans, mature seeds, sprouted, cooked, stir-fried",
  "Mung beans, mature seeds, sprouted, raw",
  "Mungo beans, mature seeds, cooked, boiled, with salt",
  "Mungo beans, mature seeds, cooked, boiled, without salt",
  "Mungo beans, mature seeds, raw",
  "Mush, blue corn with ash (Navajo)",
  "Mushroom, white, exposed to ultraviolet light, raw",
  "Mushrooms, brown, italian, or crimini, exposed to ultraviolet light, raw",
  "Mushrooms, brown, italian, or crimini, raw",
  "Mushrooms, canned, drained solids",
  "Mushrooms, Chanterelle, raw",
  "Mushrooms, enoki, raw",
  "Mushrooms, maitake, raw",
  "Mushrooms, morel, raw",
  "Mushrooms, oyster, raw",
  "Mushrooms, portabella, exposed to ultraviolet light, grilled",
  "Mushrooms, portabella, exposed to ultraviolet light, raw",
  "Mushrooms, portabella, grilled",
  "Mushrooms, portabella, raw",
  "Mushrooms, shiitake, cooked, with salt",
  "Mushrooms, shiitake, cooked, without salt",
  "Mushrooms, shiitake, dried",
  "Mushrooms, shiitake, raw",
  "Mushrooms, shiitake, stir-fried",
  "Mushrooms, straw, canned, drained solids",
  "Mushrooms, white, cooked, boiled, drained, with salt",
  "Mushrooms, white, cooked, boiled, drained, without salt",
  "Mushrooms, white, microwaved",
  "Mushrooms, white, raw",
  "Mushrooms, white, stir-fried",
  "Mustard greens, cooked, boiled, drained, with salt",
  "Mustard greens, cooked, boiled, drained, without salt",
  "Mustard greens, frozen, cooked, boiled, drained, with salt",
  "Mustard greens, frozen, cooked, boiled, drained, without salt",
  "Mustard greens, frozen, unprepared",
  "Mustard greens, raw",
  "Mustard spinach, (tendergreen), cooked, boiled, drained, with salt",
  "Mustard spinach, (tendergreen), cooked, boiled, drained, without salt",
  "Mustard spinach, (tendergreen), raw",
  "Mustard, prepared, yellow",
  "Mutton, cooked, roasted (Navajo)",
  "Nabisco, Nabisco Grahams Crackers",
  "Nabisco, Nabisco Oreo Crunchies, Cookie Crumb Topping",
  "Nabisco, Nabisco Ritz Crackers",
  "Nabisco, Nabisco Snackwell's Fat Free Devil's Food Cookie Cakes",
  "Nance, canned, syrup, drained",

  "Nance, frozen, unsweetened",
  "Naranjilla (lulo) pulp, frozen, unsweetened",
  "Natto",
  "Nectarines, raw",
  "New zealand spinach, cooked, boiled, drained, with salt",
  "New Zealand spinach, cooked, boiled, drained, without salt",
  "New Zealand spinach, raw",
  "Noodles, chinese, cellophane or long rice (mung beans), dehydrated",
  "Noodles, chinese, chow mein",
  "Noodles, egg, cooked, enriched, with added salt",
  "Noodles, egg, cooked, unenriched, with added salt",
  "Noodles, egg, dry, enriched",
  "Noodles, egg, dry, unenriched",
  "Noodles, egg, enriched, cooked",
  "Noodles, egg, spinach, enriched, cooked",
  "Noodles, egg, spinach, enriched, dry",
  "Noodles, egg, unenriched, cooked, without added salt",
  "Noodles, flat, crunchy, Chinese restaurant",
  "Noodles, japanese, soba, cooked",
  "Noodles, japanese, soba, dry",
  "Noodles, japanese, somen, cooked",
  "Noodles, japanese, somen, dry",
  "Nopales, cooked, without salt",
  "Nopales, raw",
  "Nutritional supplement for people with diabetes, liquid",
  "Nuts, acorn flour, full fat",
  "Nuts, acorns, dried",
  "Nuts, acorns, raw",
  "Nuts, almond butter, plain, with salt added",
  "Nuts, almond butter, plain, without salt added",
  "Nuts, almond paste",
  "Nuts, almonds",
  "Nuts, almonds, blanched",
  "Nuts, almonds, dry roasted, with salt added",
  "Nuts, almonds, dry roasted, without salt added",
  "Nuts, almonds, honey roasted, unblanched",
  "Nuts, almonds, oil roasted, lightly salted",
  "Nuts, almonds, oil roasted, with salt added",
  "Nuts, almonds, oil roasted, with salt added, smoke flavor",
  "Nuts, almonds, oil roasted, without salt added",
  "Nuts, beechnuts, dried",
  "Nuts, brazilnuts, dried, unblanched",
  "Nuts, butternuts, dried",
  "Nuts, cashew butter, plain, with salt added",
  "Nuts, cashew butter, plain, without salt added",
  "Nuts, cashew nuts, dry roasted, with salt added",
  "Nuts, cashew nuts, dry roasted, without salt added",
  "Nuts, cashew nuts, oil roasted, with salt added",
  "Nuts, cashew nuts, oil roasted, without salt added",
  "Nuts, cashew nuts, raw",

  "Nuts, chestnuts, chinese, boiled and steamed",
  "Nuts, chestnuts, chinese, dried",
  "Nuts, chestnuts, chinese, raw",
  "Nuts, chestnuts, chinese, roasted",
  "Nuts, chestnuts, european, boiled and steamed",
  "Nuts, chestnuts, european, dried, peeled",
  "Nuts, chestnuts, european, dried, unpeeled",
  "Nuts, chestnuts, european, raw, peeled",
  "Nuts, chestnuts, european, raw, unpeeled",
  "Nuts, chestnuts, european, roasted",
  "Nuts, chestnuts, japanese, boiled and steamed",
  "Nuts, chestnuts, japanese, dried",
  "Nuts, chestnuts, japanese, raw",
  "Nuts, chestnuts, japanese, roasted",
  "Nuts, coconut cream, canned, sweetened",
  "Nuts, coconut cream, raw (liquid expressed from grated meat)",
  "Nuts, coconut meat, dried (desiccated), creamed",
  "Nuts, coconut meat, dried (desiccated), not sweetened",
  "Nuts, coconut meat, dried (desiccated), sweetened, flaked, canned",
  "Nuts, coconut meat, dried (desiccated), sweetened, flaked, packaged",
  "Nuts, coconut meat, dried (desiccated), sweetened, shredded",
  "Nuts, coconut meat, dried (desiccated), toasted",
  "Nuts, coconut meat, raw",
  "Nuts, coconut milk, canned (liquid expressed from grated meat and water)",
  "Nuts, coconut milk, frozen (liquid expressed from grated meat and water)",
  "Nuts, coconut milk, raw (liquid expressed from grated meat and water)",
  "Nuts, coconut water (liquid from coconuts)",
  "Nuts, formulated, wheat-based, all flavors except macadamia, without salt",
  "Nuts, formulated, wheat-based, unflavored, with salt added",
  "Nuts, ginkgo nuts, canned",
  "Nuts, ginkgo nuts, dried",
  "Nuts, ginkgo nuts, raw",
  "Nuts, hazelnuts or filberts",
  "Nuts, hazelnuts or filberts, blanched",
  "Nuts, hazelnuts or filberts, dry roasted, without salt added",
  "Nuts, hickorynuts, dried",
  "Nuts, macadamia nuts, dry roasted, with salt added",
  "Nuts, macadamia nuts, dry roasted, without salt added",
  "Nuts, macadamia nuts, raw",
  "Nuts, mixed nuts, dry roasted, with peanuts, salt added, CHOSEN ROASTER",
  "Nuts, mixed nuts, dry roasted, with peanuts, salt added, PLANTERS pistachio blend",
  "Nuts, mixed nuts, dry roasted, with peanuts, with salt added",
  "Nuts, mixed nuts, dry roasted, with peanuts, without salt added",
  "Nuts, mixed nuts, oil roasted, with peanuts, lightly salted",
  "Nuts, mixed nuts, oil roasted, with peanuts, with salt added",
  "Nuts, mixed nuts, oil roasted, with peanuts, without salt added",
  "Nuts, mixed nuts, oil roasted, without peanuts, lightly salted",
  "Nuts, mixed nuts, oil roasted, without peanuts, with salt added",
  "Nuts, mixed nuts, oil roasted, without peanuts, without salt added",
  "Nuts, pecans",

  "Nuts, pecans, dry roasted, with salt added",
  "Nuts, pecans, dry roasted, without salt added",
  "Nuts, pecans, oil roasted, with salt added",
  "Nuts, pecans, oil roasted, without salt added",
  "Nuts, pilinuts, dried",
  "Nuts, pine nuts, dried",
  "Nuts, pine nuts, pinyon, dried",
  "Nuts, pistachio nuts, dry roasted, with salt added",
  "Nuts, pistachio nuts, dry roasted, without salt added",
  "Nuts, pistachio nuts, raw",
  "Nuts, walnuts, black, dried",
  "Nuts, walnuts, dry roasted, with salt added",
  "Nuts, walnuts, english",
  "Nuts, walnuts, glazed",
  "Oat bran, cooked",
  "Oat bran, raw",
  "Oat flour, partially debranned",
  "Oats (Includes foods for USDA's Food Distribution Program)",
  "Octopus (Alaska Native)",
  "Oheloberries, raw",
  "Oil, almond",
  "Oil, apricot kernel",
  "Oil, avocado",
  "Oil, babassu",
  "Oil, bearded seal (Oogruk) (Alaska Native)",
  "Oil, beluga, whale (Alaska Native)",
  "Oil, canola",
  "Oil, cocoa butter",
  "Oil, coconut",
  "Oil, cooking and salad, ENOVA, 80% diglycerides",
  "Oil, corn and canola",
  "Oil, corn, industrial and retail, all purpose salad or cooking",
  "Oil, corn, peanut, and olive",
  "Oil, cottonseed, salad or cooking",
  "Oil, cupu assu",
  "Oil, flaxseed, cold pressed",
  "Oil, flaxseed, contains added sliced flaxseed",
  "Oil, grapeseed",
  "Oil, hazelnut",
  "Oil, industrial, canola (partially hydrogenated) oil for deep fat frying",
  "Oil, industrial, canola for salads, woks and light frying",
  "Oil, industrial, canola with antifoaming agent, principal uses salads, woks and light frying",
  "Oil, industrial, canola, high oleic",
  "Oil, industrial, coconut (hydrogenated), used for whipped toppings and coffee whiteners",
  "Oil, industrial, coconut, confection fat, typical basis for ice cream coatings",
  "Oil, industrial, coconut, principal uses candy coatings, oil sprays, roasting nuts",
  "Oil, industrial, cottonseed, fully hydrogenated",
  "Oil, industrial, mid-oleic, sunflower",
  "Oil, industrial, palm and palm kernel, filling fat (non-hydrogenated)",
  "Oil, industrial, palm kernel (hydrogenated) , used for whipped toppings, non-dairy",
  "Oil, industrial, palm kernel (hydrogenated), confection fat, intermediate grade product",
  "Oil, industrial, palm kernel (hydrogenated), confection fat, uses similar to 95 degree hard butter",
  "Oil, industrial, palm kernel (hydrogenated), filling fat",
  "Oil, industrial, palm kernel, confection fat, uses similar to high quality cocoa butter",
  "Oil, industrial, soy (partially hydrogenated), all purpose",
  "Oil, industrial, soy (partially hydrogenated) and soy (winterized), pourable clear fry",
  "Oil, industrial, soy (partially hydrogenated ), palm, principal uses icings and fillings",
  "Oil, industrial, soy (partially hydrogenated) and cottonseed, principal use as a tortilla shortening",
  "Oil, industrial, soy (partially hydrogenated), multiuse for non-dairy butter flavor",
  "Oil, industrial, soy (partially hydrogenated), principal uses popcorn and flavoring vegetables",
  "Oil, industrial, soy, fully hydrogenated",
  "Oil, industrial, soy, low linolenic",
  "Oil, industrial, soy, refined, for woks and light frying",
  "Oil, industrial, soy, ultra low linolenic",
  "Oil, mustard",
  "Oil, nutmeg butter",
  "Oil, oat",
  "Oil, olive, salad or cooking",
  "Oil, palm",
  "Oil, PAM cooking spray, original",
  "Oil, peanut, salad or cooking",
  "Oil, poppyseed",
  "Oil, rice bran",
  "Oil, safflower, salad or cooking, high oleic (primary safflower oil of commerce)",
  "Oil, safflower, salad or cooking, linoleic, (over 70%)",
  "Oil, sesame, salad or cooking",
  "Oil, sheanut",
  "Oil, soybean lecithin",
  "Oil, soybean, salad or cooking",
  "Oil, soybean, salad or cooking, (partially hydrogenated)",
  "Oil, soybean, salad or cooking, (partially hydrogenated) and cottonseed",
  "Oil, spotted seal (Alaska Native)",
  "Oil, sunflower, high oleic (70% and over)",
  "Oil, sunflower, linoleic (less than 60%)",
  "Oil, sunflower, linoleic, (approx. 65%)",
  "Oil, sunflower, linoleic, (partially hydrogenated)",
  "Oil, teaseed",
  "Oil, tomatoseed",
  "Oil, ucuhuba butter",
  "Oil, vegetable, Natreon canola, high stability, non trans, high oleic (70%)",
  "Oil, vegetable, soybean, refined",
  "Oil, walnut",
  "Oil, walrus (Alaska Native)",
  "Oil, whale, bowhead (Alaska Native)",
  "Oil, wheat germ",
  "Okara",
  "Okra, cooked, boiled, drained, with salt",
  "Okra, cooked, boiled, drained, without salt",
  "Okra, frozen, cooked, boiled, drained, with salt",
  "Okra, frozen, cooked, boiled, drained, without salt",

  "Okra, frozen, unprepared",
  "Okra, raw",
  "OLIVE GARDEN, cheese ravioli with marinara sauce",
  "OLIVE GARDEN, chicken parmigiana without pasta",
  "OLIVE GARDEN, lasagna classico",
  "OLIVE GARDEN, spaghetti with meat sauce",
  "OLIVE GARDEN, spaghetti with pomodoro sauce",
  "Olive loaf, pork",
  "Olives, pickled, canned or bottled, green",
  "Olives, ripe, canned (jumbo-super colossal)",
  "Olives, ripe, canned (small-extra large)",
  "ON THE BORDER, cheese enchilada",
  "ON THE BORDER, cheese quesadilla",
  "ON THE BORDER, Mexican rice",
  "ON THE BORDER, refried beans",
  "ON THE BORDER, soft taco with ground beef, cheese and lettuce",
  "Onion rings, breaded, par fried, frozen, prepared, heated in oven",
  "Onion rings, breaded, par fried, frozen, unprepared",
  "Onions, canned, solids and liquids",
  "Onions, cooked, boiled, drained, with salt",
  "Onions, cooked, boiled, drained, without salt",
  "Onions, dehydrated flakes",
  "Onions, frozen, chopped, cooked, boiled, drained, with salt",
  "Onions, frozen, chopped, cooked, boiled, drained, without salt",
  "Onions, frozen, chopped, unprepared",
  "Onions, frozen, whole, cooked, boiled, drained, with salt",
  "Onions, frozen, whole, cooked, boiled, drained, without salt",
  "Onions, frozen, whole, unprepared",
  "Onions, raw",
  "Onions, spring or scallions (includes tops and bulb), raw",
  "Onions, sweet, raw",
  "Onions, welsh, raw",
  "Onions, yellow, sauteed",
  "Onions, young green, tops only",
  "Oopah (tunicate), whole animal (Alaska Native)",
  "Orange juice, canned, unsweetened",
  "Orange juice, chilled, includes from concentrate",
  "Orange juice, chilled, includes from concentrate, with added calcium",
  "Orange juice, chilled, includes from concentrate, with added calcium and vitamin D",
  "Orange juice, chilled, includes from concentrate, with added calcium and vitamins A, D, E",
  "Orange juice, frozen concentrate, unsweetened, diluted with 3 volume water",
  "Orange juice, frozen concentrate, unsweetened, diluted with 3 volume water, with added calcium",
  "Orange juice, frozen concentrate, unsweetened, undiluted",
  "Orange juice, frozen concentrate, unsweetened, undiluted, with added calcium",
  "Orange juice, raw (Includes foods for USDA's Food Distribution Program)",
  "Orange peel, raw",
  "Orange Pineapple Juice Blend",
  "Orange-grapefruit juice, canned or bottled, unsweetened",
  "Oranges, raw, all commercial varieties",
  "Oranges, raw, California, valencias",

  "Oranges, raw, Florida",
  "Oranges, raw, navels (Includes foods for USDA's Food Distribution Program)",
  "Oranges, raw, with peel",
  "Oscar Mayer, Bologna (beef)",
  "Oscar Mayer, Braunschweiger Liver Sausage (sliced)",
  "Oscar Mayer, Chicken Breast (honey glazed)",
  "Oscar Mayer, Ham (chopped with natural juice)",
  "Oscar Mayer, Salami (hard)",
  "Oscar Mayer, Smokies Sausage Little Cheese (pork, turkey)",
  "Oscar Mayer, Wieners (beef franks)",
  "Ostrich, fan, raw",
  "Ostrich, ground, cooked, pan-broiled",
  "Ostrich, ground, raw",
  "Ostrich, inside leg, cooked",
  "Ostrich, inside leg, raw",
  "Ostrich, inside strip, cooked",
  "Ostrich, inside strip, raw",
  "Ostrich, outside leg, raw",
  "Ostrich, outside strip, cooked",
  "Ostrich, outside strip, raw",
  "Ostrich, oyster, cooked",
  "Ostrich, oyster, raw",
  "Ostrich, round, raw",
  "Ostrich, tenderloin, raw",
  "Ostrich, tip trimmed, cooked",
  "Ostrich, tip trimmed, raw",
  "Ostrich, top loin, cooked",
  "Ostrich, top loin, raw",
  "Owl, horned, flesh, raw (Alaska Native)",
  "Pan Dulce, La Ricura, Salpora de Arroz con Azucar, cookie-like, contains wheat flour and rice flour",
  "Pancakes plain, frozen, ready-to-heat (includes buttermilk)",
  "Pancakes, blueberry, prepared from recipe",
  "Pancakes, buckwheat, dry mix, incomplete",
  "Pancakes, buttermilk, prepared from recipe",
  "Pancakes, gluten-free, frozen, ready-to-heat",
  "Pancakes, plain, dry mix, complete (includes buttermilk)",
  "Pancakes, plain, dry mix, complete, prepared",
  "Pancakes, plain, dry mix, incomplete (includes buttermilk)",
  "Pancakes, plain, dry mix, incomplete, prepared",
  "Pancakes, plain, frozen, ready-to-heat, microwave (includes buttermilk)",
  "Pancakes, plain, prepared from recipe",
  "Pancakes, plain, reduced fat",
  "Pancakes, special dietary, dry mix",
  "Pancakes, whole wheat, dry mix, incomplete",
  "Pancakes, whole-wheat, dry mix, incomplete",
  "Pancakes, whole-wheat, dry mix, incomplete, prepared",
  "PAPA JOHN'S 14\" Cheese Pizza, Original Crust",
  "PAPA JOHN'S 14\" Cheese Pizza, Thin Crust",
  "PAPA JOHN'S 14\" Pepperoni Pizza, Original Crust",
  "PAPA JOHN'S 14\" The Works Pizza, Original Crust",
  "Papad",
  "Papaya nectar, canned",
  "Papaya, canned, heavy syrup, drained",
  "Papayas, raw",
  "Parmesan cheese topping, fat free",
  "Parsley, freeze-dried",
  "Parsley, fresh",
  "Parsnips, cooked, boiled, drained, with salt",
  "Parsnips, cooked, boiled, drained, without salt",
  "Parsnips, raw",
  "Passion-fruit juice, purple, raw",
  "Passion-fruit juice, yellow, raw",
  "Passion-fruit, (granadilla), purple, raw",
  "Pasta mix, classic beef, unprepared",
  "Pasta mix, classic cheeseburger macaroni, unprepared",
  "Pasta mix, Italian four cheese lasagna, unprepared",
  "Pasta mix, Italian lasagna, unprepared",
  "Pasta with Sliced Franks in Tomato Sauce, canned entree",
  "Pasta with tomato sauce, no meat, canned",
  "Pasta, cooked, enriched, with added salt",
  "Pasta, cooked, enriched, without added salt",
  "Pasta, cooked, unenriched, with added salt",
  "Pasta, cooked, unenriched, without added salt",
  "Pasta, dry, enriched",
  "Pasta, dry, unenriched",
  "Pasta, fresh-refrigerated, plain, as purchased",
  "Pasta, fresh-refrigerated, plain, cooked",
  "Pasta, fresh-refrigerated, spinach, as purchased",
  "Pasta, fresh-refrigerated, spinach, cooked",
  "Pasta, gluten-free, brown rice flour, cooked, TINKYADA",
  "Pasta, gluten-free, corn and rice flour, cooked",
  "Pasta, gluten-free, corn flour and quinoa flour, cooked, ANCIENT HARVEST",
  "Pasta, gluten-free, corn, cooked",
  "Pasta, gluten-free, corn, dry",
  "Pasta, gluten-free, rice flour and rice bran extract, cooked, DE BOLES",
  "Pasta, homemade, made with egg, cooked",
  "Pasta, homemade, made without egg, cooked",
  "Pasta, whole grain, 51% whole wheat, remaining enriched semolina, cooked (Includes foods for USDA's Food Distribution Program)",
  "Pasta, whole grain, 51% whole wheat, remaining enriched semolina, dry (Includes foods for USDA's Food Distribution Program)",
  "Pasta, whole grain, 51% whole wheat, remaining unenriched semolina, cooked",
  "Pasta, whole grain, 51% whole wheat, remaining unenriched semolina, dry",
  "Pasta, whole-wheat, cooked (Includes foods for USDA's Food Distribution Program)",
  "Pasta, whole-wheat, dry (Includes foods for USDA's Food Distribution Program)",
  "Pastrami, beef, 98% fat-free",
  "Pastrami, turkey",
  "Pastry, Pastelitos de Guava (guava pastries)",
  "Pate de foie gras, canned (goose liver pate), smoked",
  "Pate, chicken liver, canned",
  "Pate, goose liver, smoked, canned",
  "Pate, liver, not specified, canned",
  "Pate, truffle flavor",
  "Peach nectar, canned, with added ascorbic acid",
  "Peach nectar, canned, with sucralose, without added ascorbic acid",
  "Peaches, canned, extra heavy syrup pack, solids and liquids",
  "Peaches, canned, extra light syrup, solids and liquids (Includes foods for USDA's Food Distribution Program)",
  "Peaches, canned, heavy syrup pack, solids and liquids",
  "Peaches, canned, heavy syrup, drained",
  "Peaches, canned, juice pack, solids and liquids",
  "Peaches, canned, light syrup pack, solids and liquids",
  "Peaches, canned, water pack, solids and liquids",
  "Peaches, dehydrated (low-moisture), sulfured, stewed",
  "Peaches, dehydrated (low-moisture), sulfured, uncooked",
  "Peaches, dried, sulfured, stewed, with added sugar",
  "Peaches, dried, sulfured, stewed, without added sugar",
  "Peaches, dried, sulfured, uncooked",
  "Peaches, frozen, sliced, sweetened",
  "Peaches, spiced, canned, heavy syrup pack, solids and liquids",
  "Peaches, yellow, raw",
  "Peanut butter with omega-3, creamy",
  "Peanut butter, chunk style, with salt",
  "Peanut butter, chunk style, without salt",
  "Peanut butter, chunky, vitamin and mineral fortified",
  "Peanut butter, reduced sodium",
  "Peanut Butter, smooth (Includes foods for USDA's Food Distribution Program)",
  "Peanut butter, smooth style, with salt (Includes foods for USDA's Food Distribution Program)",
  "Peanut butter, smooth style, without salt",
  "Peanut butter, smooth, reduced fat",
  "Peanut butter, smooth, vitamin and mineral fortified",
  "Peanut flour, defatted",
  "Peanut flour, low fat",
  "Peanut spread, reduced sugar",
  "Peanuts, all types, cooked, boiled, with salt",
  "Peanuts, all types, dry-roasted, with salt",
  "Peanuts, all types, dry-roasted, without salt",
  "Peanuts, all types, oil-roasted, with salt",
  "Peanuts, all types, oil-roasted, without salt",
  "Peanuts, all types, raw",
  "Peanuts, spanish, oil-roasted, with salt",
  "Peanuts, spanish, oil-roasted, without salt",
  "Peanuts, spanish, raw",
  "Peanuts, valencia, oil-roasted, with salt",
  "Peanuts, valencia, oil-roasted, without salt",
  "Peanuts, valencia, raw",
  "Peanuts, virginia, oil-roasted, with salt",
  "Peanuts, virginia, oil-roasted, without salt",
  "Peanuts, virginia, raw",
  "Pear nectar, canned, with added ascorbic acid",
  "Pear nectar, canned, without added ascorbic acid",
  "Pears, asian, raw",
  "Pears, canned, extra heavy syrup pack, solids and liquids",

  "Pears, canned, extra light syrup pack, solids and liquids (Includes foods for USDA's Food Distribution Program)",
  "Pears, canned, heavy syrup pack, solids and liquids",
  "Pears, canned, heavy syrup, drained",
  "Pears, canned, juice pack, solids and liquids",
  "Pears, canned, light syrup pack, solids and liquids",
  "Pears, canned, water pack, solids and liquids",
  "Pears, dried, sulfured, stewed, with added sugar",
  "Pears, dried, sulfured, stewed, without added sugar",
  "Pears, dried, sulfured, uncooked",
  "Pears, raw",
  "Pears, raw, bartlett (Includes foods for USDA's Food Distribution Program)",
  "Pears, raw, bosc (Includes foods for USDA's Food Distribution Program)",
  "Pears, raw, green anjou (Includes foods for USDA's Food Distribution Program)",
  "Pears, raw, red anjou",
  "Peas and carrots, canned, no salt added, solids and liquids",
  "Peas and carrots, canned, regular pack, solids and liquids",
  "Peas and carrots, frozen, cooked, boiled, drained, with salt",
  "Peas and carrots, frozen, cooked, boiled, drained, without salt",
  "Peas and carrots, frozen, unprepared",
  "Peas and onions, canned, solids and liquids",
  "Peas and onions, frozen, cooked, boiled, drained, with salt",
  "Peas and onions, frozen, cooked, boiled, drained, without salt",
  "Peas and onions, frozen, unprepared",
  "Peas, edible-podded, boiled, drained, without salt",
  "Peas, edible-podded, cooked, boiled, drained, with salt",
  "Peas, edible-podded, frozen, cooked, boiled, drained, with salt",
  "Peas, edible-podded, frozen, cooked, boiled, drained, without salt",
  "Peas, edible-podded, frozen, unprepared",
  "Peas, edible-podded, raw",
  "Peas, green (includes baby and lesuer types), canned, drained solids, unprepared",
  "Peas, green, canned, drained solids, rinsed in tap water",
  "Peas, green, canned, no salt added, drained solids",
  "Peas, green, canned, no salt added, solids and liquids",
  "Peas, green, canned, regular pack, solids and liquids",
  "Peas, green, canned, seasoned, solids and liquids",
  "Peas, green, cooked, boiled, drained, with salt",
  "Peas, green, cooked, boiled, drained, without salt",
  "Peas, green, frozen, cooked, boiled, drained, with salt",
  "Peas, green, frozen, cooked, boiled, drained, without salt",
  "Peas, green, frozen, unprepared (Includes foods for USDA's Food Distribution Program)",
  "Peas, green, raw",
  "Peas, green, split, mature seeds, raw",
  "Peas, mature seeds, sprouted, cooked, boiled, drained, with salt",
  "Peas, mature seeds, sprouted, cooked, boiled, drained, without salt",
  "Peas, mature seeds, sprouted, raw",
  "Peas, split, mature seeds, cooked, boiled, with salt",
  "Peas, split, mature seeds, cooked, boiled, without salt",
  "Pectin, liquid",
  "Pectin, unsweetened, dry mix",
  "Pepeao, dried",

  "Pepper, banana, raw",
  "Peppered loaf, pork, beef",
  "Pepperidge Farm, Goldfish, Baked Snack Crackers, Cheddar",
  "Pepperidge Farm, Goldfish, Baked Snack Crackers, Explosive Pizza",
  "Pepperidge Farm, Goldfish, Baked Snack Crackers, Original",
  "Pepperidge Farm, Goldfish, Baked Snack Crackers, Parmesan",
  "Pepperidge Farm, Goldfish, Baked Snack Crackers, Pizza",
  "Peppermint, fresh",
  "Pepperoni, beef and pork, sliced",
  "Peppers, ancho, dried",
  "Peppers, chili, green, canned",
  "Peppers, hot chile, sun-dried",
  "Peppers, hot chili, green, canned, pods, excluding seeds, solids and liquids",
  "Peppers, hot chili, green, raw",
  "Peppers, hot chili, red, canned, excluding seeds, solids and liquids",
  "Peppers, hot chili, red, raw",
  "Peppers, hot pickled, canned",
  "Peppers, hungarian, raw",
  "Peppers, jalapeno, canned, solids and liquids",
  "Peppers, jalapeno, raw",
  "Peppers, pasilla, dried",
  "Peppers, serrano, raw",
  "Peppers, sweet, green, canned, solids and liquids",
  "Peppers, sweet, green, cooked, boiled, drained, with salt",
  "Peppers, sweet, green, cooked, boiled, drained, without salt",
  "Peppers, sweet, green, frozen, chopped, boiled, drained, without salt",
  "Peppers, sweet, green, frozen, chopped, cooked, boiled, drained, with salt",
  "Peppers, sweet, green, frozen, chopped, unprepared",
  "Peppers, sweet, green, raw",
  "Peppers, sweet, green, sauteed",
  "Peppers, sweet, red, canned, solids and liquids",
  "Peppers, sweet, red, cooked, boiled, drained, with salt",
  "Peppers, sweet, red, cooked, boiled, drained, without salt",
  "Peppers, sweet, red, freeze-dried",
  "Peppers, sweet, red, frozen, chopped, boiled, drained, with salt",
  "Peppers, sweet, red, frozen, chopped, boiled, drained, without salt",
  "Peppers, sweet, red, frozen, chopped, unprepared",
  "Peppers, sweet, red, raw",
  "Peppers, sweet, red, sauteed",
  "Peppers, sweet, yellow, raw",
  "Persimmons, japanese, dried",
  "Persimmons, japanese, raw",
  "Persimmons, native, raw",
  "Pheasant, breast, meat only, raw",
  "Pheasant, cooked, total edible",
  "Pheasant, leg, meat only, raw",
  "Pheasant, raw, meat and skin",
  "Pheasant, raw, meat only",
  "Phyllo dough",
  "Pickle and pimiento loaf, pork",

  "Pickle relish, hamburger",
  "Pickle relish, hot dog",
  "Pickle relish, sweet",
  "Pickles, chowchow, with cauliflower onion mustard, sweet",
  "Pickles, cucumber, dill or kosher dill",
  "Pickles, cucumber, dill, reduced sodium",
  "Pickles, cucumber, sour",
  "Pickles, cucumber, sour, low sodium",
  "Pickles, cucumber, sweet (includes bread and butter pickles)",
  "Pickles, cucumber, sweet, low sodium (includes bread and butter pickles)",
  "Picnic loaf, pork, beef",
  "Pie Crust, Cookie-type, Chocolate, Ready Crust",
  "Pie Crust, Cookie-type, Graham Cracker, Ready Crust",
  "Pie crust, cookie-type, prepared from recipe, graham cracker, chilled",
  "Pie crust, cookie-type, prepared from recipe, vanilla wafer, chilled",
  "Pie crust, deep dish, frozen, baked, made with enriched flour",
  "Pie crust, deep dish, frozen, unbaked, made with enriched flour",
  "Pie crust, refrigerated, regular, baked",
  "Pie crust, refrigerated, regular, unbaked",
  "Pie crust, standard-type, dry mix",
  "Pie crust, standard-type, dry mix, prepared, baked",
  "Pie crust, standard-type, frozen, ready-to-bake, enriched",
  "Pie crust, standard-type, frozen, ready-to-bake, enriched, baked",
  "Pie crust, standard-type, frozen, ready-to-bake, unenriched",
  "Pie crust, standard-type, prepared from recipe, baked",
  "Pie crust, standard-type, prepared from recipe, unbaked",
  "Pie fillings, apple, canned",
  "Pie fillings, blueberry, canned",
  "Pie fillings, canned, cherry",
  "Pie fillings, cherry, low calorie",
  "Pie, apple, commercially prepared, enriched flour",
  "Pie, apple, commercially prepared, unenriched flour",
  "Pie, apple, prepared from recipe",
  "Pie, banana cream, prepared from mix, no-bake type",
  "Pie, banana cream, prepared from recipe",
  "Pie, blueberry, commercially prepared",
  "Pie, blueberry, prepared from recipe",
  "Pie, cherry, commercially prepared",
  "Pie, cherry, prepared from recipe",
  "Pie, chocolate creme, commercially prepared",
  "Pie, chocolate mousse, prepared from mix, no-bake type",
  "Pie, coconut cream, prepared from mix, no-bake type",
  "Pie, coconut creme, commercially prepared",
  "Pie, coconut custard, commercially prepared",
  "Pie, Dutch Apple, Commercially Prepared",
  "Pie, egg custard, commercially prepared",
  "Pie, fried pies, cherry",
  "Pie, fried pies, fruit",
  "Pie, fried pies, lemon",
  "Pie, lemon meringue, commercially prepared",

  "Pie, lemon meringue, prepared from recipe",
  "Pie, mince, prepared from recipe",
  "Pie, peach",
  "Pie, pecan, commercially prepared",
  "Pie, pecan, prepared from recipe",
  "Pie, pumpkin, commercially prepared",
  "Pie, pumpkin, prepared from recipe",
  "Pie, vanilla cream, prepared from recipe",
  "Pigeon peas (red gram), mature seeds, cooked, boiled, with salt",
  "Pigeon peas (red gram), mature seeds, cooked, boiled, without salt",
  "Pigeon peas (red gram), mature seeds, raw",
  "Pigeonpeas, immature seeds, cooked, boiled, drained, with salt",
  "Pigeonpeas, immature seeds, cooked, boiled, drained, without salt",
  "Pigeonpeas, immature seeds, raw",
  "Piki bread, made from blue cornmeal (Hopi)",
  "Pillsbury Golden Layer Buttermilk Biscuits, Artificial Flavor, refrigerated dough",
  "Pillsbury Grands, Buttermilk Biscuits, refrigerated dough",
  "Pillsbury, Buttermilk Biscuits, Artificial Flavor, refrigerated dough",
  "Pillsbury, Chocolate Chip Cookies, refrigerated dough",
  "Pillsbury, Cinnamon Rolls with Icing, refrigerated dough",
  "Pillsbury, Crusty French Loaf, refrigerated dough",
  "Pimento, canned",
  "Pineapple juice, canned or bottled, unsweetened, with added ascorbic acid",
  "Pineapple juice, canned or bottled, unsweetened, without added ascorbic acid",
  "Pineapple juice, canned, not from concentrate, unsweetened, with added vitamins A, C and E",
  "Pineapple juice, frozen concentrate, unsweetened, diluted with 3 volume water",
  "Pineapple juice, frozen concentrate, unsweetened, undiluted",
  "Pineapple, canned, extra heavy syrup pack, solids and liquids",
  "Pineapple, canned, heavy syrup pack, solids and liquids",
  "Pineapple, canned, juice pack, drained",
  "Pineapple, canned, juice pack, solids and liquids",
  "Pineapple, canned, light syrup pack, solids and liquids",
  "Pineapple, canned, water pack, solids and liquids",
  "Pineapple, frozen, chunks, sweetened",
  "Pineapple, raw, all varieties",
  "Pineapple, raw, extra sweet variety",
  "Pineapple, raw, traditional varieties",
  "Pinon Nuts, roasted (Navajo)",
  "Pitanga, (surinam-cherry), raw",
  "PIZZA HUT 12\" Cheese Pizza, Hand-Tossed Crust",
  "PIZZA HUT 12\" Cheese Pizza, Pan Crust",
  "PIZZA HUT 12\" Cheese Pizza, THIN 'N CRISPY Crust",
  "PIZZA HUT 12\" Pepperoni Pizza, Hand-Tossed Crust",
  "PIZZA HUT 12\" Pepperoni Pizza, Pan Crust",
  "PIZZA HUT 12\" Super Supreme Pizza, Hand-Tossed Crust",
  "PIZZA HUT 14\" Cheese Pizza, Hand-Tossed Crust",
  "PIZZA HUT 14\" Cheese Pizza, Pan Crust",
  "PIZZA HUT 14\" Cheese Pizza, Stuffed Crust",
  "PIZZA HUT 14\" Cheese Pizza, THIN 'N CRISPY Crust",
  "PIZZA HUT 14\" Pepperoni Pizza, Hand-Tossed Crust",

  "PIZZA HUT 14\" Pepperoni Pizza, Pan Crust",
  "PIZZA HUT 14\" Pepperoni Pizza, THIN 'N CRISPY Crust",
  "PIZZA HUT 14\" Sausage Pizza, Hand-Tossed Crust",
  "PIZZA HUT 14\" Sausage Pizza, Pan Crust",
  "PIZZA HUT 14\" Sausage Pizza, THIN 'N CRISPY Crust",
  "PIZZA HUT 14\" Super Supreme Pizza, Hand-Tossed Crust",
  "PIZZA HUT, breadstick, parmesan garlic",
  "Pizza rolls, frozen, unprepared",
  "Pizza, cheese topping, regular crust, frozen, cooked",
  "Pizza, cheese topping, rising crust, frozen, cooked",
  "Pizza, cheese topping, thin crust, frozen, cooked",
  "Pizza, meat and vegetable topping, regular crust, frozen, cooked",
  "Pizza, meat and vegetable topping, rising crust, frozen, cooked",
  "Pizza, meat topping, thick crust, frozen, cooked",
  "Pizza, pepperoni topping, regular crust, frozen, cooked",
  "Plantains, green, boiled",
  "Plantains, green, fried",
  "Plantains, green, raw",
  "Plantains, yellow, baked",
  "Plantains, yellow, fried, Latino restaurant",
  "Plantains, yellow, raw",
  "Plums, canned, heavy syrup, drained",
  "Plums, canned, purple, extra heavy syrup pack, solids and liquids",
  "Plums, canned, purple, heavy syrup pack, solids and liquids",
  "Plums, canned, purple, juice pack, solids and liquids",
  "Plums, canned, purple, light syrup pack, solids and liquids",
  "Plums, canned, purple, water pack, solids and liquids",
  "Plums, dried (prunes), stewed, with added sugar",
  "Plums, dried (prunes), stewed, without added sugar",
  "Plums, dried (prunes), uncooked",
  "Plums, raw",
  "Plums, wild (Northern Plains Indians)",
  "Poi",
  "Pokeberry shoots, (poke), cooked, boiled, drained, with salt",
  "Pokeberry shoots, (poke), cooked, boiled, drained, without salt",
  "Pokeberry shoots, (poke), raw",
  "Polish sausage, pork",
  "Pomegranate juice, bottled",
  "Pomegranates, raw",
  "Popcorn, microwave, low fat and sodium",
  "Popcorn, microwave, regular (butter) flavor, made with palm oil",
  "Popcorn, sugar syrup/caramel, fat-free",
  "POPEYES, biscuit",
  "POPEYES, Coleslaw",
  "POPEYES, Fried Chicken, Mild, Breast, meat and skin with breading",
  "POPEYES, Fried Chicken, Mild, Breast, meat only, skin and breading removed",
  "POPEYES, Fried Chicken, Mild, Drumstick, meat and skin with breading",
  "POPEYES, Fried Chicken, Mild, Drumstick, meat only, skin and breading removed",
  "POPEYES, Fried Chicken, Mild, Skin and Breading",
  "POPEYES, Fried Chicken, Mild, Thigh, meat and skin with breading",

  "POPEYES, Fried Chicken, Mild, Thigh, meat only, skin and breading removed",
  "POPEYES, Fried Chicken, Mild, Wing, meat and skin with breading",
  "POPEYES, Fried Chicken, Mild, Wing, meat only, skin and breading removed",
  "POPEYES, Mild Chicken Strips, analyzed 2006",
  "POPEYES, Spicy Chicken Strips, analyzed 2006",
  "Popovers, dry mix, enriched",
  "Popovers, dry mix, unenriched",
  "Pork loin, fresh, backribs, bone-in, cooked-roasted, lean only",
  "Pork loin, fresh, backribs, bone-in, raw, lean only",
  "Pork sausage rice links, brown and serve, cooked",
  "Pork sausage, link/patty, cooked, pan-fried",
  "Pork sausage, link/patty, fully cooked, microwaved",
  "Pork sausage, link/patty, fully cooked, unheated",
  "Pork sausage, link/patty, reduced fat, cooked, pan-fried",
  "Pork sausage, link/patty, reduced fat, unprepared",
  "Pork sausage, link/patty, unprepared",
  "Pork sausage, reduced sodium, cooked",
  "Pork, bacon, rendered fat, cooked",
  "Pork, cured, bacon, cooked, baked",
  "Pork, cured, bacon, cooked, broiled, pan-fried or roasted, reduced sodium",
  "Pork, cured, bacon, cooked, microwaved",
  "Pork, cured, bacon, pre-sliced, cooked, pan-fried",
  "Pork, cured, bacon, unprepared",
  "Pork, cured, breakfast strips, raw or unheated",
  "Pork, cured, feet, pickled",
  "Pork, cured, ham -- water added, rump, bone-in, separable lean and fat, heated, roasted",
  "Pork, cured, ham -- water added, rump, bone-in, separable lean and fat, unheated",
  "Pork, cured, ham -- water added, rump, bone-in, separable lean only, heated, roasted",
  "Pork, cured, ham -- water added, rump, bone-in, separable lean only, unheated",
  "Pork, cured, ham -- water added, shank, bone-in, separable lean and fat, heated, roasted",
  "Pork, cured, ham -- water added, shank, bone-in, separable lean and fat, unheated",
  "Pork, cured, ham -- water added, shank, bone-in, separable lean only, heated, roasted",
  "Pork, cured, ham -- water added, shank, bone-in, separable lean only, unheated",
  "Pork, cured, ham -- water added, slice, bone-in, separable lean and fat, heated, pan-broil",
  "Pork, cured, ham -- water added, slice, bone-in, separable lean and fat, unheated",
  "Pork, cured, ham -- water added, slice, bone-in, separable lean only, heated, pan-broil",
  "Pork, cured, ham -- water added, slice, bone-in, separable lean only, unheated",
  "Pork, cured, ham -- water added, slice, boneless, separable lean and fat, heated, pan-broil",
  "Pork, cured, ham -- water added, slice, boneless, separable lean only, heated, pan-broil",
  "Pork, cured, ham -- water added, whole, boneless, separable lean and fat, heated, roasted",
  "Pork, cured, ham -- water added, whole, boneless, separable lean and fat, unheated",
  "Pork, cured, ham -- water added, whole, boneless, separable lean only, heated, roasted",
  "Pork, cured, ham -- water added, whole, boneless, separable lean only, unheated",
  "Pork, cured, ham and water product, rump, bone-in, separable lean and fat, heated, roasted",
  "Pork, cured, ham and water product, rump, bone-in, separable lean and fat, unheated",
  "Pork, cured, ham and water product, rump, bone-in, separable lean only, heated, roasted",
  "Pork, cured, ham and water product, rump, bone-in, separable lean only, unheated",
  "Pork, cured, ham and water product, shank, bone-in, separable lean and fat, heated, roasted",
  "Pork, cured, ham and water product, shank, bone-in, separable lean and fat, unheated",
  "Pork, cured, ham and water product, shank, bone-in, separable lean only, heated, roasted",

  "Pork, cured, ham and water product, shank, bone-in, unheated, separable lean only",
  "Pork, cured, ham and water product, slice, bone-in, separable lean and fat, heated, pan-broil",
  "Pork, cured, ham and water product, slice, bone-in, separable lean and fat, unheated",
  "Pork, cured, ham and water product, slice, bone-in, separable lean only, heated, pan-broil",
  "Pork, cured, ham and water product, slice, bone-in, separable lean only, unheated",
  "Pork, cured, ham and water product, slice, boneless, separable lean and fat, heated, pan-broil",
  "Pork, cured, ham and water product, slice, boneless, separable lean only, heated, pan-broil",
  "Pork, cured, ham and water product, whole, boneless, separable lean and fat, heated, roasted",
  "Pork, cured, ham and water product, whole, boneless, separable lean and fat, unheated",
  "Pork, cured, ham and water product, whole, boneless, separable lean only, heated, roasted",
  "Pork, cured, ham and water product, whole, boneless, separable lean only, unheated",
  "Pork, cured, ham with natural juices, rump, bone-in, separable lean and fat, heated, roasted",
  "Pork, cured, ham with natural juices, rump, bone-in, separable lean and fat, unheated",
  "Pork, cured, ham with natural juices, rump, bone-in, separable lean only, heated, roasted",
  "Pork, cured, ham with natural juices, rump, bone-in, separable lean only, unheated",
  "Pork, cured, ham with natural juices, shank, bone-in, separable lean and fat, heated, roasted",
  "Pork, cured, ham with natural juices, shank, bone-in, separable lean and fat, unheated",
  "Pork, cured, ham with natural juices, shank, bone-in, separable lean only, heated, roasted",
  "Pork, cured, ham with natural juices, shank, bone-in, separable lean only, unheated",
  "Pork, cured, ham with natural juices, slice, bone-in, separable lean and fat, heated, pan-broil",
  "Pork, cured, ham with natural juices, slice, bone-in, separable lean and fat, unheated",
  "Pork, cured, ham with natural juices, slice, bone-in, separable lean only, heated, pan-broil",
  "Pork, cured, ham with natural juices, slice, bone-in, separable lean only, unheated",
  "Pork, cured, ham with natural juices, slice, boneless, separable lean and fat, heated, pan-broil",
  "Pork, cured, ham with natural juices, slice, boneless, separable lean only, heated, pan-broil",
  "Pork, cured, ham with natural juices, spiral slice, boneless, separable lean and fat, heated, roasted",
  "Pork, cured, ham with natural juices, spiral slice, boneless, separable lean and fat, unheated",
  "Pork, cured, ham with natural juices, spiral slice, boneless, separable lean only, unheated",
  "Pork, cured, ham with natural juices, spiral slice, meat only, boneless, separable lean only, heated, roasted",
  "Pork, cured, ham with natural juices, whole, boneless, separable lean and fat, heated, roasted",
  "Pork, cured, ham with natural juices, whole, boneless, separable lean and fat, unheated",
  "Pork, cured, ham with natural juices, whole, boneless, separable lean only, heated, roasted",
  "Pork, cured, ham with natural juices, whole, boneless, separable lean only, unheated",
  "Pork, cured, ham, boneless, extra lean (approximately 5% fat), roasted",
  "Pork, cured, ham, boneless, extra lean and regular, roasted",
  "Pork, cured, ham, boneless, extra lean and regular, unheated",
  "Pork, cured, ham, boneless, low sodium, extra lean (approximately 5% fat), roasted",
  "Pork, cured, ham, boneless, low sodium, extra lean and regular, roasted",
  "Pork, cured, ham, boneless, regular (approximately 11% fat), roasted",
  "Pork, cured, ham, center slice, country-style, separable lean only, raw",
  "Pork, cured, ham, center slice, separable lean and fat, unheated",
  "Pork, cured, ham, extra lean (approximately 4% fat), canned, roasted",
  "Pork, cured, ham, extra lean (approximately 4% fat), canned, unheated",
  "Pork, cured, ham, extra lean and regular, canned, roasted",
  "Pork, cured, ham, extra lean and regular, canned, unheated",
  "Pork, cured, ham, low sodium, lean and fat, cooked",
  "Pork, cured, ham, patties, unheated",
  "Pork, cured, ham, regular (approximately 13% fat), canned, roasted",
  "Pork, cured, ham, rump, bone-in, separable lean and fat, heated, roasted",
  "Pork, cured, ham, rump, bone-in, separable lean and fat, unheated",

  "Pork, cured, ham, rump, bone-in, separable lean only, heated, roasted",
  "Pork, cured, ham, rump, bone-in, separable lean only, unheated",
  "Pork, cured, ham, separable fat, boneless, heated",
  "Pork, cured, ham, separable fat, boneless, unheated",
  "Pork, cured, ham, shank, bone-in, separable lean and fat, heated, roasted",
  "Pork, cured, ham, shank, bone-in, separable lean and fat, unheated",
  "Pork, cured, ham, shank, bone-in, separable lean only, heated, roasted",
  "Pork, cured, ham, shank, bone-in, separable lean only, unheated",
  "Pork, cured, ham, slice, bone-in, separable lean and fat, heated, pan-broil",
  "Pork, cured, ham, slice, bone-in, separable lean and fat, unheated",
  "Pork, cured, ham, slice, bone-in, separable lean only, heated, pan-broil",
  "Pork, cured, ham, slice, bone-in, separable lean only, unheated",
  "Pork, cured, ham, steak, boneless, extra lean, unheated",
  "Pork, cured, ham, whole, separable lean and fat, unheated",
  "Pork, cured, ham, whole, separable lean only, roasted",
  "Pork, cured, ham, whole, separable lean only, unheated",
  "Pork, cured, salt pork, raw",
  "Pork, cured, separable fat (from ham and arm picnic), roasted",
  "Pork, cured, separable fat (from ham and arm picnic), unheated",
  "Pork, cured, shoulder, arm picnic, separable lean and fat, roasted",
  "Pork, cured, shoulder, arm picnic, separable lean only, roasted",
  "Pork, cured, shoulder, blade roll, separable lean and fat, roasted",
  "Pork, cured, shoulder, blade roll, separable lean and fat, unheated",
  "Pork, fresh, backfat, raw",
  "Pork, fresh, backribs, separable lean and fat, cooked, roasted",
  "Pork, fresh, backribs, separable lean and fat, raw",
  "Pork, fresh, belly, raw",
  "Pork, fresh, blade, (chops), boneless, separable lean and fat, cooked, broiled",
  "Pork, fresh, carcass, separable lean and fat, raw",
  "Pork, fresh, composite of separable fat, with added solution, cooked",
  "Pork, fresh, composite of separable fat, with added solution, raw",
  "Pork, fresh, composite of trimmed leg, loin, shoulder, and spareribs, (includes cuts to be cured), separable lean and fat, raw",
  "Pork, fresh, composite of trimmed retail cuts (leg, loin, and shoulder), separable lean only, cooked",
  "Pork, fresh, composite of trimmed retail cuts (leg, loin, shoulder), separable lean only, raw",
  "Pork, fresh, composite of trimmed retail cuts (leg, loin, shoulder, and spareribs), separable lean and fat, cooked",
  "Pork, fresh, composite of trimmed retail cuts (leg, loin, shoulder, and spareribs), separable lean and fat, raw",
  "Pork, fresh, composite of trimmed retail cuts (loin and shoulder blade), separable lean and fat, cooked",
  "Pork, fresh, composite of trimmed retail cuts (loin and shoulder blade), separable lean and fat, raw",
  "Pork, fresh, composite of trimmed retail cuts (loin and shoulder blade), separable lean only, cooked",
  "Pork, fresh, composite of trimmed retail cuts (loin and shoulder blade), separable lean only, raw",
  "Pork, fresh, enhanced, loin, tenderloin, separable lean only, raw",
  "Pork, fresh, ground, cooked",
  "Pork, fresh, ground, raw",
  "Pork, fresh, leg (ham), rump half, separable lean and fat, cooked, roasted",
  "Pork, fresh, leg (ham), rump half, separable lean and fat, raw",
  "Pork, fresh, leg (ham), rump half, separable lean only, cooked, roasted",
  "Pork, fresh, leg (ham), rump half, separable lean only, raw (Includes foods for USDA's Food Distribution Program)",
  "Pork, fresh, leg (ham), shank half, separable lean and fat, cooked, roasted",
  "Pork, fresh, leg (ham), shank half, separable lean and fat, raw",
  "Pork, fresh, leg (ham), shank half, separable lean only, cooked, roasted",

  "Pork, fresh, leg (ham), shank half, separable lean only, raw",
  "Pork, fresh, leg (ham), whole, separable lean and fat, cooked, roasted",
  "Pork, fresh, leg (ham), whole, separable lean and fat, raw",
  "Pork, fresh, leg (ham), whole, separable lean only, cooked, roasted",
  "Pork, fresh, leg (ham), whole, separable lean only, raw",
  "Pork, fresh, loin, blade (chops or roasts), bone-in, separable lean and fat, raw",
  "Pork, fresh, loin, blade (chops or roasts), bone-in, separable lean only, raw",
  "Pork, fresh, loin, blade (chops or roasts), boneless, separable lean and fat only, raw",
  "Pork, fresh, loin, blade (chops or roasts), boneless, separable lean only, raw",
  "Pork, fresh, loin, blade (chops), bone-in, separable lean and fat, cooked, braised",
  "Pork, fresh, loin, blade (chops), bone-in, separable lean and fat, cooked, broiled",
  "Pork, fresh, loin, blade (chops), bone-in, separable lean and fat, cooked, pan-fried",
  "Pork, fresh, loin, blade (chops), bone-in, separable lean only, cooked, braised",
  "Pork, fresh, loin, blade (chops), bone-in, separable lean only, cooked, broiled",
  "Pork, fresh, loin, blade (chops), bone-in, separable lean only, cooked, pan-fried",
  "Pork, fresh, loin, blade (chops), boneless, separable lean only, boneless, cooked, broiled",
  "Pork, fresh, loin, blade (roasts), bone-in, separable lean and fat, cooked, roasted",
  "Pork, fresh, loin, blade (roasts), bone-in, separable lean only, cooked, roasted",
  "Pork, fresh, loin, blade (roasts), boneless, separable lean and fat, cooked, roasted",
  "Pork, fresh, loin, blade (roasts), boneless, separable lean only, cooked, roasted",
  "Pork, fresh, loin, center loin (chops), bone-in, separable lean and fat, cooked, braised",
  "Pork, fresh, loin, center loin (chops), bone-in, separable lean and fat, cooked, broiled",
  "Pork, fresh, loin, center loin (chops), bone-in, separable lean and fat, cooked, pan-fried",
  "Pork, fresh, loin, center loin (chops), bone-in, separable lean and fat, raw",
  "Pork, fresh, loin, center loin (chops), bone-in, separable lean only, cooked, braised",
  "Pork, fresh, loin, center loin (chops), bone-in, separable lean only, cooked, broiled",
  "Pork, fresh, loin, center loin (chops), bone-in, separable lean only, cooked, pan-fried",
  "Pork, fresh, loin, center loin (chops), bone-in, separable lean only, raw",
  "Pork, fresh, loin, center loin (roasts), bone-in, separable lean and fat, cooked, roasted",
  "Pork, fresh, loin, center loin (roasts), bone-in, separable lean only, cooked, roasted",
  "Pork, fresh, loin, center rib (chops or roasts), bone-in, separable lean and fat, raw",
  "Pork, fresh, loin, center rib (chops or roasts), bone-in, separable lean only, raw",
  "Pork, fresh, loin, center rib (chops or roasts), boneless, separable lean and fat, raw",
  "Pork, fresh, loin, center rib (chops or roasts), boneless, separable lean only, raw",
  "Pork, fresh, loin, center rib (chops), bone-in, separable lean and fat, cooked, braised",
  "Pork, fresh, loin, center rib (chops), bone-in, separable lean and fat, cooked, broiled",
  "Pork, fresh, loin, center rib (chops), bone-in, separable lean and fat, cooked, pan-fried",
  "Pork, fresh, loin, center rib (chops), bone-in, separable lean only, cooked, braised",
  "Pork, fresh, loin, center rib (chops), bone-in, separable lean only, cooked, broiled",
  "Pork, fresh, loin, center rib (chops), bone-in, separable lean only, cooked, pan-fried",
  "Pork, fresh, loin, center rib (chops), boneless, separable lean and fat, cooked, braised",
  "Pork, fresh, loin, center rib (chops), boneless, separable lean and fat, cooked, broiled",
  "Pork, fresh, loin, center rib (chops), boneless, separable lean and fat, cooked, pan-fried",
  "Pork, fresh, loin, center rib (chops), boneless, separable lean only, cooked, braised",
  "Pork, fresh, loin, center rib (chops), boneless, separable lean only, cooked, broiled",
  "Pork, fresh, loin, center rib (chops), boneless, separable lean only, cooked, pan-fried",

  "Pork, fresh, loin, center rib (roasts), bone-in, separable lean and fat, cooked, roasted",
  "Pork, fresh, loin, center rib (roasts), bone-in, separable lean only, cooked, roasted",
  "Pork, fresh, loin, center rib (roasts), boneless, separable lean and fat, cooked, roasted",
  "Pork, fresh, loin, center rib (roasts), boneless, separable lean only, cooked, roasted",
  "Pork, fresh, loin, country-style ribs, separable lean and fat, bone-in, cooked, broiled",
  "Pork, fresh, loin, country-style ribs, separable lean and fat, bone-in, cooked, roasted",
  "Pork, fresh, loin, country-style ribs, separable lean and fat, boneless, cooked, broiled",
  "Pork, fresh, loin, country-style ribs, separable lean and fat, boneless, cooked, roasted",
  "Pork, fresh, loin, country-style ribs, separable lean and fat, cooked, braised",
  "Pork, fresh, loin, country-style ribs, separable lean and fat, raw",
  "Pork, fresh, loin, country-style ribs, separable lean only, bone-in, cooked, broiled",
  "Pork, fresh, loin, country-style ribs, separable lean only, bone-in, cooked, roasted",
  "Pork, fresh, loin, country-style ribs, separable lean only, boneless, cooked, broiled",
  "Pork, fresh, loin, country-style ribs, separable lean only, boneless, cooked, roasted",
  "Pork, fresh, loin, country-style ribs, separable lean only, cooked, braised",
  "Pork, fresh, loin, country-style ribs, separable lean only, raw",
  "Pork, fresh, loin, sirloin (chops or roasts), bone-in, separable lean and fat, raw",
  "Pork, fresh, loin, sirloin (chops or roasts), bone-in, separable lean only, raw",
  "Pork, fresh, loin, sirloin (chops or roasts), boneless, separable lean and fat, raw",
  "Pork, fresh, loin, sirloin (chops or roasts), boneless, separable lean only, raw",
  "Pork, fresh, loin, sirloin (chops), bone-in, separable lean and fat, cooked, braised",
  "Pork, fresh, loin, sirloin (chops), bone-in, separable lean and fat, cooked, broiled",
  "Pork, fresh, loin, sirloin (chops), bone-in, separable lean only, cooked, braised",
  "Pork, fresh, loin, sirloin (chops), bone-in, separable lean only, cooked, broiled",
  "Pork, fresh, loin, sirloin (chops), boneless, separable lean and fat, cooked, braised",
  "Pork, fresh, loin, sirloin (chops), boneless, separable lean and fat, cooked, broiled",
  "Pork, fresh, loin, sirloin (chops), boneless, separable lean only, cooked, braised",
  "Pork, fresh, loin, sirloin (chops), boneless, separable lean only, cooked, broiled",
  "Pork, fresh, loin, sirloin (roasts), bone-in, separable lean and fat, cooked, roasted",
  "Pork, fresh, loin, sirloin (roasts), bone-in, separable lean only, cooked, roasted",
  "Pork, fresh, loin, sirloin (roasts), boneless, separable lean and fat, cooked, roasted",
  "Pork, fresh, loin, sirloin (roasts), boneless, separable lean only, cooked, roasted",
  "Pork, fresh, loin, tenderloin, separable lean and fat, cooked, broiled",
  "Pork, fresh, loin, tenderloin, separable lean and fat, cooked, roasted",
  "Pork, fresh, loin, tenderloin, separable lean and fat, raw",
  "Pork, fresh, loin, tenderloin, separable lean and fat, with added solution, cooked, roasted",
  "Pork, fresh, loin, tenderloin, separable lean and fat, with added solution, raw",
  "Pork, fresh, loin, tenderloin, separable lean only, cooked, broiled",
  "Pork, fresh, loin, tenderloin, separable lean only, cooked, roasted",
  "Pork, fresh, loin, tenderloin, separable lean only, raw",
  "Pork, fresh, loin, tenderloin, separable lean only, with added solution, cooked, roasted",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean and fat, cooked, braised",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean and fat, cooked, broiled",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean and fat, cooked, pan-fried",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean and fat, raw",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean and fat, with added solution, cooked, broiled",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean and fat, with added solution, cooked, pan-broiled",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean and fat, with added solution, raw",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean only, cooked, braised",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean only, cooked, broiled",

  "Pork, fresh, loin, top loin (chops), boneless, separable lean only, cooked, pan-fried",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean only, raw",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean only, with added solution, cooked, broiled",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean only, with added solution, cooked, pan-broiled",
  "Pork, fresh, loin, top loin (chops), boneless, separable lean only, with added solution, raw",
  "Pork, fresh, loin, top loin (roasts), boneless, separable lean and fat, cooked, roasted",
  "Pork, fresh, loin, top loin (roasts), boneless, separable lean and fat, raw",
  "Pork, fresh, loin, top loin (roasts), boneless, separable lean only, cooked, roasted",
  "Pork, fresh, loin, top loin (roasts), boneless, separable lean only, raw",
  "Pork, fresh, loin, whole, separable lean and fat, cooked, braised",
  "Pork, fresh, loin, whole, separable lean and fat, cooked, broiled",
  "Pork, fresh, loin, whole, separable lean and fat, cooked, roasted",
  "Pork, fresh, loin, whole, separable lean and fat, raw",
  "Pork, fresh, loin, whole, separable lean only, cooked, braised",
  "Pork, fresh, loin, whole, separable lean only, cooked, broiled",
  "Pork, fresh, loin, whole, separable lean only, cooked, roasted",
  "Pork, fresh, loin, whole, separable lean only, raw",
  "Pork, fresh, separable fat, cooked",
  "Pork, fresh, separable fat, raw",
  "Pork, fresh, shoulder, (Boston butt), blade (steaks), separable lean and fat, cooked, braised",
  "Pork, fresh, shoulder, (Boston butt), blade (steaks), separable lean and fat, raw",
  "Pork, fresh, shoulder, (Boston butt), blade (steaks), separable lean and fat, with added solution, cooked, braised",
  "Pork, fresh, shoulder, (Boston butt), blade (steaks), separable lean and fat, with added solution, raw",
  "Pork, fresh, shoulder, (Boston butt), blade (steaks), separable lean only, cooked, braised",
  "Pork, fresh, shoulder, (Boston butt), blade (steaks), separable lean only, raw",
  "Pork, fresh, shoulder, (Boston butt), blade (steaks), separable lean only, with added solution cooked, braised",
  "Pork, fresh, shoulder, (Boston butt), blade (steaks), separable lean only, with added solution, raw",
  "Pork, fresh, shoulder, arm picnic, separable lean and fat, cooked, braised",
  "Pork, fresh, shoulder, arm picnic, separable lean and fat, cooked, roasted",
  "Pork, fresh, shoulder, arm picnic, separable lean and fat, raw",
  "Pork, fresh, shoulder, arm picnic, separable lean only, cooked, braised",
  "Pork, fresh, shoulder, arm picnic, separable lean only, cooked, roasted",
  "Pork, fresh, shoulder, arm picnic, separable lean only, raw",
  "Pork, fresh, shoulder, blade, boston (roasts), separable lean and fat, cooked, roasted",
  "Pork, fresh, shoulder, blade, boston (roasts), separable lean only, cooked, roasted",
  "Pork, fresh, shoulder, blade, boston (steaks), separable lean and fat, cooked, broiled",
  "Pork, fresh, shoulder, blade, boston (steaks), separable lean only, cooked, broiled",
  "Pork, fresh, shoulder, whole, separable lean and fat, cooked, roasted",
  "Pork, fresh, shoulder, whole, separable lean and fat, raw",
  "Pork, fresh, shoulder, whole, separable lean only, cooked, roasted",
  "Pork, fresh, shoulder, whole, separable lean only, raw",
  "Pork, fresh, spareribs, separable lean and fat, cooked, braised",
  "Pork, fresh, spareribs, separable lean and fat, cooked, roasted",
  "Pork, fresh, spareribs, separable lean and fat, raw",
  "Pork, fresh, variety meats and by-products, brain, cooked, braised",
  "Pork, fresh, variety meats and by-products, brain, raw",
  "Pork, fresh, variety meats and by-products, chitterlings, cooked, simmered",
  "Pork, fresh, variety meats and by-products, chitterlings, raw",
  "Pork, fresh, variety meats and by-products, ears, frozen, cooked, simmered",
  "Pork, fresh, variety meats and by-products, ears, frozen, raw",

  "Pork, fresh, variety meats and by-products, feet, cooked, simmered",
  "Pork, fresh, variety meats and by-products, feet, raw",
  "Pork, fresh, variety meats and by-products, heart, cooked, braised",
  "Pork, fresh, variety meats and by-products, heart, raw",
  "Pork, fresh, variety meats and by-products, jowl, raw",
  "Pork, fresh, variety meats and by-products, kidneys, cooked, braised",
  "Pork, fresh, variety meats and by-products, kidneys, raw",
  "Pork, fresh, variety meats and by-products, leaf fat, raw",
  "Pork, fresh, variety meats and by-products, liver, cooked, braised",
  "Pork, fresh, variety meats and by-products, liver, raw",
  "Pork, fresh, variety meats and by-products, lungs, cooked, braised",
  "Pork, fresh, variety meats and by-products, lungs, raw",
  "Pork, fresh, variety meats and by-products, mechanically separated, raw",
  "Pork, fresh, variety meats and by-products, pancreas, cooked, braised",
  "Pork, fresh, variety meats and by-products, pancreas, raw",
  "Pork, fresh, variety meats and by-products, spleen, cooked, braised",
  "Pork, fresh, variety meats and by-products, spleen, raw",
  "Pork, fresh, variety meats and by-products, stomach, cooked, simmered",
  "Pork, fresh, variety meats and by-products, stomach, raw",
  "Pork, fresh, variety meats and by-products, tail, cooked, simmered",
  "Pork, fresh, variety meats and by-products, tail, raw",
  "Pork, fresh, variety meats and by-products, tongue, cooked, braised",
  "Pork, fresh, variety meats and by-products, tongue, raw",
  "Pork, ground, 72% lean / 28% fat, cooked, crumbles",
  "Pork, ground, 72% lean / 28% fat, cooked, pan-broiled",
  "Pork, ground, 84% lean / 16% fat, cooked, crumbles",
  "Pork, ground, 84% lean / 16% fat, cooked, pan-broiled",
  "Pork, ground, 84% lean / 16% fat, raw",
  "Pork, ground, 96% lean / 4% fat, cooked, crumbles",
  "Pork, ground, 96% lean / 4% fat, cooked, pan-broiled",
  "Pork, ground, 96% lean / 4% fat, raw",
  "Pork, Leg Cap Steak, boneless, separable lean and fat, raw",
  "Pork, Leg sirloin tip roast, boneless, separable lean and fat, cooked, braised",
  "Pork, Leg sirloin tip roast, boneless, separable lean and fat, raw",
  "Pork, loin, leg cap steak, boneless, separable lean and fat, cooked, broiled",
  "Pork, oriental style, dehydrated",
  "Pork, pickled pork hocks",
  "Pork, Shoulder breast, boneless, separable lean and fat, cooked, broiled",
  "Pork, Shoulder breast, boneless, separable lean and fat, raw",
  "Pork, Shoulder petite tender, boneless, separable lean and fat, raw",
  "Pork, shoulder, petite tender, boneless, separable lean and fat, cooked, broiled",
  "Potato chips, without salt, reduced fat",
  "Potato flour",
  "Potato pancakes",
  "Potato puffs, frozen, oven-heated",
  "Potato puffs, frozen, unprepared",
  "Potato salad with egg",
  "Potato salad, home-prepared",
  "Potato soup, instant, dry mix",
  "Potato wedges, frozen (Includes foods for USDA's Food Distribution Program)",

  "Potatoes, au gratin, dry mix, prepared with water, whole milk and butter",
  "Potatoes, au gratin, dry mix, unprepared",
  "Potatoes, au gratin, home-prepared from recipe using butter",
  "Potatoes, au gratin, home-prepared from recipe using margarine",
  "Potatoes, baked, flesh and skin, with salt",
  "Potatoes, baked, flesh and skin, without salt",
  "Potatoes, baked, flesh, with salt",
  "Potatoes, baked, flesh, without salt",
  "Potatoes, baked, skin only, with salt",
  "Potatoes, baked, skin, without salt",
  "Potatoes, boiled, cooked in skin, flesh, with salt",
  "Potatoes, boiled, cooked in skin, flesh, without salt",
  "Potatoes, boiled, cooked in skin, skin, with salt",
  "Potatoes, boiled, cooked in skin, skin, without salt",
  "Potatoes, boiled, cooked without skin, flesh, with salt",
  "Potatoes, boiled, cooked without skin, flesh, without salt",
  "Potatoes, canned, drained solids",
  "Potatoes, canned, drained solids, no salt added",
  "Potatoes, canned, solids and liquids",
  "Potatoes, flesh and skin, raw",
  "Potatoes, french fried, all types, salt added in processing, frozen, home-prepared, oven heated",
  "Potatoes, french fried, all types, salt added in processing, frozen, unprepared",
  "Potatoes, french fried, all types, salt not added in processing, frozen, as purchased",
  "Potatoes, french fried, all types, salt not added in processing, frozen, oven-heated",
  "Potatoes, french fried, cottage-cut, salt not added in processing, frozen, as purchased",
  "Potatoes, french fried, cottage-cut, salt not added in processing, frozen, oven-heated",
  "Potatoes, french fried, crinkle or regular cut, salt added in processing, frozen, as purchased",
  "Potatoes, french fried, crinkle or regular cut, salt added in processing, frozen, oven-heated",
  "Potatoes, french fried, cross cut, frozen, unprepared",
  "Potatoes, french fried, shoestring, salt added in processing, frozen, as purchased",
  "Potatoes, french fried, shoestring, salt added in processing, frozen, oven-heated",
  "Potatoes, french fried, steak cut, salt not added in processing, frozen, unprepared",
  "Potatoes, french fried, steak fries, salt added in processing, frozen, as purchased",
  "Potatoes, french fried, steak fries, salt added in processing, frozen, oven-heated",
  "Potatoes, french fried, wedge cut, frozen, unprepared",
  "Potatoes, frozen, french fried, par fried, cottage-cut, prepared, heated in oven, with salt",
  "Potatoes, frozen, french fried, par fried, extruded, prepared, heated in oven, without salt",
  "Potatoes, frozen, french fried, par fried, extruded, unprepared",
  "Potatoes, frozen, whole, cooked, boiled, drained, with salt",
  "Potatoes, frozen, whole, cooked, boiled, drained, without salt",
  "Potatoes, hash brown, frozen, plain, prepared, pan fried in canola oil",
  "Potatoes, hash brown, frozen, plain, unprepared",
  "Potatoes, hash brown, frozen, with butter sauce, prepared",
  "Potatoes, hash brown, frozen, with butter sauce, unprepared",
  "Potatoes, hash brown, home-prepared",
  "Potatoes, hash brown, refrigerated, prepared, pan-fried in canola oil",
  "Potatoes, hash brown, refrigerated, unprepared",
  "Potatoes, mashed, dehydrated, flakes without milk, dry form",
  "Potatoes, mashed, dehydrated, granules with milk, dry form",
  "Potatoes, mashed, dehydrated, granules without milk, dry form",

  "Potatoes, mashed, dehydrated, prepared from flakes without milk, whole milk and butter added",
  "Potatoes, mashed, dehydrated, prepared from flakes without milk, whole milk and margarine added",
  "Potatoes, mashed, dehydrated, prepared from granules with milk, water and margarine added",
  "Potatoes, mashed, dehydrated, prepared from granules without milk, whole milk and butter added",
  "Potatoes, mashed, home-prepared, whole milk added",
  "Potatoes, mashed, home-prepared, whole milk and butter added",
  "Potatoes, mashed, home-prepared, whole milk and margarine added",
  "Potatoes, mashed, prepared from granules, without milk, whole milk and margarine",
  "Potatoes, mashed, ready-to-eat",
  "Potatoes, microwaved, cooked in skin, flesh and skin, without salt",
  "Potatoes, microwaved, cooked in skin, flesh, with salt",
  "Potatoes, microwaved, cooked in skin, flesh, without salt",
  "Potatoes, microwaved, cooked in skin, skin, without salt",
  "Potatoes, microwaved, cooked, in skin, flesh and skin, with salt",
  "Potatoes, microwaved, cooked, in skin, skin with salt",
  "Potatoes, o'brien, frozen, prepared",
  "Potatoes, o'brien, frozen, unprepared",
  "Potatoes, o'brien, home-prepared",
  "Potatoes, raw, skin",
  "Potatoes, red, flesh and skin, baked",
  "Potatoes, red, flesh and skin, raw",
  "Potatoes, roasted, salt added in processing, frozen, unprepared",
  "Potatoes, Russet, flesh and skin, baked",
  "Potatoes, russet, flesh and skin, raw (Includes foods for USDA's Food Distribution Program)",
  "Potatoes, scalloped, dry mix, prepared with water, whole milk and butter",
  "Potatoes, scalloped, dry mix, unprepared",
  "Potatoes, scalloped, home-prepared with butter",
  "Potatoes, scalloped, home-prepared with margarine",
  "Potatoes, white, flesh and skin, baked",
  "Potatoes, white, flesh and skin, raw",
  "Potatoes, yellow fleshed, french fried, frozen, unprepared",
  "Potatoes, yellow fleshed, hash brown, shredded, salt added in processing, frozen, unprepared",
  "Potatoes, yellow fleshed, roasted, salt added in processing, frozen, unprepared",
  "Potsticker or wonton, pork and vegetable, frozen, unprepared",
  "Poultry salad sandwich spread",
  "Poultry, mechanically deboned, from backs and necks with skin, raw",
  "Poultry, mechanically deboned, from backs and necks without skin, raw",
  "Poultry, mechanically deboned, from mature hens, raw",
  "Prairie Turnips, boiled (Northern Plains Indians)",
  "Prairie Turnips, raw (Northern Plains Indians)",
  "Pretzels, soft",
  "Pretzels, soft, unsalted",
  "Prickly pears, broiled (Northern Plains Indians)",
  "Prickly pears, raw",
  "Prickly pears, raw (Northern Plains Indians)",
  "Protein supplement, milk based, Muscle Milk Light, powder",
  "Protein supplement, milk based, Muscle Milk, powder",
  "Prune juice, canned",
  "Prune puree",
  "Prunes, canned, heavy syrup pack, solids and liquids",

  "Prunes, dehydrated (low-moisture), stewed",
  "Prunes, dehydrated (low-moisture), uncooked",
  "Pudding, lemon, dry mix, regular, prepared with sugar, egg yolk and water",
  "Puddings, all flavors except chocolate, low calorie, instant, dry mix",
  "Puddings, all flavors except chocolate, low calorie, regular, dry mix",
  "Puddings, banana, dry mix, instant",
  "Puddings, banana, dry mix, instant, prepared with 2% milk",
  "Puddings, banana, dry mix, instant, prepared with whole milk",
  "Puddings, banana, dry mix, instant, with added oil",
  "Puddings, banana, dry mix, regular",
  "Puddings, banana, dry mix, regular, prepared with 2% milk",
  "Puddings, banana, dry mix, regular, prepared with whole milk",
  "Puddings, banana, dry mix, regular, with added oil",
  "Puddings, chocolate flavor, low calorie, instant, dry mix",
  "Puddings, chocolate flavor, low calorie, regular, dry mix",
  "Puddings, chocolate, dry mix, instant",
  "Puddings, chocolate, dry mix, instant, prepared with 2% milk",
  "Puddings, chocolate, dry mix, instant, prepared with whole milk",
  "Puddings, chocolate, dry mix, regular",
  "Puddings, chocolate, dry mix, regular, prepared with 2% milk",
  "Puddings, chocolate, dry mix, regular, prepared with whole milk",
  "Puddings, chocolate, ready-to-eat",
  "Puddings, chocolate, ready-to-eat, fat free",
  "Puddings, coconut cream, dry mix, instant",
  "Puddings, coconut cream, dry mix, instant, prepared with 2% milk",
  "Puddings, coconut cream, dry mix, instant, prepared with whole milk",
  "Puddings, coconut cream, dry mix, regular",
  "Puddings, coconut cream, dry mix, regular, prepared with 2% milk",
  "Puddings, coconut cream, dry mix, regular, prepared with whole milk",
  "Puddings, lemon, dry mix, instant",
  "Puddings, lemon, dry mix, instant, prepared with 2% milk",
  "Puddings, lemon, dry mix, instant, prepared with whole milk",
  "Puddings, lemon, dry mix, regular",
  "Puddings, lemon, dry mix, regular, with added oil, potassium, sodium",
  "Puddings, rice, dry mix",
  "Puddings, rice, dry mix, prepared with 2% milk",
  "Puddings, rice, dry mix, prepared with whole milk",
  "Puddings, rice, ready-to-eat",
  "Puddings, tapioca, dry mix",
  "Puddings, tapioca, dry mix, prepared with 2% milk",
  "Puddings, tapioca, dry mix, prepared with whole milk",
  "Puddings, tapioca, dry mix, with no added salt",
  "Puddings, tapioca, ready-to-eat",
  "Puddings, tapioca, ready-to-eat, fat free",
  "Puddings, vanilla, dry mix, instant",
  "Puddings, vanilla, dry mix, instant, prepared with whole milk",
  "Puddings, vanilla, dry mix, regular",
  "Puddings, vanilla, dry mix, regular, prepared with 2% milk",
  "Puddings, vanilla, dry mix, regular, prepared with whole milk",
  "Puddings, vanilla, dry mix, regular, with added oil",

  "Puddings, vanilla, ready-to-eat",
  "Puddings, vanilla, ready-to-eat, fat free",
  "Puff pastry, frozen, ready-to-bake",
  "Puff pastry, frozen, ready-to-bake, baked",
  "Pulled pork in barbecue sauce",
  "Pummelo, raw",
  "Pumpkin flowers, cooked, boiled, drained, without salt",
  "Pumpkin flowers, raw",
  "Pumpkin leaves, cooked, boiled, drained, with salt",
  "Pumpkin leaves, cooked, boiled, drained, without salt",
  "Pumpkin leaves, raw",
  "Pumpkin pie mix, canned",
  "Pumpkin, canned, with salt",
  "Pumpkin, canned, without salt",
  "Pumpkin, cooked, boiled, drained, with salt",
  "Pumpkin, cooked, boiled, drained, without salt",
  "Pumpkin, flowers, cooked, boiled, drained, with salt",
  "Pumpkin, raw",
  "Purslane, cooked, boiled, drained, with salt",
  "Purslane, cooked, boiled, drained, without salt",
  "Purslane, raw",
  "Quail, breast, meat only, raw",
  "Quail, cooked, total edible",
  "Quail, meat and skin, raw",
  "Quail, meat only, raw",
  "Quinces, raw",
  "Quinoa, cooked",
  "Quinoa, uncooked",
  "Radicchio, raw",
  "Radish seeds, sprouted, raw",
  "Radishes, hawaiian style, pickled",
  "Radishes, oriental, cooked, boiled, drained, with salt",
  "Radishes, oriental, cooked, boiled, drained, without salt",
  "Radishes, oriental, dried",
  "Radishes, oriental, raw",
  "Radishes, raw",
  "Radishes, white icicle, raw",
  "Raisins, dark, seedless (Includes foods for USDA's Food Distribution Program)",
  "Raisins, golden, seedless",
  "Raisins, seeded",
  "Rambutan, canned, syrup pack",
  "Raspberries, canned, red, heavy syrup pack, solids and liquids",
  "Raspberries, frozen, red, sweetened",
  "Raspberries, frozen, red, unsweetened",
  "Raspberries, puree, seedless",
  "Raspberries, puree, with seeds",
  "Raspberries, raw",
  "Raspberries, wild (Northern Plains Indians)",
  "Raspberry juice concentrate",
  "Ravioli, cheese with tomato sauce, frozen, not prepared, includes regular and light entrees",

  "Ravioli, cheese-filled, canned",
  "Ravioli, meat-filled, with tomato sauce or meat sauce, canned",
  "Reddi Wip Fat Free Whipped Topping",
  "Refried beans, canned, fat-free",
  "Refried beans, canned, traditional style",
  "Refried beans, canned, traditional, reduced sodium",
  "Refried beans, canned, vegetarian",
  "Rennin, chocolate, dry mix, prepared with 2% milk",
  "Rennin, chocolate, dry mix, prepared with whole milk",
  "Rennin, vanilla, dry mix, prepared with 2% milk",
  "Rennin, vanilla, dry mix, prepared with whole milk",
  "Restaurant, Chinese, beef and vegetables",
  "Restaurant, Chinese, chicken and vegetables",
  "Restaurant, Chinese, chicken chow mein",
  "Restaurant, Chinese, egg rolls, assorted",
  "Restaurant, Chinese, fried rice, without meat",
  "Restaurant, Chinese, general tso's chicken",
  "Restaurant, Chinese, kung pao chicken",
  "Restaurant, Chinese, lemon chicken",
  "Restaurant, Chinese, orange chicken",
  "Restaurant, Chinese, sesame chicken",
  "Restaurant, Chinese, shrimp and vegetables",
  "Restaurant, Chinese, sweet and sour chicken",
  "Restaurant, Chinese, sweet and sour pork",
  "Restaurant, Chinese, vegetable chow mein, without meat or noodles",
  "Restaurant, Chinese, vegetable lo mein, without meat",
  "Restaurant, family style, chicken fingers, from kid's menu",
  "Restaurant, family style, chicken tenders",
  "Restaurant, family style, chili with meat and beans",
  "Restaurant, family style, coleslaw",
  "Restaurant, family style, fish fillet, battered or breaded, fried",
  "Restaurant, family style, french fries",
  "Restaurant, family style, fried mozzarella sticks",
  "Restaurant, family style, hash browns",
  "Restaurant, family style, macaroni & cheese, from kids' menu",
  "Restaurant, family style, onion rings",
  "Restaurant, family style, shrimp, breaded and fried",
  "Restaurant, family style, sirloin steak",
  "Restaurant, family style, spaghetti and meatballs",
  "Restaurant, Italian, cheese ravioli with marinara sauce",
  "Restaurant, Italian, chicken parmesan without pasta",
  "Restaurant, Italian, lasagna with meat",
  "Restaurant, Italian, spaghetti with meat sauce",
  "Restaurant, Italian, spaghetti with pomodoro sauce (no meat)",
  "Restaurant, Latino, arepa (unleavened cornmeal bread)",
  "Restaurant, Latino, Arroz con frijoles negros (rice and black beans)",
  "Restaurant, Latino, Arroz con grandules (rice and pigeonpeas)",
  "Restaurant, Latino, Arroz con habichuelas colorados (Rice And Red Beans)",
  "Restaurant, Latino, arroz con leche (rice pudding)",
  "Restaurant, Latino, black bean soup",

  "Restaurant, Latino, bunuelos (fried yeast bread)",
  "Restaurant, Latino, chicken and rice, entree, prepared",
  "Restaurant, Latino, empanadas, beef, prepared",
  "Restaurant, Latino, pupusas con frijoles (pupusas, bean)",
  "Restaurant, Latino, pupusas con queso (pupusas, cheese)",
  "Restaurant, Latino, pupusas del cerdo (pupusas, pork)",
  "Restaurant, Latino, tamale, corn",
  "Restaurant, Latino, tamale, pork",
  "Restaurant, Latino, tripe soup",
  "Restaurant, Mexican, cheese enchilada",
  "Restaurant, Mexican, cheese quesadilla",
  "Restaurant, Mexican, cheese tamales",
  "Restaurant, Mexican, refried beans",
  "Restaurant, Mexican, soft taco with ground beef, cheese and lettuce",
  "Restaurant, Mexican, spanish rice",
  "Rhubarb, frozen, cooked, with sugar",
  "Rhubarb, frozen, uncooked",
  "Rhubarb, raw",
  "Rhubarb, wild, leaves (Alaska Native)",
  "Rice and vermicelli mix, beef flavor, prepared with 80% margarine",
  "Rice and vermicelli mix, beef flavor, unprepared",
  "Rice and vermicelli mix, chicken flavor, prepared with 80% margarine",
  "Rice and vermicelli mix, rice pilaf flavor, prepared with 80% margarine",
  "Rice and vermicelli mix, rice pilaf flavor, unprepared",
  "Rice and Wheat cereal bar",
  "Rice bowl with chicken, frozen entree, prepared (includes fried, teriyaki, and sweet and sour varieties)",
  "Rice bran, crude",
  "Rice cake, cracker (include hain mini rice cakes)",
  "Rice crackers",
  "Rice flour, brown",
  "Rice flour, white, unenriched",
  "Rice mix, cheese flavor, dry mix, unprepared",
  "Rice mix, white and wild, flavored, unprepared",
  "Rice noodles, cooked",
  "Rice noodles, dry",
  "Rice, brown, long-grain, cooked (Includes foods for USDA's Food Distribution Program)",
  "Rice, brown, long-grain, raw (Includes foods for USDA's Food Distribution Program)",
  "Rice, brown, medium-grain, cooked (Includes foods for USDA's Food Distribution Program)",
  "Rice, brown, medium-grain, raw (Includes foods for USDA's Food Distribution Program)",
  "Rice, brown, parboiled, cooked, UNCLE BENS",
  "Rice, brown, parboiled, dry, UNCLE BEN'S",
  "Rice, white, glutinous, unenriched, cooked",
  "Rice, white, glutinous, unenriched, uncooked",
  "Rice, white, long-grain, parboiled, enriched, cooked",
  "Rice, white, long-grain, parboiled, enriched, dry",
  "Rice, white, long-grain, parboiled, unenriched, cooked",
  "Rice, white, long-grain, parboiled, unenriched, dry",
  "Rice, white, long-grain, precooked or instant, enriched, dry",
  "Rice, white, long-grain, precooked or instant, enriched, prepared",
  "Rice, white, long-grain, regular, cooked, enriched, with salt",

  "Rice, white, long-grain, regular, cooked, unenriched, with salt",
  "Rice, white, long-grain, regular, enriched, cooked",
  "Rice, white, long-grain, regular, raw, enriched",
  "Rice, white, long-grain, regular, raw, unenriched",
  "Rice, white, long-grain, regular, unenriched, cooked without salt",
  "Rice, white, medium-grain, cooked, unenriched",
  "Rice, white, medium-grain, enriched, cooked",
  "Rice, white, medium-grain, raw, enriched",
  "Rice, white, medium-grain, raw, unenriched",
  "Rice, white, short-grain, cooked, unenriched",
  "Rice, white, short-grain, enriched, cooked",
  "Rice, white, short-grain, enriched, uncooked",
  "Rice, white, short-grain, raw, unenriched",
  "Rice, white, steamed, Chinese restaurant",
  "RICE-A-RONI, chicken flavor, unprepared",
  "Roast beef spread",
  "Roast beef, deli style, prepackaged, sliced",
  "Rolls, dinner, egg",
  "Rolls, dinner, oat bran",
  "Rolls, dinner, plain, commercially prepared (includes brown-and-serve)",
  "Rolls, dinner, plain, prepared from recipe, made with low fat (2%) milk",
  "Rolls, dinner, rye",
  "Rolls, dinner, sweet",
  "Rolls, dinner, wheat",
  "Rolls, dinner, whole-wheat",
  "Rolls, french",
  "Rolls, gluten-free, white, made with brown rice flour, tapioca starch, and potato starch",
  "Rolls, gluten-free, white, made with brown rice flour, tapioca starch, and sorghum flour",
  "Rolls, gluten-free, white, made with rice flour, rice starch, and corn starch",
  "Rolls, gluten-free, whole grain, made with tapioca starch and brown rice flour",
  "Rolls, hamburger or hot dog, wheat/cracked wheat",
  "Rolls, hamburger or hot dog, whole wheat",
  "Rolls, hamburger or hotdog, mixed-grain",
  "Rolls, hamburger or hotdog, plain",
  "Rolls, hamburger, whole grain white, calcium-fortified",
  "Rolls, hard (includes kaiser)",
  "Rolls, pumpernickel",
  "Rose Hips, wild (Northern Plains Indians)",
  "Rose-apples, raw",
  "Roselle, raw",
  "Rosemary, fresh",
  "Rowal, raw",
  "Ruby Red grapefruit juice blend (grapefruit, grape, apple), OCEAN SPRAY, bottled, with added vitamin C",
  "Rudi's, Gluten-Free Bakery, Original Sandwich Bread",
  "Ruffed Grouse, breast meat, skinless, raw",
  "Rutabagas, cooked, boiled, drained, with salt",
  "Rutabagas, cooked, boiled, drained, without salt",
  "Rutabagas, raw",
  "Rye flour, dark",
  "Rye flour, light",

  "Rye flour, medium",
  "Rye grain",
  "Sage Valley, Gluten Free Vanilla Sandwich Cookies",
  "Salad dressing, bacon and tomato",
  "Salad dressing, blue or roquefort cheese dressing, commercial, regular",
  "Salad dressing, blue or roquefort cheese dressing, fat-free",
  "Salad dressing, blue or roquefort cheese dressing, light",
  "Salad dressing, blue or roquefort cheese, low calorie",
  "Salad dressing, buttermilk, lite",
  "Salad dressing, caesar dressing, regular",
  "Salad dressing, caesar, fat-free",
  "Salad dressing, caesar, low calorie",
  "Salad dressing, coleslaw",
  "Salad Dressing, coleslaw, reduced fat",
  "Salad dressing, french dressing, commercial, regular",
  "Salad dressing, french dressing, commercial, regular, without salt",
  "Salad dressing, french dressing, fat-free",
  "Salad dressing, french dressing, reduced calorie",
  "Salad dressing, french dressing, reduced fat",
  "Salad dressing, french dressing, reduced fat, without salt",
  "Salad dressing, french, cottonseed, oil, home recipe",
  "Salad dressing, french, home recipe",
  "Salad dressing, green goddess, regular",
  "Salad dressing, home recipe, vinegar and oil",
  "Salad dressing, honey mustard dressing, reduced calorie",
  "Salad dressing, honey mustard, regular",
  "Salad dressing, italian dressing, commercial, reduced fat",
  "Salad dressing, italian dressing, commercial, regular",
  "Salad dressing, italian dressing, commercial, regular, without salt",
  "Salad dressing, italian dressing, fat-free",
  "Salad dressing, italian dressing, reduced calorie",
  "Salad dressing, italian dressing, reduced fat, without salt",
  "Salad dressing, KRAFT Mayo Fat Free Mayonnaise Dressing",
  "Salad dressing, KRAFT Mayo Light Mayonnaise",
  "Salad dressing, KRAFT MIRACLE WHIP FREE Nonfat Dressing",
  "Salad dressing, mayonnaise and mayonnaise-type, low calorie",
  "Salad dressing, mayonnaise type, regular, with salt",
  "Salad dressing, mayonnaise, imitation, milk cream",
  "Salad dressing, mayonnaise, imitation, soybean",
  "Salad dressing, mayonnaise, imitation, soybean without cholesterol",
  "Salad dressing, mayonnaise, light",
  "Salad Dressing, mayonnaise, light, SMART BALANCE, Omega Plus light",
  "Salad dressing, mayonnaise, regular",
  "Salad dressing, mayonnaise, soybean and safflower oil, with salt",
  "Salad dressing, mayonnaise, soybean oil, without salt",
  "Salad dressing, mayonnaise-like, fat-free",
  "Salad dressing, mayonnaise-type, light",
  "Salad dressing, peppercorn dressing, commercial, regular",
  "Salad dressing, poppyseed, creamy",
  "Salad dressing, ranch dressing, fat-free",

  "Salad dressing, ranch dressing, reduced fat",
  "Salad dressing, ranch dressing, regular",
  "Salad dressing, russian dressing",
  "Salad dressing, russian dressing, low calorie",
  "Salad dressing, sesame seed dressing, regular",
  "Salad dressing, spray-style dressing, assorted flavors",
  "Salad dressing, sweet and sour",
  "Salad dressing, thousand island dressing, fat-free",
  "Salad dressing, thousand island dressing, reduced fat",
  "Salad dressing, thousand island, commercial, regular",
  "Salami, cooked, beef",
  "Salami, cooked, beef and pork",
  "Salami, cooked, turkey",
  "Salami, dry or hard, pork",
  "Salami, dry or hard, pork, beef",
  "Salami, Italian, pork",
  "Salami, Italian, pork and beef, dry, sliced, 50% less sodium",
  "Salami, pork, beef, less sodium",
  "Salisbury steak with gravy, frozen",
  "Salmon nuggets, breaded, frozen, heated",
  "Salmon nuggets, cooked as purchased, unheated",
  "Salmon, red (sockeye), filets with skin, smoked (Alaska Native)",
  "Salmon, sockeye, canned, drained solids, without skin and bones",
  "Salmon, sockeye, canned, total can contents",
  "Salmonberries, raw (Alaska Native)",
  "Salsify, (vegetable oyster), raw",
  "Salsify, cooked, boiled, drained, with salt",
  "Salsify, cooked, boiled, drained, without salt",
  "Salt, table",
  "Sandwich spread, meatless",
  "Sandwich spread, pork, beef",
  "Sandwich spread, with chopped pickle, regular, unspecified oils",
  "Sapodilla, raw",
  "Sapote, mamey, raw",
  "Sauce, alfredo mix, dry",
  "Sauce, barbecue",
  "Sauce, barbecue, BULL'S-EYE, original",
  "Sauce, barbecue, KC MASTERPIECE, original",
  "Sauce, barbecue, KRAFT, original",
  "Sauce, barbecue, OPEN PIT, original",
  "Sauce, barbecue, SWEET BABY RAY'S, original",
  "Sauce, cheese sauce mix, dry",
  "Sauce, cheese, ready-to-serve",
  "Sauce, chili, peppers, hot, immature green, canned",
  "Sauce, cocktail, ready-to-serve",
  "Sauce, duck, ready-to-serve",
  "Sauce, enchilada, red, mild, ready to serve",
  "Sauce, fish, ready-to-serve",
  "Sauce, hoisin, ready-to-serve",
  "Sauce, homemade, white, medium",

  "Sauce, homemade, white, thick",
  "Sauce, horseradish",
  "Sauce, hot chile, sriracha",
  "Sauce, hot chile, sriracha, CHA! BY TEXAS PETE",
  "Sauce, hot chile, sriracha, TUONG OT SRIRACHA",
  "Sauce, oyster, ready-to-serve",
  "Sauce, pasta, spaghetti/marinara, ready-to-serve",
  "Sauce, pasta, spaghetti/marinara, ready-to-serve, low sodium",
  "Sauce, peanut, made from coconut, water, sugar, peanuts",
  "Sauce, peanut, made from peanut butter, water, soy sauce",
  "Sauce, peppers, hot, chili, mature red, canned",
  "Sauce, pesto, BUITONI, pesto with basil, ready-to-serve, refrigerated",
  "Sauce, pesto, CLASSICO, basil pesto, ready-to-serve",
  "Sauce, pesto, MEZZETTA, NAPA VALLEY BISTRO, basil pesto, ready-to-serve",
  "Sauce, pesto, ready-to-serve, refrigerated",
  "Sauce, pesto, ready-to-serve, shelf stable",
  "Sauce, pizza, canned, ready-to-serve",
  "Sauce, plum, ready-to-serve",
  "Sauce, ready-to-serve, pepper or hot",
  "Sauce, ready-to-serve, pepper, TABASCO",
  "Sauce, salsa, ready-to-serve",
  "Sauce, salsa, verde, ready-to-serve",
  "Sauce, sofrito, prepared from recipe",
  "Sauce, steak, tomato based",
  "Sauce, sweet and sour, prepared-from-recipe",
  "Sauce, sweet and sour, ready-to-serve",
  "Sauce, tartar, ready-to-serve",
  "Sauce, teriyaki, ready-to-serve",
  "Sauce, teriyaki, ready-to-serve, reduced sodium",
  "Sauce, tomato chili sauce, bottled, with salt",
  "Sauce, white, thin, prepared-from-recipe, with butter",
  "Sauce, worcestershire",
  "Sauerkraut, canned, solids and liquids",
  "Sausage, beef, cured, cooked, smoked",
  "Sausage, beef, fresh, cooked",
  "Sausage, Berliner, pork, beef",
  "Sausage, breakfast sausage, beef, pre-cooked, unprepared",
  "Sausage, chicken or turkey, Italian style, lower sodium",
  "Sausage, chicken, beef, pork, skinless, smoked",
  "Sausage, egg and cheese breakfast biscuit",
  "Sausage, Italian, pork, mild, cooked, pan-fried",
  "Sausage, Italian, pork, mild, raw",
  "Sausage, Italian, sweet, links",
  "Sausage, Italian, turkey, smoked",
  "Sausage, meatless",
  "Sausage, New england brand, pork, beef",
  "Sausage, Polish, beef with chicken, hot",
  "Sausage, Polish, pork and beef, smoked",
  "Sausage, pork and beef, fresh, cooked",
  "Sausage, pork and beef, with cheddar cheese, smoked",

  "Sausage, pork and turkey, pre-cooked",
  "Sausage, pork, chorizo, link or ground, raw",
  "Sausage, pork, turkey, and beef, reduced sodium",
  "Sausage, smoked link sausage, pork",
  "Sausage, smoked link sausage, pork and beef",
  "Sausage, smoked link sausage, pork and beef (nonfat dry milk added)",
  "Sausage, summer, pork and beef, sticks, with cheddar cheese",
  "Sausage, turkey and pork, fresh, bulk, patty or link, cooked",
  "Sausage, turkey, breakfast links, mild, raw",
  "Sausage, turkey, fresh, cooked",
  "Sausage, turkey, fresh, raw",
  "Sausage, turkey, hot, smoked",
  "Sausage, turkey, pork, and beef, low fat, smoked",
  "Sausage, turkey, reduced fat, brown and serve, cooked",
  "Sausage, Vienna, canned, chicken, beef, pork",
  "Schar, Gluten-Free, Classic White Rolls",
  "Schar, Gluten-Free, Wheat-Free, Classic White Bread",
  "SCHIFF,TIGER'S MILK BAR",
  "School Lunch, chicken nuggets, whole grain breaded",
  "School Lunch, chicken patty, whole grain breaded",
  "School Lunch, pizza, BIG DADDY'S LS 16\" 51% Whole Grain Rolled Edge Cheese Pizza, frozen",
  "School Lunch, pizza, BIG DADDY'S LS 16\" 51% Whole Grain Rolled Edge Turkey Pepperoni Pizza, frozen",
  "School Lunch, pizza, cheese topping, thick crust, whole grain, frozen, cooked",
  "School Lunch, pizza, cheese topping, thin crust, whole grain, frozen, cooked",
  "School Lunch, pizza, pepperoni topping, thick crust, whole grain, frozen, cooked",
  "School Lunch, pizza, pepperoni topping, thin crust, whole grain, frozen, cooked",
  "School Lunch, pizza, sausage topping, thick crust, whole grain, frozen, cooked",
  "School Lunch, pizza, sausage topping, thin crust, whole grain, frozen, cooked",
  "School Lunch, pizza, TONY'S Breakfast Pizza Sausage, frozen",
  "School Lunch, pizza, TONY'S SMARTPIZZA Whole Grain 4x6 Cheese Pizza 50/50 Cheese, frozen",
  "School Lunch, pizza, TONY'S SMARTPIZZA Whole Grain 4x6 Pepperoni Pizza 50/50 Cheese, frozen",
  "Scrapple, pork",
  "Sea cucumber, yane (Alaska Native)",
  "Sea lion, Steller, fat (Alaska Native)",
  "Sea lion, Steller, heart (Alaska Native)",
  "Sea lion, Steller, kidney (Alaska Native)",
  "Sea lion, Steller, liver (Alaska Native)",
  "Sea lion, Steller, meat (Alaska Native)",
  "Sea lion, Steller, meat with fat (Alaska Native)",
  "Seal, bearded (Oogruk), meat, dried (Alaska Native)",
  "Seal, bearded (Oogruk), meat, dried, in oil (Alaska Native)",
  "Seal, bearded (Oogruk), meat, low quadrant, raw (Alaska Native)",
  "Seal, bearded (Oogruk), meat, partially dried (Alaska Native)",
  "Seal, bearded (Oogruk), meat, raw (Alaska Native)",
  "Seal, ringed, liver (Alaska Native)",
  "Seal, ringed, meat (Alaska Native)",
  "Seasoning mix, dry, chili, original",
  "Seasoning mix, dry, sazon, coriander & annatto",
  "Seasoning mix, dry, taco, original",
  "Seaweed, agar, dried",

  "Seaweed, agar, raw",
  "Seaweed, Canadian Cultivated EMI-TSUNOMATA, dry",
  "Seaweed, Canadian Cultivated EMI-TSUNOMATA, rehydrated",
  "Seaweed, irishmoss, raw",
  "Seaweed, kelp, raw",
  "Seaweed, laver, raw",
  "Seaweed, spirulina, dried",
  "Seaweed, spirulina, raw",
  "Seaweed, wakame, raw",
  "Seeds, breadfruit seeds, boiled",
  "Seeds, breadfruit seeds, raw",
  "Seeds, breadfruit seeds, roasted",
  "Seeds, breadnut tree seeds, dried",
  "Seeds, breadnut tree seeds, raw",
  "Seeds, chia seeds, dried",
  "Seeds, cottonseed flour, low fat (glandless)",
  "Seeds, cottonseed flour, partially defatted (glandless)",
  "Seeds, cottonseed kernels, roasted (glandless)",
  "Seeds, cottonseed meal, partially defatted (glandless)",
  "Seeds, flaxseed",
  "Seeds, hemp seed, hulled",
  "Seeds, lotus seeds, dried",
  "Seeds, lotus seeds, raw",
  "Seeds, pumpkin and squash seed kernels, dried",
  "Seeds, pumpkin and squash seed kernels, roasted, with salt added",
  "Seeds, pumpkin and squash seed kernels, roasted, without salt",
  "Seeds, pumpkin and squash seeds, whole, roasted, with salt added",
  "Seeds, pumpkin and squash seeds, whole, roasted, without salt",
  "Seeds, safflower seed kernels, dried",
  "Seeds, safflower seed meal, partially defatted",
  "Seeds, sesame butter, paste",
  "Seeds, sesame butter, tahini, from raw and stone ground kernels",
  "Seeds, sesame butter, tahini, from roasted and toasted kernels (most common type)",
  "Seeds, sesame butter, tahini, from unroasted kernels (non-chemically removed seed coat)",
  "Seeds, sesame butter, tahini, type of kernels unspecified",
  "Seeds, sesame flour, high-fat",
  "Seeds, sesame flour, low-fat",
  "Seeds, sesame flour, partially defatted",
  "Seeds, sesame meal, partially defatted",
  "Seeds, sesame seed kernels, dried (decorticated)",
  "Seeds, sesame seed kernels, toasted, with salt added (decorticated)",
  "Seeds, sesame seed kernels, toasted, without salt added (decorticated)",
  "Seeds, sesame seeds, whole, dried",
  "Seeds, sesame seeds, whole, roasted and toasted",
  "Seeds, sisymbrium sp. seeds, whole, dried",
  "Seeds, sunflower seed butter, with salt added (Includes foods for USDA's Food Distribution Program)",
  "Seeds, sunflower seed butter, without salt",
  "Seeds, sunflower seed flour, partially defatted",
  "Seeds, sunflower seed kernels from shell, dry roasted, with salt added",
  "Seeds, sunflower seed kernels, dried",

  "Seeds, sunflower seed kernels, dry roasted, with salt added",
  "Seeds, sunflower seed kernels, dry roasted, without salt",
  "Seeds, sunflower seed kernels, oil roasted, with salt added",
  "Seeds, sunflower seed kernels, oil roasted, without salt",
  "Seeds, sunflower seed kernels, toasted, with salt added",
  "Seeds, sunflower seed kernels, toasted, without salt",
  "Seeds, watermelon seed kernels, dried",
  "Semolina, enriched",
  "Semolina, unenriched",
  "Sesbania flower, cooked, steamed, with salt",
  "Sesbania flower, cooked, steamed, without salt",
  "Sesbania flower, raw",
  "Shake, fast food, vanilla",
  "Shallots, freeze-dried",
  "Shallots, raw",
  "Sherbet, orange",
  "Shortening bread, soybean (hydrogenated) and cottonseed",
  "Shortening cake mix, soybean (hydrogenated) and cottonseed (hydrogenated)",
  "Shortening confectionery, coconut (hydrogenated) and or palm kernel (hydrogenated)",
  "Shortening frying (heavy duty), beef tallow and cottonseed",
  "Shortening frying (heavy duty), palm (hydrogenated)",
  "Shortening frying (heavy duty), soybean (hydrogenated), linoleic (less than 1%)",
  "Shortening household soybean (hydrogenated) and palm",
  "Shortening industrial, lard and vegetable oil",
  "Shortening industrial, soybean (hydrogenated) and cottonseed",
  "Shortening, confectionery, fractionated palm",
  "Shortening, household, lard and vegetable oil",
  "Shortening, household, soybean (partially hydrogenated)-cottonseed (partially hydrogenated)",
  "Shortening, industrial, soy (partially hydrogenated ) and corn for frying",
  "Shortening, industrial, soy (partially hydrogenated ) for baking and confections",
  "Shortening, industrial, soy (partially hydrogenated), pourable liquid fry shortening",
  "Shortening, multipurpose, soybean (hydrogenated) and palm (hydrogenated)",
  "Shortening, special purpose for baking, soybean (hydrogenated) palm and cottonseed",
  "Shortening, special purpose for cakes and frostings, soybean (hydrogenated)",
  "Shortening, vegetable, household, composite",
  "SILK Banana-Strawberry soy yogurt",
  "SILK Black Cherry soy yogurt",
  "SILK Blueberry soy yogurt",
  "SILK Chai, soymilk",
  "SILK Chocolate, soymilk",
  "SILK Coffee, soymilk",
  "SILK French Vanilla Creamer",
  "SILK Hazelnut Creamer",
  "SILK Key Lime soy yogurt",
  "SILK Light Chocolate, soymilk",
  "SILK Light Plain, soymilk",
  "SILK Light Vanilla, soymilk",
  "SILK Mocha, soymilk",
  "SILK Nog, soymilk",
  "SILK Original Creamer",
  "SILK Peach soy yogurt",
  "SILK Plain soy yogurt",
  "SILK Plain, soymilk",
  "SILK Plus Fiber, soymilk",
  "SILK Plus for Bone Health, soymilk",
  "SILK Plus Omega-3 DHA, soymilk",
  "SILK Raspberry soy yogurt",
  "SILK Strawberry soy yogurt",
  "SILK Unsweetened, soymilk",
  "SILK Vanilla soy yogurt (family size)",
  "SILK Vanilla soy yogurt (single serving size)",
  "SILK Vanilla, soymilk",
  "SILK Very Vanilla, soymilk",
  "SMART SOUP, French Lentil",
  "SMART SOUP, Greek Minestrone",
  "SMART SOUP, Indian Bean Masala",
  "SMART SOUP, Moroccan Chick Pea",
  "SMART SOUP, Santa Fe Corn Chowder",
  "SMART SOUP, Thai Coconut Curry",
  "SMART SOUP, Vietnamese Carrot Lemongrass",
  "Smelt, dried (Alaska Native)",
  "Snack, BALANCE, original bar",
  "Snack, Mixed Berry Bar",
  "Snack, potato chips, made from dried potatoes, plain",
  "Snack, Pretzel, hard chocolate coated",
  "Snacks, bagel chips, plain",
  "Snacks, banana chips",
  "Snacks, beef jerky, chopped and formed",
  "Snacks, beef sticks, smoked",
  "Snacks, brown rice chips",
  "Snacks, candy bits, yogurt covered with vitamin C",
  "Snacks, candy rolls, yogurt-covered, fruit flavored with high vitamin C",
  "Snacks, CLIF BAR, mixed flavors",
  "Snacks, corn cakes",
  "Snacks, corn cakes, very low sodium",
  "Snacks, corn-based, extruded, chips, barbecue-flavor",
  "Snacks, corn-based, extruded, chips, barbecue-flavor, made with enriched masa flour",
  "Snacks, corn-based, extruded, chips, plain",
  "Snacks, corn-based, extruded, chips, unsalted",
  "Snacks, corn-based, extruded, cones, plain",
  "Snacks, corn-based, extruded, onion-flavor",
  "Snacks, corn-based, extruded, puffs or twists, cheese-flavor",
  "Snacks, corn-based, extruded, puffs or twists, cheese-flavor, unenriched",
  "Snacks, cornnuts, barbecue-flavor",
  "Snacks, crisped rice bar, almond",
  "Snacks, crisped rice bar, chocolate chip",
  "Snacks, FARLEY CANDY, FARLEY Fruit Snacks, with vitamins A, C, and E",
  "Snacks, FRITOLAY, SUNCHIPS, Multigrain Snack, Harvest Cheddar flavor",
  "Snacks, FRITOLAY, SUNCHIPS, Multigrain Snack, original flavor",
  "Snacks, FRITOLAY, SUNCHIPS, multigrain, French onion flavor",

  "Snacks, fruit leather, pieces",
  "Snacks, fruit leather, pieces, with vitamin C",
  "Snacks, fruit leather, rolls",
  "Snacks, GENERAL MILLS, BETTY CROCKER Fruit Roll Ups, berry flavored, with vitamin C",
  "Snacks, granola bar, chewy, reduced sugar, all flavors",
  "Snacks, granola bar, fruit-filled, nonfat",
  "Snacks, granola bar, GENERAL MILLS NATURE VALLEY, SWEET&SALTY NUT, peanut",
  "Snacks, granola bar, GENERAL MILLS, NATURE VALLEY, CHEWY TRAIL MIX",
  "Snacks, granola bar, GENERAL MILLS, NATURE VALLEY, with yogurt coating",
  "Snacks, granola bar, KASHI GOLEAN, chewy, mixed flavors",
  "Snacks, granola bar, KASHI GOLEAN, crunchy, mixed flavors",
  "Snacks, granola bar, KASHI TLC Bar, chewy, mixed flavors",
  "Snacks, granola bar, KASHI TLC Bar, crunchy, mixed flavors",
  "Snacks, granola bar, QUAKER, chewy, 90 Calorie Bar",
  "Snacks, granola bar, QUAKER, DIPPS, all flavors",
  "Snacks, granola bar, with coconut, chocolate coated",
  "Snacks, granola bars, hard, almond",
  "Snacks, granola bars, hard, chocolate chip",
  "Snacks, granola bars, hard, peanut butter",
  "Snacks, granola bars, hard, plain",
  "Snacks, granola bars, QUAKER OATMEAL TO GO, all flavors",
  "Snacks, granola bars, soft, almond, confectioners coating",
  "Snacks, granola bars, soft, coated, milk chocolate coating, chocolate chip",
  "Snacks, granola bars, soft, coated, milk chocolate coating, peanut butter",
  "Snacks, granola bars, soft, uncoated, chocolate chip",
  "Snacks, granola bars, soft, uncoated, chocolate chip, graham and marshmallow",
  "Snacks, granola bars, soft, uncoated, nut and raisin",
  "Snacks, granola bars, soft, uncoated, peanut butter",
  "Snacks, granola bars, soft, uncoated, peanut butter and chocolate chip",
  "Snacks, granola bars, soft, uncoated, plain",
  "Snacks, granola bars, soft, uncoated, raisin",
  "Snacks, granola bites, mixed flavors",
  "Snacks, KELLOGG, KELLOGG'S Low Fat Granola Bar, Crunchy Almond/Brown Sugar",
  "Snacks, KELLOGG, KELLOGG'S RICE KRISPIES TREATS Squares",
  "Snacks, KELLOGG, KELLOGG'S, NUTRI-GRAIN Cereal Bars, fruit",
  "Snacks, KRAFT, CORNNUTS, plain",
  "Snacks, M&M MARS, COMBOS Snacks Cheddar Cheese Pretzel",
  "Snacks, M&M MARS, KUDOS Whole Grain Bar, chocolate chip",
  "Snacks, M&M MARS, KUDOS Whole Grain Bar, M&M's milk chocolate",
  "Snacks, M&M MARS, KUDOS Whole Grain Bars, peanut butter",
  "Snacks, NUTRI-GRAIN FRUIT AND NUT BAR",
  "Snacks, oriental mix, rice-based",
  "Snacks, peas, roasted, wasabi-flavored",
  "Snacks, pita chips, salted",
  "Snacks, plantain chips, salted",
  "Snacks, popcorn, air-popped",
  "Snacks, popcorn, air-popped (Unsalted)",
  "Snacks, popcorn, cakes",
  "Snacks, popcorn, caramel-coated, with peanuts",
  "Snacks, popcorn, caramel-coated, without peanuts",

  "Snacks, popcorn, cheese-flavor",
  "Snacks, popcorn, home-prepared, oil-popped, unsalted",
  "Snacks, popcorn, microwave, 94% fat free",
  "Snacks, popcorn, microwave, low fat",
  "Snacks, popcorn, microwave, regular (butter) flavor, made with partially hydrogenated oil",
  "Snacks, popcorn, oil-popped, microwave, regular flavor, no trans fat",
  "Snacks, popcorn, oil-popped, white popcorn, salt added",
  "Snacks, pork skins, barbecue-flavor",
  "Snacks, pork skins, plain",
  "Snacks, potato chips, barbecue-flavor",
  "Snacks, potato chips, cheese-flavor",
  "Snacks, potato chips, fat free, salted",
  "Snacks, potato chips, fat-free, made with olestra",
  "Snacks, potato chips, lightly salted",
  "Snacks, potato chips, made from dried potatoes (preformed), multigrain",
  "Snacks, potato chips, made from dried potatoes, cheese-flavor",
  "Snacks, potato chips, made from dried potatoes, fat-free, made with olestra",
  "Snacks, potato chips, made from dried potatoes, reduced fat",
  "Snacks, potato chips, made from dried potatoes, sour-cream and onion-flavor",
  "Snacks, potato chips, plain, made with partially hydrogenated soybean oil, salted",
  "Snacks, potato chips, plain, made with partially hydrogenated soybean oil, unsalted",
  "Snacks, potato chips, plain, salted",
  "Snacks, potato chips, plain, unsalted",
  "Snacks, potato chips, reduced fat",
  "Snacks, potato chips, sour-cream-and-onion-flavor",
  "Snacks, potato chips, white, restructured, baked",
  "Snacks, potato sticks",
  "Snacks, Pretzels, gluten- free made with cornstarch and potato flour",
  "Snacks, pretzels, hard, confectioner's coating, chocolate-flavor",
  "Snacks, pretzels, hard, plain, made with enriched flour, unsalted",
  "Snacks, pretzels, hard, plain, made with unenriched flour, salted",
  "Snacks, pretzels, hard, plain, made with unenriched flour, unsalted",
  "Snacks, pretzels, hard, plain, salted",
  "Snacks, pretzels, hard, whole-wheat including both salted and unsalted",
  "Snacks, rice cakes, brown rice, buckwheat",
  "Snacks, rice cakes, brown rice, buckwheat, unsalted",
  "Snacks, rice cakes, brown rice, corn",
  "Snacks, rice cakes, brown rice, multigrain",
  "Snacks, rice cakes, brown rice, multigrain, unsalted",
  "Snacks, rice cakes, brown rice, plain, unsalted",
  "Snacks, rice cakes, brown rice, rye",
  "Snacks, rice cakes, brown rice, sesame seed",
  "Snacks, rice cakes, brown rice, sesame seed, unsalted",
  "Snacks, rice cracker brown rice, plain",
  "Snacks, sesame sticks, wheat-based, salted",
  "Snacks, sesame sticks, wheat-based, unsalted",
  "Snacks, shrimp cracker",
  "Snacks, soy chips or crisps, salted",
  "Snacks, SUNKIST, SUNKIST Fruit Roll, strawberry, with vitamins A, C, and E",
  "Snacks, sweet potato chips, unsalted",


  "Snacks, taro chips",
  "Snacks, tortilla chips, light (baked with less oil)",
  "Snacks, tortilla chips, low fat, made with olestra, nacho cheese",
  "Snacks, tortilla chips, low fat, unsalted",
  "Snacks, tortilla chips, nacho cheese",
  "Snacks, tortilla chips, nacho-flavor, made with enriched masa flour",
  "Snacks, tortilla chips, nacho-flavor, reduced fat",
  "Snacks, tortilla chips, plain, white corn, salted",
  "Snacks, tortilla chips, ranch-flavor",
  "Snacks, tortilla chips, taco-flavor",
  "Snacks, tortilla chips, unsalted, white corn",
  "Snacks, trail mix, regular",
  "Snacks, trail mix, regular, unsalted",
  "Snacks, trail mix, regular, with chocolate chips, salted nuts and seeds",
  "Snacks, trail mix, regular, with chocolate chips, unsalted nuts and seeds",
  "Snacks, trail mix, tropical",
  "Snacks, vegetable chips, HAIN CELESTIAL GROUP, TERRA CHIPS",
  "Snacks, vegetable chips, made from garden vegetables",
  "Snacks, yucca (cassava) chips, salted",
  "Sorghum flour, refined, unenriched",
  "Sorghum flour, whole-grain",
  "Sorghum grain",
  "Soup, bean & ham, canned, reduced sodium, prepared with water or ready-to-serve",
  "Soup, bean with bacon, condensed, single brand",
  "Soup, bean with frankfurters, canned, condensed",
  "Soup, bean with frankfurters, canned, prepared with equal volume water",
  "Soup, bean with ham, canned, chunky, ready-to-serve",
  "Soup, bean with pork, canned, condensed",
  "Soup, bean with pork, canned, prepared with equal volume water",
  "Soup, beef and mushroom, low sodium, chunk style",
  "Soup, beef and vegetables, canned, ready-to-serve",
  "Soup, beef and vegetables, reduced sodium, canned, ready-to-serve",
  "Soup, beef barley, ready to serve",
  "Soup, beef broth bouillon and consomme, canned, condensed",
  "Soup, beef broth or bouillon canned, ready-to-serve",
  "Soup, beef broth or bouillon, powder, dry",
  "Soup, beef broth or bouillon, powder, prepared with water",
  "Soup, beef broth, bouillon, consomme, prepared with equal volume water",
  "Soup, beef broth, cubed, dry",
  "Soup, beef broth, cubed, prepared with water",
  "Soup, beef broth, less/reduced sodium, ready to serve",
  "Soup, beef mushroom, canned, condensed",
  "Soup, beef mushroom, canned, prepared with equal volume water",
  "Soup, beef noodle, canned, condensed",
  "Soup, beef noodle, canned, prepared with equal volume water",
  "Soup, beef stroganoff, canned, chunky style, ready-to-serve",
  "Soup, black bean, canned, condensed",
  "Soup, black bean, canned, prepared with equal volume water",
  "Soup, bouillon cubes and granules, low sodium, dry",
  "Soup, broccoli cheese, canned, condensed, commercial",

  "Soup, cheese, canned, condensed",
  "Soup, cheese, canned, prepared with equal volume milk",
  "Soup, cheese, canned, prepared with equal volume water",
  "Soup, chicken and vegetable, canned, ready-to-serve",
  "Soup, chicken broth cubes, dry",
  "Soup, chicken broth cubes, dry, prepared with water",
  "Soup, chicken broth or bouillon, dry",
  "Soup, chicken broth or bouillon, dry, prepared with water",
  "Soup, chicken broth, canned, condensed",
  "Soup, chicken broth, canned, prepared with equal volume water",
  "Soup, chicken broth, less/reduced sodium, ready to serve",
  "Soup, chicken broth, low sodium, canned",
  "Soup, chicken broth, ready-to-serve",
  "Soup, chicken corn chowder, chunky, ready-to-serve, single brand",
  "Soup, chicken gumbo, canned, condensed",
  "Soup, chicken gumbo, canned, prepared with equal volume water",
  "Soup, chicken mushroom, canned, condensed",
  "Soup, chicken mushroom, canned, prepared with equal volume water",
  "Soup, chicken noodle, canned, condensed",
  "Soup, chicken noodle, canned, prepared with equal volume water",
  "Soup, chicken noodle, dry, mix",
  "Soup, chicken noodle, dry, mix, prepared with water",
  "Soup, chicken noodle, low sodium, canned, prepared with equal volume water",
  "Soup, chicken noodle, reduced sodium, canned, ready-to-serve",
  "Soup, chicken rice, canned, chunky, ready-to-serve",
  "Soup, chicken vegetable with potato and cheese, chunky, ready-to-serve",
  "Soup, chicken vegetable, canned, condensed",
  "Soup, chicken with rice, canned, condensed",
  "Soup, chicken with rice, canned, prepared with equal volume water",
  "Soup, chicken, canned, chunky, ready-to-serve",
  "Soup, chili beef, canned, condensed",
  "Soup, chili beef, canned, prepared with equal volume water",
  "Soup, chunky beef, canned, ready-to-serve",
  "Soup, chunky chicken noodle, canned, ready-to-serve",
  "Soup, chunky vegetable, canned, ready-to-serve",
  "Soup, chunky vegetable, reduced sodium, canned, ready-to-serve",
  "Soup, clam chowder, manhattan style, canned, chunky, ready-to-serve",
  "Soup, clam chowder, manhattan, canned, condensed",
  "Soup, clam chowder, manhattan, canned, prepared with equal volume water",
  "Soup, clam chowder, new england, canned, condensed",
  "Soup, clam chowder, new england, canned, prepared with equal volume low fat (2%) milk",
  "Soup, clam chowder, new england, canned, prepared with equal volume water",
  "Soup, clam chowder, new england, canned, ready-to-serve",
  "Soup, clam chowder, new england, reduced sodium, canned, ready-to-serve",
  "Soup, cream of asparagus, canned, condensed",
  "Soup, cream of asparagus, canned, prepared with equal volume milk",
  "Soup, cream of asparagus, canned, prepared with equal volume water",
  "Soup, cream of celery, canned, condensed",
  "Soup, cream of celery, canned, prepared with equal volume milk",
  "Soup, cream of celery, canned, prepared with equal volume water",
  
  "Soup, cream of chicken, canned, condensed",
  "Soup, cream of chicken, canned, condensed, reduced sodium",
  "Soup, cream of chicken, canned, prepared with equal volume milk",
  "Soup, cream of chicken, canned, prepared with equal volume water",
  "Soup, cream of chicken, dry, mix, prepared with water",
  "Soup, cream of mushroom, canned, condensed",
  "Soup, cream of mushroom, canned, condensed, reduced sodium",
  "Soup, cream of mushroom, canned, prepared with equal volume low fat (2%) milk",
  "Soup, cream of mushroom, canned, prepared with equal volume water",
  "Soup, cream of mushroom, low sodium, ready-to-serve, canned",
  "Soup, cream of onion, canned, condensed",
  "Soup, cream of onion, canned, prepared with equal volume milk",
  "Soup, cream of onion, canned, prepared with equal volume water",
  "Soup, cream of potato, canned, condensed",
  "Soup, cream of potato, canned, prepared with equal volume milk",
  "Soup, cream of potato, canned, prepared with equal volume water",
  "Soup, cream of shrimp, canned, condensed",
  "Soup, cream of shrimp, canned, prepared with equal volume low fat (2%) milk",
  "Soup, cream of shrimp, canned, prepared with equal volume water",
  "Soup, cream of vegetable, dry, powder",
  "Soup, egg drop, Chinese restaurant",
  "Soup, fish, homemade (Alaska Native)",
  "Soup, HEALTHY CHOICE Chicken and Rice Soup, canned",
  "Soup, HEALTHY CHOICE Chicken Noodle Soup, canned",
  "Soup, HEALTHY CHOICE Garden Vegetable Soup, canned",
  "Soup, hot and sour, Chinese restaurant",
  "Soup, lentil with ham, canned, ready-to-serve",
  "Soup, minestrone, canned, chunky, ready-to-serve",
  "Soup, minestrone, canned, condensed",
  "Soup, minestrone, canned, prepared with equal volume water",
  "Soup, minestrone, canned, reduced sodium, ready-to-serve",
  "Soup, mushroom barley, canned, condensed",
  "Soup, mushroom barley, canned, prepared with equal volume water",
  "Soup, mushroom with beef stock, canned, condensed",
  "Soup, mushroom with beef stock, canned, prepared with equal volume water",
  "Soup, onion, canned, condensed",
  "Soup, onion, dry, mix",
  "Soup, onion, dry, mix, prepared with water",
  "Soup, oyster stew, canned, condensed",
  "Soup, oyster stew, canned, prepared with equal volume milk",
  "Soup, oyster stew, canned, prepared with equal volume water",
  "Soup, pea, green, canned, condensed",
  "Soup, pea, green, canned, prepared with equal volume milk",
  "Soup, pea, green, canned, prepared with equal volume water",
  "Soup, pea, low sodium, prepared with equal volume water",
  "Soup, pea, split with ham, canned, chunky, ready-to-serve",
  "Soup, pea, split with ham, canned, condensed",
  "Soup, pea, split with ham, canned, prepared with equal volume water",
  "Soup, ramen noodle, any flavor, dry",
  "Soup, ramen noodle, beef flavor, dry",

  "Soup, ramen noodle, chicken flavor, dry",
  "Soup, ramen noodle, dry, any flavor, reduced fat, reduced sodium",
  "Soup, shark fin, restaurant-prepared",
  "Soup, stock, beef, home-prepared",
  "Soup, stock, chicken, home-prepared",
  "Soup, stock, fish, home-prepared",
  "Soup, SWANSON, beef broth, lower sodium",
  "Soup, SWANSON, vegetable broth",
  "Soup, tomato beef with noodle, canned, condensed",
  "Soup, tomato beef with noodle, canned, prepared with equal volume water",
  "Soup, tomato bisque, canned, condensed",
  "Soup, tomato bisque, canned, prepared with equal volume milk",
  "Soup, tomato bisque, canned, prepared with equal volume water",
  "Soup, tomato rice, canned, condensed",
  "Soup, tomato rice, canned, prepared with equal volume water",
  "Soup, tomato, canned, condensed",
  "Soup, tomato, canned, condensed, reduced sodium",
  "Soup, tomato, canned, prepared with equal volume low fat (2%) milk",
  "Soup, tomato, canned, prepared with equal volume water, commercial",
  "Soup, tomato, dry, mix, prepared with water",
  "Soup, tomato, low sodium, with water",
  "Soup, turkey noodle, canned, prepared with equal volume water",
  "Soup, turkey vegetable, canned, prepared with equal volume water",
  "Soup, turkey, chunky, canned, ready-to-serve",
  "Soup, vegetable beef, canned, condensed",
  "Soup, vegetable beef, canned, prepared with equal volume water",
  "Soup, vegetable beef, microwavable, ready-to-serve, single brand",
  "Soup, vegetable broth, ready to serve",
  "Soup, vegetable chicken, canned, prepared with water, low sodium",
  "Soup, vegetable soup, condensed, low sodium, prepared with equal volume water",
  "Soup, vegetable with beef broth, canned, condensed",
  "Soup, vegetable with beef broth, canned, prepared with equal volume water",
  "Soup, vegetable, canned, low sodium, condensed",
  "Soup, vegetarian vegetable, canned, condensed",
  "Soup, vegetarian vegetable, canned, prepared with equal volume water",
  "Soup, wonton, Chinese restaurant",
  "Sour cream, fat free",
  "Sour cream, imitation, cultured",
  "Sour cream, light",
  "Sour cream, reduced fat",
  "Sour dressing, non-butterfat, cultured, filled cream-type",
  "Sourdock, young leaves (Alaska Native)",
  "Soursop, raw",
  "Soy flour, defatted",
  "Soy flour, full-fat, raw",
  "Soy flour, full-fat, roasted",
  "Soy flour, low-fat",
  "Soy meal, defatted, raw",
  "Soy protein concentrate, produced by acid wash",
  "Soy protein concentrate, produced by alcohol extraction",

  "Soy protein isolate",
  "Soy protein isolate, potassium type",
  "Soy sauce made from hydrolyzed vegetable protein",
  "Soy sauce made from soy (tamari)",
  "Soy sauce made from soy and wheat (shoyu)",
  "Soy sauce made from soy and wheat (shoyu), low sodium",
  "Soy sauce made from soy and wheat (shoyu), reduced sodium",
  "Soybean, curd cheese",
  "Soybeans, green, cooked, boiled, drained, with salt",
  "Soybeans, green, cooked, boiled, drained, without salt",
  "Soybeans, green, raw",
  "Soybeans, mature cooked, boiled, without salt",
  "Soybeans, mature seeds, cooked, boiled, with salt",
  "Soybeans, mature seeds, dry roasted",
  "Soybeans, mature seeds, raw",
  "Soybeans, mature seeds, roasted, no salt added",
  "Soybeans, mature seeds, roasted, salted",
  "Soybeans, mature seeds, sprouted, cooked, steamed",
  "Soybeans, mature seeds, sprouted, cooked, steamed, with salt",
  "Soybeans, mature seeds, sprouted, cooked, stir-fried",
  "Soybeans, mature seeds, sprouted, cooked, stir-fried, with salt",
  "Soybeans, mature seeds, sprouted, raw",
  "Soymilk (All flavors), enhanced",
  "Soymilk (All flavors), lowfat, with added calcium, vitamins A and D",
  "Soymilk (all flavors), nonfat, with added calcium, vitamins A and D",
  "Soymilk (all flavors), unsweetened, with added calcium, vitamins A and D",
  "Soymilk, chocolate and other flavors, light, with added calcium, vitamins A and D",
  "Soymilk, chocolate, nonfat, with added calcium, vitamins A and D",
  "Soymilk, chocolate, unfortified",
  "Soymilk, chocolate, with added calcium, vitamins A and D",
  "Soymilk, original and vanilla, light, unsweetened, with added calcium, vitamins A and D",
  "Soymilk, original and vanilla, light, with added calcium, vitamins A and D",
  "Soymilk, original and vanilla, unfortified",
  "Soymilk, original and vanilla, with added calcium, vitamins A and D",
  "Spaghetti with meat sauce, frozen entree",
  "Spaghetti, protein-fortified, cooked, enriched (n x 6.25)",
  "Spaghetti, protein-fortified, dry, enriched (n x 6.25)",
  "Spaghetti, spinach, cooked",
  "Spaghetti, spinach, dry",
  "Spaghetti, with meatballs in tomato sauce, canned",
  "Spanish rice mix, dry mix, prepared (with canola/vegetable oil blend or diced tomatoes and margarine)",
  "Spanish rice mix, dry mix, unprepared",
  "Spearmint, dried",
  "Spearmint, fresh",
  "Spelt, cooked",
  "Spelt, uncooked",
  "Spices, allspice, ground",
  "Spices, anise seed",
  "Spices, basil, dried",
  "Spices, bay leaf",

  "Spices, caraway seed",
  "Spices, cardamom",
  "Spices, celery seed",
  "Spices, chervil, dried",
  "Spices, chili powder",
  "Spices, cinnamon, ground",
  "Spices, cloves, ground",
  "Spices, coriander leaf, dried",
  "Spices, coriander seed",
  "Spices, cumin seed",
  "Spices, curry powder",
  "Spices, dill seed",
  "Spices, dill weed, dried",
  "Spices, fennel seed",
  "Spices, fenugreek seed",
  "Spices, garlic powder",
  "Spices, ginger, ground",
  "Spices, mace, ground",
  "Spices, marjoram, dried",
  "Spices, mustard seed, ground",
  "Spices, nutmeg, ground",
  "Spices, onion powder",
  "Spices, oregano, dried",
  "Spices, paprika",
  "Spices, parsley, dried",
  "Spices, pepper, black",
  "Spices, pepper, red or cayenne",
  "Spices, pepper, white",
  "Spices, poppy seed",
  "Spices, poultry seasoning",
  "Spices, pumpkin pie spice",
  "Spices, rosemary, dried",
  "Spices, saffron",
  "Spices, sage, ground",
  "Spices, savory, ground",
  "Spices, tarragon, dried",
  "Spices, thyme, dried",
  "Spices, turmeric, ground",
  "Spinach souffle",
  "Spinach, canned, no salt added, solids and liquids",
  "Spinach, canned, regular pack, drained solids",
  "Spinach, canned, regular pack, solids and liquids",
  "Spinach, cooked, boiled, drained, with salt",
  "Spinach, cooked, boiled, drained, without salt",
  "Spinach, frozen, chopped or leaf, cooked, boiled, drained, with salt",
  "Spinach, frozen, chopped or leaf, cooked, boiled, drained, without salt",
  "Spinach, frozen, chopped or leaf, unprepared (Includes foods for USDA's Food Distribution Program)",
  "Spinach, raw",
  "Split pea soup, canned, reduced sodium, prepared with water or ready-to-serve",
  "Split pea with ham soup, canned, reduced sodium, prepared with water or ready-to-serve",

  "Squab, (pigeon), light meat without skin, raw",
  "Squab, (pigeon), meat and skin, raw",
  "Squab, (pigeon), meat only, raw",
  "Squash, Indian, cooked, boiled (Navajo)",
  "Squash, Indian, raw (Navajo)",
  "Squash, summer, all varieties, cooked, boiled, drained, with salt",
  "Squash, summer, all varieties, cooked, boiled, drained, without salt",
  "Squash, summer, all varieties, raw",
  "Squash, summer, crookneck and straightneck, canned, drained, solid, without salt",
  "Squash, summer, crookneck and straightneck, cooked, boiled, drained, with salt",
  "Squash, summer, crookneck and straightneck, cooked, boiled, drained, without salt",
  "Squash, summer, crookneck and straightneck, frozen, cooked, boiled, drained, with salt",
  "Squash, summer, crookneck and straightneck, frozen, cooked, boiled, drained, without salt",
  "Squash, summer, crookneck and straightneck, frozen, unprepared",
  "Squash, summer, crookneck and straightneck, raw",
  "Squash, summer, scallop, cooked, boiled, drained, with salt",
  "Squash, summer, scallop, cooked, boiled, drained, without salt",
  "Squash, summer, scallop, raw",
  "Squash, summer, zucchini, includes skin, cooked, boiled, drained, with salt",
  "Squash, summer, zucchini, includes skin, cooked, boiled, drained, without salt",
  "Squash, summer, zucchini, includes skin, frozen, cooked, boiled, drained, with salt",
  "Squash, summer, zucchini, includes skin, frozen, cooked, boiled, drained, without salt",
  "Squash, summer, zucchini, includes skin, frozen, unprepared",
  "Squash, summer, zucchini, includes skin, raw",
  "Squash, summer, zucchini, italian style, canned",
  "Squash, winter, acorn, cooked, baked, with salt",
  "Squash, winter, acorn, cooked, baked, without salt",
  "Squash, winter, acorn, cooked, boiled, mashed, with salt",
  "Squash, winter, acorn, cooked, boiled, mashed, without salt",
  "Squash, winter, acorn, raw",
  "Squash, winter, all varieties, cooked, baked, with salt",
  "Squash, winter, all varieties, cooked, baked, without salt",
  "Squash, winter, all varieties, raw",
  "Squash, winter, butternut, cooked, baked, with salt",
  "Squash, winter, butternut, cooked, baked, without salt",
  "Squash, winter, butternut, frozen, cooked, boiled, with salt",
  "Squash, winter, butternut, frozen, cooked, boiled, without salt",
  "Squash, winter, butternut, frozen, unprepared",
  "Squash, winter, butternut, raw",
  "Squash, winter, hubbard, baked, with salt",
  "Squash, winter, hubbard, baked, without salt",
  "Squash, winter, hubbard, cooked, boiled, mashed, with salt",
  "Squash, winter, hubbard, cooked, boiled, mashed, without salt",
  "Squash, winter, hubbard, raw",
  "Squash, winter, spaghetti, cooked, boiled, drained, or baked, with salt",
  "Squash, winter, spaghetti, cooked, boiled, drained, or baked, without salt",
  "Squash, winter, spaghetti, raw",
  "Squash, zucchini, baby, raw",
  "Squirrel, ground, meat (Alaska Native)",
  "Steelhead trout, boiled, canned (Alaska Native)",

  "Steelhead trout, dried, flesh (Shoshone Bannock)",
  "Stew, dumpling with mutton (Navajo)",
  "Stew, hominy with mutton (Navajo)",
  "Stew, moose (Alaska Native)",
  "Stew, mutton, corn, squash (Navajo)",
  "Stew, pinto bean and hominy, badufsuki (Hopi)",
  "Stew, steamed corn (Navajo)",
  "Stew/soup, caribou (Alaska Native)",
  "Stinging Nettles, blanched (Northern Plains Indians)",
  "Strawberries, canned, heavy syrup pack, solids and liquids",
  "Strawberries, frozen, sweetened, sliced",
  "Strawberries, frozen, unsweetened (Includes foods for USDA's Food Distribution Program)",
  "Strawberries, raw",
  "Strawberry-flavor beverage mix, powder",
  "Strudel, apple",
  "SUBWAY, B.L.T. sub on white bread with bacon, lettuce and tomato",
  "SUBWAY, black forest ham sub on white bread with lettuce and tomato",
  "SUBWAY, cold cut sub on white bread with lettuce and tomato",
  "SUBWAY, meatball marinara sub on white bread (no toppings)",
  "SUBWAY, oven roasted chicken sub on white bread with lettuce and tomato",
  "SUBWAY, roast beef sub on white bread with lettuce and tomato",
  "SUBWAY, steak & cheese sub on white bread with American cheese, lettuce and tomato",
  "SUBWAY, SUBWAY CLUB sub on white bread with lettuce and tomato",
  "SUBWAY, sweet onion chicken teriyaki sub on white bread with lettuce, tomato and sweet onion sauce",
  "SUBWAY, tuna sub on white bread with lettuce and tomato",
  "SUBWAY, turkey breast sub on white bread with lettuce and tomato",
  "Succotash, (corn and limas), canned, with cream style corn",
  "Succotash, (corn and limas), canned, with whole kernel corn, solids and liquids",
  "Succotash, (corn and limas), cooked, boiled, drained, with salt",
  "Succotash, (corn and limas), cooked, boiled, drained, without salt",
  "Succotash, (corn and limas), frozen, cooked, boiled, drained, with salt",
  "Succotash, (corn and limas), frozen, cooked, boiled, drained, without salt",
  "Succotash, (corn and limas), frozen, unprepared",
  "Succotash, (corn and limas), raw",
  "Sugar, turbinado",
  "Sugar-apples, (sweetsop), raw",
  "Sugars, brown",
  "Sugars, granulated",
  "Sugars, maple",
  "Sugars, powdered",
  "Sweet potato leaves, cooked, steamed, with salt",
  "Sweet potato leaves, cooked, steamed, without salt",
  "Sweet potato leaves, raw",
  "Sweet Potato puffs, frozen, unprepared",
  "Sweet potato, canned, mashed",
  "Sweet potato, canned, syrup pack, drained solids",
  "Sweet potato, canned, syrup pack, solids and liquids",
  "Sweet potato, canned, vacuum pack",
  "Sweet potato, cooked, baked in skin, flesh, with salt",
  "Sweet potato, cooked, baked in skin, flesh, without salt",

  "Sweet potato, cooked, boiled, without skin",
  "Sweet potato, cooked, boiled, without skin, with salt",
  "Sweet potato, cooked, candied, home-prepared",
  "Sweet potato, frozen, cooked, baked, with salt",
  "Sweet potato, frozen, cooked, baked, without salt",
  "Sweet potato, frozen, unprepared (Includes foods for USDA's Food Distribution Program)",
  "Sweet potato, raw, unprepared (Includes foods for USDA's Food Distribution Program)",
  "Sweet Potatoes, french fried, crosscut, frozen, unprepared",
  "Sweet Potatoes, french fried, frozen as packaged, salt added in processing",
  "Sweet rolls, cheese",
  "Sweet rolls, cinnamon, commercially prepared with raisins",
  "Sweet rolls, cinnamon, refrigerated dough with frosting",
  "Sweet rolls, cinnamon, refrigerated dough with frosting, baked",
  "Sweetener, herbal extract powder from Stevia leaf",
  "Sweetener, syrup, agave",
  "Sweeteners, for baking, brown, contains sugar and sucralose",
  "Sweeteners, for baking, contains sugar and sucralose",
  "Sweeteners, sugar substitute, granulated, brown",
  "Sweeteners, tabletop, aspartame, EQUAL, packets",
  "Sweeteners, tabletop, fructose, dry, powder",
  "Sweeteners, tabletop, fructose, liquid",
  "Sweeteners, tabletop, saccharin (sodium saccharin)",
  "Sweeteners, tabletop, sucralose, SPLENDA packets",
  "Swisswurst, pork and beef, with swiss cheese, smoked",
  "Syrup, Cane",
  "Syrup, fruit flavored",
  "Syrup, maple, Canadian",
  "Syrup, NESTLE, chocolate",
  "Syrups, chocolate, fudge-type",
  "Syrups, chocolate, HERSHEY'S Genuine Chocolate Flavored Lite Syrup",
  "Syrups, chocolate, HERSHEY'S Sugar free, Genuine Chocolate Flavored, Lite Syrup",
  "Syrups, corn, dark",
  "Syrups, corn, high-fructose",
  "Syrups, corn, light",
  "Syrups, grenadine",
  "Syrups, malt",
  "Syrups, maple",
  "Syrups, sorghum",
  "Syrups, sugar free",
  "Syrups, table blends, corn, refiner, and sugar",
  "Syrups, table blends, pancake",
  "Syrups, table blends, pancake, reduced-calorie",
  "Syrups, table blends, pancake, with 2% maple",
  "Syrups, table blends, pancake, with 2% maple, with added potassium",
  "Syrups, table blends, pancake, with butter",
  "T.G.I. FRIDAY'S, chicken fingers",
  "T.G.I. FRIDAY'S, chicken fingers, from kids' menu",
  "T.G.I. FRIDAY'S, classic sirloin steak (10 oz)",
  "T.G.I. FRIDAY'S, french fries",
  "T.G.I. FRIDAY'S, FRIDAY'S Shrimp, breaded",

  "T.G.I. FRIDAY'S, fried mozzarella",
  "T.G.I. FRIDAY'S, macaroni & cheese, from kid's menu",
  "TACO BELL, Bean Burrito",
  "TACO BELL, BURRITO SUPREME with beef",
  "TACO BELL, BURRITO SUPREME with chicken",
  "TACO BELL, BURRITO SUPREME with steak",
  "TACO BELL, Nachos",
  "TACO BELL, Nachos Supreme",
  "TACO BELL, Original Taco with beef, cheese and lettuce",
  "TACO BELL, Soft Taco with beef, cheese and lettuce",
  "TACO BELL, Soft Taco with chicken, cheese and lettuce",
  "TACO BELL, Soft Taco with steak",
  "TACO BELL, Taco Salad",
  "Taco shells, baked",
  "Taco shells, baked, without added salt",
  "Tamales (Navajo)",
  "Tamales, masa and pork filling (Hopi)",
  "Tamarind nectar, canned",
  "Tamarinds, raw",
  "Tangerine juice, raw",
  "Tangerines, (mandarin oranges), canned, juice pack",
  "Tangerines, (mandarin oranges), canned, juice pack, drained",
  "Tangerines, (mandarin oranges), canned, light syrup pack",
  "Tangerines, (mandarin oranges), raw",
  "Tapioca, pearl, dry",
  "Taquitos, frozen, beef and cheese, oven-heated",
  "Taquitos, frozen, chicken and cheese, oven-heated",
  "Taro leaves, cooked, steamed, without salt",
  "Taro leaves, raw",
  "Taro shoots, cooked, without salt",
  "Taro shoots, raw",
  "Taro, cooked, with salt",
  "Taro, cooked, without salt",
  "Taro, leaves, cooked, steamed, with salt",
  "Taro, raw",
  "Taro, shoots, cooked, with salt",
  "Taro, tahitian, cooked, with salt",
  "Taro, tahitian, cooked, without salt",
  "Taro, tahitian, raw",
  "Tart, breakfast, low fat",
  "Tea, herbal, brewed, Hohoysi (Hopi)",
  "Tea, tundra, herb and laborador combination (Alaska Native)",
  "Teff, cooked",
  "Teff, uncooked",
  "Tempeh",
  "Tempeh, cooked",
  "Tennis Bread, plain (Apache)",
  "Thuringer, cervelat, summer sausage, beef, pork",
  "Thyme, fresh",
  "Toaster pastries, brown-sugar-cinnamon",

  "Toaster pastries, fruit (includes apple, blueberry, cherry, strawberry)",
  "Toaster Pastries, fruit, frosted (include apples, blueberry, cherry, strawberry)",
  "Toaster pastries, fruit, toasted (include apple, blueberry, cherry, strawberry)",
  "Toddler drink, MEAD JOHNSON, PurAmino Toddler Powder, with ARA and DHA, not reconstituted",
  "Toddler formula, MEAD JOHNSON, ENFAGROW PREMIUM (formerly ENFAMIL, LIPIL, NEXT STEP), ready-to-feed",
  "Toddler formula, MEAD JOHNSON, ENFAGROW, Toddler Transitions, with ARA and DHA, powder",
  "Toddler formula, MEAD JOHNSON, Nutramigen Toddler with LGG Powder, with ARA and DHA, not reconstituted",
  "Tofu yogurt",
  "Tofu, dried-frozen (koyadofu)",
  "Tofu, dried-frozen (koyadofu), prepared with calcium sulfate",
  "Tofu, extra firm, prepared with nigari",
  "Tofu, firm, prepared with calcium sulfate and magnesium chloride (nigari)",
  "Tofu, fried",
  "Tofu, fried, prepared with calcium sulfate",
  "Tofu, hard, prepared with nigari",
  "Tofu, raw, firm, prepared with calcium sulfate",
  "Tofu, raw, regular, prepared with calcium sulfate",
  "Tofu, salted and fermented (fuyu)",
  "Tofu, salted and fermented (fuyu), prepared with calcium sulfate",
  "Tofu, soft, prepared with calcium sulfate and magnesium chloride (nigari)",
  "Tomatillos, raw",
  "Tomato and vegetable juice, low sodium",
  "Tomato juice, canned, with salt added",
  "Tomato juice, canned, without salt added",
  "Tomato powder",
  "Tomato products, canned, paste, without salt added (Includes foods for USDA's Food Distribution Program)",
  "Tomato products, canned, puree, with salt added",
  "Tomato products, canned, puree, without salt added",
  "Tomato products, canned, sauce",
  "Tomato products, canned, sauce, spanish style",
  "Tomato products, canned, sauce, with herbs and cheese",
  "Tomato products, canned, sauce, with mushrooms",
  "Tomato products, canned, sauce, with onions",
  "Tomato products, canned, sauce, with onions, green peppers, and celery",
  "Tomato products, canned, sauce, with tomato tidbits",
  "Tomato sauce, canned, no salt added",
  "Tomatoes, crushed, canned",
  "Tomatoes, green, raw",
  "Tomatoes, orange, raw",
  "Tomatoes, red, ripe, canned, packed in tomato juice",
  "Tomatoes, red, ripe, canned, packed in tomato juice, no salt added",
  "Tomatoes, red, ripe, canned, stewed",
  "Tomatoes, red, ripe, canned, with green chilies",
  "Tomatoes, red, ripe, cooked",
  "Tomatoes, red, ripe, cooked, stewed",
  "Tomatoes, red, ripe, cooked, with salt",
  "Tomatoes, red, ripe, raw, year round average",
  "Tomatoes, sun-dried",
  "Tomatoes, sun-dried, packed in oil, drained",
  "Tomatoes, yellow, raw",


  "Topping, SMUCKER'S MAGIC SHELL",
  "Toppings, butterscotch or caramel",
  "Toppings, marshmallow cream",
  "Toppings, nuts in syrup",
  "Toppings, pineapple",
  "Toppings, strawberry",
  "Tortellini, pasta with cheese filling, fresh-refrigerated, as purchased",
  "Tortilla chips, low fat, baked without fat",
  "Tortilla chips, yellow, plain, salted",
  "Tortilla, blue corn, Sakwavikaviki (Hopi)",
  "Tortilla, includes plain and from mutton sandwich (Navajo)",
  "Tortillas, ready-to-bake or -fry, corn",
  "Tortillas, ready-to-bake or -fry, corn, without added salt",
  "Tortillas, ready-to-bake or -fry, flour, refrigerated",
  "Tortillas, ready-to-bake or -fry, flour, shelf stable",
  "Tortillas, ready-to-bake or -fry, flour, without added calcium",
  "Tortillas, ready-to-bake or -fry, whole wheat",
  "Tostada shells, corn",
  "Tree fern, cooked, with salt",
  "Tree fern, cooked, without salt",
  "Triticale",
  "Triticale flour, whole-grain",
  "Turkey and gravy, frozen",
  "Turkey breast, low salt, prepackaged or deli, luncheon meat",
  "Turkey breast, pre-basted, meat and skin, cooked, roasted",
  "Turkey breast, sliced, prepackaged",
  "Turkey from whole, light meat, meat and skin, with added solution, cooked, roasted",
  "Turkey from whole, light meat, meat and skin, with added solution, raw",
  "Turkey from whole, light meat, meat only, with added solution, cooked, roasted",
  "Turkey from whole, light meat, meat only, with added solution, raw",
  "Turkey Pot Pie, frozen entree",
  "Turkey roast, boneless, frozen, seasoned, light and dark meat, raw",
  "Turkey sticks, breaded, battered, fried",
  "Turkey thigh, pre-basted, meat and skin, cooked, roasted",
  "Turkey, all classes, back, meat and skin, cooked, roasted",
  "Turkey, all classes, breast, meat and skin, cooked, roasted",
  "Turkey, all classes, breast, meat and skin, raw",
  "Turkey, all classes, gizzard, cooked, simmered",
  "Turkey, all classes, heart, cooked, simmered",
  "Turkey, all classes, heart, raw",
  "Turkey, all classes, leg, meat and skin, cooked, roasted",
  "Turkey, all classes, leg, meat and skin, raw",
  "Turkey, all classes, light meat, cooked, roasted",
  "Turkey, all classes, liver, cooked, simmered",
  "Turkey, all classes, liver, raw",
  "Turkey, all classes, wing, meat and skin, cooked, roasted",
  "Turkey, all classes, wing, meat and skin, raw",
  "Turkey, back, from whole bird, meat and skin, with added solution, raw",
  "Turkey, back, from whole bird, meat and skin, with added solution, roasted",
  "Turkey, back, from whole bird, meat only, with added solution, raw",

  "Turkey, back, from whole bird, meat only, with added solution, roasted",
  "Turkey, breast, from whole bird, meat only, with added solution, raw",
  "Turkey, breast, from whole bird, meat only, with added solution, roasted",
  "Turkey, breast, smoked, lemon pepper flavor, 97% fat-free",
  "Turkey, canned, meat only, with broth",
  "Turkey, dark meat from whole, meat and skin, with added solution, cooked, roasted",
  "Turkey, dark meat from whole, meat and skin, with added solution, raw",
  "Turkey, dark meat from whole, meat only, with added solution, raw",
  "Turkey, dark meat, meat and skin, raw",
  "Turkey, dark meat, meat only, with added solution, cooked, roasted",
  "Turkey, diced, light and dark meat, seasoned",
  "Turkey, drumstick, from whole bird, meat only, raw",
  "Turkey, drumstick, from whole bird, meat only, roasted",
  "Turkey, drumstick, from whole bird, meat only, with added solution, raw",
  "Turkey, drumstick, from whole bird, meat only, with added solution, roasted",
  "Turkey, drumstick, smoked, cooked, with skin, bone removed",
  "Turkey, fryer-roasters, meat and skin, cooked, roasted",
  "Turkey, gizzard, all classes, raw",
  "Turkey, ground, 85% lean, 15% fat, pan-broiled crumbles",
  "Turkey, ground, 85% lean, 15% fat, patties, broiled",
  "Turkey, ground, 85% lean, 15% fat, raw",
  "Turkey, ground, 93% lean, 7% fat, pan-broiled crumbles",
  "Turkey, ground, 93% lean, 7% fat, patties, broiled",
  "Turkey, ground, 93% lean, 7% fat, raw",
  "Turkey, Ground, cooked",
  "Turkey, ground, fat free, pan-broiled crumbles",
  "Turkey, ground, fat free, patties, broiled",
  "Turkey, ground, fat free, raw",
  "Turkey, Ground, raw",
  "Turkey, light or dark meat, smoked, cooked, skin and bone removed",
  "Turkey, light or dark meat, smoked, cooked, with skin, bone removed",
  "Turkey, mechanically deboned, from turkey frames, raw",
  "Turkey, retail parts, breast, meat and skin, cooked, roasted",
  "Turkey, retail parts, breast, meat and skin, raw",
  "Turkey, retail parts, breast, meat and skin, with added solution, raw",
  "Turkey, retail parts, breast, meat only, cooked, roasted",
  "Turkey, retail parts, breast, meat only, raw",
  "Turkey, retail parts, breast, meat only, with added solution, cooked, roasted",
  "Turkey, retail parts, breast, meat only, with added solution, raw",
  "Turkey, retail parts, drumstick, meat and skin, cooked, roasted",
  "Turkey, retail parts, drumstick, meat and skin, raw",
  "Turkey, retail parts, drumstick, meat only, cooked, roasted",
  "Turkey, retail parts, drumstick, meat only, raw",
  "Turkey, retail parts, thigh, meat and skin, cooked, roasted",
  "Turkey, retail parts, thigh, meat and skin, raw",
  "Turkey, retail parts, thigh, meat only, cooked, roasted",
  "Turkey, retail parts, thigh, meat only, raw",
  "Turkey, retail parts, wing, meat and skin, cooked, roasted",
  "Turkey, retail parts, wing, meat and skin, raw",
  "Turkey, retail parts, wing, meat only, cooked, roasted",

  "Turkey, retail parts, wing, meat only, raw",
  "Turkey, skin from whole (light and dark), with added solution, raw",
  "Turkey, skin from whole, (light and dark), with added solution, roasted",
  "Turkey, skin, from retail parts, from dark meat, cooked, roasted",
  "Turkey, skin, from retail parts, from dark meat, raw",
  "Turkey, stuffing, mashed potatoes w/gravy, assorted vegetables, frozen, microwaved",
  "Turkey, thigh, from whole bird, meat only, raw",
  "Turkey, thigh, from whole bird, meat only, roasted",
  "Turkey, thigh, from whole bird, meat only, with added solution, raw",
  "Turkey, thigh, from whole bird, meat only, with added solution, roasted",
  "Turkey, white, rotisserie, deli cut",
  "Turkey, whole, back, meat only, cooked, roasted",
  "Turkey, whole, back, meat only, raw",
  "Turkey, whole, breast, meat only, cooked, roasted",
  "Turkey, whole, breast, meat only, raw",
  "Turkey, whole, dark meat, cooked, roasted",
  "Turkey, whole, dark meat, meat and skin, cooked, roasted",
  "Turkey, whole, dark meat, meat only, raw",
  "Turkey, whole, giblets, cooked, simmered",
  "Turkey, whole, giblets, raw",
  "Turkey, whole, light meat, meat and skin, cooked, roasted",
  "Turkey, whole, light meat, meat and skin, raw",
  "Turkey, whole, light meat, raw",
  "Turkey, whole, meat and skin, cooked, roasted",
  "Turkey, whole, meat and skin, raw",
  "Turkey, whole, meat and skin, with added solution, raw",
  "Turkey, whole, meat and skin, with added solution, roasted",
  "Turkey, whole, meat only, cooked, roasted",
  "Turkey, whole, meat only, raw",
  "Turkey, whole, meat only, with added solution, raw",
  "Turkey, whole, meat only, with added solution, roasted",
  "Turkey, whole, neck, meat only, cooked, simmered",
  "Turkey, whole, neck, meat only, raw",
  "Turkey, whole, skin (light and dark), raw",
  "Turkey, whole, skin (light and dark), roasted",
  "Turkey, whole, wing, meat only, cooked, roasted",
  "Turkey, whole, wing, meat only, raw",
  "Turkey, wing, from whole bird, meat only, with added solution, raw",
  "Turkey, wing, from whole bird, meat only, with added solution, roasted",
  "Turkey, wing, smoked, cooked, with skin, bone removed",
  "Turkey, young hen, skin only, cooked, roasted",
  "Turnip greens and turnips, frozen, cooked, boiled, drained, with salt",
  "Turnip greens and turnips, frozen, cooked, boiled, drained, without salt",
  "Turnip greens and turnips, frozen, unprepared",
  "Turnip greens, canned, no salt added",
  "Turnip greens, canned, solids and liquids",
  "Turnip greens, cooked, boiled, drained, with salt",
  "Turnip greens, cooked, boiled, drained, without salt",
  "Turnip greens, frozen, cooked, boiled, drained, with salt",
  "Turnip greens, frozen, cooked, boiled, drained, without salt",

  "Turnip greens, frozen, unprepared",
  "Turnip greens, raw",
  "Turnips, cooked, boiled, drained, with salt",
  "Turnips, cooked, boiled, drained, without salt",
  "Turnips, frozen, cooked, boiled, drained, with salt",
  "Turnips, frozen, cooked, boiled, drained, without salt",
  "Turnips, frozen, unprepared",
  "Turnips, raw",
  "Turnover, cheese-filled, tomato-based sauce, frozen, unprepared",
  "Turnover, chicken- or turkey-, and vegetable-filled, reduced fat, frozen",
  "Turnover, filled with egg, meat and cheese, frozen",
  "Turnover, meat- and cheese-filled, tomato-based sauce, reduced fat, frozen",
  "Turtle, green, raw",
  "Udi's, Gluten Free, Classic French Dinner Rolls",
  "Udi's, Gluten Free, Soft & Delicious White Sandwich Bread",
  "Udi's, Gluten Free, Soft & Hearty Whole Grain Bread",
  "Udi's, Gluten Free, Whole Grain Dinner Rolls",
  "Van's, Gluten Free, Totally Original Pancakes",
  "Van's, Gluten Free, Totally Original Waffles",
  "Van's, The Perfect 10, Crispy Six Whole Grain + Four Seed Baked Crackers, Gluten Free",
  "Vanilla extract",
  "Vanilla extract, imitation, alcohol",
  "Vanilla extract, imitation, no alcohol",
  "Veal, Australian, rib, rib roast, separable lean and fat, raw",
  "Veal, Australian, rib, rib roast, separable lean only, raw",
  "Veal, Australian, separable fat, raw",
  "Veal, Australian, shank, fore, bone-in, separable lean and fat, raw",
  "Veal, Australian, shank, fore, bone-in, separable lean only, raw",
  "Veal, Australian, shank, hind, bone-in, separable lean and fat, raw",
  "Veal, Australian, shank, hind, bone-in, separable lean only, raw",
  "Veal, breast, plate half, boneless, separable lean and fat, cooked, braised",
  "Veal, breast, point half, boneless, separable lean and fat, cooked, braised",
  "Veal, breast, separable fat, cooked",
  "Veal, breast, whole, boneless, separable lean and fat, cooked, braised",
  "Veal, breast, whole, boneless, separable lean and fat, raw",
  "Veal, breast, whole, boneless, separable lean only, cooked, braised",
  "Veal, composite of trimmed retail cuts, separable fat, cooked",
  "Veal, composite of trimmed retail cuts, separable fat, raw",
  "Veal, composite of trimmed retail cuts, separable lean and fat, cooked",
  "Veal, composite of trimmed retail cuts, separable lean and fat, raw",
  "Veal, composite of trimmed retail cuts, separable lean only, cooked",
  "Veal, composite of trimmed retail cuts, separable lean only, raw",
  "Veal, cubed for stew (leg and shoulder), separable lean only, cooked, braised",
  "Veal, cubed for stew (leg and shoulder), separable lean only, raw",
  "Veal, external fat only, cooked",
  "Veal, external fat only, raw",
  "Veal, foreshank, osso buco, separable lean and fat, cooked, braised",
  "Veal, foreshank, osso buco, separable lean only, cooked, braised",
  "Veal, ground, cooked, broiled",
  "Veal, ground, cooked, pan-fried",

  "Veal, ground, raw",
  "Veal, leg (top round), separable lean and fat, cooked, braised",
  "Veal, leg (top round), separable lean and fat, cooked, pan-fried, breaded",
  "Veal, leg (top round), separable lean and fat, cooked, pan-fried, not breaded",
  "Veal, leg (top round), separable lean and fat, cooked, roasted",
  "Veal, leg (top round), separable lean and fat, raw",
  "Veal, leg (top round), separable lean only, cooked, braised",
  "Veal, leg (top round), separable lean only, cooked, pan-fried, breaded",
  "Veal, leg (top round), separable lean only, cooked, pan-fried, not breaded",
  "Veal, leg (top round), separable lean only, cooked, roasted",
  "Veal, leg (top round), separable lean only, raw",
  "Veal, leg, top round, cap off, cutlet, boneless, cooked, grilled",
  "Veal, leg, top round, cap off, cutlet, boneless, raw",
  "Veal, loin, chop, separable lean and fat, cooked, grilled",
  "Veal, loin, chop, separable lean only, cooked, grilled",
  "Veal, loin, separable lean and fat, cooked, braised",
  "Veal, loin, separable lean and fat, cooked, roasted",
  "Veal, loin, separable lean and fat, raw",
  "Veal, loin, separable lean only, cooked, braised",
  "Veal, loin, separable lean only, cooked, roasted",
  "Veal, loin, separable lean only, raw",
  "Veal, rib, separable lean and fat, cooked, braised",
  "Veal, rib, separable lean and fat, cooked, roasted",
  "Veal, rib, separable lean and fat, raw",
  "Veal, rib, separable lean only, cooked, braised",
  "Veal, rib, separable lean only, cooked, roasted",
  "Veal, rib, separable lean only, raw",
  "Veal, seam fat only, cooked",
  "Veal, seam fat only, raw",
  "Veal, shank (fore and hind), separable lean and fat, cooked, braised",
  "Veal, shank (fore and hind), separable lean and fat, raw",
  "Veal, shank (fore and hind), separable lean only, cooked, braised",
  "Veal, shank (fore and hind), separable lean only, raw",
  "Veal, shank, separable lean and fat, raw",
  "Veal, shank, separable lean only, raw",
  "Veal, shoulder, arm, separable lean and fat, cooked, braised",
  "Veal, shoulder, arm, separable lean and fat, cooked, roasted",
  "Veal, shoulder, arm, separable lean and fat, raw",
  "Veal, shoulder, arm, separable lean only, cooked, braised",
  "Veal, shoulder, arm, separable lean only, cooked, roasted",
  "Veal, shoulder, arm, separable lean only, raw",
  "Veal, shoulder, blade chop, separable lean and fat, cooked, grilled",
  "Veal, shoulder, blade chop, separable lean and fat, raw",
  "Veal, shoulder, blade chop, separable lean only, cooked, grilled",
  "Veal, shoulder, blade chop, separable lean only, raw",
  "Veal, shoulder, blade, separable lean and fat, cooked, braised",
  "Veal, shoulder, blade, separable lean and fat, cooked, roasted",
  "Veal, shoulder, blade, separable lean only, cooked, braised",
  "Veal, shoulder, blade, separable lean only, cooked, roasted",
  "Veal, shoulder, whole (arm and blade), separable lean and fat, cooked, braised",

  "Veal, shoulder, whole (arm and blade), separable lean and fat, cooked, roasted",
  "Veal, shoulder, whole (arm and blade), separable lean and fat, raw",
  "Veal, shoulder, whole (arm and blade), separable lean only, cooked, braised",
  "Veal, shoulder, whole (arm and blade), separable lean only, cooked, roasted",
  "Veal, shoulder, whole (arm and blade), separable lean only, raw",
  "Veal, sirloin, separable lean and fat, cooked, braised",
  "Veal, sirloin, separable lean and fat, cooked, roasted",
  "Veal, sirloin, separable lean and fat, raw",
  "Veal, sirloin, separable lean only, cooked, braised",
  "Veal, sirloin, separable lean only, cooked, roasted",
  "Veal, sirloin, separable lean only, raw",
  "Veal, variety meats and by-products, brain, cooked, braised",
  "Veal, variety meats and by-products, brain, cooked, pan-fried",
  "Veal, variety meats and by-products, brain, raw",
  "Veal, variety meats and by-products, heart, cooked, braised",
  "Veal, variety meats and by-products, heart, raw",
  "Veal, variety meats and by-products, kidneys, cooked, braised",
  "Veal, variety meats and by-products, kidneys, raw",
  "Veal, variety meats and by-products, liver, cooked, braised",
  "Veal, variety meats and by-products, liver, cooked, pan-fried",
  "Veal, variety meats and by-products, liver, raw",
  "Veal, variety meats and by-products, lungs, cooked, braised",
  "Veal, variety meats and by-products, lungs, raw",
  "Veal, variety meats and by-products, pancreas, cooked, braised",
  "Veal, variety meats and by-products, pancreas, raw",
  "Veal, variety meats and by-products, spleen, cooked, braised",
  "Veal, variety meats and by-products, spleen, raw",
  "Veal, variety meats and by-products, thymus, cooked, braised",
  "Veal, variety meats and by-products, thymus, raw",
  "Veal, variety meats and by-products, tongue, cooked, braised",
  "Veal, variety meats and by-products, tongue, raw",
  "Vegetable juice cocktail, canned",
  "Vegetable juice cocktail, low sodium, canned",
  "Vegetable juice, BOLTHOUSE FARMS, DAILY GREENS",
  "Vegetable oil, palm kernel",
  "Vegetable oil-butter spread, reduced calorie",
  "Vegetables, mixed (corn, lima beans, peas, green beans, carrots) canned, no salt added",
  "Vegetables, mixed, canned, drained solids",
  "Vegetables, mixed, canned, solids and liquids",
  "Vegetables, mixed, frozen, cooked, boiled, drained, with salt",
  "Vegetables, mixed, frozen, cooked, boiled, drained, without salt",
  "Vegetables, mixed, frozen, unprepared",
  "Vegetarian fillets",
  "Vegetarian meatloaf or patties",
  "Veggie burgers or soyburgers, unprepared",
  "Vermicelli, made from soy",
  "Vinegar, balsamic",
  "Vinegar, cider",
  "Vinegar, distilled",
  "Vinegar, red wine",

  "Vinespinach, (basella), raw",
  "Vital wheat gluten",
  "Vitasoy USA Azumaya, Extra Firm Tofu",
  "Vitasoy USA Azumaya, Firm Tofu",
  "Vitasoy USA Azumaya, Silken Tofu",
  "Vitasoy USA Nasoya, Lite Silken Tofu",
  "Vitasoy USA Organic Nasoya Sprouted, Tofu Plus Super Firm",
  "Vitasoy USA Organic Nasoya, Soft Tofu",
  "Vitasoy USA Organic Nasoya, Tofu Plus Extra Firm",
  "Vitasoy USA Organic Nasoya, Tofu Plus Firm",
  "Vitasoy USA, Nasoya Lite Firm Tofu",
  "Vitasoy USA, Organic Nasoya Extra Firm Tofu",
  "Vitasoy USA, Organic Nasoya Firm Tofu",
  "Vitasoy USA, Organic Nasoya Silken Tofu",
  "Vitasoy USA, Organic Nasoya Super Firm Cubed Tofu",
  "Vitasoy USA, Vitasoy Light Vanilla Soymilk",
  "Vitasoy USA, Vitasoy Organic Classic Original Soymilk",
  "Vitasoy USA, Vitasoy Organic Creamy Original Soymilk",
  "Waffle, buttermilk, frozen, ready-to-heat, microwaved",
  "Waffle, buttermilk, frozen, ready-to-heat, toasted",
  "Waffle, plain, frozen, ready-to-heat, microwave",
  "Waffles, buttermilk, frozen, ready-to-heat",
  "Waffles, chocolate chip, frozen, ready-to-heat",
  "Waffles, gluten-free, frozen, ready-to-heat",
  "Waffles, plain, frozen, ready -to-heat, toasted",
  "Waffles, plain, frozen, ready-to-heat",
  "Waffles, plain, prepared from recipe",
  "Waffles, whole wheat, lowfat, frozen, ready-to-heat",
  "Walrus, liver, raw (Alaska Native)",
  "Walrus, meat and subcutaneous fat raw (Alaska Native)",
  "Walrus, meat, dried (Alaska Native)",
  "Walrus, meat, raw (Alaska Native)",
  "Wasabi",
  "Wasabi, root, raw",
  "Water convolvulus, cooked, boiled, drained, with salt",
  "Water convolvulus, cooked, boiled, drained, without salt",
  "Water convolvulus,raw",
  "Water, bottled, generic",
  "Water, bottled, non-carbonated, NAYA",
  "Water, non-carbonated, bottles, natural fruit flavors, sweetened with low calorie sweetener",
  "Water, with corn syrup and/or sugar and low calorie sweetener, fruit flavored",
  "Waterchestnuts, chinese, (matai), raw",
  "Waterchestnuts, chinese, canned, solids and liquids",
  "Watercress, raw",
  "Watermelon, raw",
  "Waxgourd, (chinese preserving melon), cooked, boiled, drained, with salt",
  "Waxgourd, (chinese preserving melon), cooked, boiled, drained, without salt",
  "Waxgourd, (chinese preserving melon), raw",
  "WEND'YS, Crispy Chicken Sandwich",
  "WENDY'S, Chicken Nuggets",

  "WENDY'S, CLASSIC DOUBLE, with cheese",
  "WENDY'S, CLASSIC SINGLE Hamburger, no cheese",
  "WENDY'S, CLASSIC SINGLE Hamburger, with cheese",
  "WENDY'S, DAVE'S Hot 'N Juicy 1/4 LB, single",
  "WENDY'S, Double Stack, with cheese",
  "WENDY'S, french fries",
  "WENDY'S, Frosty Dairy Dessert",
  "WENDY'S, Homestyle Chicken Fillet Sandwich",
  "WENDY'S, Jr. Hamburger, with cheese",
  "WENDY'S, Jr. Hamburger, without cheese",
  "WENDY'S, Ultimate Chicken Grill Sandwich",
  "Whale, beluga, eyes (Alaska Native)",
  "Whale, beluga, flipper, raw (Alaska Native)",
  "Whale, beluga, liver, raw (Alaska Native)",
  "Whale, beluga, meat, dried (Alaska Native)",
  "Whale, beluga, meat, raw (Alaska Native)",
  "Whale, bowhead, skin and subcutaneous fat (muktuk) (Alaska Native)",
  "Whale, bowhead, subcutaneous fat (blubber) (Alaska Native)",
  "Wheat bran, crude",
  "Wheat flour, white (industrial), 10% protein, bleached, enriched",
  "Wheat flour, white (industrial), 10% protein, bleached, unenriched",
  "Wheat flour, white (industrial), 10% protein, unbleached, enriched",
  "Wheat flour, white (industrial), 11.5% protein, bleached, enriched",
  "Wheat flour, white (industrial), 11.5% protein, bleached, unenriched",
  "Wheat flour, white (industrial), 11.5% protein, unbleached, enriched",
  "Wheat flour, white (industrial), 13% protein, bleached, enriched",
  "Wheat flour, white (industrial), 13% protein, bleached, unenriched",
  "Wheat flour, white (industrial), 15% protein, bleached, enriched",
  "Wheat flour, white (industrial), 15% protein, bleached, unenriched",
  "Wheat flour, white (industrial), 9% protein, bleached, enriched",
  "Wheat flour, white (industrial), 9% protein, bleached, unenriched",
  "Wheat flour, white, all-purpose, enriched, bleached",
  "Wheat flour, white, all-purpose, enriched, calcium-fortified",
  "Wheat flour, white, all-purpose, enriched, unbleached",
  "Wheat flour, white, all-purpose, self-rising, enriched",
  "Wheat flour, white, all-purpose, unenriched",
  "Wheat flour, white, bread, enriched",
  "Wheat flour, white, cake, enriched",
  "Wheat flour, white, tortilla mix, enriched",
  "Wheat flour, whole-grain (Includes foods for USDA's Food Distribution Program)",
  "Wheat flour, whole-grain, soft wheat",
  "Wheat flours, bread, unenriched",
  "Wheat germ, crude",
  "Wheat, durum",
  "Wheat, hard red spring",
  "Wheat, hard red winter",
  "Wheat, hard white",
  "Wheat, KAMUT khorasan, cooked",
  "Wheat, KAMUT khorasan, uncooked",
  "Wheat, soft red winter",

  "Wheat, soft white",
  "Wheat, sprouted",
  "Whey, acid, dried",
  "Whey, acid, fluid",
  "Whey, sweet, dried",
  "Whey, sweet, fluid",
  "Whipped cream substitute, dietetic, made from powdered mix",
  "Whipped topping, frozen, low fat",
  "Whiskey sour mix, bottled, with added potassium and sodium",
  "Wild rice, cooked",
  "Wild rice, raw",
  "Willow, leaves in oil (Alaska Native)",
  "Willow, young leaves, chopped (Alaska Native)",
  "Winged bean leaves, raw",
  "Winged bean tuber, raw",
  "Winged bean, immature seeds, cooked, boiled, drained, with salt",
  "Winged beans, immature seeds, cooked, boiled, drained, without salt",
  "Winged beans, immature seeds, raw",
  "Winged beans, mature seeds, cooked, boiled, with salt",
  "Winged beans, mature seeds, cooked, boiled, without salt",
  "Winged beans, mature seeds, raw",
  "Wocas, dried seeds, Oregon, yellow pond lily (Klamath)",
  "Wocas, tuber, cooked, Oregon, yellow pond lily (Klamath)",
  "Wonton wrappers (includes egg roll wrappers)",
  "Yachtwurst, with pistachio nuts, cooked",
  "Yam, cooked, boiled, drained, or baked, with salt",
  "Yam, cooked, boiled, drained, or baked, without salt",
  "Yam, raw",
  "Yambean (jicama), cooked, boiled, drained, with salt",
  "Yambean (jicama), cooked, boiled, drained, without salt",
  "Yambean (jicama), raw",
  "Yardlong bean, cooked, boiled, drained, with salt",
  "Yardlong bean, cooked, boiled, drained, without salt",
  "Yardlong bean, raw",
  "Yardlong beans, mature seeds, cooked, boiled, with salt",
  "Yardlong beans, mature seeds, cooked, boiled, without salt",
  "Yardlong beans, mature seeds, raw",
  "Yautia (tannier), raw",
  "Yeast extract spread",
  "Yellow rice with seasoning, dry packet mix, unprepared",
  "Yogurt parfait, lowfat, with fruit and granola",
  "Yogurt, chocolate, nonfat milk",
  "Yogurt, chocolate, nonfat milk, fortified with vitamin D",
  "Yogurt, frozen, flavors not chocolate, nonfat milk, with low-calorie sweetener",
  "Yogurt, frozen, flavors other than chocolate, lowfat",
  "Yogurt, fruit variety, nonfat",
  "Yogurt, fruit variety, nonfat, fortified with vitamin D",
  "Yogurt, fruit, low fat, 10 grams protein per 8 ounce",
  "Yogurt, fruit, low fat, 10 grams protein per 8 ounce, fortified with vitamin D",
  "Yogurt, fruit, low fat, 11g protein/8 oz",

  
  "Yogurt, fruit, low fat, 9 grams protein per 8 ounce, fortified with vitamin D",
  "Yogurt, fruit, low fat,9 g protein/8 oz",
  "Yogurt, fruit, lowfat, with low calorie sweetener",
  "Yogurt, fruit, lowfat, with low calorie sweetener, fortified with vitamin D",
  "Yogurt, Greek, 2% fat, apricot, CHOBANI",
  "Yogurt, Greek, 2% fat, key lime blend, CHOBANI",
  "Yogurt, Greek, 2% fat, mango, CHOBANI",
  "Yogurt, Greek, 2% fat, pineapple, CHOBANI",
  "Yogurt, Greek, 2% fat, strawberry banana, CHOBANI",
  "Yogurt, Greek, 2% fat,mixed berry blend, CHOBANI",
  "Yogurt, Greek, 2%fat, coconut blend, CHOBANI",
  "Yogurt, Greek, Blueberry, CHOBANI",
  "Yogurt, Greek, fruit, whole milk",
  "Yogurt, Greek, nonfat, Fruit on Bottom, Blackberry, CHOBANI",
  "Yogurt, Greek, nonfat, Fruit on Bottom, Pomegranate, CHOBANI",
  "Yogurt, Greek, nonfat, Fruit on Bottom, Strawberry, CHOBANI",
  "Yogurt, Greek, nonfat, lemon blend, CHOBANI",
  "Yogurt, Greek, nonfat, peach, CHOBANI",
  "Yogurt, Greek, nonfat, plain, CHOBANI",
  "Yogurt, Greek, nonfat, raspberry, CHOBANI",
  "Yogurt, Greek, nonfat, strawberry, DANNON OIKOS",
  "Yogurt, Greek, nonfat, vanilla, CHOBANI",
  "Yogurt, Greek, nonfat, vanilla, DANNON OIKOS",
  "Yogurt, Greek, plain, lowfat",
  "Yogurt, Greek, plain, nonfat (Includes foods for USDA's Food Distribution Program)",
  "Yogurt, Greek, plain, whole milk",
  "Yogurt, Greek, strawberry, DANNON OIKOS",
  "Yogurt, Greek, strawberry, lowfat",
  "Yogurt, Greek, strawberry, nonfat",
  "Yogurt, Greek, vanilla, lowfat",
  "Yogurt, Greek, vanilla, nonfat",
  "Yogurt, Greek, whole, plain, CHOBANI",
  "Yogurt, plain, low fat",
  "Yogurt, plain, skim milk",
  "Yogurt, plain, whole milk",
  "Yogurt, vanilla flavor, lowfat milk, sweetened with low calorie sweetener",
  "Yogurt, vanilla or lemon flavor, nonfat milk, sweetened with low-calorie sweetener",
  "Yogurt, vanilla or lemon flavor, nonfat milk, sweetened with low-calorie sweetener, fortified with vitamin D",
  "Yogurt, vanilla, low fat, fortified with vitamin D",
  "Yogurt, vanilla, low fat.",
  "Yogurt, vanilla, non-fat",
  "Yokan, prepared from adzuki beans and sugar",
  "Zwieback"

  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<FoundationFoodsWithUnit>;
  monoUnsaturatedFatPercentage: string;
  polyUnsaturatedFatPercentage: string;
  solubleFiberPercentage: string;
  insolubleFiberPercentage: string;
  vitaminAPercentage: string;
  vitaminCPercentage: string;
  thiaminPercentage: string;
  riboflavinPercentage: string;
  niacinPercentage: string;
  vitaminB6Percentage: string;
  folatePercentage: string;
  vitaminB12Percentage: string;
  phosphorusPercentage: string;
  magnesiumPercentage: string;
  zincPercentage: string;
  cholinePercentage: string;
  fluoridePercentage: string;
  isLoading: boolean;

  constructor(private cdr: ChangeDetectorRef, private foodService: FoodService, private foodNutrientsService: FoodNutrientsService) { }

  ngOnInit(): void {

    this.foodService.getFoodList_Protein()
      .subscribe((protein: FoundationFoods[]) => this.foodList_Protein = protein);

    this.foodService.getFoodList_Carb()
      .subscribe((carb: FoundationFoods[]) => this.foodList_Carb = carb);

    this.foodService.getFoodList_Fiber()
      .subscribe((fiber: FoundationFoods[]) => this.foodList_Fiber = fiber);

    this.foodService.getFoodList_SaturatedFats()
      .subscribe((saturatedfats: FoundationFoods[]) => this.foodList_SaturatedFats = saturatedfats);

    this.foodService.getFoodList_MonoUnsaturatedFats()
      .subscribe((monounsaturatedfats: FoundationFoods[]) => this.foodList_MonoUnsaturatedFats = monounsaturatedfats);

    this.foodService.getFoodList_PolyUnsaturatedFats()
      .subscribe((polyunsaturatedfats: FoundationFoods[]) => this.foodList_PolyUnsaturatedFats = polyunsaturatedfats);

    this.foodService.getFoodList_TransFats()
      .subscribe((transfats: FoundationFoods[]) => this.foodList_TransFats = transfats);

    this.foodService.getFoodList_Cholestrol()
      .subscribe((cholestrol: FoundationFoods[]) => this.foodList_Cholestrol = cholestrol);

    this.foodService.getFoodList_Water()
      .subscribe((water: FoundationFoods[]) => this.foodList_Water = water);

    this.foodService.getFoodList_Biotin()
      .subscribe((biotin: FoundationFoods[]) => this.foodList_Biotin = biotin);

    this.foodService.getFoodList_Boron()
      .subscribe((boron: FoundationFoods[]) => this.foodList_Boron = boron);

    this.foodService.getFoodList_Calcium()
      .subscribe((calcium: FoundationFoods[]) => this.foodList_Calcium = calcium);

    this.foodService.getFoodList_Carotene_alpha()
      .subscribe((carotene_alpha: FoundationFoods[]) => this.foodList_Carotene_alpha = carotene_alpha);

    this.foodService.getFoodList_Carotene_beta()
      .subscribe((carotene_beta: FoundationFoods[]) => this.foodList_Carotene_beta = carotene_beta);

    this.foodService.getFoodList_Carotene_gamma()
      .subscribe((carotene_gamma: FoundationFoods[]) => this.foodList_Carotene_gamma = carotene_gamma);

    this.foodService.getFoodList_Chlorine()
      .subscribe((chlorine: FoundationFoods[]) => this.foodList_Chlorine = chlorine);

    this.foodService.getFoodList_CholineFree()
      .subscribe((cholineFree: FoundationFoods[]) => this.foodList_cholineFree = cholineFree);


    this.foodService.getFoodList_CholineFromPhosphocholineMg()
      .subscribe((cholineFromPhosphocholineMg: FoundationFoods[]) => this.foodList_cholineFromPhosphocholineMg = cholineFromPhosphocholineMg);


    this.foodService.getFoodList_CholineFromGlycerophosphocholine()
      .subscribe((cholineFromGlycerophosphocholine: FoundationFoods[]) => this.foodList_cholineFromGlycerophosphocholineMg = cholineFromGlycerophosphocholine);


    this.foodService.getFoodList_CholineFromPhosphotidylCholineMg()
      .subscribe((cholineFromPhosphotidylCholineMg: FoundationFoods[]) => this.foodList_cholineFromPhosphotidylCholineMg = cholineFromPhosphotidylCholineMg);

    this.foodService.getFoodList_CholineFromSphingomyelinMg()
      .subscribe((cholineFromSphingomyelinMg: FoundationFoods[]) => this.foodList_cholineFromSphingomyelinMg = cholineFromSphingomyelinMg);

    this.foodService.getFoodList_CholineTotal()
      .subscribe((cholineTotal: FoundationFoods[]) => this.foodList_cholineTotal = cholineTotal);

    this.foodService.getFoodList_ChromiumMugram()
      .subscribe((chromiumMugram: FoundationFoods[]) => this.foodList_chromiumMugram = chromiumMugram);

    this.foodService.getFoodList_Copper()
      .subscribe((copper: FoundationFoods[]) => this.foodList_copper = copper);

    this.foodService.getFoodList_Fluoride()
      .subscribe((fluoride: FoundationFoods[]) => this.foodList_fluoride = fluoride);

    this.foodService.getFoodList_FolateDFE()
      .subscribe((folateDFE: FoundationFoods[]) => this.foodList_folateDFE = folateDFE);

    this.foodService.getFoodList_folateFood()
      .subscribe((folateFood: FoundationFoods[]) => this.foodList_folateFood = folateFood);

    this.foodService.getFoodList_FolateTotal()
      .subscribe((folateTotal: FoundationFoods[]) => this.foodList_folateTotal = folateTotal);

    this.foodService.getFoodList_Iodine()
      .subscribe((iodine: FoundationFoods[]) => this.foodList_iodine = iodine);

    this.foodService.getFoodList_Iron()
      .subscribe((iron: FoundationFoods[]) => this.foodList_iron = iron);

    this.foodService.getFoodList_Magnesium()
      .subscribe((magnesium: FoundationFoods[]) => this.foodList_magnesium = magnesium);

    this.foodService.getFoodList_Manganese()
      .subscribe((manganese: FoundationFoods[]) => this.foodList_manganese = manganese);

    this.foodService.getFoodList_Molybdenum()
      .subscribe((molybdenum: FoundationFoods[]) => this.foodList_molybdenum = molybdenum);

    this.foodService.getFoodList_Niacin()
      .subscribe((niacin: FoundationFoods[]) => this.foodList_niacin = niacin);

    this.foodService.getFoodList_Nickel()
      .subscribe((nickel: FoundationFoods[]) => this.foodList_nickel = nickel);


    this.foodService.getFoodList_PantothenicAcid()
      .subscribe((pantothenicAcid: FoundationFoods[]) => this.foodList_pantothenicAcid = pantothenicAcid);

    this.foodService.getFoodList_Phosphorus()
      .subscribe((phosphorus: FoundationFoods[]) => this.foodList_phosphorus = phosphorus);

    this.foodService.getFoodList_Potassium()
      .subscribe((potassium: FoundationFoods[]) => this.foodList_potassium = potassium);

    this.foodService.getFoodList_Riboflavin()
      .subscribe((riboflavin: FoundationFoods[]) => this.foodList_riboflavin = riboflavin);

    this.foodService.getFoodList_Selenium()
      .subscribe((selenium: FoundationFoods[]) => this.foodList_selenium = selenium);

    this.foodService.getFoodList_Sodium()
      .subscribe((sodium: FoundationFoods[]) => this.foodList_sodium = sodium);

    this.foodService.getFoodList_Sulfur()
      .subscribe((sulfur: FoundationFoods[]) => this.foodList_sulfur = sulfur);

    this.foodService.getFoodList_Thiamin()
      .subscribe((thiamin: FoundationFoods[]) => this.foodList_thiamin = thiamin);

    this.foodService.getFoodList_VitaminA_RAE()
      .subscribe((vitaminA_RAE: FoundationFoods[]) => this.foodList_vitaminA_RAE = vitaminA_RAE);

    this.foodService.getFoodList_VitaminAIU()
      .subscribe((vitaminAIU: FoundationFoods[]) => this.foodList_vitaminAIU = vitaminAIU);

    this.foodService.getFoodList_VitaminB12Added()
      .subscribe((vitaminB12Added: FoundationFoods[]) => this.foodList_vitaminB12Added = vitaminB12Added);


    this.foodService.getFoodList_VitaminB12()
      .subscribe((vitaminB12: FoundationFoods[]) => this.foodList_vitaminB12 = vitaminB12);

    this.foodService.getFoodList_VitaminB6()
      .subscribe((vitaminB6: FoundationFoods[]) => this.foodList_vitaminB6 = vitaminB6);

    this.foodService.getFoodList_VitaminC()
      .subscribe((vitaminC: FoundationFoods[]) => this.foodList_vitaminC = vitaminC);

    this.foodService.getFoodList_VitaminD_D2PlusD3IU()
      .subscribe((vitaminD_D2PlusD3IU: FoundationFoods[]) => this.foodList_VitaminD_D2PlusD3IU = vitaminD_D2PlusD3IU);

    this.foodService.getFoodList_VitaminD_D2PlusD3()
      .subscribe((vitaminD_D2PlusD3: FoundationFoods[]) => this.foodList_vitaminD_D2PlusD3 = vitaminD_D2PlusD3);

    this.foodService.getFoodList_VitaminD2()
      .subscribe((vitaminD2: FoundationFoods[]) => this.foodList_vitaminD2 = vitaminD2);

    this.foodService.getFoodList_VitaminD3()
      .subscribe((vitaminD3: FoundationFoods[]) => this.foodList_vitaminD3 = vitaminD3);

    this.foodService.getFoodList_VitaminD4()
      .subscribe((vitaminD4: FoundationFoods[]) => this.foodList_vitaminD4 = vitaminD4);


    this.foodService.getFoodList_vitaminE_AlphaTocopherol()
      .subscribe((vitaminE_AlphaTocopherol: FoundationFoods[]) => this.foodList_vitaminE_AlphaTocopherol = vitaminE_AlphaTocopherol);

    this.foodService.getFoodList_VitaminE_Added()
      .subscribe((vitaminE_added: FoundationFoods[]) => this.foodList_vitaminE_Added = vitaminE_added);

    this.foodService.getFoodList_VitaminELabelEntryIU()
      .subscribe((vitaminELabelEntryIU: FoundationFoods[]) => this.foodList_vitaminELabelEntryIU = vitaminELabelEntryIU);


    this.foodService.getFoodList_VitaminEMcgRE()
      .subscribe((vitaminEMcgRE: FoundationFoods[]) => this.foodList_vitaminEMcgRE = vitaminEMcgRE);


    this.foodService.getFoodList_VitaminK_Dihydrophylloquinone()
      .subscribe((vitaminK_Dihydrophylloquinone: FoundationFoods[]) => this.foodList_vitaminK_Dihydrophylloquinone = vitaminK_Dihydrophylloquinone);


    this.foodService.getFoodList_VitaminK_Menaquinone4()
      .subscribe((vitaminK_Menaquinone4: FoundationFoods[]) => this.foodList_vitaminK_Menaquinone4 = vitaminK_Menaquinone4);

    this.foodService.getFoodList_vitaminK_Phylloquinone()
      .subscribe((vitaminK_Phylloquinone: FoundationFoods[]) => this.foodList_vitaminK_Phylloquinone = vitaminK_Phylloquinone);

    this.foodService.getFoodList_zinc()
      .subscribe((zinc: FoundationFoods[]) => this.foodList_zinc = zinc);
    //this.getAllFood();
    /*this.foodService.getAllFood()
    .subscribe((food: Food[]) => this.filteredFoodList = food);*/
    this.dataSource.paginator = this.paginator;
  }

  /* getUniqueCategories() {
    const categories = this.dataSource.data
      .map(item => item.foodcategory)
      .filter(item => item !== null);
    this.uniqueCategories = (Array.from(new Set(categories)));
    //.filter(item => item.trim() !== '');
  } */

  getUniqueCategories() {
    const categories = this.filteredDataSource.data
      .map(item => item.foodcategory)
      .filter(item => item !== null);

    // Filter out excluded categories based on diet
    let excludedCategories: string | string[] = [];
    if (this.selectedDiet === 'vegan') {
      excludedCategories = this.veganExcludedCategories;
    } else if (this.selectedDiet === 'vegetarian') {
      excludedCategories = this.vegetarianExcludedCategories;
    }

    // Filter unique categories, excluding those not allowed in the selected diet
    this.uniqueCategories = Array.from(new Set(categories));
    this.filteredCategories = this.uniqueCategories.filter(category => !excludedCategories.includes(category));
  }

  // Function to clear the input field
  clearInput() {
    this.inputValue = '';
    this.filterResults("");
  }

  // Apply filter logic
  applyFilter() {
    // Apply diet filter first
    if (this.selectedDiet === 'vegan') {
      this.filteredDataSource.data = this.dataSource.data.filter(item =>
        !this.veganExcludedCategories.includes(item.foodcategory)
      );
    } else if (this.selectedDiet === 'vegetarian') {
      this.filteredDataSource.data = this.dataSource.data.filter(item =>
        !this.vegetarianExcludedCategories.includes(item.foodcategory)
      );
    } else {
      this.filteredDataSource.data = this.dataSource.data; // No diet filter, show all
    }

    // Apply category filter
    if (this.selectedCategory) {
      this.filteredDataSource.data = this.filteredDataSource.data.filter(item => item.foodcategory === this.selectedCategory);
    }

    this.filteredDataSource.paginator = this.paginator;
    this.getUniqueCategories(); // Refresh available categories
  }


  reset() {
    this.filteredFoodList = [];
    this.unit = {} as Unit;
    this.foodsWithUnit = [];
    this.dataSource.data = [];
    this.filteredDataSource.data = [];
    this.selectedCategory = '';
  }

  navigateToPage() {
    // Example of navigating to 'page1'. Adjust as needed.
    this.router.navigate(['/login']);
  }
  addIngredient() {
    if (this.newIngredient.trim() && this.newAmount !== null) {
      var tempIngredient = {
        ingredient: this.newIngredient, amount: this.newAmount
      };
      this.ingredients = [...this.ingredients, tempIngredient];

      this.newIngredient = '';
      this.newAmount = null;
    }


  }

  generateNutritionLabel() {
    const nutrientRequests = this.ingredients.map((ingredient) =>
      this.foodNutrientsService.getNutrients(ingredient.ingredient).pipe(
        catchError((err) => {
          console.error(`Error fetching nutrients for ${ingredient.ingredient}`, err);
          return of({
            foodName: ingredient.ingredient,
            nutrients: [],
          });
        })
      )
    );

    forkJoin(nutrientRequests).subscribe(
      (results) => {
        const cumulativeNutrients: { [key: string]: number } = {};

        results.forEach((result, index) => {
          const ingredient = this.ingredients[index];

          if (result.nutrients.length > 0) {
            const multiplier = (ingredient.amount || 0) / parseFloat(result.nutrients[0]?.portionSize || '100');

            result.nutrients.forEach((nutrient) => {
              const nutrientName = nutrient.nutrientName;
              const adjustedAmount = nutrient.nutrientAmount * multiplier;

              if (cumulativeNutrients[nutrientName]) {
                cumulativeNutrients[nutrientName] += adjustedAmount;
              } else {
                cumulativeNutrients[nutrientName] = adjustedAmount;
              }
            });
          }
        });

        // Update nutritionLabel for UI display
        this.nutritionLabel = Object.entries(cumulativeNutrients).map(([name, value]) => ({
          nutrientName: name,
          nutrientAmount: Math.round(value).toString(), // Rounded to 2 decimal places
          unit: results[0]?.nutrients.find(n => n.nutrientName === name)?.unit || 'N/A',
        }));
      },
      (error) => {
        console.error('Error fetching nutrient data:', error);
      }
    );
    // this.generateNutritionLabelFDA();
    // Logic to generate data for mat-table
    this.showMatTable = true;  // Show mat-table results
    this.showFDA = false;      // Hide FDA label if previously displayed
    this.showFDA_VolNut = false;
  }

  generateNutritionLabelFDA() {
    // Your existing logic to generate the label
    this.nutritionLabel = this.generateLabelData(); // Assume this populates the label
    this.showFDA = true;      // Show FDA label
    this.showMatTable = false; // Hide mat-table if previously displayed 
    this.showFDA_VolNut = false; 
  }

  NutritionLabelFDA_VoluntaryNuts() {
    // Your existing logic to generate the label
    this.nutritionLabel = this.LabelData_VolNut(); // Assume this populates the label
    this.showFDA = false;      // Show FDA label
    this.showMatTable = false; // Hide mat-table if previously displayed  
    this.showFDA_VolNut = true;
  }

  generateLabelData() {
    const nutrientRequests = this.ingredients.map((ingredient) =>
      this.foodNutrientsService.getNutrients(ingredient.ingredient).pipe(
        catchError((err) => {
          console.error(`Error fetching nutrients for ${ingredient.ingredient}`, err);
          return of({
            foodName: ingredient.ingredient,
            nutrients: [],
          });
        })
      )
    );

    forkJoin(nutrientRequests).subscribe((results) => {
      const cumulativeNutrients: { [key: string]: number } = {};

      results.forEach((result, index) => {
        const ingredient = this.ingredients[index];
        //const ingredientServingSize = ingredient.amount; // Actual serving size for the ingredient
        const factor = (ingredient.amount || 100) / 100;

        if (result.nutrients.length > 0) {
          result.nutrients.forEach((nutrient) => {
            const nutrientName = nutrient.nutrientName;

            // Normalize nutrient amount for the actual serving size
            //const normalizedAmount = (nutrient.nutrientAmount * this.servingSize) / 100;



            // Accumulate nutrient values
            cumulativeNutrients[nutrientName] =
              (cumulativeNutrients[nutrientName] || 0) + nutrient.nutrientAmount * factor;
          });
        }
      });

      // After all nutrients are accumulated, adjust for the number of servings
      Object.keys(cumulativeNutrients).forEach((nutrientName) => {
        cumulativeNutrients[nutrientName] /= this.numberOfServings;  // Now adjust for servings
      });

      // Calculate totalCalories from Energy (Atwater Specific Factors)
      this.totalCalories = Math.round(
        cumulativeNutrients['Energy (Atwater Specific Factors)'] ||
        cumulativeNutrients['Energy (Atwater General Factors)'] ||
        cumulativeNutrients['Energy'] ||
        0
      );

      // Nutrient totals based on serving size
      this.totalFat = this.roundFatValue(
        (cumulativeNutrients['Fatty acids, total monounsaturated'] || 0) +
        (cumulativeNutrients['Fatty acids, total polyunsaturated'] || 0) +
        (cumulativeNutrients['Fatty acids, total trans'] || 0) +
        (cumulativeNutrients['Fatty acids, total saturated'] || 0) +
        (cumulativeNutrients['Fatty acids, total trans-monoenoic'] || 0) +
        (cumulativeNutrients['Fatty acids, total trans-dienoic'] || 0)
      );

      this.totalFat = this.roundFatValue(this.totalFat ||
        cumulativeNutrients['Total fat (NLEA)'] || 0
      );

      //this.totalFat = this.roundFatValue(cumulativeNutrients['Total fat (NLEA)'] || 0);
      this.saturatedFat = this.roundFatValue(cumulativeNutrients['Fatty acids, total saturated'] || 0);
      this.transFat = this.roundFatValue(cumulativeNutrients['Fatty acids, total trans'] || 0);


      // Cholesterol handling
      if (cumulativeNutrients['Cholesterol']) {
        let cholesterolValue = cumulativeNutrients['Cholesterol'];
        if (cholesterolValue < 2) {
          this.cholesterol = '< 2 ';
        } else if (cholesterolValue >= 2 && cholesterolValue <= 5) {
          this.cholesterol = 'less than 5';
        } else {
          // Round to the nearest 5 mg increment
          let roundedCholesterol = Math.round(cholesterolValue / 5) * 5;
          this.cholesterol = `${roundedCholesterol} `;
        }
      } else {
        this.cholesterol = '0 ';  // Default case when cholesterol is not available
      }

      const cholesterolNumericValue = parseFloat(this.cholesterol) || 0;


      // Total Carbohydrate rounding logic
      const totalCarbohydrateRaw = cumulativeNutrients['Carbohydrate, by difference'] || 0;
      this.totalCarbohydrateRaw = totalCarbohydrateRaw; // Store raw value for percentage calculation
      if (totalCarbohydrateRaw < 1) {
        this.totalCarbohydrate = '< 1 ';
      } else {
        this.totalCarbohydrate = `${Math.round(totalCarbohydrateRaw)} `;
      }

      // Dietary Fiber rounding logic
      const dietaryFiberRaw = cumulativeNutrients['Fiber, total dietary'] || 0;
      this.dietaryFiberRaw = dietaryFiberRaw; // Store raw value for percentage calculation
      if (dietaryFiberRaw < 0.5) {
        this.dietaryFiber = '0 ';
      } else if (dietaryFiberRaw < 1) {
        this.dietaryFiber = '< 1 ';
      } else {
        this.dietaryFiber = `${Math.round(dietaryFiberRaw)} `;
      }
      this.addedSugars = cumulativeNutrients['Sucrose'];
      this.totalSugars = cumulativeNutrients['Sugars, Total'];

      //this.totalCarbohydrate = cumulativeNutrients['Carbohydrate, by difference'] || 0;
      //this.dietaryFiber = cumulativeNutrients['Fiber, total dietary'] || 0;
      this.proteinRaw = cumulativeNutrients['Protein'] || 0;

      if (this.proteinRaw < 0.5) {
        this.protein = '< 0.5 ';
      } else if (this.proteinRaw >= 0.5 && this.proteinRaw < 1) {
        this.protein = 'less than 1 ';
      } else {
        this.protein = `${Math.round(this.proteinRaw)} `; // Round to nearest 1 g increment
      }

      this.vitaminD = Math.round(cumulativeNutrients['Vitamin D (D2 + D3)'] || 0);
      this.calcium = Math.round(cumulativeNutrients['Calcium, Ca'] || 0);
      this.iron = Math.round(cumulativeNutrients['Iron, Fe'] || 0);

      this.sodium = cumulativeNutrients['Sodium, Na'] || 0;
      this.potassium = cumulativeNutrients['Potassium, K'] || 0;

      // Apply rounding rules
      const roundedSodium = this.roundSodiumPotassium(this.sodium);
      const roundedPotassium = this.roundSodiumPotassium(this.potassium);

      // For Nutrition Label
      this.sodiumFormatted = roundedSodium < 5 ? '<5' : roundedSodium.toString();
      this.potassiumFormatted = roundedPotassium < 5 ? '<5' : roundedPotassium.toString();




      // Example for Vitamin D
      this.vitaminDPercentage = this.formatPercentageDV((this.vitaminD / this.vitaminDDV) * 100);
      // Example for Calcium
      this.calciumPercentage = this.formatPercentageDV((this.calcium / this.calciumDV) * 100);
      // Example for Iron
      this.ironPercentage = this.formatPercentageDV((this.iron / this.ironDV) * 100);
      // Example for Potassium
      this.potassiumPercentage = this.formatPercentageDV((this.potassium / this.potassiumDV) * 100);
      this.addedSugarsPercentage = ((this.addedSugars / this.addedSugarsDV) * 100).toFixed(2);

      // Handling labels for special cases when values are very low
      const lowDVStatement = 'Contains less than 2% of the Daily Value of this nutrient.';
      if (this.vitaminDPercentage === '*') {
        this.vitaminDNote = lowDVStatement;
      }
      if (this.calciumPercentage === '*') {
        this.calciumNote = lowDVStatement;
      }
      if (this.ironPercentage === '*') {
        this.ironNote = lowDVStatement;
      }
      if (this.potassiumPercentage === '*') {
        this.potassiumNote = lowDVStatement;
      }

      // Optional: If all are low and key nutrients Vitamin A, C, Calcium, Iron, include:
      const keyNutrientsNote =
        'Not a significant source of Vitamin A, Vitamin C, Calcium, and Iron.';
      if (
        this.vitaminDPercentage === '*' &&
        this.calciumPercentage === '*' &&
        this.ironPercentage === '*' &&
        this.potassiumPercentage === '*'
      ) {
        this.generalLowDVNote = keyNutrientsNote;
      }


      //this.protein = cumulativeNutrients['Protein'] || 0;


      // Calculate DV percentages based on per-serving values
      this.totalFatPercentage = ((this.totalFat / this.totalFatDV) * 100).toFixed(1);
      this.saturatedFatPercentage = ((this.saturatedFat / this.saturatedFatDV) * 100).toFixed(1);
      this.cholesterolPercentage = ((cholesterolNumericValue / this.cholesterolDV) * 100).toFixed(1);

      this.sodiumPercentage = ((roundedSodium / this.sodiumDV) * 100).toFixed(1);
      this.totalCarbohydratePercentage = ((this.totalCarbohydrateRaw / this.totalCarbohydrateDV) * 100).toFixed(1);
      this.dietaryFiberPercentage = ((this.dietaryFiberRaw / this.dietaryFiberDV) * 100).toFixed(1);

      this.proteinPercentage = ((this.proteinRaw / this.proteinDV) * 100).toFixed(1);
      // this.vitaminDPercentage = ((this.vitaminD / this.vitaminDDV) * 100).toFixed(1);
      // this.calciumPercentage = ((this.calcium / this.calciumDV) * 100).toFixed(1);
      // this.ironPercentage = ((this.iron / this.ironDV) * 100).toFixed(1);
      // this.potassiumPercentage = ((roundedPotassium / this.potassiumDV) * 100).toFixed(1);


      // Update the nutrition label with the new per-serving values
      this.nutritionLabel = [
        {
          nutrientName: '', unit: '', percentage: '% Daily Value*',
          nutrientAmount: ''
        },
        { nutrientName: 'Total Fat', nutrientAmount: this.totalFat.toFixed(2), unit: 'g', percentage: this.totalFatPercentage },
        { nutrientName: 'Saturated Fat', nutrientAmount: this.saturatedFat.toFixed(2), unit: 'g', percentage: this.saturatedFatPercentage, isSubNutrient: true },
        { nutrientName: 'Trans Fat', nutrientAmount: this.transFat.toFixed(2), unit: 'g', isSubNutrient: true },
        { nutrientName: 'Cholesterol', nutrientAmount: (Number(this.cholesterol) || 0).toFixed(2), unit: 'mg', percentage: this.cholesterolPercentage },
        // { nutrientName: 'Sodium', nutrientAmount: this.sodium.toFixed(2), unit: 'mg', percentage: this.sodiumPercentage },
        {
          nutrientName: 'Sodium',
          nutrientAmount: this.sodiumFormatted,
          unit: 'mg',
          percentage: this.sodiumPercentage,
        },
        { nutrientName: 'Total Carbohydrate', nutrientAmount: (Number(this.totalCarbohydrate) || 0).toFixed(2), unit: 'g', percentage: this.totalCarbohydratePercentage },
        { nutrientName: 'Dietary Fiber', nutrientAmount: (Number(this.dietaryFiber) || 0).toFixed(2), unit: 'g', percentage: this.dietaryFiberPercentage, isSubNutrient: true },
        { nutrientName: 'Total Sugars', nutrientAmount: (Number(this.totalSugars) || 0).toFixed(2), unit: 'g', percentage: '', isSubNutrient: true },
        { nutrientName: 'Added Sugars', nutrientAmount: (Number(this.addedSugars) || 0).toFixed(2), unit: 'g', percentage: this.addedSugarsPercentage, isSubNutrient: true, isSubSubNutrient: true },
        { nutrientName: 'Protein', nutrientAmount: (Number(this.protein) || 0).toFixed(2), unit: 'g', percentage: this.proteinPercentage },
        // { nutrientName: 'Vitamin D', nutrientAmount: this.vitaminD.toFixed(2), unit: 'mcg', percentage: this.vitaminDPercentage },
        // { nutrientName: 'Calcium', nutrientAmount: this.calcium.toFixed(2), unit: 'mg', percentage: this.calciumPercentage },
        // { nutrientName: 'Iron', nutrientAmount: this.iron.toFixed(2), unit: 'mg', percentage: this.ironPercentage },
        // { nutrientName: 'Potassium', nutrientAmount: this.potassium.toFixed(2), unit: 'mg', percentage: this.potassiumPercentage },
        // Vitamins & Minerals with low DV handling
        // Vitamins & Minerals with low DV handling
        { nutrientName: 'Vitamin D', nutrientAmount: this.vitaminD.toFixed(2), unit: 'mcg', percentage: this.vitaminDPercentage !== '*' ? this.vitaminDPercentage : undefined, note: this.vitaminDNote || undefined },
        { nutrientName: 'Calcium', nutrientAmount: this.calcium.toFixed(2), unit: 'mg', percentage: this.calciumPercentage !== '*' ? this.calciumPercentage : undefined, note: this.calciumNote || undefined },
        { nutrientName: 'Iron', nutrientAmount: this.iron.toFixed(2), unit: 'mg', percentage: this.ironPercentage !== '*' ? this.ironPercentage : undefined, note: this.ironNote || undefined },

        {
          nutrientName: 'Potassium',
          nutrientAmount: this.potassiumFormatted,
          unit: 'mg',
          percentage: this.potassiumPercentage !== '*' ? this.potassiumPercentage : undefined, note: this.potassiumNote || undefined
        },
        // { nutrientName: 'Potassium', nutrientAmount: this.potassium.toFixed(2), unit: 'mg', percentage: this.potassiumPercentage !== '*' ? this.potassiumPercentage : undefined, note: this.potassiumNote || undefined },

      ];
      // Include general note for low key nutrients if applicable
      /*   if (this.generalLowDVNote) {
          this.nutritionLabel.push({ nutrientName: 'General Note', nutrientAmount: '', unit: '', percentage: '', note: this.generalLowDVNote });
        }  */
    });

    return this.nutritionLabel;
  }


  LabelData_VolNut() {
    const nutrientRequests = this.ingredients.map((ingredient) =>
      this.foodNutrientsService.getNutrients(ingredient.ingredient).pipe(
        catchError((err) => {
          console.error(`Error fetching nutrients for ${ingredient.ingredient}`, err);
          return of({
            foodName: ingredient.ingredient,
            nutrients: [],
          });
        })
      )
    );


    forkJoin(nutrientRequests).subscribe((results) => {
      if(results){
    
      const cumulativeNutrients: { [key: string]: number } = {};

      results.forEach((result, index) => {
        const ingredient = this.ingredients[index];
        //const ingredientServingSize = ingredient.amount; // Actual serving size for the ingredient
        const factor = (ingredient.amount || 100) / 100;

        if (result.nutrients.length > 0) {
          result.nutrients.forEach((nutrient) => {
            const nutrientName = nutrient.nutrientName;

            // Normalize nutrient amount for the actual serving size
            //const normalizedAmount = (nutrient.nutrientAmount * this.servingSize) / 100;

            // Accumulate nutrient values
            cumulativeNutrients[nutrientName] =
              (cumulativeNutrients[nutrientName] || 0) + nutrient.nutrientAmount * factor;
          });
        }
      }
    
    );

      // After all nutrients are accumulated, adjust for the number of servings
      Object.keys(cumulativeNutrients).forEach((nutrientName) => {
        cumulativeNutrients[nutrientName] /= this.numberOfServings;  // Now adjust for servings
      });

      

      // Calculate totalCalories from Energy (Atwater Specific Factors)
      this.totalCalories = Math.round(
        cumulativeNutrients['Energy (Atwater Specific Factors)'] ||
        cumulativeNutrients['Energy (Atwater General Factors)'] ||
        cumulativeNutrients['Energy'] ||
        0
      );

      // Nutrient totals based on serving size
      this.totalFat = this.roundFatValue(
        (cumulativeNutrients['Fatty acids, total monounsaturated'] || 0) +
        (cumulativeNutrients['Fatty acids, total polyunsaturated'] || 0) +
        (cumulativeNutrients['Fatty acids, total trans'] || 0) +
        (cumulativeNutrients['Fatty acids, total saturated'] || 0) +
        (cumulativeNutrients['Fatty acids, total trans-monoenoic'] || 0) +
        (cumulativeNutrients['Fatty acids, total trans-dienoic'] || 0)
      );

      this.totalFat = this.roundFatValue(this.totalFat ||
        cumulativeNutrients['Total fat (NLEA)'] || 0
      );

      //this.totalFat = this.roundFatValue(cumulativeNutrients['Total fat (NLEA)'] || 0);
      this.saturatedFat = this.roundFatValue(cumulativeNutrients['Fatty acids, total saturated'] || 0);
      this.transFat = this.roundFatValue(cumulativeNutrients['Fatty acids, total trans'] || 0);
      this.polyUnsaturatedFat = this.roundFatValue(cumulativeNutrients['Fatty acids, total polyunsaturated'] || 0);
      this.monoUnsaturatedFat = this.roundFatValue(cumulativeNutrients['Fatty acids, total monounsaturated'] || 0);
      this.fluoride = this.roundFluorideValue(cumulativeNutrients['Fluoride, F'] || 0);
      this.solubleFiber = this.formatFiberValue(cumulativeNutrients['Fiber, soluble'] || 0);
      this.insolubleFiber = this.formatFiberValue(cumulativeNutrients['Fiber, insoluble'] || 0);
      this.vitaminA = this.roundFatValue(cumulativeNutrients['Vitamin A, RAE'] || 0);
      this.vitaminC = this.roundFatValue(cumulativeNutrients['Vitamin C, total ascorbic acid'] || 0);
      this.thiamin = this.roundFatValue(cumulativeNutrients['Thiamin'] || 0);
      this.riboflavin = this.roundFatValue(cumulativeNutrients['Riboflavin'] || 0);
      this.niacin = this.roundFatValue(cumulativeNutrients['Niacin'] || 0);
      this.vitaminB6 = this.roundFatValue(cumulativeNutrients['Vitamin B-6'] || 0);
      this.folate = this.roundFatValue(cumulativeNutrients['Folate, total'] || 0);
      this.vitaminB12 = this.roundFatValue(cumulativeNutrients['Vitamin B-12'] || 0);
      this.phosphorus = this.roundFatValue(cumulativeNutrients['Phosphorus, P'] || 0);
      this.magnesium = this.roundFatValue(cumulativeNutrients['Magnesium, Mg'] || 0);
      this.zinc = this.roundFatValue(cumulativeNutrients['Zinc, Zn'] || 0);
      this.choline = this.roundFatValue(cumulativeNutrients['Choline, total'] || 0);

      // Cholesterol handling
      if (cumulativeNutrients['Cholesterol']) {
        let cholesterolValue = cumulativeNutrients['Cholesterol'];
        if (cholesterolValue < 2) {
          this.cholesterol = '< 2 ';
        } else if (cholesterolValue >= 2 && cholesterolValue <= 5) {
          this.cholesterol = 'less than 5';
        } else {
          // Round to the nearest 5 mg increment
          let roundedCholesterol = Math.round(cholesterolValue / 5) * 5;
          this.cholesterol = `${roundedCholesterol} `;
        }
      } else {
        this.cholesterol = '0 ';  // Default case when cholesterol is not available
      }

      const cholesterolNumericValue = parseFloat(this.cholesterol) || 0;


      // Total Carbohydrate rounding logic
      const totalCarbohydrateRaw = cumulativeNutrients['Carbohydrate, by difference'] || 0;
      this.totalCarbohydrateRaw = totalCarbohydrateRaw; // Store raw value for percentage calculation
      if (totalCarbohydrateRaw < 1) {
        this.totalCarbohydrate = '< 1 ';
      } else {
        this.totalCarbohydrate = `${Math.round(totalCarbohydrateRaw)} `;
      }

      // Dietary Fiber rounding logic
      const dietaryFiberRaw = cumulativeNutrients['Fiber, total dietary'] || 0;
      this.dietaryFiberRaw = dietaryFiberRaw; // Store raw value for percentage calculation
      if (dietaryFiberRaw < 0.5) {
        this.dietaryFiber = '0 ';
      } else if (dietaryFiberRaw < 1) {
        this.dietaryFiber = '< 1 ';
      } else {
        this.dietaryFiber = `${Math.round(dietaryFiberRaw)} `;
      }
      this.addedSugars = cumulativeNutrients['Sucrose'];
      this.totalSugars = cumulativeNutrients['Sugars, Total'];

      //this.totalCarbohydrate = cumulativeNutrients['Carbohydrate, by difference'] || 0;
      //this.dietaryFiber = cumulativeNutrients['Fiber, total dietary'] || 0;
      this.proteinRaw = cumulativeNutrients['Protein'] || 0;

      if (this.proteinRaw < 0.5) {
        this.protein = '< 0.5 ';
      } else if (this.proteinRaw >= 0.5 && this.proteinRaw < 1) {
        this.protein = 'less than 1 ';
      } else {
        this.protein = `${Math.round(this.proteinRaw)} `; // Round to nearest 1 g increment
      }

      this.vitaminD = Math.round(cumulativeNutrients['Vitamin D (D2 + D3)'] || 0);
      this.calcium = Math.round(cumulativeNutrients['Calcium, Ca'] || 0);
      this.iron = Math.round(cumulativeNutrients['Iron, Fe'] || 0);

      this.sodium = cumulativeNutrients['Sodium, Na'] || 0;
      this.potassium = cumulativeNutrients['Potassium, K'] || 0;

      // Apply rounding rules
      const roundedSodium = this.roundSodiumPotassium(this.sodium);
      const roundedPotassium = this.roundSodiumPotassium(this.potassium);

      // For Nutrition Label
      this.sodiumFormatted = roundedSodium < 5 ? '<5' : roundedSodium.toString();
      this.potassiumFormatted = roundedPotassium < 5 ? '<5' : roundedPotassium.toString();




      // Example for Vitamin D
      this.vitaminDPercentage = this.formatPercentageDV((this.vitaminD / this.vitaminDDV) * 100);
      // Example for Calcium
      this.calciumPercentage = this.formatPercentageDV((this.calcium / this.calciumDV) * 100);
      // Example for Iron
      this.ironPercentage = this.formatPercentageDV((this.iron / this.ironDV) * 100);
      // Example for Potassium
      this.potassiumPercentage = this.formatPercentageDV((this.potassium / this.potassiumDV) * 100);
      this.addedSugarsPercentage = ((this.addedSugars / this.addedSugarsDV) * 100).toFixed(2);
      this.polyUnsaturatedFatPercentage = this.formatPercentageDV((this.polyUnsaturatedFat / this.fatDV) * 100);
      this.monoUnsaturatedFatPercentage = this.formatPercentageDV((this.monoUnsaturatedFat / this.fatDV) * 100);
     // this.fluoridePercentage = this.formatPercentageDV((this.fluoride / this.fluorideDV) * 100);
     // Ensure solubleFiber is a number before calculating percentage
      const solubleFiberValue = typeof this.solubleFiber === 'number' ? this.solubleFiber : 0;
      this.solubleFiberPercentage = this.formatPercentageDV((solubleFiberValue / this.dietaryFiberDV) * 100);
      const insolubleFiberValue = typeof this.solubleFiber === 'number' ? this.solubleFiber : 0;
      this.insolubleFiberPercentage = this.formatPercentageDV((insolubleFiberValue / this.dietaryFiberDV) * 100); // Uses dietary fiber DV
      this.vitaminAPercentage = this.formatPercentageDV((this.vitaminA / this.vitaminADV) * 100);
      this.vitaminCPercentage = this.formatPercentageDV((this.vitaminC / this.vitaminCDV) * 100);
      this.thiaminPercentage = this.formatPercentageDV((this.thiamin / this.thiaminDV) * 100);
      this.riboflavinPercentage = this.formatPercentageDV((this.riboflavin / this.riboflavinDV) * 100);
      this.niacinPercentage = this.formatPercentageDV((this.niacin / this.niacinDV) * 100);
      this.vitaminB6Percentage = this.formatPercentageDV((this.vitaminB6 / this.vitaminB6DV) * 100);
      this.folatePercentage = this.formatPercentageDV((this.folate / this.folateFolicAcidDV) * 100); // Uses folate/folic acid DV
      this.vitaminB12Percentage = this.formatPercentageDV((this.vitaminB12 / this.vitaminB12DV) * 100);
      this.phosphorusPercentage = this.formatPercentageDV((this.phosphorus / this.phosphorusDV) * 100);
      this.magnesiumPercentage = this.formatPercentageDV((this.magnesium / this.magnesiumDV) * 100);
      this.zincPercentage = this.formatPercentageDV((this.zinc / this.zincDV) * 100);
      this.cholinePercentage = this.formatPercentageDV((this.choline / this.cholineDV) * 100);

      // Handling labels for special cases when values are very low
      const lowDVStatement = 'Contains less than 2% of the Daily Value of this nutrient.';
      if (this.vitaminDPercentage === '*') {
        this.vitaminDNote = lowDVStatement;
      }
      if (this.calciumPercentage === '*') {
        this.calciumNote = lowDVStatement;
      }
      if (this.ironPercentage === '*') {
        this.ironNote = lowDVStatement;
      }
      if (this.potassiumPercentage === '*') {
        this.potassiumNote = lowDVStatement;
      }

      // Optional: If all are low and key nutrients Vitamin A, C, Calcium, Iron, include:
      const keyNutrientsNote =
        'Not a significant source of Vitamin A, Vitamin C, Calcium, and Iron.';
      if (
        this.vitaminDPercentage === '*' &&
        this.calciumPercentage === '*' &&
        this.ironPercentage === '*' &&
        this.potassiumPercentage === '*'
      ) {
        this.generalLowDVNote = keyNutrientsNote;
      }


      //this.protein = cumulativeNutrients['Protein'] || 0;


      // Calculate DV percentages based on per-serving values
      this.totalFatPercentage = ((this.totalFat / this.totalFatDV) * 100).toFixed(1);
      this.saturatedFatPercentage = ((this.saturatedFat / this.saturatedFatDV) * 100).toFixed(1);
      this.cholesterolPercentage = ((cholesterolNumericValue / this.cholesterolDV) * 100).toFixed(1);

      this.sodiumPercentage = ((roundedSodium / this.sodiumDV) * 100).toFixed(1);
      this.totalCarbohydratePercentage = ((this.totalCarbohydrateRaw / this.totalCarbohydrateDV) * 100).toFixed(1);
      this.dietaryFiberPercentage = ((this.dietaryFiberRaw / this.dietaryFiberDV) * 100).toFixed(1);

      this.proteinPercentage = ((this.proteinRaw / this.proteinDV) * 100).toFixed(1);
      // this.vitaminDPercentage = ((this.vitaminD / this.vitaminDDV) * 100).toFixed(1);
      // this.calciumPercentage = ((this.calcium / this.calciumDV) * 100).toFixed(1);
      // this.ironPercentage = ((this.iron / this.ironDV) * 100).toFixed(1);
      // this.potassiumPercentage = ((roundedPotassium / this.potassiumDV) * 100).toFixed(1);


      // Update the nutrition label with the new per-serving values
      
      // Include general note for low key nutrients if applicable
      /*   if (this.generalLowDVNote) {
          this.nutritionLabel.push({ nutrientName: 'General Note', nutrientAmount: '', unit: '', percentage: '', note: this.generalLowDVNote });
        }  */
   } });

    // Update the nutrition label with the new per-serving values
    const solubleFiberAmount =
  typeof this.solubleFiber === 'number' ? this.solubleFiber : parseFloat(this.solubleFiber) || 0;

  // Update the nutrition label with the new per-serving values
  const insolubleFiberAmount =
  typeof this.solubleFiber === 'number' ? this.solubleFiber : parseFloat(this.solubleFiber) || 0;

this.nutritionLabel = [
  {
    nutrientName: '', unit: '', percentage: '% Daily Value*',
    nutrientAmount: ''
  },
  { nutrientName: 'Total Fat', nutrientAmount: this.totalFat.toFixed(2), unit: 'g', percentage: this.totalFatPercentage },
  { nutrientName: 'Saturated Fat', nutrientAmount: this.saturatedFat.toFixed(2), unit: 'g', percentage: this.saturatedFatPercentage, isSubNutrient: true },
  { nutrientName: 'Trans Fat', nutrientAmount: this.transFat.toFixed(2), unit: 'g', isSubNutrient: true },
  { nutrientName: 'Polyunsaturated Fat', nutrientAmount: this.polyUnsaturatedFat.toFixed(2), unit: 'g',isSubNutrient: true },
  { nutrientName: 'Monounsaturated Fat', nutrientAmount: this.monoUnsaturatedFat.toFixed(2), unit: 'g', isSubNutrient: true },
  { nutrientName: 'Cholesterol', nutrientAmount: (Number(this.cholesterol) || 0).toFixed(2), unit: 'mg', percentage: this.cholesterolPercentage },
  {
    nutrientName: 'Sodium',
    nutrientAmount: this.sodiumFormatted,
    unit: 'mg',
    percentage: this.sodiumPercentage,
  },
  { nutrientName: 'Fluoride', nutrientAmount: this.fluoride.toFixed(2), unit: 'mg' },
  { nutrientName: 'Total Carbohydrate', nutrientAmount: (Number(this.totalCarbohydrate) || 0).toFixed(2), unit: 'g', percentage: this.totalCarbohydratePercentage },
  { nutrientName: 'Dietary Fiber', nutrientAmount: (Number(this.dietaryFiber) || 0).toFixed(2), unit: 'g', percentage: this.dietaryFiberPercentage, isSubNutrient: true },
  { nutrientName: 'Soluble Fiber', nutrientAmount: solubleFiberAmount.toFixed(2), unit: 'g',  isSubNutrient: true, isSubSubNutrient: true},
  { nutrientName: 'Insoluble Fiber', nutrientAmount: insolubleFiberAmount.toFixed(2), unit: 'g',  isSubNutrient: true, isSubSubNutrient: true },
  { nutrientName: 'Total Sugars', nutrientAmount: (Number(this.totalSugars) || 0).toFixed(2), unit: 'g', percentage: '', isSubNutrient: true },
  { nutrientName: 'Added Sugars', nutrientAmount: (Number(this.addedSugars) || 0).toFixed(2), unit: 'g', percentage: this.addedSugarsPercentage, isSubNutrient: true, isSubSubNutrient: true },
  { nutrientName: 'Protein', nutrientAmount: (Number(this.protein) || 0).toFixed(2), unit: 'g', percentage: this.proteinPercentage },
  // Vitamins & Minerals
  { nutrientName: 'Vitamin D', nutrientAmount: this.vitaminD.toFixed(2), unit: 'mcg', percentage: this.vitaminDPercentage !== '*' ? this.vitaminDPercentage : undefined, note: this.vitaminDNote || undefined },
  { nutrientName: 'Calcium', nutrientAmount: this.calcium.toFixed(2), unit: 'mg', percentage: this.calciumPercentage !== '*' ? this.calciumPercentage : undefined, note: this.calciumNote || undefined },
  { nutrientName: 'Iron', nutrientAmount: this.iron.toFixed(2), unit: 'mg', percentage: this.ironPercentage !== '*' ? this.ironPercentage : undefined, note: this.ironNote || undefined },
  { nutrientName: 'Potassium', nutrientAmount: this.potassiumFormatted, unit: 'mg', percentage: this.potassiumPercentage !== '*' ? this.potassiumPercentage : undefined, note: this.potassiumNote || undefined },
  { nutrientName: 'Vitamin A', nutrientAmount: this.vitaminA.toFixed(2), unit: 'mcg', percentage: this.vitaminAPercentage },
  { nutrientName: 'Vitamin C', nutrientAmount: this.vitaminC.toFixed(2), unit: 'mg', percentage: this.vitaminCPercentage },
  { nutrientName: 'Thiamin', nutrientAmount: this.thiamin.toFixed(2), unit: 'mg', percentage: this.thiaminPercentage },
  { nutrientName: 'Riboflavin', nutrientAmount: this.riboflavin.toFixed(2), unit: 'mg', percentage: this.riboflavinPercentage },
  { nutrientName: 'Niacin', nutrientAmount: this.niacin.toFixed(2), unit: 'mg', percentage: this.niacinPercentage },
  { nutrientName: 'Vitamin B6', nutrientAmount: this.vitaminB6.toFixed(2), unit: 'mg', percentage: this.vitaminB6Percentage },
  { nutrientName: 'Folate', nutrientAmount: this.folate.toFixed(2), unit: 'mcg DFE', percentage: this.folatePercentage },
  { nutrientName: 'Vitamin B12', nutrientAmount: this.vitaminB12.toFixed(2), unit: 'mcg', percentage: this.vitaminB12Percentage },
  { nutrientName: 'Phosphorus', nutrientAmount: this.phosphorus.toFixed(2), unit: 'mg', percentage: this.phosphorusPercentage },
  { nutrientName: 'Magnesium', nutrientAmount: this.magnesium.toFixed(2), unit: 'mg', percentage: this.magnesiumPercentage },
  { nutrientName: 'Zinc', nutrientAmount: this.zinc.toFixed(2), unit: 'mg', percentage: this.zincPercentage },
  { nutrientName: 'Choline', nutrientAmount: this.choline.toFixed(2), unit: 'mg', percentage: this.cholinePercentage },
];


    return this.nutritionLabel;
  }


  
  isMajorNutrient(nutrientName: string): boolean {
    const majorNutrients = ['Total Fat', 'Cholesterol', 'Sodium', 'Fluoride','Total Carbohydrate', 'Protein'];
    return majorNutrients.includes(nutrientName);
  }
  
  
  isThickLine(nutrient: { nutrientName: string }): boolean {
    // Apply thick line styling for section separators
    return nutrient.nutrientName === 'Total Fat';
  }
  
  
  orderedNutrients = this.nutritionLabel.sort((a, b) => {
    const nutrientOrder = [
      'Total Fat',
      'Saturated Fat',
      'Trans Fat',
      'Cholesterol',
      'Sodium',
      'Fluoride',
      'Total Carbohydrate',
      'Dietary Fiber',
      'Total Sugars',
      'Added Sugars',
      'Protein',
    ];
    return nutrientOrder.indexOf(a.nutrientName) - nutrientOrder.indexOf(b.nutrientName);
  });
  
  // Additional Vitamins Section
  vitamins = this.nutritionLabel.filter(nutrient => ['Vitamin D', 'Calcium', 'Iron', 'Potassium'].includes(nutrient.nutrientName));
  
  isVitaminOrMineral(nutrientName: string): boolean {
    const vitaminsAndMinerals = ['Vitamin D', 'Calcium', 'Iron', 'Potassium','Vitamin A','Vitamin C',
'Thiamin','Riboflavin','Niacin', 'Vitamin B6','Folate','Vitamin B12','Phosphorus',
'Magnesium','Zinc','Choline'
    ];
    return vitaminsAndMinerals.includes(nutrientName);
  }
  isNoDV(nutrientName: string): boolean {
    const sugarandfats = ["Trans Fat", "Total Sugars","Polyunsaturated Fat",
      'Monounsaturated Fat','Fluoride','Soluble Fiber', 'Insoluble Fiber'
    ];
    return sugarandfats.includes(nutrientName);

  }
  

  // Helper function for rounding Sodium and Potassium values
 roundSodiumPotassium(value: number): number {
  if (value < 5) {
    return 0; // Express as "<5 mg"
  } else if (value <= 140) {
    return Math.round(value / 5) * 5; // Nearest 5 mg increment
  } else {
    return Math.round(value / 10) * 10; // Nearest 10 mg increment
  }
}

     // Helper function to format %DV as per the specifications
    formatPercentageDV(value: number): string {
      if (value < 2) {
        //return '*'; // Refer to the statement for less than 2% of DV
        return `${Math.round(value)}`;
      } else if (value <= 10) {
        return `${Math.round(value / 2) * 2}`; // Nearest 2% DV increment
      } else if (value <= 50) {
        return `${Math.round(value / 5) * 5}`; // Nearest 5% DV increment
      } else {
        return `${Math.round(value / 10) * 10}`; // Nearest 10% DV increment
      }
    }

  // Helper function to round fat values according to the FDA guidelines
   roundFatValue(value: number): number {
    if (value < 0.5) {
      return 0;
    } else if (value < 5) {
      return Math.round(value * 2) / 2; // Round to nearest 0.5 g
    } else {
      return Math.round(value); // Round to nearest 1 g
    }
  }

  roundFluorideValue(value: number): number {
    if (value < 0.1) {
      return 0; // Express as zero
    } else if (value <= 0.8) {
      return Math.round(value * 10) / 10; // Round to nearest 0.1 mg
    } else {
      return Math.round(value * 5) / 5; // Round to nearest 0.2 mg
    }
  }


  // Reusable function to format fiber values
formatFiberValue(value: number, isSoluble: boolean = true): string {
  if (value < 0.5) {
    return "0"; // Express as zero if less than 0.5 grams
  } else if (value < 1) {
    return "less than 1 gram"; // Alternative statement for less than 1 gram
  } else {
    return `${Math.round(value)} grams`; // Round to nearest gram if greater than or equal to 1
  }
}



  removeIngredient(item: { ingredient: string; amount: number | null }) {
    this.ingredients = this.ingredients.filter(
      (ingredient) => ingredient !== item
    );
  }

  // Fetch nutrients for the entered food name
  onSearchFood() {
    this.foodNutrientsService.getNutrients(this.foodName).subscribe(data => {
      this.foodData = data;
      //this.initializeChartData(); // Call initializeChartData after foodData is set
    });
  }

  private initializeChartData() {
    if (this.foodData && this.foodData.nutrients) {
      this.chartData = {
        labels: this.foodData.nutrients.map(n => n.nutrientName),
        datasets: [{
          label: 'Nutrient Amount',
          data: this.foodData.nutrients.map(n => n.nutrientAmount),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        }]
      };
    }
  }



  filterResults(text: string) {
    this.reset();
    if (!text) {
      return;
    }
    if (text.toLowerCase().includes("protein")) {
      this.filteredFoodList = this.foodList_Protein;
      this.unit.unitname = "g";
    }

    if (text.toLowerCase().includes("carbohydrate")) {
      this.filteredFoodList = this.foodList_Carb;
      this.unit.unitname = "g";
    }

    if (text.toLowerCase().includes("fiber")) {
      this.filteredFoodList = this.foodList_Fiber;
      this.unit.unitname = "g";
    }

    if (text.toLowerCase().includes("saturatedfats")) {
      this.filteredFoodList = this.foodList_SaturatedFats;
      this.unit.unitname = "g";
    }


    if (text.toLowerCase().includes("monounsaturatedfats")) {
      this.filteredFoodList = this.foodList_MonoUnsaturatedFats;
      this.unit.unitname = "g";
    }


    if (text.toLowerCase().includes("polyunsaturatedfats")) {
      this.filteredFoodList = this.foodList_PolyUnsaturatedFats;
      this.unit.unitname = "g";
    }


    if (text.toLowerCase().includes("transfats")) {
      this.filteredFoodList = this.foodList_TransFats;
      this.unit.unitname = "g";
    }

    if (text.toLowerCase().includes("cholestrol")) {
      this.filteredFoodList = this.foodList_Cholestrol;
      this.unit.unitname = "mg";
    }
    if (text.toLowerCase().includes("water")) {
      this.filteredFoodList = this.foodList_Water;
      this.unit.unitname = "g";
    }

    if (text.toLowerCase().includes("biotin")) {
      this.filteredFoodList = this.foodList_Biotin;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("boron")) {
      this.filteredFoodList = this.foodList_Boron;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("calcium")) {
      this.filteredFoodList = this.foodList_Calcium;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("carotenealpha")) {
      this.filteredFoodList = this.foodList_Carotene_alpha;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("carotenebeta")) {
      this.filteredFoodList = this.foodList_Carotene_beta;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("carotenegamma")) {
      this.filteredFoodList = this.foodList_Carotene_gamma;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("chlorine")) {
      this.filteredFoodList = this.foodList_Chlorine;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("iron")) {
      this.filteredFoodList = this.foodList_iron;

    }

    if (text.toLowerCase().includes("cholinefree")) {
      this.filteredFoodList = this.foodList_cholineFree;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("cholinefromphosphocholinemg")) {
      this.filteredFoodList = this.foodList_cholineFromPhosphocholineMg;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("cholinefromphosphotidylcholinemg")) {
      this.filteredFoodList = this.foodList_cholineFromPhosphotidylCholineMg;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("cholinefromsphingomyelinmg")) {
      this.filteredFoodList = this.foodList_cholineFromSphingomyelinMg;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("cholinefromglycerophosphocholinemg")) {
      this.filteredFoodList = this.foodList_cholineFromGlycerophosphocholineMg;
      this.unit.unitname = "mg";

    }



    if (text.toLowerCase().includes("cholinetotal")) {
      this.filteredFoodList = this.foodList_cholineTotal;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("chromiumMugram")) {
      this.filteredFoodList = this.foodList_chromiumMugram;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("copper")) {
      this.filteredFoodList = this.foodList_copper;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("fluoride")) {
      this.filteredFoodList = this.foodList_fluoride;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("folatedfe")) {
      this.filteredFoodList = this.foodList_folateDFE;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("folatefood")) {
      this.filteredFoodList = this.foodList_folateFood;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("folatetotal")) {
      this.filteredFoodList = this.foodList_folateTotal;
      this.unit.unitname = "McG";
    }

    if (text.toLowerCase().includes("iodine")) {
      this.filteredFoodList = this.foodList_iodine;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("iron")) {
      this.filteredFoodList = this.foodList_iron;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("magnesium")) {
      this.filteredFoodList = this.foodList_magnesium;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("manganese")) {
      this.filteredFoodList = this.foodList_manganese;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("molybdenum")) {
      this.filteredFoodList = this.foodList_molybdenum;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("niacin")) {
      this.filteredFoodList = this.foodList_niacin;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("nickel")) {
      this.filteredFoodList = this.foodList_nickel;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("pantothenicacid")) {
      this.filteredFoodList = this.foodList_pantothenicAcid;
      this.unit.unitname = "mg";
    }
    if (text.toLowerCase().includes("phosphorus")) {
      this.filteredFoodList = this.foodList_phosphorus;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("potassium")) {
      this.filteredFoodList = this.foodList_potassium;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("riboflavin")) {
      this.filteredFoodList = this.foodList_riboflavin;
      this.unit.unitname = "mg";
    }

    if (text.toLowerCase().includes("selenium")) {
      this.filteredFoodList = this.foodList_selenium;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("sodium")) {
      this.filteredFoodList = this.foodList_sodium;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("sulfur")) {
      this.filteredFoodList = this.foodList_sulfur;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("thiamin")) {
      this.filteredFoodList = this.foodList_thiamin;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("vitamina_rae")) {
      this.filteredFoodList = this.foodList_vitaminA_RAE;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("vitaminaiu")) {
      this.filteredFoodList = this.foodList_vitaminAIU;
      this.unit.unitname = "IU";

    }
    if (text.toLowerCase().includes("vitaminb12")) {
      this.filteredFoodList = this.foodList_vitaminB12;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("vitaminb12added")) {
      this.filteredFoodList = this.foodList_vitaminB12Added;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("vitaminb6")) {
      this.filteredFoodList = this.foodList_vitaminB6;
      this.unit.unitname = "mg";

    }
    if (text.toLowerCase().includes("vitaminc")) {
      this.filteredFoodList = this.foodList_vitaminC;
      this.unit.unitname = "mg";

    }
    if (text.toLowerCase().includes("vitamind_d2plusd3")) {
      this.filteredFoodList = this.foodList_vitaminD_D2PlusD3;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("vitamind_d2plusd3iu")) {
      this.filteredFoodList = this.foodList_VitaminD_D2PlusD3IU;
      this.unit.unitname = "IU";

    }

    if (text.toLowerCase().includes("vitamind2")) {
      this.filteredFoodList = this.foodList_vitaminD2;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("vitamind3")) {
      this.filteredFoodList = this.foodList_vitaminD3;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("vitamind4")) {
      this.filteredFoodList = this.foodList_vitaminD4;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("vitamine_added")) {
      this.filteredFoodList = this.foodList_vitaminE_Added;
      this.unit.unitname = "mg";

    }
    if (text.toLowerCase().includes("vitamine_alphatocopherol")) {
      this.filteredFoodList = this.foodList_vitaminE_AlphaTocopherol;
      this.unit.unitname = "mg";

    }
    if (text.toLowerCase().includes("vitaminelabelentryiu")) {
      this.filteredFoodList = this.foodList_vitaminELabelEntryIU;
      this.unit.unitname = "IU";

    }
    if (text.toLowerCase().includes("vitaminemcgre")) {
      this.filteredFoodList = this.foodList_vitaminEMcgRE;
      this.unit.unitname = "McG_RE";

    }

    if (text.toLowerCase().includes("vitaminelabelentryiu")) {
      this.filteredFoodList = this.foodList_vitaminELabelEntryIU;

    }
    if (text.toLowerCase().includes("vitaminemcgre")) {
      this.filteredFoodList = this.foodList_vitaminEMcgRE;

    }
    if (text.toLowerCase().includes("vitamink_dihydrophylloquinone")) {
      this.filteredFoodList = this.foodList_vitaminK_Dihydrophylloquinone;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("vitamink_menaquinone4")) {
      this.filteredFoodList = this.foodList_vitaminK_Menaquinone4;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("vitamink_phylloquinone")) {
      this.filteredFoodList = this.foodList_vitaminK_Phylloquinone;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("zinc")) {
      this.filteredFoodList = this.foodList_zinc;
      this.unit.unitname = "mg";

    }

    this.filteredFoodList.sort((n1, n2) => (n2.per100unitservinging - n1.per100unitservinging));

    this.filteredFoodList.forEach((a, i) => {
      var foodWithUnit: FoundationFoodsWithUnit = {
        index: i + 1,
        fooddescription: a.fooddescription,
        perservingsizeing: a.perservingsizeing,
        per100unitservinging: a.per100unitservinging,
        foodcategory: a.foodcategory,
        unitname: this.unit.unitname
      };
      this.foodsWithUnit.push(foodWithUnit);
    });

    this.dataSource.data = this.dataSource.data.concat(this.foodsWithUnit);
    this.dataSource.paginator = this.paginator;
    this.getUniqueCategories();
    this.applyFilter(); // Apply initial filter
  }
  authService = inject(AuthService);
  router = inject(Router);
  public logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}


function applyFilter(event: Event | undefined, Event: { new(type: string, eventInitDict?: EventInit): Event; prototype: Event; readonly NONE: 0; readonly CAPTURING_PHASE: 1; readonly AT_TARGET: 2; readonly BUBBLING_PHASE: 3; }) {
  throw new Error('Function not implemented.');
}
//  this.nutrient_list.push(temp_nutrient_name);



