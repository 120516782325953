<!-- landing-page.component.html -->
<div class="landing-page">
  

  <!-- Nutrition Guide Section -->
  <div class="nutrition-guide">
    <div class="guide-content">
      <div class="guide-header">
        <h2>Discover Your Complete Nutrition</h2>
        <p>Unlock personalized Dietary Reference Intakes (DRIs) based on your unique profile</p>
      </div>

      <!-- Feature Grid with Correct Icon Colors -->
<!-- Feature Grid with Correct Icon Colors -->
<div class="feature-grid">
  <!-- Apple Icon (Red) -->
  <div class="feature-item">
    <i class="fas fa-apple-alt icon apple-icon"></i> <!-- Using classes instead of inline styles -->
    <h4>Personalized Nutrition</h4>
    <p>Get recommendations based on your age, gender, and lifestyle.</p>
  </div>

  <!-- Carrot Icon (Orange) -->
  <div class="feature-item">
    <i class="fas fa-carrot icon carrot-icon"></i> <!-- Using classes instead of inline styles -->
    <h4>Food Sources</h4>
    <p>View detailed lists of food sources rich in essential nutrients.</p>
  </div>

  <!-- Fish Icon (Blue) -->
  <div class="feature-item">
    <i class="fas fa-fish icon fish-icon"></i> <!-- Using classes instead of inline styles -->
    <h4>Track Your Progress</h4>
    <p>Monitor your daily intake and meet your nutritional goals.</p>
  </div>
</div>

      <div class="account-benefits">
        <h4>Why Create an Account?</h4>
        <ul>
          <li><i class="fas fa-chevron-right"></i>Access personalized nutrition recommendations</li>
          <li><i class="fas fa-chevron-right"></i>Discover foods that match your nutritional needs</li>
          <li><i class="fas fa-chevron-right"></i>Get daily reminders and track your progress</li>
        </ul>
      </div>

      <button class="cta-button" routerLink="/signup">Create Your Free Account</button>

      <p class="join-message">Join thousands of others on their journey to better nutrition</p>
    </div>
  </div>

 
</div> 

