import {Component} from '@angular/core';
import {HomeComponent} from './home/home.component';
import {RouterLink, RouterOutlet} from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { NuggetsComponent } from './nuggets/nuggets.component';
import { DetailsComponent } from './details/details.component';



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [DetailsComponent,RouterOutlet, HomeComponent, RouterLink, HeaderComponent, FooterComponent, AnnouncementsComponent, NuggetsComponent],
  template: `

    <app-header></app-header>
  

      
      <router-outlet></router-outlet>

      
      <app-footer></app-footer>

  `,
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Food';
}
/*<p>  <a href= "mailto: query&#64;ai-basil.com"> 
      Click here to send us an email.</a> Alternatively, write to us at <b>query&#64;ai-basil.com</b> to learn about upcoming launches and roadmap.</p>
*/