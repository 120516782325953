<footer class="footer">
    <div class="footer-content">
      <p class="footer-text">
        &copy; 2024 AI-Basil.com. All rights reserved.
      </p>
      <nav class="footer-nav">
        <!--<a (click)="onLogout()" *ngIf="authService.isLoggedIn()" class="footer-link">Logout</a>-->
        <a (click)="onAbout()" class="footer-link">About</a>
        <a (click)="onContact()" class="footer-link">Contact</a>
      </nav>
    </div>
  </footer>

  <!--footer class="footer">
    <div class="copyright">
      &copy; {{ currentYear }} AI-Basil.com. All rights reserved.
    </div>
    <button class="logout-btn" *ngIf="authService.isLoggedIn()" (click)="logout()">Logout</button>
    <button class="logout-btn"  (click)="About()">About</button>
    <button class="logout-btn"  (click)="Contact()">Contact</button>
</footer-->