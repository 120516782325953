import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../food-data';

@Injectable({
  providedIn: 'root'  // Makes the service available globally
})
export class AuthService {
 
  private cartItemCount = new BehaviorSubject<number>(0); // Create a BehaviorSubject for cart count
  private userSubject = new BehaviorSubject<any>(null); // BehaviorSubject for user updates



  constructor() { }
  httpClient = inject(HttpClient);
  baseUrl = 'https://ai-basil.com';


  signup(data: any) {
    return this.httpClient.post(`${this.baseUrl}/register`, data)
      .pipe(tap((result) => {
        localStorage.setItem('status', JSON.stringify(result));
      }));
  }

  forgotPassword(data: any) {
    return this.httpClient.post(`${this.baseUrl}/register/forgotpassword`, data)
      .pipe(tap((result) => {
        console.log(result);
      }));
  }
  contact(data: any) {
    return this.httpClient.post(`${this.baseUrl}/register/feedback`, data)
      .pipe(tap((result) => {
        console.log("feedback sent");
      }));
  }

  login(data: any) {
    return this.httpClient.post(`${this.baseUrl}/login`, data)
      .pipe(tap((result) => {
        if (result) {
          localStorage.setItem('authUser', JSON.stringify(result));
          localStorage.setItem('userLoggedIn', 'True');
        } else {
          alert("Invalid Credentials");
        }
      }));
  }

  logout() {
    localStorage.removeItem('authUser');
    localStorage.setItem('userLoggedIn', 'False');
  }


  isLoggedIn() {
    //return localStorage.getItem('authUser')===null?false:true;
    if (localStorage.getItem('userLoggedIn') === 'True')
      return true;
    return false;

    //return localStorage?.getItem('authUser')?.toString() !== "null";
  }

  getUser(id: number): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/login/info/${id}`);
  }

  /* updateUser(user: User): Observable<User> {
    return this.httpClient.put<User>(`${this.baseUrl}/update/${user.id}`, user);
  } */

  updateUser(user: User): Observable<User> {
    return this.httpClient.put<User>(`${this.baseUrl}/update/${user.id}`, user)
      .pipe(tap(updatedUser => {
        this.userSubject.next(updatedUser); // Notify subscribers of the updated user
      }));
  }

  getUserUpdates(): Observable<any> {
    return this.userSubject.asObservable(); // Expose user updates
  }

  deleteUser(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/delete/${id}`);
  }

}

