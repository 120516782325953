import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  dropdownOpen = false;
  public loggedInUser: any = null;
  public cartItemCount: number = 0; // Initialize cart item count
  public authService = inject(AuthService); // Make authService public
  availabilityMessage: string = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
    const authuser: string | null = localStorage.getItem('authUser');

    const openingTime = 9;  // 9 AM
    const closingTime = 23; // 11 PM
    const currentTime = new Date().getHours();

    this.availabilityMessage = (currentTime >= openingTime && currentTime < closingTime) 
      ? "We are available from 9 AM to 11 PM (EST)!" 
      : "Our website is available between 9 AM to 11 PM (EST).";

    if (authuser !== null) {
      const id = JSON.parse(authuser).id;
      this.getUserDetails(id);
    }

    // Subscribe to user updates
    this.authService.getUserUpdates().subscribe(updatedUser => {
      if (updatedUser) {
        this.loggedInUser = updatedUser;
        this.cartItemCount = this.loggedInUser.cart.length; // Update cart count
      }
    });
  }

  getUserDetails(userid: number): void {
    this.authService.getUser(userid).subscribe(response => {
      if (response) {
        console.log(response);
        this.loggedInUser = response;  
        this.cartItemCount = this.loggedInUser.cart.length;    
      } else {
        console.error('Error fetching user details');
      }
    });
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  onChangePassword() {
    this.router.navigate(['/change-password']);
    this.dropdownOpen = false;
  }

  onGoToCart() {
    this.router.navigate(['/cart']);
    this.dropdownOpen = false;
  }

  onLogout() {
    this.loggedInUser = null;
    this.authService.logout();
    this.router.navigate(['/landing']);
    this.dropdownOpen = false;
  }

  onLogin() {
    this.router.navigate(['/login']);
  }
}
