<!-- landing-page.component.html -->
<div class="landing-page">
  <!-- Nutrition Guide Section -->
  <div class="nutrition-guide">
    <div class="guide-content">
      <div class="guide-header">
        <h2>Know Your Food</h2>
        <p>Explore DRIs, essential food sources, and create FDA-compliant nutrition labels effortlessly.</p>
      </div>
  
    
      

      <!-- Feature Grid -->
      <div class="feature-grid">
        <!-- Personalized Nutrition (DRI) -->
        <div class="feature-item">
          <i class="fas fa-apple-alt icon apple-icon"></i>
          <h4>Personalized Nutrition (DRIs)</h4>
          <p>Understand your Dietary Reference Intakes tailored to your age, gender, and lifestyle.</p>
        </div>

        <!-- Essential Nutrient Sources -->
        <div class="feature-item">
          <i class="fas fa-carrot icon carrot-icon"></i>
          <h4>Essential Nutrient Sources</h4>
          <p>Explore food sources rich in essential nutrients and their health benefits.</p>
        </div>

        <!-- Food Source Finder -->
        <div class="feature-item">
          <i class="fas fa-search icon search-icon"></i>
          <h4>Food Source Finder</h4>
          <p>Discover the nutritional values of different foods using USDA data.</p>
        </div>

        <!-- FDA Nutrition Label Creator -->
        <div class="feature-item">
          <i class="fas fa-clipboard-list icon label-icon"></i>
          <h4>FDA Nutrition Label Creator</h4>
          <p>Generate a nutrition label from your recipes or ingredients using FDA guidelines.</p>
        </div>
      </div>

      <button class="cta-button" routerLink="/signup">Create Your Free Account</button>
      <p class="join-message">Begin your journey to better nutrition.</p>
    </div>
  </div>
</div>

   
