import { Component, Injectable, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../auth/auth.service';



@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePassswordComponent implements OnInit {

  authService = inject(AuthService);
  router = inject(Router);
  public loggedInUser: any = null;

  myForm: FormGroup;
  showPassword = false; // Default is to hide the password

  constructor(private fb: FormBuilder) {
    this.myForm = this.fb.group({
      password: [''], // Initialize password field
      changepassword: [''] // Initialize password field

    });
  }

  ngOnInit(): void {

    const authuser: string | null = localStorage.getItem('authUser');  // Example source

    if (authuser !== null) {
      // Use `parsedUserId` here, which is now a `number`
      var id = JSON.parse(authuser).id;
      this.getUserDetails(id);
    }
  }

  getUserDetails(userid: number): void {
    this.authService.getUser(userid).subscribe(
      (response) => {
        this.loggedInUser = response;
        console.log('User details:', this.loggedInUser);
      },
      (error) => {
        console.error('Error fetching user details', error);
      }
    );
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword; // Toggle password visibility
  }


  protected loginForm = new FormGroup({
    password: new FormControl('', [Validators.required]),
    confirmpassword: new FormControl('', [Validators.required])
  })


  onSubmit() {
    if (this.loginForm.valid) {
      this.loggedInUser.password = this.loginForm.value.password,
      this.authService.updateUser(this.loggedInUser).subscribe();
      alert("Pssword Reset Successfully!")
    }
    else {
      alert('Invalid Password');
    }

  }

}


