import { Component, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoodComponent } from '../food/food.component';
import { Foods, FoundationFoods, FoundationFoodsWithUnit } from '../food-data';
import { Unit } from '../food-data'
import { Observable } from 'rxjs/internal/Observable';
import { FoodService } from '../food.service';
import { catchError, forkJoin, map, of } from 'rxjs';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { AnnouncementsComponent } from '../announcements/announcements.component';
import { NuggetsComponent } from '../nuggets/nuggets.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AuthService } from '../auth/auth.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms'; // <-- Import this
import { ChangeDetectorRef } from '@angular/core';
import { SearchFormComponent } from '../search-form/search-form.component';
import { BlogComponent } from '../blog/blog.component';
import { FoodNutrientsService } from '../foodnutrients.service';
import { ChartOptions } from 'chart.js';
import { ChartData } from 'chart.js';
//import { ChartsModule } from 'ng2-charts';
//import { NgChartsModule } from 'ng2-charts';






@Component({
  selector: 'app-home',
  standalone: true,
  imports: [BlogComponent, FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatPaginatorModule, MatTableModule, MatTabsModule, CommonModule, FoodComponent, RouterOutlet, HomeComponent, RouterLink, HeaderComponent, FooterComponent, AnnouncementsComponent, NuggetsComponent, MatSelectModule, SearchFormComponent],

  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {

  foodName: string = '';
  newIngredient: string = '';
  newAmount: number | null = null;

  ingredients: { ingredient: string, amount: number | null }[] = [];


  displayedColumns_nutrition: string[] = ['ingredient', 'amount'];
  nutritionLabel: { 
    nutrientName: string; 
    nutrientAmount: string; 
    unit: string; 
    isSubNutrient?: boolean; 
    isFattyAcid?: boolean;  // Add the isFattyAcid flag here
    percentage?: string;  // Add the percentage property here

  }[] = [];
  showFDA: boolean = false; // New flag for FDA label visibility
  showMatTable: boolean = false; // For mat-table display

  totalFat: number = 10; // Example value
  fatUnit: string = 'g'; // Example unit
  saturatedFat: number = 3;
  transFat: number = 0;
  cholesterol: number = 30; // Example values, replace as needed
  sodium: number = 150;
  totalCarbohydrate: number = 20;
  dietaryFiber: number = 5;
  totalSugars: number = 8;
  addedSugars: number = 0;
  addedSugarsPercentage: number = 0;
  protein: number = 6;
  vitaminD: number = 2;
  calcium: number = 100;
  iron: number = 1.5;
  potassium: number = 250;
  totalCalories = 0; // Calculate this based on data
  servingInfo = '8 servings per container'; // Example serving info
  servingSize = '2/3 cup (55g)'; // Example serving size

  

  inputValue: string = '';
  displayedColumns: string[] = ['index', 'fooddescription', 'foodcategory', 'perservingsizeing', 'per100unitservinging', 'unitname'];
  filteredFoodList: FoundationFoods[] = [];
  foodsWithUnit: FoundationFoodsWithUnit[] = [];
  dataSource = new MatTableDataSource<FoundationFoodsWithUnit>(this.foodsWithUnit);
  filteredDataSource = new MatTableDataSource<FoundationFoodsWithUnit>(this.foodsWithUnit);
  uniqueCategories: string[] = [];
  filteredCategories: string[] = []; // Will store available categories based on selected diet
  selectedCategory: string = '';

  selectedDiet: string = ''; // New variable to store selected diet filter

  veganExcludedCategories: string[] = ['Dairy and Egg Products', 'Beef Products', 'Poultry Products', 'Sausages and Luncheon Meats', 'Pork Products', 'Finfish and Shellfish Products', 'Restaurant Foods'];
  vegetarianExcludedCategories: string[] = ['Beef Products', 'Poultry Products', 'Sausages and Luncheon Meats', 'Pork Products', 'Finfish and Shellfish Products', 'Restaurant Foods'];
  mediterraneanExcludedCategories: string[] = ['Beef Products'];
  foodList_Protein: FoundationFoods[] = [];
  foodList_Carb: FoundationFoods[] = [];//include carb by summation and others
  foodList_Fiber: FoundationFoods[] = [];
  foodList_SaturatedFats: FoundationFoods[] = [];

  foodList_MonoUnsaturatedFats: FoundationFoods[] = [];
  foodList_PolyUnsaturatedFats: FoundationFoods[] = [];
  foodList_TransFats: FoundationFoods[] = [];

  foodList_Cholestrol: FoundationFoods[] = [];
  foodList_Water: FoundationFoods[] = [];

  foodList_Biotin: FoundationFoods[] = [];
  foodList_Boron: FoundationFoods[] = [];
  foodList_Calcium: FoundationFoods[] = [];
  foodList_Carotene_alpha: FoundationFoods[] = [];
  foodList_Carotene_beta: FoundationFoods[] = [];
  foodList_Carotene_gamma: FoundationFoods[] = [];
  foodList_Chlorine: FoundationFoods[] = [];


  foodList_cholineFree: FoundationFoods[] = [];
  foodList_cholineFromPhosphocholineMg: FoundationFoods[] = [];
  foodList_cholineFromPhosphotidylCholineMg: FoundationFoods[] = [];
  foodList_cholineFromSphingomyelinMg: FoundationFoods[] = [];
  foodList_cholineTotal: FoundationFoods[] = [];
  foodList_cholineFromGlycerophosphocholineMg: FoundationFoods[] = [];

  foodList_chromiumMugram: FoundationFoods[] = [];
  foodList_copper: FoundationFoods[] = [];
  foodList_fluoride: FoundationFoods[] = [];
  foodList_folateDFE: FoundationFoods[] = [];
  foodList_folateFood: FoundationFoods[] = [];
  foodList_folateTotal: FoundationFoods[] = [];
  foodList_iodine: FoundationFoods[] = [];
  foodList_iron: FoundationFoods[] = [];
  foodList_magnesium: FoundationFoods[] = [];
  foodList_manganese: FoundationFoods[] = [];
  foodList_molybdenum: FoundationFoods[] = [];

  foodList_niacin: FoundationFoods[] = [];
  foodList_nickel: FoundationFoods[] = [];

  foodList_pantothenicAcid: FoundationFoods[] = [];
  foodList_phosphorus: FoundationFoods[] = [];
  foodList_potassium: FoundationFoods[] = [];
  foodList_riboflavin: FoundationFoods[] = [];
  foodList_selenium: FoundationFoods[] = [];
  foodList_sodium: FoundationFoods[] = [];
  foodList_sulfur: FoundationFoods[] = [];
  foodList_thiamin: FoundationFoods[] = [];
  foodList_vitaminA_RAE: FoundationFoods[] = [];
  foodList_vitaminAIU: FoundationFoods[] = [];
  foodList_vitaminB12Added: FoundationFoods[] = [];
  foodList_vitaminB12: FoundationFoods[] = [];
  foodList_vitaminB6: FoundationFoods[] = [];
  foodList_vitaminC: FoundationFoods[] = [];
  foodList_VitaminD_D2PlusD3IU: FoundationFoods[] = [];
  foodList_vitaminD_D2PlusD3: FoundationFoods[] = [];
  foodList_vitaminD2: FoundationFoods[] = [];

  foodList_vitaminD3: FoundationFoods[] = [];

  foodList_vitaminD4: FoundationFoods[] = [];
  foodList_vitaminE_Added: FoundationFoods[] = [];
  foodList_vitaminE_AlphaTocopherol: FoundationFoods[] = [];
  foodList_vitaminELabelEntryIU: FoundationFoods[] = [];
  foodList_vitaminEMcgRE: FoundationFoods[] = [];
  foodList_vitaminK_Dihydrophylloquinone: FoundationFoods[] = [];
  foodList_vitaminK_Menaquinone4: FoundationFoods[] = [];

  foodList_vitaminK_Phylloquinone: FoundationFoods[] = [];
  foodList_zinc: FoundationFoods[] = [];
  unit: Unit = { unitname: "" };


  foodData!: Foods | null;
  nutrientData: Foods | null = {
    foodId: 0,
    foodName: '',
    foodCategory: { categoryName: '' },
    nutrients: []
  };
  displayedColumns1: string[] = ['nutrientName', 'nutrientAmount', 'unit', 'portionSize', 'portionSizeUnit'];
  // Updated food list

  //chartData: ChartData<'bar'>;
  chartData: ChartData<'bar'>;
  chartOptions: ChartOptions = {
    responsive: true,
  };
  allFoods: string[] = [
    'Beans, snap, green, canned, regular pack, drained solids',
    'Beans, snap, green, raw',
    'Beets, raw',
    'Broccoli, raw',
    'Brussels sprouts, raw',
    'Cabbage, bok choy, raw',
    'Cabbage, green, raw',
    'Cabbage, red, raw',
    'Carrots, baby, raw',
    'Carrots, frozen, unprepared',
    'Carrots, mature, raw',
    'Cauliflower, raw',
    'Celery, raw',
    'Collards, raw',
    'Cucumber, with peel, raw',
    'Eggplant, raw',
    'Flour, cassava',
    'Flour, potato',
    'Garlic, raw',
    'Kale, frozen, cooked, boiled, drained, without salt',
    'Kale, raw',
    'Ketchup, restaurant',
    'Lettuce, cos or romaine, raw',
    'Lettuce, iceberg, raw',
    'Lettuce, leaf, green, raw',
    'Lettuce, leaf, red, raw',
    'Lettuce, romaine, green, raw',
    'Mushroom, beech',
    'Mushroom, crimini',
    'Mushroom, enoki',
    'Mushroom, king oyster',
    'Mushroom, lion\'s mane',
    'Mushroom, maitake',
    'Mushroom, oyster',
    'Mushroom, pioppini',
    'Mushroom, portabella',
    'Mushrooms, shiitake',
    'Mushrooms, white button',
    'Onion rings, breaded, par fried, frozen, prepared, heated in oven',
    'Onions, red, raw',
    'Onions, white, raw',
    'Onions, yellow, raw',
    'Peas, green, sweet, canned, sodium added, sugar added, drained and rinsed',
    'Peppers, bell, green, raw',
    'Peppers, bell, orange, raw',
    'Peppers, bell, red, raw',
    'Peppers, bell, yellow, raw',
    'Pickles, cucumber, dill or kosher dill',
    'Potatoes, gold, without skin, raw',
    'Potatoes, red, without skin, raw',
    'Potatoes, russet, without skin, raw',
    'Spinach, baby',
    'Spinach, mature',
    'Squash, summer, green, zucchini, includes skin, raw',
    'Squash, summer, yellow, includes skin, raw',
    'Squash, winter, acorn, raw',
    'Squash, winter, butternut, raw',
    'Sweet potatoes, orange flesh, without skin, raw',
    'Tomato juice, with added ingredients, from concentrate, shelf stable',
    'Tomato, paste, canned, without salt added',
    'Tomato, puree, canned',
    'Tomato, roma',
    'Tomato, sauce, canned, with salt added',
    'Tomatoes, canned, red, ripe, diced',
    'Tomatoes, crushed, canned',
    'Tomatoes, whole, canned, solids and liquids, with salt added',
    'Tomatoes, grape, raw',
    'Sugars, granulated',
    'Mustard, prepared, yellow',
    'Salt, table, iodized',
    'Sauce, salsa, ready-to-serve',
    'Sauce, pasta, spaghetti%2Fmarinara, ready-to-serve',
    'Sausage, turkey, breakfast links, mild, raw',
    'Sausage, Italian, pork, mild, cooked, pan-fried',
    'Sausage, pork, chorizo, link or ground, cooked, pan-fried',
    'Ham, sliced, pre-packaged, deli meat (96%fat free, water added)',
    'Frankfurter, beef, unheated',
    'Chicken, broiler or fryers, breast, skinless, boneless, meat only, cooked, braised',
    'Chicken, broilers or fryers, drumstick, meat only, cooked, braised',
    'Chicken, ground, with additives, raw',
    'Chicken, thigh, boneless, skinless, raw',
    'Turkey, ground, 93% lean, 7% fat, pan-broiled crumbles',
    'Turkey, ground, 93% lean%2F7% fat, raw',//fix
    'Ham, sliced, restaurant',
    'Pork, cured, bacon, cooked, restaurant',
    'Pork, ground, raw',
    'Pork, loin, boneless, raw',
    'Pork, loin, tenderloin, boneless, raw',
    'Almond butter, creamy',
    'Flaxseed, ground',
    'Flour, almond',
    'Flour, chestnut',
    'Flour, coconut',
    'Nuts, almonds, dry roasted, with salt added',
    'Nuts, almonds, whole, raw',
    'Nuts, brazil nuts, raw',
    'Nuts, cashew nuts, raw',
    'Nuts, hazelnuts or filberts, raw',
    'Nuts, macadamia nuts, raw',
    'Nuts, pecans, halves, raw',
    'Nuts, pine nuts, raw',
    'Nuts, pistachio nuts, raw',
    'Nuts, walnuts, English, halves, raw',
    'Seeds, pumpkin seeds (pepitas), raw',
    'Seeds, sunflower seed kernels, dry roasted, with salt added',
    'Seeds, sunflower seed, kernel, raw',
    'Sesame butter, creamy',
    'Beans, black, canned, sodium added, drained and rinsed',
    'Beans, cannellini, canned, sodium added, drained and rinsed',
    'Beans, cannellini, dry',
    'Beans, Dry, Black (0% moisture)',
    'Beans, Dry, Brown (0% moisture)',
    'Beans, Dry, Carioca (0% moisture)',
    'Beans, Dry, Cranberry (0% moisture)',
    'Beans, Dry, Dark Red Kidney (0% moisture)',
    'Beans, Dry, Flor de Mayo (0% moisture)',
    'Beans, Dry, Great Northern (0% moisture)',
    'Beans, Dry, Light Red Kidney (0% moisture)',
    'Beans, Dry, Light Tan (0% moisture)',
    'Beans, Dry, Medium Red (0% moisture)',
    'Beans, Dry, Navy (0% moisture)',
    'Beans, Dry, Pink (0% moisture)',
    'Beans, Dry, Pinto (0% moisture)',
    'Beans, Dry, Red (0% moisture)',
    'Beans, Dry, Small Red (0% moisture)',
    'Beans, Dry, Small White (0% moisture)',
    'Beans, Dry, Tan (0% moisture)',
    'Beans, great northern, canned, sodium added, drained and rinsed',
    'Beans, kidney, dark red, canned, sodium added, sugar added, drained and rinsed',
    'Beans, kidney, light red, canned, sodium added, sugar added, drained and rinsed',
    'Beans, navy, canned, sodium added, drained and rinsed',
    'Beans, pinto, canned, sodium added, drained and rinsed',
    'Blackeye pea, canned, sodium added, drained and rinsed',
    'Blackeye pea, dry',
    'Chickpeas (garbanzo beans, bengal gram), canned, sodium added, drained and rinsed',
    'Chickpeas, (garbanzo beans, bengal gram), dry',
    'Flour, soy, defatted',
    'Flour, soy, full-fat',
    'Hummus, commercial',
    'Lentils, dry',
    'Peanut butter, creamy',
    'Peanuts, raw',
    'Soy milk, sweetened, plain, refrigerated',
    'Soy milk, sweetened, plain, refrigerated',
    'Soy milk, unsweetened, plain, shelf stable',
    'Apples, fuji, with skin, raw',
    'Apples, gala, with skin, raw',
    'Apples, granny smith, with skin, raw',
    'Apples, honeycrisp, with skin, raw',
    'Apples, red delicious, with skin, raw',
    'Applesauce, unsweetened, with added vitamin C',
    'Bananas, overripe, raw',
    'Bananas, ripe and slightly ripe, raw',
    'Blueberries, raw',
    'Cherries, sweet, dark red, raw',
    // 'Cranberry juice, not fortified, from concentrate, shelf stable',
    'Figs, dried, uncooked',
    'Grape juice, purple, with added vitamin C, from concentrate, shelf stable',
    'Grape juice, white, with added vitamin C, from concentrate, shelf stable',
    'Grapefruit juice, red, not fortified, not from concentrate, refrigerated',
    'Grapefruit juice, white, canned or bottled, unsweetened',
    'Grapes, green, seedless, raw',
    'Grapes, red, seedless, raw',
    'Kiwifruit, green, raw',
    'Melons, cantaloupe, raw',
    'Nectarines, raw',
    'Olives, green, Manzanilla, stuffed with pimiento',
    'Orange juice, no pulp, not fortified, from concentrate, refrigerated',
    'Orange juice, no pulp, not fortified, not from concentrate, refrigerated',
    'Oranges, raw, navels',
    'Peaches, yellow, raw',
    'Pears, raw, bartlett',
    'Pineapple, raw',
    'Raspberries, raw',
    'Strawberries, raw',
    'Crustaceans, crab, blue swimming, lump, pasteurized, refrigerated',
    'Crustaceans, shrimp, farm raised, raw',
    'Fish, catfish, farm raised, raw',
    'Fish, cod, Atlantic, wild caught, raw',
    'Fish, haddock, raw',
    'Fish, pollock, raw',
    'Fish, salmon, Atlantic, farm raised, raw',
    'Fish, salmon, sockeye, wild caught, raw',
    'Fish, tilapia, farm raised, raw',
    'Fish, tuna, light, canned in water, drained solids',
    'Oil, canola',
    'Oil, coconut',
    'Oil, corn',
    'Oil, olive, extra light',
    'Oil, olive, extra virgin',
    'Oil, peanut',
    'Oil, safflower',
    'Oil, soybean',
    'Oil, sunflower',
    'Butter, stick, salted',
    'Butter, stick, unsalted',
    'Buttermilk, low fat',
    'Cheese, American, restaurant',
    'Cheese, cheddar',
    'Cheese, cotija, solid',
    'Cheese, cottage, lowfat, 2% milkfat',
    'Cheese, dry white, queso seco',
    'Cheese, feta, whole milk, crumbled',
    'Cheese, monterey jack, solid',
    'Cheese, mozzarella, low moisture, part-skim',
    'Cheese, oaxaca, solid',
    'Cheese, parmesan, grated',
    'Cheese, parmesan, grated, refrigerated',
    'Cheese, pasteurized process cheese food or product, American, singles',
    'Cheese, pasteurized process, American, vitamin D fortified',
    'Cheese, provolone, sliced',
    'Cheese, queso fresco, solid',
    'Cheese, ricotta, whole milk',
    'Cheese, swiss',
    'Cottage cheese, full fat, large or small curd',
    'Cream cheese, full fat, block',
    'Cream, heavy',
    'Cream, sour, full fat',
    'Egg, white, dried',
    'Egg, white, raw, frozen, pasteurized',
    'Egg, whole, dried',
    'Egg, whole, raw, frozen, pasteurized',
    'Egg, yolk, dried',
    'Egg, yolk, raw, frozen, pasteurized',
    'Eggs, Grade A, Large, egg white',
    'Eggs, Grade A, Large, egg whole',
    'Eggs, Grade A, Large, egg yolk',
    'Milk, lowfat, fluid, 1% milkfat, with added vitamin A and vitamin D',
    'Milk, nonfat, fluid, with added vitamin A and vitamin D (fat free or skim)',
    'Milk, reduced fat, fluid, 2% milkfat, with added vitamin A and vitamin D',
    'Milk, whole, 3.25% milkfat, with added vitamin D',
    'Yogurt, Greek, plain, nonfat',
    'Yogurt, Greek, plain, whole milk',
    'Yogurt, Greek, strawberry, nonfat',
    'Yogurt, plain, nonfat',
    'Yogurt, plain, whole milk',
    'Buckwheat, whole grain',
    'Flour, 00',
    'Flour, amaranth',
    'Flour, barley',
    'Flour, bread, white, enriched, unbleached',
    'Flour, buckwheat',
    'Flour, corn, yellow, fine meal, enriched',
    'Flour, oat, whole grain',
    'Flour, pastry, unenriched, unbleached',
    'Flour, quinoa',
    'Flour, rice, brown',
    'Flour, rice, glutinous',
    'Flour, rice, white, unenriched',
    'Flour, rye',
    'Flour, semolina, coarse and semi-coarse',
    'Flour, semolina, fine',
    'Flour, sorghum',
    'Flour, spelt, whole grain',
    'Flour, wheat, all-purpose, enriched, bleached',
    'Flour, wheat, all-purpose, enriched, unbleached',
    'Flour, wheat, all-purpose, unenriched, unbleached',
    'Flour, whole wheat, unenriched',
    'Millet, whole grain',
    'Oats, whole grain, rolled, old fashioned',
    'Oats, whole grain, steel cut',
    'Rice, brown, long grain, unenriched, raw',
    'Rice, white, long grain, unenriched, raw',
    'Almond milk, unsweetened, plain, refrigerated',
    'Almond milk, unsweetened, plain, shelf stable',
    'Oat milk, unsweetened, plain, refrigerated',
    'Beef, chuck, roast, boneless, choice, raw',
    'Beef, flank, steak, boneless, choice, raw',
    'Beef, ground, 90% lean meat / 10% fat, raw',
    'Beef, ground, 80% lean meat / 20% fat, raw',
    'Beef, loin, tenderloin roast, separable lean only, boneless, trimmed to 0" fat, select, cooked, roasted',
    'Beef, loin, top loin steak, boneless, lip-on, separable lean only, trimmed to 1/8" fat, choice, raw',
    'Beef, ribeye, steak, boneless, choice, raw',
    'Beef, round, eye of round roast, boneless, separable lean only, trimmed to 0" fat, select, raw',
    'Beef, round, top round roast, boneless, separable lean only, trimmed to 0" fat, select, raw',
    'Beef, round, top round, boneless, choice, raw',
    'Beef, short loin, porterhouse steak, separable lean only, trimmed to 1/8" fat, select, raw',
    'Beef, short loin, t-bone steak, bone-in, separable lean only, trimmed to 1/8" fat, choice, cooked, grilled',
    'Bread, white, commercially prepared',
    'Bread, whole-wheat, commercially prepared',
    'Cookies, oatmeal, soft, with raisins',
    "Abiyuch, raw",
    "Acerola juice, raw",
    "Acerola, (west indian cherry), raw",
    "Acorn stew (Apache)",
    "Agave, cooked (Southwest)",
    "Agave, dried (Southwest)",
    "Agave, raw (Southwest)",
    "Agutuk, fish with shortening (Alaskan ice cream) (Alaska Native)",
    "Agutuk, fish/berry with seal oil (Alaskan ice cream) (Alaska Native)",
    "Agutuk, meat-caribou (Alaskan ice cream) (Alaska Native)",
    "Alcoholic beverage, beer, light",
    "Alcoholic beverage, beer, light, BUD LIGHT",
    "Alcoholic beverage, beer, light, BUDWEISER SELECT",
    "Alcoholic beverage, beer, light, higher alcohol",
    "Alcoholic beverage, beer, light, low carb",
    "Alcoholic beverage, beer, regular, all",
    "Alcoholic beverage, beer, regular, BUDWEISER",
    "Alcoholic beverage, creme de menthe, 72 proof",
    "Alcoholic beverage, daiquiri, canned",
    "Alcoholic beverage, daiquiri, prepared-from-recipe",
    "Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 100 proof",
    "Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 80 proof",
    "Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 86 proof",
    "Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 90 proof",
    "Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 94 proof",
    "Alcoholic beverage, distilled, rum, 80 proof",
    "Alcoholic beverage, distilled, vodka, 80 proof",
    "Alcoholic beverage, distilled, whiskey, 86 proof",
    "Alcoholic beverage, liqueur, coffee with cream, 34 proof",
    "Alcoholic beverage, liqueur, coffee, 53 proof",
    "Alcoholic beverage, liqueur, coffee, 63 proof",
    "Alcoholic beverage, malt beer, hard lemonade",
    "Alcoholic beverage, pina colada, canned",
    "Alcoholic beverage, pina colada, prepared-from-recipe",
    "Alcoholic beverage, rice (sake)",
    "Alcoholic beverage, tequila sunrise, canned",
    "Alcoholic beverage, whiskey sour",
    "Alcoholic beverage, whiskey sour, canned",
    "Alcoholic beverage, whiskey sour, prepared from item 14028",
    "Alcoholic beverage, whiskey sour, prepared with water, whiskey and powder mix",
    "Alcoholic beverage, wine, cooking",
    "Alcoholic beverage, wine, dessert, dry",
    "Alcoholic beverage, wine, dessert, sweet",
    "Alcoholic beverage, wine, light",
    "Alcoholic beverage, wine, table, all",
    "Alcoholic beverage, wine, table, red",
    "Alcoholic Beverage, wine, table, red, Barbera",
    "Alcoholic Beverage, wine, table, red, Burgundy",
    "Alcoholic Beverage, wine, table, red, Cabernet Franc",
    "Alcoholic Beverage, wine, table, red, Cabernet Sauvignon",
    "Alcoholic Beverage, wine, table, red, Carignane",
    "Alcoholic Beverage, wine, table, red, Claret",
    "Alcoholic Beverage, wine, table, red, Gamay",
    "Alcoholic Beverage, wine, table, red, Lemberger",
    "Alcoholic Beverage, wine, table, red, Merlot",
    "Alcoholic Beverage, wine, table, red, Mouvedre",
    "Alcoholic Beverage, wine, table, red, Petite Sirah",
    "Alcoholic Beverage, wine, table, red, Pinot Noir",
    "Alcoholic Beverage, wine, table, red, Sangiovese",
    "Alcoholic Beverage, wine, table, red, Syrah",
    "Alcoholic Beverage, wine, table, red, Zinfandel",
    "Alcoholic beverage, wine, table, white",
    "Alcoholic beverage, wine, table, white, Chardonnay",
    "Alcoholic beverage, wine, table, white, Chenin Blanc",
    "Alcoholic beverage, wine, table, white, Fume Blanc",
    "Alcoholic beverage, wine, table, white, Gewurztraminer",
    "Alcoholic beverage, wine, table, white, late harvest",
    "Alcoholic beverage, wine, table, white, late harvest, Gewurztraminer",
    "Alcoholic beverage, wine, table, white, Muller Thurgau",
    "Alcoholic beverage, wine, table, white, Muscat",
    "Alcoholic beverage, wine, table, white, Pinot Blanc",
    "Alcoholic beverage, wine, table, white, Pinot Gris (Grigio)",
    "Alcoholic beverage, wine, table, white, Riesling",
    "Alcoholic beverage, wine, table, white, Sauvignon Blanc",
    "Alcoholic beverage, wine, table, white, Semillon",
    "Alcoholic beverages, beer, higher alcohol",
    "Alcoholic beverages, wine, rose",
    "Alfalfa seeds, sprouted, raw",
    "Amaranth grain, cooked",
    "Amaranth grain, uncooked",
    "Amaranth leaves, cooked, boiled, drained, with salt",
    "Amaranth leaves, cooked, boiled, drained, without salt",
    "Amaranth leaves, raw",
    "Andrea's, Gluten Free Soft Dinner Roll",
    "Animal fat, bacon grease",
    "Apple juice, canned or bottled, unsweetened, with added ascorbic acid",
    "Apple juice, canned or bottled, unsweetened, with added ascorbic acid, calcium, and potassium",
    "Apple juice, canned or bottled, unsweetened, without added ascorbic acid",
    "Apple juice, frozen concentrate, unsweetened, diluted with 3 volume water without added ascorbic acid",
    "Apple juice, frozen concentrate, unsweetened, diluted with 3 volume water, with added ascorbic acid",
    "Apple juice, frozen concentrate, unsweetened, undiluted, with added ascorbic acid",
    "Apple juice, frozen concentrate, unsweetened, undiluted, without added ascorbic acid",
    "APPLEBEE'S, 9 oz house sirloin steak",
    "APPLEBEE'S, chicken tenders platter",
    "APPLEBEE'S, chicken tenders, from kids' menu",
    "APPLEBEE'S, crunchy onion rings",
    "APPLEBEE'S, Double Crunch Shrimp",
    "APPLEBEE'S, fish, hand battered",
    "APPLEBEE'S, french fries",
    "APPLEBEE'S, KRAFT, Macaroni & Cheese, from kid's menu",
    "APPLEBEE'S, mozzarella sticks",
    "Apples, canned, sweetened, sliced, drained, heated",
    "Apples, dehydrated (low moisture), sulfured, stewed",
    "Apples, dehydrated (low moisture), sulfured, uncooked",
    "Apples, dried, sulfured, stewed, with added sugar",
    "Apples, dried, sulfured, stewed, without added sugar",
    "Apples, dried, sulfured, uncooked",
    "Apples, frozen, unsweetened, heated (Includes foods for USDA's Food Distribution Program)",
    "Apples, raw, fuji, with skin (Includes foods for USDA's Food Distribution Program)",
    "Apples, raw, gala, with skin (Includes foods for USDA's Food Distribution Program)",
    "Apples, raw, golden delicious, with skin",
    "Apples, raw, granny smith, with skin (Includes foods for USDA's Food Distribution Program)",
    "Apples, raw, red delicious, with skin (Includes foods for USDA's Food Distribution Program)",
    "Apples, raw, with skin (Includes foods for USDA's Food Distribution Program)",
    "Apples, raw, without skin",
    "Apples, raw, without skin, cooked, boiled",
    "Apples, raw, without skin, cooked, microwave",
    "Applesauce, canned, sweetened, with salt",
    "Applesauce, canned, sweetened, without salt",
    "Applesauce, canned, unsweetened, with added ascorbic acid",
    "Applesauce, canned, unsweetened, without added ascorbic acid (Includes foods for USDA's Food Distribution Program)",
    "Apricot nectar, canned, with added ascorbic acid",
    "Apricots, canned, extra heavy syrup pack, without skin, solids and liquids",
    "Apricots, canned, extra light syrup pack, with skin, solids and liquids (Includes foods for USDA's Food Distribution Program)",
    "Apricots, canned, heavy syrup pack, with skin, solids and liquids",
    "Apricots, canned, heavy syrup pack, without skin, solids and liquids",
    "Apricots, canned, heavy syrup, drained",
    "Apricots, canned, juice pack, with skin, solids and liquids",
    "Apricots, canned, light syrup pack, with skin, solids and liquids",
    "Apricots, canned, water pack, with skin, solids and liquids",
    "Apricots, canned, water pack, without skin, solids and liquids",
    "Apricots, dehydrated (low-moisture), sulfured, stewed",
    "Apricots, dehydrated (low-moisture), sulfured, uncooked",
    "Apricots, dried, sulfured, stewed, with added sugar",
    "Apricots, dried, sulfured, stewed, without added sugar",
    "Apricots, dried, sulfured, uncooked",
    "Apricots, frozen, sweetened",
    "Apricots, raw",
    "ARBY'S, roast beef sandwich, classic",
    "Archway Home Style Cookies, Chocolate Chip Ice Box",
    "Archway Home Style Cookies, Coconut Macaroon",
    "Archway Home Style Cookies, Date Filled Oatmeal",
    "Archway Home Style Cookies, Dutch Cocoa",
    "Archway Home Style Cookies, Frosty Lemon",
    "Archway Home Style Cookies, Iced Molasses",
    "Archway Home Style Cookies, Iced Oatmeal",
    "Archway Home Style Cookies, Molasses",
    "Archway Home Style Cookies, Oatmeal",
    "Archway Home Style Cookies, Oatmeal",
    "Archway Home Style Cookies, Oatmeal Raisin",
    "Archway Home Style Cookies, Old Fashioned Molasses",
    "Archway Home Style Cookies, Old Fashioned Windmill Cookies",
    "Archway Home Style Cookies, Peanut Butter",
    "Archway Home Style Cookies, Raspberry Filled",
    "Archway Home Style Cookies, Reduced Fat Ginger Snaps",
    "Archway Home Style Cookies, Strawberry Filled",
    "Archway Home Style Cookies, Sugar Free Oatmeal",
    "Arrowhead, cooked, boiled, drained, with salt",
    "Arrowhead, cooked, boiled, drained, without salt",
    "Arrowhead, raw",
    "Arrowroot flour",
    "Arrowroot, raw",
    "Artichokes, (globe or french), cooked, boiled, drained, with salt",
    "Artichokes, (globe or french), cooked, boiled, drained, without salt",
    "Artichokes, (globe or french), frozen, cooked, boiled, drained, with salt",
    "Artichokes, (globe or french), frozen, cooked, boiled, drained, without salt",
    "Artichokes, (globe or french), frozen, unprepared",
    "Artichokes, (globe or french), raw",
    "Artificial Blueberry Muffin Mix, dry",
    "Arugula, raw",
    "Ascidians (tunughnak) (Alaska Native)",
    "Asparagus, canned, drained solids",
    "Asparagus, canned, no salt added, solids and liquids",
    "Asparagus, canned, regular pack, solids and liquids",
    "Asparagus, cooked, boiled, drained",
    "Asparagus, cooked, boiled, drained, with salt",
    "Asparagus, frozen, cooked, boiled, drained, with salt",
    "Asparagus, frozen, cooked, boiled, drained, without salt",
    "Asparagus, frozen, unprepared",
    "Asparagus, raw",
    "Avocados, raw, all commercial varieties",
    "Avocados, raw, California",
    "Avocados, raw, Florida",
    "Babyfood, apple yogurt dessert, strained",
    "Babyfood, apple-banana juice",
    "Babyfood, apples with ham, strained",
    "Babyfood, apples, dices, toddler",
    "Babyfood, Baby MUM MUM Rice Biscuits",
    "Babyfood, baked product, finger snacks cereal fortified",
    "Babyfood, banana apple dessert, strained",
    "Babyfood, banana juice with low fat yogurt",
    "Babyfood, banana no tapioca, strained",
    "Babyfood, banana with mixed berries, strained",
    "Babyfood, beverage, GERBER, GRADUATES, FRUIT SPLASHERS",
    "Babyfood, carrots and beef, strained",
    "Babyfood, carrots, toddler",
    "Babyfood, cereal, barley, dry fortified",
    "Babyfood, cereal, barley, prepared with whole milk",
    "Babyfood, cereal, brown rice, dry, instant",
    "Babyfood, cereal, egg yolks and bacon, junior",
    "Babyfood, cereal, high protein, prepared with whole milk",
    "Babyfood, cereal, high protein, with apple and orange, dry",
    "Babyfood, cereal, high protein, with apple and orange, prepared with whole milk",
    "Babyfood, cereal, mixed, dry fortified",
    "Babyfood, cereal, mixed, prepared with whole milk",
    "Babyfood, cereal, mixed, with applesauce and bananas, junior, fortified",
    "Babyfood, cereal, mixed, with applesauce and bananas, strained",
    "Babyfood, cereal, mixed, with bananas, dry",
    "Babyfood, cereal, mixed, with bananas, prepared with whole milk",
    "Babyfood, cereal, mixed, with honey, prepared with whole milk",
    "Babyfood, cereal, oatmeal, dry fortified",
    "Babyfood, cereal, Oatmeal, dry, GERBER, SINGLE GRAIN, fortified",
    "Babyfood, cereal, oatmeal, prepared with whole milk",
    "Babyfood, cereal, rice, dry fortified",
    "Babyfood, cereal, rice, prepared with whole milk",
    "Babyfood, cereal, rice, with applesauce and bananas, strained",
    "Babyfood, cereal, rice, with bananas, dry",
    "Babyfood, cereal, rice, with bananas, prepared with whole milk",
    "Babyfood, cereal, rice, with honey, prepared with whole milk",
    "Babyfood, cereal, whole wheat, with apples, dry fortified",
    "Babyfood, cereal, with egg yolks, junior",
    "Babyfood, cereal, with egg yolks, strained",
    "Babyfood, cereal, with eggs, strained",
    "Babyfood, cherry cobbler, junior",
    "Babyfood, cookie, baby, fruit",
    "Babyfood, cookies",
    "Babyfood, cookies, arrowroot",
    "Babyfood, corn and sweet potatoes, strained",
    "Babyfood, crackers, vegetable",
    "Babyfood, dessert, banana pudding, strained",
    "Babyfood, dessert, banana yogurt, strained",
    "Babyfood, dessert, blueberry yogurt, strained",
    "Babyfood, dessert, cherry vanilla pudding, junior",
    "Babyfood, dessert, cherry vanilla pudding, strained",
    "Babyfood, dessert, dutch apple, junior",
    "Babyfood, dessert, dutch apple, strained",
    "Babyfood, dessert, fruit dessert, without ascorbic acid, junior",
    "Babyfood, dessert, fruit dessert, without ascorbic acid, strained",
    "Babyfood, dessert, fruit pudding, orange, strained",
    "Babyfood, dessert, fruit pudding, pineapple, strained",
    "Babyfood, dessert, peach cobbler, junior",
    "Babyfood, dessert, peach cobbler, strained",
    "Babyfood, dessert, peach melba, junior",
    "Babyfood, dessert, peach melba, strained",
    "Babyfood, dessert, peach yogurt",
    "Babyfood, dessert, tropical fruit, junior",
    "Babyfood, dinner, apples and chicken, strained",
    "Babyfood, dinner, beef and rice, toddler",
    "Babyfood, dinner, beef lasagna, toddler",
    "Babyfood, dinner, beef noodle, junior",
    "Babyfood, dinner, beef noodle, strained",
    "Babyfood, dinner, beef with vegetables",
    "Babyfood, dinner, broccoli and chicken, junior",
    "Babyfood, dinner, chicken and rice",
    "Babyfood, dinner, chicken noodle, junior",
    "Babyfood, dinner, chicken noodle, strained",
    "Babyfood, dinner, chicken soup, strained",
    "Babyfood, dinner, chicken stew, toddler",
    "Babyfood, dinner, macaroni and cheese, junior",
    "Babyfood, dinner, macaroni and cheese, strained",
    "Babyfood, dinner, macaroni and tomato and beef, junior",
    "Babyfood, dinner, macaroni and tomato and beef, strained",
    "Babyfood, dinner, macaroni, beef and tomato sauce, toddler",
    "Babyfood, dinner, mixed vegetable, junior",
    "Babyfood, dinner, mixed vegetable, strained",
    "Babyfood, dinner, pasta with vegetables",
    "Babyfood, dinner, spaghetti and tomato and meat, junior",
    "Babyfood, dinner, spaghetti and tomato and meat, toddler",
    "Babyfood, dinner, sweet potatoes and chicken, strained",
    "Babyfood, dinner, turkey and rice, junior",
    "Babyfood, dinner, turkey and rice, strained",
    "Babyfood, dinner, turkey, rice, and vegetables, toddler",
    "Babyfood, dinner, vegetables and beef, junior",
    "Babyfood, dinner, vegetables and beef, strained",
    "Babyfood, dinner, vegetables and chicken, junior",
    "Babyfood, dinner, vegetables and dumplings and beef, junior",
    "Babyfood, dinner, vegetables and dumplings and beef, strained",
    "Babyfood, dinner, vegetables and lamb, junior",
    "Babyfood, dinner, vegetables and noodles and turkey, junior",
    "Babyfood, dinner, vegetables and noodles and turkey, strained",
    "Babyfood, dinner, vegetables and turkey, junior",
    "Babyfood, dinner, vegetables and turkey, strained",
    "Babyfood, dinner, vegetables chicken, strained",
    "Babyfood, dinner, vegetables, noodles and chicken, junior",
    "Babyfood, dinner, vegetables, noodles and chicken, strained",
    "Babyfood, finger snacks, GERBER, GRADUATES, PUFFS, apple and cinnamon",
    "Babyfood, fortified cereal bar, fruit filling",
    "Babyfood, fruit and vegetable, apple and sweet potato",
    "Babyfood, fruit dessert, mango with tapioca",
    "Babyfood, fruit supreme dessert",
    "Babyfood, fruit, apple and blueberry, junior",
    "Babyfood, fruit, apple and blueberry, strained",
    "Babyfood, fruit, apple and raspberry, junior",
    "Babyfood, fruit, apple and raspberry, strained",
    "Babyfood, fruit, applesauce and apricots, junior",
    "Babyfood, fruit, applesauce and apricots, strained",
    "Babyfood, fruit, applesauce and cherries, junior",
    "Babyfood, fruit, applesauce and cherries, strained",
    "Babyfood, fruit, applesauce and pineapple, junior",
    "Babyfood, fruit, applesauce and pineapple, strained",
    "Babyfood, fruit, applesauce with banana, junior",
    "Babyfood, fruit, applesauce, junior",
    "Babyfood, fruit, applesauce, strained",
    "Babyfood, fruit, apricot with tapioca, junior",
    "Babyfood, fruit, apricot with tapioca, strained",
    "Babyfood, fruit, banana and strawberry, junior",
    "Babyfood, fruit, bananas and pineapple with tapioca, junior",
    "Babyfood, fruit, bananas and pineapple with tapioca, strained",
    "Babyfood, fruit, bananas with apples and pears, strained",
    "Babyfood, fruit, bananas with tapioca, junior",
    "Babyfood, fruit, bananas with tapioca, strained",
    "Babyfood, fruit, guava and papaya with tapioca, strained",
    "Babyfood, fruit, papaya and applesauce with tapioca, strained",
    "Babyfood, fruit, peaches, junior",
    "Babyfood, fruit, peaches, strained",
    "Babyfood, fruit, pears and pineapple, junior",
    "Babyfood, fruit, pears and pineapple, strained",
    "Babyfood, fruit, pears, junior",
    "Babyfood, fruit, pears, strained",
    "Babyfood, fruit, plums with tapioca, without ascorbic acid, junior",
    "Babyfood, fruit, plums with tapioca, without ascorbic acid, strained",
    "Babyfood, fruit, prunes with tapioca, without ascorbic acid, junior",
    "Babyfood, fruit, prunes with tapioca, without ascorbic acid, strained",
    "Babyfood, fruit, tutti frutti, junior",
    "Babyfood, fruit, tutti frutti, strained",
    "Babyfood, GERBER, 2nd Foods, apple, carrot and squash, organic",
    "Babyfood, GERBER, 3rd Foods, apple, mango and kiwi",
    "Babyfood, GERBER, Banana with orange medley",
    "Babyfood, GERBER, GRADUATES Lil Biscuits Vanilla Wheat",
    "Babyfood, grape juice, no sugar, canned",
    "Babyfood, green beans and turkey, strained",
    "Babyfood, green beans, dices, toddler",
    "Babyfood, juice treats, fruit medley, toddler",
    "Babyfood, juice, apple",
    "Babyfood, juice, apple - cherry",
    "Babyfood, juice, apple and grape",
    "Babyfood, juice, apple and peach",
    "Babyfood, juice, apple and prune",
    "Babyfood, juice, apple, with calcium",
    "Babyfood, juice, apple-sweet potato",
    "Babyfood, juice, fruit punch, with calcium",
    "Babyfood, juice, mixed fruit",
    "Babyfood, juice, orange",
    "Babyfood, juice, orange and apple",
    "Babyfood, juice, orange and apple and banana",
    "Babyfood, juice, orange and apricot",
    "Babyfood, juice, orange and banana",
    "Babyfood, juice, orange and pineapple",
    "Babyfood, juice, orange-carrot",
    "Babyfood, juice, pear",
    "Babyfood, juice, prune and orange",
    "Babyfood, macaroni and cheese, toddler",
    "Babyfood, mashed cheddar potatoes and broccoli, toddlers",
    "Babyfood, meat, beef with vegetables, toddler",
    "Babyfood, meat, beef, junior",
    "Babyfood, meat, beef, strained",
    "Babyfood, meat, chicken sticks, junior",
    "Babyfood, meat, chicken, junior",
    "Babyfood, meat, chicken, strained",
    "Babyfood, meat, ham, junior",
    "Babyfood, meat, ham, strained",
    "Babyfood, meat, lamb, junior",
    "Babyfood, meat, lamb, strained",
    "Babyfood, meat, meat sticks, junior",
    "Babyfood, meat, pork, strained",
    "Babyfood, meat, turkey sticks, junior",
    "Babyfood, meat, turkey, junior",
    "Babyfood, meat, turkey, strained",
    "Babyfood, meat, veal, strained",
    "Babyfood, mixed fruit juice with low fat yogurt",
    "Babyfood, mixed fruit yogurt, strained",
    "Babyfood, Multigrain whole grain cereal, dry fortified",
    "Babyfood, oatmeal cereal with fruit, dry, instant, toddler fortified",
    "Babyfood, peaches, dices, toddler",
    "Babyfood, pears, dices, toddler",
    "Babyfood, peas and brown rice",
    "Babyfood, peas, dices, toddler",
    "Babyfood, plums, bananas and rice, strained",
    "Babyfood, potatoes, toddler",
    "Babyfood, pretzels",
    "Babyfood, prunes, without vitamin c, strained",
    "Babyfood, ravioli, cheese filled, with tomato sauce",
    "Babyfood, rice and apples, dry",
    "Babyfood, rice cereal, dry, EARTHS BEST ORGANIC WHOLE GRAIN, fortified only with iron",
    "Babyfood, snack, GERBER GRADUATE FRUIT STRIPS, Real Fruit Bars",
    "Babyfood, Snack, GERBER, GRADUATES, LIL CRUNCHIES, baked whole grain corn snack",
    "Babyfood, snack, GERBER, GRADUATES, YOGURT MELTS",
    "Babyfood, tropical fruit medley",
    "Babyfood, vegetable and brown rice, strained",
    "Balsam-pear (bitter gourd), pods, raw",
    "Bamboo shoots, canned, drained solids",
    "Bamboo shoots, cooked, boiled, drained, with salt",
    "Bamboo shoots, cooked, boiled, drained, without salt",
    "Bamboo shoots, raw",
    "Bananas, dehydrated, or banana powder",
    "Bananas, raw",
    "BANQUET, Salisbury Steak With Gravy, family size, frozen, unprepared",
    "Baobab powder",
    "Barbecue loaf, pork, beef",
    "Barley flour or meal",
    "Barley malt flour",
    "Barley, hulled",
    "Barley, pearled, cooked",
    "Barley, pearled, raw",
    "Basil, fresh",
    "Beans, adzuki, mature seed, cooked, boiled, with salt",
    "Beans, adzuki, mature seeds, canned, sweetened",
    "Beans, adzuki, mature seeds, cooked, boiled, without salt",
    "Beans, adzuki, mature seeds, raw",
    "Beans, baked, canned, no salt added",
    "Beans, baked, canned, plain or vegetarian",
    "Beans, baked, canned, with beef",
    "Beans, baked, canned, with franks",
    "Beans, baked, canned, with pork",
    "Beans, baked, canned, with pork and sweet sauce",
    "Beans, baked, canned, with pork and tomato sauce",
    "Beans, baked, home prepared",
    "Beans, black turtle, mature seeds, canned",
    "Beans, black turtle, mature seeds, cooked, boiled, with salt",
    "Beans, black turtle, mature seeds, cooked, boiled, without salt",
    "Beans, black turtle, mature seeds, raw",
    "Beans, black, mature seeds, canned, low sodium",
    "Beans, black, mature seeds, cooked, boiled, with salt",
    "Beans, black, mature seeds, cooked, boiled, without salt",
    "Beans, black, mature seeds, raw",
    "Beans, chili, barbecue, ranch style, cooked",
    "Beans, cranberry (roman), mature seeds, canned",
    "Beans, cranberry (roman), mature seeds, cooked, boiled, with salt",
    "Beans, cranberry (roman), mature seeds, cooked, boiled, without salt",
    "Beans, cranberry (roman), mature seeds, raw",
    "Beans, fava, in pod, raw",
    "Beans, french, mature seeds, cooked, boiled, with salt",
    "Beans, french, mature seeds, cooked, boiled, without salt",
    "Beans, french, mature seeds, raw",
    "Beans, great northern, mature seeds, canned",
    "Beans, great northern, mature seeds, canned, low sodium",
    "Beans, great northern, mature seeds, cooked, boiled, with salt",
    "Beans, great northern, mature seeds, cooked, boiled, without salt",
    "Beans, great northern, mature seeds, raw",
    "Beans, pink, mature seeds, raw",
    "Beans, pinto, canned, drained solids",
    "Beans, pinto, immature seeds, frozen, cooked, boiled, drained, with salt",
    "Beans, pinto, immature seeds, frozen, cooked, boiled, drained, without salt",
    "Beans, pinto, immature seeds, frozen, unprepared",
    "Beans, pinto, mature seeds, canned, drained solids, rinsed in tap water",
    "Beans, pinto, mature seeds, canned, solids and liquids",
    "Beans, pinto, mature seeds, canned, solids and liquids, low sodium",
    "Beans, pinto, mature seeds, cooked, boiled, with salt",
    "Beans, pinto, mature seeds, cooked, boiled, without salt",
    "Beans, pinto, mature seeds, raw (Includes foods for USDA's Food Distribution Program)",
    "Beans, pinto, mature seeds, sprouted, cooked, boiled, drained, with salt",
    "Beans, pinto, mature seeds, sprouted, cooked, boiled, drained, without salt",
    "Beans, pinto, mature seeds, sprouted, raw",
    "Beans, shellie, canned, solids and liquids",
    "Beans, small white, mature seeds, cooked, boiled, with salt",
    "Beans, small white, mature seeds, cooked, boiled, without salt",
    "Beans, small white, mature seeds, raw",
    "Beans, snap, canned, all styles, seasoned, solids and liquids",
    "Beans, snap, green, canned, no salt added, drained solids",
    "Beans, snap, green, canned, no salt added, solids and liquids",
    "Beans, snap, green, canned, regular pack, drained solids",
    "Beans, snap, green, canned, regular pack, solids and liquids",
    "Beans, snap, green, cooked, boiled, drained, with salt",
    "Beans, snap, green, cooked, boiled, drained, without salt",
    "Beans, snap, green, frozen, all styles, microwaved",
    "Beans, snap, green, frozen, all styles, unprepared (Includes foods for USDA's Food Distribution Program)",
    "Beans, snap, green, frozen, cooked, boiled, drained without salt",
    "Beans, snap, green, frozen, cooked, boiled, drained, with salt",
    "Beans, snap, green, microwaved",
    "Beans, snap, green, raw",
    "Beans, snap, yellow, canned, no salt added, drained solids",
    "Beans, snap, yellow, canned, no salt added, solids and liquids",
    "Beans, snap, yellow, canned, regular pack, drained solids",
    "Beans, snap, yellow, canned, regular pack, solids and liquids",
    "Beans, snap, yellow, cooked, boiled, drained, with salt",
    "Beans, snap, yellow, cooked, boiled, drained, without salt",
    "Beans, snap, yellow, frozen, all styles, unprepared",
    "Beans, snap, yellow, frozen, cooked, boiled, drained, with salt",
    "Beans, snap, yellow, frozen, cooked, boiled, drained, without salt",
    "Beans, snap, yellow, raw",
    "Beans, white, mature seeds, canned",
    "Beans, white, mature seeds, cooked, boiled, with salt",
    "Beans, white, mature seeds, cooked, boiled, without salt",
    "Beans, white, mature seeds, raw",
    "Beans, yellow, mature seeds, cooked, boiled, with salt",
    "Beans, yellow, mature seeds, cooked, boiled, without salt",
    "Beans, yellow, mature seeds, raw",
    "Bear, black, meat (Alaska Native)",
    "Bear, polar, meat, raw (Alaska Native)",
    "Beef composite, separable lean only, trimmed to 1/8\" fat, choice, cooked",
    "Beef macaroni with tomato sauce, frozen entree, reduced fat",
    "Beef Pot Pie, frozen entree, prepared",
    "Beef stew, canned entree",
    "Beef, Australian, imported, grass-fed, external fat, raw",
    "Beef, Australian, imported, grass-fed, ground, 85% lean / 15% fat, raw",
    "Beef, Australian, imported, grass-fed, loin, tenderloin steak/roast, boneless, separable lean and fat, raw",
    "Beef, Australian, imported, grass-fed, loin, tenderloin steak/roast, boneless, separable lean only, raw",
    "Beef, Australian, imported, grass-fed, loin, top loin steak/roast, boneless, separable lean and fat, raw",
    "Beef, Australian, imported, grass-fed, loin, top loin steak/roast, boneless, separable lean only, raw",
    "Beef, Australian, imported, grass-fed, loin, top sirloin cap-off steak/roast, boneless, separable lean and fat, raw",
    "Beef, Australian, imported, grass-fed, loin, top sirloin cap-off steak/roast, boneless, separable lean only, raw",
    "Beef, Australian, imported, grass-fed, rib, ribeye steak/roast lip-on, boneless, separable lean and fat, raw",
    "Beef, Australian, imported, grass-fed, rib, ribeye steak/roast lip-on, boneless, separable lean only, raw",
    "Beef, Australian, imported, grass-fed, round, bottom round steak/roast, boneless, separable lean and fat, raw",
    "Beef, Australian, imported, grass-fed, round, bottom round steak/roast, boneless, separable lean only, raw",
    "Beef, Australian, imported, grass-fed, round, top round cap-off steak/roast, boneless, separable lean and fat, raw",
    "Beef, Australian, imported, grass-fed, round, top round cap-off steak/roast, boneless, separable lean only, raw",
    "Beef, Australian, imported, grass-fed, seam fat, raw",
    "Beef, Australian, imported, Wagyu, external fat, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, external fat, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, loin, tenderloin steak/roast, boneless, separable lean and fat, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, loin, tenderloin steak/roast, boneless, separable lean and fat, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, loin, tenderloin steak/roast, boneless, separable lean only, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, loin, tenderloin steak/roast, boneless, separable lean only, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, loin, top loin steak/roast, boneless, separable lean and fat, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, loin, top loin steak/roast, boneless, separable lean only, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, loin, top loin steak/roast, boneless, separable lean only, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, loin, top loin steak/roast, separable lean and fat, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, rib, small end rib steak/roast, boneless, separable lean and fat, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, rib, small end rib steak/roast, boneless, separable lean and fat, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, rib, small end rib steak/roast, boneless, separable lean only, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, rib, small end rib steak/roast, boneless, separable lean only, Aust. marble score 9, raw",
    "Beef, Australian, imported, Wagyu, seam fat, Aust. marble score 4/5, raw",
    "Beef, Australian, imported, Wagyu, seam fat, Aust. marble score 9, raw",
    "Beef, bologna, reduced sodium",
    "Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0\" fat, all grades, cooked, roasted",
    "Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0\" fat, all grades, raw",
    "Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0\" fat, choice, cooked, roasted",
    "Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0\" fat, choice, raw",
    "Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0\" fat, select, cooked, roasted",
    "Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0\" fat, select, raw",
    "Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0\" fat, all grades, raw",
    "Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0\" fat, choice, cooked, roasted",
    "Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0\" fat, choice, raw",
    "Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0\" fat, select, cooked, roasted",
    "Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0\" fat, select, raw",
    "Beef, brisket, flat half, boneless separable lean only, trimmed to 0\" fat, all grades, raw",
    "Beef, brisket, flat half, boneless, separable lean and fat, trimmed to 0\" fat, all grades, raw",
    "Beef, brisket, flat half, boneless, separable lean and fat, trimmed to 0\" fat, choice, raw",
    "Beef, brisket, flat half, boneless, separable lean and fat, trimmed to 0\" fat, select, raw",
    "Beef, brisket, flat half, boneless, separable lean only, trimmed to 0\" fat, choice, raw",
    "Beef, brisket, flat half, boneless, separable lean only, trimmed to 0\" fat, select, raw",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 0\" fat, select, cooked, braised",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 1/8\" fat, all grades, raw",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, braised",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 1/8\" fat, select, cooked, braised",
    "Beef, brisket, flat half, separable lean and fat, trimmed to 1/8\" fat, select, raw",
    "Beef, brisket, flat half, separable lean only, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, brisket, flat half, separable lean only, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, brisket, flat half, separable lean only, trimmed to 0\" fat, select, cooked, braised",
    "Beef, brisket, flat half, separable lean only, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, brisket, flat half, separable lean only, trimmed to 1/8\" fat, all grades, raw",
    "Beef, brisket, flat half, separable lean only, trimmed to 1/8\" fat, choice, cooked, braised",
    "Beef, brisket, flat half, separable lean only, trimmed to 1/8\" fat, choice, raw",
    "Beef, brisket, flat half, separable lean only, trimmed to 1/8\" fat, select, cooked, braised",
    "Beef, brisket, flat half, separable lean only, trimmed to 1/8\" fat, select, raw",
    "Beef, brisket, point half, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, brisket, point half, separable lean and fat, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, brisket, point half, separable lean and fat, trimmed to 1/8\" fat, all grades, raw",
    "Beef, brisket, point half, separable lean only, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, brisket, whole, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, brisket, whole, separable lean and fat, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, brisket, whole, separable lean and fat, trimmed to 1/8\" fat, all grades, raw",
    "Beef, brisket, whole, separable lean only, all grades, raw",
    "Beef, brisket, whole, separable lean only, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, carcass, separable lean and fat, choice, raw",
    "Beef, carcass, separable lean and fat, select, raw",
    "Beef, chopped, cured, smoked",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0\" fat, choice, raw",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0\" fat, select, cooked, braised",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0\" fat, select, raw",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0\" fat, choice, raw",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0\" fat, select, cooked, braised",
    "Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0\" fat, select, raw",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0\" fat, all grades, cooked, roasted",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0\" fat, choice, cooked, roasted",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0\" fat, choice, raw",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0\" fat, select, cooked, roasted",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0\" fat, select, raw",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0\" fat, all grades, cooked, roasted",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0\" fat, choice, cooked, roasted",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0\" fat, choice, raw",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0\" fat, select, cooked, roasted",
    "Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0\" fat, select, raw",
    "Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0\" fat, all grades, cooked, grilled",
    "Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0\" fat, choice, cooked, grilled",
    "Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0\" fat, choice, raw",
    "Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0\" fat, select, cooked, grilled",
    "Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0\" fat, select, raw",
    "Beef, chuck eye steak, boneless, separable lean only, trimmed to 0\" fat, all grades, cooked, grilled",
    "Beef, chuck eye steak, boneless, separable lean only, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck eye steak, boneless, separable lean only, trimmed to 0\" fat, choice, cooked, grilled",
    "Beef, chuck eye steak, boneless, separable lean only, trimmed to 0\" fat, choice, raw",
    "Beef, chuck eye steak, boneless, separable lean only, trimmed to 0\" fat, select, cooked, grilled",
    "Beef, chuck eye steak, boneless, separable lean only, trimmed to 0\" fat, select, raw",
    "Beef, chuck for stew, separable lean and fat, all grades, cooked, braised",
    "Beef, chuck for stew, separable lean and fat, all grades, raw",
    "Beef, chuck for stew, separable lean and fat, choice, cooked, braised",
    "Beef, chuck for stew, separable lean and fat, choice, raw",
    "Beef, chuck for stew, separable lean and fat, select, cooked, braised",
    "Beef, chuck for stew, separable lean and fat, select, raw",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 0\" fat, select, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8\" fat, all grades, raw",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8\" fat, select, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8\" fat, select, raw",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 0\" fat, select, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8\" fat, all grades, raw",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8\" fat, choice, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8\" fat, choice, raw",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8\" fat, select, cooked, braised",
    "Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8\" fat, select, raw",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8\" fat, all grades, cooked, braised",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8\" fat, all grades, raw",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8\" fat, choice, cooked, braised",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8\" fat, choice, raw",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8\" fat, select, cooked, braised",
    "Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8\" fat, select, raw",
    "Beef, chuck, blade roast, separable lean only, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck, clod roast, separable lean and fat, trimmed to 0\" fat, all grades, cooked, roasted",
    "Beef, chuck, clod roast, separable lean and fat, trimmed to 0\" fat, choice, cooked, roasted",
    "Beef, chuck, clod roast, separable lean and fat, trimmed to 0\" fat, select, cooked, roasted",
    "Beef, chuck, clod roast, separable lean only, trimmed to 0\" fat, all grades, cooked, roasted",
    "Beef, chuck, clod roast, separable lean only, trimmed to 0\" fat, choice, cooked, roasted",
    "Beef, chuck, clod roast, separable lean only, trimmed to 0\" fat, select, cooked, roasted",
    "Beef, chuck, clod roast, separable lean only, trimmed to 1/4\" fat, all grades, cooked, roasted",
    "Beef, chuck, clod roast, separable lean only, trimmed to 1/4\" fat, all grades, raw",
    "Beef, chuck, clod steak, separable lean only, trimmed to 1/4\" fat, all grades, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0\" fat, choice, raw",
    "Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0\" fat, select, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0\" fat, select, raw",
    "Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0\" fat, all grades, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0\" fat, all grades, raw",
    "Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0\" fat, choice, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0\" fat, choice, raw",
    "Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0\" fat, select, cooked, braised",
    "Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, mock tender steak, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, broiled",
    "Beef, chuck, mock tender steak, separable lean and fat, trimmed to 0%22 fat, USDA choice, cooked, broiled",
    "Beef, chuck, mock tender steak, separable lean and fat, trimmed to 0%22 fat, USDA select, cooked, broiled",
    "Beef, chuck, mock tender steak, separable lean only, trimmed to 0%22 fat, all grades, cooked, broiled",
    "Beef, chuck, mock tender steak, separable lean only, trimmed to 0%22 fat, choice, cooked, broiled",
    "Beef, chuck, mock tender steak, separable lean only, trimmed to 0%22 fat, select, cooked, broiled",
    "Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, braised",
    "Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0%22 fat, select, cooked, braised",
    "Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0%22 fat, all grades, cooked, braised",
    "Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0%22 fat, select, cooked, braised",
    "Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, grilled",
    "Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0%22 fat, choice, cooked, grilled",
    "Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0%22 fat, select, cooked, grilled",
    "Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, grilled",
    "Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0%22 fat, choice, cooked, grilled",
    "Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0%22 fat, select, cooked, grilled",
    "Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, grilled",
    "Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0%22 fat, choice, cooked, grilled",
    "Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0%22 fat, select, cooked, grilled",
    "Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, top blade, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, broiled",
    "Beef, chuck, top blade, separable lean and fat, trimmed to 0%22 fat, choice, cooked, broiled",
    "Beef, chuck, top blade, separable lean and fat, trimmed to 0%22 fat, select, cooked, broiled",
    "Beef, chuck, top blade, separable lean only, trimmed to 0%22 fat, all grades, cooked, broiled",
    "Beef, chuck, top blade, separable lean only, trimmed to 0%22 fat, choice, cooked, broiled",
    "Beef, chuck, top blade, separable lean only, trimmed to 0%22 fat, select, cooked, broiled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, grilled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0%22 fat, choice, cooked, grilled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0%22 fat, select, cooked, grilled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0%22 fat, all grades, cooked, grilled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0%22 fat, choice, cooked, grilled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0%22 fat, select, cooked, grilled",
    "Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, under blade pot roast or steak, boneless, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, under blade pot roast or steak, boneless, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, under blade pot roast or steak, boneless, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, under blade pot roast or steak, boneless, separable lean only, trimmed to 0%22 fat, all grades, raw",
    "Beef, chuck, under blade pot roast or steak, boneless, separable lean only, trimmed to 0%22 fat, choice, raw",
    "Beef, chuck, under blade pot roast or steak, boneless, separable lean only, trimmed to 0%22 fat, select, raw",
    "Beef, chuck, under blade pot roast, boneless, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, braised",
    "Beef, chuck, under blade pot roast, boneless, separable lean and fat, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, chuck, under blade pot roast, boneless, separable lean and fat, trimmed to 0%22 fat, select, cooked, braised",
    "Beef, chuck, under blade pot roast, boneless, separable lean only, trimmed to 0%22 fat, all grades, cooked, braised",
    "Beef, chuck, under blade pot roast, boneless, separable lean only, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, chuck, under blade pot roast, boneless, separable lean only, trimmed to 0%22 fat, select, cooked, braised",
    "Beef, chuck, under blade steak, boneless, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, braised",
    "Beef, chuck, under blade steak, boneless, separable lean and fat, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, chuck, under blade steak, boneless, separable lean and fat, trimmed to 0%22 fat, select, cooked, braised",
    "Beef, chuck, under blade steak, boneless, separable lean only, trimmed to 0%22 fat, all grades, cooked, braised",
    "Beef, chuck, under blade steak, boneless, separable lean only, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, chuck, under blade steak, boneless, separable lean only, trimmed to 0%22 fat, select, cooked, braised",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0%22 fat, all grades, cooked",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0%22 fat, choice, cooked",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0%22 fat, select, cooked",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1%2F8%22 fat, all grades, cooked",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1%2F8%22 fat, all grades, raw",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1%2F8%22 fat, choice, cooked",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1%2F8%22 fat, choice, raw",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1%2F8%22 fat, select, cooked",
    "Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1%2F8%22 fat, select, raw",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0%22 fat, all grades, cooked",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0%22 fat, all grades, raw",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0%22 fat, choice, cooked",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0%22 fat, choice, raw",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0%22 fat, select, cooked",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0%22 fat, select, raw",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 1%2F8%22 fat, all grades, cooked",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 1%2F8%22 fat, all grades, raw",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 1%2F8%22 fat, choice, raw",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 1%2F8%22 fat, select, cooked",
    "Beef, composite of trimmed retail cuts, separable lean only, trimmed to 1%2F8%22 fat, select, raw",
    "Beef, corned beef hash, with potato, canned",
    "Beef, cured, breakfast strips, cooked",
    "Beef, cured, breakfast strips, raw or unheated",
    "Beef, cured, corned beef, brisket, cooked",
    "Beef, cured, corned beef, brisket, raw",
    "Beef, cured, corned beef, canned",
    "Beef, cured, dried",
    "Beef, cured, luncheon meat, jellied",
    "Beef, cured, pastrami",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, all grades, cooked, broiled",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, all grades, raw",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, choice, cooked, broiled",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, choice, raw",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, select, cooked, broiled",
    "Beef, flank, steak, separable lean and fat, trimmed to 0%22 fat, select, raw",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, all grades, cooked, broiled",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, all grades, raw",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, choice, cooked, braised",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, choice, cooked, broiled",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, choice, raw",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, select, cooked, broiled",
    "Beef, flank, steak, separable lean only, trimmed to 0%22 fat, select, raw",
    "Beef, grass-fed, ground, raw",
    "Beef, grass-fed, strip steaks, lean only, raw",
    "Beef, ground, 70% lean meat %2F 30% fat, crumbles, cooked, pan-browned",
    "Beef, ground, 70% lean meat %2F 30% fat, loaf, cooked, baked",
    "Beef, ground, 70% lean meat %2F 30% fat, patty cooked, pan-broiled",
    "Beef, ground, 70% lean meat %2F 30% fat, patty, cooked, broiled",
    "Beef, ground, 70% lean meat %2F 30% fat, raw",
    "Beef, ground, 75% lean meat %2F 25% fat, crumbles, cooked, pan-browned",
    "Beef, ground, 75% lean meat %2F 25% fat, loaf, cooked, baked",
    "Beef, ground, 75% lean meat %2F 25% fat, patty, cooked, broiled",
    "Beef, ground, 75% lean meat %2F 25% fat, patty, cooked, pan-broiled",
    "Beef, ground, 75% lean meat %2F 25% fat, raw",
    "Beef, ground, 80% lean meat %2F 20% fat, crumbles, cooked, pan-browned",
    "Beef, ground, 80% lean meat %2F 20% fat, loaf, cooked, baked",
    "Beef, ground, 80% lean meat %2F 20% fat, patty, cooked, broiled",
    "Beef, ground, 80% lean meat %2F 20% fat, patty, cooked, pan-broiled",
    "Beef, ground, 80% lean meat %2F 20% fat, raw",
    "Beef, ground, 85% lean meat %2F 15% fat, crumbles, cooked, pan-browned",
    "Beef, ground, 85% lean meat %2F 15% fat, loaf, cooked, baked",
    "Beef, ground, 85% lean meat %2F 15% fat, patty, cooked, broiled",
    "Beef, ground, 85% lean meat %2F 15% fat, patty, cooked, pan-broiled",
    "Beef, ground, 85% lean meat %2F 15% fat, raw (Includes foods for USDA%22s Food Distribution Program)",
    "Beef, ground, 90% lean meat %2F 10% fat, crumbles, cooked, pan-browned",
    "Beef, ground, 90% lean meat %2F 10% fat, loaf, cooked, baked",
    "Beef, ground, 90% lean meat %2F 10% fat, patty, cooked, broiled",
    "Beef, ground, 90% lean meat %2F 10% fat, patty, cooked, pan-broiled",
    "Beef, ground, 90% lean meat %2F 10% fat, raw",
    "Beef, ground, 93% lean meat %2F 7% fat, crumbles, cooked, pan-browned",
    "Beef, ground, 93% lean meat %2F 7% fat, loaf, cooked, baked",
    "Beef, ground, 93% lean meat %2F 7% fat, patty, cooked, broiled",
    "Beef, ground, 93% lean meat %2F 7% fat, raw",
    "Beet greens, raw",
    "Beets, canned, drained solids",
    "Beets, canned, no salt added, solids and liquids",
    "Beets, canned, regular pack, solids and liquids",
    "Beets, cooked, boiled, drained",
    "Beets, cooked, boiled, drained, with salt",
    "Beets, harvard, canned, solids and liquids",
    "Beets, pickled, canned, solids and liquids",
    "Beets, raw",
    "Beverage, instant breakfast powder, chocolate, not reconstituted",
    "Beverage, instant breakfast powder, chocolate, sugar-free, not reconstituted",
    "Beverages, Energy drink, Citrus",
    "Beverages, ABBOTT, EAS soy protein powder",
    "Beverages, ABBOTT, EAS whey protein powder",
    "Beverages, ABBOTT, ENSURE PLUS, ready-to-drink",
    "Beverages, ABBOTT, ENSURE, Nutritional Shake, Ready-to-Drink",
    "Beverages, Acai berry drink, fortified",
    "Beverages, almond milk, chocolate, ready-to-drink",
    "Beverages, almond milk, sweetened, vanilla flavor, ready-to-drink",
    "Beverages, almond milk, unsweetened, shelf stable",
    "Beverages, aloe vera juice drink, fortified with Vitamin C",
    "Beverages, AMBER, hard cider",
    "Beverages, Apple juice drink, light, fortified with vitamin C",
    "Beverages, ARIZONA, tea, ready-to-drink, lemon",
    "Beverages, carbonated, club soda",
    "Beverages, carbonated, cola, fast-food cola",
    "Beverages, carbonated, cola, regular",
    "Beverages, carbonated, cola, without caffeine",
    "Beverages, carbonated, ginger ale",
    "Beverages, carbonated, grape soda",
    "Beverages, carbonated, lemon-lime soda, no caffeine",
    "Beverages, carbonated, limeade, high caffeine",
    "Beverages, carbonated, low calorie, cola or pepper-type, with aspartame, contains caffeine",
    "Beverages, carbonated, low calorie, cola or pepper-type, with aspartame, without caffeine",
    "Beverages, carbonated, low calorie, cola or pepper-types, with sodium saccharin, contains caffeine",
    "Beverages, carbonated, low calorie, other than cola or pepper, without caffeine",
    "Beverages, carbonated, low calorie, other than cola or pepper, with aspartame, contains caffeine",
    "Beverages, carbonated, orange",
    "Beverages, carbonated, pepper-type, contains caffeine",
    "Beverages, carbonated, reduced sugar, cola, contains caffeine and sweeteners",
    "Beverages, carbonated, root beer",
    "Beverages, carbonated, SPRITE, lemon-lime, without caffeine",
    "Beverages, carbonated, tonic water",
    "Beverages, Carob-flavor beverage mix, powder",
    "Beverages, Carob-flavor beverage mix, powder, prepared with whole milk",
    "Beverages, chocolate almond milk, unsweetened, shelf-stable, fortified with vitamin D2 and E",
    "Beverages, chocolate drink, milk and soy based, ready to drink, fortified",
    "Beverages, chocolate malt powder, prepared with 1% milk, fortified",
    "Beverages, chocolate malt, powder, prepared with fat free milk",
    "Beverages, chocolate powder, no sugar added",
    "Beverages, chocolate syrup",
    "Beverages, chocolate syrup, prepared with whole milk",
    "Beverages, chocolate-flavor beverage mix for milk, powder, with added nutrients",
    "Beverages, chocolate-flavor beverage mix for milk, powder, with added nutrients, prepared with whole milk",
    "Beverages, chocolate-flavor beverage mix, powder, prepared with whole milk",
    "Beverages, Chocolate-flavored drink, whey and milk based",
    "Beverages, citrus fruit juice drink, frozen concentrate",
    "Beverages, citrus fruit juice drink, frozen concentrate, prepared with water",
    "Beverages, Clam and tomato juice, canned",
    "Beverages, COCA-COLA, POWERADE, lemon-lime flavored, ready-to-drink",
    "Beverages, Cocktail mix, non-alcoholic, concentrated, frozen",
    "Beverages, Cocoa mix, low calorie, powder, with added calcium, phosphorus, aspartame, without added sodium or vitamin A",
    "Beverages, Cocoa mix, NESTLE, Hot Cocoa Mix Rich Chocolate With Marshmallows",
    "Beverages, Cocoa mix, no sugar added, powder",
    "Beverages, Cocoa mix, powder",
    "Beverages, Cocoa mix, powder, prepared with water",
    "Beverages, cocoa mix, with aspartame, powder, prepared with water",
    "Beverages, coconut milk, sweetened, fortified with calcium, vitamins A, B12, D2",
    "Beverages, Coconut water, ready-to-drink, unsweetened",
    "Beverages, coffee and cocoa, instant, decaffeinated, with whitener and low calorie sweetener",
    "Beverages, coffee substitute, cereal grain beverage, powder",
    "Beverages, coffee substitute, cereal grain beverage, powder, prepared with whole milk",
    "Beverages, coffee substitute, cereal grain beverage, prepared with water",
    "Beverages, coffee, brewed, breakfast blend",
    "Beverages, coffee, brewed, espresso, restaurant-prepared",
    "Beverages, coffee, brewed, espresso, restaurant-prepared, decaffeinated",
    "Beverages, coffee, brewed, prepared with tap water",
    "Beverages, coffee, brewed, prepared with tap water, decaffeinated",
    "Beverages, coffee, instant, chicory",
    "Beverages, coffee, instant, decaffeinated, powder",
    "Beverages, coffee, instant, decaffeinated, prepared with water",
    "Beverages, coffee, instant, mocha, sweetened",
    "Beverages, coffee, instant, regular, half the caffeine",
    "Beverages, coffee, instant, regular, powder",
    "Beverages, coffee, instant, regular, prepared with water",
    "Beverages, coffee, instant, vanilla, sweetened, decaffeinated, with non dairy creamer",
    "Beverages, coffee, instant, with chicory",
    "Beverages, coffee, instant, with whitener, reduced calorie",
    "Beverages, coffee, ready to drink, iced, mocha, milk based",
    "Beverages, coffee, ready to drink, milk based, sweetened",
    "Beverages, coffee, ready to drink, vanilla, light, milk based, sweetened",
    "Beverages, Cranberry juice cocktail",
    "Beverages, cranberry-apple juice drink, bottled",
    "Beverages, cranberry-apple juice drink, low calorie, with vitamin C added",
    "Beverages, cranberry-apricot juice drink, bottled",
    "Beverages, cranberry-grape juice drink, bottled",
    "Beverages, CYTOSPORT, Muscle Milk, ready-to-drink",
    "Beverages, dairy drink mix, chocolate, reduced calorie, with aspartame, powder, prepared with water and ice",
    "Beverages, Dairy drink mix, chocolate, reduced calorie, with low-calorie sweeteners, powder",
    "Beverages, DANNON, water, bottled, non-carbonated, with Fluoride",
    "Beverages, chocolate malt, powder, prepared with fat free milk",
    "Beverages, chocolate almond milk, unsweetened, shelf-stable, fortified with vitamin D2 and E",
    "Beverages, chocolate drink, milk and soy based, ready to drink, fortified",
    "Beverages, chocolate malt powder, prepared with 1% milk, fortified",
    "Beverages, carbonated, tonic water",
    "Beverages, Carob-flavor beverage mix, powder",
    "Beverages, drink mix, QUAKER OATS, GATORADE, orange flavor, powder",
    "Beverages, Eggnog-flavor mix, powder, prepared with whole milk",
    "Beverages, Energy Drink with carbonated water and high fructose corn syrup",
    "Beverages, Energy drink, AMP",
    "Beverages, Energy drink, AMP, sugar free",
    "Beverages, Energy drink, FULL THROTTLE",
    "Beverages, Energy Drink, Monster, fortified with vitamins C, B2, B3, B6, B12",
    "Beverages, Energy drink, RED BULL",
    "Beverages, Energy drink, RED BULL, sugar free, with added caffeine, niacin, pantothenic acid, vitamins B6 and B12",
    "Beverages, Energy drink, ROCKSTAR",
    "Beverages, Energy drink, ROCKSTAR, sugar free",
    "Beverages, Energy Drink, sugar free",
    "Beverages, Energy drink, VAULT Zero, sugar-free, citrus flavor",
    "Beverages, Energy drink, VAULT, citrus flavor",
    "Beverages, Fruit flavored drink containing less than 3% fruit juice, with high vitamin C",
    "Beverages, Fruit flavored drink, less than 3% juice, not fortified with vitamin C",
    "Beverages, Fruit flavored drink, reduced sugar, greater than 3% fruit juice, high vitamin C, added calcium",
    "Beverages, fruit juice drink, greater than 3% fruit juice, high vitamin C and added thiamin",
    "Beverages, fruit juice drink, greater than 3% juice, high vitamin C",
    "Beverages, fruit juice drink, reduced sugar, with vitamin E added",
    "Beverages, Fruit punch drink, frozen concentrate",
    "Beverages, Fruit punch drink, frozen concentrate, prepared with water",
    "Beverages, Fruit punch drink, with added nutrients, canned",
    "Beverages, fruit punch drink, without added nutrients, canned",
    "Beverages, fruit punch juice drink, frozen concentrate",
    "Beverages, fruit punch juice drink, frozen concentrate, prepared with water",
    "Beverages, fruit punch-flavor drink, powder, without added sodium, prepared with water",
    "Beverages, fruit-flavored drink, dry powdered mix, low calorie, with aspartame",
    "Beverages, fruit-flavored drink, powder, with high vitamin C with other added vitamins, low calorie",
    "Beverages, FUZE, orange mango, fortified with vitamins A, C, E, B6",
    "Beverages, GEROLSTEINER BRUNNEN GmbH & Co. KG (Gerolsteiner), naturally sparkling, mineral bottled water",
    "Beverages, GEROLSTEINER BRUNNEN GmbH & Co. KG,Gerolsteiner naturally sparkling mineral water",
    "Beverages, grape drink, canned",
    "Beverages, grape juice drink, canned",
    "Beverages, Horchata, as served in restaurant",
    "Beverages, Kiwi Strawberry Juice Drink",
    "Beverages, KRAFT, coffee, instant, French Vanilla Cafe",
    "Beverages, Lemonade fruit juice drink light, fortified with vitamin E and C",
    "Beverages, lemonade, frozen concentrate, pink, prepared with water",
    "Beverages, Lemonade, powder",
    "Beverages, lemonade-flavor drink, powder",
    "Beverages, lemonade-flavor drink, powder, prepared with water",
    "Beverages, LIPTON BRISK, tea, black, ready-to-drink, lemon",
    "Beverages, Malt liquor beverage",
    "Beverages, malted drink mix, chocolate, powder",
    "Beverages, Malted drink mix, chocolate, powder, prepared with whole milk",
    "Beverages, Malted drink mix, chocolate, with added nutrients, powder, prepared with whole milk",
    "Beverages, Malted drink mix, natural, powder, dairy based.",
    "Beverages, Malted drink mix, natural, powder, prepared with whole milk",
    "Beverages, Malted drink mix, natural, with added nutrients, powder, prepared with whole milk",
    "Beverages, Meal supplement drink, canned, peanut flavor",
    "Beverages, milk beverage, reduced fat, flavored and sweetened, Ready-to-drink, added calcium, vitamin A and vitamin D",
    "Beverages, MINUTE MAID, Lemonada, Limeade",
    "Beverages, Mixed vegetable and fruit juice drink, with added nutrients",
    "Beverages, MONSTER energy drink, low carb",
    "Beverages, MOTTS, Apple juice light, fortified with vitamin C",
    "Beverages, NESTEA, tea, black, ready-to-drink, lemon",
    "Beverages, NESTLE, Boost plus, nutritional drink, ready-to-drink",
    "Beverages, nutritional shake mix, high protein, powder",
    "Beverages, OCEAN SPRAY, Cran Cherry",
    "Beverages, OCEAN SPRAY, Cran Grape",
    "Beverages, OCEAN SPRAY, Cran Lemonade",
    "Beverages, OCEAN SPRAY, Cran Pomegranate",
    "Beverages, OCEAN SPRAY, Cran Raspberry Juice Drink",
    "Beverages, OCEAN SPRAY, Cran-Energy, Cranberry Energy Juice Drink",
    "Beverages, OCEAN SPRAY, Cranberry-Apple Juice Drink, bottled",
    "Beverages, OCEAN SPRAY, Diet Cran Cherry",
    "Beverages, OCEAN SPRAY, Diet Cranberry Juice",
    "Beverages, OCEAN SPRAY, Light Cranberry",
    "Beverages, OCEAN SPRAY, Light Cranberry and Raspberry Flavored Juice",
    "Beverages, OCEAN SPRAY, Light Cranberry, Concord Grape",
    "Beverages, OCEAN SPRAY, Ruby Red cranberry",
    "Beverages, OCEAN SPRAY, White Cranberry Peach",
    "Beverages, OCEAN SPRAY, White Cranberry Strawberry Flavored Juice Drink",
    "Beverages, orange and apricot juice drink, canned",
    "Beverages, orange breakfast drink, ready-to-drink, with added nutrients",
    "Beverages, Orange drink, breakfast type, with juice and pulp, frozen concentrate",
    "Beverages, Orange drink, breakfast type, with juice and pulp, frozen concentrate, prepared with water",
    "Beverages, orange drink, canned, with added vitamin C",
    "Beverages, Orange juice drink",
    "Beverages, Orange juice, light, No pulp",
    "Beverages, Orange-flavor drink, breakfast type, low calorie, powder",
    "Beverages, orange-flavor drink, breakfast type, powder",
    "Beverages, orange-flavor drink, breakfast type, powder, prepared with water",
    "Beverages, Orange-flavor drink, breakfast type, with pulp, frozen concentrate, prepared with water",
    "Beverages, Orange-flavor drink, breakfast type, with pulp, frozen concentrate",
    "Beverages, OVALTINE, chocolate malt powder",
    "Beverages, OVALTINE, Classic Malt powder",
    "Beverages, PEPSICO QUAKER, Gatorade G2, low calorie",
    "Beverages, PEPSICO QUAKER, Gatorade, G performance O 2, ready-to-drink",
    "Beverages, pineapple and grapefruit juice drink, canned",
    "Beverages, pineapple and orange juice drink, canned",
    "Beverages, Powerade Zero Ion4, calorie-free, assorted flavors",
    "Beverages, POWERADE, Zero, Mixed Berry",
    "Beverages, Propel Zero, fruit-flavored, non-carbonated",
    "Beverages, Protein powder soy based",
    "Beverages, Protein powder whey based",
    "Beverages, rice milk, unsweetened",
    "Beverages, rich chocolate, powder",
    "Beverages, shake, fast food, strawberry",
    "Beverages, SLIMFAST, Meal replacement, High Protein Shake, Ready-To-Drink, 3-2-1 plan",
    "Beverages, SNAPPLE, tea, black and green, ready to drink, lemon, diet",
    "Beverages, SNAPPLE, tea, black and green, ready to drink, peach, diet",
    "Beverages, Strawberry-flavor beverage mix, powder, prepared with whole milk",
    "Beverages, tea, black, brewed, prepared with distilled water",
    "Beverages, tea, black, brewed, prepared with tap water",
    "Beverages, tea, black, brewed, prepared with tap water, decaffeinated",
    "Beverages, tea, black, ready to drink",
    "Beverages, tea, black, ready to drink, decaffeinated",
    "Beverages, tea, black, ready to drink, decaffeinated, diet",
    "Beverages, tea, black, ready-to-drink, lemon, diet",
    "Beverages, tea, black, ready-to-drink, lemon, sweetened",
    "Beverages, tea, black, ready-to-drink, peach, diet",
    "Beverages, tea, green, brewed, decaffeinated",
    "Beverages, tea, green, brewed, regular",
    "Beverages, tea, green, instant, decaffeinated, lemon, unsweetened, fortified with vitamin C",
    "Beverages, tea, green, ready to drink, ginseng and honey, sweetened",
    "Beverages, tea, green, ready to drink, unsweetened",
    "Beverages, tea, green, ready-to-drink, citrus, diet, fortified with vitamin C",
    "Beverages, tea, green, ready-to-drink, diet",
    "Beverages, tea, green, ready-to-drink, sweetened",
    "Beverages, tea, herb, brewed, chamomile",
    "Beverages, tea, herb, other than chamomile, brewed",
    "Beverages, tea, hibiscus, brewed",
    "Beverages, tea, instant, decaffeinated, lemon, diet",
    "Beverages, tea, instant, decaffeinated, lemon, sweetened",
    "Beverages, tea, instant, decaffeinated, unsweetened",
    "Beverages, tea, instant, lemon, diet",
    "Beverages, tea, instant, lemon, sweetened, powder",
    "Beverages, tea, instant, lemon, sweetened, prepared with water",
    "Beverages, tea, instant, lemon, unsweetened",
    "Beverages, tea, instant, lemon, with added ascorbic acid",
    "Beverages, tea, instant, sweetened with sodium saccharin, lemon-flavored, powder",
    "Beverages, tea, instant, unsweetened, powder",
    "Beverages, tea, instant, unsweetened, prepared with water",
    "Beverages, tea, Oolong, brewed",
    "Beverages, tea, ready-to-drink, lemon, diet",
    "Beverages, The COCA-COLA company, DASANI, water, bottled, non-carbonated",
    "Beverages, The COCA-COLA company, Glaceau Vitamin Water, Revive Fruit Punch, fortified",
    "Beverages, The COCA-COLA company, Hi-C Flashin' Fruit Punch",
    "Beverages, The COCA-COLA company, Minute Maid, Lemonade",
    "Beverages, THE COCA-COLA COMPANY, NOS energy drink, Original, grape, loaded cherry, charged citrus, fortified with vitamins B6 and B12",
    "Beverages, THE COCA-COLA COMPANY, NOS Zero, energy drink, sugar-free with guarana, fortified with vitamins B6 and B12",
    "Beverages, Tropical Punch, ready-to-drink",
    "Beverages, UNILEVER, SLIMFAST Shake Mix, high protein, whey powder, 3-2-1 Plan",
    "Beverages, UNILEVER, SLIMFAST Shake Mix, powder, 3-2-1 Plan",
    "Beverages, UNILEVER, SLIMFAST, meal replacement, regular, ready-to-drink, 3-2-1 Plan",
    "Beverages, V8 SPLASH Juice Drinks, Berry Blend",
    "Beverages, V8 SPLASH Juice Drinks, Diet Berry Blend",
    "Beverages, V8 SPLASH Juice Drinks, Diet Fruit Medley",
    "Beverages, V8 SPLASH Juice Drinks, Diet Strawberry Kiwi",
    "Beverages, V8 SPLASH Juice Drinks, Diet Tropical Blend",
    "Beverages, V8 SPLASH Juice Drinks, Fruit Medley",
    "Beverages, V8 SPLASH Juice Drinks, Guava Passion Fruit",
    "Beverages, V8 SPLASH Juice Drinks, Mango Peach",
    "Beverages, V8 SPLASH Juice Drinks, Orange Pineapple",
    "Beverages, V8 SPLASH Juice Drinks, Orchard Blend",
    "Beverages, V8 SPLASH Juice Drinks, Strawberry Banana",
    "Beverages, V8 SPLASH Juice Drinks, Strawberry Kiwi",
    "Beverages, V8 SPLASH Juice Drinks, Tropical Blend",
    "Beverages, V8 SPLASH Smoothies, Peach Mango",
    "Beverages, V8 SPLASH Smoothies, Strawberry Banana",
    "Beverages, V8 SPLASH Smoothies, Tropical Colada",
    "Beverages, V8 V-FUSION Juices, Acai Berry",
    "Beverages, V8 V-FUSION Juices, Peach Mango",
    "Beverages, V8 V-FUSION Juices, Strawberry Banana",
    "Beverages, V8 V-FUSION Juices, Tropical",
    "Beverages, vegetable and fruit juice blend, 100% juice, with added vitamins A, C, E",
    "Beverages, Vegetable and fruit juice drink, reduced calorie, with low-calorie sweetener, added vitamin C",
    "Beverages, Water with added vitamins and minerals, bottles, sweetened, assorted fruit flavors",
    "Beverages, water, bottled, non-carbonated, CALISTOGA",
    "Beverages, water, bottled, non-carbonated, CRYSTAL GEYSER",
    "Beverages, water, bottled, non-carbonated, DANNON",
    "Beverages, water, bottled, non-carbonated, EVIAN",
    "Beverages, water, bottled, non-carbonated, PEPSI, AQUAFINA",
    "Beverages, water, bottled, PERRIER",
    "Beverages, water, bottled, POLAND SPRING",
    "Beverages, water, bottled, yumberry, pomegranate with anti-oxidants, zero calories",
    "Beverages, water, tap, drinking",
    "Beverages, water, tap, municipal",
    "Beverages, water, tap, well",
    "Beverages, WENDY'S, tea, ready-to-drink, unsweetened",
    "Beverages, Whey protein powder isolate",
    "Beverages, Whiskey sour mix, bottled",
    "Beverages, Whiskey sour mix, powder",
    "Beverages, Wine, non-alcoholic",
    "Beverages, yellow green colored citrus soft drink with caffeine",
    "Beverages, ZEVIA, cola",
    "Beverages, ZEVIA, cola, caffeine free",
    "Biscuits, mixed grain, refrigerated dough",
    "Biscuits, plain or buttermilk, dry mix",
    "Biscuits, plain or buttermilk, dry mix, prepared",
    "Biscuits, plain or buttermilk, frozen, baked",
    "Biscuits, plain or buttermilk, prepared from recipe",
    "Biscuits, plain or buttermilk, refrigerated dough, higher fat",
    "Biscuits, plain or buttermilk, refrigerated dough, higher fat, baked",
    "Biscuits, plain or buttermilk, refrigerated dough, lower fat",
    "Biscuits, plain or buttermilk, refrigerated dough, lower fat, baked",
    "Bison, ground, grass-fed, cooked",
    "Bison, ground, grass-fed, raw",
    "Blackberries, canned, heavy syrup, solids and liquids",
    "Blackberries, frozen, unsweetened",
    "Blackberries, raw",
    "Blackberries, wild, raw (Alaska Native)",
    "Blackberry juice, canned",
    "Blood sausage",
    "Blueberries, canned, heavy syrup, solids and liquids",
    "Blueberries, canned, light syrup, drained",
    "Blueberries, dried, sweetened",
    "Blueberries, frozen, sweetened",
    "Blueberries, frozen, unsweetened (Includes foods for USDA's Food Distribution Program)",
    "Blueberries, raw",
    "Blueberries, wild, canned, heavy syrup, drained",
    "Blueberries, wild, frozen (Alaska Native)",
    "Blueberries, wild, frozen (Includes foods for USDA's Food Distribution Program)",
    "Blueberries, wild, raw (Alaska Native)",
    "Bockwurst, pork, veal, raw",
    "Bologna, beef",
    "Bologna, beef and pork",
    "Bologna, beef and pork, low fat",
    "Bologna, beef, low fat",
    "Bologna, chicken, pork",
    "Bologna, chicken, pork, beef",
    "Bologna, chicken, turkey, pork",
    "Bologna, meat and poultry",
    "Bologna, pork",
    "Bologna, pork and turkey, lite",
    "Bologna, pork, turkey and beef",
    "Bologna, turkey",
    "Borage, cooked, boiled, drained, with salt",
    "Borage, cooked, boiled, drained, without salt",
    "Borage, raw",
    "Boysenberries, canned, heavy syrup",
    "Boysenberries, frozen, unsweetened",
    "Bratwurst, beef and pork, smoked",
    "Bratwurst, chicken, cooked",
    "Bratwurst, pork, beef and turkey, lite, smoked",
    "Bratwurst, pork, beef, link",
    "Bratwurst, pork, cooked",
    "Bratwurst, veal, cooked",
    "Braunschweiger (a liver sausage), pork",
    "Bread, banana, prepared from recipe, made with margarine",
    "Bread, blue corn, somiviki (Hopi)",
    "Bread, boston brown, canned",
    "Bread, chapati or roti, plain, commercially prepared",
    "Bread, chapati or roti, whole wheat, commercially prepared, frozen",
    "Bread, cheese",
    "Bread, cinnamon",
    "Bread, cornbread, dry mix, enriched (includes corn muffin mix)",
    "Bread, cornbread, dry mix, prepared with 2% milk, 80% margarine, and eggs",
    "Bread, cornbread, dry mix, unenriched (includes corn muffin mix)",
    "Bread, cornbread, prepared from recipe, made with low fat (2%) milk",
    "Bread, cracked-wheat",
    "Bread, crumbs, dry, grated, plain",
    "Bread, crumbs, dry, grated, seasoned",
    "Bread, egg",
    "Bread, egg, toasted",
    "Bread, french or vienna (includes sourdough)",
    "Bread, french or vienna, toasted (includes sourdough)",
    "Bread, french or vienna, whole wheat",
    "Bread, gluten-free, white, made with potato extract, rice starch, and rice flour",
    "Bread, gluten-free, white, made with rice flour, corn starch, and/or tapioca",
    "Bread, gluten-free, white, made with tapioca starch and brown rice flour",
    "Bread, gluten-free, whole grain, made with tapioca starch and brown rice flour",
    "Bread, irish soda, prepared from recipe",
    "Bread, Italian",
    "Bread, kneel down (Navajo)",
    "Bread, multi-grain (includes whole-grain)",
    "Bread, multi-grain, toasted (includes whole-grain)",
    "Bread, naan, plain, commercially prepared, refrigerated",
    "Bread, naan, whole wheat, commercially prepared, refrigerated",
    "Bread, oat bran",
    "Bread, oat bran, toasted",
    "Bread, oatmeal",
    "Bread, oatmeal, toasted",
    "Bread, pan dulce, sweet yeast bread",
    "Bread, paratha, whole wheat, commercially prepared, frozen",
    "Bread, pita, white, enriched",
    "Bread, pita, white, unenriched",
    "Bread, pita, whole-wheat",
    "Bread, potato",
    "Bread, pound cake type, pan de torta salvadoran",
    "Bread, protein (includes gluten)",
    "Bread, protein, (includes gluten), toasted",
    "Bread, pumpernickel",
    "Bread, raisin, enriched",
    "Bread, raisin, enriched, toasted",
    "Bread, raisin, unenriched",
    "Bread, reduced-calorie, oat bran",
    "Bread, reduced-calorie, oat bran, toasted",
    "Bread, reduced-calorie, oatmeal",
    "Bread, reduced-calorie, rye",
    "Bread, reduced-calorie, wheat",
    "Bread, reduced-calorie, white",
    "Bread, rice bran",
    "Bread, rice bran, toasted",
    "Bread, roll, Mexican, bollilo",
    "Bread, rye",
    "Bread, rye, toasted",
    "Bread, salvadoran sweet cheese (quesadilla salvadorena)",
    "Bread, sticks, plain",
    "Bread, stuffing, cornbread, dry mix",
    "Bread, stuffing, cornbread, dry mix, prepared",
    "Bread, stuffing, dry mix",
    "Bread, stuffing, dry mix, prepared",
    "Bread, wheat",
    "Bread, wheat, sprouted",
    "Bread, wheat, sprouted, toasted",
    "Bread, wheat, toasted",
    "Bread, white wheat",
    "Bread, white, commercially prepared (includes soft bread crumbs)",
    "Bread, white, commercially prepared, low sodium, no salt",
    "Bread, white, commercially prepared, toasted",
    "Bread, white, commercially prepared, toasted, low sodium no salt",
    "Bread, white, prepared from recipe, made with low fat (2%) milk",
    "Bread, white, prepared from recipe, made with nonfat dry milk",
    "Bread, whole-wheat, commercially prepared",
    "Bread, whole-wheat, commercially prepared, toasted",
    "Bread, whole-wheat, prepared from recipe",
    "Bread, whole-wheat, prepared from recipe, toasted",
    "Breadfruit, raw",
    "Breakfast bar, corn flake crust with fruit",
    "Breakfast bars, oats, sugar, raisins, coconut (include granola bar)",
    "Broadbeans (fava beans), mature seeds, canned",
    "Broadbeans (fava beans), mature seeds, cooked, boiled, with salt",
    "Broadbeans (fava beans), mature seeds, cooked, boiled, without salt",
    "Broadbeans (fava beans), mature seeds, raw",
    "Broadbeans, immature seeds, cooked, boiled, drained, with salt",
    "Broadbeans, immature seeds, cooked, boiled, drained, without salt",
    "Broadbeans, immature seeds, raw",
    "Broccoli raab, cooked",
    "Broccoli raab, raw",
    "Broccoli, chinese, cooked",
    "Broccoli, chinese, raw",
    "Broccoli, cooked, boiled, drained, with salt",
    "Broccoli, cooked, boiled, drained, without salt",
    "Broccoli, flower clusters, raw",
    "Broccoli, frozen, chopped, cooked, boiled, drained, with salt",
    "Broccoli, frozen, chopped, cooked, boiled, drained, without salt",
    "Broccoli, frozen, chopped, unprepared",
    "Broccoli, frozen, spears, cooked, boiled, drained, with salt",
    "Broccoli, frozen, spears, cooked, boiled, drained, without salt",
    "Broccoli, frozen, spears, unprepared (Includes foods for USDA's Food Distribution Program)",
    "Broccoli, leaves, raw",
    "Broccoli, raw",
    "Broccoli, stalks, raw",
    "Brussels sprouts, cooked, boiled, drained, with salt",
    "Brussels sprouts, cooked, boiled, drained, without salt",
    "Brussels sprouts, frozen, cooked, boiled, drained, with salt",
    "Brussels sprouts, frozen, cooked, boiled, drained, without salt"
  ];




  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<FoundationFoodsWithUnit>;
  foods: any;


  constructor(private cdr: ChangeDetectorRef, private foodService: FoodService, private foodNutrientsService: FoodNutrientsService) { }

  ngOnInit(): void {

    this.foodService.getFoodList_Protein()
      .subscribe((protein: FoundationFoods[]) => this.foodList_Protein = protein);

    this.foodService.getFoodList_Carb()
      .subscribe((carb: FoundationFoods[]) => this.foodList_Carb = carb);

    this.foodService.getFoodList_Fiber()
      .subscribe((fiber: FoundationFoods[]) => this.foodList_Fiber = fiber);

    this.foodService.getFoodList_SaturatedFats()
      .subscribe((saturatedfats: FoundationFoods[]) => this.foodList_SaturatedFats = saturatedfats);

    this.foodService.getFoodList_MonoUnsaturatedFats()
      .subscribe((monounsaturatedfats: FoundationFoods[]) => this.foodList_MonoUnsaturatedFats = monounsaturatedfats);

    this.foodService.getFoodList_PolyUnsaturatedFats()
      .subscribe((polyunsaturatedfats: FoundationFoods[]) => this.foodList_PolyUnsaturatedFats = polyunsaturatedfats);

    this.foodService.getFoodList_TransFats()
      .subscribe((transfats: FoundationFoods[]) => this.foodList_TransFats = transfats);

    this.foodService.getFoodList_Cholestrol()
      .subscribe((cholestrol: FoundationFoods[]) => this.foodList_Cholestrol = cholestrol);

    this.foodService.getFoodList_Water()
      .subscribe((water: FoundationFoods[]) => this.foodList_Water = water);

    this.foodService.getFoodList_Biotin()
      .subscribe((biotin: FoundationFoods[]) => this.foodList_Biotin = biotin);

    this.foodService.getFoodList_Boron()
      .subscribe((boron: FoundationFoods[]) => this.foodList_Boron = boron);

    this.foodService.getFoodList_Calcium()
      .subscribe((calcium: FoundationFoods[]) => this.foodList_Calcium = calcium);

    this.foodService.getFoodList_Carotene_alpha()
      .subscribe((carotene_alpha: FoundationFoods[]) => this.foodList_Carotene_alpha = carotene_alpha);

    this.foodService.getFoodList_Carotene_beta()
      .subscribe((carotene_beta: FoundationFoods[]) => this.foodList_Carotene_beta = carotene_beta);

    this.foodService.getFoodList_Carotene_gamma()
      .subscribe((carotene_gamma: FoundationFoods[]) => this.foodList_Carotene_gamma = carotene_gamma);

    this.foodService.getFoodList_Chlorine()
      .subscribe((chlorine: FoundationFoods[]) => this.foodList_Chlorine = chlorine);

    this.foodService.getFoodList_CholineFree()
      .subscribe((cholineFree: FoundationFoods[]) => this.foodList_cholineFree = cholineFree);


    this.foodService.getFoodList_CholineFromPhosphocholineMg()
      .subscribe((cholineFromPhosphocholineMg: FoundationFoods[]) => this.foodList_cholineFromPhosphocholineMg = cholineFromPhosphocholineMg);


    this.foodService.getFoodList_CholineFromGlycerophosphocholine()
      .subscribe((cholineFromGlycerophosphocholine: FoundationFoods[]) => this.foodList_cholineFromGlycerophosphocholineMg = cholineFromGlycerophosphocholine);


    this.foodService.getFoodList_CholineFromPhosphotidylCholineMg()
      .subscribe((cholineFromPhosphotidylCholineMg: FoundationFoods[]) => this.foodList_cholineFromPhosphotidylCholineMg = cholineFromPhosphotidylCholineMg);

    this.foodService.getFoodList_CholineFromSphingomyelinMg()
      .subscribe((cholineFromSphingomyelinMg: FoundationFoods[]) => this.foodList_cholineFromSphingomyelinMg = cholineFromSphingomyelinMg);

    this.foodService.getFoodList_CholineTotal()
      .subscribe((cholineTotal: FoundationFoods[]) => this.foodList_cholineTotal = cholineTotal);

    this.foodService.getFoodList_ChromiumMugram()
      .subscribe((chromiumMugram: FoundationFoods[]) => this.foodList_chromiumMugram = chromiumMugram);

    this.foodService.getFoodList_Copper()
      .subscribe((copper: FoundationFoods[]) => this.foodList_copper = copper);

    this.foodService.getFoodList_Fluoride()
      .subscribe((fluoride: FoundationFoods[]) => this.foodList_fluoride = fluoride);

    this.foodService.getFoodList_FolateDFE()
      .subscribe((folateDFE: FoundationFoods[]) => this.foodList_folateDFE = folateDFE);

    this.foodService.getFoodList_folateFood()
      .subscribe((folateFood: FoundationFoods[]) => this.foodList_folateFood = folateFood);

    this.foodService.getFoodList_FolateTotal()
      .subscribe((folateTotal: FoundationFoods[]) => this.foodList_folateTotal = folateTotal);

    this.foodService.getFoodList_Iodine()
      .subscribe((iodine: FoundationFoods[]) => this.foodList_iodine = iodine);

    this.foodService.getFoodList_Iron()
      .subscribe((iron: FoundationFoods[]) => this.foodList_iron = iron);

    this.foodService.getFoodList_Magnesium()
      .subscribe((magnesium: FoundationFoods[]) => this.foodList_magnesium = magnesium);

    this.foodService.getFoodList_Manganese()
      .subscribe((manganese: FoundationFoods[]) => this.foodList_manganese = manganese);

    this.foodService.getFoodList_Molybdenum()
      .subscribe((molybdenum: FoundationFoods[]) => this.foodList_molybdenum = molybdenum);

    this.foodService.getFoodList_Niacin()
      .subscribe((niacin: FoundationFoods[]) => this.foodList_niacin = niacin);

    this.foodService.getFoodList_Nickel()
      .subscribe((nickel: FoundationFoods[]) => this.foodList_nickel = nickel);


    this.foodService.getFoodList_PantothenicAcid()
      .subscribe((pantothenicAcid: FoundationFoods[]) => this.foodList_pantothenicAcid = pantothenicAcid);

    this.foodService.getFoodList_Phosphorus()
      .subscribe((phosphorus: FoundationFoods[]) => this.foodList_phosphorus = phosphorus);

    this.foodService.getFoodList_Potassium()
      .subscribe((potassium: FoundationFoods[]) => this.foodList_potassium = potassium);

    this.foodService.getFoodList_Riboflavin()
      .subscribe((riboflavin: FoundationFoods[]) => this.foodList_riboflavin = riboflavin);

    this.foodService.getFoodList_Selenium()
      .subscribe((selenium: FoundationFoods[]) => this.foodList_selenium = selenium);

    this.foodService.getFoodList_Sodium()
      .subscribe((sodium: FoundationFoods[]) => this.foodList_sodium = sodium);

    this.foodService.getFoodList_Sulfur()
      .subscribe((sulfur: FoundationFoods[]) => this.foodList_sulfur = sulfur);

    this.foodService.getFoodList_Thiamin()
      .subscribe((thiamin: FoundationFoods[]) => this.foodList_thiamin = thiamin);

    this.foodService.getFoodList_VitaminA_RAE()
      .subscribe((vitaminA_RAE: FoundationFoods[]) => this.foodList_vitaminA_RAE = vitaminA_RAE);

    this.foodService.getFoodList_VitaminAIU()
      .subscribe((vitaminAIU: FoundationFoods[]) => this.foodList_vitaminAIU = vitaminAIU);

    this.foodService.getFoodList_VitaminB12Added()
      .subscribe((vitaminB12Added: FoundationFoods[]) => this.foodList_vitaminB12Added = vitaminB12Added);


    this.foodService.getFoodList_VitaminB12()
      .subscribe((vitaminB12: FoundationFoods[]) => this.foodList_vitaminB12 = vitaminB12);

    this.foodService.getFoodList_VitaminB6()
      .subscribe((vitaminB6: FoundationFoods[]) => this.foodList_vitaminB6 = vitaminB6);

    this.foodService.getFoodList_VitaminC()
      .subscribe((vitaminC: FoundationFoods[]) => this.foodList_vitaminC = vitaminC);

    this.foodService.getFoodList_VitaminD_D2PlusD3IU()
      .subscribe((vitaminD_D2PlusD3IU: FoundationFoods[]) => this.foodList_VitaminD_D2PlusD3IU = vitaminD_D2PlusD3IU);

    this.foodService.getFoodList_VitaminD_D2PlusD3()
      .subscribe((vitaminD_D2PlusD3: FoundationFoods[]) => this.foodList_vitaminD_D2PlusD3 = vitaminD_D2PlusD3);

    this.foodService.getFoodList_VitaminD2()
      .subscribe((vitaminD2: FoundationFoods[]) => this.foodList_vitaminD2 = vitaminD2);

    this.foodService.getFoodList_VitaminD3()
      .subscribe((vitaminD3: FoundationFoods[]) => this.foodList_vitaminD3 = vitaminD3);

    this.foodService.getFoodList_VitaminD4()
      .subscribe((vitaminD4: FoundationFoods[]) => this.foodList_vitaminD4 = vitaminD4);


    this.foodService.getFoodList_vitaminE_AlphaTocopherol()
      .subscribe((vitaminE_AlphaTocopherol: FoundationFoods[]) => this.foodList_vitaminE_AlphaTocopherol = vitaminE_AlphaTocopherol);

    this.foodService.getFoodList_VitaminE_Added()
      .subscribe((vitaminE_added: FoundationFoods[]) => this.foodList_vitaminE_Added = vitaminE_added);

    this.foodService.getFoodList_VitaminELabelEntryIU()
      .subscribe((vitaminELabelEntryIU: FoundationFoods[]) => this.foodList_vitaminELabelEntryIU = vitaminELabelEntryIU);


    this.foodService.getFoodList_VitaminEMcgRE()
      .subscribe((vitaminEMcgRE: FoundationFoods[]) => this.foodList_vitaminEMcgRE = vitaminEMcgRE);


    this.foodService.getFoodList_VitaminK_Dihydrophylloquinone()
      .subscribe((vitaminK_Dihydrophylloquinone: FoundationFoods[]) => this.foodList_vitaminK_Dihydrophylloquinone = vitaminK_Dihydrophylloquinone);


    this.foodService.getFoodList_VitaminK_Menaquinone4()
      .subscribe((vitaminK_Menaquinone4: FoundationFoods[]) => this.foodList_vitaminK_Menaquinone4 = vitaminK_Menaquinone4);

    this.foodService.getFoodList_vitaminK_Phylloquinone()
      .subscribe((vitaminK_Phylloquinone: FoundationFoods[]) => this.foodList_vitaminK_Phylloquinone = vitaminK_Phylloquinone);

    this.foodService.getFoodList_zinc()
      .subscribe((zinc: FoundationFoods[]) => this.foodList_zinc = zinc);
    //this.getAllFood();
    /*this.foodService.getAllFood()
    .subscribe((food: Food[]) => this.filteredFoodList = food);*/
    this.dataSource.paginator = this.paginator;
  }

  /* getUniqueCategories() {
    const categories = this.dataSource.data
      .map(item => item.foodcategory)
      .filter(item => item !== null);
    this.uniqueCategories = (Array.from(new Set(categories)));
    //.filter(item => item.trim() !== '');
  } */

  getUniqueCategories() {
    const categories = this.filteredDataSource.data
      .map(item => item.foodcategory)
      .filter(item => item !== null);

    // Filter out excluded categories based on diet
    let excludedCategories: string | string[] = [];
    if (this.selectedDiet === 'vegan') {
      excludedCategories = this.veganExcludedCategories;
    } else if (this.selectedDiet === 'vegetarian') {
      excludedCategories = this.vegetarianExcludedCategories;
    }

    // Filter unique categories, excluding those not allowed in the selected diet
    this.uniqueCategories = Array.from(new Set(categories));
    this.filteredCategories = this.uniqueCategories.filter(category => !excludedCategories.includes(category));
  }

  // Function to clear the input field
  clearInput() {
    this.inputValue = '';
    this.filterResults("");
  }

  // Apply filter logic
  applyFilter() {
    // Apply diet filter first
    if (this.selectedDiet === 'vegan') {
      this.filteredDataSource.data = this.dataSource.data.filter(item =>
        !this.veganExcludedCategories.includes(item.foodcategory)
      );
    } else if (this.selectedDiet === 'vegetarian') {
      this.filteredDataSource.data = this.dataSource.data.filter(item =>
        !this.vegetarianExcludedCategories.includes(item.foodcategory)
      );
    } else {
      this.filteredDataSource.data = this.dataSource.data; // No diet filter, show all
    }

    // Apply category filter
    if (this.selectedCategory) {
      this.filteredDataSource.data = this.filteredDataSource.data.filter(item => item.foodcategory === this.selectedCategory);
    }

    this.filteredDataSource.paginator = this.paginator;
    this.getUniqueCategories(); // Refresh available categories
  }


  reset() {
    this.filteredFoodList = [];
    this.unit = {} as Unit;
    this.foodsWithUnit = [];
    this.dataSource.data = [];
    this.filteredDataSource.data = [];
    this.selectedCategory = '';
  }

  navigateToPage() {
    // Example of navigating to 'page1'. Adjust as needed.
    this.router.navigate(['/login']);
  }
  addIngredient() {
    if (this.newIngredient.trim() && this.newAmount !== null) {
      var tempIngredient = {
        ingredient: this.newIngredient, amount: this.newAmount
      };
      this.ingredients = [...this.ingredients, tempIngredient];

      this.newIngredient = '';
      this.newAmount = null;
    }


  }

  generateNutritionLabel() {
    const nutrientRequests = this.ingredients.map((ingredient) =>
      this.foodNutrientsService.getNutrients(ingredient.ingredient).pipe(
        catchError((err) => {
          console.error(`Error fetching nutrients for ${ingredient.ingredient}`, err);
          return of({
            foodName: ingredient.ingredient,
            nutrients: [],
          });
        })
      )
    );
  
    forkJoin(nutrientRequests).subscribe(
      (results) => {
        const cumulativeNutrients: { [key: string]: number } = {};
  
        results.forEach((result, index) => {
          const ingredient = this.ingredients[index];
  
          if (result.nutrients.length > 0) {
            const multiplier = (ingredient.amount || 0) / parseFloat(result.nutrients[0]?.portionSize || '100');
  
            result.nutrients.forEach((nutrient) => {
              const nutrientName = nutrient.nutrientName;
              const adjustedAmount = nutrient.nutrientAmount * multiplier;
  
              if (cumulativeNutrients[nutrientName]) {
                cumulativeNutrients[nutrientName] += adjustedAmount;
              } else {
                cumulativeNutrients[nutrientName] = adjustedAmount;
              }
            });
          }
        });
  
        // Update nutritionLabel for UI display
        this.nutritionLabel = Object.entries(cumulativeNutrients).map(([name, value]) => ({
          nutrientName: name,
          nutrientAmount: value.toFixed(2), // Rounded to 2 decimal places
          unit: results[0]?.nutrients.find(n => n.nutrientName === name)?.unit || 'N/A',
        }));
      },
      (error) => {
        console.error('Error fetching nutrient data:', error);
      }
    );
   // this.generateNutritionLabelFDA();
       // Logic to generate data for mat-table
       this.showMatTable = true;  // Show mat-table results
       this.showFDA = false;      // Hide FDA label if previously displayed
  } 

  generateNutritionLabelFDA() {
    // Your existing logic to generate the label
    this.nutritionLabel = this.generateLabelData(); // Assume this populates the label
    this.showFDA = true;      // Show FDA label
    this.showMatTable = false; // Hide mat-table if previously displayed  
    }

  
  generateLabelData() {
      const nutrientRequests = this.ingredients.map((ingredient) =>
        this.foodNutrientsService.getNutrients(ingredient.ingredient).pipe(
          catchError((err) => {
            console.error(`Error fetching nutrients for ${ingredient.ingredient}`, err);
            return of({
              foodName: ingredient.ingredient,
              nutrients: [],
            });
          })
        )
      );
    
      forkJoin(nutrientRequests).subscribe((results) => {
        const cumulativeNutrients: { [key: string]: number } = {};
    
        results.forEach((result, index) => {
          const ingredient = this.ingredients[index];
    
          if (result.nutrients.length > 0) {
            const multiplier = (ingredient.amount || 0) / parseFloat(result.nutrients[0]?.portionSize || '100');
    
            result.nutrients.forEach((nutrient) => {
              const nutrientName = nutrient.nutrientName;
              const adjustedAmount = nutrient.nutrientAmount * multiplier;
    
              if (cumulativeNutrients[nutrientName]) {
                cumulativeNutrients[nutrientName] += adjustedAmount;
              } else {
                cumulativeNutrients[nutrientName] = adjustedAmount;
              }
            });
          }
        });
    
        // Calculate specific values for the nutrition label
        this.totalFat =
          (cumulativeNutrients['Fatty acids, total monounsaturated'] || 0) +
          (cumulativeNutrients['Fatty acids, total polyunsaturated'] || 0) +
          (cumulativeNutrients['Fatty acids, total trans'] || 0) +
          (cumulativeNutrients['Fatty acids, total saturated'] || 0) +
          (cumulativeNutrients['Fatty acids, total trans-monoenoic'] || 0) +
          (cumulativeNutrients['Fatty acids, total trans-dienoic'] || 0);
    
          this. saturatedFat = (cumulativeNutrients['Fatty acids, total saturated'] || 0);
          this. totalCarbohydrate = cumulativeNutrients['Carbohydrate, by difference'] || 0;
          this. dietaryFiber = cumulativeNutrients['Fiber, total dietary'] || 0;
          this. totalSugars = cumulativeNutrients['Sugars, Total'] || 0;
          var sucrose = cumulativeNutrients['Sucrose'] || 0; // Add Sucrose for added sugars
          this.addedSugars = sucrose; // Assuming sucrose is entirely added sugar for now
          this.addedSugarsPercentage = this.addedSugars > 0 ? (this.addedSugars / 50) * 100 : 0; // Assuming 50g is the standard daily value for added sugars
    
        this.protein = cumulativeNutrients['Protein'] || 0;
        this.cholesterol = cumulativeNutrients['Cholesterol'] || 0;
        this.sodium = cumulativeNutrients['Sodium, Na'] || 0;
        this.vitaminD = cumulativeNutrients['Vitamin D (D2 + D3)'] || 0;
        this.calcium = cumulativeNutrients['Calcium, Ca'] || 0;
        this.iron = cumulativeNutrients['Iron, Fe'] || 0;
        this.potassium = cumulativeNutrients['Potassium, K'] || 0;
    
        // Update the label
        this.nutritionLabel = [
          { nutrientName: 'Total Fat', nutrientAmount: this.totalFat.toFixed(2), unit: 'g' },
          { nutrientName: 'Saturated Fat', nutrientAmount: this.saturatedFat.toFixed(2), unit: 'g', isSubNutrient: true },
          { nutrientName: 'Cholesterol', nutrientAmount: this.cholesterol.toFixed(2), unit: 'mg' },
          { nutrientName: 'Sodium', nutrientAmount: this.sodium.toFixed(2), unit: 'mg' },
          { nutrientName: 'Total Carbohydrate', nutrientAmount: this.totalCarbohydrate.toFixed(2), unit: 'g' },
          { nutrientName: 'Dietary Fiber', nutrientAmount: this.dietaryFiber.toFixed(2), unit: 'g', isSubNutrient: true },
          { nutrientName: 'Total Sugars', nutrientAmount: this.totalSugars.toFixed(2), unit: 'g', isSubNutrient: true },
          { nutrientName: 'Added Sugars', nutrientAmount: this.addedSugars.toFixed(2), unit: 'g', isSubNutrient: true, percentage: this.addedSugarsPercentage.toFixed(0) },
          { nutrientName: 'Protein', nutrientAmount: this.protein.toFixed(2), unit: 'g' },
          { nutrientName: 'Vitamin D', nutrientAmount: this.vitaminD.toFixed(2), unit: 'mcg' },
          { nutrientName: 'Calcium', nutrientAmount: this.calcium.toFixed(2), unit: 'mg' },
          { nutrientName: 'Iron', nutrientAmount: this.iron.toFixed(2), unit: 'mg' },
          { nutrientName: 'Potassium', nutrientAmount: this.potassium.toFixed(2), unit: 'mg' },
        ];
      });
      return this.nutritionLabel;
    }
    
    
    
    
  // Fetch nutrients for the entered food name
  onSearchFood() {
    this.foodNutrientsService.getNutrients(this.foodName).subscribe(data => {
      this.foodData = data;
      //this.initializeChartData(); // Call initializeChartData after foodData is set
    });
  }

  private initializeChartData() {
    if (this.foodData && this.foodData.nutrients) {
      this.chartData = {
        labels: this.foodData.nutrients.map(n => n.nutrientName),
        datasets: [{
          label: 'Nutrient Amount',
          data: this.foodData.nutrients.map(n => n.nutrientAmount),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        }]
      };
    }
  }



  filterResults(text: string) {
    this.reset();
    if (!text) {
      return;
    }
    if (text.toLowerCase().includes("protein")) {
      this.filteredFoodList = this.foodList_Protein;
      this.unit.unitname = "g";
    }

    if (text.toLowerCase().includes("carbohydrate")) {
      this.filteredFoodList = this.foodList_Carb;
      this.unit.unitname = "g";
    }

    if (text.toLowerCase().includes("fiber")) {
      this.filteredFoodList = this.foodList_Fiber;
      this.unit.unitname = "g";
    }

    if (text.toLowerCase().includes("saturatedfats")) {
      this.filteredFoodList = this.foodList_SaturatedFats;
      this.unit.unitname = "g";
    }


    if (text.toLowerCase().includes("monounsaturatedfats")) {
      this.filteredFoodList = this.foodList_MonoUnsaturatedFats;
      this.unit.unitname = "g";
    }


    if (text.toLowerCase().includes("polyunsaturatedfats")) {
      this.filteredFoodList = this.foodList_PolyUnsaturatedFats;
      this.unit.unitname = "g";
    }


    if (text.toLowerCase().includes("transfats")) {
      this.filteredFoodList = this.foodList_TransFats;
      this.unit.unitname = "g";
    }

    if (text.toLowerCase().includes("cholestrol")) {
      this.filteredFoodList = this.foodList_Cholestrol;
      this.unit.unitname = "mg";
    }
    if (text.toLowerCase().includes("water")) {
      this.filteredFoodList = this.foodList_Water;
      this.unit.unitname = "g";
    }

    if (text.toLowerCase().includes("biotin")) {
      this.filteredFoodList = this.foodList_Biotin;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("boron")) {
      this.filteredFoodList = this.foodList_Boron;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("calcium")) {
      this.filteredFoodList = this.foodList_Calcium;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("carotenealpha")) {
      this.filteredFoodList = this.foodList_Carotene_alpha;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("carotenebeta")) {
      this.filteredFoodList = this.foodList_Carotene_beta;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("carotenegamma")) {
      this.filteredFoodList = this.foodList_Carotene_gamma;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("chlorine")) {
      this.filteredFoodList = this.foodList_Chlorine;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("iron")) {
      this.filteredFoodList = this.foodList_iron;

    }

    if (text.toLowerCase().includes("cholinefree")) {
      this.filteredFoodList = this.foodList_cholineFree;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("cholinefromphosphocholinemg")) {
      this.filteredFoodList = this.foodList_cholineFromPhosphocholineMg;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("cholinefromphosphotidylcholinemg")) {
      this.filteredFoodList = this.foodList_cholineFromPhosphotidylCholineMg;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("cholinefromsphingomyelinmg")) {
      this.filteredFoodList = this.foodList_cholineFromSphingomyelinMg;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("cholinefromglycerophosphocholinemg")) {
      this.filteredFoodList = this.foodList_cholineFromGlycerophosphocholineMg;
      this.unit.unitname = "mg";

    }



    if (text.toLowerCase().includes("cholinetotal")) {
      this.filteredFoodList = this.foodList_cholineTotal;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("chromiumMugram")) {
      this.filteredFoodList = this.foodList_chromiumMugram;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("copper")) {
      this.filteredFoodList = this.foodList_copper;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("fluoride")) {
      this.filteredFoodList = this.foodList_fluoride;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("folatedfe")) {
      this.filteredFoodList = this.foodList_folateDFE;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("folatefood")) {
      this.filteredFoodList = this.foodList_folateFood;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("folatetotal")) {
      this.filteredFoodList = this.foodList_folateTotal;
      this.unit.unitname = "McG";
    }

    if (text.toLowerCase().includes("iodine")) {
      this.filteredFoodList = this.foodList_iodine;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("iron")) {
      this.filteredFoodList = this.foodList_iron;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("magnesium")) {
      this.filteredFoodList = this.foodList_magnesium;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("manganese")) {
      this.filteredFoodList = this.foodList_manganese;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("molybdenum")) {
      this.filteredFoodList = this.foodList_molybdenum;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("niacin")) {
      this.filteredFoodList = this.foodList_niacin;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("nickel")) {
      this.filteredFoodList = this.foodList_nickel;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("pantothenicacid")) {
      this.filteredFoodList = this.foodList_pantothenicAcid;
      this.unit.unitname = "mg";
    }
    if (text.toLowerCase().includes("phosphorus")) {
      this.filteredFoodList = this.foodList_phosphorus;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("potassium")) {
      this.filteredFoodList = this.foodList_potassium;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("riboflavin")) {
      this.filteredFoodList = this.foodList_riboflavin;
      this.unit.unitname = "mg";
    }

    if (text.toLowerCase().includes("selenium")) {
      this.filteredFoodList = this.foodList_selenium;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("sodium")) {
      this.filteredFoodList = this.foodList_sodium;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("sulfur")) {
      this.filteredFoodList = this.foodList_sulfur;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("thiamin")) {
      this.filteredFoodList = this.foodList_thiamin;
      this.unit.unitname = "mg";

    }

    if (text.toLowerCase().includes("vitamina_rae")) {
      this.filteredFoodList = this.foodList_vitaminA_RAE;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("vitaminaiu")) {
      this.filteredFoodList = this.foodList_vitaminAIU;
      this.unit.unitname = "IU";

    }
    if (text.toLowerCase().includes("vitaminb12")) {
      this.filteredFoodList = this.foodList_vitaminB12;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("vitaminb12added")) {
      this.filteredFoodList = this.foodList_vitaminB12Added;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("vitaminb6")) {
      this.filteredFoodList = this.foodList_vitaminB6;
      this.unit.unitname = "mg";

    }
    if (text.toLowerCase().includes("vitaminc")) {
      this.filteredFoodList = this.foodList_vitaminC;
      this.unit.unitname = "mg";

    }
    if (text.toLowerCase().includes("vitamind_d2plusd3")) {
      this.filteredFoodList = this.foodList_vitaminD_D2PlusD3;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("vitamind_d2plusd3iu")) {
      this.filteredFoodList = this.foodList_VitaminD_D2PlusD3IU;
      this.unit.unitname = "IU";

    }

    if (text.toLowerCase().includes("vitamind2")) {
      this.filteredFoodList = this.foodList_vitaminD2;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("vitamind3")) {
      this.filteredFoodList = this.foodList_vitaminD3;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("vitamind4")) {
      this.filteredFoodList = this.foodList_vitaminD4;
      this.unit.unitname = "McG";

    }
    if (text.toLowerCase().includes("vitamine_added")) {
      this.filteredFoodList = this.foodList_vitaminE_Added;
      this.unit.unitname = "mg";

    }
    if (text.toLowerCase().includes("vitamine_alphatocopherol")) {
      this.filteredFoodList = this.foodList_vitaminE_AlphaTocopherol;
      this.unit.unitname = "mg";

    }
    if (text.toLowerCase().includes("vitaminelabelentryiu")) {
      this.filteredFoodList = this.foodList_vitaminELabelEntryIU;
      this.unit.unitname = "IU";

    }
    if (text.toLowerCase().includes("vitaminemcgre")) {
      this.filteredFoodList = this.foodList_vitaminEMcgRE;
      this.unit.unitname = "McG_RE";

    }

    if (text.toLowerCase().includes("vitaminelabelentryiu")) {
      this.filteredFoodList = this.foodList_vitaminELabelEntryIU;

    }
    if (text.toLowerCase().includes("vitaminemcgre")) {
      this.filteredFoodList = this.foodList_vitaminEMcgRE;

    }
    if (text.toLowerCase().includes("vitamink_dihydrophylloquinone")) {
      this.filteredFoodList = this.foodList_vitaminK_Dihydrophylloquinone;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("vitamink_menaquinone4")) {
      this.filteredFoodList = this.foodList_vitaminK_Menaquinone4;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("vitamink_phylloquinone")) {
      this.filteredFoodList = this.foodList_vitaminK_Phylloquinone;
      this.unit.unitname = "McG";

    }

    if (text.toLowerCase().includes("zinc")) {
      this.filteredFoodList = this.foodList_zinc;
      this.unit.unitname = "mg";

    }

    this.filteredFoodList.sort((n1, n2) => (n2.per100unitservinging - n1.per100unitservinging));

    this.filteredFoodList.forEach((a, i) => {
      var foodWithUnit: FoundationFoodsWithUnit = {
        index: i + 1,
        fooddescription: a.fooddescription,
        perservingsizeing: a.perservingsizeing,
        per100unitservinging: a.per100unitservinging,
        foodcategory: a.foodcategory,
        unitname: this.unit.unitname
      };
      this.foodsWithUnit.push(foodWithUnit);
    });

    this.dataSource.data = this.dataSource.data.concat(this.foodsWithUnit);
    this.dataSource.paginator = this.paginator;
    this.getUniqueCategories();
    this.applyFilter(); // Apply initial filter
  }
  authService = inject(AuthService);
  router = inject(Router);
  public logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}


function applyFilter(event: Event | undefined, Event: { new(type: string, eventInitDict?: EventInit): Event; prototype: Event; readonly NONE: 0; readonly CAPTURING_PHASE: 1; readonly AT_TARGET: 2; readonly BUBBLING_PHASE: 3; }) {
  throw new Error('Function not implemented.');
}
//  this.nutrient_list.push(temp_nutrient_name);



