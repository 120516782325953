<div class="search-container">
  <div class="flex-container">
    <form #searchForm="ngForm" (ngSubmit)="onSearch()" class="search-form">
      <div class="form-group-row">
        <!-- Dropdown for Group -->
        <div class="form-group">
          <label for="lsgroup">Group</label>
          <select id="lsgroup" [(ngModel)]="searchCriteria.lsgroup" name="lsgroup" (change)="onFirstDropdownChange()" class="form-control">
            <option value="" disabled>Select a group</option>
            <option *ngFor="let option of dropdownOptions" [value]="option">
              {{ option }}
            </option>
          </select>
        </div>

        <!-- Age Input Field -->
        <div class="form-group">
          <label for="age">Age</label>
          <input
            type="number"
            id="age"
            name="age"
            [(ngModel)]="searchCriteria.age"
            class="form-control small-input"
            placeholder="Enter age"
            [min]="minAge"
            [max]="isChildOrInfant() ? maxAge : null" 
            (change)="onAgeChange()" 
          />
          <small class="form-text text-muted" *ngIf="isChildOrInfant()">Age should be between {{minAge}} and {{maxAge}}.</small>
        </div>

        <!-- Year/Month Dropdown -->
        <div class="form-group">
          <label for="yearOrMonth">Age in Year/Month</label>
          <select id="yearOrMonth" [(ngModel)]="searchCriteria.yearOrMonth" class="form-control">
            <option value="Year">Year</option>
            <option value="Month">Month</option>
          </select>
        </div>
      </div>

      <!-- Search Button -->
      <div class="form-group-row">
        <button type="submit" class="btn btn-primary btn-lg">Search</button>
      </div>
    </form>

    <!-- iFrame -->
    <iframe src="https://www.myplate.gov/widgets/myplate-plan-start/sm" scrolling="no" style="min-height: 300px;"></iframe>
  </div>
</div>

<div class="table-container">
  <mat-accordion>
    <mat-expansion-panel 
      *ngFor="let group of SECTIONS_DATA; let i = index" 
      [expanded]="i === 0" 
      (opened)="onPanelOpened(i)" 
      (closed)="onPanelClosed(i)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ group.name }}
        </mat-panel-title>
      </mat-expansion-panel-header> 

      <table mat-table [dataSource]="group.items" class="mat-elevation-z8 custom-table">
        <!-- Define the column for Column Name -->
        <ng-container matColumnDef="columnName">
          <mat-header-cell *matHeaderCellDef> Essential Nutrient </mat-header-cell>
          <mat-cell *matCellDef="let element">{{columnNames[element.columnName]}}</mat-cell>
        </ng-container>

        <!-- Define the column for Column Value -->
        <ng-container matColumnDef="columnValue">
          <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.columnValue}}</mat-cell>
        </ng-container>

        <!-- Define the column for Link Text -->
        <ng-container matColumnDef="linkText">
          <mat-header-cell *matHeaderCellDef> Food List </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button (click)="openRouteInNewTab(element.linkUrl)">
              {{element.linkText}}
            </button>
          </mat-cell>
        </ng-container>

        <!-- Define the header row -->
        <mat-header-row *matHeaderRowDef="['columnName', 'columnValue', 'linkText']"></mat-header-row>

        <!-- Define the data rows -->
        <mat-row *matRowDef="let row; columns: ['columnName', 'columnValue', 'linkText'];"></mat-row>
      </table>
    </mat-expansion-panel>
  </mat-accordion>
</div>
