import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { PaymentComponent } from 'src/app/payment/payment.component';


@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule, PaymentComponent],
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})


export class SignupComponent {
  authService = inject(AuthService);
  passwordVisible: boolean = false;
  isPremiumUser: boolean = false; // Declare and initialize 'isPremiumUser' here
  squareToken: string = '';  // Will store the token from <app-square-payment>


  router = inject(Router);
  public signupForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    state: new FormControl('', Validators.required),  // Replaced 'location' with 'state'
    country: new FormControl('', Validators.required), // New 'country' field    
    companyName: new FormControl('', [Validators.required]), // Change from 'company_name' to 'companyName'
    role: new FormControl('', [Validators.required]),
    signupGoal: new FormControl('', [Validators.required]),     // Updated name
    cb: new FormControl(false, [Validators.requiredTrue]),
    premium: new FormControl(false)  // Renamed from isPremium to premium
  })
  /* public onSubmit() {
    if (this.signupForm.valid) {

      this.authService.signup(this.signupForm.value)
        .subscribe({
          next: (data: any) => {
            alert("Check your email to verify account");
            this.router.navigate(['/login']);
          },
          error: (err) => console.log(err)
        });
    }
  }

 */
  public onSubmit() {
    if (this.signupForm.valid) {
      // Cast the form value to any so you can add new properties.
      let formData: any = { ...this.signupForm.value };
  
      // If premium is selected, add the Square token
      if (this.signupForm.get('isPremium')?.value) {
        if (!this.squareToken) {
          alert('Please complete the payment information to sign up as a premium user.');
          return;
        }
        formData.squareCardToken = this.squareToken;
      }
  
      console.log("Sending form data:", formData);
  
      // Send formData to your backend
      this.authService.signup(formData)
        .subscribe({
          next: (data: any) => {
            alert("Check your email to verify account");
            this.router.navigate(['/login']);
          },
          error: (err) => console.log(err)
        });
    }
  }
  
  

  onTokenReceived(token: string) {
    this.squareToken = token;
    console.log("Received Square token:", token);
  }
  

  togglePassword() {
    this.passwordVisible = !this.passwordVisible;
  }

  togglePremiumPayment() {
    // Ensure the value is boolean, fallback to false if undefined or null
    this.isPremiumUser = this.signupForm.get('premium')?.value ?? false;
  }
  
}
