<div class="about-us-container">
  <div class="nutrition-info">
    <p class="intro">
      <strong>Complete nutrition</strong> is key to the quality and longevity of our lives. Still, there is no organic way to develop this knowledge. Much of what we know about nutrition comes from childhood experiences and societal influences.
    </p>
    
    <p class="aspiration">
      <strong>AI-Basil</strong> aims to be the go-to platform for everything related to food and nutrition, backed by research and powered by AI. The information provided should serve as best practices while considering your unique personal needs.
    </p>
  </div>
  

  <section class="about-mission">
    <h2>Our Mission</h2>
    <p>
      At AI-Basil, we are on a mission to create a healthier planet and happier lives by empowering all to make educational nutrition choices.
    </p>
  </section>

  <!--section class="about-team">
    <h2>Meet the Team</h2>
    <div class="team-grid">
      <div class="team-member" *ngFor="let member of teamMembers">
        <img [src]="member.photo" alt="{{ member.name }}">
        <h3>{{ member.name }}</h3>
        <p>{{ member.role }}</p>
      </div>
    </div>
  </section-->

  <section class="about-contact">
    <h2>Contact Us</h2>
    <p>
      Have any questions? Reach us at <a href="mailto:query@ai-basil.com">query@ai-basil.com</a>.
    </p>
  </section>
</div>
<!--section class="about-section">
  <h1>About Us</h1>
    <p>Complete nutrition is key to the quality and longevity of our lives. Still, there is no organic way of developing this knowledge. Our knowledge on nutrition is broadly driven by our childhood and societal exposure.</p>
    <p>AI-Basil aspires to be one single platform for all things around food and nutrition backed by research and fueled by AI. All accessed information should be taken as good practices validating your unique personal needs.</p>
    <p>At AI-Basil, we are on a mission to create a healthier planet and happier lives by empowering all to make educational nutrition choices.</p>
</section-->