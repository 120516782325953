import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { User } from '../food-data';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-edit-profile',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent {
  user: User = new User(); // Ensure it's not null

  constructor(private userService: AuthService) { }


  ngOnInit(): void {
    // Fetch the current user and populate the form
    this.userService.getCurrentUser().subscribe((data) => {
      if (data) {
        this.user = data;
      }
    });
  }

  onSaveChanges(): void {
    this.userService.updateUser(this.user).subscribe({
      next: (updatedUser) => {
        this.user = updatedUser; // Update the UI with new data
        alert('Profile updated successfully!');
      },
      error: (err) => {
        console.error('Error updating profile:', err);
        alert('Failed to update profile. Please try again.');
      }
    });
  }
}
