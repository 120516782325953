declare global {
  interface Window {
    Square: any;
  }
}

import { AfterViewInit, Component, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-square-payment',
  standalone: true,
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements AfterViewInit {
  payments: any;
  card:any;

  constructor(private http: HttpClient, private cdr: ChangeDetectorRef) { }
  //@ViewChild('cardContainer', { static: true }) cardContainer!: ElementRef;
  @ViewChild('cardContainer', { static: false }) cardContainer!: ElementRef;
  @Output() tokenEmitted = new EventEmitter<string>();



  async ngAfterViewInit() {
    setTimeout(() => this.initializeSquarePayments(), 1000); // Ensure DOM is ready
  }
  // This method should be called to tokenize the card details.
  async tokenizeCard(): Promise<void> {
    try {
      const result = await this.card.tokenize();
      if (result.status === 'OK') {
        this.tokenEmitted.emit(result.token);
      } else {
        console.error('Tokenization errors:', result.errors);
        this.tokenEmitted.emit('');
      }
    } catch (error) {
      console.error('Error during tokenization:', error);
      this.tokenEmitted.emit('');
    }
  }

  async initializeSquarePayments() {
    try {
      await this.waitForElement('#card-container');  // Ensure the element exists before attaching

      const applicationId = 'sandbox-sq0idb-jyi25VB7zcIvOaO4d4e1xw'; // Replace with your actual Square Application ID
      const locationId = 'L901H8J2TB19N';
      const square = (window as any).Square;
      const payments = square.payments(applicationId, locationId);

      const card = await payments.card();

      // Wait for the element to exist before attaching
      const cardContainer = document.querySelector('#card-container');
      if (!cardContainer) {
        console.error('Error: #card-container element not found in DOM');
        return;
      }

      await card.attach('#card-container');
      this.card = card;

      document.getElementById('pay-button')?.addEventListener('click', async () => {
        const result = await card.tokenize();
        if (result.status === 'OK') {
          console.log('Sandbox Payment Token:', result.token);
        } else {
          console.error('Payment Error:', result.errors);
        }
      });
    } catch (error) {
      console.error('Square Payment Initialization Error:', error);
    }
  }

  async waitForElement(selector: string, timeout = 5000) {
    return new Promise<void>((resolve, reject) => {
      const startTime = Date.now();
      const checkExist = () => {
        if (document.querySelector(selector)) {
          console.log(`Element ${selector} found!`);
          resolve();
        } else if (Date.now() - startTime > timeout) {
          reject(new Error(`Timeout: ${selector} not found in DOM`));
        } else {
          setTimeout(checkExist, 100);
        }
      };
      checkExist();
    });
  }


  async submitPayment(): Promise<void> {
    try {
      const result = await this.card.tokenize();
      if (result.errors) {
        console.error('❌ Tokenization errors:', result.errors);
        alert('Invalid payment details. Please check and try again.');
        return;
      }

      this.processPayment(result.token);
    } catch (error) {
      console.error('❌ Error during payment submission:', error);
      alert('Payment submission failed. Please try again.');
    }
  }
  
  saveCard(): void {
    const userId = localStorage.getItem('userId'); // Retrieve logged-in user ID
    if (!userId) {
      alert('User not authenticated. Please log in.');
      return;
    }
  
    if (!this.card) {
      alert('Payment card is not initialized. Please try again.');
      return;
    }
  
    this.card.tokenize().then((result: any) => {
      if (result.status !== 'OK') {
        console.error('❌ Tokenization failed:', result.errors);
        alert('Payment information could not be saved. Please try again.');
        return;
      }
  
      const paymentData = {
        userId: userId,
        cardToken: result.token // Store the token instead of raw card details
      };
  
      this.http.post('http://localhost:8080/v1/foods/api/payment/save-card', paymentData)
        .subscribe({
          next: (response) => {
            console.log('✅ Card saved successfully:', response);
            alert('Payment method saved successfully!');
          },
          error: (error) => {
            console.error('❌ Failed to save card:', error);
            alert(error.error || 'Failed to save payment method. Please try again.');
          }
        });
    }).catch((error: any) => {
      console.error('❌ Error tokenizing card:', error);
      alert('Could not retrieve payment token. Please check your details and try again.');
    });
  }
  
  

  processPayment(nonce: string): void {
    const paymentData = { nonce }; // Ensure correct payload format

    this.http.post('http://localhost:8080/v1/foods/api/payment/process', paymentData)
      .subscribe({
        next: (response) => {
          console.log('✅ Payment successful:', response);
          alert('Payment successful!');
        },
        error: (error) => {
          console.error('❌ Payment failed:', error);
          const errorMessage = error.error?.message || 'Payment failed. Please try again.';
          alert(errorMessage);
        }
      });
  }


  
  

  /* async submitPayment(): Promise<void> {
    try {
      const result = await this.card.tokenize();
      if (result.errors) {
        console.error('Tokenization errors:', result.errors);
        alert('Invalid payment details. Please check and try again.');
        return;
      }

      this.processPayment(result.token);
    } catch (error) {
      console.error('Error during payment submission:', error);
      alert('Payment submission failed. Please try again.');
    }
  }

  processPayment(nonce: string): void {
    const paymentData = { nonce: nonce };

    this.http.post('http://localhost:8080/v1/foods/api/payment/process', paymentData)
      .subscribe(
        response => {
          console.log('Payment successful:', response);
          alert('Payment successful!');
        },
        error => {
          console.error('Payment failed:', error);
          alert('Payment failed. Please try again.');
        }
      );
  } */
}
