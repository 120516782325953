import {Routes} from '@angular/router';
import {DetailsComponent} from './details/details.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { authGuard } from './auth/auth.guard';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePassswordComponent } from './change-password/change-password.component';
import { CartComponent } from './cart/cart.component';
import { LandingComponent } from './pages/landing/landing.component';
import { PaymentComponent } from './payment/payment.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';

const routeConfig: Routes = [
  {
    path: 'details/:id',
    component: DetailsComponent,
    title: 'Food Sources',
  },
  { path: 'profile/edit', component: EditProfileComponent },
  { path: 'profile/payment', component: PaymentComponent }, // ✅ Ensure this exists

  { path: 'payment', component: PaymentComponent }, // Payment route
{
    path: 'login', component: LoginComponent
},
{
  path: 'landing', component: LandingComponent
},
{
    path: 'signup', component: SignupComponent
},
{
  path: 'about', component: AboutComponent
},
{
  path: 'contact', component: ContactComponent
},
{
  path: 'change-password', component: ChangePassswordComponent
},
{
  path: 'cart', component: CartComponent
},
{
  path: 'app', component: AppComponent, canActivate: [authGuard]
},
{
  path: 'home', component: HomeComponent, canActivate: [authGuard]
},
{
  path: '', redirectTo: '/landing', pathMatch: 'full'
},
{ path: 'forgot-password', component:ForgotPasswordComponent }  // Define the Forgot Password route

];

export default routeConfig;
