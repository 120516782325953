<div class="cart-container">
  <h1>Your Food Cart</h1>

  <!-- Responsive Table -->
  <mat-table [dataSource]="dataSource" class="mat-elevation-z8 custom-table">

    <!-- Index Column -->
    <ng-container matColumnDef="index">
      <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index"> {{ i + 1 }} </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>

    <!-- Quantity Column -->
    <ng-container matColumnDef="quantity">
      <mat-header-cell *matHeaderCellDef> Quantity </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <input type="number" [(ngModel)]="element.quantity" (change)="updateCartQuantity(element, element.quantity)"
          min="1" class="quantity-input">
      </mat-cell>
    </ng-container>

    <!-- Update Cart Column -->
    <ng-container matColumnDef="update">
      <mat-header-cell *matHeaderCellDef> Update </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button color="primary" (click)="updateCartQuantity(element, element.quantity)">
          Update
        </button>
      </mat-cell>
    </ng-container>

    <!-- Remove From Cart Column -->
    <ng-container matColumnDef="remove">
      <mat-header-cell *matHeaderCellDef> Remove </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button color="warn" (click)="removeFromCart(element)">
          Remove
        </button>
      </mat-cell>
    </ng-container>

    <!-- Header and Rows -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

  </mat-table>

  <p *ngIf="dataSource && dataSource.data.length === 0">Your cart is empty.</p>


  <!-- Paginator -->
  <mat-paginator [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>

  <div class="cart-summary">
    <h2>Your Cart Items</h2>

    <!-- Cart items table -->
    <table class="cart-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Item Name</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of cartItems; let i = index">
          <td>{{ i + 1 }}</td> <!-- Item Index -->
          <td>{{ item.name }}</td> <!-- Item Name -->
          <td>{{ item.quantity }}</td> <!-- Item Quantity -->
        </tr>
      </tbody>
    </table>
    <!-- Clear Cart button -->
    <button class="btn-clear-cart" (click)="clearCart()">Clear Cart</button>

    <!-- Input for email -->
    <div class="email-input-container">
      <label for="email">Enter your email to send the cart items:</label>
      <input type="email" id="email" [(ngModel)]="email" placeholder="Your email" required class="email-input" />
    </div>
    <button class="send-pdf-button" (click)="sendPdfToEmail(email)">Send Cart as PDF</button>
  </div>
</div>