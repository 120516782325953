import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartItem, FoundationFoods, Unit, User } from '../food-data';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { Location } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox'; // <-- Import MatCheckboxModule
import { FormsModule } from '@angular/forms'; // Required for [(ngModel)] two-way binding
import { AuthService } from '../auth/auth.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';



@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [FormsModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatPaginatorModule, MatTableModule, CommonModule, MatSelectModule],

  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
})
export class CartComponent implements OnInit, AfterViewInit {


  cartItems: any[] = []; // Initialize as an empty array
  public loggedInUser: any = null;
  email: string = ''; // For storing the user's email input



  // Declare the data source as MatTableDataSource
  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['index', 'name', 'quantity', 'update', 'remove'];
  baseUrl = 'https://ai-basil.com';
  @ViewChild(MatPaginator) paginator!: MatPaginator;


  constructor(private cdr: ChangeDetectorRef, private httpclient: HttpClient, private route: ActivatedRoute, private location: Location, private router: Router, private authService: AuthService) {
    // Retrieve the passed data from the router's state
    // Access the global variable from the service
  }


  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(); // Initialize here
    const authuser: string | null = localStorage.getItem('authUser');

    if (authuser !== null) {
      const id = JSON.parse(authuser).id;
      this.getUserDetails(id);
    }
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getUserDetails(userid: number): void {
    this.authService.getUser(userid).subscribe(response => {
      if (response) {
        this.loggedInUser = response;
        this.showCart();
      }
    });
  }

  showCart() {
    if (this.loggedInUser) {
      this.cartItems = this.loggedInUser.cart || [];
      this.dataSource.data = this.cartItems; // Set the data directly
    } else {
      this.cartItems = [];
      this.dataSource.data = []; // Initialize with an empty array
    }
    this.authService.updateUser(this.loggedInUser!).subscribe();
  }


  updateCartQuantity(item: CartItem, newQuantity: number) {
    if (newQuantity > 0) {
      item.quantity = newQuantity;
    }
    this.dataSource.data = [...this.dataSource.data];
    this.loggedInUser!.cart = this.dataSource.data;
    this.authService.updateUser(this.loggedInUser!).subscribe();
  }

  removeFromCart(item: CartItem) {
    this.dataSource.data = this.dataSource.data.filter(cartItem => cartItem.id !== item.id);
    this.loggedInUser!.cart = this.dataSource.data;
    
    this.authService.updateUser(this.loggedInUser!).subscribe();
    this.showCart();
  }

  goBack() {
    this.location.back(); // Navigate back to the previous route

  }

  goHome() {
    //this.location.back(); // Navigate back to the previous route
    this.router.navigate(['/home']);

  }
  clearCart() {
    // Clear the cartItems array
    this.cartItems = [];
    this.dataSource.data = [];  // Clear the table data

    // Clear the cart in the loggedInUser and update the backend
    this.loggedInUser.cart = [];
    this.authService.updateUser(this.loggedInUser).subscribe(() => {
      this.reloadPage();  // Reload the page to reflect changes
    });
  }


  reloadPage() {
    window.location.reload(); // Reloads the entire page
  }

  
  // cart.component.ts
  sendPdfToEmail(email: string): void {
    if (this.cartItems.length === 0) {
      alert('Your cart is empty. Add items before sending.');
      return; // Prevent sending the email
    }

    const pdfBlob = this.createPdfWithTable();
    const formData = new FormData();

    formData.append('file', pdfBlob, 'cart-items.pdf');
    formData.append('email', email);

    this.httpclient.post(`${this.baseUrl}/sendCartPdf`, formData, { responseType: 'text' }).subscribe({
      next: (response) => {
        alert('PDF sent successfully!');
        console.log(response);  // Inspect the response if needed
      },
      error: (error) => {
        alert('Failed to send PDF.');
        console.error('Error sending PDF:', error);
      }
    });
  }


  // Function to generate a PDF with a table format
  createPdfWithTable(): Blob {
    const doc = new jsPDF();

    // Add a title
    doc.text('Cart Items', 10, 10);

    // Define the table headers and data
    const tableColumns = ['#', 'Item Name', 'Quantity'];
    const tableRows = this.loggedInUser.cart.map((item: { name: any; quantity: any; }, index: number) => [
      index + 1,
      item.name,
      item.quantity
    ]);

    // Generate the table in the PDF
    (doc as any).autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: 20, // Set the start position for the table
    });

    // Return the generated PDF as a blob
    return doc.output('blob');
  }

  // Function to download PDF
  downloadCartPDF(): Blob {
    const doc = new jsPDF();
    doc.text('Cart Items', 10, 10);

    this.loggedInUser.cart.forEach((item: { name: any; quantity: any; }, index: number) => {
      doc.text(`${index + 1}. ${item.name} - Quantity: ${item.quantity}`, 10, 20 + index * 10);
    });

    return doc.output('blob'); // Return the PDF as a blob
  }


}

/**
 * 
 * 
Copy code
removeFromCart(item: CartItem) {
  this.dataSource.data = this.dataSource.data.filter(cartItem => cartItem.id !== item.id);
  this.loggedInUser.cart = this.dataSource.data;
  this.authService.updateUser(this.loggedInUser).subscribe(() => {
    this.authService.updateCartCount(this.getCartItemCount()); // Ensure cart count is updated
  });
}

updateCartQuantity(item: CartItem, newQuantity: number) {
  item.quantity = newQuantity;
  this.dataSource.data = [...this.dataSource.data];
  this.loggedInUser.cart = this.dataSource.data;
  this.authService.updateUser(this.loggedInUser).subscribe(() => {
    this.authService.updateCartCount(this.getCartItemCount()); // Update cart count on quantity change
  });
}
 */





